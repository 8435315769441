import React from "react";
import Pagination from "@material-ui/lab/Pagination";
import Result from "../cards/result";
import DealerCard from "../cards/dealer";
import ActiveCard from "../cards/active";
import DraftCard from "../cards/draft";
import SimpleLoader from "../simple-loader";
import SavedSearch from "../cards/saved-search";
import Header from "../results-header";
import NoResult from "../no-result";
import ScrollToTop from "../../lib/scroll-to-top";
import "./index.css";

const Ads = (props) => {
  const {
    data,
    path,
    loading,
    activePage,
    totalPages,
    ads_total,
    paginationHandler,
    deleteHandler,
    publishDraft,
    title,
    sortLabel,
    openSort,
    anchorEl,
    handleSort,
    dropdownData,
    renderHandler,
    children,
    previewAd,
    openDialog,
    toggleFavorites,
    favoriteIds,
    pinHandler,
    showHeader = true,
  } = props;

  let pagination = null;
  let descText = "no-results-text";
  if (path === "favourites") {
    descText = "no-favourites";
  }
  let renderData = data.map((item, index) => {
    return (
      <Result
        key={index}
        previewAd={previewAd}
        activePage={activePage}
        toggleFavorites={toggleFavorites}
        favoriteIds={favoriteIds}
        {...item}
      />
    );
  });

  if (path === "mycars") {
    descText = "no-my-cars";
    renderData = data.map((item, index) => {
      return (
        <ActiveCard
          key={index}
          deleteHandler={deleteHandler}
          pinHandler={pinHandler}
          openDialog={openDialog}
          previewAd={previewAd}
          loading={loading}
          {...item}
        />
      );
    });
  }

  if (path === "drafts") {
    descText = "no-drafts";
    renderData = data.map((item, index) => {
      return (
        <DraftCard
          key={index}
          deleteHandler={deleteHandler}
          openDialog={openDialog}
          publishDraft={publishDraft}
          previewAd={previewAd}
          {...item}
        />
      );
    });
  }

  if (path === "savedsearch") {
    descText = "no-savedsearch";
    renderData = data.map((item, index) => {
      return (
        <SavedSearch
          key={index}
          index={index}
          deleteHandler={deleteHandler}
          renderHandler={renderHandler}
          data={item}
        />
      );
    });
  }

  if (path === "dealer") {
    descText = "no-results-text";
    renderData = data.map((item, index) => {
      return <DealerCard key={item.uid} dealer={item} />;
    });
  }

  if (data.length === 0 || data === null) {
    renderData = null;
  }

  if (
    (path !== null && totalPages > 1 && data.length > 0) ||
    (path === null && ads_total > 1)
  ) {
    pagination = (
      <Pagination
        count={path === null ? Math.ceil(ads_total / 5) : totalPages}
        variant="outlined"
        // shape="round"
        shape="rounded"
        page={activePage}
        size="medium"
        onChange={(e, page) => paginationHandler(page)}
        showFirstButton
        showLastButton
      />
    );
  }

  return (
    <SimpleLoader loading={loading}>
      <ScrollToTop dependency={[activePage]} />
      <div className="ads-template">
        {showHeader && (
          <Header
            title={title}
            sortLabel={sortLabel}
            openSort={openSort}
            anchorEl={anchorEl}
            handleSort={handleSort}
            dropdownData={dropdownData}
            children={children}
          />
        )}
        <main>
          {renderData !== null ? (
            <div className="ads">{renderData}</div>
          ) : (
            <div className="ads">
              <NoResult descText={descText} />
            </div>
          )}
          {pagination}
        </main>
      </div>
    </SimpleLoader>
  );
};

export default Ads;
