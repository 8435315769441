export const LOCATIONENTRYMETHOD = {
  AUTOMATIC: 1,
  MANUAL: 2,
};

export const UPLOAD_TYPES = {
  USER_AVATAR: 1,
  GROUP_CHAT_AVATAR: 2,
  USER_COVER: 3,
  MERCHANT_LOGO: 4,
  GROUP_CHAT_COVE: 5,
  MERCHANT_COVER: 6,
  MERCHANT_GALLERY: 7,
  CHAT_MESSAGE_IMAGE: 8,
  GROUP_CHAT_MESSAGE_IMAGE: 9,
  RESERVED: 10,
  MERCHANG_CANNED_IMAGE: 11,
  SUB_PROMOTION_LOGO: 12,
  ONE_OF_THREE_SUB_PROMOTION_IMAGES: 13,
  AD_IMAGE: 14,
};

export const USER_TYPES = {
  SUPER: 0,
  STANDARD: 1, //user
  MERCHANT: 2, //seller
};

export const USER_TYPE = parseInt(process.env.USER_TYPE, 10);

export const TYPES_OF_MERCHANT = {
  MR: 0,
  MS: 1,
  COMPANY: 2,
};

export const ImageUploadStatus = {
  NOT_PROCESSED: "not processed",
  STARTED: "started",
  DONE: "done",
  ERROR: "error",
};
