import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getDoors,
  setTargaVehicleType,
  getVehiclesForCode18,
  getVehiclesForCode24,
} from "../../../../../store/actions";
import Button from "../../../../../components/button";
import Radio from "../../../../../components/radio";
import { getPropName, formatTargaDate } from "../../../../../lib/utils";
import TargaForMobileView from "./../../components/targa-for-mobile-view";
import "./index.css";

const ModelAndTypeSelect = (props) => {
  const {
    dispatch,
    body_type,
    fuel_type,
    transmission,
    drive_type,
    vehicleData18,
    vehicleData24,
    goToNextPage,
    setProcedeToMakePage,
    doors,
    targaCode18,
    targaCode24,
    setIsOnTargaMobileView,
  } = props;
  const [selectedVehicleIndex, setSelectedVehicleIndex] = useState(null);
  const { t } = useTranslation(["common", "sell", "advancedSearch"]);

  const handleRadioChange = (index) => {
    setSelectedVehicleIndex(index);
  };
  const vehicleData = [...vehicleData18, ...vehicleData24];

  useEffect(() => {
    setProcedeToMakePage(true);
    dispatch(getDoors());
  }, []);

  return (
    <div className="model-type-select">
      <p>{t("sell:select-vehicle-type")}</p>
      <table>
        <thead>
          <tr>
            <th>{t("sell:targa-type")}</th>
            <th>{t("sell:targa-body-type")}</th>
            <th>{t("common:power")}</th>
            <th>{t("sell:targa-doors")}</th>
            <th>{t("common:transmission")}</th>
            <th>{t("common:fuel")}</th>
            <th>{t("common:drivetrain")}</th>
          </tr>
        </thead>
        <tbody>
          {vehicleData.map((vehicle, index) => (
            <React.Fragment key={index}>
              <tr>
                <td className="radio-with-headline">
                  <Radio
                    name="vehicle"
                    value={index}
                    className="radio-button-target"
                    label={vehicle.modelltypen}
                    changeHandler={() => handleRadioChange(index)}
                    checked={selectedVehicleIndex === index}
                  />
                </td>
                <td>
                  <p>{getPropName(body_type, vehicle.aufbau, "idbodytype")}</p>
                </td>
                <td>
                  <p>
                    {vehicle.ps} ({vehicle.kw} Kw)
                  </p>
                </td>
                <td>
                  <p>{getPropName(doors, vehicle.türen, "iddoors")}</p>
                </td>
                <td>
                  <p>
                    {getPropName(
                      transmission,
                      vehicle.getriebe,
                      "idtransmission"
                    )}
                  </p>
                </td>
                <td>
                  <p>
                    {getPropName(fuel_type, vehicle.treibstoff, "idfueltype")}
                  </p>
                </td>
                <td>
                  <p>
                    {" "}
                    {getPropName(drive_type, vehicle.antrieb, "iddrivetype")}
                  </p>
                </td>
              </tr>
              {selectedVehicleIndex === index && (
                <tr className="additional-info">
                  <td colSpan="7">
                    <div className="additional-info-details">
                      <div className="left-section">
                        <span>
                          <p>{t("sell:type-approval-numbers")}:</p>
                          <p>{t("common:kerb-weight")}:</p>
                          <p>{t("sell:month-and-year-of-constr-short")}:</p>
                        </span>
                        <span>
                          <p>{targaCode24 || targaCode18}</p>
                          <p>
                            {vehicle.gewleer ? `${vehicle.gewleer} kg` : "-"}
                          </p>
                          <p>
                            {formatTargaDate(vehicle.prodvon)} -{" "}
                            {formatTargaDate(vehicle.prodbis)}
                          </p>
                        </span>
                      </div>
                      <div className="right-section">
                        <Button
                          color="red"
                          click={() =>
                            goToNextPage(() => {
                              dispatch(setTargaVehicleType(vehicle.typen));
                              const func = targaCode18
                                ? getVehiclesForCode18
                                : getVehiclesForCode24;
                              dispatch(func(targaCode18 ?? targaCode24, true));
                            })
                          }
                          label={t("common:next")}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <TargaForMobileView
        vehicleData={vehicleData}
        setIsOnTargaMobileView={setIsOnTargaMobileView}
        body_type={body_type}
        fuel_type={fuel_type}
        transmission={transmission}
        drive_type={drive_type}
        doors={doors}
        targaCode18={targaCode18}
        targaCode24={targaCode24}
        goToNextPage={goToNextPage}
        setProcedeToMakePage={setProcedeToMakePage}
      />
    </div>
  );
};

const stateToProps = (state) => {
  const {
    body_type,
    fuel_type,
    transmission,
    drive_type,
    doors,
    targaCode18,
    targaCode24,
  } = state.car;
  return {
    body_type,
    fuel_type,
    transmission,
    drive_type,
    doors,
    targaCode18,
    targaCode24,
  };
};

export default connect(stateToProps)(ModelAndTypeSelect);
