import React from "react";
import { Autocomplete } from "@material-ui/lab";
import { withStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ArrowDown } from "../svg-icons";
import "./index.css";

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#969696",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#969696",
      },
    },
  },
})(TextField);

const colors = [
  { name: "Beige", color: "#E8DED1" },
  { name: "Black", color: "#000" },
  { name: "Blue", color: "#00F" },
  { name: "Bronze", color: "#CD7F32" },
  { name: "Brown", color: "#A52A2A" },
  { name: "Gold", color: "#FFD700	" },
  { name: "Green", color: "#008000" },
  { name: "Gray", color: "#C0C0C0" },
  { name: "Orange", color: "#FFA500" },
  { name: "Red", color: "#F00" },
  { name: "Silver", color: "#C0C0C0" },
  { name: "White", color: "#FFF" },
];

const ColorPicker = (props) => {
  const { changeHandler, value, id } = props;
  const { t } = useTranslation(["common"]);

  const selectedValue =
    props?.colors && id
      ? props.colors.find((item) => item[id] === Number(value))
      : null;

  return (
    <Autocomplete
      value={selectedValue ? selectedValue : null}
      options={props?.colors ?? colors}
      popupIcon={ArrowDown}
      getOptionLabel={(option) => option?.name ?? ""}
      getOptionSelected={(option, value) => option === value}
      onChange={changeHandler}
      renderInput={(params) => (
        <CssTextField
          {...params}
          variant="outlined"
          InputLabelProps={{ shrink: false }}
          placeholder={t("common:select")}
        />
      )}
      renderOption={(props, option) => {
        return (
          <div className="color-icon">
            <div
              style={{
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                backgroundColor: props?.rgb ? `#${props?.rgb}` : props.color,
              }}
            ></div>
            <p>
              {typeof props.name === "object"
                ? props?.name?.[language ? language.toUpperCase() : "EN"] ?? "-"
                : props.name}
            </p>
          </div>
        );
      }}
    />
  );
};

export default ColorPicker;
