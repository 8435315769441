import { ERROR, CONNECTION_STATUS_TOAST } from "./constants";
//import commonComponents from '../../components/common'

//const { connectionError, connectionErrorUpdated } = commonComponents.Toast

// The initial application state
let initialState = {
  error: null,
  isConnected: null,
  connectionLostToastId: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ERROR:
      return { ...state, error: action.data };
    case CONNECTION_STATUS_TOAST:
      if (state.connectionLostToastId === null && action.show) {
        //const toastId = connectionError()
        console.log("--case1");
        //return { ...state, connectionLostToastId: toastId }
        return { ...state, connectionLostToastId: "DUMMY_TOAST_ID" };
      }
      if (!action.show) {
        //connectionErrorUpdated(state.connectionLostToastId)
        console.log("--case2");
        return { ...state, connectionLostToastId: null };
      }
      return { ...state };
    default:
      return state;
  }
};
