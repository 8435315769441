import React from "react";
import BannerWithTrailerHitch from "../../../../assets/images/ad-banners/ramader-banner-with-trailer-hitch.png";
import BannerWithOutTrailerHitch from "../../../../assets/images/ad-banners/ramader-banner-without-trailer-hitch.png";
import BannerWithTrailerHitchMobile from "../../../../assets/images/ad-banners/ramader-banner-with-trailer-hitch-mobile.png";
import BannerWithOutTrailerHitchMobile from "../../../../assets/images/ad-banners/ramader-banner-without-trailer-hitch-mobile.png";
import { useIsOnMobileChecker } from "./../../../../hooks";
import "./index.css";

const AdBanner = ({ adData }) => {
  const isMobile = useIsOnMobileChecker(480);

  const bannerImage = adData?.vehicledata?.idtrailerhitch
    ? isMobile
      ? BannerWithTrailerHitchMobile
      : BannerWithTrailerHitch
    : isMobile
    ? BannerWithOutTrailerHitchMobile
    : BannerWithOutTrailerHitch;

  return (
    <div className="ad-banner">
      <img src={bannerImage} alt="Ad Banner" />
    </div>
  );
};

export default AdBanner;
