// car index
export const SETSEARCHPROPS = "SETSEARCHPROPS";
export const RESETSEARCHPROPS = "RESETSEARCHPROPS";
export const CUSTOMSETFINDPROPS = "CUSTOMSETFINDPROPS";
export const SETDEALERFINDPROPS = "SETDEALERFINDPROPS";
export const SETFINDPROPS = "SETFINDPROPS";
export const RESETFINDPROPS = "RESETFINDPROPS";
export const REMOVEGENERALDATAPROPS = "REMOVEGENERALDATAPROPS";
export const REMOVEVEHICLEDATAPROPS = "REMOVEVEHICLEDATAPROPS";
export const RESETADPROPS = "RESETADPROPS";
export const RESETDEALERFINDPROPS = "RESETDEALERFINDPROPS";
export const RENDERSAVEDSEARCH = "RENDERSAVEDSEARCH";
export const SETFUELTYPECHECK = "SETFUELTYPECHECK";
export const RESETSELECTEDAD = "RESETSELECTEDAD";
export const CHECKBOXCHANGEHANDLER = "CHECKBOXCHANGEHANDLER";
export const RESETCHECKBOX = "RESETCHECKBOX";
export const RESETADS = "RESETADS";
export const RESETPOWER = "RESETPOWER";
export const RESETDOORCOUNT = "RESETDOORCOUT";
export const RESETNUMBEROFSEATS = "RESETNUMBEROFSEATS";
export const ISADCREATED = "ISADCREATED";
export const RESETCARMODEL = "RESETCARMODEL";
export const RESETCHECKBOXESINOBJECT = "RESETCHECKBOXESINOBJECT";
export const RESETCARDATA = "RESETCARDATA";
export const RESETMOTORS = "RESETMOTORS";
// export const SETPUBLISHAD = "SETPUBLISHAD";
export const REMOVECARMODEL = "REMOVECARMODEL";
export const RESETREADYTOREPORT = "RESETREADYTOREPORT";
export const SETOPTIONALEURONORMIDS = "SETOPTIONALEURONORMIDS";

// Set ad data
export const SETROOTADDATA = "SETROOTADDATA";
export const SET_ROOT_DATA_PROPS = "SET_ROOT_DATA_PROPS";
export const SETGENERALDATA = "SETGENERALDATA";
export const SETVEHICLEDATA = "SETVEHICLEDATA";
export const SETENGINEENVIRONMENT = "SETENGINEENVIRONMENT";
export const SETCONDITIONMAINTENANCE = "SETCONDITIONMAINTENANCE";
export const SETOPTIONALEQUIPMENTSIDS = "SETOPTIONALEQUIPMENTSIDS";
export const SETVEHICLELOCATION = "SETVEHICLELOCATION";
export const SETFUELCONSUPTION = "SETFUELCONSUPTION";
export const SETDESCRIPTION = "SETDESCRIPTION";

// socket
export const GETMAKE = "GETMAKE";
export const GETMAKE_RESULT = "GETMAKE_RESULT";
export const GETMAKE_ERROR = "GETMAKE_ERROR";

export const GETMODEL = "GETMODEL";
export const GETMODEL_RESULT = "GETMODEL_RESULT";
export const GETMODEL_ERROR = "GETMODEL_ERROR";

export const GETFUELTYPE = "GETFUELTYPE";
export const GETFUELTYPE_RESULT = "GETFUELTYPE_RESULT";
export const GETFUELTYPE_ERROR = "GETFUELTYPE_ERROR";

export const GETBODYTYPE = "GETBODYTYPE";
export const GETBODYTYPE_RESULT = "GETBODYTYPE_RESULT";
export const GETBODYTYPE_ERROR = "GETBODYTYPE_ERROR";

export const GETLOCALE = "GETLOCALE";
export const GETLOCALE_RESULT = "GETLOCALE_RESULT";
export const GETLOCALE_ERROR = "GETLOCALE_ERROR";

export const GETVERSION = "GETVERSION";
export const GETVERSION_RESULT = "GETVERSION_RESULT";
export const GETVERSION_ERROR = "GETVERSION_ERROR";

export const CREATEAD = "CREATE";
export const CREATEAD_RESULT = "CREATEAD_RESULT";
export const CREATEAD_ERROR = "CREATEAD_ERROR";

export const PUBLISHAD = "PUBLISHAD";
export const PUBLISHAD_RESULT = "PUBLISHAD_RESULT";
export const PUBLISHAD_ERROR = "PUBLISHAD_ERROR";

export const UNPUBLISHAD = "UNPUBLISHAD";
export const UNPUBLISHAD_RESULT = "UNPUBLISHAD_RESULT";
export const UNPUBLISHAD_ERROR = "UNPUBLISHAD_ERROR";

export const SEARCH = "SEARCH";
export const SEARCH_RESULT = "SEARCH_RESULT";
export const SEARCH_ERROR = "SEARCH_ERROR";

export const FINDADS = "FINDADS";
export const FINDADS_RESULT = "FINDADS_RESULT";
export const FINDADS_ERROR = "FINDADS_ERROR";

export const FINDADS2 = "FINDADS2";
export const FINDADS2_RESULT = "FINDADS2_RESULT";
export const FINDADS2_ERROR = "FINDADS2_ERROR";

export const FINDADS3 = "FINDADS3";
export const FINDADS3_RESULT = "FINDADS3_RESULT";
export const FINDADS3_ERROR = "FINDADS3_ERROR";

export const FINDDEALERS = "FINDDEALERS";
export const FINDDEALERS_RESULT = "FINDDEALERS_RESULT";
export const FINDDEALERS_ERROR = "FINDDEALERS_ERROR";

export const GETAD = "GETAD";
export const GETAD_RESULT = "GETAD_RESULT";
export const GETAD_ERROR = "GETAD_ERROR";

export const GETMERCHANTCARD_RESULT = "GETMERCHANTCARD_RESULT";

export const GETADSFOROWNER = "GETADSFOROWNER";
export const GETADSFOROWNER_RESULT = "GETADSFOROWNER_RESULT";
export const GETADSFOROWNER_ERROR = "GETADSFOROWNER_ERROR";

export const DELETEADS = "DELETEADS";
export const DELETEADS_RESULT = "DELETEADS_RESULT";
export const DELETEADS_ERROR = "DELETEADS_ERROR";

export const GETMOTORSFORMAKEMODELFUELBODY = "GETMOTORSFORMAKEMODELFUELBODY";
export const GETMOTORSFORMAKEMODELFUELBODY_RESULT =
  "GETMOTORSFORMAKEMODELFUELBODY_RESULT";
export const GETMOTORSFORMAKEMODELFUELBODY_ERROR =
  "GETMOTORSFORMAKEMODELFUELBODY_ERROR";

export const GETBODYTYPEFORLOCALE = "GETBODYTYPEFORLOCALE";
export const GETBODYTYPEFORLOCALE_RESULT = "GETBODYTYPEFORLOCALE_RESULT";
export const GETBODYTYPEFORLOCALE_ERROR = "GETBODYTYPEFORLOCALE_ERROR";

export const GETCONDITIONFORLOCALE = "GETCONDITIONFORLOCALE";
export const GETCONDITIONFORLOCALE_RESULT = "GETCONDITIONFORLOCALE_RESULT";
export const GETCONDITIONFORLOCALE_ERROR = "GETCONDITIONFORLOCALE_ERROR";

export const GETUPHOLSTERYFORLOCALE = "GETUPHOLSTERYFORLOCALE";
export const GETUPHOLSTERYFORLOCALE_RESULT = "GETUPHOLSTERYFORLOCALE_RESULT";
export const GETUPHOLSTERYFORLOCALE_ERROR = "GETUPHOLSTERYFORLOCALE_ERROR";

export const GETINTERIORCOLORFORLOCALE = "GETINTERIORCOLORFORLOCALE";
export const GETINTERIORCOLORFORLOCALE_RESULT =
  "GETINTERIORCOLORFORLOCALE_RESULT";
export const GETINTERIORCOLORFORLOCALE_ERROR =
  "GETINTERIORCOLORFORLOCALE_ERROR";

export const GETTRANSMISSIONFORLOCALE = "GETTRANSMISSIONFORLOCALE";
export const GETTRANSMISSIONFORLOCALE_RESULT =
  "GETTRANSMISSIONFORLOCALE_RESULT";
export const GETTRANSMISSIONFORLOCALE_ERROR = "GETTRANSMISSIONFORLOCALE_ERROR";

export const GETBODYCOLORFORLOCALE = "GETBODYCOLORFORLOCALE";
export const GETBODYCOLORFORLOCALE_RESULT = "GETBODYCOLORFORLOCALE_RESULT";
export const GETBODYCOLORFORLOCALE_ERROR = "GETBODYCOLORFORLOCALE_ERROR";

export const GETCARDATAFORMOTORID = "GETCARDATAFORMOTORID";
export const GETCARDATAFORMOTORID_RESULT = "GETCARDATAFORMOTORID_RESULT";
export const GETCARDATAFORMOTORID_ERROR = "GETCARDATAFORMOTORID_ERROR";

export const GETADSFORIDS = "GETADSFORIDS";
export const GETADSFORIDS_RESULT = "GETADSFORIDS_RESULT";
export const GETADSFORIDS_ERROR = "GETADSFORIDS_ERROR";

export const GETDOORS = "GETDOORS";
export const GETDOORS_RESULT = "GETDOORS_RESULT";
export const GETDOORS_ERROR = "GETDOORS_ERROR";

export const GETSEATS = "GETSEATS";
export const GETSEATS_RESULT = "GETSEATS_RESULT";
export const GETSEATS_ERROR = "GETSEATS_ERROR";

export const GETEURONORM = "GETEURONORM";
export const GETEURONORM_RESULT = "GETEURONORM_RESULT";
export const GETEURONORM_ERROR = "GETEURONORM_ERROR";

export const GETTRAILERHITCH = "GETTRAILERHITCH";
export const GETTRAILERHITCH_RESULT = "GETTRAILERHITCH_RESULT";
export const GETTRAILERHITCH_ERROR = "GETTRAILERHITCH_ERROR";

export const GETENERGYEFFICIENCE = "GETENERGYEFFICIENCE";
export const GETENERGYEFFICIENCE_RESULT = "GETENERGYEFFICIENCE_RESULT";
export const GETENERGYEFFICIENCE_ERROR = "GETENERGYEFFICIENCE_ERROR";

export const GETVEHICLESCOUNTBYBODYTYPE = "GETVEHICLESCOUNTBYBODYTYPE";
export const GETVEHICLESCOUNTBYBODYTYPE_RESULT =
  "GETVEHICLESCOUNTBYBODYTYPE_RESULT";
export const GETVEHICLESCOUNTBYBODYTYPE_ERROR =
  "GETVEHICLESCOUNTBYBODYTYPE_ERROR";

export const REPORTAD = "REPORTAD";
export const REPORTAD_RESULT = "REPORTAD_RESULT";
export const REPORTAD_ERROR = "REPORTAD_ERROR";

export const FETCHEPAPER = "FETCHEPAPER";
export const FETCHEPAPER_RESULT = "FETCHEPAPER_RESULT";
export const FETCHEPAPER_ERROR = "FETCHEPAPER_ERROR";

export const GETCARSOFTHEDAY = "GETCARSOFTHEDAY";
export const GETCARSOFTHEDAY_RESULT = "GETCARSOFTHEDAY_RESULT";
export const GETCARSOFTHEDAY_ERROR = "GETCARSOFTHEDAY_ERROR";

export const GETDRIVETYPE = "GETDRIVETYPE";
export const GETDRIVETYPE_RESULT = "GETDRIVETYPE_RESULT";
export const GETDRIVETYPE_ERROR = "GETDRIVETYPE_ERROR";

export const FETCHARTICLES = "FETCHARTICLES";
export const FETCHARTICLES_RESULT = "FETCHARTICLES_RESULT";
export const FETCHARTICLES_ERROR = "FETCHARTICLES_ERROR";

export const GETCYLINDERS = "GETCYLINDERS";
export const GETCYLINDERS_RESULT = "GETCYLINDERS_RESULT";
export const GETCYLINDERS_ERROR = "GETCYLINDERS_ERROR";

export const GETTRAILERLOAD = "GETTRAILERLOAD";
export const GETTRAILERLOAD_RESULT = "GETTRAILERLOAD_RESULT";
export const GETTRAILERLOAD_ERROR = "GETTRAILERLOAD_ERROR";

export const GETOPT = "GETOPT";
export const GETOPT_RESULT = "GETOPT_RESULT";
export const GETOPT_ERROR = "GETOPT_ERROR";

export const GETMERCHANT2 = "GETMERCHANT2";
export const GETMERCHANT2_RESULT = "GETMERCHANT2_RESULT";
export const GETMERCHANT2_ERROR = "GETMERCHANT2_ERROR";

export const GETVEHICLESFORSERIALNO = "GETVEHICLESFORSERIALNO";
export const GETVEHICLESFORSERIALNO_RESULT = "GETVEHICLESFORSERIALNO_RESULT";
export const GETVEHICLESFORSERIALNO_ERROR = "GETVEHICLESFORSERIALNO_ERROR";

export const GETVEHICLESFORREGDOCNO = "GETVEHICLESFORREGDOCNO";
export const GETVEHICLESFORREGDOCNO_RESULT = "GETVEHICLESFORREGDOCNO_RESULT";
export const GETVEHICLESFORREGDOCNO_ERROR = "GETVEHICLESFORREGDOCNO_ERROR";

export const SET_TARGA_VEHICLE_TYPE = "SET_TARGA_VEHICLE_TYPE";

export const RESETERRORS18AND24 = "RESETERRORS18AND24";
export const RESETVEHICLEDATA18AND24 = "RESETVEHICLEDATA18AND24";

export const GETENGINETYPE = "GETENGINETYPE";
export const GETENGINETYPE_RESULT = "GETENGINETYPE_RESULT";
export const GETENGINETYPE_ERROR = "GETENGINETYPE_ERROR";

export const GETENVIRONMENTALSTICKER = "GETENVIRONMENTALSTICKER";
export const GETENVIRONMENTALSTICKER_RESULT = "GETENVIRONMENTALSTICKER_RESULT";
export const GETENVIRONMENTALSTICKER_ERROR = "GETENVIRONMENTALSTICKER_ERROR";
