import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "../../../../../components/button";
import clickableImage from "../../../../../assets/images/carousel-home/clickable-image.png";
import ArrowRightSvg from "../../../../../assets/shapes/double-arrow-right-icon.svg";
import stickerEn from "../../../../../assets/images/3-months-free/sticker-en.png";
import stickerDe from "../../../../../assets/images/3-months-free/sticker-de.png";
import stickerFr from "../../../../../assets/images/3-months-free/sticker-fr.png";
import stickerIt from "../../../../../assets/images/3-months-free/sticker-it.png";

import "./index.css";

const stickers = {
  1: stickerDe,
  4: stickerFr,
  7: stickerIt,
  2: stickerEn,
};

const FirstImageWithButton = (params) => {
  const { onClick, idlocale, isAuthorized, chooseType } = params;
  const history = useHistory();
  const { t, i18n } = useTranslation(["home"]);

  const handleButtonClick = () => {
    onClick();
    const path =
      isAuthorized && chooseType === 2
        ? `/${i18n.language}/sell/`
        : `/${i18n.language}/signup/`;
    history.push(path);
  };

  const selectedSticker = stickers[idlocale] || stickerDe;
  const link = isAuthorized
    ? `/${i18n.language}/sell/`
    : `/${i18n.language}/signup/`;

  return (
    <div className="image-container">
      <a href={`/${i18n.language}/contact-us/`}>
        <div className="image-sticker">
          <img src={selectedSticker} alt="Sticker" />
        </div>
      </a>
      <img src={clickableImage} alt="" />
      <a href={link} target="_blank">
        <Button
          click={(e) => {
            if (e.button === 1) {
              window.open(link, "_blank");
            } else if (e.button === 0) {
              e.preventDefault();
              handleButtonClick();
            }
          }}
          endicon={ArrowRightSvg}
          label={t("home:place-ad-now")}
        />
      </a>
    </div>
  );
};

const stateToProps = (state) => {
  const { idlocale } = state.car;
  const { isAuthorized, chooseType } = state.profile;
  return {
    idlocale,
    isAuthorized,
    chooseType,
  };
};

export default connect(stateToProps)(FirstImageWithButton);
