import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { sendSupportEmail } from "../../store/actions";
import Select from "../../components/select";
import FormControl from "../../components/form-control";
import Radio from "../../components/radio";
import Button from "../../components/button";
import { acceptOnlyNumbers, emailRegex } from "../../lib/utils";
import ScrollToTop from "../../lib/scroll-to-top";
import { TYPES_OF_MERCHANT } from "../../types";
import {
  getReasonForRequest,
  getCustomerGroup,
  getRequiredFields,
} from "./data";
import StepperVertical from "../../components/stepper-vertical";
import ThanksPage from "../../components/thanks-page";

import "./index.css";

const ContactUs = (props) => {
  const { dispatch, emailSuccess } = props;
  const { t } = useTranslation(["common", "contactUs", "footer", "auth"]);
  const reasonForRequest = getReasonForRequest();
  const customerGroup = getCustomerGroup();
  const [customer, setCustomer] = useState("");
  const [reasonRequest, setReasonRequest] = useState("");
  const [body, setBody] = useState({
    type: "",
    company_name: "",
    first_name: "",
    last_name: "",
    streetandnumber: "",
    zipcode: "",
    city: "",
    phone_number: "",
    email: "",
    description: "",
  });
  const [conditionsMet, setConditionsMet] = useState(false);
  const [step1Complete, setStep1Complete] = useState(false);
  const [step2Complete, setStep2Complete] = useState(false);
  const [step3Complete, setStep3Complete] = useState(false);
  const [step4Complete, setStep4Complete] = useState(false);
  const [conditionsMet2, setConditionsMet2] = useState(false);
  const [companySelected, setCompanySelected] = useState(false);
  const [companyNameError, setCompanyNameError] = useState("");
  const [streetAndNumberError, setStreetAndNumberError] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");
  const [cityError, setCityError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [emailError, setEmailError] = useState("");

  const checkConditionsForClasses = () => {
    const isStep1Complete =
      customer && customer.name && customer.name.trim() !== "";

    const isStep2Complete =
      isStep1Complete &&
      reasonRequest &&
      reasonRequest.name &&
      reasonRequest.name.trim() !== "";

    const isStep3Complete =
      isStep2Complete &&
      (body?.streetandnumber?.trim() !== "" ||
        body?.zipcode?.trim() !== "" ||
        body?.city?.trim() !== "" ||
        body?.phone_number?.trim() !== "" ||
        body?.email?.trim() !== "" ||
        body?.description?.trim() !== "");

    setStep1Complete(isStep1Complete);
    setStep2Complete(isStep2Complete);
    setStep3Complete(isStep3Complete);

    setConditionsMet2(isStep1Complete && isStep2Complete && isStep3Complete);
  };

  useEffect(() => {
    checkConditionsForClasses();
  }, [customer, reasonRequest, body]);

  const handleCustomerGroupChange = (event, newValue) => {
    setCustomer(customerGroup.find((group) => group.name === newValue.name));
  };

  const handleReasonForRequestChange = (event, newValue) => {
    setReasonRequest(
      reasonForRequest.find((request) => request.name === newValue.name)
    );
  };

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    const selectedType = parseInt(value);

    setBody((prevBody) => ({
      ...prevBody,
      [name]: selectedType,
    }));
    if (selectedType === TYPES_OF_MERCHANT.COMPANY) {
      setCompanySelected(true);
    } else {
      setCompanySelected(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (
      (name === "zipcode" || name === "phone_number") &&
      acceptOnlyNumbers(value)
    ) {
      setBody((prevBody) => ({
        ...prevBody,
        [name]: value,
      }));
    } else if (name !== "zipcode" && name !== "phone_number") {
      setBody((prevBody) => ({
        ...prevBody,
        [name]: value,
      }));
    }
  };

  const handleDescriptionChange = (e) => {
    const { value } = e.target;
    setBody((prevBody) => ({
      ...prevBody,
      description: value,
    }));
  };

  const checkConditions = () => {
    const requiredFields = getRequiredFields(
      t,
      customer,
      reasonRequest,
      body,
      companySelected
    );
    let errors = {};
    let isValid = true;

    requiredFields.forEach((field) => {
      if (!field.condition) {
        errors[field.key] = field.message;
        isValid = false;
      }
    });
    if (body.email === "") {
      errors.email = t("auth:email-is-required");
      isValid = false;
    } else if (!emailRegex.test(body.email)) {
      errors.email = t("auth:recover-password-warning");
      isValid = false;
    }

    setCompanyNameError(errors.company_name || "");
    setStreetAndNumberError(errors.streetandnumber || "");
    setZipCodeError(errors.zipcode || "");
    setCityError(errors.city || "");
    setPhoneNumberError(errors.phone_number || "");
    setEmailError(errors.email || "");

    setConditionsMet(isValid);
    return isValid;
  };

  const handleStep4Change = (checked) => {
    if (step3Complete) {
      setStep4Complete(checked);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const valid = checkConditions();
    if (!valid) {
      return;
    }
    const subject = `"Customer group:"${customer.name} - "Reason for request" ${reasonRequest.name}`;
    const bodyString = JSON.stringify(body);
    dispatch(sendSupportEmail(subject, bodyString));
  };

  return (
    <>
      {emailSuccess && conditionsMet ? (
        <>
          <ScrollToTop dependency={[emailSuccess]} />
          <ThanksPage
            texts={["contactUs:your-email-has-been-sent-successfully"]}
          />
        </>
      ) : (
        <div className="contact-us">
          <header>
            <h3>{t("contactUs:contact-us")}</h3>
          </header>
          <main>
            <p>{t("contactUs:contact-us-1")}</p>
            <form onSubmit={handleSubmit}>
              <div className="aside-progress-bar">
                <StepperVertical
                  stepsData={[{ step: 1 }, { step: 2 }, { step: 3 }]}
                  activePage={
                    step3Complete
                      ? 3
                      : step2Complete
                      ? 2
                      : step1Complete
                      ? 1
                      : 0
                  }
                />
                <div
                  className={`step-4-tunnel ${step4Complete ? "red" : ""}`}
                ></div>
              </div>
              <div className="form-sector">
                <div className="form-sector-item chose-customer">
                  <legend>{t("contactUs:chose-customer-group")}</legend>
                  <FormControl label={t("contactUs:customer-group")}>
                    <Select
                      placeholder={`${t("contactUs:choose")}...`}
                      data={customerGroup}
                      defaultValue={customerGroup.find((item) => {
                        return item.name === customer.name;
                      })}
                      handleChange={handleCustomerGroupChange}
                    />
                  </FormControl>
                </div>
                <div className="form-sector-item chose-reason">
                  <legend>
                    {t("contactUs:chose-your-reason-for-request")}
                  </legend>
                  <FormControl label={t("contactUs:reason-for-request")}>
                    <Select
                      placeholder={`${t("contactUs:choose")}...`}
                      data={reasonForRequest}
                      defaultValue={reasonForRequest.find((item) => {
                        return item.name === reasonRequest.name;
                      })}
                      handleChange={handleReasonForRequestChange}
                    />
                  </FormControl>
                </div>
                <div className="form-sector-item contact-options">
                  <legend>{t("contactUs:your-contact-options")}</legend>
                  <div className="contact-type">
                    <FormControl className={"d-flex"}>
                      <Radio
                        name={"type"}
                        value={TYPES_OF_MERCHANT.MR}
                        checked={body.type === TYPES_OF_MERCHANT.MR}
                        className={"radio-button-target"}
                        label={t("profile:mr")}
                        changeHandler={handleRadioChange}
                      />
                      <Radio
                        name={"type"}
                        value={TYPES_OF_MERCHANT.MS}
                        checked={body.type === TYPES_OF_MERCHANT.MS}
                        className={"radio-button-target"}
                        label={t("profile:ms")}
                        changeHandler={handleRadioChange}
                      />
                      <Radio
                        name={"type"}
                        value={TYPES_OF_MERCHANT.COMPANY}
                        checked={body.type === TYPES_OF_MERCHANT.COMPANY}
                        className={"radio-button-target"}
                        label={t("footer:company")}
                        changeHandler={handleRadioChange}
                      />
                    </FormControl>
                  </div>
                  <div className="name-and-address">
                    <FormControl
                      inputType="textfield"
                      name="company_name"
                      label={t("contactUs:company-name")}
                      type="text"
                      handleChange={handleInputChange}
                      errorMessage={companyNameError ? companyNameError : ""}
                      errorMessageClassName={"color-red"}
                    />
                    <FormControl
                      inputType="textfield"
                      name="first_name"
                      label={t("profile:first-name")}
                      type="text"
                      handleChange={handleInputChange}
                    />
                    <FormControl
                      inputType="textfield"
                      name="last_name"
                      label={t("profile:last-name")}
                      type="text"
                      handleChange={handleInputChange}
                    />
                    <FormControl
                      inputType="textfield"
                      label={t("profile:streetandnumber")}
                      name="streetandnumber"
                      type="text"
                      handleChange={handleInputChange}
                      errorMessage={
                        streetAndNumberError ? streetAndNumberError : ""
                      }
                      errorMessageClassName={"color-red"}
                    />
                  </div>
                  <div className="zip-city">
                    <FormControl
                      inputType="textfield"
                      label={t("profile:zip-code")}
                      name="zipcode"
                      type="text"
                      handleChange={handleInputChange}
                      value={body.zipcode || ""}
                      errorMessage={zipCodeError ? zipCodeError : ""}
                      errorMessageClassName={"color-red"}
                    />
                    <FormControl
                      inputType="textfield"
                      label={t("profile:place")}
                      name="city"
                      type="text"
                      handleChange={handleInputChange}
                      errorMessage={cityError ? cityError : ""}
                      errorMessageClassName={"color-red"}
                    />
                  </div>
                  <div className="phone-email">
                    <FormControl
                      inputType="textfield"
                      label={t("profile:phone-num-example")}
                      name="phone_number"
                      type="text"
                      handleChange={handleInputChange}
                      value={body.phone_number || ""}
                      errorMessage={phoneNumberError ? phoneNumberError : ""}
                      errorMessageClassName={"color-red"}
                    />
                    <FormControl
                      inputType="textfield"
                      label={t("profile:email-address")}
                      name="email"
                      type="text"
                      handleChange={handleInputChange}
                      value={body.email || ""}
                      errorMessage={emailError ? emailError : ""}
                      errorMessageClassName={"color-red"}
                    />
                  </div>
                  <div className="textarea">
                    <label>{t("common:message")}</label>
                    <textarea
                      name="description"
                      id="description"
                      onChange={handleDescriptionChange}
                    ></textarea>
                  </div>
                  <div className="checkbox-and-button">
                    <FormControl
                      inputType="checkbox"
                      name={"acceptance"}
                      className={"radio-button-target"}
                      label={`${t(
                        "contactUs:accept-data-protection-regulations-of"
                      )}CARINDEX AG`}
                      checked={step4Complete}
                      handleChange={(e) => handleStep4Change(e.target.checked)}
                    />
                    <Button
                      type="submit"
                      color="red"
                      label={t("reportAd:send")}
                      disabled={!step4Complete}
                    />
                  </div>
                </div>
              </div>
            </form>
          </main>
        </div>
      )}
    </>
  );
};

const stateToProps = (state) => {
  const { emailSuccess } = state.common;
  return { emailSuccess };
};

export default connect(stateToProps)(ContactUs);
