import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Box } from "@material-ui/core";
import { USER_TYPES } from "../../../types";
import Button from "../../../components/button";
import "./index.css";

const ChooseUser = (props) => {
  const { title, description, setChoosenType, isOnRegister } = props;
  const { t, i18n } = useTranslation("auth");
  const history = useHistory();

  //this is termporary request
  const goToContactUsPage = () => {
    history.push(`/${i18n.language}/contact-us/`);
  };

  return (
    <div className="chooseuser-container">
      <Box className="header">
        <h5>{title}</h5>
        <p>{description}</p>
      </Box>
      <Box className="choosetype-buttons">
        <Button
          color="red"
          label={t("auth:user")}
          className="type-user"
          click={() => setChoosenType(USER_TYPES.STANDARD)}
        />
        <Button
          color="red"
          label={t("auth:seller")}
          className="type-seller"
          click={
            !!isOnRegister
              ? goToContactUsPage
              : () => setChoosenType(USER_TYPES.MERCHANT)
          }
        />
      </Box>
    </div>
  );
};

export default ChooseUser;
