import { useTranslation } from "react-i18next";
import { sortByAlphabet } from "../lib/utils";

const useCustomTranslation = () => {
  const { i18n } = useTranslation();
  const selectedLanguage = i18n.language;
  const fallbackLanguage = i18n.translator.options.fallbackLng[0];
  const isDefined = (value) => ![null, undefined, ""].includes(value);
  const translateArray = (array = []) => {
    const formattedArray = array.map((item) => {
      let newName = item.name[selectedLanguage];
      if (!isDefined(newName)) {
        newName = item.name[fallbackLanguage];
        if (!isDefined(newName)) {
          newName = "-";
        }
      }
      return {
        ...item,
        originalName: item.name,
        name: newName,
      };
    });
    return sortByAlphabet(formattedArray, "asc", "name");
  };
  return { translateArray };
};

export default useCustomTranslation;
