import { combineReducers } from "redux";
import car from "./car/reducers";
import common from "./common/reducers";
import profile from "./profile/reducers";
import socket from "./socket/reducers";
import listeners from "./comm/listener";
import soketInstance from "../lib/ws-client";

listeners(soketInstance);

export default combineReducers({
  car,
  common,
  profile,
  socket,
});
