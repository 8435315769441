import React from "react";
import { useTranslation } from "react-i18next";
import Radio from "../radio/index";
import "./index.css";
//.up-selected
const ColPackage = (props) => {
  const { uPackage, checked, type } = props;
  const { t, i18n } = useTranslation();

  const upSmallContent = (
    <Radio
      name={type}
      checked={checked}
      value={type}
      // changeHandler={ () => changeHandler(pIndex) }
      label={
        <div className={`up-small-content ${checked ? "up-selected" : ""}`}>
          <div>
            <span>
              <h4>{uPackage.type}</h4>
              <h6>{t("profile:package")}</h6>
            </span>
            <span>
              <h6>{t("common:price")}:</h6>
              <h4>{uPackage.price}</h4>
            </span>
          </div>
          <div>
            <ul>
              {uPackage.items.map((item, index) => {
                if (index <= 4) {
                  return <li key={index}>{item}</li>;
                }
              })}
            </ul>
            {uPackage?.items?.length > 4 && (
              <ul>
                {uPackage.items.map((item, index) => {
                  if (index > 4) {
                    return <li key={index}>{item}</li>;
                  }
                })}
              </ul>
            )}
          </div>
        </div>
      }
    />
  );

  const upBigContent = (
    <Radio
      name={type}
      checked={checked}
      value={type}
      // changeHandler={ () => changeHandler(pIndex) }
      label={
        <div className={`up-big-content ${checked ? "up-selected" : ""}`}>
          <div>
            <h4>{uPackage.type}</h4>
            <h6>{t("profile:package")}</h6>
          </div>
          <div>
            <ul>
              {uPackage.items.map((item, index) => {
                if (index <= 4) {
                  return <li key={index}>{item}</li>;
                }
              })}
            </ul>
            {uPackage?.items?.length > 4 && (
              <ul>
                {uPackage.items.map((item, index) => {
                  if (index > 4) {
                    return <li key={index}>{item}</li>;
                  }
                })}
              </ul>
            )}
          </div>
          <div>
            <h6>{t("common:price")}:</h6>
            <h4>{uPackage.price}</h4>
          </div>
        </div>
      }
    />
  );

  return (
    <div className="user-package">
      <div className="up-small">{upSmallContent}</div>
      <div className="up-big">{upBigContent}</div>
    </div>
  );
};

export default ColPackage;
