import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import "./index.css";

const Stepper = (props) => {
  const { stepsData, activePage, adCreated, uploadFinished } = props;
  const checked = (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className="checked-icon"
    >
      <path fill="none" d="M0 0H20V20H0z" />
      <path
        fill="#fff"
        d="M4.455 7.91L1.114 4.776 0 5.821 4.455 10 14 1.045 12.886 0z"
        transform="translate(3 5)"
      />
    </SvgIcon>
  );

  return (
    <div className="stepper">
      <div className="steps">
        {stepsData.map((item, index) => {
          return item.step === 1 ? (
            <span
              key={index}
              className={`circle ${
                activePage >= item.step ? "active-circle" : "inactive-circle"
              }`}
            >
              {activePage > item.step ? checked : item.step}
            </span>
          ) : (
            <div key={index} className="step">
              <span
                className={`tunnel ${
                  activePage >= item.step ? "active-tunnel" : "inactive-tunnel"
                }`}
              ></span>
              <span
                className={`circle ${
                  activePage >= item.step ? "active-circle" : "inactive-circle"
                }`}
              >
                {activePage > item.step ||
                (activePage > 6 && adCreated) ||
                (activePage > 6 && uploadFinished)
                  ? checked
                  : item.step}
              </span>
            </div>
          );
        })}
      </div>
      <div className="labels">
        {stepsData.map((item, index) => {
          return (
            <p
              key={index}
              className={
                activePage >= item.step ? "active-label" : "inactive-label"
              }
            >
              {item.label}
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default Stepper;
