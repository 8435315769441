import React, { useEffect, useState, useMemo } from "react";
import { Prompt, useParams, useHistory } from "react-router-dom";
import { flushSync } from "react-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  clearData,
  getAdById,
  resetSelectedAd,
  getCarModel,
  getMotorsForMakeModelFuelBody,
  getBodyColorForLocale,
  getInteriorColorForLocale,
  getSeats,
  getUpholsteryForLocale,
  getConditionForLocale,
  getDriveType,
  getTrailerHitch,
  getEuroNorm,
  getTrailerLoad,
  getEngineType,
  getEmissionSticker,
} from "../../store/actions";
import NotFound from "./../../screens/not-found";
import { currentYear } from "../../data";
import { backIcon, Share, Phone, Star } from "../../components/svg-icons/index";
import {
  getPropName,
  getPropShortName,
  getFavoritesIds,
  toggleFavorites,
  numberWithCommas,
  kwToPs,
  formatPhoneNumber,
  isEmptyObject,
} from "../../lib/utils";
import SEO from "../../components/seo/index";
import { USER_TYPES } from "../../types";
import Button from "../../components/button/index";
import Carousel from "../../components/carousel/index";
import SimpleLoader from "../../components/simple-loader";
import Equipment from "./components/equipments";
import VehicleAddress from "../../components/cards/result/components/vehicle-address";
import Flag from "../../assets/images/flagOfSwitzerland/flag1.png";
import { ArrowUpRed, ArrowDownRed } from "../../components/svg-icons/index";
import RegistrationIcon from "../../assets/images/ad-props-icons/Registration.svg";
import KmIcon from "../../assets/images/ad-props-icons/Km.svg";
import Power from "../../assets/images/ad-props-icons/Power.svg";
import Fuel from "../../assets/images/ad-props-icons/fuel.svg";
import Transsmision from "../../assets/images/ad-props-icons/transsmision.svg";
import Drivetrain from "../../assets/images/ad-props-icons/drivetrain.svg";
import AdBanner from "./components/ad-banner";
import { useInitialLoading } from "../../hooks";
import { handleShareDeepLinking } from "../../lib/deep-link";
import { ContactFormDialog } from "../../components/contact-form-dialog";
import "./index.css";

const AdDetails = (props) => {
  const {
    dispatch,
    make,
    car_model,
    body_type,
    fuel_type,
    drive_type,
    ad,
    loading,
    phoneNumber,
    adOwnerData,
    motors,
    idlocale,
    bodyColors,
    interiorcolor,
    upholstery,
    condition,
    transmission,
    energyEfficience,
    seats,
    euronorm,
    chooseType,
    trailerload,
    enginetype,
    emissionsticker,
  } = props;
  const { t, i18n } = useTranslation([
    "common",
    "adDetails",
    "sell",
    "reportAd",
    "profile",
    "advancedSearch",
  ]);
  const { loading: initialLoading } = useInitialLoading();
  const current_year = currentYear;
  const [sliderPosition, setSliderPosition] = useState(0);
  const [adData, setAdData] = useState({});
  const [viewNumber, setViewNumber] = useState(true);
  const adOwnerAddress = adOwnerData?.address_data;
  const params = useParams();
  const history = useHistory();
  const [favoriteIds, setFavoriteIds] = useState(null);
  const [collapseDetails, setCollapseDetails] = useState(true);
  const [showPrompt, setShowPrompt] = useState(false);
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);
  const [isCheckingAdData, setIsCheckingAdData] = useState(true);

  const equipmentLine = useMemo(() => {
    if (adData?.generaldata?.equipmentline) {
      const equipmentLineValue = motors?.filter((item) => {
        if (item.idmotor === Number(adData?.generaldata?.equipmentline))
          return item;
      });
      if (equipmentLineValue?.length > 0) {
        return equipmentLineValue[0].name;
      }
      return adData?.generaldata?.equipmentline;
    }
    return null;
  }, [adData?.generaldata?.equipmentline, motors]);
  const powerInKW = adData?.generaldata?.power;
  const powerInPS = kwToPs(powerInKW);
  //Preview data
  const NA = "-";
  const PS = " PS";
  //const KW = " Kw";
  const CO2 = "g CO₂/km (comb)*";
  const KG = " kg";
  const vehiclelocation = ad?.vehiclelocation;

  const makename =
    make.length > 0 ? getPropName(make, adData?.idmake, "idmake") : NA;
  const modelname =
    car_model.length > 0
      ? getPropName(car_model, adData?.idmodel, "idmodel")
      : NA;
  const fueltype =
    fuel_type.length > 0
      ? getPropName(fuel_type, adData?.idfueltype, "idfueltype")
      : NA;
  const firstregistration =
    adData?.month < 10
      ? `0${adData.month}/${adData.year}`
      : `${adData?.month}/${adData?.year}`;
  const transmissionname = useMemo(() => {
    if (adData?.engineenvironment?.idtransmission) {
      const transmissionname = transmission.find(
        (item) =>
          item.idtransmission ===
          Number(adData.engineenvironment.idtransmission)
      );
      return transmissionname?.name;
    }
    return null;
  }, [adData?.engineenvironment?.idtransmission, transmission]);

  //OG title and image
  const adTitle = `${getPropName(make, adData?.idmake, "idmake")} ${getPropName(
    car_model,
    adData?.idmodel,
    "idmodel"
  )}`;
  const adImage = adData?.photos?.length > 0 && adData?.photos?.[0];

  const formattedPhoneNumber = phoneNumber
    ? formatPhoneNumber(phoneNumber)
    : "";

  const handleThumnailClick = (imageIndex) => {
    let position = 0;
    if (imageIndex === 2) {
      position = 100;
    }
    setSliderPosition(position);
  };

  const handleLinkClick = (event) => {
    if (event.button === 1 || event.metaKey || event.ctrlKey) {
      return;
    }
    event.preventDefault();
    setIsContactFormOpen(true);
  };

  const handleNext = () => {
    if (adData?.photos?.length > 0) {
      const lastPosition = (adData.photos.length - 1) * 100;
      sliderPosition === lastPosition
        ? setSliderPosition(0)
        : setSliderPosition(sliderPosition + 100);
    }
  };
  const handlePrevious = () => {
    if (adData?.photos?.length > 0) {
      const lastPosition = (adData.photos.length - 1) * 100;
      sliderPosition === 0
        ? setSliderPosition(lastPosition)
        : setSliderPosition(sliderPosition - 100);
    }
  };

  const backToPreviousPage = () => {
    flushSync(() => {
      setShowPrompt(false);
    });
    const previewAd = localStorage.getItem("previewAd");
    if (previewAd) {
      history.goBack();
    } else {
      localStorage.setItem("previewAd", true);
      history.push(`/${i18n.language}/results/`);
    }
  };

  const goToReportAd = () => {
    const { idad } = params;
    history.push(`/${i18n.language}/ad/${idad}/report`);
  };

  /**
   *-----------------------------------------------
   * TOGGLE FAVORITE ADS
   *-----------------------------------------------
   * @param { Number } idad
   */
  const toggleFavoritesHandler = (idad) => {
    toggleFavorites(idad);
    const favorites = getFavoritesIds();
    setFavoriteIds(favorites);
  };

  const fromResultsPage = (idad) => {
    const activePage = history?.location?.state?.activePage;
    const paginationStep = history?.location?.state?.paginationStep;
    const sortLabel = history?.location?.state?.sortLabel;
    if (activePage) {
      localStorage.setItem("activePage", activePage);
    }
    if (paginationStep) {
      localStorage.setItem("paginationStep", paginationStep);
    }
    if (sortLabel) {
      localStorage.setItem("sortLabel", sortLabel);
    }
    dispatch(getAdById(idad));
  };

  const fromSellPage = () => {
    localStorage.setItem("activePage", props.history.location.state.activePage);
    localStorage.setItem("procedeToMakePage", true);
  };

  useEffect(() => {
    dispatch(getBodyColorForLocale());
    dispatch(getInteriorColorForLocale());
    const idad = parseInt(params.idad);
    const fIds = localStorage.getItem("fav_ids");
    if (fIds !== null) {
      setFavoriteIds(fIds);
    }
    if (idad > 0) {
      fromResultsPage(idad);
    }
    if (!idad) {
      const { adData } = history.location.state;
      if (adData?.photos?.length > 0) {
        const newPhotos = adData?.photos.filter((photo) => photo);
        adData.photos = newPhotos;
      }
      setAdData(adData);
      fromSellPage();
    }
    setShowPrompt(history?.location?.state?.showPrompt ? true : false);
    return () => {
      dispatch(resetSelectedAd());
      setAdData([]);
      dispatch(clearData(["adOwnerData", "addressDetails"]));
      // Reset SEO tags
      <SEO
        title="CARINDEX by AUTOMOBIL REVUE - Autos kaufen und verkaufen in der Schweiz"
        description="CARINDEX by AUTOMOBIL REVUE - Autos kaufen und verkaufen in der Schweiz"
        image="https://carindex.ch/df3375508235ea2a2f9c6c53c41e192e.png"
        url={window.location.href}
      />;
    };
  }, []);

  useEffect(() => {
    if (ad && params.idad > 0) {
      if (ad?.photos?.length > 0) {
        const newPhotos = ad.photos.filter((photo) => photo);
        ad.photos = newPhotos;
      }
      setAdData(ad);
    }
  }, [ad]);

  useEffect(() => {
    if (adData.idmake) {
      dispatch(getCarModel(adData.idmake, null, null, false));
    }
  }, [adData]);

  useEffect(() => {
    const { idmake, idmodel, idfueltype, idbodytype } = adData;
    if (idmake && idmodel && idfueltype && idbodytype) {
      dispatch(
        getMotorsForMakeModelFuelBody([idmake, idmodel, idfueltype, idbodytype])
      );
    }
  }, [adData?.idmake, adData?.idmodel, adData?.idfueltype, adData.idbodytype]);

  useEffect(() => {
    dispatch(getBodyColorForLocale());
    dispatch(getInteriorColorForLocale());
    dispatch(getConditionForLocale());
    dispatch(getUpholsteryForLocale());
    dispatch(getDriveType());
    dispatch(getTrailerHitch());
    // dispatch(getEngineType()); // we only have EN in db
    // dispatch(getEmissionSticker()); // we only have EN in db
  }, [idlocale]);

  useEffect(() => {
    dispatch(getSeats());
    dispatch(getUpholsteryForLocale());
    dispatch(getBodyColorForLocale());
    dispatch(getInteriorColorForLocale());
    dispatch(getConditionForLocale());
    dispatch(getDriveType());
    dispatch(getTrailerHitch());
    dispatch(getEuroNorm());
    dispatch(getTrailerLoad());
    dispatch(getEngineType());
    dispatch(getEmissionSticker());
  }, []);

  // Check adData once loading is complete
  useEffect(() => {
    if (!loading && !initialLoading) {
      if (!adData || isEmptyObject(adData)) {
        setIsCheckingAdData(false);
      } else {
        setIsCheckingAdData(false);
      }
    }
  }, [loading, initialLoading, adData]);

  if (loading || initialLoading || isCheckingAdData) {
    return <SimpleLoader loading={true} className="addetails" />;
  }

  if (!adData || isEmptyObject(adData)) {
    return <NotFound />;
  }

  return (
    <SimpleLoader loading={loading && initialLoading} className="addetails">
      <SEO title={adTitle} image={adImage} url={window.location.href} />
      {showPrompt && <Prompt message={() => t("common:prompt-leave-page")} />}
      <header className="ad-details-header">
        <div className="go-back" onClick={backToPreviousPage}>
          {backIcon}
          <p className="no-margin flex">{t("adDetails:back-to")}</p>
        </div>
        {/* waiting for backend */}
        {/* <div className="slider-controls">
          <IteratorButton
            label={t("adDetails:previous-car")}
            direction="previous"
            handler={handlePrevious}
            disabled={!adData?.photos?.length || adData?.photos?.length < 2}
          />
          <IteratorButton
            label={t("adDetails:next-car")}
            direction="next"
            handler={handleNext}
            disabled={!adData?.photos?.length || adData?.photos?.length < 2}
          />
        </div> */}
      </header>
      <div className="carousel">
        <Carousel
          images={adData?.photos}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          handleThumbClick={handleThumnailClick}
          position={sliderPosition}
        />
      </div>
      <main className="ad-details-main">
        <div className="base-info">
          <div className="controls">
            <div className="price-and-info">
              <Button
                className="ad-details-price-btn"
                label={`CHF ${numberWithCommas(adData?.price)}.-`}
                color="white"
              />
              {typeof variant === "undefined" &&
              equipmentLine === null &&
              adData?.headline === "" ? (
                <span className="car-name-and-details">
                  <h2 className="car-name-centered">{`${makename} ${modelname}`}</h2>{" "}
                </span>
              ) : (
                <span className="car-name-and-details">
                  <h2 className="car-name">{`${makename} ${modelname}`}</h2>
                  <h2 className="car-details">
                    {adData?.generaldata?.variant ?? null}
                    {equipmentLine && ` ${equipmentLine}`}
                    {adData?.headline && ` ${adData?.headline}`}
                  </h2>
                </span>
              )}
            </div>
            {params?.idad && (
              <span
                onClick={() => handleShareDeepLinking(params.idad)}
                className="flex-col-wrapper medium-gap flex-align-center favsshare"
              >
                <Share color="#969696" />
                <Star
                  color={
                    favoriteIds?.includes(adData.idad) ? "#cc2c24" : "#969696"
                  }
                  click={() => toggleFavoritesHandler(adData.idad)}
                />
              </span>
            )}
          </div>
        </div>
        <div className="base-description">
          <div>
            <span>
              <img src={RegistrationIcon} />
              <p>{firstregistration}</p>
            </span>
            <span>
              <img src={KmIcon} />
              <p>{numberWithCommas(adData?.mileage)} km</p>
            </span>
            <span>
              <img src={Power} />{" "}
              <p>
                {powerInPS ? powerInPS + PS : NA}
                <span className="power-kw-label">({powerInKW} KW)</span>
              </p>
            </span>
            <span>
              <img src={Fuel} />
              <p>{fueltype}</p>
            </span>
            <span>
              <img src={Transsmision} />
              <p>{transmissionname ?? NA}</p>
            </span>
            <span>
              <img src={Drivetrain} />
              <p>
                {adData?.engineenvironment?.iddrivetype > 0
                  ? getPropName(
                      drive_type,
                      adData.engineenvironment.iddrivetype,
                      "iddrivetype"
                    )
                  : NA}
                <span>
                  {adData?.engineenvironment?.iddrivetype > 0
                    ? getPropShortName(
                        drive_type,
                        adData.engineenvironment.iddrivetype,
                        "iddrivetype"
                      )
                    : NA}
                </span>
              </p>
            </span>
          </div>
        </div>
        <div className="seller-details ad-details-grid">
          <div className="flex-row-wrapper medium-gap">
            <h2 className="details-heading">{t("adDetails:seller-details")}</h2>
            <span>
              {adOwnerData ? (
                <>
                  <p className="no-margin">
                    {adOwnerData
                      ? adOwnerData?.type === 2
                        ? adOwnerData?.company
                        : adOwnerData?.name
                      : t("profile:your-name")}
                  </p>
                  <p className="no-margin">{adOwnerAddress?.streetandnumber}</p>
                  <p className="no-margin">
                    {adOwnerAddress?.zipcode} {adOwnerData?.city}{" "}
                    {adOwnerAddress?.region}
                  </p>
                </>
              ) : (
                <p>
                  {t("profile:name")} & {t("profile:address-location")}
                </p>
              )}
            </span>
          </div>
          <div className="contact-tools">
            {/* <span className="contact-box ">
              <h5>{t("adDetails:direct-chat-with-seller")}</h5>
              {Chat}
            </span> */}
            <span
              className="contact-box-phone"
              onClick={() => setViewNumber(!viewNumber)}
            >
              {viewNumber ? (
                <span className="call-seller-with-svg">
                  <h5>{t("adDetails:call-seller")}</h5>
                  {Phone}
                </span>
              ) : (
                <span className="center-phone-number">
                  {phoneNumber ? (
                    <a href={formattedPhoneNumber}>
                      <h5>{phoneNumber}</h5>
                    </a>
                  ) : (
                    <h5>{t("profile:contact-phone")}</h5>
                  )}
                </span>
              )}
            </span>
          </div>
          <div className="contact-links">
            <a href={`${i18n.language}/seller/${adData.uidowner}`}>
              {t("adDetails:view-other-vehilces")}
            </a>
            <a
              href={`https://www.google.com/maps?q=${ad?.vehiclelocation?.location?.latitude},${ad?.vehiclelocation?.location?.longitude}&z=15`}
              target="_blank"
              rel="noreferrer"
            >
              {t("adDetails:view-location-on-map")}
            </a>
          </div>
        </div>
        <div className="vehicle-details">
          <h2 className="details-heading">{t("common:vehicle-details")}:</h2>
          <div
            className={`${
              collapseDetails ? "vd-collapsed" : "vd-expand"
            } details-data letter-spacing-for-details`}
          >
            <span>
              <p>{t("common:condition")}</p>
              <p>
                {adData?.vehicledata?.idcondition > 0
                  ? getPropName(
                      condition,
                      adData.vehicledata.idcondition,
                      "idcondition"
                    )
                  : NA}
              </p>
            </span>
            <span>
              <p>{t("common:engine-type")}</p>
              <p>
                {adData?.engineenvironment?.idenginetype > 0
                  ? getPropName(
                      enginetype,
                      adData.engineenvironment.idenginetype,
                      "idenginetype"
                    )
                  : NA}
              </p>
            </span>
            <span>
              <p>{t("advancedSearch:trailer-hitch")}</p>
              <p>
                {adData?.vehicledata?.idtrailerhitch
                  ? t("sell:yes")
                  : t("common:no")}
              </p>
            </span>
            <span>
              <p>{t("common:body-type")}</p>
              <p>
                {body_type.length > 0 &&
                  getPropName(body_type, adData?.idbodytype, "idbodytype")}
              </p>
            </span>
            <span>
              <p>{t("common:engine-size")}</p>
              <p>
                {adData?.engineenvironment?.enginesize > 0
                  ? adData?.engineenvironment?.enginesize
                  : NA}
              </p>
            </span>
            <span>
              <p>{t("common:trailer-load")}</p>
              <p>
                {adData?.vehicledata?.trailerload > 0
                  ? `${getPropName(
                      trailerload,
                      adData?.vehicledata?.trailerload,
                      "idtrailerload"
                    )} kg`
                  : "-"}
              </p>
            </span>
            <span>
              <p>{t("common:body-color")}</p>
              <p>
                {adData?.generaldata?.idbodycolor > 0
                  ? getPropName(
                      bodyColors,
                      adData?.generaldata?.idbodycolor,
                      "idbodycolor"
                    )
                  : adData?.generaldata?.bodycolor !== null &&
                    adData?.generaldata?.bodycolor?.length > 0
                  ? adData?.generaldata?.bodycolor
                  : NA}
              </p>
            </span>
            <span>
              <p>{t("common:cylinder")}</p>
              <p>
                {adData?.engineenvironment?.cylinder > 0
                  ? adData?.engineenvironment?.cylinder
                  : NA}
              </p>
            </span>
            <span>
              <p>{`${t("common:from")} MFK`}</p>
              <p>
                {adData?.conditionmaintenance?.frommfk
                  ? t("sell:yes")
                  : t("common:no")}
              </p>
            </span>
            <span>
              <p>{t("common:interior-color")}</p>
              <p>
                {adData?.vehicledata?.idinteriorcolor > 0
                  ? getPropName(
                      interiorcolor,
                      adData?.vehicledata?.idinteriorcolor,
                      "idinteriorcolor"
                    )
                  : adData?.vehicledata?.interiorcolor !== null &&
                    adData?.vehicledata?.interiorcolor?.length > 0
                  ? adData?.vehicledata?.interiorcolor
                  : NA}
              </p>
            </span>
            <span>
              <p>{t("common:number-of-gears")}</p>
              <p>
                {adData?.engineenvironment?.numberofgears > 0
                  ? adData?.engineenvironment?.numberofgears
                  : NA}
              </p>
            </span>
            <span>
              <p>{t("common:guarantee")}</p>
              <p>
                {adData?.conditionmaintenance?.guarantee
                  ? t("sell:yes")
                  : t("common:no")}
              </p>
            </span>
            <span>
              <p>{t("common:door-count")}</p>
              <p>{adData?.vehicledata?.doorcount ?? NA}</p>
            </span>
            <span>
              <p>{t("common:kerb-weight")}</p>
              <p>
                {adData?.engineenvironment?.kerbweight > 0
                  ? adData?.engineenvironment?.kerbweight + KG
                  : NA}
              </p>
            </span>
            <span>
              <p>{t("common:recent-techincal-service")}</p>
              <p>
                {ad?.conditionmaintenance?.lasttechnicalservice != null
                  ? t("sell:yes")
                  : t("common:no")}{" "}
                {ad?.conditionmaintenance?.lasttechnicalservice ?? ""}
              </p>
            </span>
            <span>
              <p>{t("common:number-of-seats")}</p>
              <p>
                {adData?.vehicledata?.idnumberofseats !== null &&
                adData?.vehicledata?.idnumberofseats > 0
                  ? getPropName(
                      seats,
                      adData?.vehicledata?.idnumberofseats,
                      "idseats"
                    )
                  : adData?.vehicledata?.numberofseats !== null &&
                    adData?.vehicledata?.numberofseats > 0
                  ? adData?.vehicledata?.numberofseats
                  : NA}
              </p>
            </span>
            <span>
              <p>{t("common:total-weight")}</p>
              <p>
                {adData?.vehicledata?.totalweight > 0
                  ? adData?.vehicledata?.totalweight + KG
                  : NA}
              </p>
            </span>
            <span>
              <p> {t("common:type-certificate")}</p>
              <p>{adData?.generaldata?.targaCode24 ?? NA}</p>
            </span>
            <span>
              <p>{t("common:upholstery")}</p>
              <p>
                {adData?.vehicledata?.idupholstery > 0
                  ? getPropName(
                      upholstery,
                      adData?.vehicledata?.idupholstery,
                      "idupholstery"
                    )
                  : !!adData?.vehicledata?.upholstery
                  ? adData?.vehicledata?.upholstery
                  : NA}
              </p>
            </span>
            <span>
              <p>{t("common:payload")}</p>
              <p>
                {adData?.vehicledata?.payload > 0
                  ? adData?.vehicledata?.payload + KG
                  : NA}
              </p>
            </span>
            <span>
              <p>{t("common:direct-parallel-import")}</p>
              <p>
                {adData?.conditionmaintenance?.directimport
                  ? t("sell:yes")
                  : t("common:no")}
              </p>
            </span>
            <span>
              <p>{t("common:handicapped-accessible")}</p>
              <p>
                {adData?.conditionmaintenance?.handicappedaccessible
                  ? t("sell:yes")
                  : t("common:no")}
              </p>
            </span>
            <span>
              <p>{t("common:non-smoking-vehicle")}</p>
              <p>
                {adData?.conditionmaintenance?.nonsmokercar
                  ? t("sell:yes")
                  : t("common:no")}
              </p>
            </span>
            <span>
              <p>{t("common:damaged-vehicle")}</p>
              <p>
                {adData?.conditionmaintenance?.damagedvehicle
                  ? t("sell:yes")
                  : t("common:no")}
              </p>
            </span>
          </div>
          <span
            className="show-more"
            onClick={() => setCollapseDetails(!collapseDetails)}
          >
            <p className="show-more-link">
              {collapseDetails ? ArrowDownRed : ArrowUpRed}
              {collapseDetails ? t("common:show-more") : t("common:show-less")}
            </p>
          </span>
        </div>
        <AdBanner adData={adData} />
        <div className="equipment">
          <h2 className="details-heading">{t("sell:equipment")}:</h2>
          <Equipment ad={ad}></Equipment>
        </div>
        <div className="energy-data">
          <div>
            <h2 className="details-heading">
              {t("adDetails:fuel-consumption-energy-data")}
            </h2>
            <div className="ed-data">
              <div className="energy-data-list">
                <p className="energy-data-label">
                  {t("adDetails:fuel-consumption")}*
                </p>
                <span>
                  <p className="energy-data-label font-weight-bold primary-font-color">
                    {adData?.engineenvironment?.fuelconsumption?.combined
                      ? adData.engineenvironment.fuelconsumption.combined +
                        " l/100 km (comb)"
                      : NA}
                  </p>
                  <p className="energy-data-label font-weight-bold primary-font-color">
                    {adData?.engineenvironment?.fuelconsumption?.urban
                      ? adData.engineenvironment.fuelconsumption.urban +
                        " l/100 km (city)"
                      : NA}
                  </p>
                  <p className="energy-data-label font-weight-bold primary-font-color">
                    {adData?.engineenvironment?.fuelconsumption?.extraurban
                      ? adData.engineenvironment.fuelconsumption.extraurban +
                        " l/100 km (country)"
                      : NA}
                  </p>
                </span>
              </div>
              <div className="energy-data-list">
                <p className="energy-data-label">
                  {t("adDetails:CO2-Emissions")}*
                </p>
                <p className="energy-data-label font-weight-bold primary-font-color">
                  {adData?.engineenvironment?.co2emission
                    ? adData.engineenvironment.co2emission + CO2
                    : NA}
                </p>
              </div>
              <div className="energy-data-list">
                <p className="energy-data-label">
                  {t("common:emission-sticker")}
                </p>
                <p className="energy-data-label font-weight-bold primary-font-color">
                  {adData?.engineenvironment?.idemissionsticker > 0
                    ? getPropName(
                        emissionsticker,
                        adData.engineenvironment.idemissionsticker,
                        "idemissionsticker"
                      )
                    : NA}
                </p>
              </div>
              <div className="energy-data-list">
                <p className="energy-data-label">
                  {t("common:emission-class")}
                </p>
                <p className="energy-data-label font-weight-bold primary-font-color">
                  {NA}
                </p>
              </div>
              <div className="energy-data-list">
                <p className="energy-data-label">
                  {t("adDetails:energy-efficiency-class")}
                </p>
                <p className="energy-data-label font-weight-bold primary-font-color">
                  {adData?.engineenvironment?.energyefficiencyclass ?? NA}
                </p>
              </div>
              <div className="energy-data-list">
                <p className="energy-data-label">
                  {t("advancedSearch:particulate-filter")}
                </p>
                <p className="energy-data-label font-weight-bold primary-font-color">
                  {adData?.engineenvironment?.particulatefilter
                    ? t("sell:yes")
                    : t("common:no")}
                </p>
              </div>
              <div className="energy-data-list">
                <p className="energy-data-label">
                  {t("advancedSearch:catalytic-converter")}
                </p>
                <p className="energy-data-label font-weight-bold primary-font-color">
                  {adData?.engineenvironment?.catalyticconverter
                    ? t("sell:yes")
                    : t("common:no")}
                </p>
              </div>
              <div className="energy-data-list">
                <p className="energy-data-label">{t("adDetails:euro-norm")}</p>
                <p className="energy-data-label font-weight-bold primary-font-color">
                  {adData?.engineenvironment?.ideuronorm > 0
                    ? getPropName(
                        euronorm,
                        adData?.engineenvironment?.ideuronorm,
                        "ideuronorm"
                      )
                    : NA}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="chart-div">
              {energyEfficience.map((item) => (
                <React.Fragment key={item.energy_efficience_id}>
                  <div
                    className={`div-${item.name}`}
                    style={{ backgroundColor: `#${item.rgb}` }}
                  >
                    <p>{item.name}</p>
                    <div
                      style={{ borderLeft: `20px solid #${item.rgb}` }}
                    ></div>
                  </div>
                  {adData?.engineenvironment?.energyefficiencyclass ===
                  item.name ? (
                    <div className="pointer-div">
                      <div className="point-margin-div"></div>
                      <p>{item.name}</p>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </React.Fragment>
              ))}
            </div>
            <div className="flag-img-div">
              <img src={Flag} alt="Swiss flag" className="flag-img" />
              <p>
                {t("adDetails:energy-label")} {current_year}
              </p>
            </div>
          </div>
        </div>
        <div className="vehicle-description">
          <h2 className="details-heading">
            {t("adDetails:vehicle-description")}
          </h2>
          {/* <span className="translate-link">
            <p>{t("adDetails:automatically-translate-by-google")}</p>
            <a href="#" className="primary-color">
              {t("common:view original")}
            </a>
          </span> */}
          {/* <div className={ `${collapseVehicleDesc ? 'veh-desc-collapsed' : 'veh-desc-expand'}` }> */}
          {adData?.description ? (
            <p className="vehicle-desc-text">{adData?.description}</p>
          ) : (
            <p className="vehicle-desc-text">
              {t("adDetails:no-desc-for-vehicle")}
            </p>
          )}

          {/* </div> */}
          {/* <span className="show-more" onClick={ () => setCollapseVehicleDesc(!collapseVehicleDesc) }>
                <p className="show-more-link">
                  { collapseVehicleDesc ? ArrowDownRed : ArrowUpRed } { collapseVehicleDesc ? t('common:show-more') : t('common:show-less') }
                </p>
              </span> */}
        </div>
        <div className="ad-det-contact-details">
          <h2 className="details-heading">{t("sell:contact-details")}</h2>
          <div className="contact flex-col-wrapper">
            <div className="flex-row-wrapper small-gap">
              <span>
                {adOwnerData ? (
                  <>
                    <p className="no-margin">{adOwnerData?.name}</p>
                    <p className="no-margin">
                      <VehicleAddress
                        vehiclelocation={vehiclelocation}
                        page="details"
                      />
                    </p>
                  </>
                ) : (
                  <p>
                    {t("profile:name")} & {t("profile:address-location")}
                  </p>
                )}
              </span>
              <a
                className="send-mail primary-color"
                onClick={handleLinkClick}
                href="/"
              >
                {t("adDetails:send-email-to-seller")}
              </a>
              <ContactFormDialog
                isOpen={isContactFormOpen}
                onClose={() => setIsContactFormOpen(false)}
              />
            </div>
            {/* <div className="ratings">
              <div className="con-det-stars">
                {FullStar}
                {FullStar}
                {FullStar}
                {HalfStar}
                {EmptyStar}
                <span className="assessment"> (54)</span>
              </div>
              <p className="primary-color underlined">54 Ratings</p>
            </div> */}
          </div>
        </div>
        <div className="ad-details-info">
          <p>{t("adDetails:ad-details-info")}</p>
          <p>{t("adDetails:guideline")}</p>
          <a
            className="primary-color"
            target="_blank"
            href="https://automobilrevue.ch/fahrberichte"
            rel="noreferrer"
          >
            www.automobilrevue.ch/fahrberichte
          </a>
        </div>
        {chooseType === USER_TYPES.STANDARD ? (
          <Button
            className="report-ad-button"
            color="white"
            label={t("reportAd:report-suspicious-button")}
            click={() => goToReportAd(adData?.idad)}
          />
        ) : null}
      </main>
    </SimpleLoader>
  );
};

const stateToProps = (state) => {
  const { adOwnerData, chooseType } = state.profile;
  const phones = adOwnerData?.phones ?? [];
  const phoneNumbers = phones.map((phone) => phone.number);
  const phoneNumber = phoneNumbers.length > 0 ? phoneNumbers[0] : null;

  const {
    ad,
    loading,
    make,
    car_model,
    fuel_type,
    body_type,
    drive_type,
    motors,
    idlocale,
    bodyColors,
    interiorcolor,
    upholstery,
    condition,
    engineenvironment,
    transmission,
    optional_equipmentids,
    energyEfficience,
    seats,
    euronorm,
    trailerhitch,
    trailerload,
    enginetype,
    emissionsticker,
  } = state.car;

  return {
    ad,
    loading,
    make,
    car_model,
    fuel_type,
    body_type,
    drive_type,
    phoneNumber,
    adOwnerData,
    chooseType,
    motors,
    idlocale,
    bodyColors,
    interiorcolor,
    upholstery,
    condition,
    engineenvironment,
    transmission,
    optional_equipmentids,
    energyEfficience,
    seats,
    euronorm,
    trailerhitch,
    trailerload,
    enginetype,
    emissionsticker,
  };
};

export default connect(stateToProps)(AdDetails);
