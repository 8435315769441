import React from "react";
import { useTranslation } from "react-i18next";
import Bulb from "../../../../../assets/shapes/bulb-red.png";

import "./index.css";

const BulbRedCircle = () => {
  const { t } = useTranslation(["footer"]);
  return (
    <span className="bulb-image">
      <img src={Bulb} />
      <p>{t("footer:quick-tip")}</p>
    </span>
  );
};

export default BulbRedCircle;
