const appConfig = {
  theme: {
    nav: {
      label: "xAdmin",
    },
    dark: false,
    fixed: true,
    showHeaderOnAuthRoutes: true,
    showInputIconsOnLogin: false,
    routesWithoutStaticHeader: ["/item-details"],
  },
  services: {
    google_maps: {
      google_api_key: "AIzaSyBvzFfO04bfr0Kp1C67PBZQxmlzUd9DLLM",
      defaultLat: 51.459636,
      defaultLng: -2.538539,
      defaultZoom: 11,
    },
    stripe_key: "pk_test_SfLlfP41LhHVIG2vrA95ZrFt",
  },
  api: {
    baseURL: "https://xapi.openkinetix.com",
    paymentURL: "https://pay-mo.co.uk",
  },
  general: {
    clientName: "Open Kinetix",
    appExitRoutes: ["/", "/home", "/dashboard"],
    authRoutes: ["/login", "/register", "/reset-password"],
    defaultCurrency: "gbp",
    updateDateTimer: 20,
    debug: true,
  },
  localization: {
    defaultLocale: "en",
    supportedLocales: {
      en: "English",
      fr: "français",
    },
  },
  appType: {
    hasOrdering: true,
  },
  configS3: {
    imageNamePrefix: "xadmin-profile-image-",
    bucketName: "apps-profile-images",
    region: "eu-west-1",
    accessKeyId: "AKIAIMWCI6M7IF4RTWSA",
    secretAccessKey: "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",
  },
};

export const getConfig = () => appConfig;
export const updateConfig = (newConfig) => ({ ...appConfig, ...newConfig });

export default appConfig;
