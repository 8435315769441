import React from "react";
import { useTranslation } from "react-i18next";
import Logo from "../../assets/images/new-logo.svg";
import "./index.css";

const ThanksPage = (props) => {
  const { texts } = props;
  const { t } = useTranslation(["common", "auth"]);

  return (
    <div className="success">
      <div className="header">
        <h5>{t("sell:congratulation")}</h5>
      </div>
      <div className="textOutput">
        {texts.map((text, index) => (
          <p key={index}>{t(text)}</p>
        ))}
      </div>
      <div className="footer">
        <img src={Logo} />
      </div>
    </div>
  );
};

export default ThanksPage;
