import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { backIcon, Phone } from "../../../components/svg-icons";
import { useTranslation } from "react-i18next";
import { getMerchantByUid } from "../../../store/actions";
import { formatPhoneNumber } from "../../../lib/utils";

import "./index.css";

const DealerDetails = (props) => {
  const { dispatch, phoneNumber, uid, merchant } = props;

  const { t } = useTranslation([
    "common",
    "adDetails",
    "sell",
    "reportAd",
    "profile",
  ]);
  const history = useHistory();

  const [viewNumber, setViewNumber] = useState(true);

  const goBack = () => {
    history.goBack();
  };

  const dealerName = merchant?.name;
  const dealerAddress = merchant?.address_data;
  const city = merchant?.city;
  const formattedPhoneNumber = phoneNumber
    ? formatPhoneNumber(phoneNumber)
    : "";

  if (!uid) {
    return null;
  }

  useEffect(() => {
    dispatch(getMerchantByUid(uid));
  }, [uid, dispatch]);

  return (
    <div className="dealer-details">
      <header>
        <div onClick={goBack}>
          {backIcon}
          <p className="no-margin flex">
            {t("dealerSearch:back-to-search-results")}
          </p>
        </div>
      </header>
      <main>
        <div className="left-side">
          <h1>{dealerName}</h1>
          <div className="dealer-address">
            {dealerAddress && (
              <div>
                <p>{dealerName}</p>
                <p>{dealerAddress.streetandnumber}</p>
                <p>
                  {dealerAddress.zipcode} {dealerAddress.city || city}
                </p>
              </div>
            )}
          </div>
          <span
            className="contact-box-phone"
            onClick={() => setViewNumber(!viewNumber)}
          >
            {viewNumber ? (
              <span className="call-seller-with-svg">
                <h5>{t("adDetails:call-seller")}</h5>
                {Phone}
              </span>
            ) : (
              <span className="center-phone-number">
                {phoneNumber ? (
                  <a href={formattedPhoneNumber}>
                    <h5>{phoneNumber}</h5>
                  </a>
                ) : (
                  <h5>{t("profile:contact-phone")}</h5>
                )}
              </span>
            )}
          </span>
        </div>
        <div className="right-side">
          <div className="dealer-description">
            {/* Add dealer description here */}
          </div>
        </div>
      </main>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { merchant } = state.car;
  const { adOwnerData } = state.profile;
  const phones = adOwnerData?.phones ?? [];
  const phoneNumbers = phones.map((phone) => phone.number);
  const phoneNumber = phoneNumbers.length > 0 ? phoneNumbers[0] : null;

  return {
    adOwnerData,
    phoneNumber,
    merchant,
  };
};

export default connect(mapStateToProps)(DealerDetails);
