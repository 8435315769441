import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getPropName } from "../../../lib/utils";
import "./index.css";

const Confirmation = (props) => {
  const { make, car_model, ad_data } = props;
  const makename = getPropName(make, ad_data?.idmake, "idmake");
  const modelname = getPropName(car_model, ad_data?.idmodel, "idmodel");
  const { t } = useTranslation("");

  return (
    <div className="confirmation">
      <div className="text">
        <p className="description italic"> {t("sell:short-description")}</p>
        <p className="mb-0">
          {t("sell:vehicle")}:
          <span className="data font-weight-bold">
            {" "}
            {makename} {modelname}
          </span>
        </p>
        <p className="mt-0">
          {t("sell:photos")}:
          <span className="data font-weight-bold"> {t("sell:yes")}</span>
        </p>
        <p>
          {t("sell:user-package")}:
          <span className="data font-weight-bold"> 14 {t("sell:days")}</span>
        </p>
      </div>
    </div>
  );
};

const stateToProps = (state) => {
  const { make, car_model, ad_data } = state.car;
  return {
    make,
    car_model,
    ad_data,
  };
};

export default connect(stateToProps)(Confirmation);
