import React from "react";
import { useTranslation } from "react-i18next";
import Logo from "../../assets/images/new-logo.svg";
import "./index.css";

const NoResult = ({descText}) => {
  const { t } = useTranslation(["results"]);
    return (
      <div className="no-result-card">
        <img src={Logo}/>
        <h2 className="main-text">{t("no-results")}</h2>
        <p>{t(`${descText}`)}</p>
      </div>
    );
};
export default NoResult;
