import { useEffect } from "react";

const useCustomBackButtonHandler = (choosenType, setChoosenType) => {
  useEffect(() => {
    if (choosenType !== null) {
      // Push a new entry into the history stack when a type is chosen
      window.history.pushState(
        { page: "chooseType" },
        "",
        window.location.href
      );
    }

    const handleBackButton = (event) => {
      event.preventDefault();
      if (choosenType !== null) {
        setChoosenType(null);
      } else {
        window.history.back();
      }
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [choosenType]);
};

export default useCustomBackButtonHandler;
