import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Radio from "../../../../components/radio";
import FormControl from "../../../../components/form-control";
import ShownContactInfo from "../../../../components/user-info/index";
import Avatar from "../../../../components/avatar/index";
import "./index.css";

const Settings = (props) => {
  const { t } = useTranslation(["common", "profile"]);
  const [sendMeEmail, setSendMeEmail] = useState(0);

  return (
    <div className="profile-settings">
      <div>
        <span className="user-contact-header">
          <h5>{t("common:settings")}</h5>
          <p>{t("profile:send-statistics")}</p>
        </span>
        <FormControl
          className="receive-statistics"
          inputType="checkbox"
          label={t("profile:receive-statistics")}
        />
        <div className="emailstatistics">
          <h5>{t("profile:send-me-mail-with-statistics")}</h5>
          <FormControl>
            <Radio
              value={0}
              checked={sendMeEmail === 0}
              className={"radio-button-target"}
              label={t("profile:day")}
              changeHandler={() => setSendMeEmail(0)}
            />
            <Radio
              value={1}
              checked={sendMeEmail === 1}
              className={"radio-button-target"}
              label={t("profile:once-a-week")}
              changeHandler={() => setSendMeEmail(1)}
            />
            <Radio
              value={2}
              checked={sendMeEmail === 2}
              className={"radio-button-target"}
              label={t("profile:once-in-two-weeks")}
              changeHandler={() => setSendMeEmail(2)}
            />
            <Radio
              value={3}
              checked={sendMeEmail === 3}
              className={"radio-button-target"}
              label={t("profile:once-a-month")}
              changeHandler={() => setSendMeEmail(3)}
            />
          </FormControl>
        </div>
      </div>
      <ShownContactInfo>
        <Avatar customClass="small-avatar" circularSize={10} />
      </ShownContactInfo>
    </div>
  );
};

export default Settings;
