import React from "react";
import "./index.css";

const Image = (props) => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const { alt, src, style, className } = props;
  return (
    <React.Fragment>
      {/* <img
        className={ `image blur-thumb ${className ?? className}` }
        alt={ alt }
        src={ src }
        style={ { ...style, visibility: isLoaded ? "hidden" : "visible", position: 'absolute' } }
      /> */}
      <img
        onLoad={() => {
          setIsLoaded(true);
        }}
        className={`image full ${className ?? className}`}
        style={{ ...style, opacity: isLoaded ? 1 : 0 }}
        alt={alt}
        src={src}
      />
    </React.Fragment>
  );
};
export default Image;
