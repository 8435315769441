import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NavDropDown } from "../dropdown";
import { Menu, MenuItem } from "@material-ui/core";
import { getLocale, getTransmissionForLocale } from "../../store/car/actions";
import { USER_TYPES } from "../../types";
import Button from "../../components/button/index";
import Logo from "../../assets/images/new-logo.svg";
import Avatar from "../avatar/index";
import "./index.css";

const Navigation = (props) => {
  const { profileData, locales, idlocale, dispatch, chooseType } = props;
  const token = localStorage.getItem("token");
  const history = useHistory();
  const location = useLocation();
  const [currentLanguage, setCurrentLanguage] = useState(
    localStorage.getItem("i18nextLng")
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const { t, i18n } = useTranslation(["navbar, common, auth, footer"]);

  let revue_web_lang =
    idlocale === 1 || idlocale === 2 ? "automobilrevue" : "revueautomobile";
  let uber_uns = idlocale === 1 || idlocale === 2 ? "uber-uns" : "sur-nous";

  const openLanguageMenuHandler = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const refetchDataForLocale = () => {
    dispatch(getTransmissionForLocale());
  };
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setCurrentLanguage(localStorage.getItem("i18nextLng"));
    dispatch(getLocale());
  };

  const closeLanguageMenuHandler = (item) => {
    changeLanguage(!item.locale ? i18n.language : item.locale);
    setAnchorEl(null);
    const currentPath = history.location.pathname;
    const newPathname = currentPath.replace(
      `/${currentLanguage}/`,
      `/${!item.locale ? i18n.language : item.locale}/`
    );
    history.push(newPathname);
  };

  const redirectTo = (route) => {
    history.push(`/${i18n.language}/${route}`);
  };

  const handleClick = (e) => {
    e.preventDefault();
    if (location.pathname.startsWith(`/${i18n.language}/sell`)) {
      window.location.reload();
    } else {
      history.push(`/${i18n.language}/sell`);
    }
  };

  useEffect(() => {
    refetchDataForLocale();
  }, [idlocale]);

  return (
    <div className="navigation">
      <div className="left-side">
        <div className="nav-logo">
          <NavLink to="/">
            <img src={Logo} alt="" />
          </NavLink>
        </div>
        <div className="routes">
          {token && chooseType === USER_TYPES.MERCHANT ? (
            <>
              <span>
                <span>
                  <span> {t("common:search")}</span>{" "}
                  <span className="arrow"></span>
                </span>
                <div className="dropdown-content">
                  <NavLink to={`/${i18n.language}/advanced-search`}>
                    {t("common:advanced-search")}
                  </NavLink>
                  <NavLink to={`/${i18n.language}/favorites`}>
                    {t("navbar:favourites")}
                  </NavLink>
                  <NavLink to={`/${i18n.language}/saved-searches`}>
                    {t("navbar:saved-search")}
                  </NavLink>
                  <NavLink to={`/${i18n.language}/dealer-search`}>
                    {t("navbar:dealer-search")}
                  </NavLink>
                </div>
              </span>
              <span>
                <span>
                  <span>{t("navbar:sell-a-car")}</span>{" "}
                  <span className="arrow"></span>
                </span>
                <div className="dropdown-content">
                  <NavLink to={`/${i18n.language}/sell`} onClick={handleClick}>
                    {t("navbar:create-ad")}
                  </NavLink>
                  <NavLink to={`/${i18n.language}/cars`}>
                    {t("navbar:info-page")}
                  </NavLink>
                </div>
              </span>
              <span>
                <span>
                  <span> {t("navbar:my-cars")}</span>{" "}
                  <span className="arrow"></span>
                </span>
                <div className="dropdown-content">
                  <NavLink
                    to={{
                      pathname: `/${i18n.language}/my-cars`,
                      state: { drafts: true },
                    }}
                  >
                    {t("common:drafts")}
                  </NavLink>
                  <NavLink
                    to={{
                      pathname: `/${i18n.language}/my-cars`,
                      state: { drafts: false },
                    }}
                  >
                    {t("navbar:online-ads")}
                  </NavLink>
                </div>
              </span>
            </>
          ) : token && chooseType === USER_TYPES.STANDARD ? (
            <>
              <span>
                <span>
                  <span> {t("common:search")}</span>{" "}
                  <span className="arrow"></span>
                </span>
                <div className="dropdown-content">
                  <NavLink to={`/${i18n.language}/advanced-search`}>
                    {t("common:advanced-search")}
                  </NavLink>
                  <NavLink to={`/${i18n.language}/favorites/`}>
                    {t("navbar:favourites")}
                  </NavLink>
                  <NavLink to={`/${i18n.language}/saved-searches/`}>
                    {t("navbar:saved-search")}
                  </NavLink>
                  <NavLink to={`/${i18n.language}/dealer-search`}>
                    {t("navbar:dealer-search")}
                  </NavLink>
                </div>
              </span>
              <span>
                <span>
                  <span>{t("footer:automobil-revue")}</span>{" "}
                  <span className="arrow"></span>
                </span>
                <div className="dropdown-content">
                  <a
                    href={`https://${revue_web_lang}.ch`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("navbar:website")}
                  </a>
                  <a
                    href={`https://${revue_web_lang}.ch/${uber_uns}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("navbar:about-us")}
                  </a>
                  <a
                    href={`https://${revue_web_lang}.ch/shop/plans`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Abo & Shop
                  </a>
                  <a
                    href={`https://${revue_web_lang}.ch/tests`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("navbar:car-reviews")}
                  </a>
                </div>
              </span>
            </>
          ) : (
            <>
              <span>
                <span>
                  <span>{t("common:search")}</span>{" "}
                  <span className="arrow"></span>
                </span>
                <div className="dropdown-content">
                  <NavLink to={`/${i18n.language}/advanced-search/`}>
                    {t("common:advanced-search")}
                  </NavLink>
                  <NavLink to={`/${i18n.language}/dealer-search`}>
                    {t("navbar:dealer-search")}
                  </NavLink>
                </div>
              </span>
              <span>
                <span>
                  <span>{t("footer:automobil-revue")}</span>{" "}
                  <span className="arrow"></span>
                </span>
                <div className="dropdown-content">
                  <a
                    href={`https://${revue_web_lang}.ch`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("navbar:website")}
                  </a>
                  <a
                    href={`https://${revue_web_lang}.ch/${uber_uns}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("navbar:about-us")}
                  </a>
                  <a
                    href={`https://${revue_web_lang}.ch/shop/plans`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Abo & Shop
                  </a>
                  <a
                    href={`https://${revue_web_lang}.ch/tests`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("navbar:car-reviews")}
                  </a>
                </div>
              </span>
            </>
          )}
        </div>

        <NavDropDown
          title={currentLanguage}
          subtitle={t("navbar:language")}
          titleClass="small-dropdown-title no-border"
          subtitleClass="small-dropdown-subtitle"
          rootClass="small-dropdown"
          click={openLanguageMenuHandler}
        />
      </div>
      <div className="right-side">
        <NavDropDown
          title={currentLanguage}
          subtitle={t("navbar:language")}
          titleClass="large-dropdown-title no-border"
          subtitleClass="large-dropdown-subtitle"
          rootClass="large-dropdown"
          click={openLanguageMenuHandler}
        />
        <div className="nav-auth">
          <div className="auth-routes">
            {token ? (
              <div className="personal-info">
                <Avatar
                  customClass="nav-avatar"
                  circularSize={10}
                  handler={() => redirectTo("user-profile")}
                />
                {false && <span className="mail-number">1</span>}
                <span className="email-address">
                  {profileData?.email
                    ? profileData.email
                    : t("profile:your-email")}
                </span>
              </div>
            ) : (
              <span>
                <Button
                  click={() => redirectTo("signin")}
                  className="loginButton"
                  label={t("auth:login")}
                  color="red"
                />
                <Button
                  click={() => redirectTo("signup")}
                  className="registerButton"
                  label={t("common:register")}
                  color="red"
                />
              </span>
            )}
          </div>
        </div>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={closeLanguageMenuHandler}
        >
          {locales.map((item, index) => {
            return (
              <MenuItem
                key={index}
                onClick={() => closeLanguageMenuHandler(item)}
              >
                {item.name}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    </div>
  );
};

const stateToProps = (state) => {
  const { profileData, loading, chooseType } = state.profile;
  const { locales, idlocale } = state.car;
  return {
    locales,
    loading,
    profileData,
    idlocale,
    chooseType,
  };
};

export default connect(stateToProps)(Navigation);
