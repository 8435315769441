import { useTranslation } from "react-i18next";

export const getMonthsData = () => {
  const { t } = useTranslation(["common", "sell"]);
  return [
    { name: t("common:january"), month: 1 },
    { name: t("common:february"), month: 2 },
    { name: t("common:march"), month: 3 },
    { name: t("common:april"), month: 4 },
    { name: t("common:may"), month: 5 },
    { name: t("common:june"), month: 6 },
    { name: t("common:july"), month: 7 },
    { name: t("common:august"), month: 8 },
    { name: t("common:september"), month: 9 },
    { name: t("common:october"), month: 10 },
    { name: t("common:november"), month: 11 },
    { name: t("common:december"), month: 12 },
  ];
};

export const getNumericMonthsWithCancel = () => {
  const { t } = useTranslation("common");
  return [{ name: t("common:cancel"), month: "" }, ...numericMonths];
};

export const numericMonths = [
  { name: "1", month: 1 },
  { name: "2", month: 2 },
  { name: "3", month: 3 },
  { name: "4", month: 4 },
  { name: "5", month: 5 },
  { name: "6", month: 6 },
  { name: "7", month: 7 },
  { name: "8", month: 8 },
  { name: "9", month: 9 },
  { name: "10", month: 10 },
  { name: "11", month: 11 },
  { name: "12", month: 12 },
];

export const currentYear = new Date().getFullYear();
const firstYear = 1905;
const totalYears = currentYear - firstYear;

export const years = Array.from(new Array(totalYears), (val, index) => {
  const yearValue = currentYear - index;
  return { year: yearValue, name: yearValue.toString() };
});

export const getYearsWithCancel = () => {
  const { t } = useTranslation("common");
  return [
    { year: "", name: t("common:cancel") },
    ...Array.from(new Array(totalYears), (val, index) => {
      const yearValue = currentYear - index;
      return { year: yearValue, name: yearValue.toString() };
    }),
  ];
};

export const getYearsWithAll = () => {
  const { t } = useTranslation(["common"]);
  return [{ year: -1, name: t("common:all") }, ...years];
};

export const ADS_PER_PAGE = 5;

export const MYCARS = {
  ACTIVE: { ID: 1, LABEL: "mycars" },
  DRAFTS: { ID: 2, LABEL: "drafts" },
};

export const defaultLocation = {
  lat: 47.378308,
  lng: 8.530403,
};

export const getRegionsSwiss = () => [
  { name: "AG" },
  { name: "AI" },
  { name: "AR" },
  { name: "BE" },
  { name: "BL" },
  { name: "BS" },
  { name: "FR" },
  { name: "GE" },
  { name: "GL" },
  { name: "GR" },
  { name: "JU" },
  { name: "LU" },
  { name: "NE" },
  { name: "NW" },
  { name: "OW" },
  { name: "SG" },
  { name: "SH" },
  { name: "SO" },
  { name: "SZ" },
  { name: "TG" },
  { name: "TI" },
  { name: "UR" },
  { name: "VD" },
  { name: "VS" },
  { name: "ZG" },
  { name: "ZH" },
];

export const getnumberOfGearsAll = () => {
  const { t } = useTranslation(["common"]);
  return [{ idnumberofgears: -1, name: t("common:all") }, ...numberOfGears];
};

export const numberOfGears = [
  { numberofgears: 4, name: { DE: "4", EN: "4", FR: "4", IT: "4" } },
  { numberofgears: 5, name: { DE: "5", EN: "5", FR: "5", IT: "5" } },
  { numberofgears: 6, name: { DE: "6", EN: "6", FR: "6", IT: "6" } },
  { numberofgears: 7, name: { DE: "7", EN: "7", FR: "7", IT: "7" } },
  { numberofgears: 8, name: { DE: "8", EN: "8", FR: "8", IT: "8" } },
  { numberofgears: 9, name: { DE: "9", EN: "9", FR: "9", IT: "9" } },
  { numberofgears: 10, name: { DE: "10", EN: "10", FR: "10", IT: "10" } },
];
