import React, { useEffect } from "react";
import { connect } from "react-redux";
import { resetFindProps } from "../../store/actions";
import { useTranslation } from "react-i18next";

const ClearState = ({ dispatch, pathname }) => {
  const { i18n } = useTranslation();
  useEffect(() => {
    if (
      pathname === `/${i18n.language}/advanced-search/` ||
      pathname.includes(`/${i18n.language}/results/`) ||
      pathname.includes(`/${i18n.language}/ad/`)
    ) {
      return undefined;
    } else {
      dispatch(resetFindProps());
    }
  }, [pathname]);
};

export default connect()(ClearState);
