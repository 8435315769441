import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCarModel, customSetFindProps } from "../store/actions";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";

const removeLastSlash = (string) =>
  string.endsWith("/") ? string.slice(0, -1) : string;

const useUrlMakeAndModel = () => {
  const { makeNameUrl, modelNameUrl } = useParams();
  const { pathname } = useLocation();
  const history = useHistory();
  const makeWithAll = useSelector((state) => state.car.makeWithAll);
  const dispatch = useDispatch();
  const [model, setModel] = useState(null);
  const carModelWithAll = useSelector(
    (state) => state.car.carModelWithAll?.[0] ?? state.car.carModelWithAll
  );
  const findData = useSelector((state) => state.car.find_data);

  let idMake = null;
  let make = null;
  if (makeNameUrl) {
    make = makeWithAll?.find((item) => item.name === makeNameUrl);
    idMake = make?.idmake;
  }

  useEffect(() => {
    if (idMake) {
      dispatch(getCarModel(idMake, 0));
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(carModelWithAll)) {
      const model = carModelWithAll.find(
        (item) =>
          item && typeof item.name === "string" && item.name === modelNameUrl
      );
      if (model) {
        setModel(model);
      }
    }
  }, [carModelWithAll]);

  const getBaseUrl = () => {
    let index = pathname.indexOf("results/");
    let basePath = pathname;
    if (index !== -1) {
      basePath = pathname.substring(0, index + "results/".length);
    }
    basePath = removeLastSlash(basePath);
    return basePath;
  };

  const clearUrl = () => {
    const basePath = getBaseUrl();
    history.replace({
      pathname: basePath,
    });
  };

  const updateUrl = (idMake, idModel) => {
    const newMake = makeWithAll?.find(
      (item) => item.idmake === findData.idMake?.[0]
    );

    const newModel = carModelWithAll.find(
      (model) => model && model.idmodel === findData.idModel?.[0]
    );

    const basePath = getBaseUrl();

    const makePath = newMake?.name ? `/${newMake.name}` : "";
    const modelPath = newModel?.name ? `/${newModel.name}` : "";

    history.replace({
      pathname: `${basePath}${makePath}${modelPath}`,
    });
  };

  return {
    // make
    idMake: make?.idmake,
    make,
    makeFromUrl: makeNameUrl,
    // model
    idModel: model?.idmodel ?? null,
    model,
    modelFromUrl: modelNameUrl,
    // comon
    updateUrl,
    clearUrl,
  };
};

export default useUrlMakeAndModel;
