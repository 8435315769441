import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../../components/button";
import Close from "../../../../../assets/shapes/close.svg";
import "./index.css";

const UserInfoModal = (props) => {
  const { isOpen, onClose, message, imageSrc } = props;
  const { t } = useTranslation(["common", "sell", "footer"]);

  if (!isOpen) return null;

  return (
    <div className="user-help-modal">
      <div className="header">
        <p>{t("sell:helpful-information")}</p>
        <div onClick={onClose}>
          <Button className="close-button" label={t("common:close")} />
          <img src={Close} alt="Close" />
        </div>
      </div>
      <main>
        <p>{t("footer:cars-help-modal")}</p>
      </main>
    </div>
  );
};

export default UserInfoModal;
