import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import secureLocalStorage from "react-secure-storage";
import { useHistory } from "react-router-dom";
import { Box, Typography, TextField } from "@material-ui/core";
import { ShowPassword } from "../../../components/svg-icons";
import {
  connect as dispatchConnect,
  setChooseType,
} from "../../../store/actions";
import { withTranslation } from "../../../lib/translate";
import { emailRegex } from "../../../lib/utils";
import FormControl from "../../../components/form-control/index";
import Button from "../../../components/button";
import SimpleLoader from "../../../components/simple-loader";
import ChooseUser from "../choose-user";
import SigninError from "./components/signin-error";
import { useCustomBackButtonHandler } from "./../../../hooks";
import "./index.css";

const SignIn = (props) => {
  const { dispatch, loading, isAuthorized } = props;
  const { t, i18n } = useTranslation(["common", "auth"]);
  const history = useHistory();
  const [data, setData] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [validationMessages, setValidationMessages] = useState({});
  const [choosenType, setChoosenType] = useState(null);
  useCustomBackButtonHandler(choosenType, setChoosenType);

  useEffect(() => {
    if (props?.location?.state?.email) {
      setData((prevState) => {
        return { ...prevState, email: props.location.state.email };
      });
    }
  }, []);

  useEffect(() => {
    if (!!isAuthorized && !loading) {
      secureLocalStorage.setItem("email", data.email);
      secureLocalStorage.setItem("password", data.password);
      secureLocalStorage.setItem("userType", choosenType);
      if (props?.location?.state?.from) {
        history.push(props.location.state.from);
      } else {
        history.push("/");
      }
    }
  }, [isAuthorized, loading]);

  const loginErrorMessage = "auth:incorrect-email-or-password";

  const loginHandler = (event) => {
    event.preventDefault();
    let errorFlag = false;
    for (const key in data) {
      const value = data[key].trim();
      if (key === "email" && !emailRegex.test(value)) {
        errorFlag = true;
        setValidationMessages((prevState) => {
          return {
            ...prevState,
            emailOrPasswordError: loginErrorMessage,
          };
        });
        break;
      }
      if (key.includes("password") && (value.length < 6 || value.length > 30)) {
        errorFlag = true;
        setValidationMessages((prevState) => {
          return {
            ...prevState,
            emailOrPasswordError: loginErrorMessage,
          };
        });
        break;
      }
    }
    if (errorFlag || !data?.email.trim() || !data?.password.trim()) return;
    dispatch(
      dispatchConnect(data.email.trim(), data.password.trim(), choosenType)
    );
    dispatch(setChooseType(choosenType));
    setValidationMessages((prevState) => {
      delete prevState.emailOrPasswordError;
      return { ...prevState };
    });
  };

  const inputChangeHandler = (event) => {
    setData((prevState) => {
      return { ...prevState, [event.target.name]: event.target.value };
    });
  };

  const redirectTo = (page) => {
    history.push(`/${i18n.language}/${page}`);
  };

  if (choosenType === null) {
    return (
      <ChooseUser
        title={t("auth:do-you-want-to-login-as")}
        description={t("auth:choose-whether-you-want-to-login")}
        setChoosenType={setChoosenType}
      />
    );
  }

  return (
    <SimpleLoader loading={loading}>
      <div className="login">
        <Box className="header">
          <p>{t("auth:login")}</p>
        </Box>
        <form onSubmit={loginHandler} className="register-form">
          <Box className="flex-row-wrapper regformdiv1">
            <FormControl className="register-label primary-font-color">
              <span>{t("auth:your-email-address")}</span>
              <TextField
                value={data?.email ? data.email : ""}
                id="email"
                name="email"
                variant="outlined"
                type="text"
                placeholder={t("common:type")}
                onChange={(event) => inputChangeHandler(event)}
              />
            </FormControl>
          </Box>
          <Box className="flex-row-wrapper regformdiv1">
            <FormControl className="register-label primary-font-color">
              <span>{t("auth:your-password")}</span>
              <Box className="inputandicon">
                <TextField
                  value={data?.password ? data.password : ""}
                  id="password"
                  name="password"
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  placeholder={t("common:type")}
                  onChange={(event) => inputChangeHandler(event)}
                />
                <span
                  className="showPassword"
                  onClick={() => setShowPassword((prev) => !prev)}
                >
                  {ShowPassword}
                </span>
              </Box>
            </FormControl>
            <SigninError validationMessages={validationMessages} />
          </Box>
          <Box className="form-button">
            <Button
              color="red"
              label={t("auth:login")}
              className="login-button"
              click={(event) => loginHandler(event)}
            />
          </Box>
          <Box className="form-button">
            <Button
              color="gray"
              label={t("common:return-to-picker")}
              className="login-button"
              click={() => setChoosenType(null)}
            />
          </Box>
        </form>
        <Box className="footer">
          <Typography varinat="p">
            {t("auth:forgot-password-pt1")}
            <br />
            {t("auth:forgot-password-pt2")}{" "}
            <span onClick={() => redirectTo("recover-password")}>
              {t("common:here")}
            </span>{" "}
            {t("auth:forgot-password-pt3")}
          </Typography>
        </Box>
        <Box className="footer-register">
          <Typography varinat="p">
            {t("auth:or-click-to-register-pt1")}
            <br />
            <span onClick={() => redirectTo("signup")}>
              {t("auth:or-click-to-register-pt2")}
            </span>
            .
          </Typography>
        </Box>
      </div>
    </SimpleLoader>
  );
};

const stateToProps = (state) => {
  const { loading, isAuthorized } = state.profile;
  return {
    loading,
    isAuthorized,
  };
};

export default connect(stateToProps)(withTranslation(SignIn));
