import React from "react";
import "./index.css";

const Checkbox = (props) => {
  const { label, checked, changeHandler, className, readOnly } = props;
  return (
    <div
      className={`checkbox-filed ${className && className} ${
        checked ? "checked" : ""
      }`}
      onClick={changeHandler}
    >
      <input
        type="checkbox"
        name={label}
        checked={checked}
        onChange={() => null}
        readOnly={readOnly}
      />
      <label htmlFor="check_1">
        <span>{label}</span>
      </label>
    </div>
  );
};

export default Checkbox;
