import secureLocalStorage from "react-secure-storage";
import {
  SET_PROTECTED_REFERRER,
  SET_SYS_LOCALE,
  REGISTERWEBUSER,
  REGISTERWEBUSER_RESULT,
  REGISTERWEBUSER_ERROR,
  REGISTERUSER,
  REGISTERUSER_RESULT,
  REGISTERUSER_ERROR,
  CONFIRMTOKEN,
  CONFIRMTOKEN_RESULT,
  CONFIRMTOKEN_ERROR,
  SETAUTHORIZATION,
  CONNECT_RESULT,
  CONNECT,
  CONNECT_ERROR,
  LOGOUT,
  EDITPROFILEDATA,
  EDITPROFILEDATA_RESULT,
  EDITPROFILEDATA_ERROR,
  GETPROFILEDATA,
  GETPROFILEDATA_RESULT,
  GETPROFILEDATA_ERROR,
  ADDMERCHANTCARD,
  ADDMERCHANTCARD_RESULT,
  ADDMERCHANTCARD_ERROR,
  GETMERCHANTCARD,
  GETMERCHANT2_RESULT,
  GETMERCHANT2_ERROR,
  GETMERCHANTCARD_RESULT,
  GETMERCHANTCARD_ERROR,
  RECOVERPASSWORD,
  RECOVERPASSWORD_RESULT,
  RECOVERPASSWORD_ERROR,
  CHANGEPASSWORD,
  CHANGEPASSWORD_RESULT,
  CHANGEPASSWORD_ERROR,
  CLEAR_DATA,
  RESETCONNECTFAIL,
  SET_CHOOSE_TYPE,
  UPDATE_AVATAR,
} from "./constants";
import { sendMessage } from "../comm/sender";
import { USER_TYPE } from "../../types";
import socket from "../../lib/ws-client";

const MERCHANT_CARD_TEMPLATE = [
  "name",
  "email",
  "description",
  "website",
  "phone",
  "category",
  "subcategory",
  "address",
  "address_data",
  "location",
  "first_name",
  "last_name",
  "city",
  "company",
  "type",
  "phones",
  "country",
  "logo",
  "additional",
];

let initialState = {
  protectedReferrer: null,
  sysLocale: "",
  isAuthorized: false,
  isUserRegistered: false,
  token: null,
  loading: false,
  profileData: {},
  avatar: null,
  adOwnerData: null,
  chooseType: null,
  connectFail: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    // car index
    case SETAUTHORIZATION:
      const isAuth = localStorage.getItem("token");
      return {
        ...state,
        isAuthorized: isAuth ? true : false,
        token: action.params.token,
      };
    case SET_PROTECTED_REFERRER: {
      return { ...state, protectedReferrer: action.path };
    }
    case SET_SYS_LOCALE: {
      return { ...state, sysLocale: action.sysLocale };
    }
    case LOGOUT: {
      socket.reconnect();
      localStorage.removeItem("token");
      localStorage.removeItem("uid");
      secureLocalStorage.removeItem("email");
      secureLocalStorage.removeItem("password");
      secureLocalStorage.removeItem("userType");
      return {
        ...state,
        token: null,
        isAuthorized: false,
        isUserRegistered: false,
        profileData: {},
        avatar: null,
      };
    }
    // socket

    case REGISTERWEBUSER: {
      sendMessage("registerWebUser", [
        action.phoneNumber,
        USER_TYPE,
        parseFloat(process.env.APP_VERSION),
      ]);
      return { ...state, loading: true };
    }
    case REGISTERWEBUSER_RESULT: {
      const [uid, token] = action.params.params;
      localStorage.setItem("uid", uid);
      localStorage.setItem("token", token);
      return { ...state, isUserRegistered: true, token, loading: false }; //in case of auto login after register this should stay otherwise move it into login action
    }
    case REGISTERWEBUSER_ERROR: {
      return { ...state, isUserRegistered: false, loading: false };
    }
    case REGISTERUSER: {
      sendMessage("registerUser", [
        action.email,
        action.password,
        action.userType,
      ]);
      return { ...state, loading: true };
    }
    case REGISTERUSER_RESULT: {
      return {
        ...state,
        registrationSuccess: true,
        registrationFail: false,
        loading: false,
      };
    }
    case REGISTERUSER_ERROR: {
      return {
        ...state,
        registrationSuccess: false,
        registrationFail: true,
        loading: false,
      };
    }
    case CONFIRMTOKEN: {
      sendMessage("confirmToken", [
        action.params.email,
        action.params.token,
        action.params.type,
      ]);
      return { ...state, loading: true };
    }
    case CONFIRMTOKEN_RESULT: {
      return {
        ...state,
        emailVerifySuccess: true,
        emailVerifyFail: false,
        loading: false,
      };
    }
    case CONFIRMTOKEN_ERROR: {
      return {
        ...state,
        emailVerifySuccess: false,
        emailVerifyFail: true,
        loading: false,
      };
    }
    case CONNECT: {
      sendMessage("connect", [action.email, action.password, action.userType]);
      return { ...state, loading: true };
    }
    case CONNECT_RESULT: {
      localStorage.setItem("token", action?.params?.params[0].jwToken);
      return {
        ...state,
        isAuthorized: true,
        connectFail: false,
        loading: false,
        errorCode: null,
        connectFinished: false,
      };
    }
    case CONNECT_ERROR: {
      return {
        ...state,
        isAuthorized: false,
        connectFail: true,
        loading: false,
        errorCode: action?.params?.params[0],
        connectFinished: false,
      };
    }
    case SET_CHOOSE_TYPE: {
      return {
        ...state,
        chooseType: action?.userType,
      };
    }
    //standard user profile
    case EDITPROFILEDATA: {
      // za sutra - napraviti logiku za edit standard user data
      let transform_data = {};
      Object.keys(action.payload).map((item) => {
        if (action.payload[item] !== null) {
          transform_data = { ...transform_data, [item]: action.payload[item] };
        }
      });

      sendMessage("editProfileData", [transform_data]);
      return { ...state, loading: true };
    }
    case EDITPROFILEDATA_RESULT: {
      return { ...state, loading: false };
    }
    case EDITPROFILEDATA_ERROR: {
      return { ...state, loading: false };
    }

    case GETPROFILEDATA: {
      sendMessage("getProfileData");
      return { ...state, loading: true };
    }
    case GETPROFILEDATA_RESULT: {
      return {
        ...state,
        profileData: action.params.params[0],
        loading: false,
      };
    }
    case GETPROFILEDATA_ERROR: {
      return { ...state, loading: false };
    }
    //merchant user profile
    case ADDMERCHANTCARD: {
      const merchant_data = action.params;
      let transformed_data = {};
      Object.keys(merchant_data).map((item) => {
        if (MERCHANT_CARD_TEMPLATE.includes(item)) {
          if (item === "category") {
            return (transformed_data = { ...transformed_data, [item]: 1 });
          }
          // if (item === 'location') {
          //   return transformed_data = {
          //     ...transformed_data, [item]: {
          //       latitude: item.latitude,
          //       longitude: item.longitude,
          //     },
          //   }
          // }
          if (item === "phones" && !!merchant_data.phones) {
            let reducedPhones = [];
            merchant_data.phones.forEach((phone) => {
              if (phone.number === "") return;
              reducedPhones.push({
                type: phone.type,
                number: phone.number,
              });
            });
            if (reducedPhones.length === 0) return { ...transformed_data };
            return (transformed_data = {
              ...transformed_data,
              phones: reducedPhones,
            });
          }
          // property "type" can have value 0 and property "company" can be ""
          if (!!merchant_data[item] || item === "type" || item === "company") {
            transformed_data = {
              ...transformed_data,
              [item]: merchant_data[item],
            };
          }
        }
      });
      transformed_data.name =
        (merchant_data?.first_name ? merchant_data.first_name : "") +
        (merchant_data?.last_name
          ? merchant_data?.first_name
            ? " " + merchant_data.last_name
            : merchant_data.last_name
          : "");
      transformed_data["skipGeolocation"] = true;
      sendMessage("addMerchantCard", [transformed_data]);

      // console.log("TEST", merchant_data);
      return {
        ...state,
        loading: true,
        // profileData: {
        //   ...state.profileData,
        //   // location: {
        //   //   ...(state.profileData?.location ?? {}),
        //   //   ...(merchant_data?.location ?? {}),
        //   // },
        //   location: "PERA",
        //   TEST: "TEST",
        // },
      };
    }

    case ADDMERCHANTCARD_RESULT: {
      return { ...state, loading: false };
    }

    case ADDMERCHANTCARD_ERROR: {
      return { ...state, loading: false };
    }

    case GETMERCHANTCARD: {
      sendMessage("getMerchantCard");
      return { ...state, loading: true };
    }

    // GETMERCHANT2 is for unauthorized user
    case GETMERCHANT2_RESULT:
    case GETMERCHANTCARD_RESULT: {
      let data = action.params.params[0];

      if (data.phones === null) {
        data.phones = [{ type: "", number: "" }];
      } else {
        data.phones = data.phones.map((phone, index) => {
          return {
            id: index,
            type: phone.type,
            number: phone.number,
          };
        });
      }
      if (!data?.address_data) {
        data.address_data = {};
      }
      // case when we are fetching ad owner data we are sending
      // some additional parameter. Otherwise we will fetch logged user data
      if (action.params?.request?.params) {
        return { ...state, adOwnerData: data, loading: false };
      }
      return { ...state, profileData: data, avatar: data.logo, loading: false };
    }
    case GETMERCHANT2_ERROR:
    case GETMERCHANTCARD_ERROR: {
      return { ...state, loading: false };
    }

    case RECOVERPASSWORD: {
      sendMessage("recoverPassword", [action.email, action.userType]);
      return { ...state, loading: true };
    }
    case RECOVERPASSWORD_RESULT: {
      return {
        ...state,
        recoverPasswordSuccess: true,
        recoverPasswordFail: false,
        loading: false,
      };
    }
    case RECOVERPASSWORD_ERROR: {
      return {
        ...state,
        recoverPasswordSuccess: false,
        recoverPasswordFail: true,
        loading: false,
      };
    }

    case CHANGEPASSWORD: {
      sendMessage("changePassword", [
        action.params.email,
        action.params.password,
        action.params.type,
        action.params.hash,
      ]);
      return { ...state, loading: true };
    }
    case CHANGEPASSWORD_RESULT: {
      return {
        ...state,
        changePasswordSuccess: true,
        changePasswordFail: false,
        loading: false,
      };
    }
    case CHANGEPASSWORD_ERROR: {
      return {
        ...state,
        changePasswordSuccess: false,
        changePasswordFail: true,
        loading: false,
      };
    }

    case CLEAR_DATA: {
      action.params.forEach((value) => {
        delete state[value];
      });
      return { ...state };
    }

    case RESETCONNECTFAIL: {
      return {
        ...state,
        connectFail: false,
      };
    }
    case UPDATE_AVATAR: {
      return {
        ...state,
        avatar: action.params,
      };
    }

    default:
      return state;
  }
}

export default reducer;
