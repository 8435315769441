import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import commonEn from "../public/locales/en_CH/common.json";
import homeEn from "../public/locales/en_CH/home.json";
import navbarEn from "../public/locales/en_CH/navbar.json";
import footerEn from "../public/locales/en_CH/footer.json";
import resultsEn from "../public/locales/en_CH/results.json";
import advancedEn from "../public/locales/en_CH/advanced-search.json";
import sellEn from "../public/locales/en_CH/sell.json";
import profileEn from "../public/locales/en_CH/profile.json";
import adDetailsEn from "../public/locales/en_CH/ad-details.json";
import authEn from "../public/locales/en_CH/auth.json";
import savedSearchEn from "../public/locales/en_CH/saved-search.json";
import reportAdEn from "../public/locales/en_CH/report-ad.json";
import dealerSearchEn from "../public/locales/en_CH/dealer-search.json";
import contactUsEn from "../public/locales/en_CH/contact-us.json";

import commonDe from "../public/locales/de_CH/common.json";
import homeDe from "../public/locales/de_CH/home.json";
import navbarDe from "../public/locales/de_CH/navbar.json";
import footerDe from "../public/locales/de_CH/footer.json";
import resultsDe from "../public/locales/de_CH/results.json";
import advancedDe from "../public/locales/de_CH/advanced-search.json";
import sellDe from "../public/locales/de_CH/sell.json";
import profileDe from "../public/locales/de_CH/profile.json";
import adDetailsDe from "../public/locales/de_CH/ad-details.json";
import authDe from "../public/locales/de_CH/auth.json";
import savedSearchDe from "../public/locales/de_CH/saved-search.json";
import reportAdDe from "../public/locales/de_CH/report-ad.json";
import dealerSearchDe from "../public/locales/de_CH/dealer-search.json";
import contactUsDe from "../public/locales/de_CH/contact-us.json";

import commonIt from "../public/locales/it_CH/common.json";
import homeIt from "../public/locales/it_CH/home.json";
import navbarIt from "../public/locales/it_CH/navbar.json";
import footerIt from "../public/locales/it_CH/footer.json";
import resultsIt from "../public/locales/it_CH/results.json";
import advancedIt from "../public/locales/it_CH/advanced-search.json";
import sellIt from "../public/locales/it_CH/sell.json";
import profileIt from "../public/locales/it_CH/profile.json";
import adDetailsIt from "../public/locales/it_CH/ad-details.json";
import authIt from "../public/locales/it_CH/auth.json";
import savedSearchIt from "../public/locales/it_CH/saved-search.json";
import reportAdIt from "../public/locales/it_CH/report-ad.json";
import dealerSearchIt from "../public/locales/it_CH/dealer-search.json";
import contactUsIt from "../public/locales/it_CH/contact-us.json";

import commonFr from "../public/locales/fr_CH/common.json";
import homeFr from "../public/locales/fr_CH/home.json";
import navbarFr from "../public/locales/fr_CH/navbar.json";
import footerFr from "../public/locales/fr_CH/footer.json";
import resultsFr from "../public/locales/fr_CH/results.json";
import advancedFr from "../public/locales/fr_CH/advanced-search.json";
import sellFr from "../public/locales/fr_CH/sell.json";
import profileFr from "../public/locales/fr_CH/profile.json";
import adDetailsFr from "../public/locales/fr_CH/ad-details.json";
import authFr from "../public/locales/fr_CH/auth.json";
import savedSearchFr from "../public/locales/fr_CH/saved-search.json";
import reportAdFr from "../public/locales/fr_CH/report-ad.json";
import dealerSearchFr from "../public/locales/fr_CH/dealer-search.json";
import contactUsFr from "../public/locales/fr_CH/contact-us.json";

const DETECTION_OPTIONS = {
  order: [
    "querystring",
    "cookie",
    "localStorage",
    "sessionStorage",
    "navigator",
    "htmlTag",
    "path",
    "subdomain",
  ],
};

export const defaultLanguage = "DE";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: DETECTION_OPTIONS,
    debug: true,
    fallbackLng: defaultLanguage,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    whitelist: ["DE", "EN", "IT", "FR"],
    resources: {
      EN: {
        auth: authEn,
        common: commonEn,
        navbar: navbarEn,
        home: homeEn,
        footer: footerEn,
        results: resultsEn,
        advancedSearch: advancedEn,
        sell: sellEn,
        profile: profileEn,
        adDetails: adDetailsEn,
        savedSearch: savedSearchEn,
        reportAd: reportAdEn,
        dealerSearch: dealerSearchEn,
        contactUs: contactUsEn,
      },
      DE: {
        auth: authDe,
        common: commonDe,
        navbar: navbarDe,
        home: homeDe,
        footer: footerDe,
        results: resultsDe,
        advancedSearch: advancedDe,
        sell: sellDe,
        profile: profileDe,
        adDetails: adDetailsDe,
        savedSearch: savedSearchDe,
        reportAd: reportAdDe,
        dealerSearch: dealerSearchDe,
        contactUs: contactUsDe,
      },
      IT: {
        auth: authIt,
        common: commonIt,
        navbar: navbarIt,
        home: homeIt,
        footer: footerIt,
        results: resultsIt,
        advancedSearch: advancedIt,
        sell: sellIt,
        profile: profileIt,
        adDetails: adDetailsIt,
        savedSearch: savedSearchIt,
        reportAd: reportAdIt,
        dealerSearch: dealerSearchIt,
        contactUs: contactUsIt,
      },
      FR: {
        auth: authFr,
        common: commonFr,
        navbar: navbarFr,
        home: homeFr,
        footer: footerFr,
        results: resultsFr,
        advancedSearch: advancedFr,
        sell: sellFr,
        profile: profileFr,
        adDetails: adDetailsFr,
        savedSearch: savedSearchFr,
        reportAd: reportAdFr,
        dealerSearch: dealerSearchFr,
        contactUs: contactUsFr,
      },
    },
  });

export default i18n;

// import i18n from 'i18next';
// import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
// import { initReactI18next } from 'react-i18next';
// import HttpApi from 'i18next-http-backend';

// i18n
//   // load translation using http -> see /public/locales
//   // learn more: https://github.com/i18next/i18next-http-backend
//   .use(HttpApi)
//   // detect user language
//   // learn more: https://github.com/i18next/i18next-browser-languageDetector
//   .use(LanguageDetector)
//   // pass the i18n instance to react-i18next.
//   .use(initReactI18next)
//   // init i18next
//   // for all options read: https://www.i18next.com/overview/configuration-options
//   .init({
//     fallbackLng: 'en',
//     debug: true,

//     interpolation: {
//       escapeValue: false, // not needed for react as it escapes by default
//     },
//   });

// export default i18n;
