import {
  REGISTERUSER,
  REGISTERWEBUSER,
  LOGOUT,
  SET_PROTECTED_REFERRER,
  SET_SYS_LOCALE,
  SETAUTHORIZATION,
  EDITPROFILEDATA,
  GETPROFILEDATA,
  SETPROFILEDATA,
  CONNECT,
  SETUSERLOCATION,
  REGISTERSELLER,
  ADDMERCHANTCARD,
  SETMERCHANTCARDDATA,
  GETMERCHANTCARD,
  DISCONNECT,
  CONFIRMTOKEN,
  CLEAR_DATA,
  RESETCONNECTFAIL,
  RECOVERPASSWORD,
  CHANGEPASSWORD,
  SET_CHOOSE_TYPE,
} from "./constants";
/**
 * Tells the app we want to log in a user
 * @param { object } data The data we're sending for log in
 * @param { string } data.username The username of the user to log in
 * @param { string } data.password The password of the user to log in
 */
/**
 * Tells the app we want to register a user
 * @param { object } data The data we're sending for registration
 * @param { string } data.email The email of the user to register
 * @param { string } data.password The password of the user to register
 */

export const connect = (email, password, userType) => ({
  type: CONNECT,
  email,
  password,
  userType,
});

export const setProfileData = (key, value) => ({
  type: SETPROFILEDATA,
  key,
  value,
});

export const registerRequest = (phoneNumber) => ({
  type: REGISTERWEBUSER,
  phoneNumber,
});

export const registerUserRequest = (email, password, userType) => ({
  type: REGISTERUSER,
  email,
  password,
  userType,
});

export const confirmToken = (params) => ({ type: CONFIRMTOKEN, params });

export const changePassword = (params) => ({ type: CHANGEPASSWORD, params });

export const registerSeller = (email, password) => ({
  type: REGISTERSELLER,
  email,
  password,
});

export const logout = () => ({ type: LOGOUT });

export const setProtectedReferrer = (path) => ({
  type: SET_PROTECTED_REFERRER,
  path,
});

export const setSysLocale = (sysLocale) => ({
  type: SET_SYS_LOCALE,
  sysLocale,
});

export const setAuthorization = (params) => ({
  type: SETAUTHORIZATION,
  params,
});

export const editProfile = (payload) => ({ type: EDITPROFILEDATA, payload });

export const getProfileData = () => ({ type: GETPROFILEDATA });

export const setUserLocation = (coordiantes) => ({
  type: SETUSERLOCATION,
  coordiantes,
});

export const addMerchtCard = (params) => ({ type: ADDMERCHANTCARD, params });

export const getMerchantCard = () => ({ type: GETMERCHANTCARD });

export const setMerchantCardData = (key, value) => ({
  type: SETMERCHANTCARDDATA,
  key,
  value,
});

export const setChooseType = (userType) => ({
  type: SET_CHOOSE_TYPE,
  userType,
});

export const disconnect = () => ({ type: DISCONNECT });

export const clearData = (params) => ({ type: CLEAR_DATA, params });

export const resetConnectFail = () => ({
  type: RESETCONNECTFAIL,
});

export const recoverPassword = (email, userType) => ({
  type: RECOVERPASSWORD,
  email,
  userType,
});
