import ReconnectingWebSocket from "reconnecting-websocket";
const url =
  process.env.BACKEND_SOCKET_URL + ":" + process.env.BACKEND_SOCKET_PORT;
const socket = new ReconnectingWebSocket(url);
export default socket;

export const send = (method, params, seq) => {
  const req = JSON.stringify({ method, params, seq: seq || 999 });
  socket.send(req);
};
