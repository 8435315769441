import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, TextField } from "@material-ui/core";
import { confirmToken, clearData } from "../../../store/actions";
import SimpleLoader from "../../../components/simple-loader";
import FormControl from "../../../components/form-control/index";
import Button from "../../../components/button/index";
import ThanksPage from "../../../components/thanks-page";
import "./index.css";

const VerifyEmail = (props) => {
  const { dispatch, loading, emailVerifySuccess, emailVerifyFail } = props;
  const [payload, setPayload] = useState({
    email: "",
    type: "",
    token: "",
  });
  const { t } = useTranslation(["common", "auth"]);
  const [codeErrorFlag, setCodeErrorFlag] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const email = queryParams.get("email");
    const type = queryParams.get("type");
    const token = queryParams.get("token") ?? "";
    setPayload({
      email,
      type,
      token,
    });
    return () => {
      dispatch(clearData(["emailVerifySuccess", "emailVerifyFail"]));
    };
  }, []);

  const verifyEmailHandler = (event) => {
    const { token, email, type } = payload;
    event.preventDefault();
    if (isNaN(token) || token.toString().length !== 6) {
      setCodeErrorFlag(true);
      return null;
    } else {
      setCodeErrorFlag(false);
    }
    try {
      dispatch(confirmToken({ token, email, type }));
    } catch (e) {
      setCodeErrorFlag(true);
      return null;
    }
  };

  return (
    <SimpleLoader loading={loading}>
      <div>
        {!emailVerifySuccess ? (
          <Box className="register verifyEmail">
            <Box className="header">
              <p>{t("auth:email-code")}</p>
              <span>{t("auth:email-code-instruction-pt1")}</span>
              <br></br>
              <span>{t("auth:email-code-instruction-pt2")}</span>
            </Box>
            <form
              onSubmit={verifyEmailHandler}
              className="register-form email-code"
            >
              <Box className="flex-row-wrapper regformdiv1">
                <FormControl className="register-label primary-font-color">
                  <span>{t("common:code")}</span>
                  <TextField
                    className="email-code-wrapper"
                    value={payload?.token}
                    variant="outlined"
                    type="text"
                    placeholder={t("common:type")}
                    onChange={(event) =>
                      setPayload((prevState) => ({
                        ...prevState,
                        token: event.target.value,
                      }))
                    }
                  />
                </FormControl>
                {(codeErrorFlag || emailVerifyFail) && (
                  <span className="errorMessage">
                    {t("auth:email-code-warning")}
                  </span>
                )}
                <Button
                  type="submit"
                  color="red"
                  label={t("auth:verify-email")}
                  className="register-button email-code-wrapper"
                  click={verifyEmailHandler}
                />
              </Box>
            </form>
          </Box>
        ) : (
          <div>
            {!codeErrorFlag && !emailVerifyFail && (
              <ThanksPage texts={["auth:verified-email"]}></ThanksPage>
            )}
          </div>
        )}
      </div>
    </SimpleLoader>
  );
};

const stateToProps = (state) => {
  const { loading, emailVerifySuccess, emailVerifyFail } = state.profile;
  return {
    emailVerifyFail,
    emailVerifySuccess,
    loading,
  };
};

export default connect(stateToProps)(VerifyEmail);
