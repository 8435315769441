import { useSelector } from "react-redux";

const useSelectedValue = () => {
  const findData = useSelector((state) => state.car.find_data);

  /**
   * Find out what is selected element for the select component
   *
   * @param {[{}]} array - array of all elements
   * @param {String} propertyName - property name which can be found in one of element of array
   * @param {String} findDataPropertyName - property name in redux store car -> findData
   * @param {?Number} index - this should be number. It's indicates position of search if we are searching by multiple things
   * @return {Number} - Returns selected element. This is entire object from 'array'
   */
  const getSelectedValue = (
    array,
    propertyName,
    findDataPropertyName,
    index
  ) => {
    const id = [null, undefined].includes(index)
      ? findData[findDataPropertyName]
      : findData[findDataPropertyName]?.[index];
    return array.find((item) => {
      return item[propertyName] === (id ?? -1);
    });
  };

  return { getSelectedValue };
};

export default useSelectedValue;
