import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { chunkArray } from "../../../lib/utils";
import { resetCarModel, getCarModel } from "../../../store/actions";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { ArrowDownBlack, backIcon } from "../../../components/svg-icons/index";
import "./index.css";

const BrandAndModel = (props) => {
  const { make, car_model, dispatch } = props;
  const { t, i18n } = useTranslation(["footer", "common"]);
  const history = useHistory();
  const [expanded, setExpanded] = useState(null);
  const [hoveredMake, setHoveredMake] = useState(null);
  const [clickedMakeId, setClickedMakeId] = useState(null);
  const [clickedModelId, setClickedModelId] = useState(null);

  const goBack = () => {
    history.goBack();
  };

  const handleAccordionEnter = (idmake) => {
    setClickedMakeId(idmake);
    setExpanded(idmake);
    setHoveredMake(idmake);
    dispatch(getCarModel(idmake, 0));
  };

  const handleAccordionLeave = () => {
    setExpanded(null);
    setHoveredMake(null);
    dispatch(resetCarModel());
  };

  const handleMakeClick = (idmake) => {
    const clickedMake = make.find((item) => item.idmake === idmake);
    if (clickedMake && clickedMake.name) {
      history.push(`/${i18n.language}/results/${clickedMake.name}`);
    }
  };

  const handleModelClick = (idmodel) => {
    setClickedModelId(idmodel);
    const clickedModel = car_model.find((model) => model.idmodel === idmodel);
    if (clickedModel && clickedModel.name && clickedMakeId !== null) {
      const clickedMake = make.find((item) => item.idmake === clickedMakeId);
      if (clickedMake && clickedMake.name) {
        history.push(
          `/${i18n.language}/results/${clickedMake.name}/${clickedModel.name}`
        );
      }
    }
  };

  useEffect(() => {
    dispatch(resetCarModel());
  }, []);

  return (
    <div className="brand-and-model">
      <header>
        <div className="go-back" onClick={goBack}>
          {backIcon}
          <p className="no-margin flex">{t("common:back")}</p>
        </div>
        <h3>
          {t("footer:brand-and-model")} {t("footer:overview")}
        </h3>
      </header>
      <main>
        {chunkArray(make, 25).map((chunk, index) => {
          return (
            <div key={index}>
              {chunk.map((item) => {
                return (
                  <Accordion
                    key={item?.idmake}
                    onMouseEnter={() => handleAccordionEnter(item?.idmake)}
                    onMouseLeave={handleAccordionLeave}
                    expanded={expanded === item?.idmake}
                  >
                    <AccordionSummary
                      expandIcon={ArrowDownBlack}
                      aria-controls={item?.name}
                      id={item?.idmake}
                      onClick={() => handleMakeClick(item?.idmake)}
                    >
                      <h4 className="subtitle">{item?.name}</h4>
                    </AccordionSummary>
                    <AccordionDetails>
                      {hoveredMake === item?.idmake &&
                        car_model.map((model) => (
                          <a
                            key={model.idmodel}
                            onClick={() => handleModelClick(model.idmodel)}
                          >
                            {"\u2022 "}
                            {model.name}
                          </a>
                        ))}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </div>
          );
        })}
      </main>
    </div>
  );
};

const stateToProps = (state) => {
  const { make, car_model } = state.car;
  return {
    make,
    car_model,
  };
};

export default connect(stateToProps)(BrandAndModel);
