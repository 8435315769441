import React, { useState, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import { group, PhotoPlaceholder, statistics } from "../../svg-icons/index";
import Delete from "../../../assets/shapes/delete.svg";
import Edit from "../../../assets/shapes/edit.svg";
import Publish from "../../../assets/shapes/publish.svg";
import { useTranslation } from "react-i18next";
import Button from "../../../components/button/index";
import Image from "../../../components/image/index";
import { numberWithCommas, toDateFormat } from "../../../lib/utils";
import "./index.css";

const Draft = (props) => {
  const {
    photos,
    previewAd,
    idad,
    price,
    makename,
    modelname,
    createdat,
    openDialog,
    generaldata,
  } = props;
  const { t, i18n } = useTranslation("common");
  const [showMenu, setShowMenu] = useState(false);
  const lastModified = new Date(createdat);
  const history = useHistory();

  const showMoreOptions = () => {
    setShowMenu(true);
    setTimeout(() => {
      setShowMenu(false);
    }, 3000);
  };

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }

  const window_size = useWindowSize();

  return (
    <>
      {window_size[0] < 1024 ? (
        <div className="small-draft">
          <section>
            <div className="imageContainer" onClick={() => previewAd(idad)}>
              {photos ? (
                <Image src={photos[0]} alt="Ad photo" />
              ) : (
                <PhotoPlaceholder />
              )}
            </div>
            <Button
              color="gray"
              label={`CHF ${price ? price : null}.-`}
              className="my-car-button"
              click={() => previewAd(idad)}
            />
          </section>
          <section>
            <div>
              <div className="makeandmodel">
                <h4>
                  {props.makename}
                  {props.modelname}
                </h4>
                <h4>
                  {generaldata?.equipmentline
                    ? `${generaldata.equipmentline}`
                    : ""}{" "}
                  {generaldata?.variant ? `${generaldata.variant}` : ""}
                </h4>
              </div>
              <span>
                {showMenu ? (
                  <div className="more-options">
                    <div onClick={() => openDialog("publish", idad)}>
                      <img src={Publish} alt="" />
                      <span>{t("common:publish")}</span>
                    </div>
                    <div
                      onClick={() =>
                        history.push(`/${i18n.language}/ad/${idad}/edit`)
                      }
                    >
                      <img src={Edit} alt="" />
                      <span>{t("common:edit-ad")}</span>
                    </div>
                    <div onClick={() => openDialog("delete", idad)}>
                      <img src={Delete} alt="" />
                      <span>{t("common:delete")}</span>
                    </div>
                  </div>
                ) : (
                  <span onClick={showMoreOptions} className="gorup">
                    {group}
                  </span>
                )}
              </span>
            </div>
            <div>
              <Button
                color="gray"
                label={t("common:draft")}
                className="draft-button"
              />
              <p>
                {t("common:last-modified")}: {toDateFormat(lastModified)}
              </p>
              <p>Ad ID No: {idad}</p>
              <span>
                <p>{t("common:lowest-price-for-similar")}: </p>
                <p>{`CHF ${numberWithCommas()}-`}</p>
              </span>
              <span>
                <p>{t("common:highest-price-for-similar")}: </p>
                <p>{`CHF ${numberWithCommas()}-`}</p>
              </span>
            </div>
          </section>
        </div>
      ) : (
        <div className="big-draft">
          <section onClick={() => previewAd(idad)}>
            <div className="imageContainer">
              {photos ? (
                <Image src={photos[0]} alt="Ad photo" />
              ) : (
                <span>
                  <PhotoPlaceholder />
                </span>
              )}
            </div>
          </section>
          <section>
            <div>
              <div className="makeandmodel">
                <h4>
                  {makename}
                  {modelname}
                </h4>
                <h4>
                  {generaldata?.equipmentline ?? ""}{" "}
                  {generaldata?.variant ?? ""}
                </h4>
              </div>
              <span className={`${showMenu ? "full-height" : ""}`}>
                {showMenu ? (
                  <div className="more-options full-height">
                    <div onClick={() => openDialog("publish", idad)}>
                      <img src={Publish} alt="" />
                      <span>{t("common:publish")}</span>
                    </div>
                    <div
                      onClick={() =>
                        history.push(`/${i18n.language}/ad/${idad}/edit`)
                      }
                    >
                      <img src={Edit} alt="" />
                      <span>{t("common:edit-ad")}</span>
                    </div>
                    <div onClick={() => openDialog("delete", idad)}>
                      <img src={Delete} alt="" />
                      <span>{t("common:delete")}</span>
                    </div>
                  </div>
                ) : (
                  <span onClick={showMoreOptions} className="gorup">
                    {group}
                  </span>
                )}
              </span>
            </div>
            <div className="mycar-main">
              <span>
                {statistics}
                <p>{t("common:statistics")}</p>
              </span>
              <div>
                <div>
                  <p>
                    {t("common:no-of-views")}: <span>-</span>
                  </p>
                  <p>
                    {t("common:no-of-msgs")}: <span>-</span>
                  </p>
                  <p>
                    {t("common:no-of-favs")}: <span>-</span>
                  </p>
                </div>
                <div>
                  <p className="adid">Ad ID No: {idad}</p>
                  <p>
                    {t("common:last-modified")}: {toDateFormat(lastModified)}
                  </p>
                </div>
              </div>
            </div>
            <div className="mycar-footer">
              <Button
                color="gray"
                label={`CHF ${numberWithCommas(price)}.-`}
                className="my-car-button"
                click={() => previewAd(idad)}
              />
              <div>
                <span>
                  <p>{t("common:lowest-price-for-similar")}:</p>
                  <p>{`CHF ${numberWithCommas()}-`}</p>
                </span>
                <span>
                  <p>{t("common:highest-price-for-similar")}:</p>
                  <p>{`CHF ${numberWithCommas()}-`}</p>
                </span>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default Draft;
