import React from "react";
import Navbar from "../navbar/index";
import Footer from "../footer/index";
import "./index.css";

const Layout = (props) => {
  const { children } = props;

  return (
    <div className="layout">
      <div className="nav-wrapper">
        <div className="corner-tag">
          <span>BETA</span>
        </div>
        <div className="nav-layout" id="ttt">
          <Navbar />
        </div>
      </div>
      <div className="main-layout">{children}</div>
      <div className="footer-layout">
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
