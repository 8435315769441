import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";

import FirstImageWithButton from "./components/image-with-button";
// import SecondImageWithText from "./components/image-with-text";
import image1 from "../../../assets/images/carousel-home/image1.jpg";
import image2 from "../../../assets/images/carousel-home/image2.jpg";
import image3 from "../../../assets/images/carousel-home/image3.jpg";
import image4 from "../../../assets/images/carousel-home/image4.jpg";
import image5 from "../../../assets/images/carousel-home/image5.jpg";

import "swiper/css";
import "swiper/css/pagination";
import "./index.css";

const CarouselHome = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation(["home"]);

  const handleButtonClick = () => {
    history.push(`/${i18n.language}/sell/`);
  };

  const sliders = [
    { component: <FirstImageWithButton onClick={handleButtonClick} /> },
    // { component: <SecondImageWithText /> }, //this twint image disabled until payment is done on app
    { img: image1 },
    { img: image2 },
    { img: image3 },
    { img: image4 },
    { img: image5 },
  ];

  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        centeredSlides={true}
        loop={true}
        pagination={{
          clickable: true,
          dynamicBullets: true,
          dynamicMainBullets: 4,
        }}
        navigation={false}
        modules={[Autoplay, Pagination]}
        autoplay={{
          delay: 7000,
          disableOnInteraction: false,
        }}
      >
        {sliders.map((item, index) => (
          <SwiperSlide key={index}>
            {item.component ? item.component : <img src={item.img} />}
          </SwiperSlide>
        ))}
        <div className="home-header-text">
          <h4 className="home-banner">{t("home:home-banner")}</h4>
        </div>
      </Swiper>
    </>
  );
};

export default CarouselHome;
