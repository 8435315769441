import React from "react";
import "./index.css";

const FilterSection = (props) => {
  const { title, children, className } = props;
  return (
    <section className={`filter-section`}>
      {title ? <h5>{title}</h5> : null}
      <div className={className}>{children}</div>
    </section>
  );
};

export default FilterSection;
