import { useTranslation } from "react-i18next";

export const getReasonForRequest = () => {
  const { t } = useTranslation("contactUs");
  return [
    { name: t("contactUs:login-register") },
    { name: t("contactUs:payment-invoice-reminder") },
    { name: t("contactUs:account-settings") },
    { name: t("contactUs:report-an-error") },
    { name: t("contactUs:report-abuse") },
    { name: t("contactUs:contract") },
    { name: t("contactUs:request-offer") },
    { name: t("contactUs:provider-rating") },
    { name: t("contactUs:interface-import-info") },
    { name: t("contactUs:others") },
  ];
};

export const getCustomerGroup = () => {
  const { t } = useTranslation("contactUs");
  return [
    { name: t("contactUs:private-customer") },
    { name: t("contactUs:corporate-customer") },
  ];
};

export const getRequiredFields = (
  t,
  customer,
  reasonRequest,
  body,
  companySelected
) => [
  {
    key: "customer_name",
    condition: customer && customer.name && customer.name !== "",
  },
  {
    key: "reason_request",
    condition: reasonRequest && reasonRequest.name && reasonRequest.name !== "",
  },
  {
    key: "company_name",
    condition: !companySelected || body.company_name !== "",
    message: t("common:required-error-message"),
  },
  {
    key: "streetandnumber",
    condition: body.streetandnumber !== "",
    message: t("common:required-error-message"),
  },
  {
    key: "zipcode",
    condition: body.zipcode !== "",
    message: t("common:required-error-message"),
  },
  {
    key: "city",
    condition: body.city !== "",
    message: t("common:required-error-message"),
  },
  {
    key: "phone_number",
    condition: body?.phone_number?.trim() !== "",
    message: t("common:required-error-message"),
  },
  {
    key: "email",
    condition: body.email !== "",
    message: t("auth:email-is-required"),
  },
];
