import Home from "./screens/home";
import Results from "./screens/results";
import Favourites from "./screens/favourites";
import MyCars from "./screens/mycars";
import SavedSearch from "./screens/saved-search";
import Details from "./screens/details";
import Sell from "./screens/sell/index";
import AdvancedSearch from "./screens/advanced-search/";
import UserProfile from "./screens/profile/";
import SingUp from "./screens/auth/signup";
import SignIn from "./screens/auth/signin";
import VerifyEmail from "./screens/auth/verify-email";
import RecoverPassword from "./screens/auth/recover-password";
import ChangePassword from "./screens/auth/change-password";
import ReportAd from "./screens/report-ad/";
import TermsAndConditions from "./screens/footer-links/terms-conditions";
import InsertionRules from "./screens/footer-links/insertion-rules";
import Impressum from "./screens/footer-links/impressum";
import SafetyInstructions from "./screens/footer-links/safety-instructions";
import PrivacyProtection from "./screens/footer-links/privacy-protection";
import BrandAndModel from "./screens/footer-links/brand-and-model";
import Cars from "./screens/footer-links/cars";
import Newsletter from "./screens/footer-links/newsletter";
import ContactUs from "./screens/contact-us";
import Company from "./screens/footer-links/company";
import DealerSearch from "./screens/dealer-search";
import DealerResults from "./screens/dealer-search/01-dealer-results";

const navConfig = {
  routes: [
    { path: "/home", component: Home, exact: true, defautl: true },
    {
      path: "/favorites",
      component: Favourites,
      exact: true,
      protected: true,
      userTypes: [1, 2],
    },
    { path: "/my-cars", component: MyCars, protected: true, userTypes: [2] },
    {
      path: "/user-profile",
      component: UserProfile,
      protected: true,
      userTypes: [1, 2],
    },
    {
      path: "/saved-searches",
      component: SavedSearch,
      exact: false,
      protected: true,
      userTypes: [1, 2],
    },
    { path: "/sell", component: Sell, protected: true, userTypes: [2] },
  ],
  authRoutes: [
    { label: "Logout", path: "/signout", icon: "logout", fn: "logout" },
    { label: "SingUp", path: "/signup", component: SingUp, protected: false },
    { label: "SignIn", path: "/signin", component: SignIn, protected: false },
    {
      label: "VerifyEmail",
      path: "/verify-email/:hash",
      component: VerifyEmail,
      protected: false,
    },
    {
      label: "RecoverPassword",
      path: "/recover-password",
      component: RecoverPassword,
      protected: false,
    },
    {
      label: "ChangePassword",
      path: "/change-password/:hash",
      component: ChangePassword,
      protected: false,
    },
  ],
  additionalRoutes: [],
  footerRoutes: [
    { path: "/about-us/company", component: Company },
    { path: "/legal/terms-and-conditions", component: TermsAndConditions },
    { path: "/legal/insertion-rules", component: InsertionRules },
    { path: "/legal/impressum", component: Impressum },
    { path: "/legal/safety-instructions", component: SafetyInstructions },
    { path: "/legal/privacy-protection", component: PrivacyProtection },
    { path: "/brand-model/", component: BrandAndModel },
    { path: "/cars/", component: Cars },
    { path: "/newsletter/", component: Newsletter },
  ],

  notInMenuRoutes: [
    {
      path: "/ad/:idad/edit",
      component: Sell,
      protected: true,
      userTypes: [2],
    },
    {
      path: "/ad/:idad/report",
      component: ReportAd,
      protected: true,
      userTypes: [1],
    },
    {
      path: "/contact-us/",
      component: ContactUs,
    },
    { path: "/ad/:idad", exact: true, component: Details },
    { path: "/ad", exact: true, component: Details },
    { path: "/advanced-search", component: AdvancedSearch },
    { path: "/dealer-search", component: DealerSearch },
    { path: "/dealer-results/:city", component: DealerResults },
    { path: "/dealer-results", component: DealerResults },
    { path: "/seller/:uid", exact: true, component: Results },
    { path: "/results/:makeNameUrl/:modelNameUrl/", component: Results },
    { path: "/results/:makeNameUrl/", component: Results },
    { path: "/results/", component: Results },
  ],
};

export default navConfig;
