import React, { useEffect, useState } from "react";
import { LinearProgress } from "@material-ui/core";
import {
  CustomDialog,
  CustomDialogActions,
  CustomDialogTitle,
  CustomDialogContent,
  CustomCheckbox,
} from "./custom";
import Button from "../button/index";
import { emailRegex } from "../../lib/utils";
import Logo from "../../assets/images/new-logo.svg";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import "./index.css";
import { useTranslation } from "react-i18next";

export const ContactFormDialog = ({ isOpen, onClose }) => {
  const { t } = useTranslation([
    "common, adDetails",
    "profile",
    "auth",
    "footer",
    "reportAd",
  ]);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [canProceed, setCanProceed] = useState(false);
  const [errors, setErrors] = useState({});
  const [isMessageSent, setIsMessageSent] = useState(false);
  const [isInProgress, setIsInProgress] = useState(false);
  const [isCopyEmailRequested, setIsCopyEmailRequested] = useState(false);
  const [isDataProtectionAccepted, setIsDataProtectionAccepted] =
    useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  useEffect(() => {
    const newErrors = {};
    if (email.trim() === "") {
      newErrors.email = t("auth:email-is-required");
    } else if (!emailRegex.test(email)) {
      newErrors.email = t("auth:validation-email-message");
    }

    if (message.trim() === "") {
      newErrors.message = t("auth:message-is-required");
    }

    if (!captchaToken) {
      newErrors.captcha = t("auth:invalid-captcha");
    }

    setErrors(newErrors);
  }, [email, message, captchaToken]);

  useEffect(() => {
    setCanProceed(
      !Boolean(Object.keys(errors).length) && isDataProtectionAccepted
    );
  }, [errors, isDataProtectionAccepted]);

  useEffect(() => {
    clearForm();
    setIsMessageSent(false);
  }, [isOpen]);

  const clearForm = () => {
    setCaptchaToken(null);
    setEmail("");
    setMessage("");
    setIsCopyEmailRequested(false);
    setIsDataProtectionAccepted(false);
  };

  const handleSubmit = () => {
    if (canProceed) {
      setIsInProgress(true);
      setTimeout(() => {
        setIsMessageSent(true);
        setIsInProgress(false);
        clearForm();
      }, 2000);
      const payload = {
        email,
        message,
        isCopyEmailRequested,
        isDataProtectionAccepted,
        token: captchaToken,
      };
      console.log("Send request, payload: ", payload);
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleVerificationSuccess = (token) => {
    setCaptchaToken(token);
  };

  return (
    <CustomDialog
      open={isOpen || isInProgress}
      onClose={handleClose}
      className="contact-form-dialog"
      maxWidth="lg"
    >
      <CustomDialogTitle>
        {t("adDetails:send-email-to-seller")}
        <div className="btn-close" onClick={handleClose}>
          {t("common:close")} <HighlightOffIcon style={{ fontSize: "2rem" }} />
        </div>
      </CustomDialogTitle>

      <CustomDialogContent>
        <div className="progress-wrapper">
          {isInProgress && <LinearProgress color="secondary" />}
        </div>

        {isMessageSent ? (
          <div className="success-message">
            <div>{t("auth:thank-you")}!</div>
            <div>{t("auth-your-message-has-been-successfully-sent")}</div>
            <div className="success-message--with-logo">
              <img src={Logo} alt="" />
            </div>
          </div>
        ) : (
          <>
            <div className="text-input">
              <label>{t("profile:email-address")}</label>
              <input
                className="text-input__email"
                type="email"
                onInput={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="text-input">
              <label>{t("common:message")}</label>
              <textarea
                type="text"
                className="text-input__message"
                value={message}
                onInput={(e) => setMessage(e.target.value)}
                rows={6}
              />
            </div>

            <div className="checkbox-control">
              <CustomCheckbox
                size="small"
                checked={isCopyEmailRequested}
                onChange={() => setIsCopyEmailRequested((value) => !value)}
              />
              <label>{t("auth:email-copy-to-myself")}</label>
            </div>

            <div className="checkbox-control">
              <CustomCheckbox
                size="small"
                checked={isDataProtectionAccepted}
                onChange={() => setIsDataProtectionAccepted((value) => !value)}
              />
              <label>
                {t("contactUs:accept-data-protection-regulations-of")}CARINDEX
                AG."
              </label>
            </div>

            <div className="captcha-wrapper">
              <HCaptcha
                sitekey={process.env.HCAPTCHA_SITE_KEY}
                onVerify={(token, ekey) =>
                  handleVerificationSuccess(token, ekey)
                }
              />
            </div>
          </>
        )}

        <CustomDialogActions>
          {!isMessageSent && (
            <Button
              label={t("reportAd:send")}
              color={"red"}
              click={handleSubmit}
              disabled={!canProceed || isInProgress}
              className="btn"
            />
          )}
        </CustomDialogActions>
      </CustomDialogContent>
    </CustomDialog>
  );
};
