import React from "react";
import vw from "../../assets/images/brands/vw.png";
import audi from "../../assets/images/brands/audi.png";
import bmw from "../../assets/images/brands/bmw.png";
import fiat from "../../assets/images/brands/fiat.png";
import ford from "../../assets/images/brands/ford.png";
import mercedes from "../../assets/images/brands/mercedes.png";
import opel from "../../assets/images/brands/opel.png";
import renault from "../../assets/images/brands/renault.png";

import "./index.css";

const PopularBrands = (props) => {
  const { handler, message, modelIndex } = props;

  const brands = [
    { label: "Bmw", src: bmw, idmake: 10 },
    { label: "Volkswagen", src: vw, idmake: 92 },
    { label: "Audi", src: audi, idmake: 7 },
    { label: "Mercedes-Benz", src: mercedes, idmake: 58 },
    { label: "Ford", src: ford, idmake: 33 },
    { label: "Opel", src: opel, idmake: 66 },
    { label: "Fiat", src: fiat, idmake: 31 },
    { label: "Renault", src: renault, idmake: 72 },
  ];

  return (
    <div className="popular-brands">
      <p className="message">{message}</p>
      <div className="brands">
        {brands.map((item, index) => {
          return (
            <div
              className="popular-brand"
              key={index}
              onClick={() =>
                handler("idMake", item.idmake, modelIndex, item.label)
              }
            >
              <span className="brand-image-container">
                <img src={item.src} />
              </span>
              <p>{item.label}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PopularBrands;
