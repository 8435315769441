import React from "react";
import FormControl from "../form-control";
import { useTranslation } from "react-i18next";
import Button from "../button";
import Radio from "../radio";
import "../merchant-form/index.css";
import "./index.css";;

const UserForm = (props) => {
  const { localProfileData, inputChangeHandler, errorMessages } = props;
  const { t } = useTranslation(["common", "profile"]);

  return (
    <div className="contact-form">
      <FormControl className={"d-flex"}>
        <Radio
          name={"gender"}
          value={"m"}
          className={"radio-button-target"}
          label={t("profile:mr")}
          checked={localProfileData?.gender === "m"}
          changeHandler={(e) =>
            inputChangeHandler(e.target.name, e.target.value)
          }
        />
        <Radio
          name={"gender"}
          value={"f"}
          className={"radio-button-target"}
          label={t("profile:ms")}
          checked={localProfileData?.gender === "f"}
          changeHandler={(e) =>
            inputChangeHandler(e.target.name, e.target.value)
          }
        />
      </FormControl>
      <FormControl
        inputType="textfield"
        name="first_name"
        label={t("profile:first-name")}
        type="text"
        placeholder={t("common:type")}
        id="first-name-scroll-target"
        errorMessage={t(
          `${errorMessages?.first_name ? errorMessages?.first_name : ""}`
        )}
        errorMessageClassName={"color-red"}
        defaultValue={localProfileData?.first_name}
        handleChange={(e) => inputChangeHandler(e.target.name, e.target.value)}
      />
      <FormControl
        inputType="textfield"
        name="last_name"
        label={t("profile:last-name")}
        type="text"
        placeholder={t("common:type")}
        id="last-name-scroll-target"
        errorMessage={t(
          `${errorMessages?.last_name ? errorMessages?.last_name : ""}`
        )}
        errorMessageClassName={"color-red"}
        defaultValue={localProfileData?.last_name}
        handleChange={(e) => inputChangeHandler(e.target.name, e.target.value)}
      />
      <FormControl
        inputType="textfield"
        name="address"
        label={t("profile:address")}
        type="text"
        placeholder={t("common:type")}
        defaultValue={localProfileData?.address}
        handleChange={(e) => inputChangeHandler(e.target.name, e.target.value)}
      />
      <FormControl
        inputType="textfield"
        name="contact_phone"
        type="text"
        label={t("profile:phone-num-example")}
        placeholder={t("common:type")}
        defaultValue={localProfileData?.contact_phone ?? ""}
        handleChange={(e) => inputChangeHandler(e.target.name, e.target.value)}
      />
      <FormControl
        inputType="date"
        name="birth_date"
        type="date"
        label={t("profile:birth-date")}
        defaultValue={localProfileData.birth_date ?? ""}
        handleChange={(e) => inputChangeHandler(e.target.name, e.target.value)}
      />
      <p className="email-address">
        {t("profile:email-address")}
        <span> {localProfileData?.email && localProfileData?.email} </span>
      </p>
      <Button
        color="white"
        className="contact-btn"
        label={t("profile:contact-us-for-support")}
      />
    </div>
  );
};

export default UserForm;
