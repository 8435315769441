import React from "react";
import ReactDOM from "react-dom/client";
import { Router } from "react-router";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import reducers from "./store/reducers";
import history from "./lib/history";
import App from "./App";
import { HelmetProvider } from "react-helmet-async";
import "./assets/theme/index.css";
import "./i18n";

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);

ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <HelmetProvider>
      <Router history={history}>
        <App history={history} />
      </Router>
    </HelmetProvider>
  </Provider>
);
