import React from "react";
import { withRouter } from "react-router";
import Logo from "../../assets/images/new-logo.svg";
import "./index.css";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.redirectToHome = this.redirectToHome.bind(this);
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }
  redirectToHome() {
    this.setState({ hasError: false });
    this.props.history.push("/");
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary">
          <div className="error-boundary__wrapper">
            <img src={Logo} alt="Logo" />
            <h2>Whoops, something went wrong.</h2>
            <p>Please either refresh the page or return home to try again.</p>
            <button onClick={this.redirectToHome}>Go home</button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
