import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { resetConnectFail } from "../../../../../store/actions";

const SignInError = (props) => {
  const { errorCode, connectFail, validationMessages, dispatch } = props;
  const { t } = useTranslation(["common", "auth"]);
  const loginErrorMessage = "auth:incorrect-email-or-password";

  let error = null;
  if (validationMessages?.emailOrPasswordError) {
    error = validationMessages.emailOrPasswordError;
  }
  if (
    !validationMessages?.emailOrPasswordError &&
    connectFail &&
    errorCode === 2
  ) {
    error = loginErrorMessage;
  }
  if (
    !validationMessages?.emailOrPasswordError &&
    connectFail &&
    errorCode !== 2
  ) {
    error = "common:global-error";
  }

  useEffect(() => {
    return () => {
      dispatch(resetConnectFail());
    };
  }, [dispatch]);

  return error && <span className="errorMessage">{t(error)}</span>;
};

const stateToProps = (state) => {
  const { errorCode, connectFail } = state.profile;
  return {
    errorCode,
    connectFail,
  };
};

export default connect(stateToProps)(SignInError);
