import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  setTargaVehicleType,
  getVehiclesForCode18,
  getVehiclesForCode24,
  resetVehicleData18And24,
} from "../../../../../store/actions";
import Button from "../../../../../components/button";
import Radio from "../../../../../components/radio";
import { backIcon } from "../../../../../components/svg-icons/index";
import {
  getPropName,
  removeDotsAndSpaces,
  formatTargaDate,
} from "../../../../../lib/utils";
import "./index.css";

const TargaForMobileView = (props) => {
  const {
    dispatch,
    vehicleData,
    setIsOnTargaMobileView,
    body_type,
    fuel_type,
    transmission,
    drive_type,
    doors,
    targaCode18,
    targaCode24,
    goToNextPage,
    setProcedeToMakePage,
  } = props;

  const [selectedVehicleIndex, setSelectedVehicleIndex] = useState(null);
  const { t } = useTranslation(["common", "sell", "advancedSearch"]);
  const numberOfTargaVehicles = vehicleData.length;
  const modelNameFromTarga =
    vehicleData.length > 0 ? vehicleData[0].modkurzbez : null;

  const handleRadioChange = (index) => {
    setSelectedVehicleIndex(index);
  };

  const goBackToIdentifyVehicle = () => {
    setProcedeToMakePage(false);
    dispatch(resetVehicleData18And24());
  };

  //event listener to track if screen width is for mobile or web
  //bc other components on activePage 1 needs to have status of isOnTargaMobileView
  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 480px)");
    const handleMediaQueryChange = (e) => {
      setIsOnTargaMobileView(e.matches);
    };
    // Initial check
    handleMediaQueryChange(mediaQuery);
    mediaQuery.addEventListener("change", handleMediaQueryChange);
    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, [setIsOnTargaMobileView]);

  return (
    <div className="targa-for-mobile">
      <header>
        <span onClick={goBackToIdentifyVehicle}>
          {backIcon} <p>{t("sell:there-are")}</p> <p>{numberOfTargaVehicles}</p>{" "}
          <p>{t("sell:different")}</p>
          <p> {modelNameFromTarga}</p>
        </span>
      </header>
      <main>
        {vehicleData.map((vehicle, index) => (
          <React.Fragment key={index}>
            <div className="targa-vehicle-card">
              <span className="radio-with-headline">
                <Radio
                  name="vehicle"
                  value={index}
                  className="radio-button-target"
                  label={vehicle.modelltypen}
                  changeHandler={() => handleRadioChange(index)}
                  checked={selectedVehicleIndex === index}
                />
              </span>
              <div className="main-details">
                <div className="section-left">
                  <p>{t("common:power")}:</p>
                  <p>{t("sell:targa-doors")}:</p>
                  <p>{t("common:transmission")}:</p>
                  <p>{t("common:fuel")}:</p>
                </div>
                <div className="section-right">
                  <p>
                    {vehicle.ps} ({vehicle.kw} Kw)
                  </p>
                  <p>{getPropName(doors, vehicle.türen, "iddoors")}</p>
                  <p>
                    {getPropName(
                      transmission,
                      vehicle.getriebe,
                      "idtransmission"
                    )}
                  </p>
                  <p>
                    {getPropName(fuel_type, vehicle.treibstoff, "idfueltype")}
                  </p>
                </div>
              </div>
              {selectedVehicleIndex === index && (
                <div className="additional-info-mobile">
                  <div className="type-drivetype">
                    <div>
                      <p>{t("sell:targa-body-type")}:</p>
                      <p>{t("common:drivetrain")}:</p>
                    </div>
                    <div>
                      <p>
                        {getPropName(body_type, vehicle.aufbau, "idbodytype")}
                      </p>
                      <p>
                        {getPropName(
                          drive_type,
                          vehicle.antrieb,
                          "iddrivetype"
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="under-borderline-section">
                    <span>
                      <p>{t("sell:type-approval-numbers")}:</p>{" "}
                      <p>{targaCode24 || targaCode18}</p>
                    </span>
                    <span>
                      {" "}
                      <p>{t("common:kerb-weight")}:</p>
                      <p>{vehicle.gewleer ? `${vehicle.gewleer} kg` : "-"}</p>
                    </span>
                    <span>
                      <p>{t("sell:month-and-year-of-constr-short")}:</p>
                      <p>
                        {formatTargaDate(vehicle.prodvon)} -{" "}
                        {formatTargaDate(vehicle.prodbis)}
                      </p>
                    </span>
                  </div>
                  <Button
                    color="red"
                    click={() => {
                      goToNextPage(() => {
                        dispatch(setTargaVehicleType(vehicle.typen));
                        const func = targaCode18
                          ? getVehiclesForCode18
                          : getVehiclesForCode24;
                        const transformedTargaCode = removeDotsAndSpaces(
                          targaCode18 ?? targaCode24
                        );
                        dispatch(func(transformedTargaCode, true));
                      });
                    }}
                    label={t("common:next")}
                  />
                </div>
              )}
            </div>
          </React.Fragment>
        ))}
      </main>
    </div>
  );
};

const stateToProps = (state) => {
  const {
    body_type,
    fuel_type,
    transmission,
    drive_type,
    doors,
    targaCode18,
    targaCode24,
  } = state.car;
  return {
    body_type,
    fuel_type,
    transmission,
    drive_type,
    doors,
    targaCode18,
    targaCode24,
  };
};

export default connect(stateToProps)(TargaForMobileView);
