import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/button";
import ArrowRightSvg from "../../../assets/shapes/expand-arrow-right-small.svg";
import history from "../../../lib/history";

import "./index.css";

const Impressum = () => {
  const { t, i18n } = useTranslation(["footer"]);

  const handleButtonClick = () => {
    history.push(`/${i18n.language}/about-us/company`);
  };

  const goToContactUsPage = () => {
    history.push(`/${i18n.language}/contact-us/`);
  };

  return (
    <div className="impressum">
      <header>
        <h3>{t("footer:impressum-header")}</h3>
      </header>
      <main>
        <p>
          {t("footer:impressum-main-1-1")}
          <br />
          <a href={`/${i18n.language}/about-us/company`} target="_blank">
            <Button
              click={(e) => {
                if (e.button === 1) {
                  window.open("/company", "_blank");
                } else if (e.button === 0) {
                  e.preventDefault();
                  handleButtonClick();
                }
              }}
              label={t("common:read-more")}
            >
              <span>
                <img src={ArrowRightSvg} alt="Arrow Right" />
                <img src={ArrowRightSvg} alt="Arrow Right" />
              </span>
            </Button>
          </a>
        </p>
        <div className="section-2">
          <p className="section-heading">
            {t("footer:impressum-main-2-heading")}
          </p>
          <div className="section-body">
            <div className="section-body-box">
              <div className="section-body-item caption">CARINDEX AG</div>
              <div className="section-body-item">Arnold Baumgartner-Str. 9</div>
              <div className="section-body-item">CH-2540 Grenchen</div>
            </div>
            <div className="section-body-box">
              <div className="section-body-row">
                <div className="section-body-item caption">
                  {t("footer:impressum-telephone")}:
                </div>
                <div className="section-body-item">+41 58 689 65 05</div>
              </div>
              <div className="section-body-row">
                <div className="section-body-item caption">E-Mail:</div>
                <div className="section-body-item">support@carindex.ch</div>
              </div>
              <div className="section-body-row">
                <div className="section-body-item caption">Web:</div>
                <div className="section-body-item">www.carindex.ch</div>
              </div>
            </div>
            <div className="section-body-box">
              <div className="section-body-row">
                <div className="section-body-item caption">
                  {t("footer:management")}:
                </div>
                <div className="section-body-item">Luca Prota</div>
              </div>
              <div className="section-body-row">
                <div className="section-body-item caption">UID:</div>
                <div className="section-body-item">CHE-170.585.380</div>
              </div>
              <div className="section-body-row">
                <div className="section-body-item caption">
                  {t("footer:vat")}:
                </div>
                <div className="section-body-item">{t("footer:vat-num")}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-3">
          <p>
            {t("footer:impressum-main-3-1")}
            <a
              href={`${i18n.language}/contact-us/`}
              onClick={(e) => {
                e.preventDefault();
                goToContactUsPage();
              }}
              target="_blank"
            >
              <span>{t("footer:impressum-main-3-contacnt-form")}</span>
            </a>
            .
          </p>
          <p>{t("footer:impressum-main-3-2")}</p>
          <p>
            {"\u2022 "}
            {t("footer:impressum-main-3-3")}
          </p>
        </div>
      </main>
    </div>
  );
};

export default Impressum;
