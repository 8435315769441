export const getPlacesWithZipAndLocationFromSwiss = () => [
  {
    zipCode: 5000,
    city: "Aarau",
    cantonShort: "AG",
    latitude: 47.3887,
    longitude: 8.0483,
  },
  {
    zipCode: 5001,
    city: "Aarau 1",
    cantonShort: "AG",
    latitude: 47.3888,
    longitude: 8.0483,
  },
  {
    zipCode: 5004,
    city: "Aarau",
    cantonShort: "AG",
    latitude: 47.4007,
    longitude: 8.0607,
  },
  {
    zipCode: 5017,
    city: "Barmelweid",
    cantonShort: "AG",
    latitude: 47.4219,
    longitude: 7.9722,
  },
  {
    zipCode: 5018,
    city: "Erlinsbach",
    cantonShort: "AG",
    latitude: 47.4143,
    longitude: 8.0077,
  },
  {
    zipCode: 5022,
    city: "Rombach",
    cantonShort: "AG",
    latitude: 47.4052,
    longitude: 8.0456,
  },
  {
    zipCode: 5023,
    city: "Biberstein",
    cantonShort: "AG",
    latitude: 47.4188,
    longitude: 8.083,
  },
  {
    zipCode: 5024,
    city: "K\u00fcttigen",
    cantonShort: "AG",
    latitude: 47.4221,
    longitude: 8.0439,
  },
  {
    zipCode: 5025,
    city: "Asp",
    cantonShort: "AG",
    latitude: 47.4421,
    longitude: 8.0506,
  },
  {
    zipCode: 5026,
    city: "Densb\u00fcren",
    cantonShort: "AG",
    latitude: 47.4566,
    longitude: 8.0599,
  },
  {
    zipCode: 5032,
    city: "Aarau Rohr",
    cantonShort: "AG",
    latitude: 47.4055,
    longitude: 8.0841,
  },
  {
    zipCode: 5033,
    city: "Buchs AG",
    cantonShort: "AG",
    latitude: 47.3937,
    longitude: 8.0879,
  },
  {
    zipCode: 5034,
    city: "Suhr",
    cantonShort: "AG",
    latitude: 47.3749,
    longitude: 8.084,
  },
  {
    zipCode: 5035,
    city: "Unterentfelden",
    cantonShort: "AG",
    latitude: 47.3684,
    longitude: 8.0427,
  },
  {
    zipCode: 5036,
    city: "Oberentfelden",
    cantonShort: "AG",
    latitude: 47.3542,
    longitude: 8.0413,
  },
  {
    zipCode: 5037,
    city: "Muhen",
    cantonShort: "AG",
    latitude: 47.3329,
    longitude: 8.0627,
  },
  {
    zipCode: 5042,
    city: "Hirschthal",
    cantonShort: "AG",
    latitude: 47.3176,
    longitude: 8.0671,
  },
  {
    zipCode: 5722,
    city: "Gr\u00e4nichen",
    cantonShort: "AG",
    latitude: 47.3516,
    longitude: 8.1066,
  },
  {
    zipCode: 5300,
    city: "Turgi",
    cantonShort: "AG",
    latitude: 47.4901,
    longitude: 8.2561,
  },
  {
    zipCode: 5301,
    city: "Siggenthal Station",
    cantonShort: "AG",
    latitude: 47.5152,
    longitude: 8.238,
  },
  {
    zipCode: 5303,
    city: "W\u00fcrenlingen",
    cantonShort: "AG",
    latitude: 47.5321,
    longitude: 8.2492,
  },
  {
    zipCode: 5400,
    city: "Baden",
    cantonShort: "AG",
    latitude: 47.4721,
    longitude: 8.2914,
  },
  {
    zipCode: 5401,
    city: "Baden",
    cantonShort: "AG",
    latitude: 47.4733,
    longitude: 8.3059,
  },
  {
    zipCode: 5402,
    city: "Baden",
    cantonShort: "AG",
    latitude: 47.4733,
    longitude: 8.3059,
  },
  {
    zipCode: 5404,
    city: "Baden",
    cantonShort: "AG",
    latitude: 47.4549,
    longitude: 8.2783,
  },
  {
    zipCode: 5405,
    city: "Baden",
    cantonShort: "AG",
    latitude: 47.4733,
    longitude: 8.3059,
  },
  {
    zipCode: 5405,
    city: "D\u00e4ttwil AG",
    cantonShort: "AG",
    latitude: 47.4537,
    longitude: 8.2899,
  },
  {
    zipCode: 5406,
    city: "R\u00fctihof",
    cantonShort: "AG",
    latitude: 47.4381,
    longitude: 8.2659,
  },
  {
    zipCode: 5406,
    city: "Baden",
    cantonShort: "AG",
    latitude: 47.4733,
    longitude: 8.3059,
  },
  {
    zipCode: 5408,
    city: "Ennetbaden",
    cantonShort: "AG",
    latitude: 47.4804,
    longitude: 8.3235,
  },
  {
    zipCode: 5412,
    city: "Gebenstorf",
    cantonShort: "AG",
    latitude: 47.4782,
    longitude: 8.2487,
  },
  {
    zipCode: 5412,
    city: "Vogelsang AG",
    cantonShort: "AG",
    latitude: 47.4974,
    longitude: 8.2393,
  },
  {
    zipCode: 5413,
    city: "Birmenstorf AG",
    cantonShort: "AG",
    latitude: 47.4558,
    longitude: 8.2562,
  },
  {
    zipCode: 5415,
    city: "Nussbaumen AG",
    cantonShort: "AG",
    latitude: 47.4935,
    longitude: 8.2967,
  },
  {
    zipCode: 5415,
    city: "Rieden AG",
    cantonShort: "AG",
    latitude: 47.4839,
    longitude: 8.304,
  },
  {
    zipCode: 5415,
    city: "Hertenstein AG",
    cantonShort: "AG",
    latitude: 47.4888,
    longitude: 8.3158,
  },
  {
    zipCode: 5416,
    city: "Kirchdorf AG",
    cantonShort: "AG",
    latitude: 47.499,
    longitude: 8.2801,
  },
  {
    zipCode: 5417,
    city: "Untersiggenthal",
    cantonShort: "AG",
    latitude: 47.5067,
    longitude: 8.2584,
  },
  {
    zipCode: 5420,
    city: "Ehrendingen",
    cantonShort: "AG",
    latitude: 47.4943,
    longitude: 8.3468,
  },
  {
    zipCode: 5423,
    city: "Freienwil",
    cantonShort: "AG",
    latitude: 47.5014,
    longitude: 8.319,
  },
  {
    zipCode: 5430,
    city: "Wettingen 1",
    cantonShort: "AG",
    latitude: 47.4668,
    longitude: 8.3412,
  },
  {
    zipCode: 5430,
    city: "Wettingen 3",
    cantonShort: "AG",
    latitude: 47.4668,
    longitude: 8.3412,
  },
  {
    zipCode: 5430,
    city: "Wettingen",
    cantonShort: "AG",
    latitude: 47.4666,
    longitude: 8.3412,
  },
  {
    zipCode: 5432,
    city: "Neuenhof",
    cantonShort: "AG",
    latitude: 47.4441,
    longitude: 8.3251,
  },
  {
    zipCode: 5436,
    city: "W\u00fcrenlos",
    cantonShort: "AG",
    latitude: 47.4473,
    longitude: 8.3639,
  },
  {
    zipCode: 5442,
    city: "Fislisbach",
    cantonShort: "AG",
    latitude: 47.4374,
    longitude: 8.2907,
  },
  {
    zipCode: 5443,
    city: "Niederrohrdorf",
    cantonShort: "AG",
    latitude: 47.4201,
    longitude: 8.3013,
  },
  {
    zipCode: 5444,
    city: "K\u00fcnten",
    cantonShort: "AG",
    latitude: 47.386,
    longitude: 8.3254,
  },
  {
    zipCode: 5452,
    city: "Oberrohrdorf",
    cantonShort: "AG",
    latitude: 47.4251,
    longitude: 8.3205,
  },
  {
    zipCode: 5453,
    city: "Remetschwil",
    cantonShort: "AG",
    latitude: 47.4083,
    longitude: 8.3319,
  },
  {
    zipCode: 5454,
    city: "Bellikon",
    cantonShort: "AG",
    latitude: 47.3916,
    longitude: 8.3494,
  },
  {
    zipCode: 5506,
    city: "M\u00e4genwil",
    cantonShort: "AG",
    latitude: 47.4123,
    longitude: 8.2342,
  },
  {
    zipCode: 5507,
    city: "Mellingen",
    cantonShort: "AG",
    latitude: 47.4211,
    longitude: 8.2742,
  },
  {
    zipCode: 5512,
    city: "Wohlenschwil",
    cantonShort: "AG",
    latitude: 47.4167,
    longitude: 8.2543,
  },
  {
    zipCode: 5608,
    city: "Stetten AG",
    cantonShort: "AG",
    latitude: 47.4013,
    longitude: 8.3051,
  },
  {
    zipCode: 8109,
    city: "Kloster Fahr",
    cantonShort: "AG",
    latitude: 47.4083,
    longitude: 8.4391,
  },
  {
    zipCode: 8956,
    city: "Killwangen",
    cantonShort: "AG",
    latitude: 47.428,
    longitude: 8.3443,
  },
  {
    zipCode: 8957,
    city: "Spreitenbach",
    cantonShort: "AG",
    latitude: 47.418,
    longitude: 8.3672,
  },
  {
    zipCode: 8962,
    city: "Bergdietikon",
    cantonShort: "AG",
    latitude: 47.3881,
    longitude: 8.3785,
  },
  {
    zipCode: 5445,
    city: "Eggenwil",
    cantonShort: "AG",
    latitude: 47.3716,
    longitude: 8.3385,
  },
  {
    zipCode: 5522,
    city: "T\u00e4gerig",
    cantonShort: "AG",
    latitude: 47.3992,
    longitude: 8.2789,
  },
  {
    zipCode: 5524,
    city: "Niederwil AG",
    cantonShort: "AG",
    latitude: 47.3763,
    longitude: 8.2905,
  },
  {
    zipCode: 5524,
    city: "Nesselnbach",
    cantonShort: "AG",
    latitude: 47.3906,
    longitude: 8.2968,
  },
  {
    zipCode: 5525,
    city: "Fischbach-G\u00f6slikon",
    cantonShort: "AG",
    latitude: 47.3691,
    longitude: 8.3111,
  },
  {
    zipCode: 5605,
    city: "Dottikon",
    cantonShort: "AG",
    latitude: 47.3779,
    longitude: 8.2415,
  },
  {
    zipCode: 5607,
    city: "H\u00e4gglingen",
    cantonShort: "AG",
    latitude: 47.39,
    longitude: 8.2576,
  },
  {
    zipCode: 5610,
    city: "Wohlen AG 1",
    cantonShort: "AG",
    latitude: 47.3533,
    longitude: 8.2807,
  },
  {
    zipCode: 5610,
    city: "Wohlen AG",
    cantonShort: "AG",
    latitude: 47.3503,
    longitude: 8.2836,
  },
  {
    zipCode: 5611,
    city: "Anglikon",
    cantonShort: "AG",
    latitude: 47.3665,
    longitude: 8.2631,
  },
  {
    zipCode: 5612,
    city: "Villmergen",
    cantonShort: "AG",
    latitude: 47.3475,
    longitude: 8.2403,
  },
  {
    zipCode: 5613,
    city: "Hilfikon",
    cantonShort: "AG",
    latitude: 47.3308,
    longitude: 8.2453,
  },
  {
    zipCode: 5614,
    city: "Sarmenstorf",
    cantonShort: "AG",
    latitude: 47.3117,
    longitude: 8.2524,
  },
  {
    zipCode: 5619,
    city: "B\u00fcttikon AG",
    cantonShort: "AG",
    latitude: 47.3282,
    longitude: 8.2683,
  },
  {
    zipCode: 5619,
    city: "Uezwil",
    cantonShort: "AG",
    latitude: 47.315,
    longitude: 8.2778,
  },
  {
    zipCode: 5620,
    city: "Bremgarten AG 1",
    cantonShort: "AG",
    latitude: 47.3535,
    longitude: 8.3298,
  },
  {
    zipCode: 5620,
    city: "Bremgarten AG",
    cantonShort: "AG",
    latitude: 47.3535,
    longitude: 8.3299,
  },
  {
    zipCode: 5621,
    city: "Zufikon",
    cantonShort: "AG",
    latitude: 47.3447,
    longitude: 8.3582,
  },
  {
    zipCode: 5626,
    city: "Hermetschwil-Staffeln",
    cantonShort: "AG",
    latitude: 47.3304,
    longitude: 8.3415,
  },
  {
    zipCode: 8905,
    city: "Arni-Islisberg",
    cantonShort: "AG",
    latitude: 47.317,
    longitude: 8.4223,
  },
  {
    zipCode: 8905,
    city: "Islisberg",
    cantonShort: "AG",
    latitude: 47.3233,
    longitude: 8.4412,
  },
  {
    zipCode: 8905,
    city: "Arni AG",
    cantonShort: "AG",
    latitude: 47.317,
    longitude: 8.4223,
  },
  {
    zipCode: 8916,
    city: "Jonen",
    cantonShort: "AG",
    latitude: 47.2997,
    longitude: 8.4022,
  },
  {
    zipCode: 8917,
    city: "Oberlunkhofen",
    cantonShort: "AG",
    latitude: 47.3125,
    longitude: 8.3977,
  },
  {
    zipCode: 8918,
    city: "Unterlunkhofen",
    cantonShort: "AG",
    latitude: 47.3248,
    longitude: 8.3832,
  },
  {
    zipCode: 8964,
    city: "Rudolfstetten",
    cantonShort: "AG",
    latitude: 47.3683,
    longitude: 8.3847,
  },
  {
    zipCode: 8965,
    city: "Berikon",
    cantonShort: "AG",
    latitude: 47.3538,
    longitude: 8.3837,
  },
  {
    zipCode: 8966,
    city: "Oberwil-Lieli",
    cantonShort: "AG",
    latitude: 47.3371,
    longitude: 8.3952,
  },
  {
    zipCode: 8967,
    city: "Widen",
    cantonShort: "AG",
    latitude: 47.3705,
    longitude: 8.3583,
  },
  {
    zipCode: 5105,
    city: "Auenstein",
    cantonShort: "AG",
    latitude: 47.4185,
    longitude: 8.1262,
  },
  {
    zipCode: 5106,
    city: "Veltheim AG",
    cantonShort: "AG",
    latitude: 47.4318,
    longitude: 8.1484,
  },
  {
    zipCode: 5107,
    city: "Schinznach Dorf",
    cantonShort: "AG",
    latitude: 47.4543,
    longitude: 8.139,
  },
  {
    zipCode: 5108,
    city: "Oberflachs",
    cantonShort: "AG",
    latitude: 47.4379,
    longitude: 8.1229,
  },
  {
    zipCode: 5112,
    city: "Thalheim AG",
    cantonShort: "AG",
    latitude: 47.4392,
    longitude: 8.0934,
  },
  {
    zipCode: 5116,
    city: "Schinznach Bad",
    cantonShort: "AG",
    latitude: 47.4538,
    longitude: 8.1687,
  },
  {
    zipCode: 5200,
    city: "Brugg AG",
    cantonShort: "AG",
    latitude: 47.4864,
    longitude: 8.2112,
  },
  {
    zipCode: 5201,
    city: "Brugg AG",
    cantonShort: "AG",
    latitude: 47.4863,
    longitude: 8.211,
  },
  {
    zipCode: 5210,
    city: "Windisch",
    cantonShort: "AG",
    latitude: 47.4759,
    longitude: 8.2221,
  },
  {
    zipCode: 5212,
    city: "Hausen AG",
    cantonShort: "AG",
    latitude: 47.4613,
    longitude: 8.2074,
  },
  {
    zipCode: 5213,
    city: "Villnachern",
    cantonShort: "AG",
    latitude: 47.4725,
    longitude: 8.1625,
  },
  {
    zipCode: 5222,
    city: "Umiken",
    cantonShort: "AG",
    latitude: 47.4812,
    longitude: 8.1867,
  },
  {
    zipCode: 5223,
    city: "Riniken",
    cantonShort: "AG",
    latitude: 47.4939,
    longitude: 8.1867,
  },
  {
    zipCode: 5225,
    city: "B\u00f6zberg",
    cantonShort: "AG",
    latitude: 47.4891,
    longitude: 8.145,
  },
  {
    zipCode: 5232,
    city: "Villigen PSI",
    cantonShort: "AG",
    latitude: 47.5296,
    longitude: 8.2072,
  },
  {
    zipCode: 5233,
    city: "Stilli",
    cantonShort: "AG",
    latitude: 47.5165,
    longitude: 8.2315,
  },
  {
    zipCode: 5234,
    city: "Villigen",
    cantonShort: "AG",
    latitude: 47.5301,
    longitude: 8.2063,
  },
  {
    zipCode: 5235,
    city: "R\u00fcfenach AG",
    cantonShort: "AG",
    latitude: 47.5049,
    longitude: 8.2127,
  },
  {
    zipCode: 5236,
    city: "Remigen",
    cantonShort: "AG",
    latitude: 47.5174,
    longitude: 8.1759,
  },
  {
    zipCode: 5237,
    city: "M\u00f6nthal",
    cantonShort: "AG",
    latitude: 47.5165,
    longitude: 8.1376,
  },
  {
    zipCode: 5242,
    city: "Birr",
    cantonShort: "AG",
    latitude: 47.4322,
    longitude: 8.2121,
  },
  {
    zipCode: 5242,
    city: "Lupfig",
    cantonShort: "AG",
    latitude: 47.4436,
    longitude: 8.2075,
  },
  {
    zipCode: 5242,
    city: "Birr-Lupfig",
    cantonShort: "AG",
    latitude: 47.4437,
    longitude: 8.2076,
  },
  {
    zipCode: 5243,
    city: "M\u00fclligen",
    cantonShort: "AG",
    latitude: 47.4524,
    longitude: 8.2385,
  },
  {
    zipCode: 5244,
    city: "Birrhard",
    cantonShort: "AG",
    latitude: 47.4361,
    longitude: 8.2423,
  },
  {
    zipCode: 5245,
    city: "Habsburg",
    cantonShort: "AG",
    latitude: 47.4614,
    longitude: 8.186,
  },
  {
    zipCode: 5246,
    city: "Scherz",
    cantonShort: "AG",
    latitude: 47.4469,
    longitude: 8.1842,
  },
  {
    zipCode: 5318,
    city: "Mandach",
    cantonShort: "AG",
    latitude: 47.5515,
    longitude: 8.1877,
  },
  {
    zipCode: 5040,
    city: "Sch\u00f6ftland",
    cantonShort: "AG",
    latitude: 47.3054,
    longitude: 8.0557,
  },
  {
    zipCode: 5043,
    city: "Holziken",
    cantonShort: "AG",
    latitude: 47.3195,
    longitude: 8.0343,
  },
  {
    zipCode: 5044,
    city: "Schlossrued",
    cantonShort: "AG",
    latitude: 47.2902,
    longitude: 8.0884,
  },
  {
    zipCode: 5046,
    city: "Walde AG",
    cantonShort: "AG",
    latitude: 47.2535,
    longitude: 8.1161,
  },
  {
    zipCode: 5046,
    city: "Schmiedrued-Walde",
    cantonShort: "AG",
    latitude: 47.2632,
    longitude: 8.1117,
  },
  {
    zipCode: 5046,
    city: "Schmiedrued",
    cantonShort: "AG",
    latitude: 47.271,
    longitude: 8.1087,
  },
  {
    zipCode: 5708,
    city: "Birrwil",
    cantonShort: "AG",
    latitude: 47.2919,
    longitude: 8.1981,
  },
  {
    zipCode: 5712,
    city: "Beinwil am See",
    cantonShort: "AG",
    latitude: 47.2665,
    longitude: 8.2086,
  },
  {
    zipCode: 5723,
    city: "Teufenthal AG",
    cantonShort: "AG",
    latitude: 47.3281,
    longitude: 8.1259,
  },
  {
    zipCode: 5724,
    city: "D\u00fcrren\u00e4sch",
    cantonShort: "AG",
    latitude: 47.3186,
    longitude: 8.1544,
  },
  {
    zipCode: 5725,
    city: "Leutwil",
    cantonShort: "AG",
    latitude: 47.3056,
    longitude: 8.1738,
  },
  {
    zipCode: 5726,
    city: "Unterkulm",
    cantonShort: "AG",
    latitude: 47.3177,
    longitude: 8.1032,
  },
  {
    zipCode: 5727,
    city: "Oberkulm",
    cantonShort: "AG",
    latitude: 47.2984,
    longitude: 8.1259,
  },
  {
    zipCode: 5728,
    city: "Gontenschwil",
    cantonShort: "AG",
    latitude: 47.2676,
    longitude: 8.1415,
  },
  {
    zipCode: 5732,
    city: "Zetzwil",
    cantonShort: "AG",
    latitude: 47.2883,
    longitude: 8.162,
  },
  {
    zipCode: 5733,
    city: "Leimbach AG",
    cantonShort: "AG",
    latitude: 47.2749,
    longitude: 8.1701,
  },
  {
    zipCode: 5734,
    city: "Reinach AG",
    cantonShort: "AG",
    latitude: 47.2612,
    longitude: 8.1793,
  },
  {
    zipCode: 5736,
    city: "Burg AG",
    cantonShort: "AG",
    latitude: 47.2339,
    longitude: 8.178,
  },
  {
    zipCode: 5737,
    city: "Menziken",
    cantonShort: "AG",
    latitude: 47.236,
    longitude: 8.1865,
  },
  {
    zipCode: 4333,
    city: "M\u00fcnchwilen AG",
    cantonShort: "AG",
    latitude: 47.5375,
    longitude: 7.9642,
  },
  {
    zipCode: 4334,
    city: "Sisseln AG",
    cantonShort: "AG",
    latitude: 47.5522,
    longitude: 7.9853,
  },
  {
    zipCode: 5027,
    city: "Herznach",
    cantonShort: "AG",
    latitude: 47.4721,
    longitude: 8.0465,
  },
  {
    zipCode: 5028,
    city: "Ueken",
    cantonShort: "AG",
    latitude: 47.4854,
    longitude: 8.0434,
  },
  {
    zipCode: 5062,
    city: "Oberhof",
    cantonShort: "AG",
    latitude: 47.4418,
    longitude: 8.007,
  },
  {
    zipCode: 5063,
    city: "W\u00f6lflinswil",
    cantonShort: "AG",
    latitude: 47.4628,
    longitude: 8.0002,
  },
  {
    zipCode: 5064,
    city: "Wittnau",
    cantonShort: "AG",
    latitude: 47.4771,
    longitude: 7.9661,
  },
  {
    zipCode: 5070,
    city: "Frick",
    cantonShort: "AG",
    latitude: 47.5097,
    longitude: 8.0142,
  },
  {
    zipCode: 5072,
    city: "Oeschgen",
    cantonShort: "AG",
    latitude: 47.5249,
    longitude: 8.0171,
  },
  {
    zipCode: 5073,
    city: "Gipf-Oberfrick",
    cantonShort: "AG",
    latitude: 47.4931,
    longitude: 7.9962,
  },
  {
    zipCode: 5074,
    city: "Eiken",
    cantonShort: "AG",
    latitude: 47.537,
    longitude: 7.9892,
  },
  {
    zipCode: 5075,
    city: "Hornussen",
    cantonShort: "AG",
    latitude: 47.5016,
    longitude: 8.0625,
  },
  {
    zipCode: 5076,
    city: "B\u00f6zen",
    cantonShort: "AG",
    latitude: 47.4964,
    longitude: 8.0859,
  },
  {
    zipCode: 5077,
    city: "Elfingen",
    cantonShort: "AG",
    latitude: 47.51,
    longitude: 8.1022,
  },
  {
    zipCode: 5078,
    city: "Effingen",
    cantonShort: "AG",
    latitude: 47.4928,
    longitude: 8.1125,
  },
  {
    zipCode: 5079,
    city: "Zeihen",
    cantonShort: "AG",
    latitude: 47.4705,
    longitude: 8.0931,
  },
  {
    zipCode: 5080,
    city: "Laufenburg",
    cantonShort: "AG",
    latitude: 47.556,
    longitude: 8.0608,
  },
  {
    zipCode: 5082,
    city: "Kaisten",
    cantonShort: "AG",
    latitude: 47.5386,
    longitude: 8.0448,
  },
  {
    zipCode: 5083,
    city: "Ittenthal",
    cantonShort: "AG",
    latitude: 47.519,
    longitude: 8.0622,
  },
  {
    zipCode: 5084,
    city: "Rheinsulz",
    cantonShort: "AG",
    latitude: 47.5528,
    longitude: 8.0867,
  },
  {
    zipCode: 5085,
    city: "Sulz AG",
    cantonShort: "AG",
    latitude: 47.5343,
    longitude: 8.098,
  },
  {
    zipCode: 5272,
    city: "Gansingen",
    cantonShort: "AG",
    latitude: 47.5372,
    longitude: 8.1317,
  },
  {
    zipCode: 5273,
    city: "Oberhofen AG",
    cantonShort: "AG",
    latitude: 47.5562,
    longitude: 8.1252,
  },
  {
    zipCode: 5274,
    city: "Mettau",
    cantonShort: "AG",
    latitude: 47.569,
    longitude: 8.1301,
  },
  {
    zipCode: 5275,
    city: "Etzgen",
    cantonShort: "AG",
    latitude: 47.5743,
    longitude: 8.1137,
  },
  {
    zipCode: 5276,
    city: "Wil AG",
    cantonShort: "AG",
    latitude: 47.5662,
    longitude: 8.1575,
  },
  {
    zipCode: 5277,
    city: "Hottwil",
    cantonShort: "AG",
    latitude: 47.5423,
    longitude: 8.1638,
  },
  {
    zipCode: 5326,
    city: "Schwaderloch",
    cantonShort: "AG",
    latitude: 47.5858,
    longitude: 8.1457,
  },
  {
    zipCode: 5102,
    city: "Rupperswil",
    cantonShort: "AG",
    latitude: 47.4038,
    longitude: 8.1311,
  },
  {
    zipCode: 5103,
    city: "M\u00f6riken AG",
    cantonShort: "AG",
    latitude: 47.4165,
    longitude: 8.191,
  },
  {
    zipCode: 5103,
    city: "Wildegg",
    cantonShort: "AG",
    latitude: 47.4159,
    longitude: 8.1651,
  },
  {
    zipCode: 5113,
    city: "Holderbank AG",
    cantonShort: "AG",
    latitude: 47.4309,
    longitude: 8.1707,
  },
  {
    zipCode: 5502,
    city: "Hunzenschwil",
    cantonShort: "AG",
    latitude: 47.3842,
    longitude: 8.1229,
  },
  {
    zipCode: 5503,
    city: "Schafisheim",
    cantonShort: "AG",
    latitude: 47.3737,
    longitude: 8.1391,
  },
  {
    zipCode: 5504,
    city: "Othmarsingen",
    cantonShort: "AG",
    latitude: 47.4016,
    longitude: 8.2146,
  },
  {
    zipCode: 5505,
    city: "Brunegg",
    cantonShort: "AG",
    latitude: 47.4207,
    longitude: 8.2199,
  },
  {
    zipCode: 5600,
    city: "Lenzburg 1",
    cantonShort: "AG",
    latitude: 47.3856,
    longitude: 8.1805,
  },
  {
    zipCode: 5600,
    city: "Ammerswil AG",
    cantonShort: "AG",
    latitude: 47.3682,
    longitude: 8.2057,
  },
  {
    zipCode: 5600,
    city: "Lenzburg",
    cantonShort: "AG",
    latitude: 47.3855,
    longitude: 8.1805,
  },
  {
    zipCode: 5603,
    city: "Staufen",
    cantonShort: "AG",
    latitude: 47.3788,
    longitude: 8.16,
  },
  {
    zipCode: 5604,
    city: "Hendschiken",
    cantonShort: "AG",
    latitude: 47.3846,
    longitude: 8.2159,
  },
  {
    zipCode: 5606,
    city: "Dintikon",
    cantonShort: "AG",
    latitude: 47.3616,
    longitude: 8.2218,
  },
  {
    zipCode: 5615,
    city: "Fahrwangen",
    cantonShort: "AG",
    latitude: 47.2863,
    longitude: 8.2392,
  },
  {
    zipCode: 5616,
    city: "Meisterschwanden",
    cantonShort: "AG",
    latitude: 47.2902,
    longitude: 8.2258,
  },
  {
    zipCode: 5617,
    city: "Tennwil",
    cantonShort: "AG",
    latitude: 47.3062,
    longitude: 8.2191,
  },
  {
    zipCode: 5702,
    city: "Niederlenz",
    cantonShort: "AG",
    latitude: 47.4034,
    longitude: 8.1704,
  },
  {
    zipCode: 5703,
    city: "Seon",
    cantonShort: "AG",
    latitude: 47.3482,
    longitude: 8.1576,
  },
  {
    zipCode: 5704,
    city: "Egliswil",
    cantonShort: "AG",
    latitude: 47.3517,
    longitude: 8.1903,
  },
  {
    zipCode: 5705,
    city: "Hallwil",
    cantonShort: "AG",
    latitude: 47.3265,
    longitude: 8.1779,
  },
  {
    zipCode: 5706,
    city: "Boniswil",
    cantonShort: "AG",
    latitude: 47.3118,
    longitude: 8.1921,
  },
  {
    zipCode: 5707,
    city: "Seengen",
    cantonShort: "AG",
    latitude: 47.3283,
    longitude: 8.2087,
  },
  {
    zipCode: 5618,
    city: "Bettwil",
    cantonShort: "AG",
    latitude: 47.2902,
    longitude: 8.2717,
  },
  {
    zipCode: 5622,
    city: "Waltenschwil",
    cantonShort: "AG",
    latitude: 47.3341,
    longitude: 8.2989,
  },
  {
    zipCode: 5623,
    city: "Boswil",
    cantonShort: "AG",
    latitude: 47.2998,
    longitude: 8.3092,
  },
  {
    zipCode: 5624,
    city: "Waldh\u00e4usern AG",
    cantonShort: "AG",
    latitude: 47.3274,
    longitude: 8.3164,
  },
  {
    zipCode: 5624,
    city: "B\u00fcnzen",
    cantonShort: "AG",
    latitude: 47.3156,
    longitude: 8.3263,
  },
  {
    zipCode: 5625,
    city: "Kallern",
    cantonShort: "AG",
    latitude: 47.3095,
    longitude: 8.2901,
  },
  {
    zipCode: 5627,
    city: "Besenb\u00fcren",
    cantonShort: "AG",
    latitude: 47.3128,
    longitude: 8.3449,
  },
  {
    zipCode: 5628,
    city: "Aristau",
    cantonShort: "AG",
    latitude: 47.2912,
    longitude: 8.3647,
  },
  {
    zipCode: 5630,
    city: "Muri AG",
    cantonShort: "AG",
    latitude: 47.272,
    longitude: 8.3393,
  },
  {
    zipCode: 5632,
    city: "Buttwil",
    cantonShort: "AG",
    latitude: 47.2688,
    longitude: 8.3063,
  },
  {
    zipCode: 5634,
    city: "Merenschwand",
    cantonShort: "AG",
    latitude: 47.2627,
    longitude: 8.3839,
  },
  {
    zipCode: 5636,
    city: "Benzenschwil",
    cantonShort: "AG",
    latitude: 47.2465,
    longitude: 8.3637,
  },
  {
    zipCode: 5637,
    city: "Geltwil",
    cantonShort: "AG",
    latitude: 47.249,
    longitude: 8.326,
  },
  {
    zipCode: 5637,
    city: "Beinwil (Freiamt)",
    cantonShort: "AG",
    latitude: 47.2295,
    longitude: 8.3392,
  },
  {
    zipCode: 5642,
    city: "M\u00fchlau",
    cantonShort: "AG",
    latitude: 47.2342,
    longitude: 8.3891,
  },
  {
    zipCode: 5643,
    city: "Meienberg",
    cantonShort: "AG",
    latitude: 47.1976,
    longitude: 8.3767,
  },
  {
    zipCode: 5643,
    city: "Sins",
    cantonShort: "AG",
    latitude: 47.196,
    longitude: 8.3851,
  },
  {
    zipCode: 5643,
    city: "Sins",
    cantonShort: "AG",
    latitude: 47.196,
    longitude: 8.3851,
  },
  {
    zipCode: 5643,
    city: "Alikon",
    cantonShort: "AG",
    latitude: 47.1965,
    longitude: 8.3524,
  },
  {
    zipCode: 5644,
    city: "Auw",
    cantonShort: "AG",
    latitude: 47.2148,
    longitude: 8.3627,
  },
  {
    zipCode: 5645,
    city: "Fenkrieden",
    cantonShort: "AG",
    latitude: 47.1595,
    longitude: 8.3712,
  },
  {
    zipCode: 5645,
    city: "Aettenschwil",
    cantonShort: "AG",
    latitude: 47.1838,
    longitude: 8.3727,
  },
  {
    zipCode: 5646,
    city: "Abtwil AG",
    cantonShort: "AG",
    latitude: 47.174,
    longitude: 8.3538,
  },
  {
    zipCode: 5647,
    city: "Oberr\u00fcti",
    cantonShort: "AG",
    latitude: 47.1669,
    longitude: 8.3986,
  },
  {
    zipCode: 6042,
    city: "Dietwil",
    cantonShort: "AG",
    latitude: 47.1483,
    longitude: 8.3963,
  },
  {
    zipCode: 8919,
    city: "Rottenschwil",
    cantonShort: "AG",
    latitude: 47.3144,
    longitude: 8.3659,
  },
  {
    zipCode: 4303,
    city: "Kaiseraugst",
    cantonShort: "AG",
    latitude: 47.5364,
    longitude: 7.7428,
  },
  {
    zipCode: 4303,
    city: "Kaiseraugst Liebr\u00fcti",
    cantonShort: "AG",
    latitude: 47.5361,
    longitude: 7.7421,
  },
  {
    zipCode: 4305,
    city: "Olsberg",
    cantonShort: "AG",
    latitude: 47.526,
    longitude: 7.7777,
  },
  {
    zipCode: 4310,
    city: "Rheinfelden",
    cantonShort: "AG",
    latitude: 47.5493,
    longitude: 7.7982,
  },
  {
    zipCode: 4310,
    city: "Rheinfelden 1",
    cantonShort: "AG",
    latitude: 47.5559,
    longitude: 7.8045,
  },
  {
    zipCode: 4312,
    city: "Magden",
    cantonShort: "AG",
    latitude: 47.5196,
    longitude: 7.8096,
  },
  {
    zipCode: 4313,
    city: "M\u00f6hlin",
    cantonShort: "AG",
    latitude: 47.5655,
    longitude: 7.8489,
  },
  {
    zipCode: 4314,
    city: "Zeiningen",
    cantonShort: "AG",
    latitude: 47.5433,
    longitude: 7.8762,
  },
  {
    zipCode: 4315,
    city: "Zuzgen",
    cantonShort: "AG",
    latitude: 47.5243,
    longitude: 7.8999,
  },
  {
    zipCode: 4316,
    city: "Hellikon",
    cantonShort: "AG",
    latitude: 47.5089,
    longitude: 7.9167,
  },
  {
    zipCode: 4317,
    city: "Wegenstetten",
    cantonShort: "AG",
    latitude: 47.4939,
    longitude: 7.9312,
  },
  {
    zipCode: 4322,
    city: "Mumpf",
    cantonShort: "AG",
    latitude: 47.5451,
    longitude: 7.9135,
  },
  {
    zipCode: 4323,
    city: "Wallbach",
    cantonShort: "AG",
    latitude: 47.568,
    longitude: 7.8979,
  },
  {
    zipCode: 4324,
    city: "Obermumpf",
    cantonShort: "AG",
    latitude: 47.5299,
    longitude: 7.937,
  },
  {
    zipCode: 4325,
    city: "Schupfart",
    cantonShort: "AG",
    latitude: 47.5134,
    longitude: 7.9625,
  },
  {
    zipCode: 4332,
    city: "Stein AG",
    cantonShort: "AG",
    latitude: 47.5452,
    longitude: 7.9528,
  },
  {
    zipCode: 4663,
    city: "Aarburg",
    cantonShort: "AG",
    latitude: 47.3236,
    longitude: 7.9057,
  },
  {
    zipCode: 4665,
    city: "Oftringen",
    cantonShort: "AG",
    latitude: 47.313,
    longitude: 7.9391,
  },
  {
    zipCode: 4665,
    city: "Oftringen 2",
    cantonShort: "AG",
    latitude: 47.3134,
    longitude: 7.939,
  },
  {
    zipCode: 4665,
    city: "Oftringen 1",
    cantonShort: "AG",
    latitude: 47.3134,
    longitude: 7.939,
  },
  {
    zipCode: 4800,
    city: "Zofingen",
    cantonShort: "AG",
    latitude: 47.2865,
    longitude: 7.9576,
  },
  {
    zipCode: 4801,
    city: "Zofingen Ringier AG",
    cantonShort: "AG",
    latitude: 47.2888,
    longitude: 7.9633,
  },
  {
    zipCode: 4801,
    city: "Zofingen",
    cantonShort: "AG",
    latitude: 47.2888,
    longitude: 7.9633,
  },
  {
    zipCode: 4802,
    city: "Strengelbach",
    cantonShort: "AG",
    latitude: 47.2834,
    longitude: 7.9225,
  },
  {
    zipCode: 4803,
    city: "Vordemwald",
    cantonShort: "AG",
    latitude: 47.2694,
    longitude: 7.8926,
  },
  {
    zipCode: 4805,
    city: "Brittnau",
    cantonShort: "AG",
    latitude: 47.2546,
    longitude: 7.9287,
  },
  {
    zipCode: 4807,
    city: "Zofingen PF",
    cantonShort: "AG",
    latitude: 47.2888,
    longitude: 7.9633,
  },
  {
    zipCode: 4807,
    city: "Zofingen PostFinance",
    cantonShort: "AG",
    latitude: 47.2888,
    longitude: 7.9633,
  },
  {
    zipCode: 4808,
    city: "Zofingen PostFinance",
    cantonShort: "AG",
    latitude: 47.2888,
    longitude: 7.9633,
  },
  {
    zipCode: 4808,
    city: "Zofingen PF",
    cantonShort: "AG",
    latitude: 47.2888,
    longitude: 7.9633,
  },
  {
    zipCode: 4809,
    city: "Zofingen PF",
    cantonShort: "AG",
    latitude: 47.2888,
    longitude: 7.9633,
  },
  {
    zipCode: 4809,
    city: "Zofingen PF UBS Verarb.",
    cantonShort: "AG",
    latitude: 47.2888,
    longitude: 7.9633,
  },
  {
    zipCode: 4809,
    city: "Zofingen Postfinance UBS",
    cantonShort: "AG",
    latitude: 47.2888,
    longitude: 7.9633,
  },
  {
    zipCode: 4809,
    city: "Zofingen PF UBS",
    cantonShort: "AG",
    latitude: 47.2888,
    longitude: 7.9633,
  },
  {
    zipCode: 4810,
    city: "Zofingen PF",
    cantonShort: "AG",
    latitude: 47.2888,
    longitude: 7.9633,
  },
  {
    zipCode: 4812,
    city: "M\u00fchlethal",
    cantonShort: "AG",
    latitude: 47.299,
    longitude: 7.9784,
  },
  {
    zipCode: 4813,
    city: "Uerkheim",
    cantonShort: "AG",
    latitude: 47.3042,
    longitude: 8.01,
  },
  {
    zipCode: 4814,
    city: "Bottenwil",
    cantonShort: "AG",
    latitude: 47.2847,
    longitude: 8.0021,
  },
  {
    zipCode: 4852,
    city: "Rothrist",
    cantonShort: "AG",
    latitude: 47.2983,
    longitude: 7.8836,
  },
  {
    zipCode: 4853,
    city: "Riken AG",
    cantonShort: "AG",
    latitude: 47.2729,
    longitude: 7.8612,
  },
  {
    zipCode: 4853,
    city: "Murgenthal",
    cantonShort: "AG",
    latitude: 47.2698,
    longitude: 7.8388,
  },
  {
    zipCode: 4853,
    city: "Murgenthal",
    cantonShort: "AG",
    latitude: 47.2698,
    longitude: 7.8388,
  },
  {
    zipCode: 4856,
    city: "Glash\u00fctten",
    cantonShort: "AG",
    latitude: 47.2492,
    longitude: 7.8546,
  },
  {
    zipCode: 5053,
    city: "Staffelbach",
    cantonShort: "AG",
    latitude: 47.2813,
    longitude: 8.0391,
  },
  {
    zipCode: 5053,
    city: "Wittwil",
    cantonShort: "AG",
    latitude: 47.2931,
    longitude: 8.0359,
  },
  {
    zipCode: 5054,
    city: "Kirchleerau",
    cantonShort: "AG",
    latitude: 47.2772,
    longitude: 8.074,
  },
  {
    zipCode: 5054,
    city: "Moosleerau",
    cantonShort: "AG",
    latitude: 47.2645,
    longitude: 8.0661,
  },
  {
    zipCode: 5054,
    city: "Kirchleerau-Moosleerau",
    cantonShort: "AG",
    latitude: 47.2772,
    longitude: 8.0741,
  },
  {
    zipCode: 5056,
    city: "Attelwil",
    cantonShort: "AG",
    latitude: 47.2634,
    longitude: 8.0397,
  },
  {
    zipCode: 5057,
    city: "Reitnau",
    cantonShort: "AG",
    latitude: 47.2515,
    longitude: 8.0354,
  },
  {
    zipCode: 5058,
    city: "Wiliberg",
    cantonShort: "AG",
    latitude: 47.2669,
    longitude: 8.0166,
  },
  {
    zipCode: 5742,
    city: "K\u00f6lliken",
    cantonShort: "AG",
    latitude: 47.3347,
    longitude: 8.021,
  },
  {
    zipCode: 5745,
    city: "Safenwil",
    cantonShort: "AG",
    latitude: 47.3197,
    longitude: 7.9869,
  },
  {
    zipCode: 5304,
    city: "Endingen",
    cantonShort: "AG",
    latitude: 47.5307,
    longitude: 8.2901,
  },
  {
    zipCode: 5305,
    city: "Unterendingen",
    cantonShort: "AG",
    latitude: 47.5461,
    longitude: 8.289,
  },
  {
    zipCode: 5306,
    city: "Tegerfelden",
    cantonShort: "AG",
    latitude: 47.5619,
    longitude: 8.2849,
  },
  {
    zipCode: 5312,
    city: "D\u00f6ttingen",
    cantonShort: "AG",
    latitude: 47.56,
    longitude: 8.253,
  },
  {
    zipCode: 5313,
    city: "Klingnau",
    cantonShort: "AG",
    latitude: 47.5886,
    longitude: 8.2507,
  },
  {
    zipCode: 5314,
    city: "Kleind\u00f6ttingen",
    cantonShort: "AG",
    latitude: 47.5692,
    longitude: 8.238,
  },
  {
    zipCode: 5315,
    city: "B\u00f6ttstein",
    cantonShort: "AG",
    latitude: 47.5529,
    longitude: 8.2181,
  },
  {
    zipCode: 5316,
    city: "Leuggern",
    cantonShort: "AG",
    latitude: 47.584,
    longitude: 8.2169,
  },
  {
    zipCode: 5317,
    city: "Hettenschwil",
    cantonShort: "AG",
    latitude: 47.5739,
    longitude: 8.1939,
  },
  {
    zipCode: 5322,
    city: "Koblenz",
    cantonShort: "AG",
    latitude: 47.6044,
    longitude: 8.2447,
  },
  {
    zipCode: 5323,
    city: "Rietheim",
    cantonShort: "AG",
    latitude: 47.6029,
    longitude: 8.2739,
  },
  {
    zipCode: 5324,
    city: "Full-Reuenthal",
    cantonShort: "AG",
    latitude: 47.6087,
    longitude: 8.2028,
  },
  {
    zipCode: 5325,
    city: "Leibstadt",
    cantonShort: "AG",
    latitude: 47.5897,
    longitude: 8.1784,
  },
  {
    zipCode: 5330,
    city: "Bad Zurzach",
    cantonShort: "AG",
    latitude: 47.5859,
    longitude: 8.292,
  },
  {
    zipCode: 5332,
    city: "Rekingen AG",
    cantonShort: "AG",
    latitude: 47.5671,
    longitude: 8.3218,
  },
  {
    zipCode: 5333,
    city: "Baldingen",
    cantonShort: "AG",
    latitude: 47.5548,
    longitude: 8.3136,
  },
  {
    zipCode: 5334,
    city: "B\u00f6bikon",
    cantonShort: "AG",
    latitude: 47.5504,
    longitude: 8.3379,
  },
  {
    zipCode: 5425,
    city: "Schneisingen",
    cantonShort: "AG",
    latitude: 47.5249,
    longitude: 8.3617,
  },
  {
    zipCode: 5426,
    city: "Lengnau AG",
    cantonShort: "AG",
    latitude: 47.5251,
    longitude: 8.3241,
  },
  {
    zipCode: 5462,
    city: "Siglistorf",
    cantonShort: "AG",
    latitude: 47.5387,
    longitude: 8.387,
  },
  {
    zipCode: 5463,
    city: "Wislikofen",
    cantonShort: "AG",
    latitude: 47.5537,
    longitude: 8.3667,
  },
  {
    zipCode: 5464,
    city: "R\u00fcmikon AG",
    cantonShort: "AG",
    latitude: 47.5643,
    longitude: 8.3806,
  },
  {
    zipCode: 5465,
    city: "Mellikon",
    cantonShort: "AG",
    latitude: 47.5633,
    longitude: 8.3472,
  },
  {
    zipCode: 5466,
    city: "Kaiserstuhl AG",
    cantonShort: "AG",
    latitude: 47.5677,
    longitude: 8.419,
  },
  {
    zipCode: 5467,
    city: "Fisibach",
    cantonShort: "AG",
    latitude: 47.5583,
    longitude: 8.4047,
  },
  {
    zipCode: 9050,
    city: "Appenzell Schlatt",
    cantonShort: "AI",
    latitude: 47.3563,
    longitude: 9.4015,
  },
  {
    zipCode: 9050,
    city: "Appenzell Steinegg",
    cantonShort: "AI",
    latitude: 47.3211,
    longitude: 9.4522,
  },
  {
    zipCode: 9050,
    city: "Appenzell",
    cantonShort: "AI",
    latitude: 47.3238,
    longitude: 9.4038,
  },
  {
    zipCode: 9050,
    city: "Appenzell Eggerstanden",
    cantonShort: "AI",
    latitude: 47.334,
    longitude: 9.4797,
  },
  {
    zipCode: 9050,
    city: "Appenzell Enggenh\u00fctten",
    cantonShort: "AI",
    latitude: 47.3491,
    longitude: 9.3614,
  },
  {
    zipCode: 9050,
    city: "Appenzell Meistersr\u00fcte",
    cantonShort: "AI",
    latitude: 47.348,
    longitude: 9.4364,
  },
  {
    zipCode: 9054,
    city: "Haslen AI",
    cantonShort: "AI",
    latitude: 47.3692,
    longitude: 9.3788,
  },
  {
    zipCode: 9057,
    city: "Wasserauen",
    cantonShort: "AI",
    latitude: 47.2644,
    longitude: 9.3918,
  },
  {
    zipCode: 9057,
    city: "Weissbad",
    cantonShort: "AI",
    latitude: 47.2868,
    longitude: 9.3818,
  },
  {
    zipCode: 9057,
    city: "Schwende",
    cantonShort: "AI",
    latitude: 47.2965,
    longitude: 9.4345,
  },
  {
    zipCode: 9058,
    city: "Br\u00fclisau",
    cantonShort: "AI",
    latitude: 47.2799,
    longitude: 9.4497,
  },
  {
    zipCode: 9108,
    city: "Gontenbad",
    cantonShort: "AI",
    latitude: 47.3259,
    longitude: 9.3707,
  },
  {
    zipCode: 9108,
    city: "Jakobsbad",
    cantonShort: "AI",
    latitude: 47.3064,
    longitude: 9.3299,
  },
  {
    zipCode: 9108,
    city: "Gonten",
    cantonShort: "AI",
    latitude: 47.3237,
    longitude: 9.3494,
  },
  {
    zipCode: 9413,
    city: "Oberegg",
    cantonShort: "AI",
    latitude: 47.4165,
    longitude: 9.5533,
  },
  {
    zipCode: 9413,
    city: "Oberegg",
    cantonShort: "AI",
    latitude: 47.4165,
    longitude: 9.5533,
  },
  {
    zipCode: 9442,
    city: "B\u00fcriswilen",
    cantonShort: "AI",
    latitude: 47.4331,
    longitude: 9.6025,
  },
  {
    zipCode: 9063,
    city: "Stein AR",
    cantonShort: "AR",
    latitude: 47.3761,
    longitude: 9.3417,
  },
  {
    zipCode: 9064,
    city: "Hundwil",
    cantonShort: "AR",
    latitude: 47.3517,
    longitude: 9.319,
  },
  {
    zipCode: 9100,
    city: "Herisau",
    cantonShort: "AR",
    latitude: 47.3833,
    longitude: 9.2813,
  },
  {
    zipCode: 9102,
    city: "Herisau",
    cantonShort: "AR",
    latitude: 47.3861,
    longitude: 9.2792,
  },
  {
    zipCode: 9103,
    city: "Schwellbrunn",
    cantonShort: "AR",
    latitude: 47.3516,
    longitude: 9.2362,
  },
  {
    zipCode: 9104,
    city: "Waldstatt",
    cantonShort: "AR",
    latitude: 47.3567,
    longitude: 9.2854,
  },
  {
    zipCode: 9105,
    city: "Sch\u00f6nengrund",
    cantonShort: "AR",
    latitude: 47.3254,
    longitude: 9.2267,
  },
  {
    zipCode: 9107,
    city: "Urn\u00e4sch",
    cantonShort: "AR",
    latitude: 47.2948,
    longitude: 9.2779,
  },
  {
    zipCode: 9112,
    city: "Schachen b. Herisau",
    cantonShort: "AR",
    latitude: 47.3797,
    longitude: 9.2366,
  },
  {
    zipCode: 9037,
    city: "Speicherschwendi",
    cantonShort: "AR",
    latitude: 47.428,
    longitude: 9.4383,
  },
  {
    zipCode: 9042,
    city: "Speicher",
    cantonShort: "AR",
    latitude: 47.4099,
    longitude: 9.441,
  },
  {
    zipCode: 9043,
    city: "Trogen",
    cantonShort: "AR",
    latitude: 47.4003,
    longitude: 9.4764,
  },
  {
    zipCode: 9052,
    city: "Niederteufen",
    cantonShort: "AR",
    latitude: 47.3909,
    longitude: 9.362,
  },
  {
    zipCode: 9053,
    city: "Teufen AR",
    cantonShort: "AR",
    latitude: 47.3928,
    longitude: 9.4,
  },
  {
    zipCode: 9055,
    city: "B\u00fchler",
    cantonShort: "AR",
    latitude: 47.3783,
    longitude: 9.4266,
  },
  {
    zipCode: 9056,
    city: "Gais",
    cantonShort: "AR",
    latitude: 47.368,
    longitude: 9.4714,
  },
  {
    zipCode: 9062,
    city: "Lustm\u00fchle",
    cantonShort: "AR",
    latitude: 47.3988,
    longitude: 9.3542,
  },
  {
    zipCode: 9035,
    city: "Grub AR",
    cantonShort: "AR",
    latitude: 47.4412,
    longitude: 9.5017,
  },
  {
    zipCode: 9038,
    city: "Rehetobel",
    cantonShort: "AR",
    latitude: 47.4283,
    longitude: 9.4843,
  },
  {
    zipCode: 9044,
    city: "Wald AR",
    cantonShort: "AR",
    latitude: 47.4146,
    longitude: 9.5044,
  },
  {
    zipCode: 9405,
    city: "Wienacht-Tobel",
    cantonShort: "AR",
    latitude: 47.465,
    longitude: 9.5362,
  },
  {
    zipCode: 9410,
    city: "Heiden",
    cantonShort: "AR",
    latitude: 47.4408,
    longitude: 9.5349,
  },
  {
    zipCode: 9411,
    city: "Schachen b. Reute",
    cantonShort: "AR",
    latitude: 47.4256,
    longitude: 9.5608,
  },
  {
    zipCode: 9411,
    city: "Reute AR",
    cantonShort: "AR",
    latitude: 47.4088,
    longitude: 9.5662,
  },
  {
    zipCode: 9426,
    city: "Lutzenberg",
    cantonShort: "AR",
    latitude: 47.4593,
    longitude: 9.5748,
  },
  {
    zipCode: 9427,
    city: "Wolfhalden",
    cantonShort: "AR",
    latitude: 47.4506,
    longitude: 9.5612,
  },
  {
    zipCode: 9428,
    city: "Walzenhausen",
    cantonShort: "AR",
    latitude: 47.4433,
    longitude: 9.5971,
  },
  {
    zipCode: 2333,
    city: "La Ferri\u00e8re",
    cantonShort: "BE",
    latitude: 47.1498,
    longitude: 6.9105,
  },
  {
    zipCode: 2345,
    city: "Le Cerneux-Veusil",
    cantonShort: "BE",
    latitude: 47.1791,
    longitude: 6.967,
  },
  {
    zipCode: 2515,
    city: "Pr\u00eales",
    cantonShort: "BE",
    latitude: 47.095,
    longitude: 7.1153,
  },
  {
    zipCode: 2516,
    city: "Lamboing",
    cantonShort: "BE",
    latitude: 47.1256,
    longitude: 7.1575,
  },
  {
    zipCode: 2517,
    city: "Diesse",
    cantonShort: "BE",
    latitude: 47.1201,
    longitude: 7.118,
  },
  {
    zipCode: 2518,
    city: "Nods",
    cantonShort: "BE",
    latitude: 47.1209,
    longitude: 7.0746,
  },
  {
    zipCode: 2520,
    city: "La Neuveville",
    cantonShort: "BE",
    latitude: 47.0741,
    longitude: 7.1023,
  },
  {
    zipCode: 2534,
    city: "Orvin",
    cantonShort: "BE",
    latitude: 47.157,
    longitude: 7.2125,
  },
  {
    zipCode: 2534,
    city: "Les Pr\u00e9s-d'Orvin",
    cantonShort: "BE",
    latitude: 47.1555,
    longitude: 7.1664,
  },
  {
    zipCode: 2535,
    city: "Frinvillier",
    cantonShort: "BE",
    latitude: 47.1695,
    longitude: 7.2544,
  },
  {
    zipCode: 2536,
    city: "Plagne",
    cantonShort: "BE",
    latitude: 47.1905,
    longitude: 7.2848,
  },
  {
    zipCode: 2537,
    city: "Vauffelin",
    cantonShort: "BE",
    latitude: 47.1841,
    longitude: 7.298,
  },
  {
    zipCode: 2538,
    city: "Romont BE",
    cantonShort: "BE",
    latitude: 47.1932,
    longitude: 7.3318,
  },
  {
    zipCode: 2603,
    city: "P\u00e9ry",
    cantonShort: "BE",
    latitude: 47.1998,
    longitude: 7.2694,
  },
  {
    zipCode: 2604,
    city: "La Heutte",
    cantonShort: "BE",
    latitude: 47.1887,
    longitude: 7.2178,
  },
  {
    zipCode: 2605,
    city: "Sonceboz-Sombeval",
    cantonShort: "BE",
    latitude: 47.1884,
    longitude: 7.1806,
  },
  {
    zipCode: 2606,
    city: "Corg\u00e9mont",
    cantonShort: "BE",
    latitude: 47.1927,
    longitude: 7.1413,
  },
  {
    zipCode: 2607,
    city: "Cort\u00e9bert",
    cantonShort: "BE",
    latitude: 47.1893,
    longitude: 7.1071,
  },
  {
    zipCode: 2608,
    city: "Courtelary",
    cantonShort: "BE",
    latitude: 47.1857,
    longitude: 7.074,
  },
  {
    zipCode: 2608,
    city: "Montagne-de-Courtelary",
    cantonShort: "BE",
    latitude: 47.1599,
    longitude: 7.0959,
  },
  {
    zipCode: 2610,
    city: "Les Pontins",
    cantonShort: "BE",
    latitude: 47.1308,
    longitude: 7.0049,
  },
  {
    zipCode: 2610,
    city: "Mont-Crosin",
    cantonShort: "BE",
    latitude: 47.1862,
    longitude: 7.0307,
  },
  {
    zipCode: 2610,
    city: "St-Imier",
    cantonShort: "BE",
    latitude: 47.1559,
    longitude: 6.9933,
  },
  {
    zipCode: 2610,
    city: "Mont-Soleil",
    cantonShort: "BE",
    latitude: 47.1529,
    longitude: 6.9593,
  },
  {
    zipCode: 2612,
    city: "Cormoret",
    cantonShort: "BE",
    latitude: 47.1679,
    longitude: 7.0484,
  },
  {
    zipCode: 2613,
    city: "Villeret",
    cantonShort: "BE",
    latitude: 47.1491,
    longitude: 7.0309,
  },
  {
    zipCode: 2615,
    city: "Montagne-de-Sonvilier",
    cantonShort: "BE",
    latitude: 47.1207,
    longitude: 6.9579,
  },
  {
    zipCode: 2615,
    city: "Sonvilier",
    cantonShort: "BE",
    latitude: 47.1336,
    longitude: 6.9619,
  },
  {
    zipCode: 2616,
    city: "Renan BE",
    cantonShort: "BE",
    latitude: 47.1072,
    longitude: 6.9012,
  },
  {
    zipCode: 2710,
    city: "Tavannes",
    cantonShort: "BE",
    latitude: 47.2216,
    longitude: 7.1893,
  },
  {
    zipCode: 2712,
    city: "Le Fuet",
    cantonShort: "BE",
    latitude: 47.2485,
    longitude: 7.1751,
  },
  {
    zipCode: 2713,
    city: "Bellelay",
    cantonShort: "BE",
    latitude: 47.264,
    longitude: 7.1637,
  },
  {
    zipCode: 2715,
    city: "Ch\u00e2telat",
    cantonShort: "BE",
    latitude: 47.2683,
    longitude: 7.1895,
  },
  {
    zipCode: 2715,
    city: "Monible",
    cantonShort: "BE",
    latitude: 47.2797,
    longitude: 7.1994,
  },
  {
    zipCode: 2716,
    city: "Sornetan",
    cantonShort: "BE",
    latitude: 47.2728,
    longitude: 7.2179,
  },
  {
    zipCode: 2717,
    city: "Reb\u00e9velier",
    cantonShort: "BE",
    latitude: 47.2899,
    longitude: 7.1861,
  },
  {
    zipCode: 2717,
    city: "Fornet-Dessous",
    cantonShort: "BE",
    latitude: 47.2771,
    longitude: 7.1761,
  },
  {
    zipCode: 2720,
    city: "Tramelan",
    cantonShort: "BE",
    latitude: 47.23,
    longitude: 7.1173,
  },
  {
    zipCode: 2720,
    city: "La Tanne",
    cantonShort: "BE",
    latitude: 47.2137,
    longitude: 7.1413,
  },
  {
    zipCode: 2722,
    city: "Les Reussilles",
    cantonShort: "BE",
    latitude: 47.228,
    longitude: 7.0711,
  },
  {
    zipCode: 2723,
    city: "Mont-Tramelan",
    cantonShort: "BE",
    latitude: 47.2071,
    longitude: 7.0657,
  },
  {
    zipCode: 2732,
    city: "Saicourt",
    cantonShort: "BE",
    latitude: 47.2462,
    longitude: 7.2027,
  },
  {
    zipCode: 2732,
    city: "Saules BE",
    cantonShort: "BE",
    latitude: 47.2533,
    longitude: 7.2221,
  },
  {
    zipCode: 2732,
    city: "Reconvilier",
    cantonShort: "BE",
    latitude: 47.2248,
    longitude: 7.2242,
  },
  {
    zipCode: 2732,
    city: "Loveresse",
    cantonShort: "BE",
    latitude: 47.2408,
    longitude: 7.2411,
  },
  {
    zipCode: 2733,
    city: "Pontenet",
    cantonShort: "BE",
    latitude: 47.2498,
    longitude: 7.2542,
  },
  {
    zipCode: 2735,
    city: "Champoz",
    cantonShort: "BE",
    latitude: 47.2598,
    longitude: 7.3107,
  },
  {
    zipCode: 2735,
    city: "B\u00e9vilard",
    cantonShort: "BE",
    latitude: 47.2312,
    longitude: 7.2855,
  },
  {
    zipCode: 2735,
    city: "Malleray-B\u00e9vilard",
    cantonShort: "BE",
    latitude: 47.2391,
    longitude: 7.2714,
  },
  {
    zipCode: 2735,
    city: "Malleray",
    cantonShort: "BE",
    latitude: 47.2377,
    longitude: 7.2661,
  },
  {
    zipCode: 2736,
    city: "Sorvilier",
    cantonShort: "BE",
    latitude: 47.2315,
    longitude: 7.3076,
  },
  {
    zipCode: 2738,
    city: "Court",
    cantonShort: "BE",
    latitude: 47.239,
    longitude: 7.3609,
  },
  {
    zipCode: 2740,
    city: "Moutier",
    cantonShort: "BE",
    latitude: 47.2778,
    longitude: 7.3602,
  },
  {
    zipCode: 2742,
    city: "Perrefitte",
    cantonShort: "BE",
    latitude: 47.2774,
    longitude: 7.3182,
  },
  {
    zipCode: 2743,
    city: "Eschert",
    cantonShort: "BE",
    latitude: 47.2655,
    longitude: 7.4076,
  },
  {
    zipCode: 2744,
    city: "Belprahon",
    cantonShort: "BE",
    latitude: 47.2905,
    longitude: 7.4041,
  },
  {
    zipCode: 2745,
    city: "Grandval",
    cantonShort: "BE",
    latitude: 47.2836,
    longitude: 7.428,
  },
  {
    zipCode: 2746,
    city: "Cr\u00e9mines",
    cantonShort: "BE",
    latitude: 47.285,
    longitude: 7.4514,
  },
  {
    zipCode: 2747,
    city: "Seehof",
    cantonShort: "BE",
    latitude: 47.3048,
    longitude: 7.5253,
  },
  {
    zipCode: 2747,
    city: "Corcelles BE",
    cantonShort: "BE",
    latitude: 47.2916,
    longitude: 7.4714,
  },
  {
    zipCode: 2748,
    city: "Les Ecorcheresses",
    cantonShort: "BE",
    latitude: 47.2836,
    longitude: 7.2637,
  },
  {
    zipCode: 2748,
    city: "Souboz",
    cantonShort: "BE",
    latitude: 47.2722,
    longitude: 7.2558,
  },
  {
    zipCode: 2762,
    city: "Roches BE",
    cantonShort: "BE",
    latitude: 47.3039,
    longitude: 7.3818,
  },
  {
    zipCode: 2827,
    city: "Schelten",
    cantonShort: "BE",
    latitude: 47.3327,
    longitude: 7.5531,
  },
  {
    zipCode: 2500,
    city: "Biel/Bienne 4",
    cantonShort: "BE",
    latitude: 47.1492,
    longitude: 7.2611,
  },
  {
    zipCode: 2500,
    city: "Biel/Bienne",
    cantonShort: "BE",
    latitude: 47.1371,
    longitude: 7.2461,
  },
  {
    zipCode: 2500,
    city: "Biel/Bienne 6",
    cantonShort: "BE",
    latitude: 47.1492,
    longitude: 7.2611,
  },
  {
    zipCode: 2501,
    city: "Biel/Bienne",
    cantonShort: "BE",
    latitude: 47.1371,
    longitude: 7.2461,
  },
  {
    zipCode: 2502,
    city: "Biel/Bienne",
    cantonShort: "BE",
    latitude: 47.1489,
    longitude: 7.2473,
  },
  {
    zipCode: 2503,
    city: "Biel/Bienne",
    cantonShort: "BE",
    latitude: 47.1324,
    longitude: 7.2551,
  },
  {
    zipCode: 2504,
    city: "Biel/Bienne",
    cantonShort: "BE",
    latitude: 47.1574,
    longitude: 7.2809,
  },
  {
    zipCode: 2505,
    city: "Biel/Bienne",
    cantonShort: "BE",
    latitude: 47.1338,
    longitude: 7.2171,
  },
  {
    zipCode: 2512,
    city: "T\u00fcscherz-Alferm\u00e9e",
    cantonShort: "BE",
    latitude: 47.1217,
    longitude: 7.1964,
  },
  {
    zipCode: 2513,
    city: "Twann",
    cantonShort: "BE",
    latitude: 47.1053,
    longitude: 7.1637,
  },
  {
    zipCode: 2514,
    city: "Ligerz",
    cantonShort: "BE",
    latitude: 47.0905,
    longitude: 7.1388,
  },
  {
    zipCode: 2532,
    city: "Macolin",
    cantonShort: "BE",
    latitude: 47.139,
    longitude: 7.2141,
  },
  {
    zipCode: 2532,
    city: "Magglingen/Macolin",
    cantonShort: "BE",
    latitude: 47.142,
    longitude: 7.2115,
  },
  {
    zipCode: 2533,
    city: "Evilard",
    cantonShort: "BE",
    latitude: 47.1509,
    longitude: 7.2359,
  },
  {
    zipCode: 2542,
    city: "Pieterlen",
    cantonShort: "BE",
    latitude: 47.1739,
    longitude: 7.328,
  },
  {
    zipCode: 2543,
    city: "Lengnau BE",
    cantonShort: "BE",
    latitude: 47.182,
    longitude: 7.3659,
  },
  {
    zipCode: 2552,
    city: "Orpund",
    cantonShort: "BE",
    latitude: 47.1417,
    longitude: 7.3031,
  },
  {
    zipCode: 2553,
    city: "Safnern",
    cantonShort: "BE",
    latitude: 47.1523,
    longitude: 7.322,
  },
  {
    zipCode: 2554,
    city: "Meinisberg",
    cantonShort: "BE",
    latitude: 47.1614,
    longitude: 7.3527,
  },
  {
    zipCode: 2555,
    city: "Br\u00fcgg BE",
    cantonShort: "BE",
    latitude: 47.128,
    longitude: 7.2781,
  },
  {
    zipCode: 2556,
    city: "Scheuren",
    cantonShort: "BE",
    latitude: 47.1323,
    longitude: 7.3269,
  },
  {
    zipCode: 2556,
    city: "Schwadernau",
    cantonShort: "BE",
    latitude: 47.1239,
    longitude: 7.3173,
  },
  {
    zipCode: 2558,
    city: "Aegerten",
    cantonShort: "BE",
    latitude: 47.1172,
    longitude: 7.2851,
  },
  {
    zipCode: 2560,
    city: "Nidau",
    cantonShort: "BE",
    latitude: 47.1244,
    longitude: 7.2409,
  },
  {
    zipCode: 2562,
    city: "Port",
    cantonShort: "BE",
    latitude: 47.1135,
    longitude: 7.2591,
  },
  {
    zipCode: 2563,
    city: "Ipsach",
    cantonShort: "BE",
    latitude: 47.1149,
    longitude: 7.2314,
  },
  {
    zipCode: 2564,
    city: "Bellmund",
    cantonShort: "BE",
    latitude: 47.1024,
    longitude: 7.2436,
  },
  {
    zipCode: 2572,
    city: "Sutz",
    cantonShort: "BE",
    latitude: 47.0992,
    longitude: 7.2188,
  },
  {
    zipCode: 2572,
    city: "M\u00f6rigen",
    cantonShort: "BE",
    latitude: 47.0843,
    longitude: 7.2116,
  },
  {
    zipCode: 2557,
    city: "Studen BE",
    cantonShort: "BE",
    latitude: 47.1126,
    longitude: 7.3029,
  },
  {
    zipCode: 2565,
    city: "Jens",
    cantonShort: "BE",
    latitude: 47.0981,
    longitude: 7.2691,
  },
  {
    zipCode: 2575,
    city: "Gerolfingen",
    cantonShort: "BE",
    latitude: 47.0759,
    longitude: 7.2001,
  },
  {
    zipCode: 2575,
    city: "Hagneck",
    cantonShort: "BE",
    latitude: 47.0532,
    longitude: 7.1872,
  },
  {
    zipCode: 2575,
    city: "T\u00e4uffelen",
    cantonShort: "BE",
    latitude: 47.0621,
    longitude: 7.1957,
  },
  {
    zipCode: 2576,
    city: "L\u00fcscherz",
    cantonShort: "BE",
    latitude: 47.0441,
    longitude: 7.1615,
  },
  {
    zipCode: 2577,
    city: "Siselen BE",
    cantonShort: "BE",
    latitude: 47.0325,
    longitude: 7.196,
  },
  {
    zipCode: 2577,
    city: "Finsterhennen",
    cantonShort: "BE",
    latitude: 47.0206,
    longitude: 7.1792,
  },
  {
    zipCode: 3035,
    city: "Frieswil",
    cantonShort: "BE",
    latitude: 46.9924,
    longitude: 7.2934,
  },
  {
    zipCode: 3036,
    city: "Detligen",
    cantonShort: "BE",
    latitude: 46.994,
    longitude: 7.268,
  },
  {
    zipCode: 3053,
    city: "L\u00e4tti",
    cantonShort: "BE",
    latitude: 47.038,
    longitude: 7.4314,
  },
  {
    zipCode: 3054,
    city: "Sch\u00fcpfen",
    cantonShort: "BE",
    latitude: 47.0358,
    longitude: 7.3733,
  },
  {
    zipCode: 3225,
    city: "M\u00fcntschemier",
    cantonShort: "BE",
    latitude: 46.9949,
    longitude: 7.1464,
  },
  {
    zipCode: 3226,
    city: "Treiten",
    cantonShort: "BE",
    latitude: 47.0062,
    longitude: 7.1656,
  },
  {
    zipCode: 3232,
    city: "Ins",
    cantonShort: "BE",
    latitude: 47.0025,
    longitude: 7.1093,
  },
  {
    zipCode: 3233,
    city: "Tschugg",
    cantonShort: "BE",
    latitude: 47.0239,
    longitude: 7.0779,
  },
  {
    zipCode: 3234,
    city: "Vinelz",
    cantonShort: "BE",
    latitude: 47.0326,
    longitude: 7.1188,
  },
  {
    zipCode: 3235,
    city: "Erlach",
    cantonShort: "BE",
    latitude: 47.0468,
    longitude: 7.104,
  },
  {
    zipCode: 3236,
    city: "Gampelen",
    cantonShort: "BE",
    latitude: 46.9964,
    longitude: 7.0593,
  },
  {
    zipCode: 3237,
    city: "Br\u00fcttelen",
    cantonShort: "BE",
    latitude: 47.024,
    longitude: 7.1512,
  },
  {
    zipCode: 3238,
    city: "Gals",
    cantonShort: "BE",
    latitude: 47.0339,
    longitude: 7.0563,
  },
  {
    zipCode: 3250,
    city: "Lyss",
    cantonShort: "BE",
    latitude: 47.0763,
    longitude: 7.3152,
  },
  {
    zipCode: 3251,
    city: "Ruppoldsried",
    cantonShort: "BE",
    latitude: 47.0904,
    longitude: 7.4271,
  },
  {
    zipCode: 3251,
    city: "Wengi b. B\u00fcren",
    cantonShort: "BE",
    latitude: 47.0861,
    longitude: 7.3954,
  },
  {
    zipCode: 3252,
    city: "Worben",
    cantonShort: "BE",
    latitude: 47.0991,
    longitude: 7.2968,
  },
  {
    zipCode: 3255,
    city: "Rapperswil BE",
    cantonShort: "BE",
    latitude: 47.0662,
    longitude: 7.4111,
  },
  {
    zipCode: 3256,
    city: "Bangerten b. Dieterswil",
    cantonShort: "BE",
    latitude: 47.0563,
    longitude: 7.447,
  },
  {
    zipCode: 3256,
    city: "Dieterswil",
    cantonShort: "BE",
    latitude: 47.0503,
    longitude: 7.4305,
  },
  {
    zipCode: 3256,
    city: "Seewil",
    cantonShort: "BE",
    latitude: 47.0463,
    longitude: 7.4108,
  },
  {
    zipCode: 3257,
    city: "Grossaffoltern",
    cantonShort: "BE",
    latitude: 47.0711,
    longitude: 7.3652,
  },
  {
    zipCode: 3257,
    city: "Ammerzwil BE",
    cantonShort: "BE",
    latitude: 47.0736,
    longitude: 7.3425,
  },
  {
    zipCode: 3262,
    city: "Suberg",
    cantonShort: "BE",
    latitude: 47.0559,
    longitude: 7.3389,
  },
  {
    zipCode: 3263,
    city: "B\u00fcetigen",
    cantonShort: "BE",
    latitude: 47.106,
    longitude: 7.338,
  },
  {
    zipCode: 3264,
    city: "Diessbach b. B\u00fcren",
    cantonShort: "BE",
    latitude: 47.1044,
    longitude: 7.3639,
  },
  {
    zipCode: 3266,
    city: "Wiler b. Seedorf",
    cantonShort: "BE",
    latitude: 47.0494,
    longitude: 7.3209,
  },
  {
    zipCode: 3267,
    city: "Seedorf BE",
    cantonShort: "BE",
    latitude: 47.0265,
    longitude: 7.3231,
  },
  {
    zipCode: 3268,
    city: "Lobsigen",
    cantonShort: "BE",
    latitude: 47.0187,
    longitude: 7.2998,
  },
  {
    zipCode: 3270,
    city: "Aarberg",
    cantonShort: "BE",
    latitude: 47.0461,
    longitude: 7.2829,
  },
  {
    zipCode: 3271,
    city: "Radelfingen b. Aarberg",
    cantonShort: "BE",
    latitude: 47.0186,
    longitude: 7.2729,
  },
  {
    zipCode: 3272,
    city: "Walperswil",
    cantonShort: "BE",
    latitude: 47.0522,
    longitude: 7.2238,
  },
  {
    zipCode: 3272,
    city: "Epsach",
    cantonShort: "BE",
    latitude: 47.0704,
    longitude: 7.2201,
  },
  {
    zipCode: 3273,
    city: "Kappelen",
    cantonShort: "BE",
    latitude: 47.0713,
    longitude: 7.2744,
  },
  {
    zipCode: 3274,
    city: "Hermrigen",
    cantonShort: "BE",
    latitude: 47.0814,
    longitude: 7.2408,
  },
  {
    zipCode: 3274,
    city: "B\u00fchl b. Aarberg",
    cantonShort: "BE",
    latitude: 47.067,
    longitude: 7.2465,
  },
  {
    zipCode: 3274,
    city: "Merzligen",
    cantonShort: "BE",
    latitude: 47.0873,
    longitude: 7.2537,
  },
  {
    zipCode: 3282,
    city: "Bargen BE",
    cantonShort: "BE",
    latitude: 47.0362,
    longitude: 7.2449,
  },
  {
    zipCode: 3283,
    city: "Niederried b. Kallnach",
    cantonShort: "BE",
    latitude: 47.0063,
    longitude: 7.2314,
  },
  {
    zipCode: 3283,
    city: "Kallnach",
    cantonShort: "BE",
    latitude: 47.0166,
    longitude: 7.2168,
  },
  {
    zipCode: 3292,
    city: "Busswil BE",
    cantonShort: "BE",
    latitude: 47.0995,
    longitude: 7.3211,
  },
  {
    zipCode: 3293,
    city: "Dotzigen",
    cantonShort: "BE",
    latitude: 47.1242,
    longitude: 7.3479,
  },
  {
    zipCode: 3294,
    city: "Meienried",
    cantonShort: "BE",
    latitude: 47.1372,
    longitude: 7.3417,
  },
  {
    zipCode: 3294,
    city: "B\u00fcren an der Aare",
    cantonShort: "BE",
    latitude: 47.1417,
    longitude: 7.3701,
  },
  {
    zipCode: 3295,
    city: "R\u00fcti b. B\u00fcren",
    cantonShort: "BE",
    latitude: 47.1513,
    longitude: 7.4113,
  },
  {
    zipCode: 3296,
    city: "Arch",
    cantonShort: "BE",
    latitude: 47.1656,
    longitude: 7.4342,
  },
  {
    zipCode: 3297,
    city: "Leuzigen",
    cantonShort: "BE",
    latitude: 47.1709,
    longitude: 7.4666,
  },
  {
    zipCode: 3298,
    city: "Oberwil b. B\u00fcren",
    cantonShort: "BE",
    latitude: 47.1307,
    longitude: 7.4114,
  },
  {
    zipCode: 3360,
    city: "Herzogenbuchsee",
    cantonShort: "BE",
    latitude: 47.1946,
    longitude: 7.7138,
  },
  {
    zipCode: 3362,
    city: "Nieder\u00f6nz",
    cantonShort: "BE",
    latitude: 47.1861,
    longitude: 7.684,
  },
  {
    zipCode: 3363,
    city: "Ober\u00f6nz",
    cantonShort: "BE",
    latitude: 47.1739,
    longitude: 7.6887,
  },
  {
    zipCode: 3365,
    city: "Seeberg",
    cantonShort: "BE",
    latitude: 47.159,
    longitude: 7.6643,
  },
  {
    zipCode: 3365,
    city: "Grasswil",
    cantonShort: "BE",
    latitude: 47.1408,
    longitude: 7.669,
  },
  {
    zipCode: 3366,
    city: "Bettenhausen",
    cantonShort: "BE",
    latitude: 47.1743,
    longitude: 7.7128,
  },
  {
    zipCode: 3366,
    city: "Bollodingen",
    cantonShort: "BE",
    latitude: 47.1659,
    longitude: 7.7047,
  },
  {
    zipCode: 3367,
    city: "Th\u00f6rigen",
    cantonShort: "BE",
    latitude: 47.173,
    longitude: 7.7307,
  },
  {
    zipCode: 3367,
    city: "Ochlenberg",
    cantonShort: "BE",
    latitude: 47.1535,
    longitude: 7.7421,
  },
  {
    zipCode: 3368,
    city: "Bleienbach",
    cantonShort: "BE",
    latitude: 47.1822,
    longitude: 7.7569,
  },
  {
    zipCode: 3372,
    city: "Wanzwil",
    cantonShort: "BE",
    latitude: 47.199,
    longitude: 7.6916,
  },
  {
    zipCode: 3373,
    city: "Heimenhausen",
    cantonShort: "BE",
    latitude: 47.2137,
    longitude: 7.6969,
  },
  {
    zipCode: 3373,
    city: "R\u00f6thenbach Herzogenbuchsee",
    cantonShort: "BE",
    latitude: 47.2067,
    longitude: 7.6833,
  },
  {
    zipCode: 3374,
    city: "Wangenried",
    cantonShort: "BE",
    latitude: 47.2177,
    longitude: 7.659,
  },
  {
    zipCode: 3375,
    city: "Inkwil",
    cantonShort: "BE",
    latitude: 47.2035,
    longitude: 7.6665,
  },
  {
    zipCode: 3376,
    city: "Berken",
    cantonShort: "BE",
    latitude: 47.2256,
    longitude: 7.7055,
  },
  {
    zipCode: 3376,
    city: "Graben",
    cantonShort: "BE",
    latitude: 47.2183,
    longitude: 7.7205,
  },
  {
    zipCode: 3377,
    city: "Walliswil b. Wangen",
    cantonShort: "BE",
    latitude: 47.2271,
    longitude: 7.6835,
  },
  {
    zipCode: 3380,
    city: "Walliswil b. Niederbipp",
    cantonShort: "BE",
    latitude: 47.2362,
    longitude: 7.6953,
  },
  {
    zipCode: 3380,
    city: "Wangen an der Aare",
    cantonShort: "BE",
    latitude: 47.2315,
    longitude: 7.6515,
  },
  {
    zipCode: 3464,
    city: "Schmidigen-M\u00fchleweg",
    cantonShort: "BE",
    latitude: 47.0939,
    longitude: 7.7377,
  },
  {
    zipCode: 3475,
    city: "Riedtwil",
    cantonShort: "BE",
    latitude: 47.1411,
    longitude: 7.6927,
  },
  {
    zipCode: 3475,
    city: "Hermiswil",
    cantonShort: "BE",
    latitude: 47.1558,
    longitude: 7.6998,
  },
  {
    zipCode: 3476,
    city: "Oschwand",
    cantonShort: "BE",
    latitude: 47.137,
    longitude: 7.7217,
  },
  {
    zipCode: 4536,
    city: "Attiswil",
    cantonShort: "BE",
    latitude: 47.2527,
    longitude: 7.611,
  },
  {
    zipCode: 4537,
    city: "Wiedlisbach",
    cantonShort: "BE",
    latitude: 47.2459,
    longitude: 7.6517,
  },
  {
    zipCode: 4538,
    city: "Oberbipp",
    cantonShort: "BE",
    latitude: 47.254,
    longitude: 7.6776,
  },
  {
    zipCode: 4539,
    city: "Rumisberg",
    cantonShort: "BE",
    latitude: 47.2716,
    longitude: 7.6346,
  },
  {
    zipCode: 4539,
    city: "Farnern",
    cantonShort: "BE",
    latitude: 47.2705,
    longitude: 7.6104,
  },
  {
    zipCode: 4704,
    city: "Wolfisberg",
    cantonShort: "BE",
    latitude: 47.2771,
    longitude: 7.6586,
  },
  {
    zipCode: 4704,
    city: "Niederbipp",
    cantonShort: "BE",
    latitude: 47.2662,
    longitude: 7.7037,
  },
  {
    zipCode: 4900,
    city: "Langenthal",
    cantonShort: "BE",
    latitude: 47.2158,
    longitude: 7.7946,
  },
  {
    zipCode: 4901,
    city: "Langenthal",
    cantonShort: "BE",
    latitude: 47.2153,
    longitude: 7.7961,
  },
  {
    zipCode: 4911,
    city: "Schwarzh\u00e4usern",
    cantonShort: "BE",
    latitude: 47.2557,
    longitude: 7.7669,
  },
  {
    zipCode: 4912,
    city: "Aarwangen",
    cantonShort: "BE",
    latitude: 47.2357,
    longitude: 7.7643,
  },
  {
    zipCode: 4913,
    city: "Bannwil",
    cantonShort: "BE",
    latitude: 47.2385,
    longitude: 7.7303,
  },
  {
    zipCode: 4914,
    city: "Roggwil BE",
    cantonShort: "BE",
    latitude: 47.2366,
    longitude: 7.8185,
  },
  {
    zipCode: 4916,
    city: "Untersteckholz",
    cantonShort: "BE",
    latitude: 47.2069,
    longitude: 7.844,
  },
  {
    zipCode: 4917,
    city: "Busswil b. Melchnau",
    cantonShort: "BE",
    latitude: 47.1876,
    longitude: 7.8295,
  },
  {
    zipCode: 4917,
    city: "Melchnau",
    cantonShort: "BE",
    latitude: 47.1771,
    longitude: 7.856,
  },
  {
    zipCode: 4919,
    city: "Reisiswil",
    cantonShort: "BE",
    latitude: 47.1613,
    longitude: 7.8424,
  },
  {
    zipCode: 4922,
    city: "B\u00fctzberg",
    cantonShort: "BE",
    latitude: 47.215,
    longitude: 7.7442,
  },
  {
    zipCode: 4922,
    city: "Thunstetten",
    cantonShort: "BE",
    latitude: 47.1991,
    longitude: 7.7478,
  },
  {
    zipCode: 4923,
    city: "Wynau",
    cantonShort: "BE",
    latitude: 47.256,
    longitude: 7.8048,
  },
  {
    zipCode: 4924,
    city: "Obersteckholz",
    cantonShort: "BE",
    latitude: 47.2019,
    longitude: 7.8254,
  },
  {
    zipCode: 4932,
    city: "Lotzwil",
    cantonShort: "BE",
    latitude: 47.1902,
    longitude: 7.7922,
  },
  {
    zipCode: 4932,
    city: "Gutenburg",
    cantonShort: "BE",
    latitude: 47.1822,
    longitude: 7.7998,
  },
  {
    zipCode: 4933,
    city: "R\u00fctschelen",
    cantonShort: "BE",
    latitude: 47.1712,
    longitude: 7.7709,
  },
  {
    zipCode: 4934,
    city: "Madiswil",
    cantonShort: "BE",
    latitude: 47.1618,
    longitude: 7.8111,
  },
  {
    zipCode: 4935,
    city: "Leimiswil",
    cantonShort: "BE",
    latitude: 47.1489,
    longitude: 7.7657,
  },
  {
    zipCode: 4936,
    city: "Kleindietwil",
    cantonShort: "BE",
    latitude: 47.1423,
    longitude: 7.791,
  },
  {
    zipCode: 4937,
    city: "Ursenbach",
    cantonShort: "BE",
    latitude: 47.13,
    longitude: 7.7644,
  },
  {
    zipCode: 4938,
    city: "Rohrbach",
    cantonShort: "BE",
    latitude: 47.1331,
    longitude: 7.811,
  },
  {
    zipCode: 4938,
    city: "Rohrbachgraben",
    cantonShort: "BE",
    latitude: 47.113,
    longitude: 7.803,
  },
  {
    zipCode: 4942,
    city: "Walterswil BE",
    cantonShort: "BE",
    latitude: 47.1064,
    longitude: 7.7747,
  },
  {
    zipCode: 4943,
    city: "Oeschenbach",
    cantonShort: "BE",
    latitude: 47.1105,
    longitude: 7.7463,
  },
  {
    zipCode: 4944,
    city: "Auswil",
    cantonShort: "BE",
    latitude: 47.1358,
    longitude: 7.8383,
  },
  {
    zipCode: 4950,
    city: "Huttwil",
    cantonShort: "BE",
    latitude: 47.1098,
    longitude: 7.8546,
  },
  {
    zipCode: 4952,
    city: "Eriswil",
    cantonShort: "BE",
    latitude: 47.0707,
    longitude: 7.8587,
  },
  {
    zipCode: 4953,
    city: "Schwarzenbach (Huttwil)",
    cantonShort: "BE",
    latitude: 47.1023,
    longitude: 7.826,
  },
  {
    zipCode: 4954,
    city: "Wyssachen",
    cantonShort: "BE",
    latitude: 47.0732,
    longitude: 7.8216,
  },
  {
    zipCode: 4955,
    city: "Gondiswil",
    cantonShort: "BE",
    latitude: 47.1434,
    longitude: 7.8683,
  },
  {
    zipCode: 3309,
    city: "Kernenried",
    cantonShort: "BE",
    latitude: 47.0663,
    longitude: 7.5498,
  },
  {
    zipCode: 3315,
    city: "Kr\u00e4iligen",
    cantonShort: "BE",
    latitude: 47.1506,
    longitude: 7.5358,
  },
  {
    zipCode: 3315,
    city: "B\u00e4tterkinden",
    cantonShort: "BE",
    latitude: 47.1283,
    longitude: 7.5262,
  },
  {
    zipCode: 3324,
    city: "M\u00f6tschwil",
    cantonShort: "BE",
    latitude: 47.0459,
    longitude: 7.569,
  },
  {
    zipCode: 3324,
    city: "Hindelbank",
    cantonShort: "BE",
    latitude: 47.0418,
    longitude: 7.5432,
  },
  {
    zipCode: 3325,
    city: "Hettiswil b. Hindelbank",
    cantonShort: "BE",
    latitude: 47.0267,
    longitude: 7.5569,
  },
  {
    zipCode: 3326,
    city: "Krauchthal",
    cantonShort: "BE",
    latitude: 47.0041,
    longitude: 7.5641,
  },
  {
    zipCode: 3400,
    city: "Burgdorf",
    cantonShort: "BE",
    latitude: 47.0561,
    longitude: 7.6184,
  },
  {
    zipCode: 3401,
    city: "Burgdorf",
    cantonShort: "BE",
    latitude: 47.059,
    longitude: 7.6279,
  },
  {
    zipCode: 3412,
    city: "Heimiswil",
    cantonShort: "BE",
    latitude: 47.0515,
    longitude: 7.6611,
  },
  {
    zipCode: 3413,
    city: "Kaltacker",
    cantonShort: "BE",
    latitude: 47.0751,
    longitude: 7.6744,
  },
  {
    zipCode: 3414,
    city: "Oberburg",
    cantonShort: "BE",
    latitude: 47.0202,
    longitude: 7.6085,
  },
  {
    zipCode: 3415,
    city: "Hasle-R\u00fcegsau",
    cantonShort: "BE",
    latitude: 46.9983,
    longitude: 7.6431,
  },
  {
    zipCode: 3415,
    city: "R\u00fcegsauschachen",
    cantonShort: "BE",
    latitude: 47.0225,
    longitude: 7.6568,
  },
  {
    zipCode: 3415,
    city: "Schafhausen im Emmental",
    cantonShort: "BE",
    latitude: 46.9912,
    longitude: 7.6463,
  },
  {
    zipCode: 3415,
    city: "Hasle b. Burgdorf",
    cantonShort: "BE",
    latitude: 47.0124,
    longitude: 7.6442,
  },
  {
    zipCode: 3416,
    city: "Affoltern im Emmental",
    cantonShort: "BE",
    latitude: 47.0688,
    longitude: 7.7255,
  },
  {
    zipCode: 3417,
    city: "R\u00fcegsau",
    cantonShort: "BE",
    latitude: 47.0275,
    longitude: 7.6773,
  },
  {
    zipCode: 3418,
    city: "R\u00fcegsbach",
    cantonShort: "BE",
    latitude: 47.0468,
    longitude: 7.6991,
  },
  {
    zipCode: 3419,
    city: "Biembach im Emmental",
    cantonShort: "BE",
    latitude: 46.9938,
    longitude: 7.6161,
  },
  {
    zipCode: 3421,
    city: "R\u00fcti b. Lyssach",
    cantonShort: "BE",
    latitude: 47.055,
    longitude: 7.5822,
  },
  {
    zipCode: 3421,
    city: "Lyssach",
    cantonShort: "BE",
    latitude: 47.0633,
    longitude: 7.5812,
  },
  {
    zipCode: 3421,
    city: "Lyssach",
    cantonShort: "BE",
    latitude: 47.0633,
    longitude: 7.5812,
  },
  {
    zipCode: 3422,
    city: "Kirchberg BE",
    cantonShort: "BE",
    latitude: 47.0846,
    longitude: 7.6015,
  },
  {
    zipCode: 3422,
    city: "R\u00fcdtligen",
    cantonShort: "BE",
    latitude: 47.0855,
    longitude: 7.5661,
  },
  {
    zipCode: 3422,
    city: "Alchenfl\u00fch",
    cantonShort: "BE",
    latitude: 47.0824,
    longitude: 7.5774,
  },
  {
    zipCode: 3423,
    city: "Ersigen",
    cantonShort: "BE",
    latitude: 47.0976,
    longitude: 7.6043,
  },
  {
    zipCode: 3424,
    city: "Ober\u00f6sch",
    cantonShort: "BE",
    latitude: 47.1071,
    longitude: 7.6106,
  },
  {
    zipCode: 3424,
    city: "Nieder\u00f6sch",
    cantonShort: "BE",
    latitude: 47.116,
    longitude: 7.6153,
  },
  {
    zipCode: 3425,
    city: "Koppigen",
    cantonShort: "BE",
    latitude: 47.1359,
    longitude: 7.6027,
  },
  {
    zipCode: 3425,
    city: "Willadingen",
    cantonShort: "BE",
    latitude: 47.147,
    longitude: 7.6149,
  },
  {
    zipCode: 3426,
    city: "Aefligen",
    cantonShort: "BE",
    latitude: 47.0966,
    longitude: 7.5495,
  },
  {
    zipCode: 3427,
    city: "Utzenstorf",
    cantonShort: "BE",
    latitude: 47.124,
    longitude: 7.5635,
  },
  {
    zipCode: 3428,
    city: "Wiler b. Utzenstorf",
    cantonShort: "BE",
    latitude: 47.1529,
    longitude: 7.5582,
  },
  {
    zipCode: 3429,
    city: "Hellsau",
    cantonShort: "BE",
    latitude: 47.1437,
    longitude: 7.6483,
  },
  {
    zipCode: 3429,
    city: "H\u00f6chstetten",
    cantonShort: "BE",
    latitude: 47.1437,
    longitude: 7.6334,
  },
  {
    zipCode: 3432,
    city: "L\u00fctzelfl\u00fch-Goldbach",
    cantonShort: "BE",
    latitude: 47.001,
    longitude: 7.6821,
  },
  {
    zipCode: 3433,
    city: "Schwanden im Emmental",
    cantonShort: "BE",
    latitude: 46.9779,
    longitude: 7.695,
  },
  {
    zipCode: 3435,
    city: "Ramsei",
    cantonShort: "BE",
    latitude: 46.9994,
    longitude: 7.7121,
  },
  {
    zipCode: 3436,
    city: "Zollbr\u00fcck",
    cantonShort: "BE",
    latitude: 46.9751,
    longitude: 7.7663,
  },
  {
    zipCode: 3437,
    city: "R\u00fcderswil",
    cantonShort: "BE",
    latitude: 46.9827,
    longitude: 7.7167,
  },
  {
    zipCode: 3438,
    city: "Lauperswil",
    cantonShort: "BE",
    latitude: 46.9603,
    longitude: 7.7268,
  },
  {
    zipCode: 3439,
    city: "Ranfl\u00fch",
    cantonShort: "BE",
    latitude: 46.9901,
    longitude: 7.7472,
  },
  {
    zipCode: 3452,
    city: "Gr\u00fcnenmatt",
    cantonShort: "BE",
    latitude: 47.0149,
    longitude: 7.7247,
  },
  {
    zipCode: 3453,
    city: "Heimisbach",
    cantonShort: "BE",
    latitude: 47.0091,
    longitude: 7.7838,
  },
  {
    zipCode: 3454,
    city: "Sumiswald",
    cantonShort: "BE",
    latitude: 47.0376,
    longitude: 7.7502,
  },
  {
    zipCode: 3455,
    city: "Gr\u00fcnen",
    cantonShort: "BE",
    latitude: 47.0222,
    longitude: 7.7391,
  },
  {
    zipCode: 3456,
    city: "Trachselwald",
    cantonShort: "BE",
    latitude: 47.0154,
    longitude: 7.7448,
  },
  {
    zipCode: 3457,
    city: "Wasen im Emmental",
    cantonShort: "BE",
    latitude: 47.029,
    longitude: 7.8386,
  },
  {
    zipCode: 3462,
    city: "Weier im Emmental",
    cantonShort: "BE",
    latitude: 47.0601,
    longitude: 7.7616,
  },
  {
    zipCode: 3463,
    city: "H\u00e4usernmoos im Emmental",
    cantonShort: "BE",
    latitude: 47.0806,
    longitude: 7.7527,
  },
  {
    zipCode: 3465,
    city: "D\u00fcrrenroth",
    cantonShort: "BE",
    latitude: 47.0796,
    longitude: 7.7867,
  },
  {
    zipCode: 3472,
    city: "Wynigen",
    cantonShort: "BE",
    latitude: 47.0991,
    longitude: 7.6819,
  },
  {
    zipCode: 3472,
    city: "Rumendingen",
    cantonShort: "BE",
    latitude: 47.1054,
    longitude: 7.6432,
  },
  {
    zipCode: 3473,
    city: "Alchenstorf",
    cantonShort: "BE",
    latitude: 47.1245,
    longitude: 7.6437,
  },
  {
    zipCode: 3474,
    city: "R\u00fcedisbach",
    cantonShort: "BE",
    latitude: 47.1184,
    longitude: 7.7028,
  },
  {
    zipCode: 3534,
    city: "Signau",
    cantonShort: "BE",
    latitude: 46.9089,
    longitude: 7.7339,
  },
  {
    zipCode: 3535,
    city: "Sch\u00fcpbach",
    cantonShort: "BE",
    latitude: 46.9292,
    longitude: 7.7483,
  },
  {
    zipCode: 3536,
    city: "Aeschau",
    cantonShort: "BE",
    latitude: 46.9041,
    longitude: 7.7853,
  },
  {
    zipCode: 3537,
    city: "Eggiwil",
    cantonShort: "BE",
    latitude: 46.8613,
    longitude: 7.812,
  },
  {
    zipCode: 3538,
    city: "R\u00f6thenbach im Emmental",
    cantonShort: "BE",
    latitude: 46.8574,
    longitude: 7.7413,
  },
  {
    zipCode: 3543,
    city: "Emmenmatt",
    cantonShort: "BE",
    latitude: 46.9444,
    longitude: 7.7269,
  },
  {
    zipCode: 3550,
    city: "Langnau im Emmental",
    cantonShort: "BE",
    latitude: 46.9333,
    longitude: 7.7854,
  },
  {
    zipCode: 3551,
    city: "Oberfrittenbach",
    cantonShort: "BE",
    latitude: 46.9744,
    longitude: 7.7959,
  },
  {
    zipCode: 3552,
    city: "B\u00e4rau",
    cantonShort: "BE",
    latitude: 46.94,
    longitude: 7.8265,
  },
  {
    zipCode: 3553,
    city: "Gohl",
    cantonShort: "BE",
    latitude: 46.9762,
    longitude: 7.826,
  },
  {
    zipCode: 3555,
    city: "Trubschachen",
    cantonShort: "BE",
    latitude: 46.9101,
    longitude: 7.8523,
  },
  {
    zipCode: 3556,
    city: "Trub",
    cantonShort: "BE",
    latitude: 46.9648,
    longitude: 7.8769,
  },
  {
    zipCode: 3557,
    city: "Fankhaus (Trub)",
    cantonShort: "BE",
    latitude: 46.9824,
    longitude: 7.9244,
  },
  {
    zipCode: 3618,
    city: "S\u00fcderen",
    cantonShort: "BE",
    latitude: 46.8198,
    longitude: 7.7713,
  },
  {
    zipCode: 4564,
    city: "Zielebach",
    cantonShort: "BE",
    latitude: 47.158,
    longitude: 7.573,
  },
  {
    zipCode: 6197,
    city: "Schangnau",
    cantonShort: "BE",
    latitude: 46.8106,
    longitude: 7.8871,
  },
  {
    zipCode: 1595,
    city: "Clavaleyres",
    cantonShort: "BE",
    latitude: 46.8976,
    longitude: 7.0917,
  },
  {
    zipCode: 1738,
    city: "Sangernboden",
    cantonShort: "BE",
    latitude: 46.6993,
    longitude: 7.371,
  },
  {
    zipCode: 1797,
    city: "M\u00fcnchenwiler",
    cantonShort: "BE",
    latitude: 46.9111,
    longitude: 7.1255,
  },
  {
    zipCode: 3000,
    city: "Bern 22",
    cantonShort: "BE",
    latitude: 46.9476,
    longitude: 7.4065,
  },
  {
    zipCode: 3000,
    city: "Bern 5",
    cantonShort: "BE",
    latitude: 46.9476,
    longitude: 7.4065,
  },
  {
    zipCode: 3000,
    city: "Bern 13",
    cantonShort: "BE",
    latitude: 46.9476,
    longitude: 7.4065,
  },
  {
    zipCode: 3000,
    city: "Bern 31",
    cantonShort: "BE",
    latitude: 46.9476,
    longitude: 7.4065,
  },
  {
    zipCode: 3000,
    city: "Bern 8",
    cantonShort: "BE",
    latitude: 46.9476,
    longitude: 7.4065,
  },
  {
    zipCode: 3000,
    city: "Bern 65",
    cantonShort: "BE",
    latitude: 46.9476,
    longitude: 7.4065,
  },
  {
    zipCode: 3000,
    city: "Bern 94",
    cantonShort: "BE",
    latitude: 46.9476,
    longitude: 7.4065,
  },
  {
    zipCode: 3000,
    city: "Bern 6",
    cantonShort: "BE",
    latitude: 46.9476,
    longitude: 7.4065,
  },
  {
    zipCode: 3000,
    city: "Bern 16",
    cantonShort: "BE",
    latitude: 46.9476,
    longitude: 7.4065,
  },
  {
    zipCode: 3000,
    city: "Bern 15",
    cantonShort: "BE",
    latitude: 46.9476,
    longitude: 7.4065,
  },
  {
    zipCode: 3000,
    city: "Bern 9",
    cantonShort: "BE",
    latitude: 46.9476,
    longitude: 7.4065,
  },
  {
    zipCode: 3000,
    city: "Bern",
    cantonShort: "BE",
    latitude: 46.9481,
    longitude: 7.4474,
  },
  {
    zipCode: 3000,
    city: "Bern 60 UPD",
    cantonShort: "BE",
    latitude: 46.9476,
    longitude: 7.4065,
  },
  {
    zipCode: 3000,
    city: "Bern 14",
    cantonShort: "BE",
    latitude: 46.9476,
    longitude: 7.4065,
  },
  {
    zipCode: 3000,
    city: "Bern 65 SBB",
    cantonShort: "BE",
    latitude: 46.9476,
    longitude: 7.4065,
  },
  {
    zipCode: 3001,
    city: "Bern",
    cantonShort: "BE",
    latitude: 46.9481,
    longitude: 7.4474,
  },
  {
    zipCode: 3002,
    city: "Bern PostFinance",
    cantonShort: "BE",
    latitude: 46.9476,
    longitude: 7.4065,
  },
  {
    zipCode: 3003,
    city: "Bern",
    cantonShort: "BE",
    latitude: 46.9481,
    longitude: 7.4474,
  },
  {
    zipCode: 3004,
    city: "Bern",
    cantonShort: "BE",
    latitude: 46.9753,
    longitude: 7.45,
  },
  {
    zipCode: 3005,
    city: "Bern",
    cantonShort: "BE",
    latitude: 46.9398,
    longitude: 7.4502,
  },
  {
    zipCode: 3006,
    city: "Bern",
    cantonShort: "BE",
    latitude: 46.9462,
    longitude: 7.4707,
  },
  {
    zipCode: 3007,
    city: "Bern",
    cantonShort: "BE",
    latitude: 46.9385,
    longitude: 7.4339,
  },
  {
    zipCode: 3008,
    city: "Bern",
    cantonShort: "BE",
    latitude: 46.944,
    longitude: 7.4156,
  },
  {
    zipCode: 3010,
    city: "Bern",
    cantonShort: "BE",
    latitude: 46.947,
    longitude: 7.4237,
  },
  {
    zipCode: 3011,
    city: "Bern",
    cantonShort: "BE",
    latitude: 46.9477,
    longitude: 7.4458,
  },
  {
    zipCode: 3011,
    city: "Bern",
    cantonShort: "BE",
    latitude: 46.9477,
    longitude: 7.4458,
  },
  {
    zipCode: 3012,
    city: "Bern",
    cantonShort: "BE",
    latitude: 46.9608,
    longitude: 7.4262,
  },
  {
    zipCode: 3013,
    city: "Bern",
    cantonShort: "BE",
    latitude: 46.9547,
    longitude: 7.4507,
  },
  {
    zipCode: 3014,
    city: "Bern",
    cantonShort: "BE",
    latitude: 46.9635,
    longitude: 7.4591,
  },
  {
    zipCode: 3015,
    city: "Bern",
    cantonShort: "BE",
    latitude: 46.9397,
    longitude: 7.4841,
  },
  {
    zipCode: 3018,
    city: "Bern",
    cantonShort: "BE",
    latitude: 46.9364,
    longitude: 7.3906,
  },
  {
    zipCode: 3019,
    city: "Bern",
    cantonShort: "BE",
    latitude: 46.932,
    longitude: 7.348,
  },
  {
    zipCode: 3020,
    city: "Bern",
    cantonShort: "BE",
    latitude: 46.9369,
    longitude: 7.3243,
  },
  {
    zipCode: 3024,
    city: "Bern",
    cantonShort: "BE",
    latitude: 46.9481,
    longitude: 7.4474,
  },
  {
    zipCode: 3027,
    city: "Bern",
    cantonShort: "BE",
    latitude: 46.9528,
    longitude: 7.3844,
  },
  {
    zipCode: 3029,
    city: "Bern",
    cantonShort: "BE",
    latitude: 46.9481,
    longitude: 7.4474,
  },
  {
    zipCode: 3030,
    city: "Bern",
    cantonShort: "BE",
    latitude: 46.9481,
    longitude: 7.4474,
  },
  {
    zipCode: 3032,
    city: "Hinterkappelen",
    cantonShort: "BE",
    latitude: 46.9673,
    longitude: 7.3816,
  },
  {
    zipCode: 3033,
    city: "Wohlen b. Bern",
    cantonShort: "BE",
    latitude: 46.974,
    longitude: 7.3512,
  },
  {
    zipCode: 3034,
    city: "Murzelen",
    cantonShort: "BE",
    latitude: 46.9765,
    longitude: 7.3104,
  },
  {
    zipCode: 3037,
    city: "Herrenschwanden",
    cantonShort: "BE",
    latitude: 46.9761,
    longitude: 7.4179,
  },
  {
    zipCode: 3038,
    city: "Kirchlindach",
    cantonShort: "BE",
    latitude: 47.0016,
    longitude: 7.4155,
  },
  {
    zipCode: 3039,
    city: "Bern PF Operations Center",
    cantonShort: "BE",
    latitude: 46.9476,
    longitude: 7.4065,
  },
  {
    zipCode: 3039,
    city: "Bern PF OC",
    cantonShort: "BE",
    latitude: 46.9476,
    longitude: 7.4065,
  },
  {
    zipCode: 3040,
    city: "Bern Verarbeitungszentrum",
    cantonShort: "BE",
    latitude: 46.9476,
    longitude: 7.4065,
  },
  {
    zipCode: 3040,
    city: "Bern Verarb.zentr.",
    cantonShort: "BE",
    latitude: 46.9476,
    longitude: 7.4065,
  },
  {
    zipCode: 3041,
    city: "Bern UBS",
    cantonShort: "BE",
    latitude: 46.9476,
    longitude: 7.4065,
  },
  {
    zipCode: 3042,
    city: "Ortschwaben",
    cantonShort: "BE",
    latitude: 46.9923,
    longitude: 7.3992,
  },
  {
    zipCode: 3043,
    city: "Uettligen",
    cantonShort: "BE",
    latitude: 46.9864,
    longitude: 7.3809,
  },
  {
    zipCode: 3044,
    city: "Innerberg",
    cantonShort: "BE",
    latitude: 46.9959,
    longitude: 7.3122,
  },
  {
    zipCode: 3045,
    city: "Meikirch",
    cantonShort: "BE",
    latitude: 47.0093,
    longitude: 7.3723,
  },
  {
    zipCode: 3046,
    city: "Wahlendorf",
    cantonShort: "BE",
    latitude: 47.0081,
    longitude: 7.3342,
  },
  {
    zipCode: 3047,
    city: "Bremgarten b. Bern",
    cantonShort: "BE",
    latitude: 46.9781,
    longitude: 7.4355,
  },
  {
    zipCode: 3048,
    city: "Worblaufen",
    cantonShort: "BE",
    latitude: 46.9796,
    longitude: 7.4643,
  },
  {
    zipCode: 3049,
    city: "S\u00e4riswil",
    cantonShort: "BE",
    latitude: 46.9935,
    longitude: 7.3363,
  },
  {
    zipCode: 3050,
    city: "Bern Swisscom",
    cantonShort: "BE",
    latitude: 46.9476,
    longitude: 7.4065,
  },
  {
    zipCode: 3052,
    city: "Zollikofen",
    cantonShort: "BE",
    latitude: 46.9959,
    longitude: 7.4548,
  },
  {
    zipCode: 3053,
    city: "M\u00fcnchenbuchsee",
    cantonShort: "BE",
    latitude: 47.0208,
    longitude: 7.445,
  },
  {
    zipCode: 3053,
    city: "Deisswil b. M\u00fcnchenbuchsee",
    cantonShort: "BE",
    latitude: 47.0372,
    longitude: 7.4533,
  },
  {
    zipCode: 3053,
    city: "Wiggiswil",
    cantonShort: "BE",
    latitude: 47.0313,
    longitude: 7.4711,
  },
  {
    zipCode: 3053,
    city: "Diemerswil",
    cantonShort: "BE",
    latitude: 47.0201,
    longitude: 7.4205,
  },
  {
    zipCode: 3063,
    city: "Ittigen",
    cantonShort: "BE",
    latitude: 46.9803,
    longitude: 7.4782,
  },
  {
    zipCode: 3065,
    city: "Bolligen",
    cantonShort: "BE",
    latitude: 46.9858,
    longitude: 7.5077,
  },
  {
    zipCode: 3066,
    city: "Stettlen",
    cantonShort: "BE",
    latitude: 46.9634,
    longitude: 7.5286,
  },
  {
    zipCode: 3067,
    city: "Boll",
    cantonShort: "BE",
    latitude: 46.9613,
    longitude: 7.5519,
  },
  {
    zipCode: 3068,
    city: "Utzigen",
    cantonShort: "BE",
    latitude: 46.9667,
    longitude: 7.5823,
  },
  {
    zipCode: 3071,
    city: "Ostermundigen KATA",
    cantonShort: "BE",
    latitude: 46.9559,
    longitude: 7.4956,
  },
  {
    zipCode: 3072,
    city: "Ostermundigen 1",
    cantonShort: "BE",
    latitude: 46.9559,
    longitude: 7.4956,
  },
  {
    zipCode: 3072,
    city: "Ostermundigen",
    cantonShort: "BE",
    latitude: 46.9564,
    longitude: 7.4939,
  },
  {
    zipCode: 3073,
    city: "G\u00fcmligen",
    cantonShort: "BE",
    latitude: 46.9356,
    longitude: 7.5081,
  },
  {
    zipCode: 3074,
    city: "Muri b. Bern",
    cantonShort: "BE",
    latitude: 46.928,
    longitude: 7.4885,
  },
  {
    zipCode: 3075,
    city: "R\u00fcfenacht BE",
    cantonShort: "BE",
    latitude: 46.928,
    longitude: 7.5368,
  },
  {
    zipCode: 3075,
    city: "Vielbringen b. Worb",
    cantonShort: "BE",
    latitude: 46.9169,
    longitude: 7.543,
  },
  {
    zipCode: 3076,
    city: "Worb",
    cantonShort: "BE",
    latitude: 46.9336,
    longitude: 7.5618,
  },
  {
    zipCode: 3077,
    city: "Enggistein",
    cantonShort: "BE",
    latitude: 46.9296,
    longitude: 7.6004,
  },
  {
    zipCode: 3078,
    city: "Richigen",
    cantonShort: "BE",
    latitude: 46.9171,
    longitude: 7.585,
  },
  {
    zipCode: 3082,
    city: "Schlosswil",
    cantonShort: "BE",
    latitude: 46.9113,
    longitude: 7.6112,
  },
  {
    zipCode: 3083,
    city: "Trimstein",
    cantonShort: "BE",
    latitude: 46.9029,
    longitude: 7.5769,
  },
  {
    zipCode: 3084,
    city: "Wabern",
    cantonShort: "BE",
    latitude: 46.923,
    longitude: 7.453,
  },
  {
    zipCode: 3085,
    city: "Wabern Weihnachten",
    cantonShort: "BE",
    latitude: 46.9043,
    longitude: 7.3978,
  },
  {
    zipCode: 3085,
    city: "Wabern 2 x Weihnachten",
    cantonShort: "BE",
    latitude: 46.9043,
    longitude: 7.3978,
  },
  {
    zipCode: 3086,
    city: "Zimmerwald",
    cantonShort: "BE",
    latitude: 46.8801,
    longitude: 7.4616,
  },
  {
    zipCode: 3086,
    city: "Englisberg",
    cantonShort: "BE",
    latitude: 46.8955,
    longitude: 7.4635,
  },
  {
    zipCode: 3087,
    city: "Niedermuhlern",
    cantonShort: "BE",
    latitude: 46.8587,
    longitude: 7.4579,
  },
  {
    zipCode: 3088,
    city: "R\u00fceggisberg",
    cantonShort: "BE",
    latitude: 46.8241,
    longitude: 7.4383,
  },
  {
    zipCode: 3088,
    city: "Oberb\u00fctschel",
    cantonShort: "BE",
    latitude: 46.8409,
    longitude: 7.4534,
  },
  {
    zipCode: 3089,
    city: "Hinterfultigen",
    cantonShort: "BE",
    latitude: 46.8391,
    longitude: 7.4052,
  },
  {
    zipCode: 3095,
    city: "Spiegel b. Bern",
    cantonShort: "BE",
    latitude: 46.9247,
    longitude: 7.4342,
  },
  {
    zipCode: 3096,
    city: "Oberbalm",
    cantonShort: "BE",
    latitude: 46.8655,
    longitude: 7.4093,
  },
  {
    zipCode: 3097,
    city: "Liebefeld",
    cantonShort: "BE",
    latitude: 46.9304,
    longitude: 7.4192,
  },
  {
    zipCode: 3098,
    city: "Schliern b. K\u00f6niz",
    cantonShort: "BE",
    latitude: 46.907,
    longitude: 7.4219,
  },
  {
    zipCode: 3098,
    city: "K\u00f6niz",
    cantonShort: "BE",
    latitude: 46.916,
    longitude: 7.4181,
  },
  {
    zipCode: 3099,
    city: "R\u00fcti b. Riggisberg",
    cantonShort: "BE",
    latitude: 46.7631,
    longitude: 7.4568,
  },
  {
    zipCode: 3110,
    city: "M\u00fcnsingen",
    cantonShort: "BE",
    latitude: 46.8761,
    longitude: 7.5607,
  },
  {
    zipCode: 3111,
    city: "T\u00e4gertschi",
    cantonShort: "BE",
    latitude: 46.8726,
    longitude: 7.5869,
  },
  {
    zipCode: 3112,
    city: "Allmendingen b. Bern",
    cantonShort: "BE",
    latitude: 46.9167,
    longitude: 7.5211,
  },
  {
    zipCode: 3113,
    city: "Rubigen",
    cantonShort: "BE",
    latitude: 46.8997,
    longitude: 7.545,
  },
  {
    zipCode: 3114,
    city: "Wichtrach",
    cantonShort: "BE",
    latitude: 46.8451,
    longitude: 7.5789,
  },
  {
    zipCode: 3115,
    city: "Gerzensee",
    cantonShort: "BE",
    latitude: 46.8463,
    longitude: 7.5418,
  },
  {
    zipCode: 3116,
    city: "Noflen BE",
    cantonShort: "BE",
    latitude: 46.8027,
    longitude: 7.5449,
  },
  {
    zipCode: 3116,
    city: "Kirchdorf BE",
    cantonShort: "BE",
    latitude: 46.8172,
    longitude: 7.5453,
  },
  {
    zipCode: 3116,
    city: "M\u00fchledorf BE",
    cantonShort: "BE",
    latitude: 46.8275,
    longitude: 7.5303,
  },
  {
    zipCode: 3122,
    city: "Kehrsatz",
    cantonShort: "BE",
    latitude: 46.9114,
    longitude: 7.472,
  },
  {
    zipCode: 3123,
    city: "Belp",
    cantonShort: "BE",
    latitude: 46.8933,
    longitude: 7.5077,
  },
  {
    zipCode: 3124,
    city: "Belpberg",
    cantonShort: "BE",
    latitude: 46.8669,
    longitude: 7.5211,
  },
  {
    zipCode: 3125,
    city: "Toffen",
    cantonShort: "BE",
    latitude: 46.8593,
    longitude: 7.4935,
  },
  {
    zipCode: 3126,
    city: "Kaufdorf",
    cantonShort: "BE",
    latitude: 46.8397,
    longitude: 7.4965,
  },
  {
    zipCode: 3126,
    city: "Gelterfingen",
    cantonShort: "BE",
    latitude: 46.8436,
    longitude: 7.516,
  },
  {
    zipCode: 3127,
    city: "Lohnstorf",
    cantonShort: "BE",
    latitude: 46.8066,
    longitude: 7.5131,
  },
  {
    zipCode: 3127,
    city: "M\u00fchlethurnen",
    cantonShort: "BE",
    latitude: 46.8159,
    longitude: 7.5103,
  },
  {
    zipCode: 3128,
    city: "Kirchenthurnen",
    cantonShort: "BE",
    latitude: 46.8243,
    longitude: 7.5058,
  },
  {
    zipCode: 3128,
    city: "R\u00fcmligen",
    cantonShort: "BE",
    latitude: 46.8287,
    longitude: 7.4934,
  },
  {
    zipCode: 3132,
    city: "Riggisberg",
    cantonShort: "BE",
    latitude: 46.8098,
    longitude: 7.4675,
  },
  {
    zipCode: 3144,
    city: "Gasel",
    cantonShort: "BE",
    latitude: 46.8971,
    longitude: 7.4099,
  },
  {
    zipCode: 3145,
    city: "Niederscherli",
    cantonShort: "BE",
    latitude: 46.8857,
    longitude: 7.394,
  },
  {
    zipCode: 3147,
    city: "Mittelh\u00e4usern",
    cantonShort: "BE",
    latitude: 46.8724,
    longitude: 7.3699,
  },
  {
    zipCode: 3148,
    city: "Lanzenh\u00e4usern",
    cantonShort: "BE",
    latitude: 46.8466,
    longitude: 7.3629,
  },
  {
    zipCode: 3150,
    city: "Schwarzenburg",
    cantonShort: "BE",
    latitude: 46.811,
    longitude: 7.3335,
  },
  {
    zipCode: 3152,
    city: "Mamishaus",
    cantonShort: "BE",
    latitude: 46.8152,
    longitude: 7.3801,
  },
  {
    zipCode: 3153,
    city: "R\u00fcschegg Gambach",
    cantonShort: "BE",
    latitude: 46.7726,
    longitude: 7.3774,
  },
  {
    zipCode: 3154,
    city: "R\u00fcschegg Heubach",
    cantonShort: "BE",
    latitude: 46.7682,
    longitude: 7.4174,
  },
  {
    zipCode: 3155,
    city: "Helgisried-Rohrbach",
    cantonShort: "BE",
    latitude: 46.8109,
    longitude: 7.4184,
  },
  {
    zipCode: 3156,
    city: "Riffenmatt",
    cantonShort: "BE",
    latitude: 46.7507,
    longitude: 7.3621,
  },
  {
    zipCode: 3157,
    city: "Milken",
    cantonShort: "BE",
    latitude: 46.794,
    longitude: 7.3617,
  },
  {
    zipCode: 3158,
    city: "Guggisberg",
    cantonShort: "BE",
    latitude: 46.7591,
    longitude: 7.3182,
  },
  {
    zipCode: 3159,
    city: "Riedst\u00e4tt",
    cantonShort: "BE",
    latitude: 46.7841,
    longitude: 7.3218,
  },
  {
    zipCode: 3172,
    city: "Niederwangen b. Bern",
    cantonShort: "BE",
    latitude: 46.9213,
    longitude: 7.3786,
  },
  {
    zipCode: 3173,
    city: "Oberwangen b. Bern",
    cantonShort: "BE",
    latitude: 46.9136,
    longitude: 7.3568,
  },
  {
    zipCode: 3174,
    city: "Th\u00f6rishaus",
    cantonShort: "BE",
    latitude: 46.8967,
    longitude: 7.35,
  },
  {
    zipCode: 3176,
    city: "Neuenegg",
    cantonShort: "BE",
    latitude: 46.9065,
    longitude: 7.2997,
  },
  {
    zipCode: 3177,
    city: "Laupen BE",
    cantonShort: "BE",
    latitude: 46.9105,
    longitude: 7.2433,
  },
  {
    zipCode: 3179,
    city: "Kriechenwil",
    cantonShort: "BE",
    latitude: 46.9112,
    longitude: 7.2159,
  },
  {
    zipCode: 3183,
    city: "Albligen",
    cantonShort: "BE",
    latitude: 46.8486,
    longitude: 7.3229,
  },
  {
    zipCode: 3202,
    city: "Frauenkappelen",
    cantonShort: "BE",
    latitude: 46.9556,
    longitude: 7.3319,
  },
  {
    zipCode: 3203,
    city: "M\u00fchleberg",
    cantonShort: "BE",
    latitude: 46.9618,
    longitude: 7.2718,
  },
  {
    zipCode: 3204,
    city: "Rossh\u00e4usern",
    cantonShort: "BE",
    latitude: 46.9313,
    longitude: 7.2759,
  },
  {
    zipCode: 3205,
    city: "G\u00fcmmenen",
    cantonShort: "BE",
    latitude: 46.9431,
    longitude: 7.2526,
  },
  {
    zipCode: 3206,
    city: "Gammen",
    cantonShort: "BE",
    latitude: 46.9215,
    longitude: 7.2264,
  },
  {
    zipCode: 3206,
    city: "Biberen",
    cantonShort: "BE",
    latitude: 46.9375,
    longitude: 7.2128,
  },
  {
    zipCode: 3206,
    city: "Rizenbach",
    cantonShort: "BE",
    latitude: 46.9489,
    longitude: 7.2259,
  },
  {
    zipCode: 3206,
    city: "Ferenbalm",
    cantonShort: "BE",
    latitude: 46.9551,
    longitude: 7.2148,
  },
  {
    zipCode: 3207,
    city: "Wileroltigen",
    cantonShort: "BE",
    latitude: 46.9709,
    longitude: 7.238,
  },
  {
    zipCode: 3207,
    city: "Golaten",
    cantonShort: "BE",
    latitude: 46.9867,
    longitude: 7.2393,
  },
  {
    zipCode: 3208,
    city: "Gurbr\u00fc",
    cantonShort: "BE",
    latitude: 46.9654,
    longitude: 7.2145,
  },
  {
    zipCode: 3302,
    city: "Moosseedorf",
    cantonShort: "BE",
    latitude: 47.0101,
    longitude: 7.4845,
  },
  {
    zipCode: 3303,
    city: "Jegenstorf",
    cantonShort: "BE",
    latitude: 47.0522,
    longitude: 7.5036,
  },
  {
    zipCode: 3303,
    city: "M\u00fcnchringen",
    cantonShort: "BE",
    latitude: 47.0484,
    longitude: 7.5249,
  },
  {
    zipCode: 3303,
    city: "Ballmoos",
    cantonShort: "BE",
    latitude: 47.0409,
    longitude: 7.4753,
  },
  {
    zipCode: 3303,
    city: "Zuzwil BE",
    cantonShort: "BE",
    latitude: 47.0498,
    longitude: 7.4673,
  },
  {
    zipCode: 3305,
    city: "Scheunen",
    cantonShort: "BE",
    latitude: 47.0705,
    longitude: 7.4549,
  },
  {
    zipCode: 3305,
    city: "Iffwil",
    cantonShort: "BE",
    latitude: 47.0651,
    longitude: 7.4771,
  },
  {
    zipCode: 3306,
    city: "Etzelkofen",
    cantonShort: "BE",
    latitude: 47.0835,
    longitude: 7.4806,
  },
  {
    zipCode: 3308,
    city: "Grafenried",
    cantonShort: "BE",
    latitude: 47.0784,
    longitude: 7.5055,
  },
  {
    zipCode: 3309,
    city: "Zauggenried",
    cantonShort: "BE",
    latitude: 47.0709,
    longitude: 7.5322,
  },
  {
    zipCode: 3312,
    city: "Fraubrunnen",
    cantonShort: "BE",
    latitude: 47.0879,
    longitude: 7.5386,
  },
  {
    zipCode: 3313,
    city: "B\u00fcren zum Hof",
    cantonShort: "BE",
    latitude: 47.0972,
    longitude: 7.5116,
  },
  {
    zipCode: 3314,
    city: "Schalunen",
    cantonShort: "BE",
    latitude: 47.1102,
    longitude: 7.5257,
  },
  {
    zipCode: 3317,
    city: "Limpach",
    cantonShort: "BE",
    latitude: 47.1102,
    longitude: 7.4987,
  },
  {
    zipCode: 3317,
    city: "M\u00fclchi",
    cantonShort: "BE",
    latitude: 47.1013,
    longitude: 7.4761,
  },
  {
    zipCode: 3321,
    city: "Sch\u00f6nb\u00fchl EKZ",
    cantonShort: "BE",
    latitude: 47.0168,
    longitude: 7.4839,
  },
  {
    zipCode: 3321,
    city: "Sch\u00f6nb\u00fchl Einkaufszentrum",
    cantonShort: "BE",
    latitude: 47.0168,
    longitude: 7.4839,
  },
  {
    zipCode: 3322,
    city: "Mattstetten",
    cantonShort: "BE",
    latitude: 47.0232,
    longitude: 7.5205,
  },
  {
    zipCode: 3322,
    city: "Urtenen-Sch\u00f6nb\u00fchl",
    cantonShort: "BE",
    latitude: 47.0216,
    longitude: 7.4996,
  },
  {
    zipCode: 3323,
    city: "B\u00e4riswil BE",
    cantonShort: "BE",
    latitude: 47.0169,
    longitude: 7.532,
  },
  {
    zipCode: 3434,
    city: "Landiswil",
    cantonShort: "BE",
    latitude: 46.9541,
    longitude: 7.6886,
  },
  {
    zipCode: 3434,
    city: "Obergoldbach",
    cantonShort: "BE",
    latitude: 46.9585,
    longitude: 7.664,
  },
  {
    zipCode: 3503,
    city: "Gysenstein",
    cantonShort: "BE",
    latitude: 46.8935,
    longitude: 7.5955,
  },
  {
    zipCode: 3504,
    city: "Oberh\u00fcnigen",
    cantonShort: "BE",
    latitude: 46.8733,
    longitude: 7.6693,
  },
  {
    zipCode: 3504,
    city: "Niederh\u00fcnigen",
    cantonShort: "BE",
    latitude: 46.8711,
    longitude: 7.6465,
  },
  {
    zipCode: 3506,
    city: "Grossh\u00f6chstetten",
    cantonShort: "BE",
    latitude: 46.9068,
    longitude: 7.6354,
  },
  {
    zipCode: 3507,
    city: "Biglen",
    cantonShort: "BE",
    latitude: 46.9286,
    longitude: 7.6288,
  },
  {
    zipCode: 3508,
    city: "Arni BE",
    cantonShort: "BE",
    latitude: 46.9349,
    longitude: 7.6601,
  },
  {
    zipCode: 3510,
    city: "Konolfingen",
    cantonShort: "BE",
    latitude: 46.8813,
    longitude: 7.6179,
  },
  {
    zipCode: 3510,
    city: "Freimettigen",
    cantonShort: "BE",
    latitude: 46.8634,
    longitude: 7.6325,
  },
  {
    zipCode: 3510,
    city: "H\u00e4utligen",
    cantonShort: "BE",
    latitude: 46.8576,
    longitude: 7.6047,
  },
  {
    zipCode: 3512,
    city: "Walkringen",
    cantonShort: "BE",
    latitude: 46.9491,
    longitude: 7.6199,
  },
  {
    zipCode: 3513,
    city: "Bigenthal",
    cantonShort: "BE",
    latitude: 46.9721,
    longitude: 7.6345,
  },
  {
    zipCode: 3531,
    city: "Oberthal",
    cantonShort: "BE",
    latitude: 46.9187,
    longitude: 7.6835,
  },
  {
    zipCode: 3532,
    city: "Mirchel",
    cantonShort: "BE",
    latitude: 46.8915,
    longitude: 7.6503,
  },
  {
    zipCode: 3532,
    city: "Z\u00e4ziwil",
    cantonShort: "BE",
    latitude: 46.8956,
    longitude: 7.6676,
  },
  {
    zipCode: 3533,
    city: "Bowil",
    cantonShort: "BE",
    latitude: 46.8891,
    longitude: 7.7009,
  },
  {
    zipCode: 3629,
    city: "Kiesen",
    cantonShort: "BE",
    latitude: 46.8124,
    longitude: 7.5794,
  },
  {
    zipCode: 3629,
    city: "Oppligen",
    cantonShort: "BE",
    latitude: 46.8172,
    longitude: 7.5966,
  },
  {
    zipCode: 3629,
    city: "Jaberg",
    cantonShort: "BE",
    latitude: 46.8202,
    longitude: 7.5657,
  },
  {
    zipCode: 3671,
    city: "Herbligen",
    cantonShort: "BE",
    latitude: 46.8271,
    longitude: 7.611,
  },
  {
    zipCode: 3671,
    city: "Brenzikofen",
    cantonShort: "BE",
    latitude: 46.8148,
    longitude: 7.6165,
  },
  {
    zipCode: 3672,
    city: "Oberdiessbach",
    cantonShort: "BE",
    latitude: 46.8426,
    longitude: 7.6273,
  },
  {
    zipCode: 3672,
    city: "Aeschlen b. Oberdiessbach",
    cantonShort: "BE",
    latitude: 46.8329,
    longitude: 7.652,
  },
  {
    zipCode: 3673,
    city: "Linden",
    cantonShort: "BE",
    latitude: 46.8497,
    longitude: 7.6867,
  },
  {
    zipCode: 3674,
    city: "Bleiken b. Oberdiessbach",
    cantonShort: "BE",
    latitude: 46.8168,
    longitude: 7.6401,
  },
  {
    zipCode: 3600,
    city: "Thun",
    cantonShort: "BE",
    latitude: 46.7554,
    longitude: 7.6234,
  },
  {
    zipCode: 3602,
    city: "Thun",
    cantonShort: "BE",
    latitude: 46.7512,
    longitude: 7.6217,
  },
  {
    zipCode: 3603,
    city: "Thun",
    cantonShort: "BE",
    latitude: 46.7624,
    longitude: 7.5991,
  },
  {
    zipCode: 3604,
    city: "Thun",
    cantonShort: "BE",
    latitude: 46.7403,
    longitude: 7.6201,
  },
  {
    zipCode: 3607,
    city: "Thun",
    cantonShort: "BE",
    latitude: 46.7512,
    longitude: 7.6217,
  },
  {
    zipCode: 3608,
    city: "Thun",
    cantonShort: "BE",
    latitude: 46.7387,
    longitude: 7.5974,
  },
  {
    zipCode: 3609,
    city: "Thun",
    cantonShort: "BE",
    latitude: 46.7512,
    longitude: 7.6217,
  },
  {
    zipCode: 3612,
    city: "Steffisburg",
    cantonShort: "BE",
    latitude: 46.7825,
    longitude: 7.6336,
  },
  {
    zipCode: 3613,
    city: "Steffisburg",
    cantonShort: "BE",
    latitude: 46.7713,
    longitude: 7.6157,
  },
  {
    zipCode: 3614,
    city: "Unterlangenegg",
    cantonShort: "BE",
    latitude: 46.7989,
    longitude: 7.6936,
  },
  {
    zipCode: 3615,
    city: "Heimenschwand",
    cantonShort: "BE",
    latitude: 46.821,
    longitude: 7.6913,
  },
  {
    zipCode: 3616,
    city: "Schwarzenegg",
    cantonShort: "BE",
    latitude: 46.8,
    longitude: 7.7343,
  },
  {
    zipCode: 3617,
    city: "Fahrni b. Thun",
    cantonShort: "BE",
    latitude: 46.7978,
    longitude: 7.6549,
  },
  {
    zipCode: 3619,
    city: "Innereriz",
    cantonShort: "BE",
    latitude: 46.7883,
    longitude: 7.8239,
  },
  {
    zipCode: 3619,
    city: "Eriz",
    cantonShort: "BE",
    latitude: 46.7805,
    longitude: 7.8048,
  },
  {
    zipCode: 3622,
    city: "Homberg b. Thun",
    cantonShort: "BE",
    latitude: 46.7764,
    longitude: 7.6833,
  },
  {
    zipCode: 3623,
    city: "Teuffenthal b. Thun",
    cantonShort: "BE",
    latitude: 46.7669,
    longitude: 7.7192,
  },
  {
    zipCode: 3623,
    city: "Buchen BE",
    cantonShort: "BE",
    latitude: 46.7821,
    longitude: 7.7084,
  },
  {
    zipCode: 3623,
    city: "Horrenbach",
    cantonShort: "BE",
    latitude: 46.7775,
    longitude: 7.7467,
  },
  {
    zipCode: 3624,
    city: "Schwendibach",
    cantonShort: "BE",
    latitude: 46.7699,
    longitude: 7.661,
  },
  {
    zipCode: 3624,
    city: "Goldiwil (Thun)",
    cantonShort: "BE",
    latitude: 46.7614,
    longitude: 7.6654,
  },
  {
    zipCode: 3625,
    city: "Heiligenschwendi",
    cantonShort: "BE",
    latitude: 46.752,
    longitude: 7.6822,
  },
  {
    zipCode: 3626,
    city: "H\u00fcnibach",
    cantonShort: "BE",
    latitude: 46.7477,
    longitude: 7.6487,
  },
  {
    zipCode: 3627,
    city: "Heimberg",
    cantonShort: "BE",
    latitude: 46.7944,
    longitude: 7.6032,
  },
  {
    zipCode: 3628,
    city: "Uttigen",
    cantonShort: "BE",
    latitude: 46.795,
    longitude: 7.5732,
  },
  {
    zipCode: 3631,
    city: "H\u00f6fen b. Thun",
    cantonShort: "BE",
    latitude: 46.7225,
    longitude: 7.5666,
  },
  {
    zipCode: 3632,
    city: "Niederstocken",
    cantonShort: "BE",
    latitude: 46.7021,
    longitude: 7.5712,
  },
  {
    zipCode: 3632,
    city: "Oberstocken",
    cantonShort: "BE",
    latitude: 46.7068,
    longitude: 7.5502,
  },
  {
    zipCode: 3633,
    city: "Amsoldingen",
    cantonShort: "BE",
    latitude: 46.7261,
    longitude: 7.5854,
  },
  {
    zipCode: 3634,
    city: "Thierachern",
    cantonShort: "BE",
    latitude: 46.7519,
    longitude: 7.5719,
  },
  {
    zipCode: 3635,
    city: "Uebeschi",
    cantonShort: "BE",
    latitude: 46.7395,
    longitude: 7.5473,
  },
  {
    zipCode: 3636,
    city: "Forst b. L\u00e4ngenb\u00fchl",
    cantonShort: "BE",
    latitude: 46.7647,
    longitude: 7.524,
  },
  {
    zipCode: 3636,
    city: "L\u00e4ngenb\u00fchl",
    cantonShort: "BE",
    latitude: 46.7579,
    longitude: 7.5385,
  },
  {
    zipCode: 3638,
    city: "Blumenstein",
    cantonShort: "BE",
    latitude: 46.7281,
    longitude: 7.4955,
  },
  {
    zipCode: 3638,
    city: "Pohlern",
    cantonShort: "BE",
    latitude: 46.7157,
    longitude: 7.5229,
  },
  {
    zipCode: 3645,
    city: "Zwieselberg",
    cantonShort: "BE",
    latitude: 46.7097,
    longitude: 7.615,
  },
  {
    zipCode: 3647,
    city: "Reutigen",
    cantonShort: "BE",
    latitude: 46.6927,
    longitude: 7.6055,
  },
  {
    zipCode: 3652,
    city: "Hilterfingen",
    cantonShort: "BE",
    latitude: 46.7408,
    longitude: 7.6628,
  },
  {
    zipCode: 3653,
    city: "Oberhofen am Thunersee",
    cantonShort: "BE",
    latitude: 46.7332,
    longitude: 7.6771,
  },
  {
    zipCode: 3654,
    city: "Gunten",
    cantonShort: "BE",
    latitude: 46.7145,
    longitude: 7.7023,
  },
  {
    zipCode: 3655,
    city: "Sigriswil",
    cantonShort: "BE",
    latitude: 46.7193,
    longitude: 7.7344,
  },
  {
    zipCode: 3656,
    city: "Aeschlen ob Gunten",
    cantonShort: "BE",
    latitude: 46.7216,
    longitude: 7.6966,
  },
  {
    zipCode: 3656,
    city: "Ringoldswil",
    cantonShort: "BE",
    latitude: 46.7375,
    longitude: 7.6948,
  },
  {
    zipCode: 3656,
    city: "Tschingel ob Gunten",
    cantonShort: "BE",
    latitude: 46.7257,
    longitude: 7.7088,
  },
  {
    zipCode: 3657,
    city: "Schwanden (Sigriswil)",
    cantonShort: "BE",
    latitude: 46.7443,
    longitude: 7.7572,
  },
  {
    zipCode: 3658,
    city: "Merligen",
    cantonShort: "BE",
    latitude: 46.704,
    longitude: 7.753,
  },
  {
    zipCode: 3661,
    city: "Uetendorf",
    cantonShort: "BE",
    latitude: 46.775,
    longitude: 7.5746,
  },
  {
    zipCode: 3662,
    city: "Seftigen",
    cantonShort: "BE",
    latitude: 46.7904,
    longitude: 7.5392,
  },
  {
    zipCode: 3663,
    city: "Gurzelen",
    cantonShort: "BE",
    latitude: 46.7753,
    longitude: 7.5357,
  },
  {
    zipCode: 3664,
    city: "Burgistein",
    cantonShort: "BE",
    latitude: 46.792,
    longitude: 7.5032,
  },
  {
    zipCode: 3665,
    city: "Wattenwil",
    cantonShort: "BE",
    latitude: 46.7594,
    longitude: 7.4929,
  },
  {
    zipCode: 1657,
    city: "Abl\u00e4ndschen",
    cantonShort: "BE",
    latitude: 46.5662,
    longitude: 7.2768,
  },
  {
    zipCode: 3766,
    city: "Boltigen",
    cantonShort: "BE",
    latitude: 46.6052,
    longitude: 7.3582,
  },
  {
    zipCode: 3770,
    city: "Zweisimmen",
    cantonShort: "BE",
    latitude: 46.562,
    longitude: 7.3903,
  },
  {
    zipCode: 3771,
    city: "Blankenburg",
    cantonShort: "BE",
    latitude: 46.542,
    longitude: 7.4183,
  },
  {
    zipCode: 3772,
    city: "St. Stephan",
    cantonShort: "BE",
    latitude: 46.4964,
    longitude: 7.3917,
  },
  {
    zipCode: 3773,
    city: "Matten (St. Stephan)",
    cantonShort: "BE",
    latitude: 46.5104,
    longitude: 7.4622,
  },
  {
    zipCode: 3775,
    city: "Lenk im Simmental",
    cantonShort: "BE",
    latitude: 46.4251,
    longitude: 7.4565,
  },
  {
    zipCode: 3776,
    city: "Oeschseite",
    cantonShort: "BE",
    latitude: 46.5268,
    longitude: 7.3277,
  },
  {
    zipCode: 3777,
    city: "Saanenm\u00f6ser",
    cantonShort: "BE",
    latitude: 46.5157,
    longitude: 7.305,
  },
  {
    zipCode: 3778,
    city: "Sch\u00f6nried",
    cantonShort: "BE",
    latitude: 46.52,
    longitude: 7.2714,
  },
  {
    zipCode: 3780,
    city: "Gstaad",
    cantonShort: "BE",
    latitude: 46.4707,
    longitude: 7.2946,
  },
  {
    zipCode: 3781,
    city: "Turbach",
    cantonShort: "BE",
    latitude: 46.4629,
    longitude: 7.3509,
  },
  {
    zipCode: 3782,
    city: "Lauenen b. Gstaad",
    cantonShort: "BE",
    latitude: 46.3981,
    longitude: 7.3386,
  },
  {
    zipCode: 3783,
    city: "Grund b. Gstaad",
    cantonShort: "BE",
    latitude: 46.4375,
    longitude: 7.2524,
  },
  {
    zipCode: 3784,
    city: "Feutersoey",
    cantonShort: "BE",
    latitude: 46.4044,
    longitude: 7.2444,
  },
  {
    zipCode: 3785,
    city: "Gsteig b. Gstaad",
    cantonShort: "BE",
    latitude: 46.3691,
    longitude: 7.2591,
  },
  {
    zipCode: 3792,
    city: "Saanen",
    cantonShort: "BE",
    latitude: 46.4775,
    longitude: 7.2495,
  },
  {
    zipCode: 3645,
    city: "Gwatt (Thun)",
    cantonShort: "BE",
    latitude: 46.7235,
    longitude: 7.6213,
  },
  {
    zipCode: 3646,
    city: "Einigen",
    cantonShort: "BE",
    latitude: 46.7071,
    longitude: 7.6453,
  },
  {
    zipCode: 3700,
    city: "Spiez",
    cantonShort: "BE",
    latitude: 46.6857,
    longitude: 7.6698,
  },
  {
    zipCode: 3702,
    city: "Hondrich",
    cantonShort: "BE",
    latitude: 46.6718,
    longitude: 7.6818,
  },
  {
    zipCode: 3703,
    city: "Aeschi b. Spiez",
    cantonShort: "BE",
    latitude: 46.6543,
    longitude: 7.6988,
  },
  {
    zipCode: 3703,
    city: "Aeschiried",
    cantonShort: "BE",
    latitude: 46.6135,
    longitude: 7.7685,
  },
  {
    zipCode: 3704,
    city: "Krattigen",
    cantonShort: "BE",
    latitude: 46.6508,
    longitude: 7.7393,
  },
  {
    zipCode: 3705,
    city: "Faulensee",
    cantonShort: "BE",
    latitude: 46.6721,
    longitude: 7.7074,
  },
  {
    zipCode: 3711,
    city: "Emdthal",
    cantonShort: "BE",
    latitude: 46.6505,
    longitude: 7.6807,
  },
  {
    zipCode: 3711,
    city: "M\u00fclenen",
    cantonShort: "BE",
    latitude: 46.6396,
    longitude: 7.675,
  },
  {
    zipCode: 3713,
    city: "Reichenbach im Kandertal",
    cantonShort: "BE",
    latitude: 46.618,
    longitude: 7.6918,
  },
  {
    zipCode: 3714,
    city: "Wengi b. Frutigen",
    cantonShort: "BE",
    latitude: 46.6225,
    longitude: 7.6464,
  },
  {
    zipCode: 3714,
    city: "Frutigen",
    cantonShort: "BE",
    latitude: 46.5854,
    longitude: 7.6383,
  },
  {
    zipCode: 3715,
    city: "Adelboden",
    cantonShort: "BE",
    latitude: 46.4736,
    longitude: 7.5552,
  },
  {
    zipCode: 3716,
    city: "Kandergrund",
    cantonShort: "BE",
    latitude: 46.5515,
    longitude: 7.6677,
  },
  {
    zipCode: 3717,
    city: "Blausee-Mitholz",
    cantonShort: "BE",
    latitude: 46.525,
    longitude: 7.6837,
  },
  {
    zipCode: 3718,
    city: "Kandersteg",
    cantonShort: "BE",
    latitude: 46.4692,
    longitude: 7.7083,
  },
  {
    zipCode: 3722,
    city: "Scharnachtal",
    cantonShort: "BE",
    latitude: 46.6148,
    longitude: 7.7159,
  },
  {
    zipCode: 3723,
    city: "Kiental",
    cantonShort: "BE",
    latitude: 46.5561,
    longitude: 7.7608,
  },
  {
    zipCode: 3724,
    city: "Ried (Frutigen)",
    cantonShort: "BE",
    latitude: 46.5604,
    longitude: 7.5942,
  },
  {
    zipCode: 3725,
    city: "Achseten",
    cantonShort: "BE",
    latitude: 46.5291,
    longitude: 7.6046,
  },
  {
    zipCode: 3752,
    city: "Wimmis",
    cantonShort: "BE",
    latitude: 46.6616,
    longitude: 7.6412,
  },
  {
    zipCode: 3753,
    city: "Oey",
    cantonShort: "BE",
    latitude: 46.6477,
    longitude: 7.5971,
  },
  {
    zipCode: 3754,
    city: "Diemtigen",
    cantonShort: "BE",
    latitude: 46.6466,
    longitude: 7.5579,
  },
  {
    zipCode: 3755,
    city: "Horboden",
    cantonShort: "BE",
    latitude: 46.6038,
    longitude: 7.572,
  },
  {
    zipCode: 3756,
    city: "Zwischenfl\u00fch",
    cantonShort: "BE",
    latitude: 46.6022,
    longitude: 7.5015,
  },
  {
    zipCode: 3757,
    city: "Schwenden im Diemtigtal",
    cantonShort: "BE",
    latitude: 46.5563,
    longitude: 7.5001,
  },
  {
    zipCode: 3758,
    city: "Latterbach",
    cantonShort: "BE",
    latitude: 46.6721,
    longitude: 7.5895,
  },
  {
    zipCode: 3762,
    city: "Erlenbach im Simmental",
    cantonShort: "BE",
    latitude: 46.6585,
    longitude: 7.5302,
  },
  {
    zipCode: 3763,
    city: "D\u00e4rstetten",
    cantonShort: "BE",
    latitude: 46.6804,
    longitude: 7.5051,
  },
  {
    zipCode: 3764,
    city: "Weissenburg",
    cantonShort: "BE",
    latitude: 46.6661,
    longitude: 7.477,
  },
  {
    zipCode: 3765,
    city: "Oberwil im Simmental",
    cantonShort: "BE",
    latitude: 46.6559,
    longitude: 7.4312,
  },
  {
    zipCode: 3706,
    city: "Leissigen",
    cantonShort: "BE",
    latitude: 46.6428,
    longitude: 7.7789,
  },
  {
    zipCode: 3707,
    city: "D\u00e4rligen",
    cantonShort: "BE",
    latitude: 46.6539,
    longitude: 7.8128,
  },
  {
    zipCode: 3800,
    city: "Interlaken",
    cantonShort: "BE",
    latitude: 46.6867,
    longitude: 7.866,
  },
  {
    zipCode: 3800,
    city: "Sundlauenen",
    cantonShort: "BE",
    latitude: 46.6857,
    longitude: 7.7818,
  },
  {
    zipCode: 3800,
    city: "Matten b. Interlaken",
    cantonShort: "BE",
    latitude: 46.6763,
    longitude: 7.8649,
  },
  {
    zipCode: 3800,
    city: "Unterseen",
    cantonShort: "BE",
    latitude: 46.6945,
    longitude: 7.8405,
  },
  {
    zipCode: 3800,
    city: "Interlaken",
    cantonShort: "BE",
    latitude: 46.6867,
    longitude: 7.866,
  },
  {
    zipCode: 3802,
    city: "Interlaken Ost",
    cantonShort: "BE",
    latitude: 46.6871,
    longitude: 7.8649,
  },
  {
    zipCode: 3803,
    city: "Beatenberg",
    cantonShort: "BE",
    latitude: 46.717,
    longitude: 7.8063,
  },
  {
    zipCode: 3804,
    city: "Habkern",
    cantonShort: "BE",
    latitude: 46.755,
    longitude: 7.8899,
  },
  {
    zipCode: 3805,
    city: "Goldswil b. Interlaken",
    cantonShort: "BE",
    latitude: 46.7005,
    longitude: 7.8745,
  },
  {
    zipCode: 3806,
    city: "B\u00f6nigen b. Interlaken",
    cantonShort: "BE",
    latitude: 46.6804,
    longitude: 7.9238,
  },
  {
    zipCode: 3807,
    city: "Iseltwald",
    cantonShort: "BE",
    latitude: 46.7011,
    longitude: 7.9899,
  },
  {
    zipCode: 3812,
    city: "Wilderswil",
    cantonShort: "BE",
    latitude: 46.6488,
    longitude: 7.8691,
  },
  {
    zipCode: 3813,
    city: "Saxeten",
    cantonShort: "BE",
    latitude: 46.6251,
    longitude: 7.8275,
  },
  {
    zipCode: 3814,
    city: "Gsteigwiler",
    cantonShort: "BE",
    latitude: 46.6522,
    longitude: 7.889,
  },
  {
    zipCode: 3815,
    city: "Zweil\u00fctschinen",
    cantonShort: "BE",
    latitude: 46.626,
    longitude: 7.9068,
  },
  {
    zipCode: 3815,
    city: "G\u00fcndlischwand",
    cantonShort: "BE",
    latitude: 46.6552,
    longitude: 7.9369,
  },
  {
    zipCode: 3816,
    city: "Burglauenen",
    cantonShort: "BE",
    latitude: 46.6331,
    longitude: 7.9739,
  },
  {
    zipCode: 3816,
    city: "L\u00fctschental",
    cantonShort: "BE",
    latitude: 46.6439,
    longitude: 7.9522,
  },
  {
    zipCode: 3818,
    city: "Grindelwald",
    cantonShort: "BE",
    latitude: 46.6186,
    longitude: 8.0535,
  },
  {
    zipCode: 3822,
    city: "Lauterbrunnen",
    cantonShort: "BE",
    latitude: 46.5952,
    longitude: 7.9072,
  },
  {
    zipCode: 3822,
    city: "Isenfluh",
    cantonShort: "BE",
    latitude: 46.5967,
    longitude: 7.8565,
  },
  {
    zipCode: 3823,
    city: "Wengen",
    cantonShort: "BE",
    latitude: 46.5751,
    longitude: 7.9498,
  },
  {
    zipCode: 3823,
    city: "Eigergletscher",
    cantonShort: "BE",
    latitude: 46.5748,
    longitude: 7.9744,
  },
  {
    zipCode: 3823,
    city: "Kleine Scheidegg",
    cantonShort: "BE",
    latitude: 46.5844,
    longitude: 7.9602,
  },
  {
    zipCode: 3824,
    city: "Stechelberg",
    cantonShort: "BE",
    latitude: 46.515,
    longitude: 7.8979,
  },
  {
    zipCode: 3825,
    city: "M\u00fcrren",
    cantonShort: "BE",
    latitude: 46.5766,
    longitude: 7.8827,
  },
  {
    zipCode: 3826,
    city: "Gimmelwald",
    cantonShort: "BE",
    latitude: 46.538,
    longitude: 7.8517,
  },
  {
    zipCode: 3852,
    city: "Ringgenberg BE",
    cantonShort: "BE",
    latitude: 46.7114,
    longitude: 7.8968,
  },
  {
    zipCode: 3853,
    city: "Niederried b. Interlaken",
    cantonShort: "BE",
    latitude: 46.7249,
    longitude: 7.9263,
  },
  {
    zipCode: 3854,
    city: "Oberried am Brienzersee",
    cantonShort: "BE",
    latitude: 46.7563,
    longitude: 7.9571,
  },
  {
    zipCode: 3855,
    city: "Axalp",
    cantonShort: "BE",
    latitude: 46.7215,
    longitude: 8.0399,
  },
  {
    zipCode: 3855,
    city: "Schwanden b. Brienz",
    cantonShort: "BE",
    latitude: 46.7711,
    longitude: 8.0557,
  },
  {
    zipCode: 3855,
    city: "Brienz BE",
    cantonShort: "BE",
    latitude: 46.7321,
    longitude: 8.0379,
  },
  {
    zipCode: 3856,
    city: "Brienzwiler",
    cantonShort: "BE",
    latitude: 46.7586,
    longitude: 8.106,
  },
  {
    zipCode: 3857,
    city: "Unterbach BE",
    cantonShort: "BE",
    latitude: 46.7378,
    longitude: 8.119,
  },
  {
    zipCode: 3858,
    city: "Hofstetten b. Brienz",
    cantonShort: "BE",
    latitude: 46.7669,
    longitude: 8.0799,
  },
  {
    zipCode: 3860,
    city: "Br\u00fcnig",
    cantonShort: "BE",
    latitude: 46.7587,
    longitude: 8.133,
  },
  {
    zipCode: 3860,
    city: "Meiringen",
    cantonShort: "BE",
    latitude: 46.7108,
    longitude: 8.1255,
  },
  {
    zipCode: 3860,
    city: "Rosenlaui",
    cantonShort: "BE",
    latitude: 46.6796,
    longitude: 8.1537,
  },
  {
    zipCode: 3860,
    city: "Schattenhalb",
    cantonShort: "BE",
    latitude: 46.6942,
    longitude: 8.17,
  },
  {
    zipCode: 3862,
    city: "Innertkirchen",
    cantonShort: "BE",
    latitude: 46.6717,
    longitude: 8.2371,
  },
  {
    zipCode: 3863,
    city: "Gadmen",
    cantonShort: "BE",
    latitude: 46.7112,
    longitude: 8.3757,
  },
  {
    zipCode: 3864,
    city: "Guttannen",
    cantonShort: "BE",
    latitude: 46.5965,
    longitude: 8.2578,
  },
  {
    zipCode: 6083,
    city: "Hasliberg Hohfluh",
    cantonShort: "BE",
    latitude: 46.7543,
    longitude: 8.1733,
  },
  {
    zipCode: 6084,
    city: "Hasliberg Wasserwendi",
    cantonShort: "BE",
    latitude: 46.7559,
    longitude: 8.2104,
  },
  {
    zipCode: 6085,
    city: "Hasliberg Goldern",
    cantonShort: "BE",
    latitude: 46.7385,
    longitude: 8.1963,
  },
  {
    zipCode: 6086,
    city: "Hasliberg Reuti",
    cantonShort: "BE",
    latitude: 46.7418,
    longitude: 8.2522,
  },
  {
    zipCode: 4040,
    city: "Basel",
    cantonShort: "BL",
    latitude: 47.5186,
    longitude: 7.6174,
  },
  {
    zipCode: 4042,
    city: "Basel PF Operations Center",
    cantonShort: "BL",
    latitude: 47.5186,
    longitude: 7.6174,
  },
  {
    zipCode: 4042,
    city: "Basel PF OC",
    cantonShort: "BL",
    latitude: 47.5186,
    longitude: 7.6174,
  },
  {
    zipCode: 4101,
    city: "Bruderholz",
    cantonShort: "BL",
    latitude: 47.528,
    longitude: 7.5812,
  },
  {
    zipCode: 4102,
    city: "Binningen",
    cantonShort: "BL",
    latitude: 47.5367,
    longitude: 7.5676,
  },
  {
    zipCode: 4102,
    city: "Binningen 1",
    cantonShort: "BL",
    latitude: 47.5367,
    longitude: 7.5678,
  },
  {
    zipCode: 4103,
    city: "Bottmingen",
    cantonShort: "BL",
    latitude: 47.522,
    longitude: 7.5765,
  },
  {
    zipCode: 4104,
    city: "Oberwil BL",
    cantonShort: "BL",
    latitude: 47.5169,
    longitude: 7.5508,
  },
  {
    zipCode: 4105,
    city: "Biel-Benken BL",
    cantonShort: "BL",
    latitude: 47.5077,
    longitude: 7.5209,
  },
  {
    zipCode: 4106,
    city: "Therwil",
    cantonShort: "BL",
    latitude: 47.4976,
    longitude: 7.5589,
  },
  {
    zipCode: 4107,
    city: "Ettingen",
    cantonShort: "BL",
    latitude: 47.4745,
    longitude: 7.5459,
  },
  {
    zipCode: 4123,
    city: "Allschwil 1",
    cantonShort: "BL",
    latitude: 47.5451,
    longitude: 7.5375,
  },
  {
    zipCode: 4123,
    city: "Allschwil",
    cantonShort: "BL",
    latitude: 47.545,
    longitude: 7.5375,
  },
  {
    zipCode: 4124,
    city: "Sch\u00f6nenbuch",
    cantonShort: "BL",
    latitude: 47.5352,
    longitude: 7.5061,
  },
  {
    zipCode: 4127,
    city: "Birsfelden",
    cantonShort: "BL",
    latitude: 47.5508,
    longitude: 7.6363,
  },
  {
    zipCode: 4132,
    city: "Muttenz",
    cantonShort: "BL",
    latitude: 47.5234,
    longitude: 7.6481,
  },
  {
    zipCode: 4132,
    city: "Muttenz 1",
    cantonShort: "BL",
    latitude: 47.5248,
    longitude: 7.6491,
  },
  {
    zipCode: 4142,
    city: "M\u00fcnchenstein",
    cantonShort: "BL",
    latitude: 47.5169,
    longitude: 7.6175,
  },
  {
    zipCode: 4142,
    city: "M\u00fcnchenstein 1",
    cantonShort: "BL",
    latitude: 47.5186,
    longitude: 7.6174,
  },
  {
    zipCode: 4144,
    city: "Arlesheim",
    cantonShort: "BL",
    latitude: 47.4955,
    longitude: 7.6283,
  },
  {
    zipCode: 4147,
    city: "Aesch BL",
    cantonShort: "BL",
    latitude: 47.4728,
    longitude: 7.584,
  },
  {
    zipCode: 4148,
    city: "Pfeffingen",
    cantonShort: "BL",
    latitude: 47.4562,
    longitude: 7.582,
  },
  {
    zipCode: 4153,
    city: "Reinach BL 1",
    cantonShort: "BL",
    latitude: 47.4975,
    longitude: 7.5912,
  },
  {
    zipCode: 4153,
    city: "Reinach BL",
    cantonShort: "BL",
    latitude: 47.4973,
    longitude: 7.5909,
  },
  {
    zipCode: 2814,
    city: "Roggenburg",
    cantonShort: "BL",
    latitude: 47.4277,
    longitude: 7.3567,
  },
  {
    zipCode: 4117,
    city: "Burg im Leimental",
    cantonShort: "BL",
    latitude: 47.4523,
    longitude: 7.4368,
  },
  {
    zipCode: 4202,
    city: "Duggingen",
    cantonShort: "BL",
    latitude: 47.4513,
    longitude: 7.6096,
  },
  {
    zipCode: 4203,
    city: "Grellingen",
    cantonShort: "BL",
    latitude: 47.439,
    longitude: 7.5866,
  },
  {
    zipCode: 4222,
    city: "Zwingen",
    cantonShort: "BL",
    latitude: 47.436,
    longitude: 7.5279,
  },
  {
    zipCode: 4223,
    city: "Blauen",
    cantonShort: "BL",
    latitude: 47.4527,
    longitude: 7.5233,
  },
  {
    zipCode: 4224,
    city: "Nenzlingen",
    cantonShort: "BL",
    latitude: 47.4493,
    longitude: 7.5609,
  },
  {
    zipCode: 4225,
    city: "Brislach",
    cantonShort: "BL",
    latitude: 47.4252,
    longitude: 7.5496,
  },
  {
    zipCode: 4242,
    city: "Laufen",
    cantonShort: "BL",
    latitude: 47.4105,
    longitude: 7.4911,
  },
  {
    zipCode: 4243,
    city: "Dittingen",
    cantonShort: "BL",
    latitude: 47.4415,
    longitude: 7.4927,
  },
  {
    zipCode: 4244,
    city: "R\u00f6schenz",
    cantonShort: "BL",
    latitude: 47.4329,
    longitude: 7.4649,
  },
  {
    zipCode: 4246,
    city: "Wahlen b. Laufen",
    cantonShort: "BL",
    latitude: 47.398,
    longitude: 7.5115,
  },
  {
    zipCode: 4253,
    city: "Liesberg",
    cantonShort: "BL",
    latitude: 47.3952,
    longitude: 7.4298,
  },
  {
    zipCode: 4254,
    city: "Liesberg Dorf",
    cantonShort: "BL",
    latitude: 47.4074,
    longitude: 7.4156,
  },
  {
    zipCode: 4133,
    city: "Pratteln 1",
    cantonShort: "BL",
    latitude: 47.5185,
    longitude: 7.6927,
  },
  {
    zipCode: 4133,
    city: "Pratteln",
    cantonShort: "BL",
    latitude: 47.5192,
    longitude: 7.6913,
  },
  {
    zipCode: 4302,
    city: "Augst BL",
    cantonShort: "BL",
    latitude: 47.5321,
    longitude: 7.7176,
  },
  {
    zipCode: 4304,
    city: "Giebenach",
    cantonShort: "BL",
    latitude: 47.5234,
    longitude: 7.7383,
  },
  {
    zipCode: 4402,
    city: "Frenkendorf",
    cantonShort: "BL",
    latitude: 47.5009,
    longitude: 7.6971,
  },
  {
    zipCode: 4410,
    city: "Liestal",
    cantonShort: "BL",
    latitude: 47.4853,
    longitude: 7.7255,
  },
  {
    zipCode: 4411,
    city: "Seltisberg",
    cantonShort: "BL",
    latitude: 47.462,
    longitude: 7.7157,
  },
  {
    zipCode: 4414,
    city: "F\u00fcllinsdorf",
    cantonShort: "BL",
    latitude: 47.5109,
    longitude: 7.732,
  },
  {
    zipCode: 4415,
    city: "Lausen",
    cantonShort: "BL",
    latitude: 47.4706,
    longitude: 7.7656,
  },
  {
    zipCode: 4416,
    city: "Bubendorf",
    cantonShort: "BL",
    latitude: 47.4458,
    longitude: 7.737,
  },
  {
    zipCode: 4417,
    city: "Ziefen",
    cantonShort: "BL",
    latitude: 47.429,
    longitude: 7.7027,
  },
  {
    zipCode: 4419,
    city: "Lupsingen",
    cantonShort: "BL",
    latitude: 47.4463,
    longitude: 7.6961,
  },
  {
    zipCode: 4422,
    city: "Arisdorf",
    cantonShort: "BL",
    latitude: 47.5093,
    longitude: 7.7665,
  },
  {
    zipCode: 4423,
    city: "Hersberg",
    cantonShort: "BL",
    latitude: 47.4922,
    longitude: 7.7833,
  },
  {
    zipCode: 4433,
    city: "Ramlinsburg",
    cantonShort: "BL",
    latitude: 47.4502,
    longitude: 7.7682,
  },
  {
    zipCode: 4441,
    city: "Th\u00fcrnen",
    cantonShort: "BL",
    latitude: 47.4531,
    longitude: 7.8289,
  },
  {
    zipCode: 4442,
    city: "Diepflingen",
    cantonShort: "BL",
    latitude: 47.445,
    longitude: 7.8404,
  },
  {
    zipCode: 4443,
    city: "Wittinsburg",
    cantonShort: "BL",
    latitude: 47.4297,
    longitude: 7.8386,
  },
  {
    zipCode: 4444,
    city: "R\u00fcmlingen",
    cantonShort: "BL",
    latitude: 47.428,
    longitude: 7.856,
  },
  {
    zipCode: 4445,
    city: "H\u00e4felfingen",
    cantonShort: "BL",
    latitude: 47.4139,
    longitude: 7.8704,
  },
  {
    zipCode: 4446,
    city: "Buckten",
    cantonShort: "BL",
    latitude: 47.4116,
    longitude: 7.8467,
  },
  {
    zipCode: 4447,
    city: "K\u00e4nerkinden",
    cantonShort: "BL",
    latitude: 47.4116,
    longitude: 7.8324,
  },
  {
    zipCode: 4448,
    city: "L\u00e4ufelfingen",
    cantonShort: "BL",
    latitude: 47.3942,
    longitude: 7.856,
  },
  {
    zipCode: 4450,
    city: "Sissach",
    cantonShort: "BL",
    latitude: 47.4722,
    longitude: 7.8076,
  },
  {
    zipCode: 4451,
    city: "Wintersingen",
    cantonShort: "BL",
    latitude: 47.4961,
    longitude: 7.8271,
  },
  {
    zipCode: 4452,
    city: "Itingen",
    cantonShort: "BL",
    latitude: 47.4647,
    longitude: 7.7847,
  },
  {
    zipCode: 4453,
    city: "Nusshof",
    cantonShort: "BL",
    latitude: 47.4915,
    longitude: 7.7985,
  },
  {
    zipCode: 4455,
    city: "Zunzgen",
    cantonShort: "BL",
    latitude: 47.4451,
    longitude: 7.7986,
  },
  {
    zipCode: 4456,
    city: "Tenniken",
    cantonShort: "BL",
    latitude: 47.4325,
    longitude: 7.8146,
  },
  {
    zipCode: 4460,
    city: "Gelterkinden",
    cantonShort: "BL",
    latitude: 47.4571,
    longitude: 7.8586,
  },
  {
    zipCode: 4461,
    city: "B\u00f6ckten",
    cantonShort: "BL",
    latitude: 47.4673,
    longitude: 7.8344,
  },
  {
    zipCode: 4462,
    city: "Rickenbach BL",
    cantonShort: "BL",
    latitude: 47.4847,
    longitude: 7.8492,
  },
  {
    zipCode: 4463,
    city: "Buus",
    cantonShort: "BL",
    latitude: 47.5047,
    longitude: 7.8661,
  },
  {
    zipCode: 4464,
    city: "Maisprach",
    cantonShort: "BL",
    latitude: 47.5223,
    longitude: 7.8458,
  },
  {
    zipCode: 4465,
    city: "Hemmiken",
    cantonShort: "BL",
    latitude: 47.4902,
    longitude: 7.8917,
  },
  {
    zipCode: 4466,
    city: "Ormalingen",
    cantonShort: "BL",
    latitude: 47.4731,
    longitude: 7.8784,
  },
  {
    zipCode: 4467,
    city: "Rothenfluh",
    cantonShort: "BL",
    latitude: 47.4703,
    longitude: 7.9177,
  },
  {
    zipCode: 4469,
    city: "Anwil",
    cantonShort: "BL",
    latitude: 47.453,
    longitude: 7.942,
  },
  {
    zipCode: 4492,
    city: "Tecknau",
    cantonShort: "BL",
    latitude: 47.4454,
    longitude: 7.8897,
  },
  {
    zipCode: 4493,
    city: "Wenslingen",
    cantonShort: "BL",
    latitude: 47.4432,
    longitude: 7.91,
  },
  {
    zipCode: 4494,
    city: "Oltingen",
    cantonShort: "BL",
    latitude: 47.4302,
    longitude: 7.9373,
  },
  {
    zipCode: 4495,
    city: "Zeglingen",
    cantonShort: "BL",
    latitude: 47.4121,
    longitude: 7.9123,
  },
  {
    zipCode: 4496,
    city: "Kilchberg BL",
    cantonShort: "BL",
    latitude: 47.4257,
    longitude: 7.8969,
  },
  {
    zipCode: 4497,
    city: "R\u00fcnenberg",
    cantonShort: "BL",
    latitude: 47.432,
    longitude: 7.8804,
  },
  {
    zipCode: 4207,
    city: "Bretzwil",
    cantonShort: "BL",
    latitude: 47.3961,
    longitude: 7.6501,
  },
  {
    zipCode: 4418,
    city: "Reigoldswil",
    cantonShort: "BL",
    latitude: 47.3948,
    longitude: 7.6904,
  },
  {
    zipCode: 4424,
    city: "Arboldswil",
    cantonShort: "BL",
    latitude: 47.4148,
    longitude: 7.7185,
  },
  {
    zipCode: 4425,
    city: "Titterten",
    cantonShort: "BL",
    latitude: 47.402,
    longitude: 7.7148,
  },
  {
    zipCode: 4426,
    city: "Lauwil",
    cantonShort: "BL",
    latitude: 47.3802,
    longitude: 7.6679,
  },
  {
    zipCode: 4431,
    city: "Bennwil",
    cantonShort: "BL",
    latitude: 47.3963,
    longitude: 7.7821,
  },
  {
    zipCode: 4432,
    city: "Lampenberg",
    cantonShort: "BL",
    latitude: 47.4267,
    longitude: 7.7539,
  },
  {
    zipCode: 4434,
    city: "H\u00f6lstein",
    cantonShort: "BL",
    latitude: 47.4259,
    longitude: 7.7749,
  },
  {
    zipCode: 4435,
    city: "Niederdorf",
    cantonShort: "BL",
    latitude: 47.4105,
    longitude: 7.7475,
  },
  {
    zipCode: 4436,
    city: "Liedertswil",
    cantonShort: "BL",
    latitude: 47.3887,
    longitude: 7.7206,
  },
  {
    zipCode: 4436,
    city: "Oberdorf BL",
    cantonShort: "BL",
    latitude: 47.3928,
    longitude: 7.7526,
  },
  {
    zipCode: 4437,
    city: "Waldenburg",
    cantonShort: "BL",
    latitude: 47.3771,
    longitude: 7.7402,
  },
  {
    zipCode: 4438,
    city: "Langenbruck",
    cantonShort: "BL",
    latitude: 47.3577,
    longitude: 7.7711,
  },
  {
    zipCode: 4457,
    city: "Diegten",
    cantonShort: "BL",
    latitude: 47.4093,
    longitude: 7.8097,
  },
  {
    zipCode: 4458,
    city: "Eptingen",
    cantonShort: "BL",
    latitude: 47.3797,
    longitude: 7.8173,
  },
  {
    zipCode: 4000,
    city: "Basel",
    cantonShort: "BS",
    latitude: 47.5584,
    longitude: 7.5733,
  },
  {
    zipCode: 4001,
    city: "Basel",
    cantonShort: "BS",
    latitude: 47.5568,
    longitude: 7.5887,
  },
  {
    zipCode: 4002,
    city: "Basel",
    cantonShort: "BS",
    latitude: 47.5584,
    longitude: 7.5733,
  },
  {
    zipCode: 4005,
    city: "Basel",
    cantonShort: "BS",
    latitude: 47.5584,
    longitude: 7.5733,
  },
  {
    zipCode: 4009,
    city: "Basel",
    cantonShort: "BS",
    latitude: 47.5584,
    longitude: 7.5733,
  },
  {
    zipCode: 4010,
    city: "Basel",
    cantonShort: "BS",
    latitude: 47.5584,
    longitude: 7.5733,
  },
  {
    zipCode: 4018,
    city: "Basel",
    cantonShort: "BS",
    latitude: 47.5584,
    longitude: 7.5733,
  },
  {
    zipCode: 4019,
    city: "Basel",
    cantonShort: "BS",
    latitude: 47.5584,
    longitude: 7.5733,
  },
  {
    zipCode: 4020,
    city: "Basel",
    cantonShort: "BS",
    latitude: 47.5584,
    longitude: 7.5733,
  },
  {
    zipCode: 4030,
    city: "Basel",
    cantonShort: "BS",
    latitude: 47.5584,
    longitude: 7.5733,
  },
  {
    zipCode: 4031,
    city: "Basel",
    cantonShort: "BS",
    latitude: 47.5619,
    longitude: 7.5823,
  },
  {
    zipCode: 4039,
    city: "Basel SSF",
    cantonShort: "BS",
    latitude: 47.5577,
    longitude: 7.5936,
  },
  {
    zipCode: 4039,
    city: "Basel",
    cantonShort: "BS",
    latitude: 47.5577,
    longitude: 7.5936,
  },
  {
    zipCode: 4041,
    city: "Basel UBS",
    cantonShort: "BS",
    latitude: 47.5577,
    longitude: 7.5936,
  },
  {
    zipCode: 4041,
    city: "Basel",
    cantonShort: "BS",
    latitude: 47.5577,
    longitude: 7.5936,
  },
  {
    zipCode: 4051,
    city: "Basel",
    cantonShort: "BS",
    latitude: 47.554,
    longitude: 7.5865,
  },
  {
    zipCode: 4052,
    city: "Basel",
    cantonShort: "BS",
    latitude: 47.5457,
    longitude: 7.6096,
  },
  {
    zipCode: 4052,
    city: "Basel",
    cantonShort: "BS",
    latitude: 47.5457,
    longitude: 7.6096,
  },
  {
    zipCode: 4053,
    city: "Basel",
    cantonShort: "BS",
    latitude: 47.5406,
    longitude: 7.5964,
  },
  {
    zipCode: 4054,
    city: "Basel",
    cantonShort: "BS",
    latitude: 47.5497,
    longitude: 7.5696,
  },
  {
    zipCode: 4055,
    city: "Basel",
    cantonShort: "BS",
    latitude: 47.5626,
    longitude: 7.5667,
  },
  {
    zipCode: 4056,
    city: "Basel",
    cantonShort: "BS",
    latitude: 47.5698,
    longitude: 7.5749,
  },
  {
    zipCode: 4057,
    city: "Basel",
    cantonShort: "BS",
    latitude: 47.5767,
    longitude: 7.5947,
  },
  {
    zipCode: 4058,
    city: "Basel",
    cantonShort: "BS",
    latitude: 47.566,
    longitude: 7.6112,
  },
  {
    zipCode: 4059,
    city: "Basel",
    cantonShort: "BS",
    latitude: 47.5309,
    longitude: 7.5939,
  },
  {
    zipCode: 4070,
    city: "Basel",
    cantonShort: "BS",
    latitude: 47.5584,
    longitude: 7.5733,
  },
  {
    zipCode: 4075,
    city: "Basel",
    cantonShort: "BS",
    latitude: 47.5584,
    longitude: 7.5733,
  },
  {
    zipCode: 4089,
    city: "Basel SPI GLS",
    cantonShort: "BS",
    latitude: 47.5577,
    longitude: 7.5936,
  },
  {
    zipCode: 4089,
    city: "Basel",
    cantonShort: "BS",
    latitude: 47.5577,
    longitude: 7.5936,
  },
  {
    zipCode: 4089,
    city: "Basel SPILOG",
    cantonShort: "BS",
    latitude: 47.5577,
    longitude: 7.5936,
  },
  {
    zipCode: 4089,
    city: "Basel SPI GLS Retour",
    cantonShort: "BS",
    latitude: 47.5577,
    longitude: 7.5936,
  },
  {
    zipCode: 4091,
    city: "Basel",
    cantonShort: "BS",
    latitude: 47.5584,
    longitude: 7.5733,
  },
  {
    zipCode: 4125,
    city: "Riehen",
    cantonShort: "BS",
    latitude: 47.5795,
    longitude: 7.6512,
  },
  {
    zipCode: 4125,
    city: "Riehen 1",
    cantonShort: "BS",
    latitude: 47.5794,
    longitude: 7.6512,
  },
  {
    zipCode: 4126,
    city: "Bettingen",
    cantonShort: "BS",
    latitude: 47.5704,
    longitude: 7.6722,
  },
  {
    zipCode: 1410,
    city: "Pr\u00e9vondavaux",
    cantonShort: "FR",
    latitude: 46.7313,
    longitude: 6.8026,
  },
  {
    zipCode: 1468,
    city: "Cheyres",
    cantonShort: "FR",
    latitude: 46.8207,
    longitude: 6.7756,
  },
  {
    zipCode: 1470,
    city: "Estavayer-le-Lac",
    cantonShort: "FR",
    latitude: 46.861,
    longitude: 6.8415,
  },
  {
    zipCode: 1470,
    city: "Lully FR",
    cantonShort: "FR",
    latitude: 46.8317,
    longitude: 6.85,
  },
  {
    zipCode: 1470,
    city: "Bollion",
    cantonShort: "FR",
    latitude: 46.8215,
    longitude: 6.832,
  },
  {
    zipCode: 1470,
    city: "Seiry",
    cantonShort: "FR",
    latitude: 46.8146,
    longitude: 6.835,
  },
  {
    zipCode: 1473,
    city: "Ch\u00e2tillon FR",
    cantonShort: "FR",
    latitude: 46.83,
    longitude: 6.8302,
  },
  {
    zipCode: 1473,
    city: "Font",
    cantonShort: "FR",
    latitude: 46.8483,
    longitude: 6.8109,
  },
  {
    zipCode: 1474,
    city: "Ch\u00e2bles FR",
    cantonShort: "FR",
    latitude: 46.8313,
    longitude: 6.8002,
  },
  {
    zipCode: 1475,
    city: "Autavaux",
    cantonShort: "FR",
    latitude: 46.8762,
    longitude: 6.8606,
  },
  {
    zipCode: 1475,
    city: "Forel FR",
    cantonShort: "FR",
    latitude: 46.884,
    longitude: 6.88,
  },
  {
    zipCode: 1475,
    city: "Montbrelloz",
    cantonShort: "FR",
    latitude: 46.854,
    longitude: 6.8872,
  },
  {
    zipCode: 1482,
    city: "Cugy FR",
    cantonShort: "FR",
    latitude: 46.8109,
    longitude: 6.8939,
  },
  {
    zipCode: 1483,
    city: "Montet (Broye)",
    cantonShort: "FR",
    latitude: 46.8172,
    longitude: 6.8648,
  },
  {
    zipCode: 1483,
    city: "Frasses",
    cantonShort: "FR",
    latitude: 46.8262,
    longitude: 6.8668,
  },
  {
    zipCode: 1483,
    city: "Vesin",
    cantonShort: "FR",
    latitude: 46.8052,
    longitude: 6.8723,
  },
  {
    zipCode: 1484,
    city: "Aumont",
    cantonShort: "FR",
    latitude: 46.7975,
    longitude: 6.8551,
  },
  {
    zipCode: 1484,
    city: "Granges-de-Vesin",
    cantonShort: "FR",
    latitude: 46.8107,
    longitude: 6.842,
  },
  {
    zipCode: 1485,
    city: "Nuvilly",
    cantonShort: "FR",
    latitude: 46.7841,
    longitude: 6.8339,
  },
  {
    zipCode: 1486,
    city: "Vuissens",
    cantonShort: "FR",
    latitude: 46.7346,
    longitude: 6.7692,
  },
  {
    zipCode: 1489,
    city: "Murist",
    cantonShort: "FR",
    latitude: 46.796,
    longitude: 6.8112,
  },
  {
    zipCode: 1527,
    city: "Villeneuve FR",
    cantonShort: "FR",
    latitude: 46.7421,
    longitude: 6.8668,
  },
  {
    zipCode: 1528,
    city: "Praratoud",
    cantonShort: "FR",
    latitude: 46.736,
    longitude: 6.8435,
  },
  {
    zipCode: 1528,
    city: "Surpierre",
    cantonShort: "FR",
    latitude: 46.7474,
    longitude: 6.8553,
  },
  {
    zipCode: 1529,
    city: "Cheiry",
    cantonShort: "FR",
    latitude: 46.7502,
    longitude: 6.8383,
  },
  {
    zipCode: 1532,
    city: "F\u00e9tigny",
    cantonShort: "FR",
    latitude: 46.7967,
    longitude: 6.9138,
  },
  {
    zipCode: 1533,
    city: "M\u00e9ni\u00e8res",
    cantonShort: "FR",
    latitude: 46.7853,
    longitude: 6.8861,
  },
  {
    zipCode: 1534,
    city: "Chapelle (Broye)",
    cantonShort: "FR",
    latitude: 46.7661,
    longitude: 6.8499,
  },
  {
    zipCode: 1541,
    city: "Bussy FR",
    cantonShort: "FR",
    latitude: 46.8346,
    longitude: 6.8921,
  },
  {
    zipCode: 1541,
    city: "Morens FR",
    cantonShort: "FR",
    latitude: 46.8456,
    longitude: 6.9047,
  },
  {
    zipCode: 1541,
    city: "S\u00e9vaz",
    cantonShort: "FR",
    latitude: 46.8394,
    longitude: 6.8736,
  },
  {
    zipCode: 1542,
    city: "Rueyres-les-Pr\u00e9s",
    cantonShort: "FR",
    latitude: 46.8571,
    longitude: 6.9109,
  },
  {
    zipCode: 1544,
    city: "Gletterens",
    cantonShort: "FR",
    latitude: 46.9113,
    longitude: 6.9241,
  },
  {
    zipCode: 1563,
    city: "Dompierre FR",
    cantonShort: "FR",
    latitude: 46.8564,
    longitude: 6.9906,
  },
  {
    zipCode: 1564,
    city: "Domdidier",
    cantonShort: "FR",
    latitude: 46.8622,
    longitude: 7.0145,
  },
  {
    zipCode: 1565,
    city: "Vallon",
    cantonShort: "FR",
    latitude: 46.8849,
    longitude: 6.9533,
  },
  {
    zipCode: 1566,
    city: "St-Aubin FR",
    cantonShort: "FR",
    latitude: 46.8912,
    longitude: 6.9795,
  },
  {
    zipCode: 1566,
    city: "Les Friques",
    cantonShort: "FR",
    latitude: 46.8966,
    longitude: 6.9942,
  },
  {
    zipCode: 1567,
    city: "Delley",
    cantonShort: "FR",
    latitude: 46.9132,
    longitude: 6.9717,
  },
  {
    zipCode: 1568,
    city: "Portalban",
    cantonShort: "FR",
    latitude: 46.9262,
    longitude: 6.9392,
  },
  {
    zipCode: 1773,
    city: "L\u00e9chelles",
    cantonShort: "FR",
    latitude: 46.8289,
    longitude: 7.0204,
  },
  {
    zipCode: 1773,
    city: "Chandon",
    cantonShort: "FR",
    latitude: 46.8411,
    longitude: 7.0436,
  },
  {
    zipCode: 1773,
    city: "Russy",
    cantonShort: "FR",
    latitude: 46.8408,
    longitude: 7.0052,
  },
  {
    zipCode: 1774,
    city: "Cousset",
    cantonShort: "FR",
    latitude: 46.8139,
    longitude: 6.98,
  },
  {
    zipCode: 1774,
    city: "Cousset",
    cantonShort: "FR",
    latitude: 46.8139,
    longitude: 6.98,
  },
  {
    zipCode: 1774,
    city: "Montagny-les-Monts",
    cantonShort: "FR",
    latitude: 46.8016,
    longitude: 6.9928,
  },
  {
    zipCode: 1775,
    city: "Mannens",
    cantonShort: "FR",
    latitude: 46.7969,
    longitude: 6.9702,
  },
  {
    zipCode: 1775,
    city: "Grandsivaz",
    cantonShort: "FR",
    latitude: 46.787,
    longitude: 6.9823,
  },
  {
    zipCode: 1776,
    city: "Montagny-la-Ville",
    cantonShort: "FR",
    latitude: 46.8207,
    longitude: 6.9989,
  },
  {
    zipCode: 1553,
    city: "Ch\u00e2tonnaye",
    cantonShort: "FR",
    latitude: 46.7531,
    longitude: 6.9382,
  },
  {
    zipCode: 1608,
    city: "Chapelle (Gl\u00e2ne)",
    cantonShort: "FR",
    latitude: 46.5879,
    longitude: 6.8377,
  },
  {
    zipCode: 1670,
    city: "Esmonts",
    cantonShort: "FR",
    latitude: 46.6445,
    longitude: 6.8469,
  },
  {
    zipCode: 1670,
    city: "Ursy",
    cantonShort: "FR",
    latitude: 46.6333,
    longitude: 6.8358,
  },
  {
    zipCode: 1670,
    city: "Bionnens",
    cantonShort: "FR",
    latitude: 46.635,
    longitude: 6.8564,
  },
  {
    zipCode: 1673,
    city: "Gillarens",
    cantonShort: "FR",
    latitude: 46.5879,
    longitude: 6.825,
  },
  {
    zipCode: 1673,
    city: "Promasens",
    cantonShort: "FR",
    latitude: 46.6022,
    longitude: 6.8226,
  },
  {
    zipCode: 1673,
    city: "Auboranges",
    cantonShort: "FR",
    latitude: 46.5838,
    longitude: 6.8051,
  },
  {
    zipCode: 1673,
    city: "Rue",
    cantonShort: "FR",
    latitude: 46.6219,
    longitude: 6.8237,
  },
  {
    zipCode: 1673,
    city: "Ecublens FR",
    cantonShort: "FR",
    latitude: 46.6153,
    longitude: 6.807,
  },
  {
    zipCode: 1674,
    city: "Vuarmarens",
    cantonShort: "FR",
    latitude: 46.6476,
    longitude: 6.8284,
  },
  {
    zipCode: 1674,
    city: "Montet (Gl\u00e2ne)",
    cantonShort: "FR",
    latitude: 46.6413,
    longitude: 6.813,
  },
  {
    zipCode: 1674,
    city: "Morlens",
    cantonShort: "FR",
    latitude: 46.6554,
    longitude: 6.8381,
  },
  {
    zipCode: 1675,
    city: "Blessens",
    cantonShort: "FR",
    latitude: 46.6077,
    longitude: 6.8395,
  },
  {
    zipCode: 1675,
    city: "Mossel",
    cantonShort: "FR",
    latitude: 46.6139,
    longitude: 6.8614,
  },
  {
    zipCode: 1675,
    city: "Vauderens",
    cantonShort: "FR",
    latitude: 46.6236,
    longitude: 6.8511,
  },
  {
    zipCode: 1676,
    city: "Chavannes-les-Forts",
    cantonShort: "FR",
    latitude: 46.6465,
    longitude: 6.8948,
  },
  {
    zipCode: 1677,
    city: "Prez-vers-Siviriez",
    cantonShort: "FR",
    latitude: 46.6352,
    longitude: 6.8745,
  },
  {
    zipCode: 1678,
    city: "Siviriez",
    cantonShort: "FR",
    latitude: 46.6604,
    longitude: 6.8774,
  },
  {
    zipCode: 1679,
    city: "Villaraboud",
    cantonShort: "FR",
    latitude: 46.66,
    longitude: 6.9128,
  },
  {
    zipCode: 1680,
    city: "Berlens",
    cantonShort: "FR",
    latitude: 46.6953,
    longitude: 6.9514,
  },
  {
    zipCode: 1680,
    city: "Romont FR",
    cantonShort: "FR",
    latitude: 46.7,
    longitude: 6.9186,
  },
  {
    zipCode: 1681,
    city: "Billens",
    cantonShort: "FR",
    latitude: 46.6897,
    longitude: 6.8962,
  },
  {
    zipCode: 1681,
    city: "Hennens",
    cantonShort: "FR",
    latitude: 46.6816,
    longitude: 6.8786,
  },
  {
    zipCode: 1684,
    city: "M\u00e9zi\u00e8res FR",
    cantonShort: "FR",
    latitude: 46.679,
    longitude: 6.9272,
  },
  {
    zipCode: 1685,
    city: "Villariaz",
    cantonShort: "FR",
    latitude: 46.6633,
    longitude: 6.9442,
  },
  {
    zipCode: 1686,
    city: "La Neirigue",
    cantonShort: "FR",
    latitude: 46.684,
    longitude: 6.9543,
  },
  {
    zipCode: 1686,
    city: "Grangettes-pr\u00e8s-Romont",
    cantonShort: "FR",
    latitude: 46.6717,
    longitude: 6.9753,
  },
  {
    zipCode: 1687,
    city: "Vuisternens-devant-Romont",
    cantonShort: "FR",
    latitude: 46.6502,
    longitude: 6.9325,
  },
  {
    zipCode: 1687,
    city: "La Magne",
    cantonShort: "FR",
    latitude: 46.6379,
    longitude: 6.9299,
  },
  {
    zipCode: 1687,
    city: "Est\u00e9venens",
    cantonShort: "FR",
    latitude: 46.6661,
    longitude: 6.9651,
  },
  {
    zipCode: 1688,
    city: "Lieffrens",
    cantonShort: "FR",
    latitude: 46.6329,
    longitude: 6.8988,
  },
  {
    zipCode: 1688,
    city: "Sommentier",
    cantonShort: "FR",
    latitude: 46.6361,
    longitude: 6.9144,
  },
  {
    zipCode: 1689,
    city: "Le Ch\u00e2telard-pr\u00e8s-Romont",
    cantonShort: "FR",
    latitude: 46.6755,
    longitude: 6.9949,
  },
  {
    zipCode: 1690,
    city: "Villaz-St-Pierre",
    cantonShort: "FR",
    latitude: 46.7209,
    longitude: 6.9621,
  },
  {
    zipCode: 1690,
    city: "Lussy FR",
    cantonShort: "FR",
    latitude: 46.7179,
    longitude: 6.9385,
  },
  {
    zipCode: 1691,
    city: "Villarimboud",
    cantonShort: "FR",
    latitude: 46.7409,
    longitude: 6.9661,
  },
  {
    zipCode: 1692,
    city: "Massonnens",
    cantonShort: "FR",
    latitude: 46.6966,
    longitude: 6.9761,
  },
  {
    zipCode: 1694,
    city: "Villarsiviriaux",
    cantonShort: "FR",
    latitude: 46.6904,
    longitude: 7.0186,
  },
  {
    zipCode: 1694,
    city: "Orsonnens",
    cantonShort: "FR",
    latitude: 46.7145,
    longitude: 6.9947,
  },
  {
    zipCode: 1694,
    city: "Chavannes-sous-Orsonnens",
    cantonShort: "FR",
    latitude: 46.728,
    longitude: 6.9891,
  },
  {
    zipCode: 1694,
    city: "Villargiroud",
    cantonShort: "FR",
    latitude: 46.6953,
    longitude: 7.0011,
  },
  {
    zipCode: 1697,
    city: "La Joux FR",
    cantonShort: "FR",
    latitude: 46.6259,
    longitude: 6.9364,
  },
  {
    zipCode: 1697,
    city: "Les Ecasseys",
    cantonShort: "FR",
    latitude: 46.6213,
    longitude: 6.9098,
  },
  {
    zipCode: 1748,
    city: "Torny-le-Grand",
    cantonShort: "FR",
    latitude: 46.7676,
    longitude: 6.9678,
  },
  {
    zipCode: 1749,
    city: "Middes",
    cantonShort: "FR",
    latitude: 46.7741,
    longitude: 6.9485,
  },
  {
    zipCode: 1625,
    city: "S\u00e2les (Gruy\u00e8re)",
    cantonShort: "FR",
    latitude: 46.6287,
    longitude: 6.9639,
  },
  {
    zipCode: 1625,
    city: "Maules",
    cantonShort: "FR",
    latitude: 46.643,
    longitude: 6.9968,
  },
  {
    zipCode: 1626,
    city: "Treyfayes",
    cantonShort: "FR",
    latitude: 46.6552,
    longitude: 6.9575,
  },
  {
    zipCode: 1626,
    city: "Rueyres-Treyfayes",
    cantonShort: "FR",
    latitude: 46.6444,
    longitude: 6.9534,
  },
  {
    zipCode: 1626,
    city: "Romanens",
    cantonShort: "FR",
    latitude: 46.6528,
    longitude: 6.9766,
  },
  {
    zipCode: 1627,
    city: "Vaulruz",
    cantonShort: "FR",
    latitude: 46.6186,
    longitude: 6.985,
  },
  {
    zipCode: 1628,
    city: "Vuadens",
    cantonShort: "FR",
    latitude: 46.6106,
    longitude: 7.0049,
  },
  {
    zipCode: 1630,
    city: "Bulle",
    cantonShort: "FR",
    latitude: 46.6021,
    longitude: 7.0223,
  },
  {
    zipCode: 1631,
    city: "Bulle centre de traitement",
    cantonShort: "FR",
    latitude: 46.6075,
    longitude: 7.0462,
  },
  {
    zipCode: 1632,
    city: "Riaz",
    cantonShort: "FR",
    latitude: 46.6428,
    longitude: 7.0432,
  },
  {
    zipCode: 1633,
    city: "Marsens",
    cantonShort: "FR",
    latitude: 46.6566,
    longitude: 7.039,
  },
  {
    zipCode: 1633,
    city: "Vuippens",
    cantonShort: "FR",
    latitude: 46.6632,
    longitude: 7.0812,
  },
  {
    zipCode: 1634,
    city: "La Roche FR",
    cantonShort: "FR",
    latitude: 46.6925,
    longitude: 7.1606,
  },
  {
    zipCode: 1635,
    city: "La Tour-de-Tr\u00eame",
    cantonShort: "FR",
    latitude: 46.5889,
    longitude: 7.0354,
  },
  {
    zipCode: 1636,
    city: "Broc",
    cantonShort: "FR",
    latitude: 46.5985,
    longitude: 7.1245,
  },
  {
    zipCode: 1637,
    city: "Charmey (Gruy\u00e8re)",
    cantonShort: "FR",
    latitude: 46.594,
    longitude: 7.1959,
  },
  {
    zipCode: 1638,
    city: "Morlon",
    cantonShort: "FR",
    latitude: 46.6247,
    longitude: 7.0917,
  },
  {
    zipCode: 1642,
    city: "Sorens",
    cantonShort: "FR",
    latitude: 46.6713,
    longitude: 7.0437,
  },
  {
    zipCode: 1643,
    city: "Gumefens",
    cantonShort: "FR",
    latitude: 46.6778,
    longitude: 7.0722,
  },
  {
    zipCode: 1644,
    city: "Avry-devant-Pont",
    cantonShort: "FR",
    latitude: 46.6865,
    longitude: 7.0835,
  },
  {
    zipCode: 1645,
    city: "Le Bry",
    cantonShort: "FR",
    latitude: 46.6986,
    longitude: 7.0826,
  },
  {
    zipCode: 1646,
    city: "Echarlens",
    cantonShort: "FR",
    latitude: 46.6472,
    longitude: 7.0839,
  },
  {
    zipCode: 1647,
    city: "Corbi\u00e8res",
    cantonShort: "FR",
    latitude: 46.6567,
    longitude: 7.1175,
  },
  {
    zipCode: 1648,
    city: "Hauteville",
    cantonShort: "FR",
    latitude: 46.6699,
    longitude: 7.1308,
  },
  {
    zipCode: 1649,
    city: "Pont-la-Ville",
    cantonShort: "FR",
    latitude: 46.7026,
    longitude: 7.1117,
  },
  {
    zipCode: 1651,
    city: "Villarvolard",
    cantonShort: "FR",
    latitude: 46.6435,
    longitude: 7.118,
  },
  {
    zipCode: 1652,
    city: "Villarbeney",
    cantonShort: "FR",
    latitude: 46.6306,
    longitude: 7.1157,
  },
  {
    zipCode: 1652,
    city: "Botterens",
    cantonShort: "FR",
    latitude: 46.6185,
    longitude: 7.1133,
  },
  {
    zipCode: 1653,
    city: "Cr\u00e9suz",
    cantonShort: "FR",
    latitude: 46.6225,
    longitude: 7.1413,
  },
  {
    zipCode: 1653,
    city: "Ch\u00e2tel-sur-Montsalvens",
    cantonShort: "FR",
    latitude: 46.6177,
    longitude: 7.1275,
  },
  {
    zipCode: 1654,
    city: "Cerniat FR",
    cantonShort: "FR",
    latitude: 46.6657,
    longitude: 7.2019,
  },
  {
    zipCode: 1656,
    city: "Im Fang",
    cantonShort: "FR",
    latitude: 46.5913,
    longitude: 7.2374,
  },
  {
    zipCode: 1656,
    city: "Jaun",
    cantonShort: "FR",
    latitude: 46.6075,
    longitude: 7.2873,
  },
  {
    zipCode: 1661,
    city: "Le P\u00e2quier-Montbarry",
    cantonShort: "FR",
    latitude: 46.5904,
    longitude: 7.0519,
  },
  {
    zipCode: 1663,
    city: "Gruy\u00e8res",
    cantonShort: "FR",
    latitude: 46.5807,
    longitude: 7.085,
  },
  {
    zipCode: 1663,
    city: "Mol\u00e9son-sur-Gruy\u00e8res",
    cantonShort: "FR",
    latitude: 46.5567,
    longitude: 7.0281,
  },
  {
    zipCode: 1663,
    city: "Epagny",
    cantonShort: "FR",
    latitude: 46.5883,
    longitude: 7.0939,
  },
  {
    zipCode: 1663,
    city: "Pringy",
    cantonShort: "FR",
    latitude: 46.577,
    longitude: 7.0594,
  },
  {
    zipCode: 1665,
    city: "Estavannens",
    cantonShort: "FR",
    latitude: 46.5638,
    longitude: 7.1304,
  },
  {
    zipCode: 1666,
    city: "Grandvillard",
    cantonShort: "FR",
    latitude: 46.5281,
    longitude: 7.1095,
  },
  {
    zipCode: 1666,
    city: "Villars-sous-Mont",
    cantonShort: "FR",
    latitude: 46.5442,
    longitude: 7.0578,
  },
  {
    zipCode: 1667,
    city: "Enney",
    cantonShort: "FR",
    latitude: 46.562,
    longitude: 7.07,
  },
  {
    zipCode: 1669,
    city: "Neirivue",
    cantonShort: "FR",
    latitude: 46.5291,
    longitude: 7.0303,
  },
  {
    zipCode: 1669,
    city: "Les Sciernes-d'Albeuve",
    cantonShort: "FR",
    latitude: 46.4975,
    longitude: 7.0365,
  },
  {
    zipCode: 1669,
    city: "Montbovon",
    cantonShort: "FR",
    latitude: 46.4723,
    longitude: 7.012,
  },
  {
    zipCode: 1669,
    city: "Albeuve",
    cantonShort: "FR",
    latitude: 46.508,
    longitude: 7.0266,
  },
  {
    zipCode: 1669,
    city: "Lessoc",
    cantonShort: "FR",
    latitude: 46.5051,
    longitude: 7.0789,
  },
  {
    zipCode: 1695,
    city: "Villarlod",
    cantonShort: "FR",
    latitude: 46.6975,
    longitude: 7.0309,
  },
  {
    zipCode: 1695,
    city: "Rueyres-St-Laurent",
    cantonShort: "FR",
    latitude: 46.7133,
    longitude: 7.0377,
  },
  {
    zipCode: 1695,
    city: "Villarsel-le-Gibloux",
    cantonShort: "FR",
    latitude: 46.7133,
    longitude: 7.0151,
  },
  {
    zipCode: 1695,
    city: "Estavayer-le-Gibloux",
    cantonShort: "FR",
    latitude: 46.7244,
    longitude: 7.0244,
  },
  {
    zipCode: 1696,
    city: "Vuisternens-en-Ogoz",
    cantonShort: "FR",
    latitude: 46.7042,
    longitude: 7.0561,
  },
  {
    zipCode: 1700,
    city: "Fribourg",
    cantonShort: "FR",
    latitude: 46.8067,
    longitude: 7.1636,
  },
  {
    zipCode: 1701,
    city: "Fribourg",
    cantonShort: "FR",
    latitude: 46.8024,
    longitude: 7.1513,
  },
  {
    zipCode: 1708,
    city: "Fribourg",
    cantonShort: "FR",
    latitude: 46.8024,
    longitude: 7.1513,
  },
  {
    zipCode: 1720,
    city: "Corminboeuf",
    cantonShort: "FR",
    latitude: 46.8049,
    longitude: 7.0973,
  },
  {
    zipCode: 1720,
    city: "Ch\u00e9sopelloz",
    cantonShort: "FR",
    latitude: 46.8059,
    longitude: 7.0769,
  },
  {
    zipCode: 1722,
    city: "Bourguillon",
    cantonShort: "FR",
    latitude: 46.7988,
    longitude: 7.1748,
  },
  {
    zipCode: 1723,
    city: "Pierrafortscha",
    cantonShort: "FR",
    latitude: 46.7812,
    longitude: 7.1807,
  },
  {
    zipCode: 1723,
    city: "Marly 1",
    cantonShort: "FR",
    latitude: 46.776,
    longitude: 7.1492,
  },
  {
    zipCode: 1723,
    city: "Villarsel-sur-Marly",
    cantonShort: "FR",
    latitude: 46.7624,
    longitude: 7.1727,
  },
  {
    zipCode: 1723,
    city: "Marly",
    cantonShort: "FR",
    latitude: 46.776,
    longitude: 7.1494,
  },
  {
    zipCode: 1724,
    city: "Bonnefontaine",
    cantonShort: "FR",
    latitude: 46.7309,
    longitude: 7.2048,
  },
  {
    zipCode: 1724,
    city: "Mont\u00e9vraz",
    cantonShort: "FR",
    latitude: 46.7214,
    longitude: 7.1807,
  },
  {
    zipCode: 1724,
    city: "Sen\u00e8des",
    cantonShort: "FR",
    latitude: 46.742,
    longitude: 7.1432,
  },
  {
    zipCode: 1724,
    city: "Essert FR",
    cantonShort: "FR",
    latitude: 46.7361,
    longitude: 7.1624,
  },
  {
    zipCode: 1724,
    city: "Ferpicloz",
    cantonShort: "FR",
    latitude: 46.7475,
    longitude: 7.1637,
  },
  {
    zipCode: 1724,
    city: "Le Mouret",
    cantonShort: "FR",
    latitude: 46.7514,
    longitude: 7.1812,
  },
  {
    zipCode: 1724,
    city: "Oberried FR",
    cantonShort: "FR",
    latitude: 46.7264,
    longitude: 7.1938,
  },
  {
    zipCode: 1724,
    city: "Z\u00e9nauva",
    cantonShort: "FR",
    latitude: 46.7229,
    longitude: 7.1868,
  },
  {
    zipCode: 1725,
    city: "Posieux",
    cantonShort: "FR",
    latitude: 46.7715,
    longitude: 7.108,
  },
  {
    zipCode: 1726,
    city: "Farvagny-le-Petit",
    cantonShort: "FR",
    latitude: 46.7309,
    longitude: 7.0743,
  },
  {
    zipCode: 1726,
    city: "Grenilles",
    cantonShort: "FR",
    latitude: 46.7274,
    longitude: 7.0487,
  },
  {
    zipCode: 1726,
    city: "Farvagny-le-Grand",
    cantonShort: "FR",
    latitude: 46.7183,
    longitude: 7.0717,
  },
  {
    zipCode: 1726,
    city: "Posat",
    cantonShort: "FR",
    latitude: 46.7406,
    longitude: 7.061,
  },
  {
    zipCode: 1726,
    city: "Farvagny",
    cantonShort: "FR",
    latitude: 46.7277,
    longitude: 7.0663,
  },
  {
    zipCode: 1727,
    city: "Corpataux",
    cantonShort: "FR",
    latitude: 46.7427,
    longitude: 7.0982,
  },
  {
    zipCode: 1727,
    city: "Magnedens",
    cantonShort: "FR",
    latitude: 46.7411,
    longitude: 7.0794,
  },
  {
    zipCode: 1728,
    city: "Rossens FR",
    cantonShort: "FR",
    latitude: 46.723,
    longitude: 7.1,
  },
  {
    zipCode: 1730,
    city: "Ecuvillens",
    cantonShort: "FR",
    latitude: 46.7555,
    longitude: 7.0784,
  },
  {
    zipCode: 1731,
    city: "Ependes FR",
    cantonShort: "FR",
    latitude: 46.7571,
    longitude: 7.1488,
  },
  {
    zipCode: 1732,
    city: "Arconciel",
    cantonShort: "FR",
    latitude: 46.7512,
    longitude: 7.1212,
  },
  {
    zipCode: 1733,
    city: "Treyvaux",
    cantonShort: "FR",
    latitude: 46.7241,
    longitude: 7.1438,
  },
  {
    zipCode: 1740,
    city: "Neyruz FR",
    cantonShort: "FR",
    latitude: 46.768,
    longitude: 7.0649,
  },
  {
    zipCode: 1741,
    city: "Cottens FR",
    cantonShort: "FR",
    latitude: 46.7532,
    longitude: 7.0362,
  },
  {
    zipCode: 1742,
    city: "Autigny",
    cantonShort: "FR",
    latitude: 46.7393,
    longitude: 7.0283,
  },
  {
    zipCode: 1744,
    city: "Ch\u00e9nens",
    cantonShort: "FR",
    latitude: 46.7432,
    longitude: 6.9966,
  },
  {
    zipCode: 1745,
    city: "Lentigny",
    cantonShort: "FR",
    latitude: 46.7599,
    longitude: 7.0019,
  },
  {
    zipCode: 1746,
    city: "Prez-vers-Nor\u00e9az",
    cantonShort: "FR",
    latitude: 46.7857,
    longitude: 7.0128,
  },
  {
    zipCode: 1747,
    city: "Corserey",
    cantonShort: "FR",
    latitude: 46.7719,
    longitude: 6.989,
  },
  {
    zipCode: 1752,
    city: "Villars-sur-Gl\u00e2ne",
    cantonShort: "FR",
    latitude: 46.7943,
    longitude: 7.1252,
  },
  {
    zipCode: 1752,
    city: "Villars-sur-Gl\u00e2ne 1",
    cantonShort: "FR",
    latitude: 46.7944,
    longitude: 7.1251,
  },
  {
    zipCode: 1753,
    city: "Matran",
    cantonShort: "FR",
    latitude: 46.7869,
    longitude: 7.0916,
  },
  {
    zipCode: 1754,
    city: "Avry-Centre FR",
    cantonShort: "FR",
    latitude: 46.7881,
    longitude: 7.0603,
  },
  {
    zipCode: 1754,
    city: "Ros\u00e9",
    cantonShort: "FR",
    latitude: 46.784,
    longitude: 7.0629,
  },
  {
    zipCode: 1754,
    city: "Corjolens",
    cantonShort: "FR",
    latitude: 46.7863,
    longitude: 7.0425,
  },
  {
    zipCode: 1754,
    city: "Avry-sur-Matran",
    cantonShort: "FR",
    latitude: 46.7886,
    longitude: 7.066,
  },
  {
    zipCode: 1756,
    city: "Onnens FR",
    cantonShort: "FR",
    latitude: 46.7724,
    longitude: 7.0377,
  },
  {
    zipCode: 1756,
    city: "Lovens",
    cantonShort: "FR",
    latitude: 46.7726,
    longitude: 7.0189,
  },
  {
    zipCode: 1757,
    city: "Nor\u00e9az",
    cantonShort: "FR",
    latitude: 46.8019,
    longitude: 7.0365,
  },
  {
    zipCode: 1762,
    city: "Givisiez",
    cantonShort: "FR",
    latitude: 46.8127,
    longitude: 7.1255,
  },
  {
    zipCode: 1763,
    city: "Granges-Paccot",
    cantonShort: "FR",
    latitude: 46.8236,
    longitude: 7.1483,
  },
  {
    zipCode: 1772,
    city: "Ponthaux",
    cantonShort: "FR",
    latitude: 46.817,
    longitude: 7.0411,
  },
  {
    zipCode: 1772,
    city: "Nierlet-les-Bois",
    cantonShort: "FR",
    latitude: 46.8169,
    longitude: 7.0581,
  },
  {
    zipCode: 1772,
    city: "Grolley",
    cantonShort: "FR",
    latitude: 46.8347,
    longitude: 7.0658,
  },
  {
    zipCode: 1782,
    city: "La Corbaz",
    cantonShort: "FR",
    latitude: 46.8412,
    longitude: 7.1161,
  },
  {
    zipCode: 1782,
    city: "Formangueires",
    cantonShort: "FR",
    latitude: 46.8279,
    longitude: 7.1224,
  },
  {
    zipCode: 1782,
    city: "Autafond",
    cantonShort: "FR",
    latitude: 46.8162,
    longitude: 7.0759,
  },
  {
    zipCode: 1782,
    city: "Cormagens",
    cantonShort: "FR",
    latitude: 46.8359,
    longitude: 7.1377,
  },
  {
    zipCode: 1782,
    city: "Lossy",
    cantonShort: "FR",
    latitude: 46.8347,
    longitude: 7.1061,
  },
  {
    zipCode: 1782,
    city: "Belfaux",
    cantonShort: "FR",
    latitude: 46.8288,
    longitude: 7.095,
  },
  {
    zipCode: 1583,
    city: "Villarepos",
    cantonShort: "FR",
    latitude: 46.8823,
    longitude: 7.08,
  },
  {
    zipCode: 1721,
    city: "Corm\u00e9rod",
    cantonShort: "FR",
    latitude: 46.8667,
    longitude: 7.0857,
  },
  {
    zipCode: 1721,
    city: "Misery-Courtion",
    cantonShort: "FR",
    latitude: 46.8582,
    longitude: 7.0849,
  },
  {
    zipCode: 1721,
    city: "Misery",
    cantonShort: "FR",
    latitude: 46.851,
    longitude: 7.0682,
  },
  {
    zipCode: 1721,
    city: "Cournillens",
    cantonShort: "FR",
    latitude: 46.8555,
    longitude: 7.1002,
  },
  {
    zipCode: 1721,
    city: "Courtion",
    cantonShort: "FR",
    latitude: 46.8595,
    longitude: 7.0705,
  },
  {
    zipCode: 1783,
    city: "Barber\u00eache",
    cantonShort: "FR",
    latitude: 46.8638,
    longitude: 7.1604,
  },
  {
    zipCode: 1783,
    city: "Pensier",
    cantonShort: "FR",
    latitude: 46.8471,
    longitude: 7.1292,
  },
  {
    zipCode: 1784,
    city: "Courtepin",
    cantonShort: "FR",
    latitude: 46.8615,
    longitude: 7.1242,
  },
  {
    zipCode: 1784,
    city: "Wallenried",
    cantonShort: "FR",
    latitude: 46.876,
    longitude: 7.1116,
  },
  {
    zipCode: 1785,
    city: "Cressier FR",
    cantonShort: "FR",
    latitude: 46.898,
    longitude: 7.1379,
  },
  {
    zipCode: 1786,
    city: "Sugiez",
    cantonShort: "FR",
    latitude: 46.9687,
    longitude: 7.112,
  },
  {
    zipCode: 1787,
    city: "M\u00f4tier (Vully)",
    cantonShort: "FR",
    latitude: 46.9393,
    longitude: 7.0807,
  },
  {
    zipCode: 1787,
    city: "Mur (Vully) FR",
    cantonShort: "FR",
    latitude: 46.9456,
    longitude: 7.0643,
  },
  {
    zipCode: 1788,
    city: "Praz (Vully)",
    cantonShort: "FR",
    latitude: 46.9495,
    longitude: 7.1032,
  },
  {
    zipCode: 1789,
    city: "Lugnorre",
    cantonShort: "FR",
    latitude: 46.9586,
    longitude: 7.0733,
  },
  {
    zipCode: 1791,
    city: "Courtaman",
    cantonShort: "FR",
    latitude: 46.8711,
    longitude: 7.1316,
  },
  {
    zipCode: 1792,
    city: "Guschelmuth",
    cantonShort: "FR",
    latitude: 46.8848,
    longitude: 7.1397,
  },
  {
    zipCode: 1792,
    city: "Cordast",
    cantonShort: "FR",
    latitude: 46.8733,
    longitude: 7.1512,
  },
  {
    zipCode: 1793,
    city: "Jeuss",
    cantonShort: "FR",
    latitude: 46.9124,
    longitude: 7.1744,
  },
  {
    zipCode: 1794,
    city: "Salvenach",
    cantonShort: "FR",
    latitude: 46.9141,
    longitude: 7.1523,
  },
  {
    zipCode: 1795,
    city: "Courlevon",
    cantonShort: "FR",
    latitude: 46.891,
    longitude: 7.1105,
  },
  {
    zipCode: 1796,
    city: "Courgevaux",
    cantonShort: "FR",
    latitude: 46.9043,
    longitude: 7.1047,
  },
  {
    zipCode: 3206,
    city: "Wallenbuch",
    cantonShort: "FR",
    latitude: 46.9318,
    longitude: 7.2249,
  },
  {
    zipCode: 3210,
    city: "Kerzers",
    cantonShort: "FR",
    latitude: 46.9806,
    longitude: 7.1959,
  },
  {
    zipCode: 3212,
    city: "Gurmels",
    cantonShort: "FR",
    latitude: 46.8903,
    longitude: 7.1725,
  },
  {
    zipCode: 3212,
    city: "Kleingurmels",
    cantonShort: "FR",
    latitude: 46.8845,
    longitude: 7.187,
  },
  {
    zipCode: 3213,
    city: "Kleinb\u00f6singen",
    cantonShort: "FR",
    latitude: 46.893,
    longitude: 7.2024,
  },
  {
    zipCode: 3213,
    city: "Liebistorf",
    cantonShort: "FR",
    latitude: 46.9113,
    longitude: 7.1931,
  },
  {
    zipCode: 3214,
    city: "Ulmiz",
    cantonShort: "FR",
    latitude: 46.9299,
    longitude: 7.1986,
  },
  {
    zipCode: 3215,
    city: "Gempenach",
    cantonShort: "FR",
    latitude: 46.9432,
    longitude: 7.1986,
  },
  {
    zipCode: 3215,
    city: "Lurtigen",
    cantonShort: "FR",
    latitude: 46.9284,
    longitude: 7.1722,
  },
  {
    zipCode: 3215,
    city: "B\u00fcchslen",
    cantonShort: "FR",
    latitude: 46.9449,
    longitude: 7.1787,
  },
  {
    zipCode: 3216,
    city: "Agriswil",
    cantonShort: "FR",
    latitude: 46.9544,
    longitude: 7.2013,
  },
  {
    zipCode: 3216,
    city: "Ried b. Kerzers",
    cantonShort: "FR",
    latitude: 46.9653,
    longitude: 7.1762,
  },
  {
    zipCode: 3280,
    city: "Murten",
    cantonShort: "FR",
    latitude: 46.9304,
    longitude: 7.1335,
  },
  {
    zipCode: 3280,
    city: "Greng",
    cantonShort: "FR",
    latitude: 46.9191,
    longitude: 7.0865,
  },
  {
    zipCode: 3280,
    city: "Meyriez",
    cantonShort: "FR",
    latitude: 46.9288,
    longitude: 7.1007,
  },
  {
    zipCode: 3284,
    city: "Fr\u00e4schels",
    cantonShort: "FR",
    latitude: 46.9979,
    longitude: 7.2082,
  },
  {
    zipCode: 3285,
    city: "Galmiz",
    cantonShort: "FR",
    latitude: 46.9599,
    longitude: 7.1466,
  },
  {
    zipCode: 3286,
    city: "Muntelier",
    cantonShort: "FR",
    latitude: 46.9424,
    longitude: 7.128,
  },
  {
    zipCode: 1712,
    city: "Tafers",
    cantonShort: "FR",
    latitude: 46.8126,
    longitude: 7.2246,
  },
  {
    zipCode: 1713,
    city: "St. Antoni",
    cantonShort: "FR",
    latitude: 46.8192,
    longitude: 7.2671,
  },
  {
    zipCode: 1714,
    city: "Heitenried",
    cantonShort: "FR",
    latitude: 46.8279,
    longitude: 7.3,
  },
  {
    zipCode: 1715,
    city: "Alterswil FR",
    cantonShort: "FR",
    latitude: 46.7892,
    longitude: 7.274,
  },
  {
    zipCode: 1716,
    city: "Schwarzsee",
    cantonShort: "FR",
    latitude: 46.6724,
    longitude: 7.2892,
  },
  {
    zipCode: 1716,
    city: "Oberschrot",
    cantonShort: "FR",
    latitude: 46.7369,
    longitude: 7.2697,
  },
  {
    zipCode: 1716,
    city: "Plaffeien",
    cantonShort: "FR",
    latitude: 46.7271,
    longitude: 7.2874,
  },
  {
    zipCode: 1717,
    city: "St. Ursen",
    cantonShort: "FR",
    latitude: 46.7884,
    longitude: 7.2194,
  },
  {
    zipCode: 1718,
    city: "Rechthalten",
    cantonShort: "FR",
    latitude: 46.7653,
    longitude: 7.2482,
  },
  {
    zipCode: 1719,
    city: "Br\u00fcnisried",
    cantonShort: "FR",
    latitude: 46.7573,
    longitude: 7.2764,
  },
  {
    zipCode: 1719,
    city: "Zumholz",
    cantonShort: "FR",
    latitude: 46.7566,
    longitude: 7.2937,
  },
  {
    zipCode: 1734,
    city: "Tentlingen",
    cantonShort: "FR",
    latitude: 46.7676,
    longitude: 7.2,
  },
  {
    zipCode: 1735,
    city: "Giffers",
    cantonShort: "FR",
    latitude: 46.7549,
    longitude: 7.2246,
  },
  {
    zipCode: 1736,
    city: "St. Silvester",
    cantonShort: "FR",
    latitude: 46.738,
    longitude: 7.2209,
  },
  {
    zipCode: 1737,
    city: "Plasselb",
    cantonShort: "FR",
    latitude: 46.7116,
    longitude: 7.2436,
  },
  {
    zipCode: 3175,
    city: "Flamatt",
    cantonShort: "FR",
    latitude: 46.889,
    longitude: 7.3199,
  },
  {
    zipCode: 3178,
    city: "B\u00f6singen",
    cantonShort: "FR",
    latitude: 46.8853,
    longitude: 7.234,
  },
  {
    zipCode: 3182,
    city: "Ueberstorf",
    cantonShort: "FR",
    latitude: 46.8694,
    longitude: 7.3262,
  },
  {
    zipCode: 3184,
    city: "W\u00fcnnewil",
    cantonShort: "FR",
    latitude: 46.8757,
    longitude: 7.2791,
  },
  {
    zipCode: 3185,
    city: "Schmitten FR",
    cantonShort: "FR",
    latitude: 46.8504,
    longitude: 7.247,
  },
  {
    zipCode: 3186,
    city: "D\u00fcdingen",
    cantonShort: "FR",
    latitude: 46.8462,
    longitude: 7.1915,
  },
  {
    zipCode: 1609,
    city: "St-Martin FR",
    cantonShort: "FR",
    latitude: 46.5742,
    longitude: 6.882,
  },
  {
    zipCode: 1609,
    city: "Besencens",
    cantonShort: "FR",
    latitude: 46.5878,
    longitude: 6.8685,
  },
  {
    zipCode: 1609,
    city: "Fiaug\u00e8res",
    cantonShort: "FR",
    latitude: 46.5894,
    longitude: 6.8914,
  },
  {
    zipCode: 1609,
    city: "Fiaug\u00e8res",
    cantonShort: "FR",
    latitude: 46.5894,
    longitude: 6.8914,
  },
  {
    zipCode: 1611,
    city: "Le Cr\u00eat-pr\u00e8s-Semsales",
    cantonShort: "FR",
    latitude: 46.607,
    longitude: 6.919,
  },
  {
    zipCode: 1614,
    city: "Granges (Veveyse)",
    cantonShort: "FR",
    latitude: 46.523,
    longitude: 6.8263,
  },
  {
    zipCode: 1615,
    city: "Bossonnens",
    cantonShort: "FR",
    latitude: 46.5289,
    longitude: 6.8481,
  },
  {
    zipCode: 1616,
    city: "Attalens",
    cantonShort: "FR",
    latitude: 46.5078,
    longitude: 6.8541,
  },
  {
    zipCode: 1617,
    city: "Remaufens",
    cantonShort: "FR",
    latitude: 46.5371,
    longitude: 6.8826,
  },
  {
    zipCode: 1617,
    city: "Tatroz",
    cantonShort: "FR",
    latitude: 46.5285,
    longitude: 6.8671,
  },
  {
    zipCode: 1618,
    city: "Ch\u00e2tel-St-Denis",
    cantonShort: "FR",
    latitude: 46.5344,
    longitude: 6.937,
  },
  {
    zipCode: 1619,
    city: "Les Paccots",
    cantonShort: "FR",
    latitude: 46.5073,
    longitude: 6.9663,
  },
  {
    zipCode: 1623,
    city: "Semsales",
    cantonShort: "FR",
    latitude: 46.5699,
    longitude: 6.9517,
  },
  {
    zipCode: 1624,
    city: "Grattavache",
    cantonShort: "FR",
    latitude: 46.5964,
    longitude: 6.9267,
  },
  {
    zipCode: 1624,
    city: "Progens",
    cantonShort: "FR",
    latitude: 46.5857,
    longitude: 6.9184,
  },
  {
    zipCode: 1624,
    city: "Progens",
    cantonShort: "FR",
    latitude: 46.5857,
    longitude: 6.9184,
  },
  {
    zipCode: 1624,
    city: "La Verrerie",
    cantonShort: "FR",
    latitude: 46.5878,
    longitude: 6.9211,
  },
  {
    zipCode: 1699,
    city: "Pont (Veveyse)",
    cantonShort: "FR",
    latitude: 46.5913,
    longitude: 6.8522,
  },
  {
    zipCode: 1699,
    city: "Porsel",
    cantonShort: "FR",
    latitude: 46.6018,
    longitude: 6.8706,
  },
  {
    zipCode: 1699,
    city: "Bouloz",
    cantonShort: "FR",
    latitude: 46.6148,
    longitude: 6.8877,
  },
  {
    zipCode: 1699,
    city: "Porsel",
    cantonShort: "FR",
    latitude: 46.6018,
    longitude: 6.8706,
  },
  {
    zipCode: 1200,
    city: "Gen\u00e8ve",
    cantonShort: "GE",
    latitude: 46.2022,
    longitude: 6.1457,
  },
  {
    zipCode: 1201,
    city: "Gen\u00e8ve",
    cantonShort: "GE",
    latitude: 46.2107,
    longitude: 6.1469,
  },
  {
    zipCode: 1202,
    city: "Gen\u00e8ve",
    cantonShort: "GE",
    latitude: 46.221,
    longitude: 6.1432,
  },
  {
    zipCode: 1203,
    city: "Gen\u00e8ve",
    cantonShort: "GE",
    latitude: 46.2079,
    longitude: 6.1221,
  },
  {
    zipCode: 1204,
    city: "Gen\u00e8ve",
    cantonShort: "GE",
    latitude: 46.2027,
    longitude: 6.1457,
  },
  {
    zipCode: 1205,
    city: "Gen\u00e8ve",
    cantonShort: "GE",
    latitude: 46.1961,
    longitude: 6.1403,
  },
  {
    zipCode: 1206,
    city: "Gen\u00e8ve",
    cantonShort: "GE",
    latitude: 46.1894,
    longitude: 6.1574,
  },
  {
    zipCode: 1207,
    city: "Gen\u00e8ve",
    cantonShort: "GE",
    latitude: 46.2066,
    longitude: 6.1624,
  },
  {
    zipCode: 1208,
    city: "Gen\u00e8ve",
    cantonShort: "GE",
    latitude: 46.1984,
    longitude: 6.1676,
  },
  {
    zipCode: 1209,
    city: "Gen\u00e8ve",
    cantonShort: "GE",
    latitude: 46.2215,
    longitude: 6.1232,
  },
  {
    zipCode: 1211,
    city: "Gen\u00e8ve 3",
    cantonShort: "GE",
    latitude: 46.2058,
    longitude: 6.1416,
  },
  {
    zipCode: 1211,
    city: "Gen\u00e8ve 80",
    cantonShort: "GE",
    latitude: 46.2058,
    longitude: 6.1416,
  },
  {
    zipCode: 1211,
    city: "Gen\u00e8ve 1",
    cantonShort: "GE",
    latitude: 46.2058,
    longitude: 6.1416,
  },
  {
    zipCode: 1211,
    city: "Gen\u00e8ve 27",
    cantonShort: "GE",
    latitude: 46.2379,
    longitude: 6.1424,
  },
  {
    zipCode: 1211,
    city: "Gen\u00e8ve 19",
    cantonShort: "GE",
    latitude: 46.2058,
    longitude: 6.1416,
  },
  {
    zipCode: 1211,
    city: "Gen\u00e8ve 8",
    cantonShort: "GE",
    latitude: 46.2058,
    longitude: 6.1416,
  },
  {
    zipCode: 1211,
    city: "Gen\u00e8ve 28",
    cantonShort: "GE",
    latitude: 46.2058,
    longitude: 6.1416,
  },
  {
    zipCode: 1211,
    city: "Gen\u00e8ve 70",
    cantonShort: "GE",
    latitude: 46.2058,
    longitude: 6.1416,
  },
  {
    zipCode: 1211,
    city: "Gen\u00e8ve 13",
    cantonShort: "GE",
    latitude: 46.2058,
    longitude: 6.1416,
  },
  {
    zipCode: 1211,
    city: "Gen\u00e8ve 22",
    cantonShort: "GE",
    latitude: 46.2058,
    longitude: 6.1416,
  },
  {
    zipCode: 1211,
    city: "Gen\u00e8ve 84 Votation",
    cantonShort: "GE",
    latitude: 46.2058,
    longitude: 6.1416,
  },
  {
    zipCode: 1211,
    city: "Gen\u00e8ve 26",
    cantonShort: "GE",
    latitude: 46.1897,
    longitude: 6.1158,
  },
  {
    zipCode: 1211,
    city: "Gen\u00e8ve 73",
    cantonShort: "GE",
    latitude: 46.2058,
    longitude: 6.1416,
  },
  {
    zipCode: 1211,
    city: "Gen\u00e8ve 14",
    cantonShort: "GE",
    latitude: 46.2058,
    longitude: 6.1416,
  },
  {
    zipCode: 1211,
    city: "Gen\u00e8ve 2",
    cantonShort: "GE",
    latitude: 46.2058,
    longitude: 6.1416,
  },
  {
    zipCode: 1211,
    city: "Gen\u00e8ve 6",
    cantonShort: "GE",
    latitude: 46.2058,
    longitude: 6.1416,
  },
  {
    zipCode: 1211,
    city: "Gen\u00e8ve 12",
    cantonShort: "GE",
    latitude: 46.2058,
    longitude: 6.1416,
  },
  {
    zipCode: 1211,
    city: "Gen\u00e8ve 84 Votations",
    cantonShort: "GE",
    latitude: 46.2058,
    longitude: 6.1416,
  },
  {
    zipCode: 1211,
    city: "Gen\u00e8ve 11",
    cantonShort: "GE",
    latitude: 46.2058,
    longitude: 6.1416,
  },
  {
    zipCode: 1211,
    city: "Gen\u00e8ve 23",
    cantonShort: "GE",
    latitude: 46.2322,
    longitude: 6.0791,
  },
  {
    zipCode: 1211,
    city: "Gen\u00e8ve 4",
    cantonShort: "GE",
    latitude: 46.2058,
    longitude: 6.1416,
  },
  {
    zipCode: 1211,
    city: "Gen\u00e8ve 71 CS CP",
    cantonShort: "GE",
    latitude: 46.2058,
    longitude: 6.1416,
  },
  {
    zipCode: 1211,
    city: "Gen\u00e8ve 5",
    cantonShort: "GE",
    latitude: 46.2358,
    longitude: 6.1192,
  },
  {
    zipCode: 1211,
    city: "Gen\u00e8ve 20",
    cantonShort: "GE",
    latitude: 46.2058,
    longitude: 6.1416,
  },
  {
    zipCode: 1211,
    city: "Gen\u00e8ve 10",
    cantonShort: "GE",
    latitude: 46.2058,
    longitude: 6.1416,
  },
  {
    zipCode: 1212,
    city: "Grand-Lancy",
    cantonShort: "GE",
    latitude: 46.178,
    longitude: 6.122,
  },
  {
    zipCode: 1212,
    city: "Grand-Lancy 1",
    cantonShort: "GE",
    latitude: 46.1897,
    longitude: 6.1158,
  },
  {
    zipCode: 1213,
    city: "Petit-Lancy 1",
    cantonShort: "GE",
    latitude: 46.1897,
    longitude: 6.1158,
  },
  {
    zipCode: 1213,
    city: "Onex",
    cantonShort: "GE",
    latitude: 46.1854,
    longitude: 6.1002,
  },
  {
    zipCode: 1213,
    city: "Petit-Lancy",
    cantonShort: "GE",
    latitude: 46.1939,
    longitude: 6.1159,
  },
  {
    zipCode: 1214,
    city: "Vernier",
    cantonShort: "GE",
    latitude: 46.2139,
    longitude: 6.0796,
  },
  {
    zipCode: 1215,
    city: "Gen\u00e8ve 15 A\u00e9roport",
    cantonShort: "GE",
    latitude: 46.2347,
    longitude: 6.1064,
  },
  {
    zipCode: 1215,
    city: "Gen\u00e8ve",
    cantonShort: "GE",
    latitude: 46.2358,
    longitude: 6.1192,
  },
  {
    zipCode: 1216,
    city: "Cointrin",
    cantonShort: "GE",
    latitude: 46.224,
    longitude: 6.1062,
  },
  {
    zipCode: 1217,
    city: "Meyrin 1",
    cantonShort: "GE",
    latitude: 46.2322,
    longitude: 6.0791,
  },
  {
    zipCode: 1217,
    city: "Meyrin",
    cantonShort: "GE",
    latitude: 46.2339,
    longitude: 6.0743,
  },
  {
    zipCode: 1218,
    city: "Le Grand-Saconnex",
    cantonShort: "GE",
    latitude: 46.2338,
    longitude: 6.1221,
  },
  {
    zipCode: 1219,
    city: "A\u00efre",
    cantonShort: "GE",
    latitude: 46.1986,
    longitude: 6.0985,
  },
  {
    zipCode: 1219,
    city: "Le Lignon",
    cantonShort: "GE",
    latitude: 46.2067,
    longitude: 6.0984,
  },
  {
    zipCode: 1219,
    city: "Ch\u00e2telaine",
    cantonShort: "GE",
    latitude: 46.2138,
    longitude: 6.1087,
  },
  {
    zipCode: 1220,
    city: "Les Avanchets",
    cantonShort: "GE",
    latitude: 46.2207,
    longitude: 6.1091,
  },
  {
    zipCode: 1222,
    city: "V\u00e9senaz",
    cantonShort: "GE",
    latitude: 46.2454,
    longitude: 6.2008,
  },
  {
    zipCode: 1223,
    city: "Cologny",
    cantonShort: "GE",
    latitude: 46.2228,
    longitude: 6.1808,
  },
  {
    zipCode: 1224,
    city: "Ch\u00eane-Bougeries",
    cantonShort: "GE",
    latitude: 46.1995,
    longitude: 6.1858,
  },
  {
    zipCode: 1225,
    city: "Ch\u00eane-Bourg",
    cantonShort: "GE",
    latitude: 46.1974,
    longitude: 6.197,
  },
  {
    zipCode: 1226,
    city: "Th\u00f4nex",
    cantonShort: "GE",
    latitude: 46.1961,
    longitude: 6.2046,
  },
  {
    zipCode: 1227,
    city: "Carouge GE",
    cantonShort: "GE",
    latitude: 46.1817,
    longitude: 6.1403,
  },
  {
    zipCode: 1227,
    city: "Les Acacias",
    cantonShort: "GE",
    latitude: 46.1926,
    longitude: 6.1321,
  },
  {
    zipCode: 1228,
    city: "Plan-les-Ouates",
    cantonShort: "GE",
    latitude: 46.1642,
    longitude: 6.118,
  },
  {
    zipCode: 1231,
    city: "Conches",
    cantonShort: "GE",
    latitude: 46.1855,
    longitude: 6.1759,
  },
  {
    zipCode: 1232,
    city: "Confignon",
    cantonShort: "GE",
    latitude: 46.1743,
    longitude: 6.0888,
  },
  {
    zipCode: 1233,
    city: "Bernex",
    cantonShort: "GE",
    latitude: 46.1785,
    longitude: 6.0686,
  },
  {
    zipCode: 1234,
    city: "Vessy",
    cantonShort: "GE",
    latitude: 46.1742,
    longitude: 6.159,
  },
  {
    zipCode: 1236,
    city: "Cartigny",
    cantonShort: "GE",
    latitude: 46.1759,
    longitude: 6.0309,
  },
  {
    zipCode: 1237,
    city: "Avully",
    cantonShort: "GE",
    latitude: 46.1675,
    longitude: 5.9985,
  },
  {
    zipCode: 1239,
    city: "Collex",
    cantonShort: "GE",
    latitude: 46.2796,
    longitude: 6.1212,
  },
  {
    zipCode: 1240,
    city: "Gen\u00e8ve",
    cantonShort: "GE",
    latitude: 46.2022,
    longitude: 6.1457,
  },
  {
    zipCode: 1241,
    city: "Puplinge",
    cantonShort: "GE",
    latitude: 46.2108,
    longitude: 6.2322,
  },
  {
    zipCode: 1242,
    city: "Satigny",
    cantonShort: "GE",
    latitude: 46.2143,
    longitude: 6.035,
  },
  {
    zipCode: 1243,
    city: "Presinge",
    cantonShort: "GE",
    latitude: 46.218,
    longitude: 6.2602,
  },
  {
    zipCode: 1244,
    city: "Choulex",
    cantonShort: "GE",
    latitude: 46.2263,
    longitude: 6.2238,
  },
  {
    zipCode: 1245,
    city: "Collonge-Bellerive",
    cantonShort: "GE",
    latitude: 46.2587,
    longitude: 6.1965,
  },
  {
    zipCode: 1246,
    city: "Corsier GE",
    cantonShort: "GE",
    latitude: 46.2659,
    longitude: 6.2191,
  },
  {
    zipCode: 1247,
    city: "Ani\u00e8res",
    cantonShort: "GE",
    latitude: 46.2808,
    longitude: 6.2226,
  },
  {
    zipCode: 1248,
    city: "Hermance",
    cantonShort: "GE",
    latitude: 46.2988,
    longitude: 6.2325,
  },
  {
    zipCode: 1251,
    city: "Gy",
    cantonShort: "GE",
    latitude: 46.2519,
    longitude: 6.27,
  },
  {
    zipCode: 1252,
    city: "Meinier",
    cantonShort: "GE",
    latitude: 46.243,
    longitude: 6.2351,
  },
  {
    zipCode: 1253,
    city: "Vandoeuvres",
    cantonShort: "GE",
    latitude: 46.219,
    longitude: 6.2037,
  },
  {
    zipCode: 1254,
    city: "Jussy",
    cantonShort: "GE",
    latitude: 46.2372,
    longitude: 6.2791,
  },
  {
    zipCode: 1255,
    city: "Veyrier",
    cantonShort: "GE",
    latitude: 46.1684,
    longitude: 6.1769,
  },
  {
    zipCode: 1256,
    city: "Troinex",
    cantonShort: "GE",
    latitude: 46.1593,
    longitude: 6.1506,
  },
  {
    zipCode: 1257,
    city: "La Croix-de-Rozon",
    cantonShort: "GE",
    latitude: 46.149,
    longitude: 6.1207,
  },
  {
    zipCode: 1258,
    city: "Perly",
    cantonShort: "GE",
    latitude: 46.1575,
    longitude: 6.0889,
  },
  {
    zipCode: 1281,
    city: "Russin",
    cantonShort: "GE",
    latitude: 46.1939,
    longitude: 6.0139,
  },
  {
    zipCode: 1283,
    city: "Dardagny",
    cantonShort: "GE",
    latitude: 46.2002,
    longitude: 5.9878,
  },
  {
    zipCode: 1283,
    city: "La Plaine",
    cantonShort: "GE",
    latitude: 46.1792,
    longitude: 6.0032,
  },
  {
    zipCode: 1284,
    city: "Chancy",
    cantonShort: "GE",
    latitude: 46.1475,
    longitude: 5.9787,
  },
  {
    zipCode: 1285,
    city: "Athenaz (Avusy)",
    cantonShort: "GE",
    latitude: 46.1496,
    longitude: 6.0086,
  },
  {
    zipCode: 1286,
    city: "Soral",
    cantonShort: "GE",
    latitude: 46.1481,
    longitude: 6.0474,
  },
  {
    zipCode: 1287,
    city: "Laconnex",
    cantonShort: "GE",
    latitude: 46.1589,
    longitude: 6.0341,
  },
  {
    zipCode: 1288,
    city: "Aire-la-Ville",
    cantonShort: "GE",
    latitude: 46.1902,
    longitude: 6.0422,
  },
  {
    zipCode: 1290,
    city: "Versoix",
    cantonShort: "GE",
    latitude: 46.2877,
    longitude: 6.1559,
  },
  {
    zipCode: 1292,
    city: "Chamb\u00e9sy",
    cantonShort: "GE",
    latitude: 46.2375,
    longitude: 6.1511,
  },
  {
    zipCode: 1293,
    city: "Bellevue",
    cantonShort: "GE",
    latitude: 46.2545,
    longitude: 6.1455,
  },
  {
    zipCode: 1294,
    city: "Genthod",
    cantonShort: "GE",
    latitude: 46.2637,
    longitude: 6.1614,
  },
  {
    zipCode: 1298,
    city: "C\u00e9ligny",
    cantonShort: "GE",
    latitude: 46.3473,
    longitude: 6.2052,
  },
  {
    zipCode: 1298,
    city: "C\u00e9ligny",
    cantonShort: "GE",
    latitude: 46.3473,
    longitude: 6.2052,
  },
  {
    zipCode: 8750,
    city: "Glarus",
    cantonShort: "GL",
    latitude: 47.0369,
    longitude: 9.0546,
  },
  {
    zipCode: 8750,
    city: "Riedern",
    cantonShort: "GL",
    latitude: 47.0496,
    longitude: 9.0394,
  },
  {
    zipCode: 8750,
    city: "Kl\u00f6ntal",
    cantonShort: "GL",
    latitude: 47.019,
    longitude: 8.9682,
  },
  {
    zipCode: 8752,
    city: "N\u00e4fels",
    cantonShort: "GL",
    latitude: 47.0865,
    longitude: 9.0053,
  },
  {
    zipCode: 8753,
    city: "Mollis",
    cantonShort: "GL",
    latitude: 47.0938,
    longitude: 9.0914,
  },
  {
    zipCode: 8754,
    city: "Netstal",
    cantonShort: "GL",
    latitude: 47.0638,
    longitude: 9.0417,
  },
  {
    zipCode: 8755,
    city: "Ennenda",
    cantonShort: "GL",
    latitude: 47.0445,
    longitude: 9.0986,
  },
  {
    zipCode: 8756,
    city: "Mitl\u00f6di",
    cantonShort: "GL",
    latitude: 47.0154,
    longitude: 9.0805,
  },
  {
    zipCode: 8757,
    city: "Filzbach",
    cantonShort: "GL",
    latitude: 47.1015,
    longitude: 9.1253,
  },
  {
    zipCode: 8758,
    city: "Obstalden",
    cantonShort: "GL",
    latitude: 47.0772,
    longitude: 9.1536,
  },
  {
    zipCode: 8759,
    city: "Netstal",
    cantonShort: "GL",
    latitude: 47.0634,
    longitude: 9.0573,
  },
  {
    zipCode: 8762,
    city: "Schwanden GL",
    cantonShort: "GL",
    latitude: 46.9738,
    longitude: 9.0849,
  },
  {
    zipCode: 8762,
    city: "Sool",
    cantonShort: "GL",
    latitude: 47.0125,
    longitude: 9.1249,
  },
  {
    zipCode: 8762,
    city: "Schw\u00e4ndi b. Schwanden",
    cantonShort: "GL",
    latitude: 47.0131,
    longitude: 9.0564,
  },
  {
    zipCode: 8765,
    city: "Engi",
    cantonShort: "GL",
    latitude: 46.9983,
    longitude: 9.1692,
  },
  {
    zipCode: 8766,
    city: "Matt",
    cantonShort: "GL",
    latitude: 46.9713,
    longitude: 9.198,
  },
  {
    zipCode: 8767,
    city: "Elm",
    cantonShort: "GL",
    latitude: 46.9061,
    longitude: 9.1564,
  },
  {
    zipCode: 8772,
    city: "Nidfurn",
    cantonShort: "GL",
    latitude: 46.9876,
    longitude: 9.0435,
  },
  {
    zipCode: 8773,
    city: "Haslen GL",
    cantonShort: "GL",
    latitude: 46.9595,
    longitude: 9.0726,
  },
  {
    zipCode: 8774,
    city: "Leuggelbach",
    cantonShort: "GL",
    latitude: 46.9787,
    longitude: 9.0363,
  },
  {
    zipCode: 8775,
    city: "Luchsingen-H\u00e4tzingen",
    cantonShort: "GL",
    latitude: 46.9271,
    longitude: 9.0779,
  },
  {
    zipCode: 8775,
    city: "Luchsingen",
    cantonShort: "GL",
    latitude: 46.9764,
    longitude: 9.0012,
  },
  {
    zipCode: 8775,
    city: "H\u00e4tzingen",
    cantonShort: "GL",
    latitude: 46.9603,
    longitude: 9.0493,
  },
  {
    zipCode: 8777,
    city: "Betschwanden",
    cantonShort: "GL",
    latitude: 46.9283,
    longitude: 9.0549,
  },
  {
    zipCode: 8777,
    city: "Diesbach GL",
    cantonShort: "GL",
    latitude: 46.944,
    longitude: 9.0511,
  },
  {
    zipCode: 8782,
    city: "R\u00fcti GL",
    cantonShort: "GL",
    latitude: 46.9316,
    longitude: 9.0211,
  },
  {
    zipCode: 8783,
    city: "Linthal",
    cantonShort: "GL",
    latitude: 46.8631,
    longitude: 8.979,
  },
  {
    zipCode: 8784,
    city: "Braunwald",
    cantonShort: "GL",
    latitude: 46.9458,
    longitude: 8.9845,
  },
  {
    zipCode: 8865,
    city: "Bilten",
    cantonShort: "GL",
    latitude: 47.1479,
    longitude: 9.0078,
  },
  {
    zipCode: 8867,
    city: "Niederurnen",
    cantonShort: "GL",
    latitude: 47.1265,
    longitude: 9.0208,
  },
  {
    zipCode: 8868,
    city: "Oberurnen",
    cantonShort: "GL",
    latitude: 47.1153,
    longitude: 9.0366,
  },
  {
    zipCode: 8874,
    city: "M\u00fchlehorn",
    cantonShort: "GL",
    latitude: 47.1027,
    longitude: 9.1749,
  },
  {
    zipCode: 7077,
    city: "Valbella",
    cantonShort: "GR",
    latitude: 46.7522,
    longitude: 9.5502,
  },
  {
    zipCode: 7078,
    city: "Lenzerheide/Lai",
    cantonShort: "GR",
    latitude: 46.7317,
    longitude: 9.5481,
  },
  {
    zipCode: 7082,
    city: "Vaz/Obervaz",
    cantonShort: "GR",
    latitude: 46.6974,
    longitude: 9.5183,
  },
  {
    zipCode: 7083,
    city: "Lantsch/Lenz",
    cantonShort: "GR",
    latitude: 46.707,
    longitude: 9.5794,
  },
  {
    zipCode: 7084,
    city: "Brienz/Brinzauls GR",
    cantonShort: "GR",
    latitude: 46.6837,
    longitude: 9.6028,
  },
  {
    zipCode: 7450,
    city: "Tiefencastel",
    cantonShort: "GR",
    latitude: 46.6483,
    longitude: 9.6037,
  },
  {
    zipCode: 7450,
    city: "Tiefencastel",
    cantonShort: "GR",
    latitude: 46.6483,
    longitude: 9.6037,
  },
  {
    zipCode: 7451,
    city: "Alvaschein",
    cantonShort: "GR",
    latitude: 46.6778,
    longitude: 9.5467,
  },
  {
    zipCode: 7452,
    city: "Cunter",
    cantonShort: "GR",
    latitude: 46.6202,
    longitude: 9.6043,
  },
  {
    zipCode: 7453,
    city: "Tinizong",
    cantonShort: "GR",
    latitude: 46.5868,
    longitude: 9.6608,
  },
  {
    zipCode: 7454,
    city: "Rona",
    cantonShort: "GR",
    latitude: 46.5567,
    longitude: 9.6608,
  },
  {
    zipCode: 7455,
    city: "Mulegns",
    cantonShort: "GR",
    latitude: 46.5044,
    longitude: 9.5795,
  },
  {
    zipCode: 7456,
    city: "Marmorera",
    cantonShort: "GR",
    latitude: 46.4997,
    longitude: 9.6473,
  },
  {
    zipCode: 7456,
    city: "Sur",
    cantonShort: "GR",
    latitude: 46.528,
    longitude: 9.6632,
  },
  {
    zipCode: 7457,
    city: "Bivio",
    cantonShort: "GR",
    latitude: 46.4578,
    longitude: 9.6618,
  },
  {
    zipCode: 7458,
    city: "Mon",
    cantonShort: "GR",
    latitude: 46.6487,
    longitude: 9.5484,
  },
  {
    zipCode: 7459,
    city: "Stierva",
    cantonShort: "GR",
    latitude: 46.657,
    longitude: 9.5204,
  },
  {
    zipCode: 7460,
    city: "Savognin",
    cantonShort: "GR",
    latitude: 46.5814,
    longitude: 9.6024,
  },
  {
    zipCode: 7462,
    city: "Salouf",
    cantonShort: "GR",
    latitude: 46.6124,
    longitude: 9.533,
  },
  {
    zipCode: 7463,
    city: "Riom",
    cantonShort: "GR",
    latitude: 46.6224,
    longitude: 9.5892,
  },
  {
    zipCode: 7463,
    city: "Riom",
    cantonShort: "GR",
    latitude: 46.6224,
    longitude: 9.5892,
  },
  {
    zipCode: 7464,
    city: "Parsonz",
    cantonShort: "GR",
    latitude: 46.5567,
    longitude: 9.5423,
  },
  {
    zipCode: 7472,
    city: "Surava",
    cantonShort: "GR",
    latitude: 46.6572,
    longitude: 9.6172,
  },
  {
    zipCode: 7473,
    city: "Alvaneu Bad",
    cantonShort: "GR",
    latitude: 46.6686,
    longitude: 9.6491,
  },
  {
    zipCode: 7477,
    city: "Filisur",
    cantonShort: "GR",
    latitude: 46.6488,
    longitude: 9.6882,
  },
  {
    zipCode: 7482,
    city: "Preda",
    cantonShort: "GR",
    latitude: 46.587,
    longitude: 9.7729,
  },
  {
    zipCode: 7482,
    city: "Berg\u00fcn/Bravuogn",
    cantonShort: "GR",
    latitude: 46.6389,
    longitude: 9.8055,
  },
  {
    zipCode: 7482,
    city: "Stugl/Stuls",
    cantonShort: "GR",
    latitude: 46.6575,
    longitude: 9.7426,
  },
  {
    zipCode: 7484,
    city: "Latsch",
    cantonShort: "GR",
    latitude: 46.6377,
    longitude: 9.7553,
  },
  {
    zipCode: 7492,
    city: "Alvaneu Dorf",
    cantonShort: "GR",
    latitude: 46.7156,
    longitude: 9.6443,
  },
  {
    zipCode: 7493,
    city: "Schmitten (Albula)",
    cantonShort: "GR",
    latitude: 46.7042,
    longitude: 9.6778,
  },
  {
    zipCode: 7710,
    city: "Ospizio Bernina",
    cantonShort: "GR",
    latitude: 46.3952,
    longitude: 10.0196,
  },
  {
    zipCode: 7710,
    city: "Alp Gr\u00fcm",
    cantonShort: "GR",
    latitude: 46.375,
    longitude: 10.0326,
  },
  {
    zipCode: 7741,
    city: "S. Carlo (Poschiavo)",
    cantonShort: "GR",
    latitude: 46.3567,
    longitude: 10.082,
  },
  {
    zipCode: 7742,
    city: "La R\u00f6sa",
    cantonShort: "GR",
    latitude: 46.4087,
    longitude: 10.0774,
  },
  {
    zipCode: 7742,
    city: "Sfaz\u00f9",
    cantonShort: "GR",
    latitude: 46.3989,
    longitude: 10.1197,
  },
  {
    zipCode: 7742,
    city: "Poschiavo",
    cantonShort: "GR",
    latitude: 46.3379,
    longitude: 10.0388,
  },
  {
    zipCode: 7743,
    city: "Miralago",
    cantonShort: "GR",
    latitude: 46.2731,
    longitude: 10.1009,
  },
  {
    zipCode: 7743,
    city: "Brusio",
    cantonShort: "GR",
    latitude: 46.2753,
    longitude: 10.1232,
  },
  {
    zipCode: 7744,
    city: "Campocologno",
    cantonShort: "GR",
    latitude: 46.2362,
    longitude: 10.142,
  },
  {
    zipCode: 7745,
    city: "Li Curt",
    cantonShort: "GR",
    latitude: 46.2988,
    longitude: 10.0336,
  },
  {
    zipCode: 7746,
    city: "Le Prese",
    cantonShort: "GR",
    latitude: 46.2834,
    longitude: 10.0863,
  },
  {
    zipCode: 7747,
    city: "Viano",
    cantonShort: "GR",
    latitude: 46.2612,
    longitude: 10.1523,
  },
  {
    zipCode: 7748,
    city: "Campascio",
    cantonShort: "GR",
    latitude: 46.2381,
    longitude: 10.0953,
  },
  {
    zipCode: 7527,
    city: "Brail",
    cantonShort: "GR",
    latitude: 46.6765,
    longitude: 10.0189,
  },
  {
    zipCode: 7530,
    city: "Zernez",
    cantonShort: "GR",
    latitude: 46.6705,
    longitude: 10.1404,
  },
  {
    zipCode: 7532,
    city: "Tschierv",
    cantonShort: "GR",
    latitude: 46.6422,
    longitude: 10.2981,
  },
  {
    zipCode: 7533,
    city: "Fuldera",
    cantonShort: "GR",
    latitude: 46.6048,
    longitude: 10.3451,
  },
  {
    zipCode: 7534,
    city: "L\u00fc",
    cantonShort: "GR",
    latitude: 46.6355,
    longitude: 10.3724,
  },
  {
    zipCode: 7535,
    city: "Valchava",
    cantonShort: "GR",
    latitude: 46.6022,
    longitude: 10.3859,
  },
  {
    zipCode: 7536,
    city: "Sta. Maria Val M\u00fcstair",
    cantonShort: "GR",
    latitude: 46.5785,
    longitude: 10.3704,
  },
  {
    zipCode: 7537,
    city: "M\u00fcstair",
    cantonShort: "GR",
    latitude: 46.615,
    longitude: 10.4555,
  },
  {
    zipCode: 7542,
    city: "Susch",
    cantonShort: "GR",
    latitude: 46.751,
    longitude: 10.0223,
  },
  {
    zipCode: 7543,
    city: "Lavin",
    cantonShort: "GR",
    latitude: 46.7828,
    longitude: 10.1073,
  },
  {
    zipCode: 7545,
    city: "Guarda",
    cantonShort: "GR",
    latitude: 46.8081,
    longitude: 10.1422,
  },
  {
    zipCode: 7546,
    city: "Ardez",
    cantonShort: "GR",
    latitude: 46.7698,
    longitude: 10.1857,
  },
  {
    zipCode: 7550,
    city: "Scuol",
    cantonShort: "GR",
    latitude: 46.7346,
    longitude: 10.3263,
  },
  {
    zipCode: 7551,
    city: "Ftan",
    cantonShort: "GR",
    latitude: 46.8259,
    longitude: 10.2279,
  },
  {
    zipCode: 7552,
    city: "Vulpera",
    cantonShort: "GR",
    latitude: 46.7873,
    longitude: 10.286,
  },
  {
    zipCode: 7553,
    city: "Tarasp",
    cantonShort: "GR",
    latitude: 46.7396,
    longitude: 10.2465,
  },
  {
    zipCode: 7554,
    city: "Crusch",
    cantonShort: "GR",
    latitude: 46.7346,
    longitude: 10.3264,
  },
  {
    zipCode: 7554,
    city: "Sent",
    cantonShort: "GR",
    latitude: 46.8251,
    longitude: 10.3404,
  },
  {
    zipCode: 7556,
    city: "Ramosch",
    cantonShort: "GR",
    latitude: 46.8583,
    longitude: 10.33,
  },
  {
    zipCode: 7556,
    city: "Ramosch",
    cantonShort: "GR",
    latitude: 46.8583,
    longitude: 10.33,
  },
  {
    zipCode: 7557,
    city: "Vn\u00e0",
    cantonShort: "GR",
    latitude: 46.8802,
    longitude: 10.3512,
  },
  {
    zipCode: 7558,
    city: "Strada",
    cantonShort: "GR",
    latitude: 46.8407,
    longitude: 10.4386,
  },
  {
    zipCode: 7559,
    city: "Tschlin",
    cantonShort: "GR",
    latitude: 46.9061,
    longitude: 10.4097,
  },
  {
    zipCode: 7560,
    city: "Martina",
    cantonShort: "GR",
    latitude: 46.9159,
    longitude: 10.4553,
  },
  {
    zipCode: 7562,
    city: "Samnaun-Compatsch",
    cantonShort: "GR",
    latitude: 46.9633,
    longitude: 10.4023,
  },
  {
    zipCode: 7563,
    city: "Samnaun Dorf",
    cantonShort: "GR",
    latitude: 46.9496,
    longitude: 10.358,
  },
  {
    zipCode: 7012,
    city: "Felsberg",
    cantonShort: "GR",
    latitude: 46.8564,
    longitude: 9.4625,
  },
  {
    zipCode: 7013,
    city: "Domat/Ems",
    cantonShort: "GR",
    latitude: 46.8197,
    longitude: 9.4547,
  },
  {
    zipCode: 7014,
    city: "Trin",
    cantonShort: "GR",
    latitude: 46.8686,
    longitude: 9.3291,
  },
  {
    zipCode: 7015,
    city: "Tamins",
    cantonShort: "GR",
    latitude: 46.844,
    longitude: 9.4086,
  },
  {
    zipCode: 7016,
    city: "Trin Mulin",
    cantonShort: "GR",
    latitude: 46.8224,
    longitude: 9.3327,
  },
  {
    zipCode: 7017,
    city: "Flims Dorf",
    cantonShort: "GR",
    latitude: 46.864,
    longitude: 9.2694,
  },
  {
    zipCode: 7018,
    city: "Flims Waldhaus",
    cantonShort: "GR",
    latitude: 46.8248,
    longitude: 9.2871,
  },
  {
    zipCode: 7019,
    city: "Fidaz",
    cantonShort: "GR",
    latitude: 46.8455,
    longitude: 9.319,
  },
  {
    zipCode: 7402,
    city: "Bonaduz",
    cantonShort: "GR",
    latitude: 46.7939,
    longitude: 9.3699,
  },
  {
    zipCode: 7403,
    city: "Rh\u00e4z\u00fcns",
    cantonShort: "GR",
    latitude: 46.7862,
    longitude: 9.3922,
  },
  {
    zipCode: 7202,
    city: "Says",
    cantonShort: "GR",
    latitude: 46.9013,
    longitude: 9.597,
  },
  {
    zipCode: 7203,
    city: "Trimmis",
    cantonShort: "GR",
    latitude: 46.8892,
    longitude: 9.5728,
  },
  {
    zipCode: 7204,
    city: "Untervaz",
    cantonShort: "GR",
    latitude: 46.9206,
    longitude: 9.5159,
  },
  {
    zipCode: 7205,
    city: "Zizers",
    cantonShort: "GR",
    latitude: 46.9343,
    longitude: 9.5726,
  },
  {
    zipCode: 7206,
    city: "Igis",
    cantonShort: "GR",
    latitude: 46.9452,
    longitude: 9.5807,
  },
  {
    zipCode: 7208,
    city: "Malans GR",
    cantonShort: "GR",
    latitude: 46.9888,
    longitude: 9.5803,
  },
  {
    zipCode: 7302,
    city: "Landquart",
    cantonShort: "GR",
    latitude: 46.9616,
    longitude: 9.5772,
  },
  {
    zipCode: 7303,
    city: "Mastrils",
    cantonShort: "GR",
    latitude: 46.9589,
    longitude: 9.5295,
  },
  {
    zipCode: 7304,
    city: "Maienfeld",
    cantonShort: "GR",
    latitude: 47.0305,
    longitude: 9.5629,
  },
  {
    zipCode: 7306,
    city: "Fl\u00e4sch",
    cantonShort: "GR",
    latitude: 47.0395,
    longitude: 9.5053,
  },
  {
    zipCode: 7307,
    city: "Jenins",
    cantonShort: "GR",
    latitude: 47.0117,
    longitude: 9.5744,
  },
  {
    zipCode: 7500,
    city: "St. Moritz 1",
    cantonShort: "GR",
    latitude: 46.4903,
    longitude: 9.8169,
  },
  {
    zipCode: 7500,
    city: "St. Moritz 3",
    cantonShort: "GR",
    latitude: 46.4903,
    longitude: 9.8169,
  },
  {
    zipCode: 7500,
    city: "St. Moritz",
    cantonShort: "GR",
    latitude: 46.4905,
    longitude: 9.8174,
  },
  {
    zipCode: 7502,
    city: "Bever",
    cantonShort: "GR",
    latitude: 46.543,
    longitude: 9.8005,
  },
  {
    zipCode: 7502,
    city: "Bever",
    cantonShort: "GR",
    latitude: 46.543,
    longitude: 9.8005,
  },
  {
    zipCode: 7503,
    city: "Samedan",
    cantonShort: "GR",
    latitude: 46.5332,
    longitude: 9.8492,
  },
  {
    zipCode: 7504,
    city: "Pontresina",
    cantonShort: "GR",
    latitude: 46.4311,
    longitude: 9.9249,
  },
  {
    zipCode: 7505,
    city: "Celerina/Schlarigna",
    cantonShort: "GR",
    latitude: 46.5097,
    longitude: 9.8436,
  },
  {
    zipCode: 7512,
    city: "Champf\u00e8r",
    cantonShort: "GR",
    latitude: 46.4766,
    longitude: 9.8058,
  },
  {
    zipCode: 7513,
    city: "Silvaplana-Surlej",
    cantonShort: "GR",
    latitude: 46.4447,
    longitude: 9.82,
  },
  {
    zipCode: 7513,
    city: "Silvaplana",
    cantonShort: "GR",
    latitude: 46.474,
    longitude: 9.7609,
  },
  {
    zipCode: 7514,
    city: "Fex",
    cantonShort: "GR",
    latitude: 46.3835,
    longitude: 9.7899,
  },
  {
    zipCode: 7514,
    city: "Sils/Segl Maria",
    cantonShort: "GR",
    latitude: 46.4347,
    longitude: 9.7786,
  },
  {
    zipCode: 7515,
    city: "Sils/Segl Baselgia",
    cantonShort: "GR",
    latitude: 46.4371,
    longitude: 9.7424,
  },
  {
    zipCode: 7516,
    city: "Maloja",
    cantonShort: "GR",
    latitude: 46.3649,
    longitude: 9.7199,
  },
  {
    zipCode: 7517,
    city: "Plaun da Lej",
    cantonShort: "GR",
    latitude: 46.4271,
    longitude: 9.7091,
  },
  {
    zipCode: 7522,
    city: "La Punt-Chamues-ch",
    cantonShort: "GR",
    latitude: 46.5789,
    longitude: 9.9201,
  },
  {
    zipCode: 7522,
    city: "La Punt Chamues-ch",
    cantonShort: "GR",
    latitude: 46.5508,
    longitude: 9.9302,
  },
  {
    zipCode: 7523,
    city: "Madulain",
    cantonShort: "GR",
    latitude: 46.546,
    longitude: 9.9659,
  },
  {
    zipCode: 7523,
    city: "Madulain",
    cantonShort: "GR",
    latitude: 46.546,
    longitude: 9.9659,
  },
  {
    zipCode: 7524,
    city: "Zuoz",
    cantonShort: "GR",
    latitude: 46.5631,
    longitude: 9.9802,
  },
  {
    zipCode: 7524,
    city: "Zuoz",
    cantonShort: "GR",
    latitude: 46.5631,
    longitude: 9.9802,
  },
  {
    zipCode: 7525,
    city: "S-chanf",
    cantonShort: "GR",
    latitude: 46.5971,
    longitude: 10.0385,
  },
  {
    zipCode: 7526,
    city: "Cinuos-chel",
    cantonShort: "GR",
    latitude: 46.6429,
    longitude: 10.0222,
  },
  {
    zipCode: 7526,
    city: "Chapella",
    cantonShort: "GR",
    latitude: 46.6557,
    longitude: 9.9374,
  },
  {
    zipCode: 7602,
    city: "Casaccia",
    cantonShort: "GR",
    latitude: 46.3882,
    longitude: 9.6568,
  },
  {
    zipCode: 7603,
    city: "Vicosoprano",
    cantonShort: "GR",
    latitude: 46.3505,
    longitude: 9.6285,
  },
  {
    zipCode: 7604,
    city: "Borgonovo",
    cantonShort: "GR",
    latitude: 46.3477,
    longitude: 9.6062,
  },
  {
    zipCode: 7605,
    city: "Stampa",
    cantonShort: "GR",
    latitude: 46.3396,
    longitude: 9.5922,
  },
  {
    zipCode: 7606,
    city: "Bondo",
    cantonShort: "GR",
    latitude: 46.3147,
    longitude: 9.5798,
  },
  {
    zipCode: 7606,
    city: "Promontogno",
    cantonShort: "GR",
    latitude: 46.3393,
    longitude: 9.5573,
  },
  {
    zipCode: 7608,
    city: "Castasegna",
    cantonShort: "GR",
    latitude: 46.335,
    longitude: 9.5234,
  },
  {
    zipCode: 7610,
    city: "Soglio",
    cantonShort: "GR",
    latitude: 46.357,
    longitude: 9.5381,
  },
  {
    zipCode: 6534,
    city: "S. Vittore",
    cantonShort: "GR",
    latitude: 46.2444,
    longitude: 9.0981,
  },
  {
    zipCode: 6534,
    city: "S. Vittore",
    cantonShort: "GR",
    latitude: 46.2444,
    longitude: 9.0981,
  },
  {
    zipCode: 6535,
    city: "Roveredo GR",
    cantonShort: "GR",
    latitude: 46.208,
    longitude: 9.1525,
  },
  {
    zipCode: 6537,
    city: "Grono",
    cantonShort: "GR",
    latitude: 46.2327,
    longitude: 9.1814,
  },
  {
    zipCode: 6538,
    city: "Verdabbio",
    cantonShort: "GR",
    latitude: 46.2731,
    longitude: 9.2032,
  },
  {
    zipCode: 6538,
    city: "Verdabbio",
    cantonShort: "GR",
    latitude: 46.2731,
    longitude: 9.2032,
  },
  {
    zipCode: 6540,
    city: "Castaneda",
    cantonShort: "GR",
    latitude: 46.2609,
    longitude: 9.1301,
  },
  {
    zipCode: 6541,
    city: "Sta. Maria in Calanca",
    cantonShort: "GR",
    latitude: 46.2835,
    longitude: 9.148,
  },
  {
    zipCode: 6542,
    city: "Buseno",
    cantonShort: "GR",
    latitude: 46.2759,
    longitude: 9.1034,
  },
  {
    zipCode: 6543,
    city: "Arvigo",
    cantonShort: "GR",
    latitude: 46.3011,
    longitude: 9.0946,
  },
  {
    zipCode: 6544,
    city: "Braggio",
    cantonShort: "GR",
    latitude: 46.302,
    longitude: 9.1333,
  },
  {
    zipCode: 6545,
    city: "Selma",
    cantonShort: "GR",
    latitude: 46.3221,
    longitude: 9.1032,
  },
  {
    zipCode: 6546,
    city: "Cauco",
    cantonShort: "GR",
    latitude: 46.3336,
    longitude: 9.1221,
  },
  {
    zipCode: 6548,
    city: "Rossa",
    cantonShort: "GR",
    latitude: 46.404,
    longitude: 9.127,
  },
  {
    zipCode: 6549,
    city: "Laura",
    cantonShort: "GR",
    latitude: 46.2108,
    longitude: 9.1047,
  },
  {
    zipCode: 6556,
    city: "Leggia",
    cantonShort: "GR",
    latitude: 46.2502,
    longitude: 9.1872,
  },
  {
    zipCode: 6557,
    city: "Cama",
    cantonShort: "GR",
    latitude: 46.2607,
    longitude: 9.2064,
  },
  {
    zipCode: 6558,
    city: "Lostallo",
    cantonShort: "GR",
    latitude: 46.3068,
    longitude: 9.2247,
  },
  {
    zipCode: 6562,
    city: "Soazza",
    cantonShort: "GR",
    latitude: 46.3515,
    longitude: 9.2259,
  },
  {
    zipCode: 6563,
    city: "Mesocco",
    cantonShort: "GR",
    latitude: 46.4025,
    longitude: 9.226,
  },
  {
    zipCode: 6565,
    city: "S. Bernardino",
    cantonShort: "GR",
    latitude: 46.4713,
    longitude: 9.1888,
  },
  {
    zipCode: 7000,
    city: "Chur",
    cantonShort: "GR",
    latitude: 46.8528,
    longitude: 9.5299,
  },
  {
    zipCode: 7001,
    city: "Chur",
    cantonShort: "GR",
    latitude: 46.8499,
    longitude: 9.5329,
  },
  {
    zipCode: 7004,
    city: "Chur",
    cantonShort: "GR",
    latitude: 46.8499,
    longitude: 9.5329,
  },
  {
    zipCode: 7006,
    city: "Chur",
    cantonShort: "GR",
    latitude: 46.8499,
    longitude: 9.5329,
  },
  {
    zipCode: 7007,
    city: "Chur",
    cantonShort: "GR",
    latitude: 46.8499,
    longitude: 9.5329,
  },
  {
    zipCode: 7023,
    city: "Haldenstein",
    cantonShort: "GR",
    latitude: 46.8816,
    longitude: 9.5009,
  },
  {
    zipCode: 7026,
    city: "Maladers",
    cantonShort: "GR",
    latitude: 46.8447,
    longitude: 9.5723,
  },
  {
    zipCode: 7027,
    city: "L\u00fcen",
    cantonShort: "GR",
    latitude: 46.8409,
    longitude: 9.6237,
  },
  {
    zipCode: 7027,
    city: "Castiel",
    cantonShort: "GR",
    latitude: 46.8484,
    longitude: 9.6143,
  },
  {
    zipCode: 7027,
    city: "Calfreisen",
    cantonShort: "GR",
    latitude: 46.8544,
    longitude: 9.6005,
  },
  {
    zipCode: 7028,
    city: "St. Peter",
    cantonShort: "GR",
    latitude: 46.8483,
    longitude: 9.6586,
  },
  {
    zipCode: 7028,
    city: "Pagig",
    cantonShort: "GR",
    latitude: 46.8497,
    longitude: 9.6376,
  },
  {
    zipCode: 7029,
    city: "Peist",
    cantonShort: "GR",
    latitude: 46.8427,
    longitude: 9.6882,
  },
  {
    zipCode: 7050,
    city: "Arosa",
    cantonShort: "GR",
    latitude: 46.7699,
    longitude: 9.6683,
  },
  {
    zipCode: 7056,
    city: "Molinis",
    cantonShort: "GR",
    latitude: 46.8141,
    longitude: 9.6465,
  },
  {
    zipCode: 7057,
    city: "Langwies",
    cantonShort: "GR",
    latitude: 46.8235,
    longitude: 9.7455,
  },
  {
    zipCode: 7058,
    city: "Litzir\u00fcti",
    cantonShort: "GR",
    latitude: 46.7988,
    longitude: 9.7014,
  },
  {
    zipCode: 7062,
    city: "Passugg",
    cantonShort: "GR",
    latitude: 46.8212,
    longitude: 9.5573,
  },
  {
    zipCode: 7063,
    city: "Praden",
    cantonShort: "GR",
    latitude: 46.821,
    longitude: 9.5824,
  },
  {
    zipCode: 7064,
    city: "Tschiertschen",
    cantonShort: "GR",
    latitude: 46.7929,
    longitude: 9.6067,
  },
  {
    zipCode: 7074,
    city: "Malix",
    cantonShort: "GR",
    latitude: 46.8117,
    longitude: 9.518,
  },
  {
    zipCode: 7075,
    city: "Churwalden",
    cantonShort: "GR",
    latitude: 46.7826,
    longitude: 9.5323,
  },
  {
    zipCode: 7076,
    city: "Parpan",
    cantonShort: "GR",
    latitude: 46.7762,
    longitude: 9.5703,
  },
  {
    zipCode: 7212,
    city: "Seewis Dorf",
    cantonShort: "GR",
    latitude: 47.023,
    longitude: 9.6603,
  },
  {
    zipCode: 7212,
    city: "Seewis-Schmitten",
    cantonShort: "GR",
    latitude: 46.9815,
    longitude: 9.6421,
  },
  {
    zipCode: 7212,
    city: "Seewis-Pardisla",
    cantonShort: "GR",
    latitude: 46.978,
    longitude: 9.633,
  },
  {
    zipCode: 7213,
    city: "Valzeina",
    cantonShort: "GR",
    latitude: 46.9163,
    longitude: 9.6237,
  },
  {
    zipCode: 7214,
    city: "Gr\u00fcsch",
    cantonShort: "GR",
    latitude: 46.9627,
    longitude: 9.6397,
  },
  {
    zipCode: 7215,
    city: "Fanas",
    cantonShort: "GR",
    latitude: 47.0072,
    longitude: 9.6875,
  },
  {
    zipCode: 7220,
    city: "Schiers",
    cantonShort: "GR",
    latitude: 46.9811,
    longitude: 9.7189,
  },
  {
    zipCode: 7220,
    city: "Schiers",
    cantonShort: "GR",
    latitude: 46.9811,
    longitude: 9.7189,
  },
  {
    zipCode: 7222,
    city: "Lunden",
    cantonShort: "GR",
    latitude: 46.9517,
    longitude: 9.7116,
  },
  {
    zipCode: 7223,
    city: "Buchen im Pr\u00e4ttigau",
    cantonShort: "GR",
    latitude: 46.9388,
    longitude: 9.7244,
  },
  {
    zipCode: 7224,
    city: "Putz",
    cantonShort: "GR",
    latitude: 46.9269,
    longitude: 9.7407,
  },
  {
    zipCode: 7226,
    city: "Fajauna",
    cantonShort: "GR",
    latitude: 46.9714,
    longitude: 9.7065,
  },
  {
    zipCode: 7226,
    city: "Stels",
    cantonShort: "GR",
    latitude: 46.9885,
    longitude: 9.769,
  },
  {
    zipCode: 7226,
    city: "Stels",
    cantonShort: "GR",
    latitude: 46.9885,
    longitude: 9.769,
  },
  {
    zipCode: 7228,
    city: "Schuders",
    cantonShort: "GR",
    latitude: 47.0089,
    longitude: 9.7482,
  },
  {
    zipCode: 7228,
    city: "Pusserein",
    cantonShort: "GR",
    latitude: 46.9922,
    longitude: 9.7129,
  },
  {
    zipCode: 7231,
    city: "Pragg-Jenaz",
    cantonShort: "GR",
    latitude: 46.9426,
    longitude: 9.6953,
  },
  {
    zipCode: 7232,
    city: "Furna",
    cantonShort: "GR",
    latitude: 46.9136,
    longitude: 9.6615,
  },
  {
    zipCode: 7233,
    city: "Jenaz",
    cantonShort: "GR",
    latitude: 46.8981,
    longitude: 9.7035,
  },
  {
    zipCode: 7235,
    city: "Fideris",
    cantonShort: "GR",
    latitude: 46.8866,
    longitude: 9.7395,
  },
  {
    zipCode: 7240,
    city: "K\u00fcblis",
    cantonShort: "GR",
    latitude: 46.9253,
    longitude: 9.7955,
  },
  {
    zipCode: 7241,
    city: "Conters im Pr\u00e4ttigau",
    cantonShort: "GR",
    latitude: 46.8858,
    longitude: 9.7876,
  },
  {
    zipCode: 7242,
    city: "Luzein",
    cantonShort: "GR",
    latitude: 46.9212,
    longitude: 9.7623,
  },
  {
    zipCode: 7243,
    city: "Pany",
    cantonShort: "GR",
    latitude: 46.9482,
    longitude: 9.7675,
  },
  {
    zipCode: 7244,
    city: "Gadenst\u00e4tt",
    cantonShort: "GR",
    latitude: 46.9448,
    longitude: 9.7904,
  },
  {
    zipCode: 7245,
    city: "Ascharina",
    cantonShort: "GR",
    latitude: 46.9495,
    longitude: 9.8203,
  },
  {
    zipCode: 7246,
    city: "St. Ant\u00f6nien",
    cantonShort: "GR",
    latitude: 46.9802,
    longitude: 9.8459,
  },
  {
    zipCode: 7247,
    city: "Saas im Pr\u00e4ttigau",
    cantonShort: "GR",
    latitude: 46.9151,
    longitude: 9.8524,
  },
  {
    zipCode: 7249,
    city: "Serneus",
    cantonShort: "GR",
    latitude: 46.8871,
    longitude: 9.8339,
  },
  {
    zipCode: 7250,
    city: "Klosters",
    cantonShort: "GR",
    latitude: 46.8475,
    longitude: 9.9649,
  },
  {
    zipCode: 7252,
    city: "Klosters Dorf",
    cantonShort: "GR",
    latitude: 46.9013,
    longitude: 9.9155,
  },
  {
    zipCode: 7260,
    city: "Davos Dorf",
    cantonShort: "GR",
    latitude: 46.7674,
    longitude: 9.8969,
  },
  {
    zipCode: 7265,
    city: "Davos Wolfgang",
    cantonShort: "GR",
    latitude: 46.8389,
    longitude: 9.8534,
  },
  {
    zipCode: 7270,
    city: "Davos Platz",
    cantonShort: "GR",
    latitude: 46.7874,
    longitude: 9.8181,
  },
  {
    zipCode: 7270,
    city: "Davos Platz 1",
    cantonShort: "GR",
    latitude: 46.8048,
    longitude: 9.8372,
  },
  {
    zipCode: 7272,
    city: "Davos Clavadel",
    cantonShort: "GR",
    latitude: 46.7227,
    longitude: 9.8447,
  },
  {
    zipCode: 7276,
    city: "Davos Frauenkirch",
    cantonShort: "GR",
    latitude: 46.7756,
    longitude: 9.7698,
  },
  {
    zipCode: 7277,
    city: "Davos Glaris",
    cantonShort: "GR",
    latitude: 46.7398,
    longitude: 9.7705,
  },
  {
    zipCode: 7278,
    city: "Davos Monstein",
    cantonShort: "GR",
    latitude: 46.6983,
    longitude: 9.7849,
  },
  {
    zipCode: 7494,
    city: "Davos Wiesen",
    cantonShort: "GR",
    latitude: 46.7163,
    longitude: 9.7204,
  },
  {
    zipCode: 7031,
    city: "Laax GR",
    cantonShort: "GR",
    latitude: 46.8095,
    longitude: 9.2635,
  },
  {
    zipCode: 7032,
    city: "Laax GR 2",
    cantonShort: "GR",
    latitude: 46.8515,
    longitude: 9.2121,
  },
  {
    zipCode: 7104,
    city: "Versam",
    cantonShort: "GR",
    latitude: 46.7773,
    longitude: 9.3365,
  },
  {
    zipCode: 7104,
    city: "Versam",
    cantonShort: "GR",
    latitude: 46.7773,
    longitude: 9.3365,
  },
  {
    zipCode: 7104,
    city: "Arezen",
    cantonShort: "GR",
    latitude: 46.7823,
    longitude: 9.3344,
  },
  {
    zipCode: 7106,
    city: "Tenna",
    cantonShort: "GR",
    latitude: 46.7465,
    longitude: 9.3251,
  },
  {
    zipCode: 7107,
    city: "Safien Platz",
    cantonShort: "GR",
    latitude: 46.7127,
    longitude: 9.3222,
  },
  {
    zipCode: 7109,
    city: "Thalkirch",
    cantonShort: "GR",
    latitude: 46.6321,
    longitude: 9.2815,
  },
  {
    zipCode: 7110,
    city: "Peiden",
    cantonShort: "GR",
    latitude: 46.7158,
    longitude: 9.1981,
  },
  {
    zipCode: 7111,
    city: "Pitasch",
    cantonShort: "GR",
    latitude: 46.7219,
    longitude: 9.2458,
  },
  {
    zipCode: 7112,
    city: "Duvin",
    cantonShort: "GR",
    latitude: 46.6963,
    longitude: 9.248,
  },
  {
    zipCode: 7113,
    city: "Camuns",
    cantonShort: "GR",
    latitude: 46.6891,
    longitude: 9.2216,
  },
  {
    zipCode: 7114,
    city: "Uors (Lumnezia)",
    cantonShort: "GR",
    latitude: 46.7038,
    longitude: 9.188,
  },
  {
    zipCode: 7115,
    city: "Surcasti",
    cantonShort: "GR",
    latitude: 46.6808,
    longitude: 9.1692,
  },
  {
    zipCode: 7116,
    city: "St. Martin (Lugnez)",
    cantonShort: "GR",
    latitude: 46.6543,
    longitude: 9.206,
  },
  {
    zipCode: 7116,
    city: "Tersnaus",
    cantonShort: "GR",
    latitude: 46.6863,
    longitude: 9.1951,
  },
  {
    zipCode: 7122,
    city: "Carrera",
    cantonShort: "GR",
    latitude: 46.7885,
    longitude: 9.2961,
  },
  {
    zipCode: 7122,
    city: "Valendas",
    cantonShort: "GR",
    latitude: 46.773,
    longitude: 9.2947,
  },
  {
    zipCode: 7126,
    city: "Castrisch",
    cantonShort: "GR",
    latitude: 46.7726,
    longitude: 9.243,
  },
  {
    zipCode: 7127,
    city: "Sevgein",
    cantonShort: "GR",
    latitude: 46.7575,
    longitude: 9.2226,
  },
  {
    zipCode: 7128,
    city: "Riein",
    cantonShort: "GR",
    latitude: 46.7422,
    longitude: 9.2606,
  },
  {
    zipCode: 7130,
    city: "Schnaus",
    cantonShort: "GR",
    latitude: 46.8056,
    longitude: 9.1789,
  },
  {
    zipCode: 7130,
    city: "Ilanz",
    cantonShort: "GR",
    latitude: 46.7707,
    longitude: 9.1927,
  },
  {
    zipCode: 7130,
    city: "Schnaus",
    cantonShort: "GR",
    latitude: 46.8056,
    longitude: 9.1789,
  },
  {
    zipCode: 7132,
    city: "Vals",
    cantonShort: "GR",
    latitude: 46.5737,
    longitude: 9.1308,
  },
  {
    zipCode: 7134,
    city: "Obersaxen",
    cantonShort: "GR",
    latitude: 46.7197,
    longitude: 9.0712,
  },
  {
    zipCode: 7137,
    city: "Flond",
    cantonShort: "GR",
    latitude: 46.7664,
    longitude: 9.161,
  },
  {
    zipCode: 7138,
    city: "Surcuolm",
    cantonShort: "GR",
    latitude: 46.7529,
    longitude: 9.1513,
  },
  {
    zipCode: 7141,
    city: "Luven",
    cantonShort: "GR",
    latitude: 46.7548,
    longitude: 9.1906,
  },
  {
    zipCode: 7142,
    city: "Cumbel",
    cantonShort: "GR",
    latitude: 46.7345,
    longitude: 9.2011,
  },
  {
    zipCode: 7143,
    city: "Morissen",
    cantonShort: "GR",
    latitude: 46.7348,
    longitude: 9.1737,
  },
  {
    zipCode: 7144,
    city: "Vella",
    cantonShort: "GR",
    latitude: 46.7213,
    longitude: 9.159,
  },
  {
    zipCode: 7145,
    city: "Degen",
    cantonShort: "GR",
    latitude: 46.7059,
    longitude: 9.1709,
  },
  {
    zipCode: 7146,
    city: "Vattiz",
    cantonShort: "GR",
    latitude: 46.7118,
    longitude: 9.1384,
  },
  {
    zipCode: 7147,
    city: "Vignogn",
    cantonShort: "GR",
    latitude: 46.694,
    longitude: 9.1413,
  },
  {
    zipCode: 7148,
    city: "Lumbrein",
    cantonShort: "GR",
    latitude: 46.6683,
    longitude: 9.1035,
  },
  {
    zipCode: 7149,
    city: "Vrin",
    cantonShort: "GR",
    latitude: 46.6241,
    longitude: 9.0564,
  },
  {
    zipCode: 7151,
    city: "Schluein",
    cantonShort: "GR",
    latitude: 46.7889,
    longitude: 9.2224,
  },
  {
    zipCode: 7152,
    city: "Sagogn",
    cantonShort: "GR",
    latitude: 46.7983,
    longitude: 9.2755,
  },
  {
    zipCode: 7153,
    city: "Falera",
    cantonShort: "GR",
    latitude: 46.813,
    longitude: 9.22,
  },
  {
    zipCode: 7154,
    city: "Ruschein",
    cantonShort: "GR",
    latitude: 46.8291,
    longitude: 9.1686,
  },
  {
    zipCode: 7155,
    city: "Ladir",
    cantonShort: "GR",
    latitude: 46.8114,
    longitude: 9.1906,
  },
  {
    zipCode: 7155,
    city: "Ladir",
    cantonShort: "GR",
    latitude: 46.8114,
    longitude: 9.1906,
  },
  {
    zipCode: 7156,
    city: "Rueun",
    cantonShort: "GR",
    latitude: 46.795,
    longitude: 9.1406,
  },
  {
    zipCode: 7156,
    city: "Pigniu",
    cantonShort: "GR",
    latitude: 46.8423,
    longitude: 9.1091,
  },
  {
    zipCode: 7157,
    city: "Siat",
    cantonShort: "GR",
    latitude: 46.8152,
    longitude: 9.1545,
  },
  {
    zipCode: 7158,
    city: "Waltensburg/Vuorz",
    cantonShort: "GR",
    latitude: 46.8116,
    longitude: 9.0777,
  },
  {
    zipCode: 7159,
    city: "Andiast",
    cantonShort: "GR",
    latitude: 46.8179,
    longitude: 9.0975,
  },
  {
    zipCode: 7162,
    city: "Tavanasa",
    cantonShort: "GR",
    latitude: 46.7567,
    longitude: 9.0749,
  },
  {
    zipCode: 7163,
    city: "Danis",
    cantonShort: "GR",
    latitude: 46.7561,
    longitude: 9.0534,
  },
  {
    zipCode: 7164,
    city: "Dardin",
    cantonShort: "GR",
    latitude: 46.7606,
    longitude: 9.0364,
  },
  {
    zipCode: 7165,
    city: "Breil/Brigels",
    cantonShort: "GR",
    latitude: 46.7924,
    longitude: 9.0289,
  },
  {
    zipCode: 7166,
    city: "Trun",
    cantonShort: "GR",
    latitude: 46.7411,
    longitude: 8.9786,
  },
  {
    zipCode: 7167,
    city: "Zignau",
    cantonShort: "GR",
    latitude: 46.734,
    longitude: 9.0104,
  },
  {
    zipCode: 7168,
    city: "Schlans",
    cantonShort: "GR",
    latitude: 46.7653,
    longitude: 9.0009,
  },
  {
    zipCode: 7172,
    city: "Rabius",
    cantonShort: "GR",
    latitude: 46.7419,
    longitude: 8.9547,
  },
  {
    zipCode: 7173,
    city: "Surrein",
    cantonShort: "GR",
    latitude: 46.6738,
    longitude: 8.9654,
  },
  {
    zipCode: 7174,
    city: "S. Benedetg",
    cantonShort: "GR",
    latitude: 46.7371,
    longitude: 8.9435,
  },
  {
    zipCode: 7175,
    city: "Sumvitg",
    cantonShort: "GR",
    latitude: 46.7622,
    longitude: 8.9153,
  },
  {
    zipCode: 7176,
    city: "Cumpadials",
    cantonShort: "GR",
    latitude: 46.7087,
    longitude: 8.9161,
  },
  {
    zipCode: 7180,
    city: "Disentis/Must\u00e9r",
    cantonShort: "GR",
    latitude: 46.7456,
    longitude: 8.8481,
  },
  {
    zipCode: 7182,
    city: "Cavardiras",
    cantonShort: "GR",
    latitude: 46.6995,
    longitude: 8.8819,
  },
  {
    zipCode: 7183,
    city: "Mump\u00e9 Medel",
    cantonShort: "GR",
    latitude: 46.6811,
    longitude: 8.8313,
  },
  {
    zipCode: 7184,
    city: "Curaglia",
    cantonShort: "GR",
    latitude: 46.6525,
    longitude: 8.8851,
  },
  {
    zipCode: 7185,
    city: "Platta",
    cantonShort: "GR",
    latitude: 46.6065,
    longitude: 8.8364,
  },
  {
    zipCode: 7186,
    city: "Segnas",
    cantonShort: "GR",
    latitude: 46.6937,
    longitude: 8.8169,
  },
  {
    zipCode: 7187,
    city: "Camischolas",
    cantonShort: "GR",
    latitude: 46.6814,
    longitude: 8.7615,
  },
  {
    zipCode: 7188,
    city: "Sedrun",
    cantonShort: "GR",
    latitude: 46.6377,
    longitude: 8.7502,
  },
  {
    zipCode: 7189,
    city: "Rueras",
    cantonShort: "GR",
    latitude: 46.6662,
    longitude: 8.709,
  },
  {
    zipCode: 7404,
    city: "Feldis/Veulden",
    cantonShort: "GR",
    latitude: 46.7968,
    longitude: 9.4422,
  },
  {
    zipCode: 7405,
    city: "Rothenbrunnen",
    cantonShort: "GR",
    latitude: 46.7769,
    longitude: 9.4208,
  },
  {
    zipCode: 7407,
    city: "Trans",
    cantonShort: "GR",
    latitude: 46.7624,
    longitude: 9.4817,
  },
  {
    zipCode: 7408,
    city: "Realta",
    cantonShort: "GR",
    latitude: 46.7319,
    longitude: 9.4277,
  },
  {
    zipCode: 7408,
    city: "Cazis",
    cantonShort: "GR",
    latitude: 46.7391,
    longitude: 9.4258,
  },
  {
    zipCode: 7411,
    city: "Sils im Domleschg",
    cantonShort: "GR",
    latitude: 46.6908,
    longitude: 9.465,
  },
  {
    zipCode: 7412,
    city: "Scharans",
    cantonShort: "GR",
    latitude: 46.7183,
    longitude: 9.4825,
  },
  {
    zipCode: 7413,
    city: "F\u00fcrstenaubruck",
    cantonShort: "GR",
    latitude: 46.715,
    longitude: 9.4498,
  },
  {
    zipCode: 7414,
    city: "F\u00fcrstenau",
    cantonShort: "GR",
    latitude: 46.7231,
    longitude: 9.4481,
  },
  {
    zipCode: 7415,
    city: "Rodels",
    cantonShort: "GR",
    latitude: 46.7406,
    longitude: 9.4391,
  },
  {
    zipCode: 7415,
    city: "Pratval",
    cantonShort: "GR",
    latitude: 46.7312,
    longitude: 9.4458,
  },
  {
    zipCode: 7416,
    city: "Almens",
    cantonShort: "GR",
    latitude: 46.7426,
    longitude: 9.4826,
  },
  {
    zipCode: 7417,
    city: "Paspels",
    cantonShort: "GR",
    latitude: 46.7513,
    longitude: 9.4509,
  },
  {
    zipCode: 7418,
    city: "Tumegl/Tomils",
    cantonShort: "GR",
    latitude: 46.764,
    longitude: 9.4447,
  },
  {
    zipCode: 7419,
    city: "Scheid",
    cantonShort: "GR",
    latitude: 46.7834,
    longitude: 9.4663,
  },
  {
    zipCode: 7421,
    city: "Summaprada",
    cantonShort: "GR",
    latitude: 46.713,
    longitude: 9.4312,
  },
  {
    zipCode: 7422,
    city: "Tartar",
    cantonShort: "GR",
    latitude: 46.7221,
    longitude: 9.4193,
  },
  {
    zipCode: 7423,
    city: "Sarn",
    cantonShort: "GR",
    latitude: 46.7234,
    longitude: 9.3879,
  },
  {
    zipCode: 7423,
    city: "Portein",
    cantonShort: "GR",
    latitude: 46.7121,
    longitude: 9.3848,
  },
  {
    zipCode: 7424,
    city: "Dalin",
    cantonShort: "GR",
    latitude: 46.7347,
    longitude: 9.4058,
  },
  {
    zipCode: 7424,
    city: "Pr\u00e4z",
    cantonShort: "GR",
    latitude: 46.7486,
    longitude: 9.3965,
  },
  {
    zipCode: 7425,
    city: "Masein",
    cantonShort: "GR",
    latitude: 46.6954,
    longitude: 9.4226,
  },
  {
    zipCode: 7426,
    city: "Flerden",
    cantonShort: "GR",
    latitude: 46.6941,
    longitude: 9.3926,
  },
  {
    zipCode: 7426,
    city: "Flerden",
    cantonShort: "GR",
    latitude: 46.6941,
    longitude: 9.3926,
  },
  {
    zipCode: 7427,
    city: "Urmein",
    cantonShort: "GR",
    latitude: 46.686,
    longitude: 9.4019,
  },
  {
    zipCode: 7428,
    city: "Glaspass",
    cantonShort: "GR",
    latitude: 46.677,
    longitude: 9.3474,
  },
  {
    zipCode: 7428,
    city: "Tschappina",
    cantonShort: "GR",
    latitude: 46.6767,
    longitude: 9.3607,
  },
  {
    zipCode: 7430,
    city: "Thusis",
    cantonShort: "GR",
    latitude: 46.6885,
    longitude: 9.4328,
  },
  {
    zipCode: 7430,
    city: "Rongellen",
    cantonShort: "GR",
    latitude: 46.6739,
    longitude: 9.4406,
  },
  {
    zipCode: 7431,
    city: "Obermutten",
    cantonShort: "GR",
    latitude: 46.6682,
    longitude: 9.4876,
  },
  {
    zipCode: 7431,
    city: "Mutten",
    cantonShort: "GR",
    latitude: 46.6773,
    longitude: 9.5018,
  },
  {
    zipCode: 7432,
    city: "Zillis",
    cantonShort: "GR",
    latitude: 46.6381,
    longitude: 9.47,
  },
  {
    zipCode: 7433,
    city: "Mathon",
    cantonShort: "GR",
    latitude: 46.6406,
    longitude: 9.3803,
  },
  {
    zipCode: 7433,
    city: "Lohn GR",
    cantonShort: "GR",
    latitude: 46.6574,
    longitude: 9.4232,
  },
  {
    zipCode: 7433,
    city: "Wergenstein",
    cantonShort: "GR",
    latitude: 46.6156,
    longitude: 9.3519,
  },
  {
    zipCode: 7433,
    city: "Donat",
    cantonShort: "GR",
    latitude: 46.6312,
    longitude: 9.4247,
  },
  {
    zipCode: 7433,
    city: "Farden",
    cantonShort: "GR",
    latitude: 46.6327,
    longitude: 9.4241,
  },
  {
    zipCode: 7434,
    city: "Sufers",
    cantonShort: "GR",
    latitude: 46.5573,
    longitude: 9.3703,
  },
  {
    zipCode: 7435,
    city: "Spl\u00fcgen",
    cantonShort: "GR",
    latitude: 46.5371,
    longitude: 9.329,
  },
  {
    zipCode: 7436,
    city: "Medels im Rheinwald",
    cantonShort: "GR",
    latitude: 46.5522,
    longitude: 9.2853,
  },
  {
    zipCode: 7437,
    city: "Nufenen",
    cantonShort: "GR",
    latitude: 46.5404,
    longitude: 9.2385,
  },
  {
    zipCode: 7438,
    city: "Hinterrhein",
    cantonShort: "GR",
    latitude: 46.5137,
    longitude: 9.1351,
  },
  {
    zipCode: 7440,
    city: "Andeer",
    cantonShort: "GR",
    latitude: 46.5859,
    longitude: 9.4178,
  },
  {
    zipCode: 7442,
    city: "Clugin",
    cantonShort: "GR",
    latitude: 46.6127,
    longitude: 9.4121,
  },
  {
    zipCode: 7443,
    city: "Pignia",
    cantonShort: "GR",
    latitude: 46.6035,
    longitude: 9.4665,
  },
  {
    zipCode: 7444,
    city: "Ausserferrera",
    cantonShort: "GR",
    latitude: 46.5516,
    longitude: 9.4606,
  },
  {
    zipCode: 7445,
    city: "Innerferrera",
    cantonShort: "GR",
    latitude: 46.5058,
    longitude: 9.4399,
  },
  {
    zipCode: 7445,
    city: "Innerferrera",
    cantonShort: "GR",
    latitude: 46.5058,
    longitude: 9.4399,
  },
  {
    zipCode: 7446,
    city: "Campsut-Cr\u00f6t",
    cantonShort: "GR",
    latitude: 46.4246,
    longitude: 9.491,
  },
  {
    zipCode: 7447,
    city: "Cresta (Avers)",
    cantonShort: "GR",
    latitude: 46.4738,
    longitude: 9.5246,
  },
  {
    zipCode: 7447,
    city: "Am Bach (Avers)",
    cantonShort: "GR",
    latitude: 46.4272,
    longitude: 9.5449,
  },
  {
    zipCode: 7448,
    city: "Juf",
    cantonShort: "GR",
    latitude: 46.4329,
    longitude: 9.5864,
  },
  {
    zipCode: 2800,
    city: "Del\u00e9mont 1",
    cantonShort: "JU",
    latitude: 47.3751,
    longitude: 7.3329,
  },
  {
    zipCode: 2800,
    city: "Del\u00e9mont 2",
    cantonShort: "JU",
    latitude: 47.3751,
    longitude: 7.3329,
  },
  {
    zipCode: 2800,
    city: "Del\u00e9mont",
    cantonShort: "JU",
    latitude: 47.3718,
    longitude: 7.3341,
  },
  {
    zipCode: 2802,
    city: "Develier",
    cantonShort: "JU",
    latitude: 47.3648,
    longitude: 7.2813,
  },
  {
    zipCode: 2803,
    city: "Bourrignon",
    cantonShort: "JU",
    latitude: 47.3932,
    longitude: 7.2556,
  },
  {
    zipCode: 2805,
    city: "Soyhi\u00e8res",
    cantonShort: "JU",
    latitude: 47.3955,
    longitude: 7.3822,
  },
  {
    zipCode: 2806,
    city: "Mettembert",
    cantonShort: "JU",
    latitude: 47.3943,
    longitude: 7.3276,
  },
  {
    zipCode: 2807,
    city: "Pleigne",
    cantonShort: "JU",
    latitude: 47.4151,
    longitude: 7.2807,
  },
  {
    zipCode: 2807,
    city: "Lucelle",
    cantonShort: "JU",
    latitude: 47.4174,
    longitude: 7.2411,
  },
  {
    zipCode: 2812,
    city: "Movelier",
    cantonShort: "JU",
    latitude: 47.4089,
    longitude: 7.3281,
  },
  {
    zipCode: 2813,
    city: "Ederswiler",
    cantonShort: "JU",
    latitude: 47.4264,
    longitude: 7.324,
  },
  {
    zipCode: 2822,
    city: "Courroux",
    cantonShort: "JU",
    latitude: 47.3672,
    longitude: 7.3788,
  },
  {
    zipCode: 2823,
    city: "Courcelon",
    cantonShort: "JU",
    latitude: 47.3674,
    longitude: 7.407,
  },
  {
    zipCode: 2824,
    city: "Vicques",
    cantonShort: "JU",
    latitude: 47.3516,
    longitude: 7.4268,
  },
  {
    zipCode: 2825,
    city: "Courchapoix",
    cantonShort: "JU",
    latitude: 47.3488,
    longitude: 7.4522,
  },
  {
    zipCode: 2826,
    city: "Corban",
    cantonShort: "JU",
    latitude: 47.3534,
    longitude: 7.4742,
  },
  {
    zipCode: 2827,
    city: "Mervelier",
    cantonShort: "JU",
    latitude: 47.3402,
    longitude: 7.516,
  },
  {
    zipCode: 2828,
    city: "Montsevelier",
    cantonShort: "JU",
    latitude: 47.3602,
    longitude: 7.512,
  },
  {
    zipCode: 2829,
    city: "Vermes",
    cantonShort: "JU",
    latitude: 47.3192,
    longitude: 7.4849,
  },
  {
    zipCode: 2830,
    city: "Courrendlin",
    cantonShort: "JU",
    latitude: 47.3343,
    longitude: 7.3769,
  },
  {
    zipCode: 2830,
    city: "Vellerat",
    cantonShort: "JU",
    latitude: 47.3155,
    longitude: 7.3654,
  },
  {
    zipCode: 2832,
    city: "Rebeuvelier",
    cantonShort: "JU",
    latitude: 47.3209,
    longitude: 7.4189,
  },
  {
    zipCode: 2842,
    city: "Rossemaison",
    cantonShort: "JU",
    latitude: 47.3439,
    longitude: 7.3443,
  },
  {
    zipCode: 2843,
    city: "Ch\u00e2tillon JU",
    cantonShort: "JU",
    latitude: 47.322,
    longitude: 7.3449,
  },
  {
    zipCode: 2852,
    city: "Court\u00e9telle",
    cantonShort: "JU",
    latitude: 47.3313,
    longitude: 7.318,
  },
  {
    zipCode: 2853,
    city: "Courfaivre",
    cantonShort: "JU",
    latitude: 47.3298,
    longitude: 7.2802,
  },
  {
    zipCode: 2854,
    city: "Bassecourt",
    cantonShort: "JU",
    latitude: 47.3365,
    longitude: 7.2418,
  },
  {
    zipCode: 2855,
    city: "Glovelier",
    cantonShort: "JU",
    latitude: 47.3269,
    longitude: 7.1844,
  },
  {
    zipCode: 2856,
    city: "Bo\u00e9court",
    cantonShort: "JU",
    latitude: 47.3547,
    longitude: 7.2168,
  },
  {
    zipCode: 2857,
    city: "Montavon",
    cantonShort: "JU",
    latitude: 47.3717,
    longitude: 7.2335,
  },
  {
    zipCode: 2863,
    city: "Undervelier",
    cantonShort: "JU",
    latitude: 47.3034,
    longitude: 7.2194,
  },
  {
    zipCode: 2864,
    city: "Soulce",
    cantonShort: "JU",
    latitude: 47.3002,
    longitude: 7.2871,
  },
  {
    zipCode: 2873,
    city: "Saulcy",
    cantonShort: "JU",
    latitude: 47.3031,
    longitude: 7.1606,
  },
  {
    zipCode: 2336,
    city: "Les Bois",
    cantonShort: "JU",
    latitude: 47.1796,
    longitude: 6.8983,
  },
  {
    zipCode: 2338,
    city: "Muriaux",
    cantonShort: "JU",
    latitude: 47.2442,
    longitude: 6.9802,
  },
  {
    zipCode: 2338,
    city: "Les Emibois",
    cantonShort: "JU",
    latitude: 47.2318,
    longitude: 6.9944,
  },
  {
    zipCode: 2340,
    city: "Le Noirmont",
    cantonShort: "JU",
    latitude: 47.2152,
    longitude: 6.9438,
  },
  {
    zipCode: 2345,
    city: "La Chaux-des-Breuleux",
    cantonShort: "JU",
    latitude: 47.2227,
    longitude: 7.0287,
  },
  {
    zipCode: 2345,
    city: "Les Breuleux",
    cantonShort: "JU",
    latitude: 47.203,
    longitude: 6.9928,
  },
  {
    zipCode: 2350,
    city: "Saignel\u00e9gier",
    cantonShort: "JU",
    latitude: 47.2458,
    longitude: 7.02,
  },
  {
    zipCode: 2353,
    city: "Les Pommerats",
    cantonShort: "JU",
    latitude: 47.2801,
    longitude: 6.9908,
  },
  {
    zipCode: 2354,
    city: "Goumois",
    cantonShort: "JU",
    latitude: 47.2674,
    longitude: 6.9594,
  },
  {
    zipCode: 2360,
    city: "Le B\u00e9mont JU",
    cantonShort: "JU",
    latitude: 47.2625,
    longitude: 7.0334,
  },
  {
    zipCode: 2362,
    city: "Montfaucon",
    cantonShort: "JU",
    latitude: 47.2749,
    longitude: 7.0714,
  },
  {
    zipCode: 2362,
    city: "Montfavergier",
    cantonShort: "JU",
    latitude: 47.3064,
    longitude: 7.0852,
  },
  {
    zipCode: 2363,
    city: "Les Enfers",
    cantonShort: "JU",
    latitude: 47.2904,
    longitude: 7.0455,
  },
  {
    zipCode: 2364,
    city: "St-Brais",
    cantonShort: "JU",
    latitude: 47.3062,
    longitude: 7.1177,
  },
  {
    zipCode: 2714,
    city: "Les Genevez JU",
    cantonShort: "JU",
    latitude: 47.2531,
    longitude: 7.1275,
  },
  {
    zipCode: 2714,
    city: "Le Pr\u00e9dame",
    cantonShort: "JU",
    latitude: 47.2557,
    longitude: 7.0965,
  },
  {
    zipCode: 2718,
    city: "Fornet-Dessus",
    cantonShort: "JU",
    latitude: 47.285,
    longitude: 7.16,
  },
  {
    zipCode: 2718,
    city: "Lajoux JU",
    cantonShort: "JU",
    latitude: 47.2778,
    longitude: 7.1269,
  },
  {
    zipCode: 2882,
    city: "St-Ursanne",
    cantonShort: "JU",
    latitude: 47.3628,
    longitude: 7.1459,
  },
  {
    zipCode: 2887,
    city: "Soubey",
    cantonShort: "JU",
    latitude: 47.3101,
    longitude: 7.0405,
  },
  {
    zipCode: 2882,
    city: "St-Ursanne",
    cantonShort: "JU",
    latitude: 47.3628,
    longitude: 7.1459,
  },
  {
    zipCode: 2883,
    city: "Montmelon",
    cantonShort: "JU",
    latitude: 47.3499,
    longitude: 7.1729,
  },
  {
    zipCode: 2884,
    city: "Montenol",
    cantonShort: "JU",
    latitude: 47.3517,
    longitude: 7.1448,
  },
  {
    zipCode: 2885,
    city: "Epauvillers",
    cantonShort: "JU",
    latitude: 47.3366,
    longitude: 7.1176,
  },
  {
    zipCode: 2886,
    city: "Epiquerez",
    cantonShort: "JU",
    latitude: 47.3267,
    longitude: 7.0757,
  },
  {
    zipCode: 2888,
    city: "Seleute",
    cantonShort: "JU",
    latitude: 47.368,
    longitude: 7.1118,
  },
  {
    zipCode: 2889,
    city: "Ocourt",
    cantonShort: "JU",
    latitude: 47.3549,
    longitude: 7.0701,
  },
  {
    zipCode: 2900,
    city: "Porrentruy 1",
    cantonShort: "JU",
    latitude: 47.4213,
    longitude: 7.071,
  },
  {
    zipCode: 2900,
    city: "Porrentruy",
    cantonShort: "JU",
    latitude: 47.4212,
    longitude: 7.071,
  },
  {
    zipCode: 2900,
    city: "Porrentruy 2",
    cantonShort: "JU",
    latitude: 47.4213,
    longitude: 7.071,
  },
  {
    zipCode: 2902,
    city: "Fontenais",
    cantonShort: "JU",
    latitude: 47.3998,
    longitude: 7.0793,
  },
  {
    zipCode: 2903,
    city: "Villars-sur-Fontenais",
    cantonShort: "JU",
    latitude: 47.3811,
    longitude: 7.0754,
  },
  {
    zipCode: 2904,
    city: "Bressaucourt",
    cantonShort: "JU",
    latitude: 47.3842,
    longitude: 7.039,
  },
  {
    zipCode: 2905,
    city: "Courtedoux",
    cantonShort: "JU",
    latitude: 47.4129,
    longitude: 7.028,
  },
  {
    zipCode: 2906,
    city: "Chevenez",
    cantonShort: "JU",
    latitude: 47.3925,
    longitude: 6.9934,
  },
  {
    zipCode: 2907,
    city: "Rocourt",
    cantonShort: "JU",
    latitude: 47.3865,
    longitude: 6.9602,
  },
  {
    zipCode: 2908,
    city: "Grandfontaine",
    cantonShort: "JU",
    latitude: 47.3919,
    longitude: 6.9336,
  },
  {
    zipCode: 2912,
    city: "Roche-d'Or",
    cantonShort: "JU",
    latitude: 47.3653,
    longitude: 6.9587,
  },
  {
    zipCode: 2912,
    city: "R\u00e9cl\u00e8re",
    cantonShort: "JU",
    latitude: 47.369,
    longitude: 6.9241,
  },
  {
    zipCode: 2914,
    city: "Damvant",
    cantonShort: "JU",
    latitude: 47.3681,
    longitude: 6.8957,
  },
  {
    zipCode: 2915,
    city: "Bure",
    cantonShort: "JU",
    latitude: 47.4386,
    longitude: 6.9971,
  },
  {
    zipCode: 2916,
    city: "Fahy",
    cantonShort: "JU",
    latitude: 47.42,
    longitude: 6.9567,
  },
  {
    zipCode: 2922,
    city: "Courchavon",
    cantonShort: "JU",
    latitude: 47.4382,
    longitude: 7.0463,
  },
  {
    zipCode: 2923,
    city: "Courtema\u00eeche",
    cantonShort: "JU",
    latitude: 47.4578,
    longitude: 7.0469,
  },
  {
    zipCode: 2924,
    city: "Montignez",
    cantonShort: "JU",
    latitude: 47.4835,
    longitude: 7.0587,
  },
  {
    zipCode: 2925,
    city: "Buix",
    cantonShort: "JU",
    latitude: 47.4756,
    longitude: 7.0263,
  },
  {
    zipCode: 2926,
    city: "Boncourt",
    cantonShort: "JU",
    latitude: 47.488,
    longitude: 7.0076,
  },
  {
    zipCode: 2932,
    city: "Coeuve",
    cantonShort: "JU",
    latitude: 47.4524,
    longitude: 7.0953,
  },
  {
    zipCode: 2933,
    city: "Lugnez",
    cantonShort: "JU",
    latitude: 47.4854,
    longitude: 7.094,
  },
  {
    zipCode: 2933,
    city: "Damphreux",
    cantonShort: "JU",
    latitude: 47.4734,
    longitude: 7.1013,
  },
  {
    zipCode: 2935,
    city: "Beurnev\u00e9sin",
    cantonShort: "JU",
    latitude: 47.4924,
    longitude: 7.1328,
  },
  {
    zipCode: 2942,
    city: "Alle",
    cantonShort: "JU",
    latitude: 47.4284,
    longitude: 7.1262,
  },
  {
    zipCode: 2943,
    city: "Vendlincourt",
    cantonShort: "JU",
    latitude: 47.4519,
    longitude: 7.1485,
  },
  {
    zipCode: 2944,
    city: "Bonfol",
    cantonShort: "JU",
    latitude: 47.4759,
    longitude: 7.1568,
  },
  {
    zipCode: 2946,
    city: "Mi\u00e9court",
    cantonShort: "JU",
    latitude: 47.4311,
    longitude: 7.1713,
  },
  {
    zipCode: 2947,
    city: "Charmoille",
    cantonShort: "JU",
    latitude: 47.4274,
    longitude: 7.2138,
  },
  {
    zipCode: 2950,
    city: "Courtemautruy",
    cantonShort: "JU",
    latitude: 47.3867,
    longitude: 7.141,
  },
  {
    zipCode: 2950,
    city: "Courgenay",
    cantonShort: "JU",
    latitude: 47.3933,
    longitude: 7.1207,
  },
  {
    zipCode: 2952,
    city: "Cornol",
    cantonShort: "JU",
    latitude: 47.4048,
    longitude: 7.1631,
  },
  {
    zipCode: 2953,
    city: "Pleujouse",
    cantonShort: "JU",
    latitude: 47.4124,
    longitude: 7.2203,
  },
  {
    zipCode: 2953,
    city: "Fregi\u00e9court",
    cantonShort: "JU",
    latitude: 47.4108,
    longitude: 7.1924,
  },
  {
    zipCode: 2954,
    city: "Asuel",
    cantonShort: "JU",
    latitude: 47.3911,
    longitude: 7.2051,
  },
  {
    zipCode: 6000,
    city: "Luzern 16",
    cantonShort: "LU",
    latitude: 47.0471,
    longitude: 8.3252,
  },
  {
    zipCode: 6000,
    city: "Luzern 6",
    cantonShort: "LU",
    latitude: 47.0471,
    longitude: 8.3252,
  },
  {
    zipCode: 6000,
    city: "Luzern 7",
    cantonShort: "LU",
    latitude: 47.0471,
    longitude: 8.3252,
  },
  {
    zipCode: 6000,
    city: "Luzern 15",
    cantonShort: "LU",
    latitude: 47.0471,
    longitude: 8.3252,
  },
  {
    zipCode: 6000,
    city: "Luzern",
    cantonShort: "LU",
    latitude: 47.0505,
    longitude: 8.3064,
  },
  {
    zipCode: 6000,
    city: "Luzern 30 AAL",
    cantonShort: "LU",
    latitude: 47.0471,
    longitude: 8.3252,
  },
  {
    zipCode: 6000,
    city: "Luzern 14",
    cantonShort: "LU",
    latitude: 47.0471,
    longitude: 8.3252,
  },
  {
    zipCode: 6002,
    city: "Luzern",
    cantonShort: "LU",
    latitude: 47.0505,
    longitude: 8.3064,
  },
  {
    zipCode: 6003,
    city: "Luzern",
    cantonShort: "LU",
    latitude: 47.0491,
    longitude: 8.296,
  },
  {
    zipCode: 6004,
    city: "Luzern",
    cantonShort: "LU",
    latitude: 47.0594,
    longitude: 8.3008,
  },
  {
    zipCode: 6005,
    city: "Luzern",
    cantonShort: "LU",
    latitude: 47.0382,
    longitude: 8.3134,
  },
  {
    zipCode: 6006,
    city: "Luzern",
    cantonShort: "LU",
    latitude: 47.0538,
    longitude: 8.3345,
  },
  {
    zipCode: 6007,
    city: "Luzern",
    cantonShort: "LU",
    latitude: 47.0505,
    longitude: 8.3064,
  },
  {
    zipCode: 6014,
    city: "Luzern",
    cantonShort: "LU",
    latitude: 47.0568,
    longitude: 8.247,
  },
  {
    zipCode: 6015,
    city: "Luzern",
    cantonShort: "LU",
    latitude: 47.0631,
    longitude: 8.2778,
  },
  {
    zipCode: 6005,
    city: "St. Niklausen LU",
    cantonShort: "LU",
    latitude: 47.0264,
    longitude: 8.3405,
  },
  {
    zipCode: 6009,
    city: "Luzern",
    cantonShort: "LU",
    latitude: 47.0208,
    longitude: 8.2589,
  },
  {
    zipCode: 6010,
    city: "Kriens 2",
    cantonShort: "LU",
    latitude: 47.0208,
    longitude: 8.2589,
  },
  {
    zipCode: 6010,
    city: "Kriens",
    cantonShort: "LU",
    latitude: 47.0199,
    longitude: 8.2682,
  },
  {
    zipCode: 6011,
    city: "Kriens",
    cantonShort: "LU",
    latitude: 47.0311,
    longitude: 8.2855,
  },
  {
    zipCode: 6012,
    city: "Obernau",
    cantonShort: "LU",
    latitude: 47.0269,
    longitude: 8.2366,
  },
  {
    zipCode: 6013,
    city: "Eigenthal",
    cantonShort: "LU",
    latitude: 46.9926,
    longitude: 8.2154,
  },
  {
    zipCode: 6030,
    city: "Ebikon",
    cantonShort: "LU",
    latitude: 47.0799,
    longitude: 8.3417,
  },
  {
    zipCode: 6031,
    city: "Ebikon",
    cantonShort: "LU",
    latitude: 47.0794,
    longitude: 8.3404,
  },
  {
    zipCode: 6033,
    city: "Buchrain",
    cantonShort: "LU",
    latitude: 47.1007,
    longitude: 8.3463,
  },
  {
    zipCode: 6035,
    city: "Perlen",
    cantonShort: "LU",
    latitude: 47.1115,
    longitude: 8.3649,
  },
  {
    zipCode: 6036,
    city: "Dierikon",
    cantonShort: "LU",
    latitude: 47.0942,
    longitude: 8.3726,
  },
  {
    zipCode: 6037,
    city: "Root",
    cantonShort: "LU",
    latitude: 47.1113,
    longitude: 8.3928,
  },
  {
    zipCode: 6038,
    city: "Honau",
    cantonShort: "LU",
    latitude: 47.1328,
    longitude: 8.4093,
  },
  {
    zipCode: 6038,
    city: "Gisikon",
    cantonShort: "LU",
    latitude: 47.1275,
    longitude: 8.3997,
  },
  {
    zipCode: 6039,
    city: "Root D4",
    cantonShort: "LU",
    latitude: 47.1031,
    longitude: 8.3733,
  },
  {
    zipCode: 6043,
    city: "Adligenswil",
    cantonShort: "LU",
    latitude: 47.0723,
    longitude: 8.3685,
  },
  {
    zipCode: 6044,
    city: "Udligenswil",
    cantonShort: "LU",
    latitude: 47.0928,
    longitude: 8.3998,
  },
  {
    zipCode: 6045,
    city: "Meggen",
    cantonShort: "LU",
    latitude: 47.0449,
    longitude: 8.3745,
  },
  {
    zipCode: 6047,
    city: "Kastanienbaum",
    cantonShort: "LU",
    latitude: 47.0074,
    longitude: 8.3402,
  },
  {
    zipCode: 6048,
    city: "Horw",
    cantonShort: "LU",
    latitude: 47.0108,
    longitude: 8.3039,
  },
  {
    zipCode: 6102,
    city: "Malters",
    cantonShort: "LU",
    latitude: 47.0378,
    longitude: 8.1845,
  },
  {
    zipCode: 6103,
    city: "Schwarzenberg LU",
    cantonShort: "LU",
    latitude: 46.9983,
    longitude: 8.1738,
  },
  {
    zipCode: 6344,
    city: "Meierskappel",
    cantonShort: "LU",
    latitude: 47.1151,
    longitude: 8.4527,
  },
  {
    zipCode: 6353,
    city: "Weggis",
    cantonShort: "LU",
    latitude: 47.0313,
    longitude: 8.4242,
  },
  {
    zipCode: 6354,
    city: "Vitznau",
    cantonShort: "LU",
    latitude: 47.0162,
    longitude: 8.4859,
  },
  {
    zipCode: 6356,
    city: "Rigi Kaltbad",
    cantonShort: "LU",
    latitude: 47.0366,
    longitude: 8.4882,
  },
  {
    zipCode: 6404,
    city: "Greppen",
    cantonShort: "LU",
    latitude: 47.0563,
    longitude: 8.4313,
  },
  {
    zipCode: 6020,
    city: "Emmenbr\u00fccke",
    cantonShort: "LU",
    latitude: 47.0771,
    longitude: 8.2667,
  },
  {
    zipCode: 6020,
    city: "Emmenbr\u00fccke 2",
    cantonShort: "LU",
    latitude: 47.0866,
    longitude: 8.2862,
  },
  {
    zipCode: 6021,
    city: "Emmenbr\u00fccke 1",
    cantonShort: "LU",
    latitude: 47.0866,
    longitude: 8.2862,
  },
  {
    zipCode: 6023,
    city: "Rothenburg",
    cantonShort: "LU",
    latitude: 47.1046,
    longitude: 8.2625,
  },
  {
    zipCode: 6026,
    city: "Rain",
    cantonShort: "LU",
    latitude: 47.1319,
    longitude: 8.2636,
  },
  {
    zipCode: 6027,
    city: "R\u00f6merswil LU",
    cantonShort: "LU",
    latitude: 47.1697,
    longitude: 8.2489,
  },
  {
    zipCode: 6028,
    city: "Herlisberg",
    cantonShort: "LU",
    latitude: 47.1979,
    longitude: 8.2303,
  },
  {
    zipCode: 6032,
    city: "Emmen",
    cantonShort: "LU",
    latitude: 47.0901,
    longitude: 8.3101,
  },
  {
    zipCode: 6034,
    city: "Inwil",
    cantonShort: "LU",
    latitude: 47.1287,
    longitude: 8.3623,
  },
  {
    zipCode: 6274,
    city: "Eschenbach LU",
    cantonShort: "LU",
    latitude: 47.1282,
    longitude: 8.3125,
  },
  {
    zipCode: 6275,
    city: "Ballwil",
    cantonShort: "LU",
    latitude: 47.1544,
    longitude: 8.3348,
  },
  {
    zipCode: 6276,
    city: "Hohenrain",
    cantonShort: "LU",
    latitude: 47.1784,
    longitude: 8.3203,
  },
  {
    zipCode: 6277,
    city: "Kleinwangen",
    cantonShort: "LU",
    latitude: 47.197,
    longitude: 8.3041,
  },
  {
    zipCode: 6277,
    city: "Lieli LU",
    cantonShort: "LU",
    latitude: 47.2122,
    longitude: 8.3058,
  },
  {
    zipCode: 6280,
    city: "Hochdorf",
    cantonShort: "LU",
    latitude: 47.1666,
    longitude: 8.2847,
  },
  {
    zipCode: 6280,
    city: "Urswil",
    cantonShort: "LU",
    latitude: 47.1496,
    longitude: 8.2867,
  },
  {
    zipCode: 6281,
    city: "Hochdorf",
    cantonShort: "LU",
    latitude: 47.1684,
    longitude: 8.2918,
  },
  {
    zipCode: 6283,
    city: "Baldegg",
    cantonShort: "LU",
    latitude: 47.1848,
    longitude: 8.2713,
  },
  {
    zipCode: 6284,
    city: "Gelfingen",
    cantonShort: "LU",
    latitude: 47.2076,
    longitude: 8.2679,
  },
  {
    zipCode: 6284,
    city: "Sulz LU",
    cantonShort: "LU",
    latitude: 47.2228,
    longitude: 8.2942,
  },
  {
    zipCode: 6285,
    city: "Retschwil",
    cantonShort: "LU",
    latitude: 47.1986,
    longitude: 8.2451,
  },
  {
    zipCode: 6285,
    city: "Hitzkirch",
    cantonShort: "LU",
    latitude: 47.2226,
    longitude: 8.2578,
  },
  {
    zipCode: 6286,
    city: "Altwis",
    cantonShort: "LU",
    latitude: 47.2406,
    longitude: 8.2522,
  },
  {
    zipCode: 6287,
    city: "Aesch LU",
    cantonShort: "LU",
    latitude: 47.2583,
    longitude: 8.2386,
  },
  {
    zipCode: 6288,
    city: "Schongau",
    cantonShort: "LU",
    latitude: 47.267,
    longitude: 8.2715,
  },
  {
    zipCode: 6289,
    city: "H\u00e4mikon",
    cantonShort: "LU",
    latitude: 47.2418,
    longitude: 8.2794,
  },
  {
    zipCode: 6289,
    city: "H\u00e4mikon",
    cantonShort: "LU",
    latitude: 47.2418,
    longitude: 8.2794,
  },
  {
    zipCode: 6289,
    city: "M\u00fcswangen",
    cantonShort: "LU",
    latitude: 47.2384,
    longitude: 8.2979,
  },
  {
    zipCode: 6294,
    city: "Ermensee",
    cantonShort: "LU",
    latitude: 47.2254,
    longitude: 8.2344,
  },
  {
    zipCode: 6295,
    city: "Mosen",
    cantonShort: "LU",
    latitude: 47.2419,
    longitude: 8.227,
  },
  {
    zipCode: 5735,
    city: "Pfeffikon LU",
    cantonShort: "LU",
    latitude: 47.2459,
    longitude: 8.165,
  },
  {
    zipCode: 6016,
    city: "Hellb\u00fchl",
    cantonShort: "LU",
    latitude: 47.0693,
    longitude: 8.1974,
  },
  {
    zipCode: 6017,
    city: "Ruswil",
    cantonShort: "LU",
    latitude: 47.0859,
    longitude: 8.1338,
  },
  {
    zipCode: 6018,
    city: "Buttisholz",
    cantonShort: "LU",
    latitude: 47.1141,
    longitude: 8.0923,
  },
  {
    zipCode: 6019,
    city: "Sigigen",
    cantonShort: "LU",
    latitude: 47.0591,
    longitude: 8.139,
  },
  {
    zipCode: 6022,
    city: "Grosswangen",
    cantonShort: "LU",
    latitude: 47.1335,
    longitude: 8.0549,
  },
  {
    zipCode: 6024,
    city: "Hildisrieden",
    cantonShort: "LU",
    latitude: 47.1475,
    longitude: 8.2316,
  },
  {
    zipCode: 6025,
    city: "Neudorf",
    cantonShort: "LU",
    latitude: 47.176,
    longitude: 8.2102,
  },
  {
    zipCode: 6203,
    city: "Sempach Station",
    cantonShort: "LU",
    latitude: 47.1172,
    longitude: 8.2006,
  },
  {
    zipCode: 6204,
    city: "Sempach",
    cantonShort: "LU",
    latitude: 47.1374,
    longitude: 8.1982,
  },
  {
    zipCode: 6205,
    city: "Eich",
    cantonShort: "LU",
    latitude: 47.1567,
    longitude: 8.1654,
  },
  {
    zipCode: 6206,
    city: "Neuenkirch",
    cantonShort: "LU",
    latitude: 47.0968,
    longitude: 8.201,
  },
  {
    zipCode: 6207,
    city: "Nottwil",
    cantonShort: "LU",
    latitude: 47.1303,
    longitude: 8.141,
  },
  {
    zipCode: 6207,
    city: "Nottwil Paraplegikerzentrum",
    cantonShort: "LU",
    latitude: 47.1279,
    longitude: 8.1355,
  },
  {
    zipCode: 6208,
    city: "Oberkirch LU",
    cantonShort: "LU",
    latitude: 47.1541,
    longitude: 8.1078,
  },
  {
    zipCode: 6210,
    city: "Sursee",
    cantonShort: "LU",
    latitude: 47.1765,
    longitude: 8.1046,
  },
  {
    zipCode: 6212,
    city: "St. Erhard",
    cantonShort: "LU",
    latitude: 47.1848,
    longitude: 8.0721,
  },
  {
    zipCode: 6212,
    city: "Kaltbach",
    cantonShort: "LU",
    latitude: 47.1824,
    longitude: 8.0575,
  },
  {
    zipCode: 6213,
    city: "Knutwil",
    cantonShort: "LU",
    latitude: 47.2011,
    longitude: 8.0703,
  },
  {
    zipCode: 6214,
    city: "Schenkon",
    cantonShort: "LU",
    latitude: 47.1794,
    longitude: 8.1333,
  },
  {
    zipCode: 6215,
    city: "Schwarzenbach LU",
    cantonShort: "LU",
    latitude: 47.237,
    longitude: 8.2129,
  },
  {
    zipCode: 6215,
    city: "Berom\u00fcnster",
    cantonShort: "LU",
    latitude: 47.2092,
    longitude: 8.2032,
  },
  {
    zipCode: 6216,
    city: "Mauensee",
    cantonShort: "LU",
    latitude: 47.1685,
    longitude: 8.0723,
  },
  {
    zipCode: 6221,
    city: "Rickenbach LU",
    cantonShort: "LU",
    latitude: 47.2197,
    longitude: 8.149,
  },
  {
    zipCode: 6222,
    city: "Gunzwil",
    cantonShort: "LU",
    latitude: 47.1917,
    longitude: 8.1716,
  },
  {
    zipCode: 6231,
    city: "Schlierbach",
    cantonShort: "LU",
    latitude: 47.2274,
    longitude: 8.1191,
  },
  {
    zipCode: 6232,
    city: "Geuensee",
    cantonShort: "LU",
    latitude: 47.2018,
    longitude: 8.1165,
  },
  {
    zipCode: 6233,
    city: "B\u00fcron",
    cantonShort: "LU",
    latitude: 47.2136,
    longitude: 8.0953,
  },
  {
    zipCode: 6234,
    city: "Kulmerau",
    cantonShort: "LU",
    latitude: 47.2531,
    longitude: 8.0894,
  },
  {
    zipCode: 6234,
    city: "Triengen",
    cantonShort: "LU",
    latitude: 47.2374,
    longitude: 8.0808,
  },
  {
    zipCode: 6235,
    city: "Winikon",
    cantonShort: "LU",
    latitude: 47.2335,
    longitude: 8.0419,
  },
  {
    zipCode: 6236,
    city: "Wilihof",
    cantonShort: "LU",
    latitude: 47.2206,
    longitude: 8.0628,
  },
  {
    zipCode: 4806,
    city: "Wikon",
    cantonShort: "LU",
    latitude: 47.2664,
    longitude: 7.9679,
  },
  {
    zipCode: 4915,
    city: "St. Urban",
    cantonShort: "LU",
    latitude: 47.2236,
    longitude: 7.8563,
  },
  {
    zipCode: 6122,
    city: "Menznau",
    cantonShort: "LU",
    latitude: 47.0775,
    longitude: 8.0373,
  },
  {
    zipCode: 6123,
    city: "Geiss",
    cantonShort: "LU",
    latitude: 47.0912,
    longitude: 8.062,
  },
  {
    zipCode: 6125,
    city: "Menzberg",
    cantonShort: "LU",
    latitude: 47.0403,
    longitude: 7.9916,
  },
  {
    zipCode: 6126,
    city: "Daiwil",
    cantonShort: "LU",
    latitude: 47.0858,
    longitude: 8.0124,
  },
  {
    zipCode: 6130,
    city: "Willisau",
    cantonShort: "LU",
    latitude: 47.1078,
    longitude: 7.984,
  },
  {
    zipCode: 6132,
    city: "Rohrmatt",
    cantonShort: "LU",
    latitude: 47.079,
    longitude: 7.9838,
  },
  {
    zipCode: 6133,
    city: "Hergiswil b. Willisau",
    cantonShort: "LU",
    latitude: 47.0659,
    longitude: 7.9528,
  },
  {
    zipCode: 6142,
    city: "Gettnau",
    cantonShort: "LU",
    latitude: 47.1385,
    longitude: 7.9666,
  },
  {
    zipCode: 6143,
    city: "Ohmstal",
    cantonShort: "LU",
    latitude: 47.1592,
    longitude: 7.9544,
  },
  {
    zipCode: 6144,
    city: "Zell LU",
    cantonShort: "LU",
    latitude: 47.1393,
    longitude: 7.9317,
  },
  {
    zipCode: 6145,
    city: "Fischbach LU",
    cantonShort: "LU",
    latitude: 47.1527,
    longitude: 7.9069,
  },
  {
    zipCode: 6146,
    city: "Grossdietwil",
    cantonShort: "LU",
    latitude: 47.1672,
    longitude: 7.8939,
  },
  {
    zipCode: 6147,
    city: "Altb\u00fcron",
    cantonShort: "LU",
    latitude: 47.1912,
    longitude: 7.8901,
  },
  {
    zipCode: 6152,
    city: "H\u00fcswil",
    cantonShort: "LU",
    latitude: 47.1262,
    longitude: 7.9066,
  },
  {
    zipCode: 6153,
    city: "Ufhusen",
    cantonShort: "LU",
    latitude: 47.1077,
    longitude: 7.8933,
  },
  {
    zipCode: 6154,
    city: "Hofstatt",
    cantonShort: "LU",
    latitude: 47.0777,
    longitude: 7.898,
  },
  {
    zipCode: 6156,
    city: "Luthern",
    cantonShort: "LU",
    latitude: 47.0559,
    longitude: 7.9072,
  },
  {
    zipCode: 6156,
    city: "Luthern Bad",
    cantonShort: "LU",
    latitude: 47.0247,
    longitude: 7.9209,
  },
  {
    zipCode: 6211,
    city: "Buchs LU",
    cantonShort: "LU",
    latitude: 47.2017,
    longitude: 8.0386,
  },
  {
    zipCode: 6217,
    city: "Kottwil",
    cantonShort: "LU",
    latitude: 47.1632,
    longitude: 8.0414,
  },
  {
    zipCode: 6218,
    city: "Ettiswil",
    cantonShort: "LU",
    latitude: 47.1488,
    longitude: 8.0184,
  },
  {
    zipCode: 6242,
    city: "Wauwil",
    cantonShort: "LU",
    latitude: 47.1837,
    longitude: 8.0315,
  },
  {
    zipCode: 6243,
    city: "Egolzwil",
    cantonShort: "LU",
    latitude: 47.184,
    longitude: 8.0036,
  },
  {
    zipCode: 6244,
    city: "Nebikon",
    cantonShort: "LU",
    latitude: 47.1909,
    longitude: 7.9792,
  },
  {
    zipCode: 6245,
    city: "Ebersecken",
    cantonShort: "LU",
    latitude: 47.1813,
    longitude: 7.9377,
  },
  {
    zipCode: 6246,
    city: "Altishofen",
    cantonShort: "LU",
    latitude: 47.2023,
    longitude: 7.9599,
  },
  {
    zipCode: 6247,
    city: "Sch\u00f6tz",
    cantonShort: "LU",
    latitude: 47.1682,
    longitude: 7.987,
  },
  {
    zipCode: 6248,
    city: "Alberswil",
    cantonShort: "LU",
    latitude: 47.1472,
    longitude: 7.9952,
  },
  {
    zipCode: 6252,
    city: "Dagmersellen",
    cantonShort: "LU",
    latitude: 47.2159,
    longitude: 7.9951,
  },
  {
    zipCode: 6253,
    city: "Uffikon",
    cantonShort: "LU",
    latitude: 47.2157,
    longitude: 8.0245,
  },
  {
    zipCode: 6260,
    city: "Reidermoos",
    cantonShort: "LU",
    latitude: 47.2485,
    longitude: 7.999,
  },
  {
    zipCode: 6260,
    city: "Hintermoos",
    cantonShort: "LU",
    latitude: 47.2691,
    longitude: 7.9962,
  },
  {
    zipCode: 6260,
    city: "Mehlsecken",
    cantonShort: "LU",
    latitude: 47.2451,
    longitude: 7.958,
  },
  {
    zipCode: 6260,
    city: "Reiden",
    cantonShort: "LU",
    latitude: 47.2435,
    longitude: 7.9767,
  },
  {
    zipCode: 6262,
    city: "Langnau b. Reiden",
    cantonShort: "LU",
    latitude: 47.2298,
    longitude: 7.9489,
  },
  {
    zipCode: 6263,
    city: "Richenthal",
    cantonShort: "LU",
    latitude: 47.2105,
    longitude: 7.932,
  },
  {
    zipCode: 6264,
    city: "Pfaffnau",
    cantonShort: "LU",
    latitude: 47.2297,
    longitude: 7.9009,
  },
  {
    zipCode: 6265,
    city: "Roggliswil",
    cantonShort: "LU",
    latitude: 47.2114,
    longitude: 7.8859,
  },
  {
    zipCode: 6105,
    city: "Schachen LU",
    cantonShort: "LU",
    latitude: 47.0292,
    longitude: 8.1289,
  },
  {
    zipCode: 6106,
    city: "Werthenstein",
    cantonShort: "LU",
    latitude: 47.0472,
    longitude: 8.1044,
  },
  {
    zipCode: 6110,
    city: "Wolhusen",
    cantonShort: "LU",
    latitude: 47.0547,
    longitude: 8.0726,
  },
  {
    zipCode: 6110,
    city: "Fontannen b. Wolhusen",
    cantonShort: "LU",
    latitude: 47.0203,
    longitude: 7.9771,
  },
  {
    zipCode: 6112,
    city: "Doppleschwand",
    cantonShort: "LU",
    latitude: 47.0119,
    longitude: 8.0508,
  },
  {
    zipCode: 6113,
    city: "Romoos",
    cantonShort: "LU",
    latitude: 47.007,
    longitude: 8.0052,
  },
  {
    zipCode: 6114,
    city: "Steinhuserberg",
    cantonShort: "LU",
    latitude: 47.046,
    longitude: 8.0446,
  },
  {
    zipCode: 6160,
    city: "Entlebuch Businesszentrum",
    cantonShort: "LU",
    latitude: 46.9762,
    longitude: 8.1079,
  },
  {
    zipCode: 6160,
    city: "Entlebuch",
    cantonShort: "LU",
    latitude: 46.9762,
    longitude: 8.1079,
  },
  {
    zipCode: 6162,
    city: "Rengg",
    cantonShort: "LU",
    latitude: 47.0003,
    longitude: 8.1102,
  },
  {
    zipCode: 6162,
    city: "Entlebuch",
    cantonShort: "LU",
    latitude: 46.9882,
    longitude: 8.0746,
  },
  {
    zipCode: 6162,
    city: "Finsterwald b. Entlebuch",
    cantonShort: "LU",
    latitude: 46.9418,
    longitude: 8.1178,
  },
  {
    zipCode: 6163,
    city: "Ebnet",
    cantonShort: "LU",
    latitude: 47.0211,
    longitude: 8.0831,
  },
  {
    zipCode: 6166,
    city: "Hasle LU",
    cantonShort: "LU",
    latitude: 46.9592,
    longitude: 8.067,
  },
  {
    zipCode: 6167,
    city: "Bramboden",
    cantonShort: "LU",
    latitude: 46.9851,
    longitude: 7.9727,
  },
  {
    zipCode: 6170,
    city: "Sch\u00fcpfheim",
    cantonShort: "LU",
    latitude: 46.9462,
    longitude: 8.0121,
  },
  {
    zipCode: 6173,
    city: "Fl\u00fchli LU",
    cantonShort: "LU",
    latitude: 46.8826,
    longitude: 8.0249,
  },
  {
    zipCode: 6174,
    city: "S\u00f6renberg",
    cantonShort: "LU",
    latitude: 46.8161,
    longitude: 8.0089,
  },
  {
    zipCode: 6182,
    city: "Escholzmatt",
    cantonShort: "LU",
    latitude: 46.9238,
    longitude: 7.9427,
  },
  {
    zipCode: 6192,
    city: "Wiggen",
    cantonShort: "LU",
    latitude: 46.8779,
    longitude: 7.944,
  },
  {
    zipCode: 6196,
    city: "Marbach LU",
    cantonShort: "LU",
    latitude: 46.8557,
    longitude: 7.9091,
  },
  {
    zipCode: 2012,
    city: "Auvernier",
    cantonShort: "NE",
    latitude: 46.9702,
    longitude: 6.8926,
  },
  {
    zipCode: 2013,
    city: "Colombier NE",
    cantonShort: "NE",
    latitude: 46.9645,
    longitude: 6.8726,
  },
  {
    zipCode: 2014,
    city: "B\u00f4le",
    cantonShort: "NE",
    latitude: 46.9688,
    longitude: 6.8372,
  },
  {
    zipCode: 2015,
    city: "Areuse",
    cantonShort: "NE",
    latitude: 46.9462,
    longitude: 6.8898,
  },
  {
    zipCode: 2016,
    city: "Cortaillod",
    cantonShort: "NE",
    latitude: 46.9329,
    longitude: 6.8617,
  },
  {
    zipCode: 2017,
    city: "Boudry",
    cantonShort: "NE",
    latitude: 46.9532,
    longitude: 6.8133,
  },
  {
    zipCode: 2019,
    city: "Rochefort",
    cantonShort: "NE",
    latitude: 46.9894,
    longitude: 6.8002,
  },
  {
    zipCode: 2019,
    city: "Chambrelien",
    cantonShort: "NE",
    latitude: 46.9702,
    longitude: 6.8137,
  },
  {
    zipCode: 2022,
    city: "Bevaix",
    cantonShort: "NE",
    latitude: 46.9228,
    longitude: 6.8195,
  },
  {
    zipCode: 2023,
    city: "Gorgier",
    cantonShort: "NE",
    latitude: 46.9236,
    longitude: 6.7675,
  },
  {
    zipCode: 2024,
    city: "St-Aubin-Sauges",
    cantonShort: "NE",
    latitude: 46.8993,
    longitude: 6.7648,
  },
  {
    zipCode: 2025,
    city: "Chez-le-Bart",
    cantonShort: "NE",
    latitude: 46.8966,
    longitude: 6.8092,
  },
  {
    zipCode: 2027,
    city: "Montalchez",
    cantonShort: "NE",
    latitude: 46.9092,
    longitude: 6.7345,
  },
  {
    zipCode: 2027,
    city: "Fresens",
    cantonShort: "NE",
    latitude: 46.8886,
    longitude: 6.7479,
  },
  {
    zipCode: 2028,
    city: "Vaumarcus",
    cantonShort: "NE",
    latitude: 46.8705,
    longitude: 6.7727,
  },
  {
    zipCode: 2034,
    city: "Peseux",
    cantonShort: "NE",
    latitude: 46.9956,
    longitude: 6.8894,
  },
  {
    zipCode: 2035,
    city: "Corcelles NE",
    cantonShort: "NE",
    latitude: 46.9865,
    longitude: 6.8602,
  },
  {
    zipCode: 2036,
    city: "Cormondr\u00e8che",
    cantonShort: "NE",
    latitude: 46.9808,
    longitude: 6.8692,
  },
  {
    zipCode: 2037,
    city: "Montezillon",
    cantonShort: "NE",
    latitude: 46.9863,
    longitude: 6.837,
  },
  {
    zipCode: 2149,
    city: "Champ-du-Moulin",
    cantonShort: "NE",
    latitude: 46.9524,
    longitude: 6.7683,
  },
  {
    zipCode: 2149,
    city: "Fretereules",
    cantonShort: "NE",
    latitude: 46.9605,
    longitude: 6.7641,
  },
  {
    zipCode: 2149,
    city: "Brot-Dessous",
    cantonShort: "NE",
    latitude: 46.9631,
    longitude: 6.759,
  },
  {
    zipCode: 2300,
    city: "La Chaux-de-Fonds",
    cantonShort: "NE",
    latitude: 47.1135,
    longitude: 6.8347,
  },
  {
    zipCode: 2300,
    city: "La Cibourg",
    cantonShort: "NE",
    latitude: 47.119,
    longitude: 6.877,
  },
  {
    zipCode: 2301,
    city: "La Chaux-de-Fonds",
    cantonShort: "NE",
    latitude: 47.0999,
    longitude: 6.8259,
  },
  {
    zipCode: 2303,
    city: "La Chaux-de-Fonds",
    cantonShort: "NE",
    latitude: 47.0999,
    longitude: 6.8259,
  },
  {
    zipCode: 2304,
    city: "La Chaux-de-Fonds",
    cantonShort: "NE",
    latitude: 47.0999,
    longitude: 6.8259,
  },
  {
    zipCode: 2314,
    city: "La Sagne NE",
    cantonShort: "NE",
    latitude: 47.0474,
    longitude: 6.8021,
  },
  {
    zipCode: 2322,
    city: "Le Cr\u00eat-du-Locle",
    cantonShort: "NE",
    latitude: 47.0805,
    longitude: 6.7818,
  },
  {
    zipCode: 2325,
    city: "Les Planchettes",
    cantonShort: "NE",
    latitude: 47.1082,
    longitude: 6.7714,
  },
  {
    zipCode: 2333,
    city: "La Cibourg",
    cantonShort: "NE",
    latitude: 47.1242,
    longitude: 6.8958,
  },
  {
    zipCode: 2616,
    city: "La Cibourg",
    cantonShort: "NE",
    latitude: 47.1156,
    longitude: 6.8903,
  },
  {
    zipCode: 2316,
    city: "Petit-Martel",
    cantonShort: "NE",
    latitude: 47.0132,
    longitude: 6.7516,
  },
  {
    zipCode: 2316,
    city: "Les Ponts-de-Martel",
    cantonShort: "NE",
    latitude: 46.9872,
    longitude: 6.7111,
  },
  {
    zipCode: 2318,
    city: "Brot-Plamboz",
    cantonShort: "NE",
    latitude: 46.9831,
    longitude: 6.7486,
  },
  {
    zipCode: 2400,
    city: "Le Locle",
    cantonShort: "NE",
    latitude: 47.0548,
    longitude: 6.7513,
  },
  {
    zipCode: 2400,
    city: "Le Pr\u00e9voux",
    cantonShort: "NE",
    latitude: 47.0352,
    longitude: 6.7127,
  },
  {
    zipCode: 2400,
    city: "Le Locle",
    cantonShort: "NE",
    latitude: 47.0548,
    longitude: 6.7513,
  },
  {
    zipCode: 2405,
    city: "La Chaux-du-Milieu",
    cantonShort: "NE",
    latitude: 47.0058,
    longitude: 6.6981,
  },
  {
    zipCode: 2406,
    city: "La Br\u00e9vine",
    cantonShort: "NE",
    latitude: 46.9719,
    longitude: 6.6029,
  },
  {
    zipCode: 2406,
    city: "Le Brouillet",
    cantonShort: "NE",
    latitude: 46.9559,
    longitude: 6.5247,
  },
  {
    zipCode: 2406,
    city: "La Ch\u00e2tagne",
    cantonShort: "NE",
    latitude: 46.9784,
    longitude: 6.6424,
  },
  {
    zipCode: 2406,
    city: "Les Taill\u00e8res",
    cantonShort: "NE",
    latitude: 46.9728,
    longitude: 6.5759,
  },
  {
    zipCode: 2414,
    city: "Le Cerneux-P\u00e9quignot",
    cantonShort: "NE",
    latitude: 47.017,
    longitude: 6.6702,
  },
  {
    zipCode: 2416,
    city: "Les Brenets",
    cantonShort: "NE",
    latitude: 47.0745,
    longitude: 6.7242,
  },
  {
    zipCode: 2000,
    city: "Neuch\u00e2tel",
    cantonShort: "NE",
    latitude: 46.9884,
    longitude: 6.9413,
  },
  {
    zipCode: 2001,
    city: "Neuch\u00e2tel 1",
    cantonShort: "NE",
    latitude: 47.0094,
    longitude: 6.9381,
  },
  {
    zipCode: 2002,
    city: "Neuch\u00e2tel 2",
    cantonShort: "NE",
    latitude: 47.0094,
    longitude: 6.9381,
  },
  {
    zipCode: 2010,
    city: "Neuch\u00e2tel OFS",
    cantonShort: "NE",
    latitude: 47.0094,
    longitude: 6.9381,
  },
  {
    zipCode: 2067,
    city: "Chaumont",
    cantonShort: "NE",
    latitude: 47.0404,
    longitude: 6.9636,
  },
  {
    zipCode: 2068,
    city: "Hauterive NE",
    cantonShort: "NE",
    latitude: 47.0104,
    longitude: 6.9757,
  },
  {
    zipCode: 2072,
    city: "St-Blaise",
    cantonShort: "NE",
    latitude: 47.0351,
    longitude: 6.9969,
  },
  {
    zipCode: 2073,
    city: "Enges",
    cantonShort: "NE",
    latitude: 47.0722,
    longitude: 7.0136,
  },
  {
    zipCode: 2074,
    city: "Marin-Epagnier",
    cantonShort: "NE",
    latitude: 46.9992,
    longitude: 7.0132,
  },
  {
    zipCode: 2074,
    city: "Marin-Centre",
    cantonShort: "NE",
    latitude: 47.0137,
    longitude: 7.0165,
  },
  {
    zipCode: 2075,
    city: "Thielle",
    cantonShort: "NE",
    latitude: 47.0183,
    longitude: 7.0263,
  },
  {
    zipCode: 2075,
    city: "Wavre",
    cantonShort: "NE",
    latitude: 47.0235,
    longitude: 7.0202,
  },
  {
    zipCode: 2087,
    city: "Cornaux NE",
    cantonShort: "NE",
    latitude: 47.0348,
    longitude: 7.0204,
  },
  {
    zipCode: 2088,
    city: "Cressier NE",
    cantonShort: "NE",
    latitude: 47.0491,
    longitude: 7.0367,
  },
  {
    zipCode: 2523,
    city: "Ligni\u00e8res",
    cantonShort: "NE",
    latitude: 47.0899,
    longitude: 7.0475,
  },
  {
    zipCode: 2525,
    city: "Le Landeron",
    cantonShort: "NE",
    latitude: 47.0604,
    longitude: 7.0651,
  },
  {
    zipCode: 2037,
    city: "Montmollin",
    cantonShort: "NE",
    latitude: 46.9988,
    longitude: 6.8432,
  },
  {
    zipCode: 2042,
    city: "Valangin",
    cantonShort: "NE",
    latitude: 47.0138,
    longitude: 6.8995,
  },
  {
    zipCode: 2043,
    city: "Boudevilliers",
    cantonShort: "NE",
    latitude: 47.0289,
    longitude: 6.8837,
  },
  {
    zipCode: 2046,
    city: "Fontaines NE",
    cantonShort: "NE",
    latitude: 47.0427,
    longitude: 6.9018,
  },
  {
    zipCode: 2052,
    city: "La Vue-des-Alpes",
    cantonShort: "NE",
    latitude: 47.0655,
    longitude: 6.8685,
  },
  {
    zipCode: 2052,
    city: "Fontainemelon",
    cantonShort: "NE",
    latitude: 47.0555,
    longitude: 6.8877,
  },
  {
    zipCode: 2053,
    city: "Cernier",
    cantonShort: "NE",
    latitude: 47.0588,
    longitude: 6.904,
  },
  {
    zipCode: 2054,
    city: "Les Vieux-Pr\u00e9s",
    cantonShort: "NE",
    latitude: 47.0904,
    longitude: 6.9297,
  },
  {
    zipCode: 2054,
    city: "Ch\u00e9zard-St-Martin",
    cantonShort: "NE",
    latitude: 47.0638,
    longitude: 6.9274,
  },
  {
    zipCode: 2056,
    city: "Dombresson",
    cantonShort: "NE",
    latitude: 47.0699,
    longitude: 6.9569,
  },
  {
    zipCode: 2057,
    city: "Villiers",
    cantonShort: "NE",
    latitude: 47.081,
    longitude: 6.9846,
  },
  {
    zipCode: 2058,
    city: "Le P\u00e2quier NE",
    cantonShort: "NE",
    latitude: 47.1059,
    longitude: 6.9821,
  },
  {
    zipCode: 2063,
    city: "Engollon",
    cantonShort: "NE",
    latitude: 47.0444,
    longitude: 6.9246,
  },
  {
    zipCode: 2063,
    city: "Fenin",
    cantonShort: "NE",
    latitude: 47.0233,
    longitude: 6.9192,
  },
  {
    zipCode: 2063,
    city: "Saules",
    cantonShort: "NE",
    latitude: 47.0384,
    longitude: 6.9388,
  },
  {
    zipCode: 2063,
    city: "Vilars NE",
    cantonShort: "NE",
    latitude: 47.0325,
    longitude: 6.9308,
  },
  {
    zipCode: 2065,
    city: "Savagnier",
    cantonShort: "NE",
    latitude: 47.0509,
    longitude: 6.9568,
  },
  {
    zipCode: 2206,
    city: "Les Geneveys-sur-Coffrane",
    cantonShort: "NE",
    latitude: 47.0186,
    longitude: 6.8341,
  },
  {
    zipCode: 2207,
    city: "Coffrane",
    cantonShort: "NE",
    latitude: 47.0088,
    longitude: 6.8663,
  },
  {
    zipCode: 2208,
    city: "Les Hauts-Geneveys",
    cantonShort: "NE",
    latitude: 47.0417,
    longitude: 6.861,
  },
  {
    zipCode: 2103,
    city: "Noiraigue",
    cantonShort: "NE",
    latitude: 46.949,
    longitude: 6.7247,
  },
  {
    zipCode: 2105,
    city: "Travers",
    cantonShort: "NE",
    latitude: 46.9471,
    longitude: 6.6757,
  },
  {
    zipCode: 2108,
    city: "Couvet",
    cantonShort: "NE",
    latitude: 46.9189,
    longitude: 6.6543,
  },
  {
    zipCode: 2112,
    city: "M\u00f4tiers NE",
    cantonShort: "NE",
    latitude: 46.9044,
    longitude: 6.6176,
  },
  {
    zipCode: 2113,
    city: "Boveresse",
    cantonShort: "NE",
    latitude: 46.9168,
    longitude: 6.5996,
  },
  {
    zipCode: 2114,
    city: "Fleurier",
    cantonShort: "NE",
    latitude: 46.8949,
    longitude: 6.5893,
  },
  {
    zipCode: 2115,
    city: "Buttes",
    cantonShort: "NE",
    latitude: 46.8795,
    longitude: 6.5432,
  },
  {
    zipCode: 2116,
    city: "Mont-de-Buttes",
    cantonShort: "NE",
    latitude: 46.888,
    longitude: 6.5197,
  },
  {
    zipCode: 2117,
    city: "La C\u00f4te-aux-F\u00e9es",
    cantonShort: "NE",
    latitude: 46.8634,
    longitude: 6.4853,
  },
  {
    zipCode: 2123,
    city: "St-Sulpice NE",
    cantonShort: "NE",
    latitude: 46.9088,
    longitude: 6.56,
  },
  {
    zipCode: 2124,
    city: "Les Sagnettes",
    cantonShort: "NE",
    latitude: 46.9392,
    longitude: 6.5957,
  },
  {
    zipCode: 2126,
    city: "Les Verri\u00e8res",
    cantonShort: "NE",
    latitude: 46.9144,
    longitude: 6.4748,
  },
  {
    zipCode: 2127,
    city: "Les Bayards",
    cantonShort: "NE",
    latitude: 46.9254,
    longitude: 6.5382,
  },
  {
    zipCode: 6052,
    city: "Hergiswil NW",
    cantonShort: "NW",
    latitude: 46.9859,
    longitude: 8.2813,
  },
  {
    zipCode: 6362,
    city: "Stansstad",
    cantonShort: "NW",
    latitude: 46.974,
    longitude: 8.3314,
  },
  {
    zipCode: 6363,
    city: "Obb\u00fcrgen",
    cantonShort: "NW",
    latitude: 46.9854,
    longitude: 8.3712,
  },
  {
    zipCode: 6363,
    city: "B\u00fcrgenstock",
    cantonShort: "NW",
    latitude: 46.9963,
    longitude: 8.3846,
  },
  {
    zipCode: 6363,
    city: "F\u00fcrigen",
    cantonShort: "NW",
    latitude: 46.9837,
    longitude: 8.349,
  },
  {
    zipCode: 6365,
    city: "Kehrsiten",
    cantonShort: "NW",
    latitude: 47.0046,
    longitude: 8.3827,
  },
  {
    zipCode: 6370,
    city: "Oberdorf NW",
    cantonShort: "NW",
    latitude: 46.9518,
    longitude: 8.386,
  },
  {
    zipCode: 6370,
    city: "Stans",
    cantonShort: "NW",
    latitude: 46.9588,
    longitude: 8.3633,
  },
  {
    zipCode: 6371,
    city: "Stans",
    cantonShort: "NW",
    latitude: 46.9581,
    longitude: 8.3661,
  },
  {
    zipCode: 6372,
    city: "Ennetmoos",
    cantonShort: "NW",
    latitude: 46.9448,
    longitude: 8.3225,
  },
  {
    zipCode: 6373,
    city: "Ennetb\u00fcrgen",
    cantonShort: "NW",
    latitude: 46.9945,
    longitude: 8.4308,
  },
  {
    zipCode: 6374,
    city: "Buochs",
    cantonShort: "NW",
    latitude: 46.9672,
    longitude: 8.4227,
  },
  {
    zipCode: 6375,
    city: "Beckenried",
    cantonShort: "NW",
    latitude: 46.9502,
    longitude: 8.4716,
  },
  {
    zipCode: 6376,
    city: "Emmetten",
    cantonShort: "NW",
    latitude: 46.946,
    longitude: 8.5203,
  },
  {
    zipCode: 6382,
    city: "B\u00fcren NW",
    cantonShort: "NW",
    latitude: 46.9407,
    longitude: 8.408,
  },
  {
    zipCode: 6383,
    city: "Wiesenberg",
    cantonShort: "NW",
    latitude: 46.915,
    longitude: 8.3551,
  },
  {
    zipCode: 6383,
    city: "Niederrickenbach",
    cantonShort: "NW",
    latitude: 46.9193,
    longitude: 8.4391,
  },
  {
    zipCode: 6383,
    city: "Dallenwil",
    cantonShort: "NW",
    latitude: 46.9223,
    longitude: 8.3836,
  },
  {
    zipCode: 6383,
    city: "Wirzweli",
    cantonShort: "NW",
    latitude: 46.9121,
    longitude: 8.3632,
  },
  {
    zipCode: 6386,
    city: "Wolfenschiessen",
    cantonShort: "NW",
    latitude: 46.8996,
    longitude: 8.3825,
  },
  {
    zipCode: 6387,
    city: "Oberrickenbach",
    cantonShort: "NW",
    latitude: 46.8787,
    longitude: 8.4401,
  },
  {
    zipCode: 6010,
    city: "Pilatus Kulm",
    cantonShort: "OW",
    latitude: 46.979,
    longitude: 8.2548,
  },
  {
    zipCode: 6010,
    city: "Kriens",
    cantonShort: "OW",
    latitude: 47.0199,
    longitude: 8.2682,
  },
  {
    zipCode: 6053,
    city: "Alpnachstad",
    cantonShort: "OW",
    latitude: 46.961,
    longitude: 8.291,
  },
  {
    zipCode: 6055,
    city: "Alpnach Dorf",
    cantonShort: "OW",
    latitude: 46.949,
    longitude: 8.2264,
  },
  {
    zipCode: 6056,
    city: "K\u00e4giswil",
    cantonShort: "OW",
    latitude: 46.917,
    longitude: 8.2553,
  },
  {
    zipCode: 6060,
    city: "Ramersberg",
    cantonShort: "OW",
    latitude: 46.9136,
    longitude: 8.2083,
  },
  {
    zipCode: 6060,
    city: "Sarnen 2",
    cantonShort: "OW",
    latitude: 46.8985,
    longitude: 8.1765,
  },
  {
    zipCode: 6060,
    city: "Sarnen",
    cantonShort: "OW",
    latitude: 46.8958,
    longitude: 8.2474,
  },
  {
    zipCode: 6061,
    city: "Sarnen 1",
    cantonShort: "OW",
    latitude: 46.8985,
    longitude: 8.1765,
  },
  {
    zipCode: 6062,
    city: "Wilen (Sarnen)",
    cantonShort: "OW",
    latitude: 46.8705,
    longitude: 8.207,
  },
  {
    zipCode: 6063,
    city: "Stalden (Sarnen)",
    cantonShort: "OW",
    latitude: 46.8944,
    longitude: 8.1489,
  },
  {
    zipCode: 6064,
    city: "Kerns",
    cantonShort: "OW",
    latitude: 46.9071,
    longitude: 8.3028,
  },
  {
    zipCode: 6066,
    city: "St. Niklausen OW",
    cantonShort: "OW",
    latitude: 46.8732,
    longitude: 8.3026,
  },
  {
    zipCode: 6067,
    city: "Melchtal",
    cantonShort: "OW",
    latitude: 46.8235,
    longitude: 8.3012,
  },
  {
    zipCode: 6068,
    city: "Melchsee-Frutt",
    cantonShort: "OW",
    latitude: 46.7821,
    longitude: 8.2988,
  },
  {
    zipCode: 6072,
    city: "Sachseln",
    cantonShort: "OW",
    latitude: 46.8319,
    longitude: 8.2424,
  },
  {
    zipCode: 6073,
    city: "Fl\u00fceli-Ranft",
    cantonShort: "OW",
    latitude: 46.874,
    longitude: 8.2596,
  },
  {
    zipCode: 6074,
    city: "Giswil",
    cantonShort: "OW",
    latitude: 46.8375,
    longitude: 8.122,
  },
  {
    zipCode: 6078,
    city: "B\u00fcrglen OW",
    cantonShort: "OW",
    latitude: 46.8113,
    longitude: 8.1674,
  },
  {
    zipCode: 6078,
    city: "Lungern",
    cantonShort: "OW",
    latitude: 46.7848,
    longitude: 8.1673,
  },
  {
    zipCode: 6388,
    city: "Grafenort",
    cantonShort: "OW",
    latitude: 46.8634,
    longitude: 8.3692,
  },
  {
    zipCode: 6390,
    city: "Engelberg",
    cantonShort: "OW",
    latitude: 46.8145,
    longitude: 8.4412,
  },
  {
    zipCode: 6391,
    city: "Engelberg",
    cantonShort: "OW",
    latitude: 46.8211,
    longitude: 8.4013,
  },
  {
    zipCode: 9000,
    city: "St. Gallen",
    cantonShort: "SG",
    latitude: 47.424,
    longitude: 9.3685,
  },
  {
    zipCode: 9001,
    city: "St. Gallen",
    cantonShort: "SG",
    latitude: 47.4221,
    longitude: 9.3755,
  },
  {
    zipCode: 9004,
    city: "St. Gallen",
    cantonShort: "SG",
    latitude: 47.4221,
    longitude: 9.3755,
  },
  {
    zipCode: 9006,
    city: "St. Gallen",
    cantonShort: "SG",
    latitude: 47.4221,
    longitude: 9.3755,
  },
  {
    zipCode: 9007,
    city: "St. Gallen",
    cantonShort: "SG",
    latitude: 47.4221,
    longitude: 9.3755,
  },
  {
    zipCode: 9008,
    city: "St. Gallen",
    cantonShort: "SG",
    latitude: 47.4413,
    longitude: 9.3918,
  },
  {
    zipCode: 9010,
    city: "St. Gallen",
    cantonShort: "SG",
    latitude: 47.4419,
    longitude: 9.3736,
  },
  {
    zipCode: 9011,
    city: "St. Gallen",
    cantonShort: "SG",
    latitude: 47.4154,
    longitude: 9.4021,
  },
  {
    zipCode: 9012,
    city: "St. Gallen",
    cantonShort: "SG",
    latitude: 47.4096,
    longitude: 9.3636,
  },
  {
    zipCode: 9013,
    city: "St. Gallen",
    cantonShort: "SG",
    latitude: 47.4221,
    longitude: 9.3755,
  },
  {
    zipCode: 9014,
    city: "St. Gallen",
    cantonShort: "SG",
    latitude: 47.4081,
    longitude: 9.3356,
  },
  {
    zipCode: 9015,
    city: "St. Gallen",
    cantonShort: "SG",
    latitude: 47.408,
    longitude: 9.3072,
  },
  {
    zipCode: 9016,
    city: "St. Gallen",
    cantonShort: "SG",
    latitude: 47.4418,
    longitude: 9.4154,
  },
  {
    zipCode: 9020,
    city: "St. Gallen",
    cantonShort: "SG",
    latitude: 47.4221,
    longitude: 9.3755,
  },
  {
    zipCode: 9021,
    city: "St. Gallen MS",
    cantonShort: "SG",
    latitude: 47.4221,
    longitude: 9.3755,
  },
  {
    zipCode: 9023,
    city: "St. Gallen",
    cantonShort: "SG",
    latitude: 47.4221,
    longitude: 9.3755,
  },
  {
    zipCode: 9024,
    city: "St. Gallen Presse-Serv.G\u00fcll",
    cantonShort: "SG",
    latitude: 47.4221,
    longitude: 9.3755,
  },
  {
    zipCode: 9024,
    city: "St. Gallen",
    cantonShort: "SG",
    latitude: 47.4221,
    longitude: 9.3755,
  },
  {
    zipCode: 9026,
    city: "St. Gallen K\u00fcnzler AG",
    cantonShort: "SG",
    latitude: 47.4221,
    longitude: 9.3755,
  },
  {
    zipCode: 9026,
    city: "St. Gallen K AG",
    cantonShort: "SG",
    latitude: 47.4221,
    longitude: 9.3755,
  },
  {
    zipCode: 9027,
    city: "St. Gallen",
    cantonShort: "SG",
    latitude: 47.4221,
    longitude: 9.3755,
  },
  {
    zipCode: 9027,
    city: "St. Gallen Mona Versand",
    cantonShort: "SG",
    latitude: 47.4221,
    longitude: 9.3755,
  },
  {
    zipCode: 9028,
    city: "St. Gallen",
    cantonShort: "SG",
    latitude: 47.4221,
    longitude: 9.3755,
  },
  {
    zipCode: 9029,
    city: "St. Gallen",
    cantonShort: "SG",
    latitude: 47.4221,
    longitude: 9.3755,
  },
  {
    zipCode: 9029,
    city: "St. Gallen Sonderdienste",
    cantonShort: "SG",
    latitude: 47.4221,
    longitude: 9.3755,
  },
  {
    zipCode: 9030,
    city: "Abtwil SG",
    cantonShort: "SG",
    latitude: 47.4283,
    longitude: 9.3204,
  },
  {
    zipCode: 9030,
    city: "St. Josefen",
    cantonShort: "SG",
    latitude: 47.426,
    longitude: 9.3373,
  },
  {
    zipCode: 9032,
    city: "Engelburg",
    cantonShort: "SG",
    latitude: 47.443,
    longitude: 9.3415,
  },
  {
    zipCode: 9034,
    city: "Eggersriet",
    cantonShort: "SG",
    latitude: 47.4414,
    longitude: 9.4623,
  },
  {
    zipCode: 9036,
    city: "Grub SG",
    cantonShort: "SG",
    latitude: 47.4543,
    longitude: 9.5098,
  },
  {
    zipCode: 9200,
    city: "Gossau SG",
    cantonShort: "SG",
    latitude: 47.4178,
    longitude: 9.2492,
  },
  {
    zipCode: 9200,
    city: "Gossau SG 2",
    cantonShort: "SG",
    latitude: 47.421,
    longitude: 9.2536,
  },
  {
    zipCode: 9201,
    city: "Gossau SG",
    cantonShort: "SG",
    latitude: 47.421,
    longitude: 9.2536,
  },
  {
    zipCode: 9204,
    city: "Andwil SG",
    cantonShort: "SG",
    latitude: 47.4372,
    longitude: 9.279,
  },
  {
    zipCode: 9205,
    city: "Waldkirch",
    cantonShort: "SG",
    latitude: 47.463,
    longitude: 9.2879,
  },
  {
    zipCode: 9212,
    city: "Arnegg",
    cantonShort: "SG",
    latitude: 47.4425,
    longitude: 9.2524,
  },
  {
    zipCode: 9300,
    city: "Wittenbach",
    cantonShort: "SG",
    latitude: 47.4661,
    longitude: 9.3798,
  },
  {
    zipCode: 9301,
    city: "Wittenbach",
    cantonShort: "SG",
    latitude: 47.4611,
    longitude: 9.386,
  },
  {
    zipCode: 9304,
    city: "Bernhardzell",
    cantonShort: "SG",
    latitude: 47.4669,
    longitude: 9.3409,
  },
  {
    zipCode: 9308,
    city: "L\u00f6mmenschwil",
    cantonShort: "SG",
    latitude: 47.4966,
    longitude: 9.3455,
  },
  {
    zipCode: 9312,
    city: "H\u00e4ggenschwil",
    cantonShort: "SG",
    latitude: 47.4907,
    longitude: 9.3353,
  },
  {
    zipCode: 9313,
    city: "Muolen",
    cantonShort: "SG",
    latitude: 47.5171,
    longitude: 9.3179,
  },
  {
    zipCode: 9033,
    city: "Untereggen",
    cantonShort: "SG",
    latitude: 47.4538,
    longitude: 9.4543,
  },
  {
    zipCode: 9305,
    city: "Berg SG",
    cantonShort: "SG",
    latitude: 47.4862,
    longitude: 9.4122,
  },
  {
    zipCode: 9323,
    city: "Steinach",
    cantonShort: "SG",
    latitude: 47.5065,
    longitude: 9.4497,
  },
  {
    zipCode: 9327,
    city: "T\u00fcbach",
    cantonShort: "SG",
    latitude: 47.4825,
    longitude: 9.4538,
  },
  {
    zipCode: 9400,
    city: "Rorschach",
    cantonShort: "SG",
    latitude: 47.4972,
    longitude: 9.5037,
  },
  {
    zipCode: 9401,
    city: "Rorschach",
    cantonShort: "SG",
    latitude: 47.478,
    longitude: 9.4903,
  },
  {
    zipCode: 9402,
    city: "M\u00f6rschwil",
    cantonShort: "SG",
    latitude: 47.4675,
    longitude: 9.426,
  },
  {
    zipCode: 9403,
    city: "Goldach",
    cantonShort: "SG",
    latitude: 47.4859,
    longitude: 9.4782,
  },
  {
    zipCode: 9404,
    city: "Rorschacherberg",
    cantonShort: "SG",
    latitude: 47.4803,
    longitude: 9.5104,
  },
  {
    zipCode: 9422,
    city: "Staad SG",
    cantonShort: "SG",
    latitude: 47.492,
    longitude: 9.5371,
  },
  {
    zipCode: 9423,
    city: "Altenrhein",
    cantonShort: "SG",
    latitude: 47.5158,
    longitude: 9.5473,
  },
  {
    zipCode: 9425,
    city: "Thal",
    cantonShort: "SG",
    latitude: 47.4732,
    longitude: 9.5649,
  },
  {
    zipCode: 9411,
    city: "Reute AR",
    cantonShort: "SG",
    latitude: 47.4088,
    longitude: 9.5662,
  },
  {
    zipCode: 9424,
    city: "Rheineck",
    cantonShort: "SG",
    latitude: 47.4688,
    longitude: 9.585,
  },
  {
    zipCode: 9430,
    city: "St. Margrethen SG",
    cantonShort: "SG",
    latitude: 47.4529,
    longitude: 9.6276,
  },
  {
    zipCode: 9434,
    city: "Au SG",
    cantonShort: "SG",
    latitude: 47.4297,
    longitude: 9.639,
  },
  {
    zipCode: 9435,
    city: "Heerbrugg",
    cantonShort: "SG",
    latitude: 47.4126,
    longitude: 9.6266,
  },
  {
    zipCode: 9436,
    city: "Balgach",
    cantonShort: "SG",
    latitude: 47.3935,
    longitude: 9.612,
  },
  {
    zipCode: 9437,
    city: "Marbach SG",
    cantonShort: "SG",
    latitude: 47.3865,
    longitude: 9.579,
  },
  {
    zipCode: 9442,
    city: "Berneck",
    cantonShort: "SG",
    latitude: 47.4244,
    longitude: 9.6118,
  },
  {
    zipCode: 9443,
    city: "Widnau",
    cantonShort: "SG",
    latitude: 47.4007,
    longitude: 9.6376,
  },
  {
    zipCode: 9444,
    city: "Diepoldsau",
    cantonShort: "SG",
    latitude: 47.3823,
    longitude: 9.6518,
  },
  {
    zipCode: 9445,
    city: "Rebstein",
    cantonShort: "SG",
    latitude: 47.3942,
    longitude: 9.5898,
  },
  {
    zipCode: 9450,
    city: "Altst\u00e4tten SG",
    cantonShort: "SG",
    latitude: 47.3706,
    longitude: 9.5419,
  },
  {
    zipCode: 9450,
    city: "Altst\u00e4tten SG 2",
    cantonShort: "SG",
    latitude: 47.378,
    longitude: 9.5487,
  },
  {
    zipCode: 9450,
    city: "L\u00fcchingen",
    cantonShort: "SG",
    latitude: 47.3897,
    longitude: 9.5549,
  },
  {
    zipCode: 9451,
    city: "Kriessern",
    cantonShort: "SG",
    latitude: 47.3639,
    longitude: 9.6059,
  },
  {
    zipCode: 9452,
    city: "Hinterforst",
    cantonShort: "SG",
    latitude: 47.3579,
    longitude: 9.5278,
  },
  {
    zipCode: 9453,
    city: "Eichberg",
    cantonShort: "SG",
    latitude: 47.3442,
    longitude: 9.5209,
  },
  {
    zipCode: 9462,
    city: "Montlingen",
    cantonShort: "SG",
    latitude: 47.3412,
    longitude: 9.5842,
  },
  {
    zipCode: 9462,
    city: "Montlingen",
    cantonShort: "SG",
    latitude: 47.3412,
    longitude: 9.5842,
  },
  {
    zipCode: 9463,
    city: "Oberriet SG",
    cantonShort: "SG",
    latitude: 47.3228,
    longitude: 9.5411,
  },
  {
    zipCode: 9464,
    city: "Lienz",
    cantonShort: "SG",
    latitude: 47.2866,
    longitude: 9.5073,
  },
  {
    zipCode: 9464,
    city: "R\u00fcthi (Rheintal)",
    cantonShort: "SG",
    latitude: 47.2949,
    longitude: 9.5335,
  },
  {
    zipCode: 9465,
    city: "Salez",
    cantonShort: "SG",
    latitude: 47.2337,
    longitude: 9.495,
  },
  {
    zipCode: 9466,
    city: "Sennwald",
    cantonShort: "SG",
    latitude: 47.2623,
    longitude: 9.5013,
  },
  {
    zipCode: 9467,
    city: "Fr\u00fcmsen",
    cantonShort: "SG",
    latitude: 47.2505,
    longitude: 9.4685,
  },
  {
    zipCode: 9468,
    city: "Sax",
    cantonShort: "SG",
    latitude: 47.235,
    longitude: 9.4431,
  },
  {
    zipCode: 9469,
    city: "Haag (Rheintal)",
    cantonShort: "SG",
    latitude: 47.2094,
    longitude: 9.4875,
  },
  {
    zipCode: 9470,
    city: "Buchs SG",
    cantonShort: "SG",
    latitude: 47.1592,
    longitude: 9.4622,
  },
  {
    zipCode: 9470,
    city: "Werdenberg",
    cantonShort: "SG",
    latitude: 47.1706,
    longitude: 9.458,
  },
  {
    zipCode: 9471,
    city: "Buchs SG 1",
    cantonShort: "SG",
    latitude: 47.166,
    longitude: 9.4627,
  },
  {
    zipCode: 9471,
    city: "Buchs SG 3",
    cantonShort: "SG",
    latitude: 47.166,
    longitude: 9.4627,
  },
  {
    zipCode: 9472,
    city: "Grabserberg",
    cantonShort: "SG",
    latitude: 47.165,
    longitude: 9.3793,
  },
  {
    zipCode: 9472,
    city: "Grabs",
    cantonShort: "SG",
    latitude: 47.1811,
    longitude: 9.4465,
  },
  {
    zipCode: 9473,
    city: "Gams",
    cantonShort: "SG",
    latitude: 47.2117,
    longitude: 9.4297,
  },
  {
    zipCode: 9475,
    city: "Sevelen",
    cantonShort: "SG",
    latitude: 47.1292,
    longitude: 9.4585,
  },
  {
    zipCode: 9476,
    city: "Fontnas",
    cantonShort: "SG",
    latitude: 47.0906,
    longitude: 9.4885,
  },
  {
    zipCode: 9476,
    city: "Weite",
    cantonShort: "SG",
    latitude: 47.0986,
    longitude: 9.5026,
  },
  {
    zipCode: 9477,
    city: "Tr\u00fcbbach",
    cantonShort: "SG",
    latitude: 47.076,
    longitude: 9.4861,
  },
  {
    zipCode: 9478,
    city: "Azmoos",
    cantonShort: "SG",
    latitude: 47.0886,
    longitude: 9.4318,
  },
  {
    zipCode: 9479,
    city: "Gretschins",
    cantonShort: "SG",
    latitude: 47.0971,
    longitude: 9.486,
  },
  {
    zipCode: 9479,
    city: "Oberschan",
    cantonShort: "SG",
    latitude: 47.102,
    longitude: 9.4579,
  },
  {
    zipCode: 9479,
    city: "Malans SG",
    cantonShort: "SG",
    latitude: 47.0902,
    longitude: 9.4756,
  },
  {
    zipCode: 7310,
    city: "Bad Ragaz",
    cantonShort: "SG",
    latitude: 46.9969,
    longitude: 9.4731,
  },
  {
    zipCode: 7310,
    city: "Bad Ragaz",
    cantonShort: "SG",
    latitude: 46.9969,
    longitude: 9.4731,
  },
  {
    zipCode: 7312,
    city: "Pf\u00e4fers",
    cantonShort: "SG",
    latitude: 46.9844,
    longitude: 9.5075,
  },
  {
    zipCode: 7313,
    city: "St. Margrethenberg",
    cantonShort: "SG",
    latitude: 46.9682,
    longitude: 9.5101,
  },
  {
    zipCode: 7314,
    city: "Vadura",
    cantonShort: "SG",
    latitude: 46.9562,
    longitude: 9.4916,
  },
  {
    zipCode: 7315,
    city: "V\u00e4ttis",
    cantonShort: "SG",
    latitude: 46.9132,
    longitude: 9.385,
  },
  {
    zipCode: 7317,
    city: "Valens",
    cantonShort: "SG",
    latitude: 46.977,
    longitude: 9.4672,
  },
  {
    zipCode: 7317,
    city: "Vas\u00f6n",
    cantonShort: "SG",
    latitude: 46.9549,
    longitude: 9.46,
  },
  {
    zipCode: 7320,
    city: "Sargans",
    cantonShort: "SG",
    latitude: 47.0556,
    longitude: 9.4489,
  },
  {
    zipCode: 7323,
    city: "Wangs",
    cantonShort: "SG",
    latitude: 47.0061,
    longitude: 9.4138,
  },
  {
    zipCode: 7324,
    city: "Vilters",
    cantonShort: "SG",
    latitude: 47.0198,
    longitude: 9.4573,
  },
  {
    zipCode: 7325,
    city: "Schwendi im Weisstannental",
    cantonShort: "SG",
    latitude: 46.9966,
    longitude: 9.3682,
  },
  {
    zipCode: 7326,
    city: "Weisstannen",
    cantonShort: "SG",
    latitude: 46.9719,
    longitude: 9.3047,
  },
  {
    zipCode: 8877,
    city: "Murg",
    cantonShort: "SG",
    latitude: 47.0696,
    longitude: 9.1964,
  },
  {
    zipCode: 8878,
    city: "Quinten",
    cantonShort: "SG",
    latitude: 47.1329,
    longitude: 9.221,
  },
  {
    zipCode: 8879,
    city: "Pizolpark (Mels)",
    cantonShort: "SG",
    latitude: 47.0008,
    longitude: 9.3374,
  },
  {
    zipCode: 8880,
    city: "Walenstadt",
    cantonShort: "SG",
    latitude: 47.1224,
    longitude: 9.3107,
  },
  {
    zipCode: 8881,
    city: "Walenstadtberg",
    cantonShort: "SG",
    latitude: 47.1406,
    longitude: 9.2785,
  },
  {
    zipCode: 8881,
    city: "Tscherlach",
    cantonShort: "SG",
    latitude: 47.1298,
    longitude: 9.3402,
  },
  {
    zipCode: 8882,
    city: "Unterterzen",
    cantonShort: "SG",
    latitude: 47.1161,
    longitude: 9.2484,
  },
  {
    zipCode: 8883,
    city: "Quarten",
    cantonShort: "SG",
    latitude: 47.1029,
    longitude: 9.2344,
  },
  {
    zipCode: 8884,
    city: "Oberterzen",
    cantonShort: "SG",
    latitude: 47.0863,
    longitude: 9.2458,
  },
  {
    zipCode: 8885,
    city: "Mols",
    cantonShort: "SG",
    latitude: 47.1123,
    longitude: 9.2824,
  },
  {
    zipCode: 8886,
    city: "M\u00e4dris-Vermol",
    cantonShort: "SG",
    latitude: 47.0371,
    longitude: 9.3462,
  },
  {
    zipCode: 8887,
    city: "Mels",
    cantonShort: "SG",
    latitude: 47.0327,
    longitude: 9.4166,
  },
  {
    zipCode: 8888,
    city: "Heiligkreuz (Mels)",
    cantonShort: "SG",
    latitude: 47.0684,
    longitude: 9.4085,
  },
  {
    zipCode: 8889,
    city: "Plons",
    cantonShort: "SG",
    latitude: 47.0618,
    longitude: 9.3833,
  },
  {
    zipCode: 8890,
    city: "Flums",
    cantonShort: "SG",
    latitude: 47.0935,
    longitude: 9.3416,
  },
  {
    zipCode: 8892,
    city: "Berschis",
    cantonShort: "SG",
    latitude: 47.1136,
    longitude: 9.3721,
  },
  {
    zipCode: 8893,
    city: "Flums Hochwiese",
    cantonShort: "SG",
    latitude: 47.0837,
    longitude: 9.3781,
  },
  {
    zipCode: 8894,
    city: "Flumserberg Saxli",
    cantonShort: "SG",
    latitude: 47.0597,
    longitude: 9.3302,
  },
  {
    zipCode: 8895,
    city: "Flumserberg Portels",
    cantonShort: "SG",
    latitude: 47.0712,
    longitude: 9.3208,
  },
  {
    zipCode: 8896,
    city: "Flumserberg Bergheim",
    cantonShort: "SG",
    latitude: 47.0925,
    longitude: 9.309,
  },
  {
    zipCode: 8897,
    city: "Flumserberg Tannenheim",
    cantonShort: "SG",
    latitude: 47.046,
    longitude: 9.266,
  },
  {
    zipCode: 8898,
    city: "Flumserberg Tannenbodenalp",
    cantonShort: "SG",
    latitude: 47.0805,
    longitude: 9.2683,
  },
  {
    zipCode: 8638,
    city: "Goldingen",
    cantonShort: "SG",
    latitude: 47.2847,
    longitude: 8.9739,
  },
  {
    zipCode: 8640,
    city: "Rapperswil SG",
    cantonShort: "SG",
    latitude: 47.225,
    longitude: 8.8188,
  },
  {
    zipCode: 8645,
    city: "Jona",
    cantonShort: "SG",
    latitude: 47.232,
    longitude: 8.84,
  },
  {
    zipCode: 8646,
    city: "Wagen",
    cantonShort: "SG",
    latitude: 47.2321,
    longitude: 8.8862,
  },
  {
    zipCode: 8715,
    city: "Bollingen",
    cantonShort: "SG",
    latitude: 47.2199,
    longitude: 8.8909,
  },
  {
    zipCode: 8716,
    city: "Schmerikon",
    cantonShort: "SG",
    latitude: 47.2245,
    longitude: 8.9419,
  },
  {
    zipCode: 8717,
    city: "Benken SG",
    cantonShort: "SG",
    latitude: 47.1924,
    longitude: 8.9958,
  },
  {
    zipCode: 8718,
    city: "Sch\u00e4nis",
    cantonShort: "SG",
    latitude: 47.1604,
    longitude: 9.0585,
  },
  {
    zipCode: 8722,
    city: "Kaltbrunn",
    cantonShort: "SG",
    latitude: 47.2096,
    longitude: 9.0494,
  },
  {
    zipCode: 8723,
    city: "Rufi",
    cantonShort: "SG",
    latitude: 47.1835,
    longitude: 9.079,
  },
  {
    zipCode: 8723,
    city: "Maseltrangen",
    cantonShort: "SG",
    latitude: 47.1963,
    longitude: 9.0537,
  },
  {
    zipCode: 8725,
    city: "Gebertingen",
    cantonShort: "SG",
    latitude: 47.2556,
    longitude: 9.0052,
  },
  {
    zipCode: 8725,
    city: "Ernetschwil",
    cantonShort: "SG",
    latitude: 47.2414,
    longitude: 8.9985,
  },
  {
    zipCode: 8726,
    city: "Ricken SG",
    cantonShort: "SG",
    latitude: 47.2594,
    longitude: 9.0491,
  },
  {
    zipCode: 8727,
    city: "Walde SG",
    cantonShort: "SG",
    latitude: 47.2743,
    longitude: 9.0227,
  },
  {
    zipCode: 8730,
    city: "Uznach",
    cantonShort: "SG",
    latitude: 47.2256,
    longitude: 8.9811,
  },
  {
    zipCode: 8732,
    city: "Neuhaus SG",
    cantonShort: "SG",
    latitude: 47.2418,
    longitude: 8.9447,
  },
  {
    zipCode: 8733,
    city: "Eschenbach SG",
    cantonShort: "SG",
    latitude: 47.245,
    longitude: 8.922,
  },
  {
    zipCode: 8734,
    city: "Ermenswil",
    cantonShort: "SG",
    latitude: 47.247,
    longitude: 8.8897,
  },
  {
    zipCode: 8735,
    city: "St. Gallenkappel",
    cantonShort: "SG",
    latitude: 47.246,
    longitude: 8.9713,
  },
  {
    zipCode: 8735,
    city: "R\u00fceterswil",
    cantonShort: "SG",
    latitude: 47.274,
    longitude: 8.9952,
  },
  {
    zipCode: 8737,
    city: "Gommiswald",
    cantonShort: "SG",
    latitude: 47.2315,
    longitude: 9.0203,
  },
  {
    zipCode: 8738,
    city: "Uetliburg SG",
    cantonShort: "SG",
    latitude: 47.2412,
    longitude: 9.046,
  },
  {
    zipCode: 8739,
    city: "Rieden SG",
    cantonShort: "SG",
    latitude: 47.2258,
    longitude: 9.0772,
  },
  {
    zipCode: 8740,
    city: "Uznach V\u00f6gele Versandhaus",
    cantonShort: "SG",
    latitude: 47.2265,
    longitude: 8.9813,
  },
  {
    zipCode: 8740,
    city: "Uznach V\u00f6gele AG",
    cantonShort: "SG",
    latitude: 47.2265,
    longitude: 8.9813,
  },
  {
    zipCode: 8866,
    city: "Ziegelbr\u00fccke",
    cantonShort: "SG",
    latitude: 47.136,
    longitude: 9.0625,
  },
  {
    zipCode: 8872,
    city: "Weesen",
    cantonShort: "SG",
    latitude: 47.1372,
    longitude: 9.1115,
  },
  {
    zipCode: 8873,
    city: "Amden",
    cantonShort: "SG",
    latitude: 47.1599,
    longitude: 9.1679,
  },
  {
    zipCode: 9114,
    city: "Hoffeld",
    cantonShort: "SG",
    latitude: 47.3559,
    longitude: 9.1726,
  },
  {
    zipCode: 9115,
    city: "Dicken",
    cantonShort: "SG",
    latitude: 47.3366,
    longitude: 9.1932,
  },
  {
    zipCode: 9122,
    city: "Mogelsberg",
    cantonShort: "SG",
    latitude: 47.3604,
    longitude: 9.1372,
  },
  {
    zipCode: 9122,
    city: "Ebersol",
    cantonShort: "SG",
    latitude: 47.3414,
    longitude: 9.1574,
  },
  {
    zipCode: 9123,
    city: "Nassen",
    cantonShort: "SG",
    latitude: 47.3793,
    longitude: 9.1493,
  },
  {
    zipCode: 9125,
    city: "Brunnadern",
    cantonShort: "SG",
    latitude: 47.324,
    longitude: 9.1417,
  },
  {
    zipCode: 9126,
    city: "Necker",
    cantonShort: "SG",
    latitude: 47.3462,
    longitude: 9.1279,
  },
  {
    zipCode: 9127,
    city: "St. Peterzell",
    cantonShort: "SG",
    latitude: 47.3212,
    longitude: 9.1793,
  },
  {
    zipCode: 9500,
    city: "Wil SG",
    cantonShort: "SG",
    latitude: 47.4622,
    longitude: 9.0517,
  },
  {
    zipCode: 9533,
    city: "Dietschwil",
    cantonShort: "SG",
    latitude: 47.4253,
    longitude: 9.0118,
  },
  {
    zipCode: 9533,
    city: "Kirchberg SG",
    cantonShort: "SG",
    latitude: 47.4183,
    longitude: 9.0341,
  },
  {
    zipCode: 9534,
    city: "G\u00e4hwil",
    cantonShort: "SG",
    latitude: 47.3939,
    longitude: 8.9982,
  },
  {
    zipCode: 9601,
    city: "L\u00fctisburg Station",
    cantonShort: "SG",
    latitude: 47.3814,
    longitude: 9.0669,
  },
  {
    zipCode: 9602,
    city: "Bazenheid",
    cantonShort: "SG",
    latitude: 47.4127,
    longitude: 9.0654,
  },
  {
    zipCode: 9602,
    city: "M\u00fcselbach",
    cantonShort: "SG",
    latitude: 47.3883,
    longitude: 9.0406,
  },
  {
    zipCode: 9604,
    city: "L\u00fctisburg",
    cantonShort: "SG",
    latitude: 47.3954,
    longitude: 9.106,
  },
  {
    zipCode: 9604,
    city: "Unterrindal",
    cantonShort: "SG",
    latitude: 47.4091,
    longitude: 9.0935,
  },
  {
    zipCode: 9606,
    city: "B\u00fctschwil",
    cantonShort: "SG",
    latitude: 47.3563,
    longitude: 9.0691,
  },
  {
    zipCode: 9607,
    city: "Mosnang",
    cantonShort: "SG",
    latitude: 47.3592,
    longitude: 9.0387,
  },
  {
    zipCode: 9608,
    city: "Ganterschwil",
    cantonShort: "SG",
    latitude: 47.3743,
    longitude: 9.0965,
  },
  {
    zipCode: 9612,
    city: "Dreien",
    cantonShort: "SG",
    latitude: 47.3552,
    longitude: 9.0047,
  },
  {
    zipCode: 9613,
    city: "M\u00fchlr\u00fcti",
    cantonShort: "SG",
    latitude: 47.3672,
    longitude: 8.9784,
  },
  {
    zipCode: 9614,
    city: "Libingen",
    cantonShort: "SG",
    latitude: 47.3228,
    longitude: 9.0173,
  },
  {
    zipCode: 9615,
    city: "Dietfurt",
    cantonShort: "SG",
    latitude: 47.3365,
    longitude: 9.0677,
  },
  {
    zipCode: 9620,
    city: "Lichtensteig",
    cantonShort: "SG",
    latitude: 47.3187,
    longitude: 9.0877,
  },
  {
    zipCode: 9621,
    city: "Oberhelfenschwil",
    cantonShort: "SG",
    latitude: 47.3483,
    longitude: 9.1066,
  },
  {
    zipCode: 9622,
    city: "Krinau",
    cantonShort: "SG",
    latitude: 47.3178,
    longitude: 9.0521,
  },
  {
    zipCode: 9630,
    city: "Wattwil",
    cantonShort: "SG",
    latitude: 47.2939,
    longitude: 9.0881,
  },
  {
    zipCode: 9631,
    city: "Ulisbach",
    cantonShort: "SG",
    latitude: 47.2851,
    longitude: 9.1173,
  },
  {
    zipCode: 9633,
    city: "B\u00e4chli (Hemberg)",
    cantonShort: "SG",
    latitude: 47.3005,
    longitude: 9.2016,
  },
  {
    zipCode: 9633,
    city: "Hemberg",
    cantonShort: "SG",
    latitude: 47.2892,
    longitude: 9.1756,
  },
  {
    zipCode: 9642,
    city: "Ebnat-Kappel",
    cantonShort: "SG",
    latitude: 47.245,
    longitude: 9.1299,
  },
  {
    zipCode: 9643,
    city: "Krummenau",
    cantonShort: "SG",
    latitude: 47.258,
    longitude: 9.1869,
  },
  {
    zipCode: 9650,
    city: "Nesslau",
    cantonShort: "SG",
    latitude: 47.2123,
    longitude: 9.1831,
  },
  {
    zipCode: 9651,
    city: "Ennetb\u00fchl",
    cantonShort: "SG",
    latitude: 47.2455,
    longitude: 9.2592,
  },
  {
    zipCode: 9652,
    city: "Neu St. Johann",
    cantonShort: "SG",
    latitude: 47.242,
    longitude: 9.1901,
  },
  {
    zipCode: 9655,
    city: "Stein SG",
    cantonShort: "SG",
    latitude: 47.2017,
    longitude: 9.2298,
  },
  {
    zipCode: 9656,
    city: "Alt St. Johann",
    cantonShort: "SG",
    latitude: 47.1773,
    longitude: 9.2647,
  },
  {
    zipCode: 9657,
    city: "Unterwasser",
    cantonShort: "SG",
    latitude: 47.1964,
    longitude: 9.3065,
  },
  {
    zipCode: 9658,
    city: "Wildhaus",
    cantonShort: "SG",
    latitude: 47.2165,
    longitude: 9.3527,
  },
  {
    zipCode: 9113,
    city: "Degersheim",
    cantonShort: "SG",
    latitude: 47.3734,
    longitude: 9.1981,
  },
  {
    zipCode: 9116,
    city: "Wolfertswil",
    cantonShort: "SG",
    latitude: 47.3957,
    longitude: 9.1692,
  },
  {
    zipCode: 9203,
    city: "Niederwil SG",
    cantonShort: "SG",
    latitude: 47.4323,
    longitude: 9.2016,
  },
  {
    zipCode: 9230,
    city: "Flawil 2 Botsberg",
    cantonShort: "SG",
    latitude: 47.4053,
    longitude: 9.1977,
  },
  {
    zipCode: 9230,
    city: "Flawil",
    cantonShort: "SG",
    latitude: 47.4086,
    longitude: 9.1895,
  },
  {
    zipCode: 9230,
    city: "Flawil 1",
    cantonShort: "SG",
    latitude: 47.4053,
    longitude: 9.1977,
  },
  {
    zipCode: 9231,
    city: "Egg (Flawil)",
    cantonShort: "SG",
    latitude: 47.3943,
    longitude: 9.2222,
  },
  {
    zipCode: 9240,
    city: "Niederglatt SG",
    cantonShort: "SG",
    latitude: 47.4278,
    longitude: 9.163,
  },
  {
    zipCode: 9240,
    city: "Uzwil",
    cantonShort: "SG",
    latitude: 47.4358,
    longitude: 9.1356,
  },
  {
    zipCode: 9242,
    city: "Oberuzwil",
    cantonShort: "SG",
    latitude: 47.4292,
    longitude: 9.1217,
  },
  {
    zipCode: 9243,
    city: "Jonschwil",
    cantonShort: "SG",
    latitude: 47.423,
    longitude: 9.0951,
  },
  {
    zipCode: 9244,
    city: "Niederuzwil",
    cantonShort: "SG",
    latitude: 47.4463,
    longitude: 9.1427,
  },
  {
    zipCode: 9245,
    city: "Sonnental",
    cantonShort: "SG",
    latitude: 47.4584,
    longitude: 9.1348,
  },
  {
    zipCode: 9245,
    city: "Oberb\u00fcren",
    cantonShort: "SG",
    latitude: 47.4505,
    longitude: 9.1675,
  },
  {
    zipCode: 9246,
    city: "Niederb\u00fcren",
    cantonShort: "SG",
    latitude: 47.4625,
    longitude: 9.2147,
  },
  {
    zipCode: 9247,
    city: "Henau",
    cantonShort: "SG",
    latitude: 47.4557,
    longitude: 9.114,
  },
  {
    zipCode: 9248,
    city: "Bichwil",
    cantonShort: "SG",
    latitude: 47.4157,
    longitude: 9.1376,
  },
  {
    zipCode: 9249,
    city: "Niederstetten",
    cantonShort: "SG",
    latitude: 47.4535,
    longitude: 9.0823,
  },
  {
    zipCode: 9249,
    city: "Oberstetten",
    cantonShort: "SG",
    latitude: 47.4464,
    longitude: 9.0914,
  },
  {
    zipCode: 9249,
    city: "Algetshausen",
    cantonShort: "SG",
    latitude: 47.4443,
    longitude: 9.1101,
  },
  {
    zipCode: 9500,
    city: "Wil SG",
    cantonShort: "SG",
    latitude: 47.4622,
    longitude: 9.0517,
  },
  {
    zipCode: 9501,
    city: "Wil SG 1",
    cantonShort: "SG",
    latitude: 47.4757,
    longitude: 9.0518,
  },
  {
    zipCode: 9512,
    city: "Rossr\u00fcti",
    cantonShort: "SG",
    latitude: 47.4817,
    longitude: 9.0721,
  },
  {
    zipCode: 9523,
    city: "Z\u00fcberwangen",
    cantonShort: "SG",
    latitude: 47.4652,
    longitude: 9.0888,
  },
  {
    zipCode: 9524,
    city: "Zuzwil SG",
    cantonShort: "SG",
    latitude: 47.475,
    longitude: 9.1112,
  },
  {
    zipCode: 9525,
    city: "Lenggenwil",
    cantonShort: "SG",
    latitude: 47.4713,
    longitude: 9.1422,
  },
  {
    zipCode: 9526,
    city: "Zuckenriet",
    cantonShort: "SG",
    latitude: 47.4868,
    longitude: 9.1641,
  },
  {
    zipCode: 9527,
    city: "Niederhelfenschwil",
    cantonShort: "SG",
    latitude: 47.4786,
    longitude: 9.1855,
  },
  {
    zipCode: 9532,
    city: "Rickenbach b. Wil",
    cantonShort: "SG",
    latitude: 47.4457,
    longitude: 9.0487,
  },
  {
    zipCode: 9536,
    city: "Schwarzenbach SG",
    cantonShort: "SG",
    latitude: 47.4406,
    longitude: 9.0728,
  },
  {
    zipCode: 9552,
    city: "Bronschhofen",
    cantonShort: "SG",
    latitude: 47.481,
    longitude: 9.0301,
  },
  {
    zipCode: 9604,
    city: "Oberrindal",
    cantonShort: "SG",
    latitude: 47.4054,
    longitude: 9.126,
  },
  {
    zipCode: 8213,
    city: "Neunkirch",
    cantonShort: "SH",
    latitude: 47.682,
    longitude: 8.5118,
  },
  {
    zipCode: 8214,
    city: "G\u00e4chlingen",
    cantonShort: "SH",
    latitude: 47.7111,
    longitude: 8.4965,
  },
  {
    zipCode: 8224,
    city: "L\u00f6hningen",
    cantonShort: "SH",
    latitude: 47.7035,
    longitude: 8.5465,
  },
  {
    zipCode: 8234,
    city: "Stetten SH",
    cantonShort: "SH",
    latitude: 47.7384,
    longitude: 8.6582,
  },
  {
    zipCode: 8235,
    city: "Lohn SH",
    cantonShort: "SH",
    latitude: 47.7558,
    longitude: 8.6704,
  },
  {
    zipCode: 8236,
    city: "B\u00fcttenhardt",
    cantonShort: "SH",
    latitude: 47.7533,
    longitude: 8.6445,
  },
  {
    zipCode: 8236,
    city: "Opfertshofen SH",
    cantonShort: "SH",
    latitude: 47.7725,
    longitude: 8.6592,
  },
  {
    zipCode: 8239,
    city: "D\u00f6rflingen",
    cantonShort: "SH",
    latitude: 47.7101,
    longitude: 8.717,
  },
  {
    zipCode: 8240,
    city: "Thayngen",
    cantonShort: "SH",
    latitude: 47.7431,
    longitude: 8.7062,
  },
  {
    zipCode: 8241,
    city: "Barzheim",
    cantonShort: "SH",
    latitude: 47.7601,
    longitude: 8.7176,
  },
  {
    zipCode: 8242,
    city: "Hofen SH",
    cantonShort: "SH",
    latitude: 47.7806,
    longitude: 8.677,
  },
  {
    zipCode: 8242,
    city: "Bibern SH",
    cantonShort: "SH",
    latitude: 47.7689,
    longitude: 8.6791,
  },
  {
    zipCode: 8243,
    city: "Altdorf SH",
    cantonShort: "SH",
    latitude: 47.7875,
    longitude: 8.6568,
  },
  {
    zipCode: 8200,
    city: "Schaffhausen",
    cantonShort: "SH",
    latitude: 47.7148,
    longitude: 8.625,
  },
  {
    zipCode: 8201,
    city: "Schaffhausen",
    cantonShort: "SH",
    latitude: 47.6973,
    longitude: 8.6349,
  },
  {
    zipCode: 8203,
    city: "Schaffhausen",
    cantonShort: "SH",
    latitude: 47.6992,
    longitude: 8.6634,
  },
  {
    zipCode: 8207,
    city: "Schaffhausen",
    cantonShort: "SH",
    latitude: 47.7228,
    longitude: 8.673,
  },
  {
    zipCode: 8208,
    city: "Schaffhausen",
    cantonShort: "SH",
    latitude: 47.7122,
    longitude: 8.6354,
  },
  {
    zipCode: 8212,
    city: "Neuhausen am Rheinfall 1",
    cantonShort: "SH",
    latitude: 47.6792,
    longitude: 8.5994,
  },
  {
    zipCode: 8212,
    city: "Neuhausen am Rheinfall",
    cantonShort: "SH",
    latitude: 47.6791,
    longitude: 8.5992,
  },
  {
    zipCode: 8222,
    city: "Beringen",
    cantonShort: "SH",
    latitude: 47.7044,
    longitude: 8.5769,
  },
  {
    zipCode: 8223,
    city: "Guntmadingen",
    cantonShort: "SH",
    latitude: 47.6814,
    longitude: 8.5572,
  },
  {
    zipCode: 8231,
    city: "Hemmental",
    cantonShort: "SH",
    latitude: 47.7377,
    longitude: 8.578,
  },
  {
    zipCode: 8232,
    city: "Merishausen",
    cantonShort: "SH",
    latitude: 47.7629,
    longitude: 8.5998,
  },
  {
    zipCode: 8233,
    city: "Bargen SH",
    cantonShort: "SH",
    latitude: 47.7934,
    longitude: 8.5919,
  },
  {
    zipCode: 8454,
    city: "Buchberg",
    cantonShort: "SH",
    latitude: 47.571,
    longitude: 8.5526,
  },
  {
    zipCode: 8455,
    city: "R\u00fcdlingen",
    cantonShort: "SH",
    latitude: 47.5871,
    longitude: 8.5669,
  },
  {
    zipCode: 8225,
    city: "Siblingen",
    cantonShort: "SH",
    latitude: 47.7234,
    longitude: 8.5225,
  },
  {
    zipCode: 8226,
    city: "Schleitheim",
    cantonShort: "SH",
    latitude: 47.7485,
    longitude: 8.4948,
  },
  {
    zipCode: 8228,
    city: "Beggingen",
    cantonShort: "SH",
    latitude: 47.7652,
    longitude: 8.5444,
  },
  {
    zipCode: 8260,
    city: "Stein am Rhein 1",
    cantonShort: "SH",
    latitude: 47.6666,
    longitude: 8.8578,
  },
  {
    zipCode: 8260,
    city: "Stein am Rhein",
    cantonShort: "SH",
    latitude: 47.6661,
    longitude: 8.8582,
  },
  {
    zipCode: 8260,
    city: "Stein am Rhein 2 Stadt",
    cantonShort: "SH",
    latitude: 47.6666,
    longitude: 8.8578,
  },
  {
    zipCode: 8261,
    city: "Hemishofen",
    cantonShort: "SH",
    latitude: 47.6883,
    longitude: 8.841,
  },
  {
    zipCode: 8262,
    city: "Ramsen",
    cantonShort: "SH",
    latitude: 47.7048,
    longitude: 8.8147,
  },
  {
    zipCode: 8263,
    city: "Buch SH",
    cantonShort: "SH",
    latitude: 47.7158,
    longitude: 8.7846,
  },
  {
    zipCode: 8215,
    city: "Hallau",
    cantonShort: "SH",
    latitude: 47.7005,
    longitude: 8.4432,
  },
  {
    zipCode: 8216,
    city: "Oberhallau",
    cantonShort: "SH",
    latitude: 47.7139,
    longitude: 8.4734,
  },
  {
    zipCode: 8217,
    city: "Wilchingen",
    cantonShort: "SH",
    latitude: 47.665,
    longitude: 8.4713,
  },
  {
    zipCode: 8218,
    city: "Osterfingen",
    cantonShort: "SH",
    latitude: 47.658,
    longitude: 8.4971,
  },
  {
    zipCode: 8219,
    city: "Trasadingen",
    cantonShort: "SH",
    latitude: 47.67,
    longitude: 8.4259,
  },
  {
    zipCode: 4622,
    city: "Egerkingen",
    cantonShort: "SO",
    latitude: 47.3258,
    longitude: 7.7964,
  },
  {
    zipCode: 4623,
    city: "Neuendorf",
    cantonShort: "SO",
    latitude: 47.2971,
    longitude: 7.8008,
  },
  {
    zipCode: 4624,
    city: "H\u00e4rkingen",
    cantonShort: "SO",
    latitude: 47.3046,
    longitude: 7.8214,
  },
  {
    zipCode: 4625,
    city: "Oberbuchsiten",
    cantonShort: "SO",
    latitude: 47.3122,
    longitude: 7.7598,
  },
  {
    zipCode: 4626,
    city: "Niederbuchsiten",
    cantonShort: "SO",
    latitude: 47.2887,
    longitude: 7.7782,
  },
  {
    zipCode: 4628,
    city: "Wolfwil",
    cantonShort: "SO",
    latitude: 47.2701,
    longitude: 7.7982,
  },
  {
    zipCode: 4702,
    city: "Oensingen",
    cantonShort: "SO",
    latitude: 47.2879,
    longitude: 7.7228,
  },
  {
    zipCode: 4703,
    city: "Kestenholz",
    cantonShort: "SO",
    latitude: 47.2763,
    longitude: 7.7533,
  },
  {
    zipCode: 4710,
    city: "Balsthal",
    cantonShort: "SO",
    latitude: 47.3143,
    longitude: 7.7003,
  },
  {
    zipCode: 4712,
    city: "Laupersdorf",
    cantonShort: "SO",
    latitude: 47.3128,
    longitude: 7.6569,
  },
  {
    zipCode: 4713,
    city: "Matzendorf",
    cantonShort: "SO",
    latitude: 47.302,
    longitude: 7.6282,
  },
  {
    zipCode: 4714,
    city: "Aedermannsdorf",
    cantonShort: "SO",
    latitude: 47.306,
    longitude: 7.6,
  },
  {
    zipCode: 4715,
    city: "Herbetswil",
    cantonShort: "SO",
    latitude: 47.2919,
    longitude: 7.5735,
  },
  {
    zipCode: 4716,
    city: "G\u00e4nsbrunnen",
    cantonShort: "SO",
    latitude: 47.2622,
    longitude: 7.4669,
  },
  {
    zipCode: 4716,
    city: "Welschenrohr",
    cantonShort: "SO",
    latitude: 47.2792,
    longitude: 7.5232,
  },
  {
    zipCode: 4717,
    city: "M\u00fcmliswil",
    cantonShort: "SO",
    latitude: 47.3456,
    longitude: 7.6844,
  },
  {
    zipCode: 4718,
    city: "Holderbank SO",
    cantonShort: "SO",
    latitude: 47.3327,
    longitude: 7.7513,
  },
  {
    zipCode: 4719,
    city: "Ramiswil",
    cantonShort: "SO",
    latitude: 47.3463,
    longitude: 7.6337,
  },
  {
    zipCode: 3253,
    city: "Schnottwil",
    cantonShort: "SO",
    latitude: 47.1088,
    longitude: 7.3912,
  },
  {
    zipCode: 3254,
    city: "Balm b. Messen",
    cantonShort: "SO",
    latitude: 47.1045,
    longitude: 7.4347,
  },
  {
    zipCode: 3254,
    city: "Messen",
    cantonShort: "SO",
    latitude: 47.0909,
    longitude: 7.4496,
  },
  {
    zipCode: 3307,
    city: "Brunnenthal",
    cantonShort: "SO",
    latitude: 47.0853,
    longitude: 7.4664,
  },
  {
    zipCode: 4571,
    city: "L\u00fcterkofen",
    cantonShort: "SO",
    latitude: 47.1648,
    longitude: 7.509,
  },
  {
    zipCode: 4571,
    city: "Ichertswil",
    cantonShort: "SO",
    latitude: 47.1598,
    longitude: 7.4939,
  },
  {
    zipCode: 4574,
    city: "Nennigkofen",
    cantonShort: "SO",
    latitude: 47.1829,
    longitude: 7.4889,
  },
  {
    zipCode: 4574,
    city: "L\u00fcsslingen",
    cantonShort: "SO",
    latitude: 47.1914,
    longitude: 7.5016,
  },
  {
    zipCode: 4576,
    city: "Tscheppach",
    cantonShort: "SO",
    latitude: 47.1484,
    longitude: 7.4818,
  },
  {
    zipCode: 4577,
    city: "Hessigkofen",
    cantonShort: "SO",
    latitude: 47.139,
    longitude: 7.4597,
  },
  {
    zipCode: 4578,
    city: "Bibern SO",
    cantonShort: "SO",
    latitude: 47.1479,
    longitude: 7.454,
  },
  {
    zipCode: 4579,
    city: "Gossliwil",
    cantonShort: "SO",
    latitude: 47.1367,
    longitude: 7.4345,
  },
  {
    zipCode: 4581,
    city: "K\u00fcttigkofen",
    cantonShort: "SO",
    latitude: 47.1516,
    longitude: 7.513,
  },
  {
    zipCode: 4582,
    city: "Br\u00fcgglen",
    cantonShort: "SO",
    latitude: 47.1439,
    longitude: 7.4955,
  },
  {
    zipCode: 4583,
    city: "M\u00fchledorf SO",
    cantonShort: "SO",
    latitude: 47.1331,
    longitude: 7.4755,
  },
  {
    zipCode: 4583,
    city: "Aetigkofen",
    cantonShort: "SO",
    latitude: 47.1235,
    longitude: 7.4642,
  },
  {
    zipCode: 4584,
    city: "L\u00fcterswil",
    cantonShort: "SO",
    latitude: 47.1177,
    longitude: 7.4407,
  },
  {
    zipCode: 4584,
    city: "G\u00e4chliwil",
    cantonShort: "SO",
    latitude: 47.1273,
    longitude: 7.4453,
  },
  {
    zipCode: 4585,
    city: "Biezwil",
    cantonShort: "SO",
    latitude: 47.1118,
    longitude: 7.4191,
  },
  {
    zipCode: 4586,
    city: "Kyburg-Buchegg",
    cantonShort: "SO",
    latitude: 47.1423,
    longitude: 7.5137,
  },
  {
    zipCode: 4587,
    city: "Aetingen",
    cantonShort: "SO",
    latitude: 47.1295,
    longitude: 7.5009,
  },
  {
    zipCode: 4588,
    city: "Brittern",
    cantonShort: "SO",
    latitude: 47.121,
    longitude: 7.494,
  },
  {
    zipCode: 4588,
    city: "Oberramsern",
    cantonShort: "SO",
    latitude: 47.1134,
    longitude: 7.4618,
  },
  {
    zipCode: 4588,
    city: "Unterramsern",
    cantonShort: "SO",
    latitude: 47.1189,
    longitude: 7.4808,
  },
  {
    zipCode: 4108,
    city: "Witterswil",
    cantonShort: "SO",
    latitude: 47.4873,
    longitude: 7.5261,
  },
  {
    zipCode: 4112,
    city: "B\u00e4ttwil-Fl\u00fch",
    cantonShort: "SO",
    latitude: 47.4916,
    longitude: 7.51,
  },
  {
    zipCode: 4112,
    city: "Fl\u00fch",
    cantonShort: "SO",
    latitude: 47.483,
    longitude: 7.4984,
  },
  {
    zipCode: 4112,
    city: "B\u00e4ttwil",
    cantonShort: "SO",
    latitude: 47.4918,
    longitude: 7.5105,
  },
  {
    zipCode: 4114,
    city: "Hofstetten SO",
    cantonShort: "SO",
    latitude: 47.4703,
    longitude: 7.51,
  },
  {
    zipCode: 4115,
    city: "Mariastein",
    cantonShort: "SO",
    latitude: 47.4759,
    longitude: 7.4844,
  },
  {
    zipCode: 4116,
    city: "Metzerlen",
    cantonShort: "SO",
    latitude: 47.4637,
    longitude: 7.4682,
  },
  {
    zipCode: 4118,
    city: "Rodersdorf",
    cantonShort: "SO",
    latitude: 47.4846,
    longitude: 7.4463,
  },
  {
    zipCode: 4143,
    city: "Dornach",
    cantonShort: "SO",
    latitude: 47.4766,
    longitude: 7.6243,
  },
  {
    zipCode: 4145,
    city: "Gempen",
    cantonShort: "SO",
    latitude: 47.4784,
    longitude: 7.6591,
  },
  {
    zipCode: 4146,
    city: "Hochwald",
    cantonShort: "SO",
    latitude: 47.4545,
    longitude: 7.6396,
  },
  {
    zipCode: 4206,
    city: "Seewen SO",
    cantonShort: "SO",
    latitude: 47.4263,
    longitude: 7.6475,
  },
  {
    zipCode: 4412,
    city: "Nuglar",
    cantonShort: "SO",
    latitude: 47.4721,
    longitude: 7.6887,
  },
  {
    zipCode: 4413,
    city: "B\u00fcren SO",
    cantonShort: "SO",
    latitude: 47.4478,
    longitude: 7.6733,
  },
  {
    zipCode: 4421,
    city: "St. Pantaleon",
    cantonShort: "SO",
    latitude: 47.459,
    longitude: 7.689,
  },
  {
    zipCode: 4468,
    city: "Kienberg",
    cantonShort: "SO",
    latitude: 47.4403,
    longitude: 7.9672,
  },
  {
    zipCode: 4632,
    city: "Trimbach",
    cantonShort: "SO",
    latitude: 47.3687,
    longitude: 7.8889,
  },
  {
    zipCode: 4633,
    city: "Hauenstein",
    cantonShort: "SO",
    latitude: 47.3713,
    longitude: 7.8558,
  },
  {
    zipCode: 4634,
    city: "Wisen SO",
    cantonShort: "SO",
    latitude: 47.3938,
    longitude: 7.8936,
  },
  {
    zipCode: 4652,
    city: "Winznau",
    cantonShort: "SO",
    latitude: 47.3682,
    longitude: 7.9288,
  },
  {
    zipCode: 4653,
    city: "Oberg\u00f6sgen",
    cantonShort: "SO",
    latitude: 47.3642,
    longitude: 7.9541,
  },
  {
    zipCode: 4654,
    city: "Lostorf",
    cantonShort: "SO",
    latitude: 47.3872,
    longitude: 7.9349,
  },
  {
    zipCode: 4655,
    city: "St\u00fcsslingen",
    cantonShort: "SO",
    latitude: 47.3948,
    longitude: 7.9699,
  },
  {
    zipCode: 4655,
    city: "Rohr b. Olten",
    cantonShort: "SO",
    latitude: 47.4108,
    longitude: 7.9531,
  },
  {
    zipCode: 5013,
    city: "Niederg\u00f6sgen",
    cantonShort: "SO",
    latitude: 47.3736,
    longitude: 7.984,
  },
  {
    zipCode: 5015,
    city: "Erlinsbach SO",
    cantonShort: "SO",
    latitude: 47.3983,
    longitude: 7.9959,
  },
  {
    zipCode: 4528,
    city: "Zuchwil",
    cantonShort: "SO",
    latitude: 47.2047,
    longitude: 7.5634,
  },
  {
    zipCode: 4542,
    city: "Luterbach",
    cantonShort: "SO",
    latitude: 47.2175,
    longitude: 7.5881,
  },
  {
    zipCode: 4543,
    city: "Deitingen",
    cantonShort: "SO",
    latitude: 47.2177,
    longitude: 7.6223,
  },
  {
    zipCode: 4552,
    city: "Derendingen",
    cantonShort: "SO",
    latitude: 47.192,
    longitude: 7.5897,
  },
  {
    zipCode: 4553,
    city: "Subingen",
    cantonShort: "SO",
    latitude: 47.2001,
    longitude: 7.6237,
  },
  {
    zipCode: 4554,
    city: "H\u00fcniken",
    cantonShort: "SO",
    latitude: 47.1834,
    longitude: 7.6357,
  },
  {
    zipCode: 4554,
    city: "Etziken",
    cantonShort: "SO",
    latitude: 47.1879,
    longitude: 7.6458,
  },
  {
    zipCode: 4556,
    city: "Bolken",
    cantonShort: "SO",
    latitude: 47.1931,
    longitude: 7.6619,
  },
  {
    zipCode: 4556,
    city: "Burg\u00e4schi",
    cantonShort: "SO",
    latitude: 47.1725,
    longitude: 7.67,
  },
  {
    zipCode: 4556,
    city: "Aeschi SO",
    cantonShort: "SO",
    latitude: 47.1759,
    longitude: 7.6604,
  },
  {
    zipCode: 4556,
    city: "Steinhof SO",
    cantonShort: "SO",
    latitude: 47.1591,
    longitude: 7.6864,
  },
  {
    zipCode: 4557,
    city: "Horriwil",
    cantonShort: "SO",
    latitude: 47.1803,
    longitude: 7.6256,
  },
  {
    zipCode: 4558,
    city: "Heinrichswil",
    cantonShort: "SO",
    latitude: 47.1582,
    longitude: 7.6307,
  },
  {
    zipCode: 4558,
    city: "Winistorf",
    cantonShort: "SO",
    latitude: 47.1578,
    longitude: 7.6466,
  },
  {
    zipCode: 4558,
    city: "Hersiwil",
    cantonShort: "SO",
    latitude: 47.1663,
    longitude: 7.6351,
  },
  {
    zipCode: 4562,
    city: "Biberist",
    cantonShort: "SO",
    latitude: 47.1827,
    longitude: 7.5498,
  },
  {
    zipCode: 4563,
    city: "Gerlafingen",
    cantonShort: "SO",
    latitude: 47.1706,
    longitude: 7.5725,
  },
  {
    zipCode: 4564,
    city: "Obergerlafingen",
    cantonShort: "SO",
    latitude: 47.1611,
    longitude: 7.5841,
  },
  {
    zipCode: 4565,
    city: "Recherswil",
    cantonShort: "SO",
    latitude: 47.1598,
    longitude: 7.6015,
  },
  {
    zipCode: 4566,
    city: "Oekingen",
    cantonShort: "SO",
    latitude: 47.1787,
    longitude: 7.6119,
  },
  {
    zipCode: 4566,
    city: "Kriegstetten",
    cantonShort: "SO",
    latitude: 47.1749,
    longitude: 7.595,
  },
  {
    zipCode: 4566,
    city: "Halten",
    cantonShort: "SO",
    latitude: 47.1682,
    longitude: 7.6119,
  },
  {
    zipCode: 4573,
    city: "Lohn-Ammannsegg",
    cantonShort: "SO",
    latitude: 47.1724,
    longitude: 7.5278,
  },
  {
    zipCode: 2540,
    city: "Grenchen 1",
    cantonShort: "SO",
    latitude: 47.1972,
    longitude: 7.3875,
  },
  {
    zipCode: 2540,
    city: "Grenchen",
    cantonShort: "SO",
    latitude: 47.1972,
    longitude: 7.3876,
  },
  {
    zipCode: 2544,
    city: "Bettlach",
    cantonShort: "SO",
    latitude: 47.2085,
    longitude: 7.4189,
  },
  {
    zipCode: 2545,
    city: "Selzach",
    cantonShort: "SO",
    latitude: 47.2175,
    longitude: 7.4481,
  },
  {
    zipCode: 4512,
    city: "Bellach",
    cantonShort: "SO",
    latitude: 47.211,
    longitude: 7.4947,
  },
  {
    zipCode: 4513,
    city: "Langendorf",
    cantonShort: "SO",
    latitude: 47.2216,
    longitude: 7.5153,
  },
  {
    zipCode: 4514,
    city: "Lommiswil",
    cantonShort: "SO",
    latitude: 47.2284,
    longitude: 7.4679,
  },
  {
    zipCode: 4515,
    city: "Oberdorf SO",
    cantonShort: "SO",
    latitude: 47.2324,
    longitude: 7.4984,
  },
  {
    zipCode: 4515,
    city: "Weissenstein b. Solothurn",
    cantonShort: "SO",
    latitude: 47.2472,
    longitude: 7.4852,
  },
  {
    zipCode: 4522,
    city: "R\u00fcttenen",
    cantonShort: "SO",
    latitude: 47.2408,
    longitude: 7.5317,
  },
  {
    zipCode: 4523,
    city: "Niederwil SO",
    cantonShort: "SO",
    latitude: 47.247,
    longitude: 7.569,
  },
  {
    zipCode: 4524,
    city: "Oberbalmberg",
    cantonShort: "SO",
    latitude: 47.2649,
    longitude: 7.5385,
  },
  {
    zipCode: 4524,
    city: "G\u00fcnsberg",
    cantonShort: "SO",
    latitude: 47.2622,
    longitude: 7.5746,
  },
  {
    zipCode: 4524,
    city: "Balmberg",
    cantonShort: "SO",
    latitude: 47.2685,
    longitude: 7.5515,
  },
  {
    zipCode: 4525,
    city: "Balm b. G\u00fcnsberg",
    cantonShort: "SO",
    latitude: 47.2582,
    longitude: 7.5432,
  },
  {
    zipCode: 4532,
    city: "Feldbrunnen",
    cantonShort: "SO",
    latitude: 47.2249,
    longitude: 7.5521,
  },
  {
    zipCode: 4533,
    city: "Riedholz",
    cantonShort: "SO",
    latitude: 47.2319,
    longitude: 7.5701,
  },
  {
    zipCode: 4534,
    city: "Flumenthal",
    cantonShort: "SO",
    latitude: 47.2351,
    longitude: 7.6007,
  },
  {
    zipCode: 4535,
    city: "Kammersrohr",
    cantonShort: "SO",
    latitude: 47.2567,
    longitude: 7.5923,
  },
  {
    zipCode: 4535,
    city: "Hubersdorf",
    cantonShort: "SO",
    latitude: 47.245,
    longitude: 7.5887,
  },
  {
    zipCode: 4600,
    city: "Olten",
    cantonShort: "SO",
    latitude: 47.3458,
    longitude: 7.9009,
  },
  {
    zipCode: 4601,
    city: "Olten 1 F\u00e4cher",
    cantonShort: "SO",
    latitude: 47.3448,
    longitude: 7.9001,
  },
  {
    zipCode: 4605,
    city: "Olten",
    cantonShort: "SO",
    latitude: 47.35,
    longitude: 7.9033,
  },
  {
    zipCode: 4609,
    city: "Olten Sonderdienste",
    cantonShort: "SO",
    latitude: 47.3448,
    longitude: 7.9001,
  },
  {
    zipCode: 4609,
    city: "Olten",
    cantonShort: "SO",
    latitude: 47.3448,
    longitude: 7.9001,
  },
  {
    zipCode: 4612,
    city: "Wangen b. Olten",
    cantonShort: "SO",
    latitude: 47.3421,
    longitude: 7.8717,
  },
  {
    zipCode: 4613,
    city: "Rickenbach SO",
    cantonShort: "SO",
    latitude: 47.3426,
    longitude: 7.8552,
  },
  {
    zipCode: 4614,
    city: "H\u00e4gendorf",
    cantonShort: "SO",
    latitude: 47.3446,
    longitude: 7.8264,
  },
  {
    zipCode: 4615,
    city: "Allerheiligenberg",
    cantonShort: "SO",
    latitude: 47.3523,
    longitude: 7.8149,
  },
  {
    zipCode: 4616,
    city: "Kappel SO",
    cantonShort: "SO",
    latitude: 47.321,
    longitude: 7.8573,
  },
  {
    zipCode: 4617,
    city: "Gunzgen",
    cantonShort: "SO",
    latitude: 47.3121,
    longitude: 7.8355,
  },
  {
    zipCode: 4618,
    city: "Boningen",
    cantonShort: "SO",
    latitude: 47.3036,
    longitude: 7.8543,
  },
  {
    zipCode: 4629,
    city: "Fulenbach",
    cantonShort: "SO",
    latitude: 47.2808,
    longitude: 7.8327,
  },
  {
    zipCode: 4656,
    city: "Starrkirch-Wil",
    cantonShort: "SO",
    latitude: 47.3419,
    longitude: 7.927,
  },
  {
    zipCode: 4657,
    city: "Dulliken",
    cantonShort: "SO",
    latitude: 47.3473,
    longitude: 7.9454,
  },
  {
    zipCode: 4658,
    city: "D\u00e4niken SO",
    cantonShort: "SO",
    latitude: 47.3488,
    longitude: 7.9777,
  },
  {
    zipCode: 5012,
    city: "W\u00f6schnau",
    cantonShort: "SO",
    latitude: 47.3844,
    longitude: 8.0251,
  },
  {
    zipCode: 5012,
    city: "Sch\u00f6nenwerd",
    cantonShort: "SO",
    latitude: 47.3732,
    longitude: 8.0095,
  },
  {
    zipCode: 5012,
    city: "Eppenberg",
    cantonShort: "SO",
    latitude: 47.3741,
    longitude: 8.0238,
  },
  {
    zipCode: 5014,
    city: "Gretzenbach",
    cantonShort: "SO",
    latitude: 47.3541,
    longitude: 7.9996,
  },
  {
    zipCode: 5746,
    city: "Walterswil SO",
    cantonShort: "SO",
    latitude: 47.3305,
    longitude: 7.9632,
  },
  {
    zipCode: 4500,
    city: "Solothurn",
    cantonShort: "SO",
    latitude: 47.2062,
    longitude: 7.5295,
  },
  {
    zipCode: 4501,
    city: "Solothurn",
    cantonShort: "SO",
    latitude: 47.2084,
    longitude: 7.5301,
  },
  {
    zipCode: 4502,
    city: "Solothurn",
    cantonShort: "SO",
    latitude: 47.2079,
    longitude: 7.5371,
  },
  {
    zipCode: 4503,
    city: "Solothurn",
    cantonShort: "SO",
    latitude: 47.2079,
    longitude: 7.5371,
  },
  {
    zipCode: 4509,
    city: "Solothurn",
    cantonShort: "SO",
    latitude: 47.2079,
    longitude: 7.5371,
  },
  {
    zipCode: 4204,
    city: "Himmelried",
    cantonShort: "SO",
    latitude: 47.423,
    longitude: 7.5939,
  },
  {
    zipCode: 4208,
    city: "Nunningen",
    cantonShort: "SO",
    latitude: 47.3977,
    longitude: 7.6166,
  },
  {
    zipCode: 4226,
    city: "Breitenbach",
    cantonShort: "SO",
    latitude: 47.4061,
    longitude: 7.5512,
  },
  {
    zipCode: 4227,
    city: "B\u00fcsserach",
    cantonShort: "SO",
    latitude: 47.3897,
    longitude: 7.5447,
  },
  {
    zipCode: 4228,
    city: "Erschwil",
    cantonShort: "SO",
    latitude: 47.3716,
    longitude: 7.5445,
  },
  {
    zipCode: 4229,
    city: "Beinwil SO",
    cantonShort: "SO",
    latitude: 47.3578,
    longitude: 7.5951,
  },
  {
    zipCode: 4232,
    city: "Fehren",
    cantonShort: "SO",
    latitude: 47.4027,
    longitude: 7.5786,
  },
  {
    zipCode: 4233,
    city: "Meltingen",
    cantonShort: "SO",
    latitude: 47.3795,
    longitude: 7.5884,
  },
  {
    zipCode: 4234,
    city: "Zullwil",
    cantonShort: "SO",
    latitude: 47.388,
    longitude: 7.6036,
  },
  {
    zipCode: 4245,
    city: "Kleinl\u00fctzel",
    cantonShort: "SO",
    latitude: 47.4266,
    longitude: 7.4179,
  },
  {
    zipCode: 4247,
    city: "Grindel",
    cantonShort: "SO",
    latitude: 47.3797,
    longitude: 7.5073,
  },
  {
    zipCode: 4252,
    city: "B\u00e4rschwil",
    cantonShort: "SO",
    latitude: 47.3851,
    longitude: 7.4659,
  },
  {
    zipCode: 4252,
    city: "B\u00e4rschwil Dorf",
    cantonShort: "SO",
    latitude: 47.385,
    longitude: 7.4657,
  },
  {
    zipCode: 8836,
    city: "Bennau",
    cantonShort: "SZ",
    latitude: 47.1506,
    longitude: 8.7145,
  },
  {
    zipCode: 8840,
    city: "Trachslau",
    cantonShort: "SZ",
    latitude: 47.1033,
    longitude: 8.7274,
  },
  {
    zipCode: 8840,
    city: "Einsiedeln",
    cantonShort: "SZ",
    latitude: 47.1316,
    longitude: 8.7441,
  },
  {
    zipCode: 8841,
    city: "Gross",
    cantonShort: "SZ",
    latitude: 47.0969,
    longitude: 8.7628,
  },
  {
    zipCode: 8844,
    city: "Euthal",
    cantonShort: "SZ",
    latitude: 47.0903,
    longitude: 8.8293,
  },
  {
    zipCode: 8846,
    city: "Willerzell",
    cantonShort: "SZ",
    latitude: 47.1299,
    longitude: 8.8105,
  },
  {
    zipCode: 8847,
    city: "Egg SZ",
    cantonShort: "SZ",
    latitude: 47.161,
    longitude: 8.7773,
  },
  {
    zipCode: 6410,
    city: "Rigi Scheidegg",
    cantonShort: "SZ",
    latitude: 47.0257,
    longitude: 8.5187,
  },
  {
    zipCode: 6442,
    city: "Gersau",
    cantonShort: "SZ",
    latitude: 46.9977,
    longitude: 8.5198,
  },
  {
    zipCode: 8640,
    city: "Hurden",
    cantonShort: "SZ",
    latitude: 47.2129,
    longitude: 8.8026,
  },
  {
    zipCode: 8806,
    city: "B\u00e4ch SZ",
    cantonShort: "SZ",
    latitude: 47.2043,
    longitude: 8.7308,
  },
  {
    zipCode: 8807,
    city: "Freienbach",
    cantonShort: "SZ",
    latitude: 47.207,
    longitude: 8.7571,
  },
  {
    zipCode: 8808,
    city: "Pf\u00e4ffikon SZ",
    cantonShort: "SZ",
    latitude: 47.1976,
    longitude: 8.7832,
  },
  {
    zipCode: 8832,
    city: "Wollerau",
    cantonShort: "SZ",
    latitude: 47.1907,
    longitude: 8.7051,
  },
  {
    zipCode: 8832,
    city: "Wilen b. Wollerau",
    cantonShort: "SZ",
    latitude: 47.1958,
    longitude: 8.7349,
  },
  {
    zipCode: 8832,
    city: "Wollerau",
    cantonShort: "SZ",
    latitude: 47.1907,
    longitude: 8.7051,
  },
  {
    zipCode: 8834,
    city: "Schindellegi",
    cantonShort: "SZ",
    latitude: 47.1736,
    longitude: 8.711,
  },
  {
    zipCode: 8835,
    city: "Feusisberg",
    cantonShort: "SZ",
    latitude: 47.1812,
    longitude: 8.7516,
  },
  {
    zipCode: 6402,
    city: "Merlischachen",
    cantonShort: "SZ",
    latitude: 47.0704,
    longitude: 8.4065,
  },
  {
    zipCode: 6403,
    city: "K\u00fcssnacht am Rigi",
    cantonShort: "SZ",
    latitude: 47.0789,
    longitude: 8.4457,
  },
  {
    zipCode: 6405,
    city: "Immensee",
    cantonShort: "SZ",
    latitude: 47.094,
    longitude: 8.4748,
  },
  {
    zipCode: 8852,
    city: "Altendorf",
    cantonShort: "SZ",
    latitude: 47.1765,
    longitude: 8.8278,
  },
  {
    zipCode: 8853,
    city: "Lachen SZ",
    cantonShort: "SZ",
    latitude: 47.197,
    longitude: 8.8542,
  },
  {
    zipCode: 8854,
    city: "Siebnen",
    cantonShort: "SZ",
    latitude: 47.1486,
    longitude: 8.9229,
  },
  {
    zipCode: 8854,
    city: "Galgenen",
    cantonShort: "SZ",
    latitude: 47.1673,
    longitude: 8.8678,
  },
  {
    zipCode: 8855,
    city: "Wangen SZ",
    cantonShort: "SZ",
    latitude: 47.1988,
    longitude: 8.8911,
  },
  {
    zipCode: 8856,
    city: "Tuggen",
    cantonShort: "SZ",
    latitude: 47.2014,
    longitude: 8.9385,
  },
  {
    zipCode: 8857,
    city: "Vorderthal",
    cantonShort: "SZ",
    latitude: 47.1263,
    longitude: 8.8893,
  },
  {
    zipCode: 8858,
    city: "Innerthal",
    cantonShort: "SZ",
    latitude: 47.0756,
    longitude: 8.9172,
  },
  {
    zipCode: 8862,
    city: "Sch\u00fcbelbach",
    cantonShort: "SZ",
    latitude: 47.1746,
    longitude: 8.9271,
  },
  {
    zipCode: 8863,
    city: "Buttikon SZ",
    cantonShort: "SZ",
    latitude: 47.1674,
    longitude: 8.9471,
  },
  {
    zipCode: 8864,
    city: "Reichenburg",
    cantonShort: "SZ",
    latitude: 47.161,
    longitude: 8.9733,
  },
  {
    zipCode: 6410,
    city: "Rigi Kulm",
    cantonShort: "SZ",
    latitude: 47.0562,
    longitude: 8.4904,
  },
  {
    zipCode: 6410,
    city: "Rigi Staffel",
    cantonShort: "SZ",
    latitude: 47.05,
    longitude: 8.476,
  },
  {
    zipCode: 6410,
    city: "Rigi Kl\u00f6sterli",
    cantonShort: "SZ",
    latitude: 47.0432,
    longitude: 8.4982,
  },
  {
    zipCode: 6410,
    city: "Goldau",
    cantonShort: "SZ",
    latitude: 47.0433,
    longitude: 8.5468,
  },
  {
    zipCode: 6414,
    city: "Oberarth",
    cantonShort: "SZ",
    latitude: 47.0543,
    longitude: 8.5335,
  },
  {
    zipCode: 6415,
    city: "Arth",
    cantonShort: "SZ",
    latitude: 47.0749,
    longitude: 8.5245,
  },
  {
    zipCode: 6416,
    city: "Steinerberg",
    cantonShort: "SZ",
    latitude: 47.0674,
    longitude: 8.5808,
  },
  {
    zipCode: 6417,
    city: "Sattel",
    cantonShort: "SZ",
    latitude: 47.08,
    longitude: 8.6312,
  },
  {
    zipCode: 6418,
    city: "Rothenthurm",
    cantonShort: "SZ",
    latitude: 47.1019,
    longitude: 8.6804,
  },
  {
    zipCode: 6422,
    city: "Steinen",
    cantonShort: "SZ",
    latitude: 47.0517,
    longitude: 8.6095,
  },
  {
    zipCode: 6423,
    city: "Seewen SZ",
    cantonShort: "SZ",
    latitude: 47.0328,
    longitude: 8.6281,
  },
  {
    zipCode: 6424,
    city: "Lauerz",
    cantonShort: "SZ",
    latitude: 47.0248,
    longitude: 8.5805,
  },
  {
    zipCode: 6430,
    city: "Schwyz",
    cantonShort: "SZ",
    latitude: 47.0346,
    longitude: 8.6667,
  },
  {
    zipCode: 6431,
    city: "Schwyz",
    cantonShort: "SZ",
    latitude: 47.0208,
    longitude: 8.6541,
  },
  {
    zipCode: 6432,
    city: "Rickenbach b. Schwyz",
    cantonShort: "SZ",
    latitude: 47.011,
    longitude: 8.6928,
  },
  {
    zipCode: 6433,
    city: "Stoos SZ",
    cantonShort: "SZ",
    latitude: 46.97,
    longitude: 8.6717,
  },
  {
    zipCode: 6434,
    city: "Illgau",
    cantonShort: "SZ",
    latitude: 46.9965,
    longitude: 8.7399,
  },
  {
    zipCode: 6436,
    city: "Bisisthal",
    cantonShort: "SZ",
    latitude: 46.9296,
    longitude: 8.8692,
  },
  {
    zipCode: 6436,
    city: "Muotathal",
    cantonShort: "SZ",
    latitude: 46.9636,
    longitude: 8.8079,
  },
  {
    zipCode: 6436,
    city: "Ried (Muotathal)",
    cantonShort: "SZ",
    latitude: 46.982,
    longitude: 8.71,
  },
  {
    zipCode: 6438,
    city: "Ibach",
    cantonShort: "SZ",
    latitude: 47.0025,
    longitude: 8.6469,
  },
  {
    zipCode: 6440,
    city: "Brunnen",
    cantonShort: "SZ",
    latitude: 47.0009,
    longitude: 8.5956,
  },
  {
    zipCode: 6443,
    city: "Morschach",
    cantonShort: "SZ",
    latitude: 46.9748,
    longitude: 8.621,
  },
  {
    zipCode: 6452,
    city: "Riemenstalden",
    cantonShort: "SZ",
    latitude: 46.938,
    longitude: 8.6855,
  },
  {
    zipCode: 8842,
    city: "Unteriberg",
    cantonShort: "SZ",
    latitude: 47.0415,
    longitude: 8.8079,
  },
  {
    zipCode: 8843,
    city: "Oberiberg",
    cantonShort: "SZ",
    latitude: 47.0308,
    longitude: 8.7617,
  },
  {
    zipCode: 8845,
    city: "Studen SZ",
    cantonShort: "SZ",
    latitude: 47.0436,
    longitude: 8.8589,
  },
  {
    zipCode: 8849,
    city: "Alpthal",
    cantonShort: "SZ",
    latitude: 47.0578,
    longitude: 8.7145,
  },
  {
    zipCode: 8580,
    city: "Hefenhofen",
    cantonShort: "TG",
    latitude: 47.5613,
    longitude: 9.3131,
  },
  {
    zipCode: 8580,
    city: "Sommeri",
    cantonShort: "TG",
    latitude: 47.5713,
    longitude: 9.2839,
  },
  {
    zipCode: 8580,
    city: "Biessenhofen",
    cantonShort: "TG",
    latitude: 47.5454,
    longitude: 9.2584,
  },
  {
    zipCode: 8580,
    city: "Hagenwil b. Amriswil",
    cantonShort: "TG",
    latitude: 47.5292,
    longitude: 9.3065,
  },
  {
    zipCode: 8580,
    city: "Amriswil",
    cantonShort: "TG",
    latitude: 47.5411,
    longitude: 9.2981,
  },
  {
    zipCode: 8581,
    city: "Schocherswil",
    cantonShort: "TG",
    latitude: 47.5361,
    longitude: 9.2644,
  },
  {
    zipCode: 8582,
    city: "Dozwil",
    cantonShort: "TG",
    latitude: 47.5761,
    longitude: 9.3191,
  },
  {
    zipCode: 8587,
    city: "Oberaach",
    cantonShort: "TG",
    latitude: 47.5597,
    longitude: 9.2726,
  },
  {
    zipCode: 8590,
    city: "Romanshorn",
    cantonShort: "TG",
    latitude: 47.5805,
    longitude: 9.3897,
  },
  {
    zipCode: 8592,
    city: "Uttwil",
    cantonShort: "TG",
    latitude: 47.5994,
    longitude: 9.3554,
  },
  {
    zipCode: 8593,
    city: "Kesswil",
    cantonShort: "TG",
    latitude: 47.6029,
    longitude: 9.3264,
  },
  {
    zipCode: 8599,
    city: "Salmsach",
    cantonShort: "TG",
    latitude: 47.5544,
    longitude: 9.3736,
  },
  {
    zipCode: 9306,
    city: "Freidorf TG",
    cantonShort: "TG",
    latitude: 47.4854,
    longitude: 9.3858,
  },
  {
    zipCode: 9314,
    city: "Steinebrunn",
    cantonShort: "TG",
    latitude: 47.5338,
    longitude: 9.3438,
  },
  {
    zipCode: 9315,
    city: "Neukirch (Egnach)",
    cantonShort: "TG",
    latitude: 47.5263,
    longitude: 9.3716,
  },
  {
    zipCode: 9315,
    city: "Winden",
    cantonShort: "TG",
    latitude: 47.5097,
    longitude: 9.3577,
  },
  {
    zipCode: 9320,
    city: "Arbon",
    cantonShort: "TG",
    latitude: 47.5331,
    longitude: 9.4531,
  },
  {
    zipCode: 9320,
    city: "Stachen",
    cantonShort: "TG",
    latitude: 47.5088,
    longitude: 9.4108,
  },
  {
    zipCode: 9320,
    city: "Frasnacht",
    cantonShort: "TG",
    latitude: 47.5444,
    longitude: 9.4333,
  },
  {
    zipCode: 9322,
    city: "Egnach",
    cantonShort: "TG",
    latitude: 47.5529,
    longitude: 9.4079,
  },
  {
    zipCode: 9325,
    city: "Roggwil TG",
    cantonShort: "TG",
    latitude: 47.5057,
    longitude: 9.3928,
  },
  {
    zipCode: 9326,
    city: "Horn",
    cantonShort: "TG",
    latitude: 47.5159,
    longitude: 9.4839,
  },
  {
    zipCode: 8252,
    city: "Schlatt TG",
    cantonShort: "TG",
    latitude: 47.6672,
    longitude: 8.6989,
  },
  {
    zipCode: 8253,
    city: "Willisdorf",
    cantonShort: "TG",
    latitude: 47.6798,
    longitude: 8.7378,
  },
  {
    zipCode: 8253,
    city: "Diessenhofen",
    cantonShort: "TG",
    latitude: 47.6812,
    longitude: 8.746,
  },
  {
    zipCode: 8254,
    city: "Basadingen",
    cantonShort: "TG",
    latitude: 47.6607,
    longitude: 8.7403,
  },
  {
    zipCode: 8255,
    city: "Schlattingen",
    cantonShort: "TG",
    latitude: 47.6605,
    longitude: 8.7691,
  },
  {
    zipCode: 8259,
    city: "Etzwilen",
    cantonShort: "TG",
    latitude: 47.6571,
    longitude: 8.8169,
  },
  {
    zipCode: 8259,
    city: "Kaltenbach",
    cantonShort: "TG",
    latitude: 47.6459,
    longitude: 8.8376,
  },
  {
    zipCode: 8259,
    city: "Rheinklingen",
    cantonShort: "TG",
    latitude: 47.6709,
    longitude: 8.8144,
  },
  {
    zipCode: 8259,
    city: "Wagenhausen",
    cantonShort: "TG",
    latitude: 47.6629,
    longitude: 8.8381,
  },
  {
    zipCode: 8264,
    city: "Eschenz",
    cantonShort: "TG",
    latitude: 47.6365,
    longitude: 8.8756,
  },
  {
    zipCode: 8265,
    city: "Mammern",
    cantonShort: "TG",
    latitude: 47.6467,
    longitude: 8.9214,
  },
  {
    zipCode: 8266,
    city: "Steckborn",
    cantonShort: "TG",
    latitude: 47.6604,
    longitude: 8.9866,
  },
  {
    zipCode: 8267,
    city: "Berlingen",
    cantonShort: "TG",
    latitude: 47.6733,
    longitude: 9.0259,
  },
  {
    zipCode: 8500,
    city: "Frauenfeld",
    cantonShort: "TG",
    latitude: 47.5598,
    longitude: 8.8977,
  },
  {
    zipCode: 8500,
    city: "Gerlikon",
    cantonShort: "TG",
    latitude: 47.5353,
    longitude: 8.8801,
  },
  {
    zipCode: 8501,
    city: "Frauenfeld",
    cantonShort: "TG",
    latitude: 47.5578,
    longitude: 8.8989,
  },
  {
    zipCode: 8502,
    city: "Frauenfeld",
    cantonShort: "TG",
    latitude: 47.558,
    longitude: 8.8964,
  },
  {
    zipCode: 8503,
    city: "Frauenfeld",
    cantonShort: "TG",
    latitude: 47.558,
    longitude: 8.8964,
  },
  {
    zipCode: 8505,
    city: "Dettighofen",
    cantonShort: "TG",
    latitude: 47.618,
    longitude: 8.9553,
  },
  {
    zipCode: 8505,
    city: "Pfyn",
    cantonShort: "TG",
    latitude: 47.599,
    longitude: 8.9519,
  },
  {
    zipCode: 8506,
    city: "Lanzenneunforn",
    cantonShort: "TG",
    latitude: 47.6239,
    longitude: 8.9336,
  },
  {
    zipCode: 8507,
    city: "H\u00f6rhausen",
    cantonShort: "TG",
    latitude: 47.6416,
    longitude: 8.9714,
  },
  {
    zipCode: 8508,
    city: "Homburg",
    cantonShort: "TG",
    latitude: 47.6292,
    longitude: 8.9995,
  },
  {
    zipCode: 8509,
    city: "Frauenfeld",
    cantonShort: "TG",
    latitude: 47.5578,
    longitude: 8.8989,
  },
  {
    zipCode: 8510,
    city: "Frauenfeld Kant. Verwaltung",
    cantonShort: "TG",
    latitude: 47.558,
    longitude: 8.8964,
  },
  {
    zipCode: 8510,
    city: "Frauenfeld",
    cantonShort: "TG",
    latitude: 47.558,
    longitude: 8.8964,
  },
  {
    zipCode: 8512,
    city: "Thundorf",
    cantonShort: "TG",
    latitude: 47.5472,
    longitude: 8.9642,
  },
  {
    zipCode: 8512,
    city: "Wetzikon TG",
    cantonShort: "TG",
    latitude: 47.5385,
    longitude: 8.9982,
  },
  {
    zipCode: 8512,
    city: "Lustdorf",
    cantonShort: "TG",
    latitude: 47.5548,
    longitude: 8.9882,
  },
  {
    zipCode: 8524,
    city: "Buch b. Frauenfeld",
    cantonShort: "TG",
    latitude: 47.5973,
    longitude: 8.8378,
  },
  {
    zipCode: 8524,
    city: "Uesslingen",
    cantonShort: "TG",
    latitude: 47.5822,
    longitude: 8.824,
  },
  {
    zipCode: 8525,
    city: "Niederneunforn",
    cantonShort: "TG",
    latitude: 47.5944,
    longitude: 8.7781,
  },
  {
    zipCode: 8525,
    city: "Wilen b. Neunforn",
    cantonShort: "TG",
    latitude: 47.6013,
    longitude: 8.7972,
  },
  {
    zipCode: 8526,
    city: "Oberneunforn",
    cantonShort: "TG",
    latitude: 47.6083,
    longitude: 8.7627,
  },
  {
    zipCode: 8532,
    city: "Weiningen TG",
    cantonShort: "TG",
    latitude: 47.5905,
    longitude: 8.8983,
  },
  {
    zipCode: 8532,
    city: "Warth",
    cantonShort: "TG",
    latitude: 47.5857,
    longitude: 8.8697,
  },
  {
    zipCode: 8535,
    city: "Herdern",
    cantonShort: "TG",
    latitude: 47.6105,
    longitude: 8.9102,
  },
  {
    zipCode: 8536,
    city: "H\u00fcttwilen",
    cantonShort: "TG",
    latitude: 47.6109,
    longitude: 8.8696,
  },
  {
    zipCode: 8537,
    city: "Uerschhausen",
    cantonShort: "TG",
    latitude: 47.6089,
    longitude: 8.8169,
  },
  {
    zipCode: 8537,
    city: "Nussbaumen TG",
    cantonShort: "TG",
    latitude: 47.624,
    longitude: 8.8299,
  },
  {
    zipCode: 8546,
    city: "Kefikon TG",
    cantonShort: "TG",
    latitude: 47.5527,
    longitude: 8.8348,
  },
  {
    zipCode: 8546,
    city: "Islikon",
    cantonShort: "TG",
    latitude: 47.5509,
    longitude: 8.8449,
  },
  {
    zipCode: 8547,
    city: "Gachnang",
    cantonShort: "TG",
    latitude: 47.5381,
    longitude: 8.8552,
  },
  {
    zipCode: 8552,
    city: "Felben-Wellhausen",
    cantonShort: "TG",
    latitude: 47.577,
    longitude: 8.94,
  },
  {
    zipCode: 8553,
    city: "Eschikofen",
    cantonShort: "TG",
    latitude: 47.5804,
    longitude: 9.0047,
  },
  {
    zipCode: 8553,
    city: "H\u00fcttlingen",
    cantonShort: "TG",
    latitude: 47.5785,
    longitude: 8.9844,
  },
  {
    zipCode: 8553,
    city: "Mettendorf TG",
    cantonShort: "TG",
    latitude: 47.5777,
    longitude: 8.9664,
  },
  {
    zipCode: 8553,
    city: "Harenwilen",
    cantonShort: "TG",
    latitude: 47.5683,
    longitude: 9.0013,
  },
  {
    zipCode: 8555,
    city: "M\u00fcllheim Dorf",
    cantonShort: "TG",
    latitude: 47.6028,
    longitude: 9.0037,
  },
  {
    zipCode: 9507,
    city: "Stettfurt",
    cantonShort: "TG",
    latitude: 47.525,
    longitude: 8.9585,
  },
  {
    zipCode: 9548,
    city: "Matzingen",
    cantonShort: "TG",
    latitude: 47.5253,
    longitude: 8.9285,
  },
  {
    zipCode: 8268,
    city: "Mannenbach-Salenstein",
    cantonShort: "TG",
    latitude: 47.6758,
    longitude: 9.0539,
  },
  {
    zipCode: 8268,
    city: "Salenstein",
    cantonShort: "TG",
    latitude: 47.6627,
    longitude: 9.051,
  },
  {
    zipCode: 8269,
    city: "Fruthwilen",
    cantonShort: "TG",
    latitude: 47.654,
    longitude: 9.0647,
  },
  {
    zipCode: 8272,
    city: "Ermatingen",
    cantonShort: "TG",
    latitude: 47.6604,
    longitude: 9.0861,
  },
  {
    zipCode: 8273,
    city: "Triboltingen",
    cantonShort: "TG",
    latitude: 47.6599,
    longitude: 9.1078,
  },
  {
    zipCode: 8274,
    city: "T\u00e4gerwilen",
    cantonShort: "TG",
    latitude: 47.6481,
    longitude: 9.1318,
  },
  {
    zipCode: 8274,
    city: "Gottlieben",
    cantonShort: "TG",
    latitude: 47.6654,
    longitude: 9.126,
  },
  {
    zipCode: 8280,
    city: "Kreuzlingen 3",
    cantonShort: "TG",
    latitude: 47.6398,
    longitude: 9.1727,
  },
  {
    zipCode: 8280,
    city: "Kreuzlingen 1",
    cantonShort: "TG",
    latitude: 47.6398,
    longitude: 9.1727,
  },
  {
    zipCode: 8280,
    city: "Kreuzlingen",
    cantonShort: "TG",
    latitude: 47.6415,
    longitude: 9.1752,
  },
  {
    zipCode: 8285,
    city: "Kreuzlingen Ifolor AG",
    cantonShort: "TG",
    latitude: 47.6398,
    longitude: 9.1727,
  },
  {
    zipCode: 8285,
    city: "Kreuzlingen Ifolor",
    cantonShort: "TG",
    latitude: 47.6398,
    longitude: 9.1727,
  },
  {
    zipCode: 8558,
    city: "Raperswilen",
    cantonShort: "TG",
    latitude: 47.6367,
    longitude: 9.047,
  },
  {
    zipCode: 8564,
    city: "Engwilen",
    cantonShort: "TG",
    latitude: 47.6161,
    longitude: 9.0959,
  },
  {
    zipCode: 8564,
    city: "W\u00e4ldi",
    cantonShort: "TG",
    latitude: 47.6322,
    longitude: 9.0953,
  },
  {
    zipCode: 8564,
    city: "Lipperswil",
    cantonShort: "TG",
    latitude: 47.6172,
    longitude: 9.055,
  },
  {
    zipCode: 8564,
    city: "Sonterswil",
    cantonShort: "TG",
    latitude: 47.6221,
    longitude: 9.0814,
  },
  {
    zipCode: 8564,
    city: "Gunterswilen",
    cantonShort: "TG",
    latitude: 47.6355,
    longitude: 9.077,
  },
  {
    zipCode: 8564,
    city: "Hattenhausen",
    cantonShort: "TG",
    latitude: 47.6267,
    longitude: 9.0679,
  },
  {
    zipCode: 8564,
    city: "Hefenhausen",
    cantonShort: "TG",
    latitude: 47.6161,
    longitude: 9.0688,
  },
  {
    zipCode: 8565,
    city: "Hugelshofen",
    cantonShort: "TG",
    latitude: 47.5953,
    longitude: 9.1175,
  },
  {
    zipCode: 8566,
    city: "Dotnacht",
    cantonShort: "TG",
    latitude: 47.5972,
    longitude: 9.143,
  },
  {
    zipCode: 8566,
    city: "Neuwilen",
    cantonShort: "TG",
    latitude: 47.6238,
    longitude: 9.1301,
  },
  {
    zipCode: 8566,
    city: "Lippoldswilen",
    cantonShort: "TG",
    latitude: 47.6106,
    longitude: 9.1139,
  },
  {
    zipCode: 8566,
    city: "Ellighausen",
    cantonShort: "TG",
    latitude: 47.6145,
    longitude: 9.1388,
  },
  {
    zipCode: 8573,
    city: "Alterswilen",
    cantonShort: "TG",
    latitude: 47.6108,
    longitude: 9.1536,
  },
  {
    zipCode: 8573,
    city: "Altishausen",
    cantonShort: "TG",
    latitude: 47.5986,
    longitude: 9.1694,
  },
  {
    zipCode: 8573,
    city: "Siegershausen",
    cantonShort: "TG",
    latitude: 47.6167,
    longitude: 9.1689,
  },
  {
    zipCode: 8574,
    city: "Illighausen",
    cantonShort: "TG",
    latitude: 47.6031,
    longitude: 9.2098,
  },
  {
    zipCode: 8574,
    city: "Oberhofen TG",
    cantonShort: "TG",
    latitude: 47.6125,
    longitude: 9.1914,
  },
  {
    zipCode: 8574,
    city: "Dettighofen (Lengwil)",
    cantonShort: "TG",
    latitude: 47.6225,
    longitude: 9.2043,
  },
  {
    zipCode: 8574,
    city: "Lengwil",
    cantonShort: "TG",
    latitude: 47.6219,
    longitude: 9.1969,
  },
  {
    zipCode: 8574,
    city: "Lengwil",
    cantonShort: "TG",
    latitude: 47.6219,
    longitude: 9.1969,
  },
  {
    zipCode: 8585,
    city: "Herrenhof",
    cantonShort: "TG",
    latitude: 47.5973,
    longitude: 9.2421,
  },
  {
    zipCode: 8585,
    city: "Langrickenbach",
    cantonShort: "TG",
    latitude: 47.583,
    longitude: 9.2506,
  },
  {
    zipCode: 8585,
    city: "Zuben",
    cantonShort: "TG",
    latitude: 47.6026,
    longitude: 9.2312,
  },
  {
    zipCode: 8585,
    city: "Sch\u00f6nenbaumgarten",
    cantonShort: "TG",
    latitude: 47.6129,
    longitude: 9.2268,
  },
  {
    zipCode: 8594,
    city: "G\u00fcttingen",
    cantonShort: "TG",
    latitude: 47.6118,
    longitude: 9.3003,
  },
  {
    zipCode: 8595,
    city: "Altnau",
    cantonShort: "TG",
    latitude: 47.6196,
    longitude: 9.272,
  },
  {
    zipCode: 8596,
    city: "M\u00fcnsterlingen",
    cantonShort: "TG",
    latitude: 47.6457,
    longitude: 9.2374,
  },
  {
    zipCode: 8596,
    city: "Scherzingen",
    cantonShort: "TG",
    latitude: 47.6285,
    longitude: 9.2247,
  },
  {
    zipCode: 8597,
    city: "Landschlacht",
    cantonShort: "TG",
    latitude: 47.6373,
    longitude: 9.258,
  },
  {
    zipCode: 8598,
    city: "Bottighofen",
    cantonShort: "TG",
    latitude: 47.6403,
    longitude: 9.2129,
  },
  {
    zipCode: 8355,
    city: "Aadorf",
    cantonShort: "TG",
    latitude: 47.492,
    longitude: 8.9075,
  },
  {
    zipCode: 8356,
    city: "Ettenhausen TG",
    cantonShort: "TG",
    latitude: 47.4743,
    longitude: 8.8967,
  },
  {
    zipCode: 8357,
    city: "Guntershausen b. Aadorf",
    cantonShort: "TG",
    latitude: 47.4717,
    longitude: 8.9207,
  },
  {
    zipCode: 8360,
    city: "Eschlikon TG",
    cantonShort: "TG",
    latitude: 47.4638,
    longitude: 8.9671,
  },
  {
    zipCode: 8360,
    city: "Wallenwil",
    cantonShort: "TG",
    latitude: 47.4532,
    longitude: 8.9547,
  },
  {
    zipCode: 8362,
    city: "Balterswil",
    cantonShort: "TG",
    latitude: 47.454,
    longitude: 8.9368,
  },
  {
    zipCode: 8363,
    city: "Bichelsee",
    cantonShort: "TG",
    latitude: 47.4462,
    longitude: 8.9178,
  },
  {
    zipCode: 8370,
    city: "Sirnach",
    cantonShort: "TG",
    latitude: 47.4624,
    longitude: 8.9983,
  },
  {
    zipCode: 8371,
    city: "Busswil TG",
    cantonShort: "TG",
    latitude: 47.4533,
    longitude: 9.0172,
  },
  {
    zipCode: 8372,
    city: "Wiezikon b. Sirnach",
    cantonShort: "TG",
    latitude: 47.4466,
    longitude: 8.9872,
  },
  {
    zipCode: 8374,
    city: "Oberwangen TG",
    cantonShort: "TG",
    latitude: 47.4294,
    longitude: 8.9813,
  },
  {
    zipCode: 8374,
    city: "Dussnang",
    cantonShort: "TG",
    latitude: 47.422,
    longitude: 8.9427,
  },
  {
    zipCode: 8376,
    city: "Fischingen",
    cantonShort: "TG",
    latitude: 47.411,
    longitude: 8.973,
  },
  {
    zipCode: 8376,
    city: "Au TG",
    cantonShort: "TG",
    latitude: 47.3944,
    longitude: 8.9512,
  },
  {
    zipCode: 8522,
    city: "Aawangen",
    cantonShort: "TG",
    latitude: 47.5118,
    longitude: 8.9022,
  },
  {
    zipCode: 8522,
    city: "H\u00e4uslenen",
    cantonShort: "TG",
    latitude: 47.5273,
    longitude: 8.9021,
  },
  {
    zipCode: 9502,
    city: "Braunau",
    cantonShort: "TG",
    latitude: 47.5044,
    longitude: 9.0689,
  },
  {
    zipCode: 9506,
    city: "Lommis",
    cantonShort: "TG",
    latitude: 47.5148,
    longitude: 8.9976,
  },
  {
    zipCode: 9508,
    city: "Weingarten-Kalth\u00e4usern",
    cantonShort: "TG",
    latitude: 47.5253,
    longitude: 8.9872,
  },
  {
    zipCode: 9532,
    city: "Rickenbach b. Wil",
    cantonShort: "TG",
    latitude: 47.4457,
    longitude: 9.0487,
  },
  {
    zipCode: 9535,
    city: "Wilen b. Wil",
    cantonShort: "TG",
    latitude: 47.4471,
    longitude: 9.0321,
  },
  {
    zipCode: 9542,
    city: "M\u00fcnchwilen TG",
    cantonShort: "TG",
    latitude: 47.4794,
    longitude: 8.991,
  },
  {
    zipCode: 9543,
    city: "St. Margarethen TG",
    cantonShort: "TG",
    latitude: 47.4906,
    longitude: 9.0028,
  },
  {
    zipCode: 9545,
    city: "W\u00e4ngi",
    cantonShort: "TG",
    latitude: 47.4988,
    longitude: 8.9618,
  },
  {
    zipCode: 9546,
    city: "Tuttwil",
    cantonShort: "TG",
    latitude: 47.4808,
    longitude: 8.9482,
  },
  {
    zipCode: 9547,
    city: "Wittenwil",
    cantonShort: "TG",
    latitude: 47.5033,
    longitude: 8.9227,
  },
  {
    zipCode: 9553,
    city: "Bettwiesen",
    cantonShort: "TG",
    latitude: 47.4977,
    longitude: 9.0187,
  },
  {
    zipCode: 9554,
    city: "T\u00e4gerschen",
    cantonShort: "TG",
    latitude: 47.5062,
    longitude: 9.0279,
  },
  {
    zipCode: 9555,
    city: "Tobel",
    cantonShort: "TG",
    latitude: 47.5167,
    longitude: 9.0442,
  },
  {
    zipCode: 9573,
    city: "Littenheid",
    cantonShort: "TG",
    latitude: 47.4414,
    longitude: 9.0079,
  },
  {
    zipCode: 8514,
    city: "Amlikon-Bissegg",
    cantonShort: "TG",
    latitude: 47.5588,
    longitude: 9.0353,
  },
  {
    zipCode: 8554,
    city: "Bonau",
    cantonShort: "TG",
    latitude: 47.5827,
    longitude: 9.0355,
  },
  {
    zipCode: 8554,
    city: "M\u00fcllheim-Wigoltingen",
    cantonShort: "TG",
    latitude: 47.5901,
    longitude: 9.0133,
  },
  {
    zipCode: 8556,
    city: "Lamperswil TG",
    cantonShort: "TG",
    latitude: 47.6107,
    longitude: 9.0372,
  },
  {
    zipCode: 8556,
    city: "Engwang",
    cantonShort: "TG",
    latitude: 47.6012,
    longitude: 9.0524,
  },
  {
    zipCode: 8556,
    city: "Wigoltingen",
    cantonShort: "TG",
    latitude: 47.5974,
    longitude: 9.0314,
  },
  {
    zipCode: 8556,
    city: "Illhart",
    cantonShort: "TG",
    latitude: 47.6182,
    longitude: 9.0361,
  },
  {
    zipCode: 8560,
    city: "M\u00e4rstetten",
    cantonShort: "TG",
    latitude: 47.5913,
    longitude: 9.068,
  },
  {
    zipCode: 8561,
    city: "Ottoberg",
    cantonShort: "TG",
    latitude: 47.59,
    longitude: 9.09,
  },
  {
    zipCode: 8564,
    city: "Wagerswil",
    cantonShort: "TG",
    latitude: 47.6092,
    longitude: 9.0635,
  },
  {
    zipCode: 8570,
    city: "Weinfelden",
    cantonShort: "TG",
    latitude: 47.5698,
    longitude: 9.1122,
  },
  {
    zipCode: 8572,
    city: "Graltshausen",
    cantonShort: "TG",
    latitude: 47.5961,
    longitude: 9.1831,
  },
  {
    zipCode: 8572,
    city: "Andhausen",
    cantonShort: "TG",
    latitude: 47.5772,
    longitude: 9.1793,
  },
  {
    zipCode: 8572,
    city: "Berg TG",
    cantonShort: "TG",
    latitude: 47.5831,
    longitude: 9.1672,
  },
  {
    zipCode: 8572,
    city: "Berg TG",
    cantonShort: "TG",
    latitude: 47.5831,
    longitude: 9.1672,
  },
  {
    zipCode: 8572,
    city: "Guntershausen b. Berg",
    cantonShort: "TG",
    latitude: 47.572,
    longitude: 9.1869,
  },
  {
    zipCode: 8575,
    city: "Istighofen",
    cantonShort: "TG",
    latitude: 47.5393,
    longitude: 9.1431,
  },
  {
    zipCode: 8575,
    city: "B\u00fcrglen TG",
    cantonShort: "TG",
    latitude: 47.5505,
    longitude: 9.1536,
  },
  {
    zipCode: 8576,
    city: "Mauren TG",
    cantonShort: "TG",
    latitude: 47.5658,
    longitude: 9.1541,
  },
  {
    zipCode: 8577,
    city: "Sch\u00f6nholzerswilen",
    cantonShort: "TG",
    latitude: 47.5151,
    longitude: 9.1331,
  },
  {
    zipCode: 8583,
    city: "Sulgen",
    cantonShort: "TG",
    latitude: 47.5409,
    longitude: 9.1865,
  },
  {
    zipCode: 8583,
    city: "Donzhausen",
    cantonShort: "TG",
    latitude: 47.5543,
    longitude: 9.1976,
  },
  {
    zipCode: 8583,
    city: "G\u00f6tighofen",
    cantonShort: "TG",
    latitude: 47.5349,
    longitude: 9.2125,
  },
  {
    zipCode: 8584,
    city: "Opfershofen TG",
    cantonShort: "TG",
    latitude: 47.5618,
    longitude: 9.1735,
  },
  {
    zipCode: 8584,
    city: "Leimbach TG",
    cantonShort: "TG",
    latitude: 47.5624,
    longitude: 9.1901,
  },
  {
    zipCode: 8585,
    city: "Birwinken",
    cantonShort: "TG",
    latitude: 47.5849,
    longitude: 9.1966,
  },
  {
    zipCode: 8585,
    city: "Klarsreuti",
    cantonShort: "TG",
    latitude: 47.5882,
    longitude: 9.2141,
  },
  {
    zipCode: 8585,
    city: "Mattwil",
    cantonShort: "TG",
    latitude: 47.5794,
    longitude: 9.2121,
  },
  {
    zipCode: 8585,
    city: "Happerswil",
    cantonShort: "TG",
    latitude: 47.5799,
    longitude: 9.2272,
  },
  {
    zipCode: 8586,
    city: "Andwil TG",
    cantonShort: "TG",
    latitude: 47.5654,
    longitude: 9.2151,
  },
  {
    zipCode: 8586,
    city: "Erlen",
    cantonShort: "TG",
    latitude: 47.5485,
    longitude: 9.2346,
  },
  {
    zipCode: 8586,
    city: "Buch b. K\u00fcmmertshausen",
    cantonShort: "TG",
    latitude: 47.5716,
    longitude: 9.2353,
  },
  {
    zipCode: 8586,
    city: "Riedt b. Erlen",
    cantonShort: "TG",
    latitude: 47.5428,
    longitude: 9.2141,
  },
  {
    zipCode: 8586,
    city: "Ennetaach",
    cantonShort: "TG",
    latitude: 47.5527,
    longitude: 9.2151,
  },
  {
    zipCode: 8586,
    city: "Engishofen",
    cantonShort: "TG",
    latitude: 47.5572,
    longitude: 9.2507,
  },
  {
    zipCode: 8586,
    city: "Buchackern",
    cantonShort: "TG",
    latitude: 47.5364,
    longitude: 9.2391,
  },
  {
    zipCode: 8586,
    city: "K\u00fcmmertshausen",
    cantonShort: "TG",
    latitude: 47.5659,
    longitude: 9.2466,
  },
  {
    zipCode: 8588,
    city: "Zihlschlacht",
    cantonShort: "TG",
    latitude: 47.521,
    longitude: 9.2614,
  },
  {
    zipCode: 8589,
    city: "Sitterdorf",
    cantonShort: "TG",
    latitude: 47.5071,
    longitude: 9.2488,
  },
  {
    zipCode: 9213,
    city: "Hauptwil",
    cantonShort: "TG",
    latitude: 47.4823,
    longitude: 9.2558,
  },
  {
    zipCode: 9214,
    city: "Kradolf",
    cantonShort: "TG",
    latitude: 47.5265,
    longitude: 9.1984,
  },
  {
    zipCode: 9215,
    city: "Buhwil",
    cantonShort: "TG",
    latitude: 47.5255,
    longitude: 9.166,
  },
  {
    zipCode: 9215,
    city: "Sch\u00f6nenberg an der Thur",
    cantonShort: "TG",
    latitude: 47.5185,
    longitude: 9.1969,
  },
  {
    zipCode: 9216,
    city: "Hohentannen",
    cantonShort: "TG",
    latitude: 47.5088,
    longitude: 9.2224,
  },
  {
    zipCode: 9216,
    city: "Heldswil",
    cantonShort: "TG",
    latitude: 47.5256,
    longitude: 9.2274,
  },
  {
    zipCode: 9217,
    city: "Neukirch an der Thur",
    cantonShort: "TG",
    latitude: 47.5097,
    longitude: 9.1729,
  },
  {
    zipCode: 9220,
    city: "Bischofszell",
    cantonShort: "TG",
    latitude: 47.497,
    longitude: 9.2568,
  },
  {
    zipCode: 9223,
    city: "Schweizersholz",
    cantonShort: "TG",
    latitude: 47.4999,
    longitude: 9.1954,
  },
  {
    zipCode: 9223,
    city: "Halden",
    cantonShort: "TG",
    latitude: 47.5043,
    longitude: 9.2074,
  },
  {
    zipCode: 9225,
    city: "Wilen (Gottshaus)",
    cantonShort: "TG",
    latitude: 47.4896,
    longitude: 9.2777,
  },
  {
    zipCode: 9225,
    city: "St. Pelagiberg",
    cantonShort: "TG",
    latitude: 47.4894,
    longitude: 9.3048,
  },
  {
    zipCode: 9503,
    city: "Lanterswil",
    cantonShort: "TG",
    latitude: 47.5167,
    longitude: 9.0979,
  },
  {
    zipCode: 9503,
    city: "Stehrenberg",
    cantonShort: "TG",
    latitude: 47.5233,
    longitude: 9.09,
  },
  {
    zipCode: 9504,
    city: "Friltschen",
    cantonShort: "TG",
    latitude: 47.5353,
    longitude: 9.0894,
  },
  {
    zipCode: 9514,
    city: "Wuppenau",
    cantonShort: "TG",
    latitude: 47.4972,
    longitude: 9.1058,
  },
  {
    zipCode: 9515,
    city: "Hosenruck",
    cantonShort: "TG",
    latitude: 47.4916,
    longitude: 9.1372,
  },
  {
    zipCode: 9517,
    city: "Mettlen",
    cantonShort: "TG",
    latitude: 47.5341,
    longitude: 9.1195,
  },
  {
    zipCode: 9556,
    city: "Zezikon",
    cantonShort: "TG",
    latitude: 47.5347,
    longitude: 9.0174,
  },
  {
    zipCode: 9556,
    city: "Affeltrangen",
    cantonShort: "TG",
    latitude: 47.5277,
    longitude: 9.0303,
  },
  {
    zipCode: 9562,
    city: "M\u00e4rwil",
    cantonShort: "TG",
    latitude: 47.5302,
    longitude: 9.0694,
  },
  {
    zipCode: 9562,
    city: "Buch b. M\u00e4rwil",
    cantonShort: "TG",
    latitude: 47.537,
    longitude: 9.0554,
  },
  {
    zipCode: 9565,
    city: "Oberbussnang",
    cantonShort: "TG",
    latitude: 47.5439,
    longitude: 9.0922,
  },
  {
    zipCode: 9565,
    city: "Oppikon",
    cantonShort: "TG",
    latitude: 47.5497,
    longitude: 9.0601,
  },
  {
    zipCode: 9565,
    city: "Schmidshof",
    cantonShort: "TG",
    latitude: 47.5448,
    longitude: 9.0549,
  },
  {
    zipCode: 9565,
    city: "Bussnang",
    cantonShort: "TG",
    latitude: 47.5567,
    longitude: 9.0796,
  },
  {
    zipCode: 9565,
    city: "Rothenhausen",
    cantonShort: "TG",
    latitude: 47.5485,
    longitude: 9.1056,
  },
  {
    zipCode: 6500,
    city: "Bellinzona 4",
    cantonShort: "TI",
    latitude: 46.1999,
    longitude: 9.0225,
  },
  {
    zipCode: 6500,
    city: "Bellinzona 5",
    cantonShort: "TI",
    latitude: 46.1999,
    longitude: 9.0225,
  },
  {
    zipCode: 6500,
    city: "Bellinzona VZ",
    cantonShort: "TI",
    latitude: 46.1999,
    longitude: 9.0225,
  },
  {
    zipCode: 6500,
    city: "Bellinzona",
    cantonShort: "TI",
    latitude: 46.1926,
    longitude: 9.0364,
  },
  {
    zipCode: 6500,
    city: "Bellinzona 2",
    cantonShort: "TI",
    latitude: 46.1999,
    longitude: 9.0225,
  },
  {
    zipCode: 6501,
    city: "Bellinzona",
    cantonShort: "TI",
    latitude: 46.1928,
    longitude: 9.017,
  },
  {
    zipCode: 6503,
    city: "Bellinzona",
    cantonShort: "TI",
    latitude: 46.2109,
    longitude: 9.0017,
  },
  {
    zipCode: 6512,
    city: "Giubiasco",
    cantonShort: "TI",
    latitude: 46.1733,
    longitude: 9.0046,
  },
  {
    zipCode: 6513,
    city: "Monte Carasso",
    cantonShort: "TI",
    latitude: 46.2048,
    longitude: 8.9744,
  },
  {
    zipCode: 6514,
    city: "Sementina",
    cantonShort: "TI",
    latitude: 46.1901,
    longitude: 8.9702,
  },
  {
    zipCode: 6515,
    city: "Gudo",
    cantonShort: "TI",
    latitude: 46.1818,
    longitude: 8.9452,
  },
  {
    zipCode: 6517,
    city: "Arbedo",
    cantonShort: "TI",
    latitude: 46.1987,
    longitude: 9.086,
  },
  {
    zipCode: 6518,
    city: "Gorduno",
    cantonShort: "TI",
    latitude: 46.2253,
    longitude: 8.9954,
  },
  {
    zipCode: 6523,
    city: "Preonzo",
    cantonShort: "TI",
    latitude: 46.2489,
    longitude: 8.9667,
  },
  {
    zipCode: 6524,
    city: "Moleno",
    cantonShort: "TI",
    latitude: 46.2662,
    longitude: 8.9684,
  },
  {
    zipCode: 6525,
    city: "Gnosca",
    cantonShort: "TI",
    latitude: 46.2382,
    longitude: 9.0038,
  },
  {
    zipCode: 6528,
    city: "Camorino",
    cantonShort: "TI",
    latitude: 46.1577,
    longitude: 9.0119,
  },
  {
    zipCode: 6532,
    city: "Castione",
    cantonShort: "TI",
    latitude: 46.23,
    longitude: 9.0433,
  },
  {
    zipCode: 6533,
    city: "Lumino",
    cantonShort: "TI",
    latitude: 46.2384,
    longitude: 9.0681,
  },
  {
    zipCode: 6582,
    city: "Pianezzo",
    cantonShort: "TI",
    latitude: 46.1611,
    longitude: 9.0487,
  },
  {
    zipCode: 6583,
    city: "S. Antonio (Val Morobbia)",
    cantonShort: "TI",
    latitude: 46.1683,
    longitude: 9.0699,
  },
  {
    zipCode: 6584,
    city: "Carena",
    cantonShort: "TI",
    latitude: 46.1556,
    longitude: 9.1077,
  },
  {
    zipCode: 6592,
    city: "S. Antonino",
    cantonShort: "TI",
    latitude: 46.1554,
    longitude: 8.973,
  },
  {
    zipCode: 6593,
    city: "Cadenazzo",
    cantonShort: "TI",
    latitude: 46.1507,
    longitude: 8.9495,
  },
  {
    zipCode: 6599,
    city: "Robasacco",
    cantonShort: "TI",
    latitude: 46.1384,
    longitude: 8.9497,
  },
  {
    zipCode: 6702,
    city: "Claro",
    cantonShort: "TI",
    latitude: 46.2659,
    longitude: 9.0372,
  },
  {
    zipCode: 6809,
    city: "Medeglia",
    cantonShort: "TI",
    latitude: 46.1285,
    longitude: 8.9892,
  },
  {
    zipCode: 6810,
    city: "Isone",
    cantonShort: "TI",
    latitude: 46.1335,
    longitude: 9.0144,
  },
  {
    zipCode: 6713,
    city: "Malvaglia",
    cantonShort: "TI",
    latitude: 46.4349,
    longitude: 9.0386,
  },
  {
    zipCode: 6714,
    city: "Semione",
    cantonShort: "TI",
    latitude: 46.4019,
    longitude: 8.954,
  },
  {
    zipCode: 6715,
    city: "Dongio",
    cantonShort: "TI",
    latitude: 46.4525,
    longitude: 8.9717,
  },
  {
    zipCode: 6716,
    city: "Acquarossa",
    cantonShort: "TI",
    latitude: 46.4569,
    longitude: 8.9378,
  },
  {
    zipCode: 6716,
    city: "Leontica",
    cantonShort: "TI",
    latitude: 46.4528,
    longitude: 8.9032,
  },
  {
    zipCode: 6716,
    city: "Lottigna",
    cantonShort: "TI",
    latitude: 46.4637,
    longitude: 8.9555,
  },
  {
    zipCode: 6717,
    city: "Dangio",
    cantonShort: "TI",
    latitude: 46.4955,
    longitude: 8.955,
  },
  {
    zipCode: 6717,
    city: "Torre",
    cantonShort: "TI",
    latitude: 46.4831,
    longitude: 8.9738,
  },
  {
    zipCode: 6718,
    city: "Olivone",
    cantonShort: "TI",
    latitude: 46.5349,
    longitude: 8.8778,
  },
  {
    zipCode: 6718,
    city: "Camperio",
    cantonShort: "TI",
    latitude: 46.5169,
    longitude: 8.9089,
  },
  {
    zipCode: 6719,
    city: "Aquila",
    cantonShort: "TI",
    latitude: 46.5406,
    longitude: 8.9881,
  },
  {
    zipCode: 6719,
    city: "Aquila",
    cantonShort: "TI",
    latitude: 46.5406,
    longitude: 8.9881,
  },
  {
    zipCode: 6719,
    city: "Aquila",
    cantonShort: "TI",
    latitude: 46.5406,
    longitude: 8.9881,
  },
  {
    zipCode: 6720,
    city: "Ghirone",
    cantonShort: "TI",
    latitude: 46.5906,
    longitude: 8.9439,
  },
  {
    zipCode: 6720,
    city: "Campo (Blenio)",
    cantonShort: "TI",
    latitude: 46.5598,
    longitude: 8.8894,
  },
  {
    zipCode: 6721,
    city: "Ludiano",
    cantonShort: "TI",
    latitude: 46.4208,
    longitude: 8.9482,
  },
  {
    zipCode: 6721,
    city: "Motto (Blenio)",
    cantonShort: "TI",
    latitude: 46.4397,
    longitude: 8.9822,
  },
  {
    zipCode: 6722,
    city: "Corzoneso",
    cantonShort: "TI",
    latitude: 46.4364,
    longitude: 8.9256,
  },
  {
    zipCode: 6723,
    city: "Prugiasco",
    cantonShort: "TI",
    latitude: 46.4716,
    longitude: 8.8925,
  },
  {
    zipCode: 6723,
    city: "Castro",
    cantonShort: "TI",
    latitude: 46.4696,
    longitude: 8.9303,
  },
  {
    zipCode: 6723,
    city: "Marolta",
    cantonShort: "TI",
    latitude: 46.4786,
    longitude: 8.9171,
  },
  {
    zipCode: 6724,
    city: "Ponto Valentino",
    cantonShort: "TI",
    latitude: 46.4904,
    longitude: 8.8992,
  },
  {
    zipCode: 6724,
    city: "Largario",
    cantonShort: "TI",
    latitude: 46.4928,
    longitude: 8.9383,
  },
  {
    zipCode: 6742,
    city: "Pollegio",
    cantonShort: "TI",
    latitude: 46.3709,
    longitude: 8.9456,
  },
  {
    zipCode: 6743,
    city: "Bodio TI",
    cantonShort: "TI",
    latitude: 46.386,
    longitude: 8.9205,
  },
  {
    zipCode: 6744,
    city: "Personico",
    cantonShort: "TI",
    latitude: 46.3463,
    longitude: 8.894,
  },
  {
    zipCode: 6745,
    city: "Giornico",
    cantonShort: "TI",
    latitude: 46.3892,
    longitude: 8.8624,
  },
  {
    zipCode: 6746,
    city: "Lavorgo",
    cantonShort: "TI",
    latitude: 46.4426,
    longitude: 8.8409,
  },
  {
    zipCode: 6746,
    city: "Nivo",
    cantonShort: "TI",
    latitude: 46.4364,
    longitude: 8.8426,
  },
  {
    zipCode: 6746,
    city: "Calonico",
    cantonShort: "TI",
    latitude: 46.4536,
    longitude: 8.8462,
  },
  {
    zipCode: 6747,
    city: "Chironico",
    cantonShort: "TI",
    latitude: 46.4233,
    longitude: 8.7932,
  },
  {
    zipCode: 6748,
    city: "Anzonico",
    cantonShort: "TI",
    latitude: 46.4412,
    longitude: 8.8679,
  },
  {
    zipCode: 6749,
    city: "Cavagnago",
    cantonShort: "TI",
    latitude: 46.4188,
    longitude: 8.8887,
  },
  {
    zipCode: 6749,
    city: "Sobrio",
    cantonShort: "TI",
    latitude: 46.403,
    longitude: 8.9069,
  },
  {
    zipCode: 6760,
    city: "Car\u00ec",
    cantonShort: "TI",
    latitude: 46.5036,
    longitude: 8.8264,
  },
  {
    zipCode: 6760,
    city: "Campello",
    cantonShort: "TI",
    latitude: 46.4985,
    longitude: 8.8148,
  },
  {
    zipCode: 6760,
    city: "Faido",
    cantonShort: "TI",
    latitude: 46.4736,
    longitude: 8.8011,
  },
  {
    zipCode: 6760,
    city: "Molare",
    cantonShort: "TI",
    latitude: 46.4941,
    longitude: 8.8447,
  },
  {
    zipCode: 6760,
    city: "Rossura",
    cantonShort: "TI",
    latitude: 46.4741,
    longitude: 8.844,
  },
  {
    zipCode: 6760,
    city: "Calpiogna",
    cantonShort: "TI",
    latitude: 46.485,
    longitude: 8.805,
  },
  {
    zipCode: 6763,
    city: "Mairengo",
    cantonShort: "TI",
    latitude: 46.5032,
    longitude: 8.7969,
  },
  {
    zipCode: 6763,
    city: "Osco",
    cantonShort: "TI",
    latitude: 46.5038,
    longitude: 8.7682,
  },
  {
    zipCode: 6764,
    city: "Chiggiogna",
    cantonShort: "TI",
    latitude: 46.4613,
    longitude: 8.824,
  },
  {
    zipCode: 6764,
    city: "Chiggiogna",
    cantonShort: "TI",
    latitude: 46.4613,
    longitude: 8.824,
  },
  {
    zipCode: 6772,
    city: "Rodi-Fiesso",
    cantonShort: "TI",
    latitude: 46.481,
    longitude: 8.7221,
  },
  {
    zipCode: 6773,
    city: "Prato (Leventina)",
    cantonShort: "TI",
    latitude: 46.4714,
    longitude: 8.7458,
  },
  {
    zipCode: 6774,
    city: "Dalpe",
    cantonShort: "TI",
    latitude: 46.4572,
    longitude: 8.7621,
  },
  {
    zipCode: 6775,
    city: "Ambr\u00ec",
    cantonShort: "TI",
    latitude: 46.4956,
    longitude: 8.6951,
  },
  {
    zipCode: 6776,
    city: "Piotta",
    cantonShort: "TI",
    latitude: 46.5375,
    longitude: 8.7113,
  },
  {
    zipCode: 6777,
    city: "Varenzo",
    cantonShort: "TI",
    latitude: 46.5031,
    longitude: 8.7251,
  },
  {
    zipCode: 6777,
    city: "Quinto",
    cantonShort: "TI",
    latitude: 46.5154,
    longitude: 8.7303,
  },
  {
    zipCode: 6780,
    city: "Madrano",
    cantonShort: "TI",
    latitude: 46.5272,
    longitude: 8.6306,
  },
  {
    zipCode: 6780,
    city: "Airolo",
    cantonShort: "TI",
    latitude: 46.542,
    longitude: 8.5992,
  },
  {
    zipCode: 6781,
    city: "Villa Bedretto",
    cantonShort: "TI",
    latitude: 46.5221,
    longitude: 8.5168,
  },
  {
    zipCode: 6781,
    city: "Bedretto",
    cantonShort: "TI",
    latitude: 46.489,
    longitude: 8.478,
  },
  {
    zipCode: 6516,
    city: "Cugnasco",
    cantonShort: "TI",
    latitude: 46.1955,
    longitude: 8.9135,
  },
  {
    zipCode: 6571,
    city: "Indemini",
    cantonShort: "TI",
    latitude: 46.0954,
    longitude: 8.8421,
  },
  {
    zipCode: 6572,
    city: "Quartino",
    cantonShort: "TI",
    latitude: 46.1521,
    longitude: 8.8971,
  },
  {
    zipCode: 6573,
    city: "Magadino",
    cantonShort: "TI",
    latitude: 46.144,
    longitude: 8.8713,
  },
  {
    zipCode: 6574,
    city: "Vira (Gambarogno)",
    cantonShort: "TI",
    latitude: 46.1307,
    longitude: 8.8563,
  },
  {
    zipCode: 6575,
    city: "Vairano",
    cantonShort: "TI",
    latitude: 46.1206,
    longitude: 8.8157,
  },
  {
    zipCode: 6575,
    city: "S. Nazzaro",
    cantonShort: "TI",
    latitude: 46.1364,
    longitude: 8.8026,
  },
  {
    zipCode: 6576,
    city: "Gerra (Gambarogno)",
    cantonShort: "TI",
    latitude: 46.122,
    longitude: 8.7889,
  },
  {
    zipCode: 6577,
    city: "Ranzo",
    cantonShort: "TI",
    latitude: 46.1152,
    longitude: 8.7762,
  },
  {
    zipCode: 6578,
    city: "Caviano",
    cantonShort: "TI",
    latitude: 46.1061,
    longitude: 8.7703,
  },
  {
    zipCode: 6579,
    city: "Piazzogna",
    cantonShort: "TI",
    latitude: 46.1303,
    longitude: 8.831,
  },
  {
    zipCode: 6594,
    city: "Contone",
    cantonShort: "TI",
    latitude: 46.1522,
    longitude: 8.9232,
  },
  {
    zipCode: 6595,
    city: "Riazzino",
    cantonShort: "TI",
    latitude: 46.1696,
    longitude: 8.8953,
  },
  {
    zipCode: 6596,
    city: "Gordola",
    cantonShort: "TI",
    latitude: 46.175,
    longitude: 8.8649,
  },
  {
    zipCode: 6597,
    city: "Agarone",
    cantonShort: "TI",
    latitude: 46.1799,
    longitude: 8.908,
  },
  {
    zipCode: 6598,
    city: "Tenero",
    cantonShort: "TI",
    latitude: 46.1713,
    longitude: 8.8461,
  },
  {
    zipCode: 6600,
    city: "Muralto",
    cantonShort: "TI",
    latitude: 46.1729,
    longitude: 8.8043,
  },
  {
    zipCode: 6600,
    city: "Locarno",
    cantonShort: "TI",
    latitude: 46.1608,
    longitude: 8.803,
  },
  {
    zipCode: 6600,
    city: "Solduno",
    cantonShort: "TI",
    latitude: 46.1744,
    longitude: 8.7712,
  },
  {
    zipCode: 6600,
    city: "Locarno",
    cantonShort: "TI",
    latitude: 46.1608,
    longitude: 8.803,
  },
  {
    zipCode: 6601,
    city: "Locarno",
    cantonShort: "TI",
    latitude: 46.1709,
    longitude: 8.7995,
  },
  {
    zipCode: 6602,
    city: "Muralto",
    cantonShort: "TI",
    latitude: 46.1732,
    longitude: 8.8022,
  },
  {
    zipCode: 6604,
    city: "Locarno",
    cantonShort: "TI",
    latitude: 46.1709,
    longitude: 8.7995,
  },
  {
    zipCode: 6605,
    city: "Locarno",
    cantonShort: "TI",
    latitude: 46.1847,
    longitude: 8.7788,
  },
  {
    zipCode: 6611,
    city: "Crana",
    cantonShort: "TI",
    latitude: 46.1963,
    longitude: 8.6028,
  },
  {
    zipCode: 6611,
    city: "Mosogno",
    cantonShort: "TI",
    latitude: 46.196,
    longitude: 8.6351,
  },
  {
    zipCode: 6611,
    city: "Gresso",
    cantonShort: "TI",
    latitude: 46.2394,
    longitude: 8.614,
  },
  {
    zipCode: 6612,
    city: "Ascona",
    cantonShort: "TI",
    latitude: 46.1477,
    longitude: 8.772,
  },
  {
    zipCode: 6613,
    city: "Porto Ronco",
    cantonShort: "TI",
    latitude: 46.1353,
    longitude: 8.736,
  },
  {
    zipCode: 6614,
    city: "Brissago",
    cantonShort: "TI",
    latitude: 46.1237,
    longitude: 8.6936,
  },
  {
    zipCode: 6614,
    city: "Isole di Brissago",
    cantonShort: "TI",
    latitude: 46.133,
    longitude: 8.7354,
  },
  {
    zipCode: 6616,
    city: "Losone",
    cantonShort: "TI",
    latitude: 46.1692,
    longitude: 8.742,
  },
  {
    zipCode: 6618,
    city: "Arcegno",
    cantonShort: "TI",
    latitude: 46.1605,
    longitude: 8.7403,
  },
  {
    zipCode: 6622,
    city: "Ronco sopra Ascona",
    cantonShort: "TI",
    latitude: 46.1478,
    longitude: 8.7196,
  },
  {
    zipCode: 6631,
    city: "Corippo",
    cantonShort: "TI",
    latitude: 46.2357,
    longitude: 8.8239,
  },
  {
    zipCode: 6632,
    city: "Vogorno",
    cantonShort: "TI",
    latitude: 46.2246,
    longitude: 8.8845,
  },
  {
    zipCode: 6633,
    city: "Lavertezzo",
    cantonShort: "TI",
    latitude: 46.2753,
    longitude: 8.8632,
  },
  {
    zipCode: 6634,
    city: "Brione (Verzasca)",
    cantonShort: "TI",
    latitude: 46.3017,
    longitude: 8.7577,
  },
  {
    zipCode: 6635,
    city: "Gerra (Verzasca)",
    cantonShort: "TI",
    latitude: 46.3175,
    longitude: 8.8065,
  },
  {
    zipCode: 6636,
    city: "Frasco",
    cantonShort: "TI",
    latitude: 46.3542,
    longitude: 8.8188,
  },
  {
    zipCode: 6637,
    city: "Sonogno",
    cantonShort: "TI",
    latitude: 46.3648,
    longitude: 8.7574,
  },
  {
    zipCode: 6644,
    city: "Orselina",
    cantonShort: "TI",
    latitude: 46.183,
    longitude: 8.7953,
  },
  {
    zipCode: 6645,
    city: "Brione sopra Minusio",
    cantonShort: "TI",
    latitude: 46.1944,
    longitude: 8.8107,
  },
  {
    zipCode: 6646,
    city: "Contra",
    cantonShort: "TI",
    latitude: 46.1922,
    longitude: 8.8385,
  },
  {
    zipCode: 6647,
    city: "Mergoscia",
    cantonShort: "TI",
    latitude: 46.2143,
    longitude: 8.8265,
  },
  {
    zipCode: 6648,
    city: "Minusio",
    cantonShort: "TI",
    latitude: 46.1733,
    longitude: 8.8244,
  },
  {
    zipCode: 6652,
    city: "Tegna",
    cantonShort: "TI",
    latitude: 46.1908,
    longitude: 8.7458,
  },
  {
    zipCode: 6653,
    city: "Verscio",
    cantonShort: "TI",
    latitude: 46.1931,
    longitude: 8.728,
  },
  {
    zipCode: 6654,
    city: "Cavigliano",
    cantonShort: "TI",
    latitude: 46.1905,
    longitude: 8.7089,
  },
  {
    zipCode: 6655,
    city: "Verdasio",
    cantonShort: "TI",
    latitude: 46.1725,
    longitude: 8.6443,
  },
  {
    zipCode: 6655,
    city: "Rasa",
    cantonShort: "TI",
    latitude: 46.157,
    longitude: 8.6566,
  },
  {
    zipCode: 6655,
    city: "Intragna",
    cantonShort: "TI",
    latitude: 46.1691,
    longitude: 8.6867,
  },
  {
    zipCode: 6656,
    city: "Golino",
    cantonShort: "TI",
    latitude: 46.1785,
    longitude: 8.7132,
  },
  {
    zipCode: 6657,
    city: "Palagnedra",
    cantonShort: "TI",
    latitude: 46.1422,
    longitude: 8.6463,
  },
  {
    zipCode: 6658,
    city: "Borgnone",
    cantonShort: "TI",
    latitude: 46.1704,
    longitude: 8.6008,
  },
  {
    zipCode: 6659,
    city: "Camedo",
    cantonShort: "TI",
    latitude: 46.1554,
    longitude: 8.6098,
  },
  {
    zipCode: 6659,
    city: "Moneto",
    cantonShort: "TI",
    latitude: 46.138,
    longitude: 8.6183,
  },
  {
    zipCode: 6661,
    city: "Auressio",
    cantonShort: "TI",
    latitude: 46.2043,
    longitude: 8.6925,
  },
  {
    zipCode: 6661,
    city: "Berzona",
    cantonShort: "TI",
    latitude: 46.2082,
    longitude: 8.6566,
  },
  {
    zipCode: 6661,
    city: "Loco",
    cantonShort: "TI",
    latitude: 46.2114,
    longitude: 8.6755,
  },
  {
    zipCode: 6662,
    city: "Russo",
    cantonShort: "TI",
    latitude: 46.2164,
    longitude: 8.6265,
  },
  {
    zipCode: 6663,
    city: "Spruga",
    cantonShort: "TI",
    latitude: 46.2034,
    longitude: 8.5554,
  },
  {
    zipCode: 6663,
    city: "Comologno",
    cantonShort: "TI",
    latitude: 46.2056,
    longitude: 8.5797,
  },
  {
    zipCode: 6664,
    city: "Vergeletto",
    cantonShort: "TI",
    latitude: 46.241,
    longitude: 8.5425,
  },
  {
    zipCode: 6802,
    city: "Rivera",
    cantonShort: "TI",
    latitude: 46.1239,
    longitude: 8.9045,
  },
  {
    zipCode: 6803,
    city: "Camignolo",
    cantonShort: "TI",
    latitude: 46.105,
    longitude: 8.9506,
  },
  {
    zipCode: 6804,
    city: "Bironico",
    cantonShort: "TI",
    latitude: 46.1259,
    longitude: 8.9332,
  },
  {
    zipCode: 6805,
    city: "Mezzovico",
    cantonShort: "TI",
    latitude: 46.096,
    longitude: 8.9082,
  },
  {
    zipCode: 6805,
    city: "Mezzovico",
    cantonShort: "TI",
    latitude: 46.096,
    longitude: 8.9082,
  },
  {
    zipCode: 6806,
    city: "Sigirino",
    cantonShort: "TI",
    latitude: 46.0852,
    longitude: 8.9,
  },
  {
    zipCode: 6807,
    city: "Taverne",
    cantonShort: "TI",
    latitude: 46.063,
    longitude: 8.9306,
  },
  {
    zipCode: 6807,
    city: "Taverne",
    cantonShort: "TI",
    latitude: 46.063,
    longitude: 8.9306,
  },
  {
    zipCode: 6808,
    city: "Torricella",
    cantonShort: "TI",
    latitude: 46.07,
    longitude: 8.9064,
  },
  {
    zipCode: 6809,
    city: "Medeglia",
    cantonShort: "TI",
    latitude: 46.1285,
    longitude: 8.9892,
  },
  {
    zipCode: 6814,
    city: "Lamone-Cadempino",
    cantonShort: "TI",
    latitude: 46.0342,
    longitude: 8.9339,
  },
  {
    zipCode: 6814,
    city: "Cadempino",
    cantonShort: "TI",
    latitude: 46.0339,
    longitude: 8.9338,
  },
  {
    zipCode: 6814,
    city: "Lamone",
    cantonShort: "TI",
    latitude: 46.0451,
    longitude: 8.9315,
  },
  {
    zipCode: 6815,
    city: "Melide",
    cantonShort: "TI",
    latitude: 45.9564,
    longitude: 8.9488,
  },
  {
    zipCode: 6816,
    city: "Bissone",
    cantonShort: "TI",
    latitude: 45.951,
    longitude: 8.9655,
  },
  {
    zipCode: 6817,
    city: "Maroggia",
    cantonShort: "TI",
    latitude: 45.9365,
    longitude: 8.9711,
  },
  {
    zipCode: 6818,
    city: "Melano",
    cantonShort: "TI",
    latitude: 45.9209,
    longitude: 8.9911,
  },
  {
    zipCode: 6821,
    city: "Rovio",
    cantonShort: "TI",
    latitude: 45.9356,
    longitude: 9.0003,
  },
  {
    zipCode: 6822,
    city: "Arogno",
    cantonShort: "TI",
    latitude: 45.9571,
    longitude: 8.9929,
  },
  {
    zipCode: 6823,
    city: "Pugerna",
    cantonShort: "TI",
    latitude: 45.9902,
    longitude: 8.9987,
  },
  {
    zipCode: 6825,
    city: "Capolago",
    cantonShort: "TI",
    latitude: 45.9043,
    longitude: 8.9845,
  },
  {
    zipCode: 6827,
    city: "Brusino Arsizio",
    cantonShort: "TI",
    latitude: 45.9254,
    longitude: 8.9376,
  },
  {
    zipCode: 6867,
    city: "Serpiano",
    cantonShort: "TI",
    latitude: 45.9163,
    longitude: 8.9419,
  },
  {
    zipCode: 6900,
    city: "Lugano",
    cantonShort: "TI",
    latitude: 46.0054,
    longitude: 8.9545,
  },
  {
    zipCode: 6900,
    city: "Paradiso",
    cantonShort: "TI",
    latitude: 45.9891,
    longitude: 8.955,
  },
  {
    zipCode: 6900,
    city: "Massagno",
    cantonShort: "TI",
    latitude: 46.0126,
    longitude: 8.9437,
  },
  {
    zipCode: 6901,
    city: "Lugano",
    cantonShort: "TI",
    latitude: 46.0101,
    longitude: 8.96,
  },
  {
    zipCode: 6902,
    city: "Lugano 2 Paradiso Caselle",
    cantonShort: "TI",
    latitude: 45.988,
    longitude: 8.9483,
  },
  {
    zipCode: 6902,
    city: "Lugano 2 Caselle",
    cantonShort: "TI",
    latitude: 45.988,
    longitude: 8.9483,
  },
  {
    zipCode: 6903,
    city: "Lugano",
    cantonShort: "TI",
    latitude: 46.0101,
    longitude: 8.96,
  },
  {
    zipCode: 6904,
    city: "Lugano 4 Caselle",
    cantonShort: "TI",
    latitude: 46.0055,
    longitude: 8.9714,
  },
  {
    zipCode: 6904,
    city: "Lugano 4 Molino Nuovo Casel",
    cantonShort: "TI",
    latitude: 46.0055,
    longitude: 8.9714,
  },
  {
    zipCode: 6906,
    city: "Lugano 6 Cassarate Caselle",
    cantonShort: "TI",
    latitude: 46.0055,
    longitude: 8.9714,
  },
  {
    zipCode: 6906,
    city: "Lugano 6 Caselle",
    cantonShort: "TI",
    latitude: 46.0055,
    longitude: 8.9714,
  },
  {
    zipCode: 6907,
    city: "Lugano 7 Caselle",
    cantonShort: "TI",
    latitude: 46.0055,
    longitude: 8.9714,
  },
  {
    zipCode: 6908,
    city: "Massagno Caselle",
    cantonShort: "TI",
    latitude: 46.0126,
    longitude: 8.9437,
  },
  {
    zipCode: 6912,
    city: "Pazzallo",
    cantonShort: "TI",
    latitude: 45.981,
    longitude: 8.9473,
  },
  {
    zipCode: 6913,
    city: "Carabbia",
    cantonShort: "TI",
    latitude: 45.9704,
    longitude: 8.9344,
  },
  {
    zipCode: 6914,
    city: "Carona",
    cantonShort: "TI",
    latitude: 45.9567,
    longitude: 8.9322,
  },
  {
    zipCode: 6915,
    city: "Pambio-Noranco",
    cantonShort: "TI",
    latitude: 45.9855,
    longitude: 8.9307,
  },
  {
    zipCode: 6916,
    city: "Grancia",
    cantonShort: "TI",
    latitude: 45.9684,
    longitude: 8.9276,
  },
  {
    zipCode: 6917,
    city: "Barbengo",
    cantonShort: "TI",
    latitude: 45.9604,
    longitude: 8.916,
  },
  {
    zipCode: 6918,
    city: "Figino",
    cantonShort: "TI",
    latitude: 45.9557,
    longitude: 8.9077,
  },
  {
    zipCode: 6919,
    city: "Carabietta",
    cantonShort: "TI",
    latitude: 45.9687,
    longitude: 8.8996,
  },
  {
    zipCode: 6921,
    city: "Vico Morcote",
    cantonShort: "TI",
    latitude: 45.9347,
    longitude: 8.9252,
  },
  {
    zipCode: 6922,
    city: "Morcote",
    cantonShort: "TI",
    latitude: 45.9324,
    longitude: 8.9082,
  },
  {
    zipCode: 6924,
    city: "Sorengo",
    cantonShort: "TI",
    latitude: 45.9998,
    longitude: 8.9355,
  },
  {
    zipCode: 6925,
    city: "Gentilino",
    cantonShort: "TI",
    latitude: 45.9917,
    longitude: 8.9261,
  },
  {
    zipCode: 6926,
    city: "Montagnola",
    cantonShort: "TI",
    latitude: 45.9794,
    longitude: 8.9143,
  },
  {
    zipCode: 6927,
    city: "Agra",
    cantonShort: "TI",
    latitude: 45.9682,
    longitude: 8.9116,
  },
  {
    zipCode: 6928,
    city: "Manno",
    cantonShort: "TI",
    latitude: 46.0308,
    longitude: 8.9194,
  },
  {
    zipCode: 6929,
    city: "Gravesano",
    cantonShort: "TI",
    latitude: 46.0421,
    longitude: 8.9187,
  },
  {
    zipCode: 6930,
    city: "Bedano",
    cantonShort: "TI",
    latitude: 46.0531,
    longitude: 8.9181,
  },
  {
    zipCode: 6932,
    city: "Breganzona",
    cantonShort: "TI",
    latitude: 46.009,
    longitude: 8.9274,
  },
  {
    zipCode: 6933,
    city: "Muzzano",
    cantonShort: "TI",
    latitude: 45.9971,
    longitude: 8.917,
  },
  {
    zipCode: 6934,
    city: "Bioggio",
    cantonShort: "TI",
    latitude: 46.0151,
    longitude: 8.9127,
  },
  {
    zipCode: 6935,
    city: "Bosco Luganese",
    cantonShort: "TI",
    latitude: 46.0301,
    longitude: 8.9055,
  },
  {
    zipCode: 6936,
    city: "Cademario",
    cantonShort: "TI",
    latitude: 46.0226,
    longitude: 8.8918,
  },
  {
    zipCode: 6937,
    city: "Breno",
    cantonShort: "TI",
    latitude: 46.0436,
    longitude: 8.8587,
  },
  {
    zipCode: 6938,
    city: "Vezio",
    cantonShort: "TI",
    latitude: 46.0605,
    longitude: 8.8711,
  },
  {
    zipCode: 6938,
    city: "Fescoggia",
    cantonShort: "TI",
    latitude: 46.0496,
    longitude: 8.8711,
  },
  {
    zipCode: 6939,
    city: "Arosio-Mugena",
    cantonShort: "TI",
    latitude: 46.0542,
    longitude: 8.8764,
  },
  {
    zipCode: 6939,
    city: "Arosio",
    cantonShort: "TI",
    latitude: 46.0598,
    longitude: 8.8935,
  },
  {
    zipCode: 6939,
    city: "Mugena",
    cantonShort: "TI",
    latitude: 46.0566,
    longitude: 8.8821,
  },
  {
    zipCode: 6942,
    city: "Savosa",
    cantonShort: "TI",
    latitude: 46.0193,
    longitude: 8.9447,
  },
  {
    zipCode: 6943,
    city: "Vezia",
    cantonShort: "TI",
    latitude: 46.0248,
    longitude: 8.935,
  },
  {
    zipCode: 6944,
    city: "Cureglia",
    cantonShort: "TI",
    latitude: 46.0375,
    longitude: 8.9441,
  },
  {
    zipCode: 6945,
    city: "Origlio",
    cantonShort: "TI",
    latitude: 46.0514,
    longitude: 8.9448,
  },
  {
    zipCode: 6946,
    city: "Ponte Capriasca",
    cantonShort: "TI",
    latitude: 46.106,
    longitude: 9.0219,
  },
  {
    zipCode: 6946,
    city: "Ponte Capriasca",
    cantonShort: "TI",
    latitude: 46.106,
    longitude: 9.0219,
  },
  {
    zipCode: 6947,
    city: "Vaglio",
    cantonShort: "TI",
    latitude: 46.0557,
    longitude: 8.96,
  },
  {
    zipCode: 6948,
    city: "Porza",
    cantonShort: "TI",
    latitude: 46.027,
    longitude: 8.9532,
  },
  {
    zipCode: 6949,
    city: "Comano",
    cantonShort: "TI",
    latitude: 46.04,
    longitude: 8.9567,
  },
  {
    zipCode: 6950,
    city: "Tesserete",
    cantonShort: "TI",
    latitude: 46.0699,
    longitude: 8.9682,
  },
  {
    zipCode: 6951,
    city: "Scareglia",
    cantonShort: "TI",
    latitude: 46.0975,
    longitude: 9.036,
  },
  {
    zipCode: 6951,
    city: "Colla",
    cantonShort: "TI",
    latitude: 46.1057,
    longitude: 9.0627,
  },
  {
    zipCode: 6951,
    city: "Bogno",
    cantonShort: "TI",
    latitude: 46.0911,
    longitude: 9.075,
  },
  {
    zipCode: 6951,
    city: "Insone",
    cantonShort: "TI",
    latitude: 46.0901,
    longitude: 9.029,
  },
  {
    zipCode: 6951,
    city: "Sign\u00f4ra",
    cantonShort: "TI",
    latitude: 46.1035,
    longitude: 9.0447,
  },
  {
    zipCode: 6951,
    city: "Cozzo",
    cantonShort: "TI",
    latitude: 46.0972,
    longitude: 9.0619,
  },
  {
    zipCode: 6952,
    city: "Canobbio",
    cantonShort: "TI",
    latitude: 46.0365,
    longitude: 8.9676,
  },
  {
    zipCode: 6953,
    city: "Lugaggia",
    cantonShort: "TI",
    latitude: 46.0562,
    longitude: 8.9707,
  },
  {
    zipCode: 6954,
    city: "Sala Capriasca",
    cantonShort: "TI",
    latitude: 46.0663,
    longitude: 8.9552,
  },
  {
    zipCode: 6954,
    city: "Bigorio",
    cantonShort: "TI",
    latitude: 46.0801,
    longitude: 8.9488,
  },
  {
    zipCode: 6955,
    city: "Oggio",
    cantonShort: "TI",
    latitude: 46.0715,
    longitude: 8.9871,
  },
  {
    zipCode: 6955,
    city: "Cagiallo",
    cantonShort: "TI",
    latitude: 46.0654,
    longitude: 8.9752,
  },
  {
    zipCode: 6955,
    city: "Oggio",
    cantonShort: "TI",
    latitude: 46.0715,
    longitude: 8.9871,
  },
  {
    zipCode: 6956,
    city: "Lopagno",
    cantonShort: "TI",
    latitude: 46.0699,
    longitude: 8.978,
  },
  {
    zipCode: 6957,
    city: "Roveredo TI",
    cantonShort: "TI",
    latitude: 46.0746,
    longitude: 8.9827,
  },
  {
    zipCode: 6958,
    city: "Corticiasca",
    cantonShort: "TI",
    latitude: 46.0901,
    longitude: 9.0181,
  },
  {
    zipCode: 6958,
    city: "Bidogno",
    cantonShort: "TI",
    latitude: 46.0892,
    longitude: 9.0062,
  },
  {
    zipCode: 6958,
    city: "Corticiasca",
    cantonShort: "TI",
    latitude: 46.0901,
    longitude: 9.0181,
  },
  {
    zipCode: 6959,
    city: "Maglio di Colla",
    cantonShort: "TI",
    latitude: 46.0869,
    longitude: 9.0421,
  },
  {
    zipCode: 6959,
    city: "Cimadera",
    cantonShort: "TI",
    latitude: 46.0718,
    longitude: 9.052,
  },
  {
    zipCode: 6959,
    city: "Curtina",
    cantonShort: "TI",
    latitude: 46.0821,
    longitude: 9.0217,
  },
  {
    zipCode: 6959,
    city: "Certara",
    cantonShort: "TI",
    latitude: 46.0791,
    longitude: 9.0668,
  },
  {
    zipCode: 6959,
    city: "Piandera Paese",
    cantonShort: "TI",
    latitude: 46.0832,
    longitude: 9.0427,
  },
  {
    zipCode: 6959,
    city: "Piandera Paese",
    cantonShort: "TI",
    latitude: 46.0832,
    longitude: 9.0427,
  },
  {
    zipCode: 6960,
    city: "Odogno",
    cantonShort: "TI",
    latitude: 46.1026,
    longitude: 8.9923,
  },
  {
    zipCode: 6962,
    city: "Viganello",
    cantonShort: "TI",
    latitude: 46.0129,
    longitude: 8.9706,
  },
  {
    zipCode: 6963,
    city: "Cureggia",
    cantonShort: "TI",
    latitude: 46.0198,
    longitude: 8.9858,
  },
  {
    zipCode: 6963,
    city: "Pregassona",
    cantonShort: "TI",
    latitude: 46.0228,
    longitude: 8.9753,
  },
  {
    zipCode: 6964,
    city: "Davesco-Soragno",
    cantonShort: "TI",
    latitude: 46.0325,
    longitude: 8.9862,
  },
  {
    zipCode: 6965,
    city: "Cadro",
    cantonShort: "TI",
    latitude: 46.0423,
    longitude: 8.9945,
  },
  {
    zipCode: 6966,
    city: "Villa Luganese",
    cantonShort: "TI",
    latitude: 46.0521,
    longitude: 9.0053,
  },
  {
    zipCode: 6967,
    city: "Dino",
    cantonShort: "TI",
    latitude: 46.054,
    longitude: 8.9802,
  },
  {
    zipCode: 6968,
    city: "Sonvico",
    cantonShort: "TI",
    latitude: 46.0651,
    longitude: 9.0144,
  },
  {
    zipCode: 6974,
    city: "Aldesago",
    cantonShort: "TI",
    latitude: 46.0094,
    longitude: 8.9798,
  },
  {
    zipCode: 6976,
    city: "Castagnola",
    cantonShort: "TI",
    latitude: 45.9991,
    longitude: 8.9815,
  },
  {
    zipCode: 6977,
    city: "Ruvigliana",
    cantonShort: "TI",
    latitude: 46.0053,
    longitude: 8.98,
  },
  {
    zipCode: 6978,
    city: "Gandria",
    cantonShort: "TI",
    latitude: 46.0093,
    longitude: 9.0099,
  },
  {
    zipCode: 6979,
    city: "Br\u00e8 sopra Lugano",
    cantonShort: "TI",
    latitude: 46.0174,
    longitude: 8.9989,
  },
  {
    zipCode: 6980,
    city: "Castelrotto",
    cantonShort: "TI",
    latitude: 45.9954,
    longitude: 8.839,
  },
  {
    zipCode: 6981,
    city: "Beride di Bedigliora",
    cantonShort: "TI",
    latitude: 46.0033,
    longitude: 8.8327,
  },
  {
    zipCode: 6981,
    city: "Biogno-Beride",
    cantonShort: "TI",
    latitude: 45.9884,
    longitude: 8.8423,
  },
  {
    zipCode: 6981,
    city: "Bedigliora",
    cantonShort: "TI",
    latitude: 46.0017,
    longitude: 8.8455,
  },
  {
    zipCode: 6981,
    city: "Bedigliora",
    cantonShort: "TI",
    latitude: 46.0017,
    longitude: 8.8455,
  },
  {
    zipCode: 6981,
    city: "Bombinasco",
    cantonShort: "TI",
    latitude: 46.0162,
    longitude: 8.8328,
  },
  {
    zipCode: 6981,
    city: "Banco",
    cantonShort: "TI",
    latitude: 46.01,
    longitude: 8.8402,
  },
  {
    zipCode: 6982,
    city: "Agno",
    cantonShort: "TI",
    latitude: 46.0005,
    longitude: 8.9028,
  },
  {
    zipCode: 6983,
    city: "Magliaso",
    cantonShort: "TI",
    latitude: 45.982,
    longitude: 8.8914,
  },
  {
    zipCode: 6984,
    city: "Pura",
    cantonShort: "TI",
    latitude: 45.9833,
    longitude: 8.8652,
  },
  {
    zipCode: 6986,
    city: "Curio",
    cantonShort: "TI",
    latitude: 46.0008,
    longitude: 8.8622,
  },
  {
    zipCode: 6986,
    city: "Miglieglia",
    cantonShort: "TI",
    latitude: 46.0313,
    longitude: 8.8494,
  },
  {
    zipCode: 6986,
    city: "Novaggio",
    cantonShort: "TI",
    latitude: 46.0185,
    longitude: 8.8449,
  },
  {
    zipCode: 6987,
    city: "Caslano",
    cantonShort: "TI",
    latitude: 45.9681,
    longitude: 8.8804,
  },
  {
    zipCode: 6988,
    city: "Ponte Tresa",
    cantonShort: "TI",
    latitude: 45.9707,
    longitude: 8.8593,
  },
  {
    zipCode: 6989,
    city: "Purasca",
    cantonShort: "TI",
    latitude: 45.9814,
    longitude: 8.8493,
  },
  {
    zipCode: 6990,
    city: "Cassina d'Agno",
    cantonShort: "TI",
    latitude: 45.9914,
    longitude: 8.8933,
  },
  {
    zipCode: 6991,
    city: "Neggio",
    cantonShort: "TI",
    latitude: 45.9895,
    longitude: 8.8779,
  },
  {
    zipCode: 6992,
    city: "Cimo",
    cantonShort: "TI",
    latitude: 46.0053,
    longitude: 8.8908,
  },
  {
    zipCode: 6992,
    city: "Vernate",
    cantonShort: "TI",
    latitude: 45.9958,
    longitude: 8.8803,
  },
  {
    zipCode: 6993,
    city: "Iseo",
    cantonShort: "TI",
    latitude: 46.0053,
    longitude: 8.8786,
  },
  {
    zipCode: 6994,
    city: "Aranno",
    cantonShort: "TI",
    latitude: 46.0185,
    longitude: 8.874,
  },
  {
    zipCode: 6995,
    city: "Madonna del Piano",
    cantonShort: "TI",
    latitude: 45.9891,
    longitude: 8.8321,
  },
  {
    zipCode: 6995,
    city: "Molinazzo di Monteggio",
    cantonShort: "TI",
    latitude: 45.9924,
    longitude: 8.8194,
  },
  {
    zipCode: 6997,
    city: "Sessa",
    cantonShort: "TI",
    latitude: 46.0032,
    longitude: 8.8156,
  },
  {
    zipCode: 6998,
    city: "Termine",
    cantonShort: "TI",
    latitude: 45.9998,
    longitude: 8.7921,
  },
  {
    zipCode: 6998,
    city: "Monteggio",
    cantonShort: "TI",
    latitude: 45.9961,
    longitude: 8.8036,
  },
  {
    zipCode: 6998,
    city: "Monteggio 2",
    cantonShort: "TI",
    latitude: 45.982,
    longitude: 8.8514,
  },
  {
    zipCode: 6999,
    city: "Astano",
    cantonShort: "TI",
    latitude: 46.0153,
    longitude: 8.8163,
  },
  {
    zipCode: 6825,
    city: "Capolago",
    cantonShort: "TI",
    latitude: 45.9043,
    longitude: 8.9845,
  },
  {
    zipCode: 6826,
    city: "Riva San Vitale",
    cantonShort: "TI",
    latitude: 45.9129,
    longitude: 8.9645,
  },
  {
    zipCode: 6828,
    city: "Balerna",
    cantonShort: "TI",
    latitude: 45.8432,
    longitude: 9.0077,
  },
  {
    zipCode: 6830,
    city: "Chiasso 3",
    cantonShort: "TI",
    latitude: 45.8288,
    longitude: 9.0166,
  },
  {
    zipCode: 6830,
    city: "Chiasso",
    cantonShort: "TI",
    latitude: 45.8338,
    longitude: 9.0256,
  },
  {
    zipCode: 6830,
    city: "Chiasso 1",
    cantonShort: "TI",
    latitude: 45.8288,
    longitude: 9.0166,
  },
  {
    zipCode: 6832,
    city: "Seseglio",
    cantonShort: "TI",
    latitude: 45.8283,
    longitude: 8.9998,
  },
  {
    zipCode: 6832,
    city: "Pedrinate",
    cantonShort: "TI",
    latitude: 45.8251,
    longitude: 9.0158,
  },
  {
    zipCode: 6833,
    city: "Vacallo",
    cantonShort: "TI",
    latitude: 45.847,
    longitude: 9.0364,
  },
  {
    zipCode: 6834,
    city: "Morbio Inferiore",
    cantonShort: "TI",
    latitude: 45.8497,
    longitude: 9.0215,
  },
  {
    zipCode: 6835,
    city: "Morbio Superiore",
    cantonShort: "TI",
    latitude: 45.867,
    longitude: 9.0397,
  },
  {
    zipCode: 6836,
    city: "Serfontana",
    cantonShort: "TI",
    latitude: 45.8497,
    longitude: 9.0216,
  },
  {
    zipCode: 6837,
    city: "Bruzella",
    cantonShort: "TI",
    latitude: 45.8871,
    longitude: 9.0568,
  },
  {
    zipCode: 6837,
    city: "Caneggio",
    cantonShort: "TI",
    latitude: 45.8764,
    longitude: 9.0479,
  },
  {
    zipCode: 6838,
    city: "Scudellate",
    cantonShort: "TI",
    latitude: 45.9229,
    longitude: 9.0415,
  },
  {
    zipCode: 6838,
    city: "Muggio",
    cantonShort: "TI",
    latitude: 45.9115,
    longitude: 9.0354,
  },
  {
    zipCode: 6838,
    city: "Cabbio",
    cantonShort: "TI",
    latitude: 45.9009,
    longitude: 9.0604,
  },
  {
    zipCode: 6839,
    city: "Sagno",
    cantonShort: "TI",
    latitude: 45.8612,
    longitude: 9.0446,
  },
  {
    zipCode: 6850,
    city: "Mendrisio",
    cantonShort: "TI",
    latitude: 45.8748,
    longitude: 8.9811,
  },
  {
    zipCode: 6850,
    city: "Mendrisio Borgo",
    cantonShort: "TI",
    latitude: 45.8862,
    longitude: 8.9929,
  },
  {
    zipCode: 6850,
    city: "Mendrisio Stazione",
    cantonShort: "TI",
    latitude: 45.8862,
    longitude: 8.9929,
  },
  {
    zipCode: 6852,
    city: "Genestrerio",
    cantonShort: "TI",
    latitude: 45.8501,
    longitude: 8.9573,
  },
  {
    zipCode: 6853,
    city: "Ligornetto",
    cantonShort: "TI",
    latitude: 45.8631,
    longitude: 8.9546,
  },
  {
    zipCode: 6854,
    city: "S. Pietro",
    cantonShort: "TI",
    latitude: 45.8579,
    longitude: 8.9354,
  },
  {
    zipCode: 6855,
    city: "Stabio",
    cantonShort: "TI",
    latitude: 45.8456,
    longitude: 8.9324,
  },
  {
    zipCode: 6862,
    city: "Rancate",
    cantonShort: "TI",
    latitude: 45.8755,
    longitude: 8.9705,
  },
  {
    zipCode: 6863,
    city: "Besazio",
    cantonShort: "TI",
    latitude: 45.8714,
    longitude: 8.9539,
  },
  {
    zipCode: 6864,
    city: "Arzo",
    cantonShort: "TI",
    latitude: 45.88,
    longitude: 8.9399,
  },
  {
    zipCode: 6865,
    city: "Tremona",
    cantonShort: "TI",
    latitude: 45.8808,
    longitude: 8.959,
  },
  {
    zipCode: 6866,
    city: "Meride",
    cantonShort: "TI",
    latitude: 45.8992,
    longitude: 8.9429,
  },
  {
    zipCode: 6872,
    city: "Somazzo",
    cantonShort: "TI",
    latitude: 45.9017,
    longitude: 9.0061,
  },
  {
    zipCode: 6872,
    city: "Salorino",
    cantonShort: "TI",
    latitude: 45.8873,
    longitude: 9.003,
  },
  {
    zipCode: 6873,
    city: "Corteglia",
    cantonShort: "TI",
    latitude: 45.8621,
    longitude: 8.9934,
  },
  {
    zipCode: 6874,
    city: "Castel San Pietro",
    cantonShort: "TI",
    latitude: 45.8654,
    longitude: 9.0085,
  },
  {
    zipCode: 6875,
    city: "Monte",
    cantonShort: "TI",
    latitude: 45.8831,
    longitude: 9.0225,
  },
  {
    zipCode: 6875,
    city: "Casima",
    cantonShort: "TI",
    latitude: 45.893,
    longitude: 9.0354,
  },
  {
    zipCode: 6875,
    city: "Campora",
    cantonShort: "TI",
    latitude: 45.8745,
    longitude: 9.0187,
  },
  {
    zipCode: 6877,
    city: "Coldrerio",
    cantonShort: "TI",
    latitude: 45.854,
    longitude: 8.986,
  },
  {
    zipCode: 6883,
    city: "Novazzano",
    cantonShort: "TI",
    latitude: 45.8428,
    longitude: 8.9785,
  },
  {
    zipCode: 6526,
    city: "Prosito",
    cantonShort: "TI",
    latitude: 46.281,
    longitude: 8.9411,
  },
  {
    zipCode: 6527,
    city: "Lodrino",
    cantonShort: "TI",
    latitude: 46.305,
    longitude: 8.9686,
  },
  {
    zipCode: 6703,
    city: "Osogna",
    cantonShort: "TI",
    latitude: 46.3262,
    longitude: 9.0212,
  },
  {
    zipCode: 6705,
    city: "Cresciano",
    cantonShort: "TI",
    latitude: 46.3027,
    longitude: 9.0324,
  },
  {
    zipCode: 6707,
    city: "Iragna",
    cantonShort: "TI",
    latitude: 46.3248,
    longitude: 8.9395,
  },
  {
    zipCode: 6710,
    city: "Biasca Stazione",
    cantonShort: "TI",
    latitude: 46.3597,
    longitude: 8.9697,
  },
  {
    zipCode: 6710,
    city: "Biasca",
    cantonShort: "TI",
    latitude: 46.3665,
    longitude: 9.0226,
  },
  {
    zipCode: 6670,
    city: "Avegno",
    cantonShort: "TI",
    latitude: 46.2037,
    longitude: 8.7645,
  },
  {
    zipCode: 6672,
    city: "Gordevio",
    cantonShort: "TI",
    latitude: 46.2325,
    longitude: 8.7665,
  },
  {
    zipCode: 6673,
    city: "Maggia",
    cantonShort: "TI",
    latitude: 46.2661,
    longitude: 8.7331,
  },
  {
    zipCode: 6674,
    city: "Someo",
    cantonShort: "TI",
    latitude: 46.288,
    longitude: 8.641,
  },
  {
    zipCode: 6674,
    city: "Riveo",
    cantonShort: "TI",
    latitude: 46.2803,
    longitude: 8.6049,
  },
  {
    zipCode: 6675,
    city: "Cevio",
    cantonShort: "TI",
    latitude: 46.3042,
    longitude: 8.5955,
  },
  {
    zipCode: 6676,
    city: "Bignasco",
    cantonShort: "TI",
    latitude: 46.3422,
    longitude: 8.594,
  },
  {
    zipCode: 6677,
    city: "Aurigeno",
    cantonShort: "TI",
    latitude: 46.2225,
    longitude: 8.7175,
  },
  {
    zipCode: 6677,
    city: "Moghegno",
    cantonShort: "TI",
    latitude: 46.2391,
    longitude: 8.6869,
  },
  {
    zipCode: 6678,
    city: "Coglio",
    cantonShort: "TI",
    latitude: 46.288,
    longitude: 8.7071,
  },
  {
    zipCode: 6678,
    city: "Lodano",
    cantonShort: "TI",
    latitude: 46.2522,
    longitude: 8.6581,
  },
  {
    zipCode: 6678,
    city: "Giumaglio",
    cantonShort: "TI",
    latitude: 46.2958,
    longitude: 8.6819,
  },
  {
    zipCode: 6682,
    city: "Linescio",
    cantonShort: "TI",
    latitude: 46.3122,
    longitude: 8.5768,
  },
  {
    zipCode: 6683,
    city: "Cerentino",
    cantonShort: "TI",
    latitude: 46.3099,
    longitude: 8.5437,
  },
  {
    zipCode: 6683,
    city: "Niva (Vallemaggia)",
    cantonShort: "TI",
    latitude: 46.2763,
    longitude: 8.542,
  },
  {
    zipCode: 6684,
    city: "Campo (Vallemaggia)",
    cantonShort: "TI",
    latitude: 46.2842,
    longitude: 8.5061,
  },
  {
    zipCode: 6684,
    city: "Cimalmotto",
    cantonShort: "TI",
    latitude: 46.2748,
    longitude: 8.4683,
  },
  {
    zipCode: 6685,
    city: "Bosco/Gurin",
    cantonShort: "TI",
    latitude: 46.3203,
    longitude: 8.484,
  },
  {
    zipCode: 6690,
    city: "S. Carlo (Val Bavona)",
    cantonShort: "TI",
    latitude: 46.4277,
    longitude: 8.5021,
  },
  {
    zipCode: 6690,
    city: "Cavergno",
    cantonShort: "TI",
    latitude: 46.3733,
    longitude: 8.5357,
  },
  {
    zipCode: 6692,
    city: "Brontallo",
    cantonShort: "TI",
    latitude: 46.3581,
    longitude: 8.6192,
  },
  {
    zipCode: 6692,
    city: "Menzonio",
    cantonShort: "TI",
    latitude: 46.3554,
    longitude: 8.6557,
  },
  {
    zipCode: 6693,
    city: "Broglio",
    cantonShort: "TI",
    latitude: 46.3715,
    longitude: 8.6747,
  },
  {
    zipCode: 6694,
    city: "Prato-Sornico",
    cantonShort: "TI",
    latitude: 46.3976,
    longitude: 8.6942,
  },
  {
    zipCode: 6695,
    city: "Piano di Peccia",
    cantonShort: "TI",
    latitude: 46.4294,
    longitude: 8.5874,
  },
  {
    zipCode: 6695,
    city: "Peccia",
    cantonShort: "TI",
    latitude: 46.4061,
    longitude: 8.6407,
  },
  {
    zipCode: 6696,
    city: "Fusio",
    cantonShort: "TI",
    latitude: 46.4576,
    longitude: 8.6418,
  },
  {
    zipCode: 6377,
    city: "Seelisberg",
    cantonShort: "UR",
    latitude: 46.9634,
    longitude: 8.5748,
  },
  {
    zipCode: 6441,
    city: "R\u00fctli",
    cantonShort: "UR",
    latitude: 46.9696,
    longitude: 8.5983,
  },
  {
    zipCode: 6452,
    city: "Sisikon",
    cantonShort: "UR",
    latitude: 46.941,
    longitude: 8.6267,
  },
  {
    zipCode: 6454,
    city: "Fl\u00fcelen",
    cantonShort: "UR",
    latitude: 46.9143,
    longitude: 8.6391,
  },
  {
    zipCode: 6460,
    city: "Altdorf UR",
    cantonShort: "UR",
    latitude: 46.8877,
    longitude: 8.6414,
  },
  {
    zipCode: 6460,
    city: "Altdorf UR 1",
    cantonShort: "UR",
    latitude: 46.8883,
    longitude: 8.6409,
  },
  {
    zipCode: 6460,
    city: "Altdorf UR 2",
    cantonShort: "UR",
    latitude: 46.8883,
    longitude: 8.6409,
  },
  {
    zipCode: 6461,
    city: "Isenthal",
    cantonShort: "UR",
    latitude: 46.8866,
    longitude: 8.5253,
  },
  {
    zipCode: 6462,
    city: "Seedorf UR",
    cantonShort: "UR",
    latitude: 46.8825,
    longitude: 8.5882,
  },
  {
    zipCode: 6463,
    city: "B\u00fcrglen UR",
    cantonShort: "UR",
    latitude: 46.8854,
    longitude: 8.6897,
  },
  {
    zipCode: 6464,
    city: "Spiringen",
    cantonShort: "UR",
    latitude: 46.876,
    longitude: 8.7352,
  },
  {
    zipCode: 6465,
    city: "Untersch\u00e4chen",
    cantonShort: "UR",
    latitude: 46.8476,
    longitude: 8.7868,
  },
  {
    zipCode: 6466,
    city: "Bauen",
    cantonShort: "UR",
    latitude: 46.9299,
    longitude: 8.5819,
  },
  {
    zipCode: 6467,
    city: "Schattdorf",
    cantonShort: "UR",
    latitude: 46.8565,
    longitude: 8.6521,
  },
  {
    zipCode: 6468,
    city: "Attinghausen",
    cantonShort: "UR",
    latitude: 46.8535,
    longitude: 8.5985,
  },
  {
    zipCode: 6469,
    city: "Haldi b. Schattdorf",
    cantonShort: "UR",
    latitude: 46.8451,
    longitude: 8.6862,
  },
  {
    zipCode: 6472,
    city: "Erstfeld",
    cantonShort: "UR",
    latitude: 46.8124,
    longitude: 8.6023,
  },
  {
    zipCode: 6473,
    city: "Silenen",
    cantonShort: "UR",
    latitude: 46.8042,
    longitude: 8.6915,
  },
  {
    zipCode: 6474,
    city: "Amsteg",
    cantonShort: "UR",
    latitude: 46.7793,
    longitude: 8.6544,
  },
  {
    zipCode: 6475,
    city: "Bristen",
    cantonShort: "UR",
    latitude: 46.7724,
    longitude: 8.7632,
  },
  {
    zipCode: 6476,
    city: "Intschi",
    cantonShort: "UR",
    latitude: 46.7712,
    longitude: 8.6323,
  },
  {
    zipCode: 6482,
    city: "Gurtnellen",
    cantonShort: "UR",
    latitude: 46.7352,
    longitude: 8.6205,
  },
  {
    zipCode: 6484,
    city: "Wassen UR",
    cantonShort: "UR",
    latitude: 46.6974,
    longitude: 8.597,
  },
  {
    zipCode: 6485,
    city: "Meien",
    cantonShort: "UR",
    latitude: 46.7338,
    longitude: 8.5119,
  },
  {
    zipCode: 6487,
    city: "G\u00f6schenen",
    cantonShort: "UR",
    latitude: 46.6639,
    longitude: 8.4988,
  },
  {
    zipCode: 6490,
    city: "Andermatt",
    cantonShort: "UR",
    latitude: 46.6205,
    longitude: 8.6314,
  },
  {
    zipCode: 6491,
    city: "Realp",
    cantonShort: "UR",
    latitude: 46.5803,
    longitude: 8.4701,
  },
  {
    zipCode: 6493,
    city: "Hospental",
    cantonShort: "UR",
    latitude: 46.6066,
    longitude: 8.5617,
  },
  {
    zipCode: 8751,
    city: "Urnerboden",
    cantonShort: "UR",
    latitude: 46.8796,
    longitude: 8.9019,
  },
  {
    zipCode: 1844,
    city: "Villeneuve VD",
    cantonShort: "VD",
    latitude: 46.4062,
    longitude: 6.9685,
  },
  {
    zipCode: 1845,
    city: "Noville",
    cantonShort: "VD",
    latitude: 46.3939,
    longitude: 6.883,
  },
  {
    zipCode: 1846,
    city: "Chessel",
    cantonShort: "VD",
    latitude: 46.3534,
    longitude: 6.8958,
  },
  {
    zipCode: 1847,
    city: "Rennaz",
    cantonShort: "VD",
    latitude: 46.3758,
    longitude: 6.9184,
  },
  {
    zipCode: 1852,
    city: "Roche VD",
    cantonShort: "VD",
    latitude: 46.3598,
    longitude: 6.9252,
  },
  {
    zipCode: 1853,
    city: "Yvorne",
    cantonShort: "VD",
    latitude: 46.339,
    longitude: 6.9458,
  },
  {
    zipCode: 1854,
    city: "Leysin",
    cantonShort: "VD",
    latitude: 46.3525,
    longitude: 7.0111,
  },
  {
    zipCode: 1856,
    city: "Corbeyrier",
    cantonShort: "VD",
    latitude: 46.3736,
    longitude: 6.9855,
  },
  {
    zipCode: 1860,
    city: "Aigle",
    cantonShort: "VD",
    latitude: 46.3147,
    longitude: 6.9716,
  },
  {
    zipCode: 1862,
    city: "La Comballaz",
    cantonShort: "VD",
    latitude: 46.3769,
    longitude: 7.0778,
  },
  {
    zipCode: 1862,
    city: "Les Mosses",
    cantonShort: "VD",
    latitude: 46.3943,
    longitude: 7.1052,
  },
  {
    zipCode: 1863,
    city: "Le S\u00e9pey",
    cantonShort: "VD",
    latitude: 46.3731,
    longitude: 7.0478,
  },
  {
    zipCode: 1864,
    city: "Vers-l'Eglise",
    cantonShort: "VD",
    latitude: 46.3589,
    longitude: 7.1178,
  },
  {
    zipCode: 1865,
    city: "Les Diablerets",
    cantonShort: "VD",
    latitude: 46.3439,
    longitude: 7.1757,
  },
  {
    zipCode: 1866,
    city: "La Forclaz VD",
    cantonShort: "VD",
    latitude: 46.3425,
    longitude: 7.0799,
  },
  {
    zipCode: 1867,
    city: "Panex",
    cantonShort: "VD",
    latitude: 46.3164,
    longitude: 7.0267,
  },
  {
    zipCode: 1867,
    city: "Ollon VD",
    cantonShort: "VD",
    latitude: 46.2875,
    longitude: 7.0022,
  },
  {
    zipCode: 1867,
    city: "St-Triphon",
    cantonShort: "VD",
    latitude: 46.2848,
    longitude: 6.9717,
  },
  {
    zipCode: 1880,
    city: "Bex",
    cantonShort: "VD",
    latitude: 46.2479,
    longitude: 7.0182,
  },
  {
    zipCode: 1880,
    city: "Freni\u00e8res-sur-Bex",
    cantonShort: "VD",
    latitude: 46.2427,
    longitude: 7.065,
  },
  {
    zipCode: 1880,
    city: "Les Posses-sur-Bex",
    cantonShort: "VD",
    latitude: 46.2742,
    longitude: 7.0458,
  },
  {
    zipCode: 1880,
    city: "Fenalet-sur-Bex",
    cantonShort: "VD",
    latitude: 46.2756,
    longitude: 7.0316,
  },
  {
    zipCode: 1880,
    city: "Les Plans-sur-Bex",
    cantonShort: "VD",
    latitude: 46.2446,
    longitude: 7.1149,
  },
  {
    zipCode: 1882,
    city: "Gryon",
    cantonShort: "VD",
    latitude: 46.2868,
    longitude: 7.1252,
  },
  {
    zipCode: 1884,
    city: "Hu\u00e9moz",
    cantonShort: "VD",
    latitude: 46.2955,
    longitude: 7.0191,
  },
  {
    zipCode: 1884,
    city: "Villars-sur-Ollon",
    cantonShort: "VD",
    latitude: 46.3161,
    longitude: 7.0871,
  },
  {
    zipCode: 1884,
    city: "Arveyes",
    cantonShort: "VD",
    latitude: 46.2879,
    longitude: 7.0593,
  },
  {
    zipCode: 1885,
    city: "Chesi\u00e8res",
    cantonShort: "VD",
    latitude: 46.3047,
    longitude: 7.0418,
  },
  {
    zipCode: 1892,
    city: "Morcles",
    cantonShort: "VD",
    latitude: 46.2056,
    longitude: 7.048,
  },
  {
    zipCode: 1892,
    city: "Lavey-les-Bains",
    cantonShort: "VD",
    latitude: 46.2042,
    longitude: 7.0179,
  },
  {
    zipCode: 1892,
    city: "Lavey-Village",
    cantonShort: "VD",
    latitude: 46.2149,
    longitude: 7.0287,
  },
  {
    zipCode: 1082,
    city: "Corcelles-le-Jorat",
    cantonShort: "VD",
    latitude: 46.6013,
    longitude: 6.7308,
  },
  {
    zipCode: 1085,
    city: "Vulliens",
    cantonShort: "VD",
    latitude: 46.6227,
    longitude: 6.7936,
  },
  {
    zipCode: 1088,
    city: "Ropraz",
    cantonShort: "VD",
    latitude: 46.6109,
    longitude: 6.7537,
  },
  {
    zipCode: 1509,
    city: "Vucherens",
    cantonShort: "VD",
    latitude: 46.6269,
    longitude: 6.7759,
  },
  {
    zipCode: 1510,
    city: "Moudon",
    cantonShort: "VD",
    latitude: 46.672,
    longitude: 6.7921,
  },
  {
    zipCode: 1510,
    city: "Syens",
    cantonShort: "VD",
    latitude: 46.6429,
    longitude: 6.7782,
  },
  {
    zipCode: 1512,
    city: "Chavannes-sur-Moudon",
    cantonShort: "VD",
    latitude: 46.6597,
    longitude: 6.8188,
  },
  {
    zipCode: 1513,
    city: "Hermenches",
    cantonShort: "VD",
    latitude: 46.6374,
    longitude: 6.7546,
  },
  {
    zipCode: 1513,
    city: "Rossenges",
    cantonShort: "VD",
    latitude: 46.6543,
    longitude: 6.775,
  },
  {
    zipCode: 1514,
    city: "Bussy-sur-Moudon",
    cantonShort: "VD",
    latitude: 46.6904,
    longitude: 6.8096,
  },
  {
    zipCode: 1515,
    city: "Villars-le-Comte",
    cantonShort: "VD",
    latitude: 46.7135,
    longitude: 6.8018,
  },
  {
    zipCode: 1521,
    city: "Curtilles",
    cantonShort: "VD",
    latitude: 46.7005,
    longitude: 6.8506,
  },
  {
    zipCode: 1522,
    city: "Lucens",
    cantonShort: "VD",
    latitude: 46.7093,
    longitude: 6.8375,
  },
  {
    zipCode: 1522,
    city: "Oulens-sur-Lucens",
    cantonShort: "VD",
    latitude: 46.7076,
    longitude: 6.8142,
  },
  {
    zipCode: 1523,
    city: "Granges-pr\u00e8s-Marnand",
    cantonShort: "VD",
    latitude: 46.7672,
    longitude: 6.887,
  },
  {
    zipCode: 1524,
    city: "Marnand",
    cantonShort: "VD",
    latitude: 46.7611,
    longitude: 6.9046,
  },
  {
    zipCode: 1525,
    city: "Henniez",
    cantonShort: "VD",
    latitude: 46.7426,
    longitude: 6.8848,
  },
  {
    zipCode: 1525,
    city: "Seigneux",
    cantonShort: "VD",
    latitude: 46.7283,
    longitude: 6.8779,
  },
  {
    zipCode: 1526,
    city: "Cremin",
    cantonShort: "VD",
    latitude: 46.7235,
    longitude: 6.8406,
  },
  {
    zipCode: 1526,
    city: "Forel-sur-Lucens",
    cantonShort: "VD",
    latitude: 46.7264,
    longitude: 6.8242,
  },
  {
    zipCode: 1530,
    city: "Payerne",
    cantonShort: "VD",
    latitude: 46.825,
    longitude: 6.9299,
  },
  {
    zipCode: 1534,
    city: "Sassel",
    cantonShort: "VD",
    latitude: 46.777,
    longitude: 6.8593,
  },
  {
    zipCode: 1535,
    city: "Combremont-le-Grand",
    cantonShort: "VD",
    latitude: 46.765,
    longitude: 6.8225,
  },
  {
    zipCode: 1536,
    city: "Combremont-le-Petit",
    cantonShort: "VD",
    latitude: 46.7461,
    longitude: 6.8037,
  },
  {
    zipCode: 1537,
    city: "Champtauroz",
    cantonShort: "VD",
    latitude: 46.759,
    longitude: 6.7863,
  },
  {
    zipCode: 1538,
    city: "Treytorrens (Payerne)",
    cantonShort: "VD",
    latitude: 46.7748,
    longitude: 6.8024,
  },
  {
    zipCode: 1543,
    city: "Grandcour",
    cantonShort: "VD",
    latitude: 46.8724,
    longitude: 6.9324,
  },
  {
    zipCode: 1545,
    city: "Chevroux",
    cantonShort: "VD",
    latitude: 46.902,
    longitude: 6.8992,
  },
  {
    zipCode: 1551,
    city: "Vers-chez-Perrin",
    cantonShort: "VD",
    latitude: 46.798,
    longitude: 6.9479,
  },
  {
    zipCode: 1552,
    city: "Trey",
    cantonShort: "VD",
    latitude: 46.7749,
    longitude: 6.9232,
  },
  {
    zipCode: 1554,
    city: "S\u00e9deilles",
    cantonShort: "VD",
    latitude: 46.7428,
    longitude: 6.9302,
  },
  {
    zipCode: 1554,
    city: "Rossens VD",
    cantonShort: "VD",
    latitude: 46.7331,
    longitude: 6.9227,
  },
  {
    zipCode: 1555,
    city: "Villarzel",
    cantonShort: "VD",
    latitude: 46.7438,
    longitude: 6.9091,
  },
  {
    zipCode: 1562,
    city: "Corcelles-pr\u00e8s-Payerne",
    cantonShort: "VD",
    latitude: 46.8425,
    longitude: 6.9661,
  },
  {
    zipCode: 1565,
    city: "Missy",
    cantonShort: "VD",
    latitude: 46.8761,
    longitude: 6.9739,
  },
  {
    zipCode: 1580,
    city: "Avenches",
    cantonShort: "VD",
    latitude: 46.8876,
    longitude: 7.0387,
  },
  {
    zipCode: 1580,
    city: "Donatyre",
    cantonShort: "VD",
    latitude: 46.8726,
    longitude: 7.0601,
  },
  {
    zipCode: 1580,
    city: "Oleyres",
    cantonShort: "VD",
    latitude: 46.855,
    longitude: 7.0409,
  },
  {
    zipCode: 1584,
    city: "Villars-le-Grand",
    cantonShort: "VD",
    latitude: 46.9054,
    longitude: 6.9986,
  },
  {
    zipCode: 1585,
    city: "Salavaux",
    cantonShort: "VD",
    latitude: 46.9149,
    longitude: 7.0361,
  },
  {
    zipCode: 1585,
    city: "Cotterd",
    cantonShort: "VD",
    latitude: 46.9232,
    longitude: 7.0273,
  },
  {
    zipCode: 1585,
    city: "Bellerive VD",
    cantonShort: "VD",
    latitude: 46.9255,
    longitude: 7.022,
  },
  {
    zipCode: 1586,
    city: "Vallamand",
    cantonShort: "VD",
    latitude: 46.9303,
    longitude: 7.0426,
  },
  {
    zipCode: 1587,
    city: "Montmagny",
    cantonShort: "VD",
    latitude: 46.9265,
    longitude: 7.006,
  },
  {
    zipCode: 1587,
    city: "Constantine",
    cantonShort: "VD",
    latitude: 46.9103,
    longitude: 7.0119,
  },
  {
    zipCode: 1588,
    city: "Cudrefin",
    cantonShort: "VD",
    latitude: 46.9597,
    longitude: 7.0127,
  },
  {
    zipCode: 1589,
    city: "Chabrey",
    cantonShort: "VD",
    latitude: 46.942,
    longitude: 6.9647,
  },
  {
    zipCode: 1595,
    city: "Faoug",
    cantonShort: "VD",
    latitude: 46.9068,
    longitude: 7.0726,
  },
  {
    zipCode: 1682,
    city: "Villars-Bramard",
    cantonShort: "VD",
    latitude: 46.7202,
    longitude: 6.9046,
  },
  {
    zipCode: 1682,
    city: "Lovatens",
    cantonShort: "VD",
    latitude: 46.6921,
    longitude: 6.8659,
  },
  {
    zipCode: 1682,
    city: "Cerniaz VD",
    cantonShort: "VD",
    latitude: 46.7291,
    longitude: 6.8983,
  },
  {
    zipCode: 1682,
    city: "Dompierre VD",
    cantonShort: "VD",
    latitude: 46.7113,
    longitude: 6.8801,
  },
  {
    zipCode: 1682,
    city: "Pr\u00e9vonloup",
    cantonShort: "VD",
    latitude: 46.6979,
    longitude: 6.8828,
  },
  {
    zipCode: 1683,
    city: "Brenles",
    cantonShort: "VD",
    latitude: 46.6678,
    longitude: 6.8562,
  },
  {
    zipCode: 1683,
    city: "Chesalles-sur-Moudon",
    cantonShort: "VD",
    latitude: 46.6747,
    longitude: 6.8366,
  },
  {
    zipCode: 1683,
    city: "Sarzens",
    cantonShort: "VD",
    latitude: 46.6825,
    longitude: 6.8495,
  },
  {
    zipCode: 1787,
    city: "Mur (Vully) VD",
    cantonShort: "VD",
    latitude: 46.9388,
    longitude: 7.0554,
  },
  {
    zipCode: 1031,
    city: "Mex VD",
    cantonShort: "VD",
    latitude: 46.5771,
    longitude: 6.559,
  },
  {
    zipCode: 1034,
    city: "Boussens",
    cantonShort: "VD",
    latitude: 46.6033,
    longitude: 6.586,
  },
  {
    zipCode: 1035,
    city: "Bournens",
    cantonShort: "VD",
    latitude: 46.6089,
    longitude: 6.564,
  },
  {
    zipCode: 1036,
    city: "Sullens",
    cantonShort: "VD",
    latitude: 46.5912,
    longitude: 6.5674,
  },
  {
    zipCode: 1037,
    city: "Etagni\u00e8res",
    cantonShort: "VD",
    latitude: 46.6014,
    longitude: 6.6085,
  },
  {
    zipCode: 1038,
    city: "Bercher",
    cantonShort: "VD",
    latitude: 46.6916,
    longitude: 6.7063,
  },
  {
    zipCode: 1040,
    city: "Villars-le-Terroir",
    cantonShort: "VD",
    latitude: 46.6618,
    longitude: 6.6426,
  },
  {
    zipCode: 1040,
    city: "Echallens",
    cantonShort: "VD",
    latitude: 46.6384,
    longitude: 6.6353,
  },
  {
    zipCode: 1040,
    city: "St-Barth\u00e9lemy VD",
    cantonShort: "VD",
    latitude: 46.6348,
    longitude: 6.6021,
  },
  {
    zipCode: 1041,
    city: "Dommartin",
    cantonShort: "VD",
    latitude: 46.6438,
    longitude: 6.6973,
  },
  {
    zipCode: 1041,
    city: "Poliez-le-Grand",
    cantonShort: "VD",
    latitude: 46.6397,
    longitude: 6.6703,
  },
  {
    zipCode: 1041,
    city: "Montaubion-Chardonney",
    cantonShort: "VD",
    latitude: 46.6458,
    longitude: 6.713,
  },
  {
    zipCode: 1041,
    city: "Poliez-Pittet",
    cantonShort: "VD",
    latitude: 46.6235,
    longitude: 6.6887,
  },
  {
    zipCode: 1041,
    city: "Bottens",
    cantonShort: "VD",
    latitude: 46.6151,
    longitude: 6.6621,
  },
  {
    zipCode: 1041,
    city: "Naz",
    cantonShort: "VD",
    latitude: 46.6594,
    longitude: 6.694,
  },
  {
    zipCode: 1042,
    city: "Bettens",
    cantonShort: "VD",
    latitude: 46.6242,
    longitude: 6.5751,
  },
  {
    zipCode: 1042,
    city: "Bioley-Orjulaz",
    cantonShort: "VD",
    latitude: 46.6193,
    longitude: 6.6009,
  },
  {
    zipCode: 1042,
    city: "Assens",
    cantonShort: "VD",
    latitude: 46.6178,
    longitude: 6.6273,
  },
  {
    zipCode: 1043,
    city: "Sugnens",
    cantonShort: "VD",
    latitude: 46.6544,
    longitude: 6.6729,
  },
  {
    zipCode: 1044,
    city: "Fey",
    cantonShort: "VD",
    latitude: 46.6729,
    longitude: 6.6801,
  },
  {
    zipCode: 1045,
    city: "Ogens",
    cantonShort: "VD",
    latitude: 46.7093,
    longitude: 6.7236,
  },
  {
    zipCode: 1046,
    city: "Rueyres",
    cantonShort: "VD",
    latitude: 46.6975,
    longitude: 6.6939,
  },
  {
    zipCode: 1047,
    city: "Oppens",
    cantonShort: "VD",
    latitude: 46.7153,
    longitude: 6.695,
  },
  {
    zipCode: 1053,
    city: "Bretigny-sur-Morrens",
    cantonShort: "VD",
    latitude: 46.601,
    longitude: 6.6443,
  },
  {
    zipCode: 1053,
    city: "Cugy VD",
    cantonShort: "VD",
    latitude: 46.5856,
    longitude: 6.658,
  },
  {
    zipCode: 1054,
    city: "Morrens VD",
    cantonShort: "VD",
    latitude: 46.5923,
    longitude: 6.6271,
  },
  {
    zipCode: 1055,
    city: "Froideville",
    cantonShort: "VD",
    latitude: 46.5982,
    longitude: 6.6917,
  },
  {
    zipCode: 1058,
    city: "Villars-Tiercelin",
    cantonShort: "VD",
    latitude: 46.6199,
    longitude: 6.7109,
  },
  {
    zipCode: 1059,
    city: "Peney-le-Jorat",
    cantonShort: "VD",
    latitude: 46.6301,
    longitude: 6.729,
  },
  {
    zipCode: 1061,
    city: "Villars-Mendraz",
    cantonShort: "VD",
    latitude: 46.6453,
    longitude: 6.7268,
  },
  {
    zipCode: 1062,
    city: "Sottens",
    cantonShort: "VD",
    latitude: 46.6536,
    longitude: 6.7399,
  },
  {
    zipCode: 1063,
    city: "Peyres-Possens",
    cantonShort: "VD",
    latitude: 46.6628,
    longitude: 6.7069,
  },
  {
    zipCode: 1063,
    city: "Boulens",
    cantonShort: "VD",
    latitude: 46.6789,
    longitude: 6.7183,
  },
  {
    zipCode: 1063,
    city: "Chapelle-sur-Moudon",
    cantonShort: "VD",
    latitude: 46.6692,
    longitude: 6.7359,
  },
  {
    zipCode: 1063,
    city: "Martherenges",
    cantonShort: "VD",
    latitude: 46.6613,
    longitude: 6.7558,
  },
  {
    zipCode: 1302,
    city: "Vufflens-la-Ville",
    cantonShort: "VD",
    latitude: 46.5754,
    longitude: 6.5373,
  },
  {
    zipCode: 1303,
    city: "Penthaz",
    cantonShort: "VD",
    latitude: 46.5988,
    longitude: 6.5414,
  },
  {
    zipCode: 1305,
    city: "Penthalaz",
    cantonShort: "VD",
    latitude: 46.6128,
    longitude: 6.5291,
  },
  {
    zipCode: 1306,
    city: "Daillens",
    cantonShort: "VD",
    latitude: 46.6286,
    longitude: 6.5497,
  },
  {
    zipCode: 1307,
    city: "Lussery-Villars",
    cantonShort: "VD",
    latitude: 46.6328,
    longitude: 6.5255,
  },
  {
    zipCode: 1375,
    city: "Penth\u00e9r\u00e9az",
    cantonShort: "VD",
    latitude: 46.6828,
    longitude: 6.6065,
  },
  {
    zipCode: 1376,
    city: "Goumoens-le-Jux",
    cantonShort: "VD",
    latitude: 46.6677,
    longitude: 6.588,
  },
  {
    zipCode: 1376,
    city: "Eclagnens",
    cantonShort: "VD",
    latitude: 46.6491,
    longitude: 6.592,
  },
  {
    zipCode: 1376,
    city: "Goumoens-la-Ville",
    cantonShort: "VD",
    latitude: 46.6602,
    longitude: 6.6111,
  },
  {
    zipCode: 1377,
    city: "Oulens-sous-Echallens",
    cantonShort: "VD",
    latitude: 46.6467,
    longitude: 6.573,
  },
  {
    zipCode: 1409,
    city: "Chan\u00e9az",
    cantonShort: "VD",
    latitude: 46.7276,
    longitude: 6.7445,
  },
  {
    zipCode: 1410,
    city: "Denezy",
    cantonShort: "VD",
    latitude: 46.7222,
    longitude: 6.7857,
  },
  {
    zipCode: 1410,
    city: "St-Cierges",
    cantonShort: "VD",
    latitude: 46.6878,
    longitude: 6.7447,
  },
  {
    zipCode: 1410,
    city: "Thierrens",
    cantonShort: "VD",
    latitude: 46.7038,
    longitude: 6.7589,
  },
  {
    zipCode: 1410,
    city: "Correvon",
    cantonShort: "VD",
    latitude: 46.7184,
    longitude: 6.7414,
  },
  {
    zipCode: 1416,
    city: "Pailly",
    cantonShort: "VD",
    latitude: 46.6997,
    longitude: 6.6735,
  },
  {
    zipCode: 1417,
    city: "Epautheyres",
    cantonShort: "VD",
    latitude: 46.7348,
    longitude: 6.6454,
  },
  {
    zipCode: 1417,
    city: "Essertines-sur-Yverdon",
    cantonShort: "VD",
    latitude: 46.7142,
    longitude: 6.6445,
  },
  {
    zipCode: 1418,
    city: "Vuarrens",
    cantonShort: "VD",
    latitude: 46.6858,
    longitude: 6.6459,
  },
  {
    zipCode: 1515,
    city: "Neyruz-sur-Moudon",
    cantonShort: "VD",
    latitude: 46.6972,
    longitude: 6.7876,
  },
  {
    zipCode: 1148,
    city: "La Praz",
    cantonShort: "VD",
    latitude: 46.6698,
    longitude: 6.4243,
  },
  {
    zipCode: 1321,
    city: "Arnex-sur-Orbe",
    cantonShort: "VD",
    latitude: 46.69,
    longitude: 6.5168,
  },
  {
    zipCode: 1322,
    city: "Croy",
    cantonShort: "VD",
    latitude: 46.6859,
    longitude: 6.4859,
  },
  {
    zipCode: 1323,
    city: "Romainm\u00f4tier",
    cantonShort: "VD",
    latitude: 46.6869,
    longitude: 6.4618,
  },
  {
    zipCode: 1324,
    city: "Premier",
    cantonShort: "VD",
    latitude: 46.7092,
    longitude: 6.4419,
  },
  {
    zipCode: 1325,
    city: "Vaulion",
    cantonShort: "VD",
    latitude: 46.6848,
    longitude: 6.3832,
  },
  {
    zipCode: 1326,
    city: "Juriens",
    cantonShort: "VD",
    latitude: 46.6828,
    longitude: 6.4301,
  },
  {
    zipCode: 1329,
    city: "Bretonni\u00e8res",
    cantonShort: "VD",
    latitude: 46.7159,
    longitude: 6.4704,
  },
  {
    zipCode: 1337,
    city: "Vallorbe",
    cantonShort: "VD",
    latitude: 46.7078,
    longitude: 6.3714,
  },
  {
    zipCode: 1338,
    city: "Ballaigues",
    cantonShort: "VD",
    latitude: 46.7349,
    longitude: 6.4104,
  },
  {
    zipCode: 1341,
    city: "L'Orient",
    cantonShort: "VD",
    latitude: 46.5912,
    longitude: 6.2577,
  },
  {
    zipCode: 1341,
    city: "Orient",
    cantonShort: "VD",
    latitude: 46.6015,
    longitude: 6.2394,
  },
  {
    zipCode: 1342,
    city: "Le Pont",
    cantonShort: "VD",
    latitude: 46.6681,
    longitude: 6.3377,
  },
  {
    zipCode: 1343,
    city: "Les Charbonni\u00e8res",
    cantonShort: "VD",
    latitude: 46.6785,
    longitude: 6.3026,
  },
  {
    zipCode: 1344,
    city: "L'Abbaye",
    cantonShort: "VD",
    latitude: 46.6405,
    longitude: 6.324,
  },
  {
    zipCode: 1345,
    city: "Le S\u00e9chey",
    cantonShort: "VD",
    latitude: 46.656,
    longitude: 6.2997,
  },
  {
    zipCode: 1345,
    city: "Le Lieu",
    cantonShort: "VD",
    latitude: 46.6487,
    longitude: 6.2605,
  },
  {
    zipCode: 1346,
    city: "Les Bioux",
    cantonShort: "VD",
    latitude: 46.615,
    longitude: 6.2908,
  },
  {
    zipCode: 1347,
    city: "Le Solliat",
    cantonShort: "VD",
    latitude: 46.6259,
    longitude: 6.2202,
  },
  {
    zipCode: 1347,
    city: "Le Sentier",
    cantonShort: "VD",
    latitude: 46.6106,
    longitude: 6.2143,
  },
  {
    zipCode: 1348,
    city: "Le Brassus",
    cantonShort: "VD",
    latitude: 46.5685,
    longitude: 6.1906,
  },
  {
    zipCode: 1350,
    city: "Orbe",
    cantonShort: "VD",
    latitude: 46.727,
    longitude: 6.5431,
  },
  {
    zipCode: 1352,
    city: "Agiez",
    cantonShort: "VD",
    latitude: 46.7182,
    longitude: 6.5077,
  },
  {
    zipCode: 1353,
    city: "Bofflens",
    cantonShort: "VD",
    latitude: 46.7036,
    longitude: 6.4953,
  },
  {
    zipCode: 1354,
    city: "Montcherand",
    cantonShort: "VD",
    latitude: 46.734,
    longitude: 6.5108,
  },
  {
    zipCode: 1355,
    city: "L'Abergement",
    cantonShort: "VD",
    latitude: 46.7547,
    longitude: 6.4832,
  },
  {
    zipCode: 1355,
    city: "Sergey",
    cantonShort: "VD",
    latitude: 46.7512,
    longitude: 6.501,
  },
  {
    zipCode: 1356,
    city: "La Russille",
    cantonShort: "VD",
    latitude: 46.7392,
    longitude: 6.4879,
  },
  {
    zipCode: 1356,
    city: "Les Cl\u00e9es",
    cantonShort: "VD",
    latitude: 46.7297,
    longitude: 6.4624,
  },
  {
    zipCode: 1357,
    city: "Lignerolle",
    cantonShort: "VD",
    latitude: 46.7498,
    longitude: 6.4488,
  },
  {
    zipCode: 1358,
    city: "Valeyres-sous-Rances",
    cantonShort: "VD",
    latitude: 46.7482,
    longitude: 6.5354,
  },
  {
    zipCode: 1372,
    city: "Bavois",
    cantonShort: "VD",
    latitude: 46.6793,
    longitude: 6.566,
  },
  {
    zipCode: 1373,
    city: "Chavornay",
    cantonShort: "VD",
    latitude: 46.705,
    longitude: 6.5684,
  },
  {
    zipCode: 1374,
    city: "Corcelles-sur-Chavornay",
    cantonShort: "VD",
    latitude: 46.7027,
    longitude: 6.6046,
  },
  {
    zipCode: 1400,
    city: "Yverdon-les-Bains",
    cantonShort: "VD",
    latitude: 46.7762,
    longitude: 6.6385,
  },
  {
    zipCode: 1400,
    city: "Yverdon 2",
    cantonShort: "VD",
    latitude: 46.7743,
    longitude: 6.6371,
  },
  {
    zipCode: 1400,
    city: "Cheseaux-Nor\u00e9az",
    cantonShort: "VD",
    latitude: 46.7969,
    longitude: 6.6878,
  },
  {
    zipCode: 1401,
    city: "Yverdon-les-Bains",
    cantonShort: "VD",
    latitude: 46.7785,
    longitude: 6.6411,
  },
  {
    zipCode: 1404,
    city: "Cuarny",
    cantonShort: "VD",
    latitude: 46.7708,
    longitude: 6.692,
  },
  {
    zipCode: 1404,
    city: "Villars-Epeney",
    cantonShort: "VD",
    latitude: 46.7832,
    longitude: 6.6991,
  },
  {
    zipCode: 1405,
    city: "Pomy",
    cantonShort: "VD",
    latitude: 46.7583,
    longitude: 6.6692,
  },
  {
    zipCode: 1406,
    city: "Cronay",
    cantonShort: "VD",
    latitude: 46.7568,
    longitude: 6.7029,
  },
  {
    zipCode: 1407,
    city: "Gossens",
    cantonShort: "VD",
    latitude: 46.7398,
    longitude: 6.699,
  },
  {
    zipCode: 1407,
    city: "Donneloye",
    cantonShort: "VD",
    latitude: 46.7519,
    longitude: 6.7198,
  },
  {
    zipCode: 1407,
    city: "M\u00e9zery-pr\u00e8s-Donneloye",
    cantonShort: "VD",
    latitude: 46.7531,
    longitude: 6.7308,
  },
  {
    zipCode: 1407,
    city: "Bioley-Magnoux",
    cantonShort: "VD",
    latitude: 46.7267,
    longitude: 6.7144,
  },
  {
    zipCode: 1408,
    city: "Prahins",
    cantonShort: "VD",
    latitude: 46.7365,
    longitude: 6.733,
  },
  {
    zipCode: 1412,
    city: "Valeyres-sous-Ursins",
    cantonShort: "VD",
    latitude: 46.7453,
    longitude: 6.6533,
  },
  {
    zipCode: 1412,
    city: "Ursins",
    cantonShort: "VD",
    latitude: 46.7345,
    longitude: 6.6693,
  },
  {
    zipCode: 1413,
    city: "Orzens",
    cantonShort: "VD",
    latitude: 46.7263,
    longitude: 6.683,
  },
  {
    zipCode: 1415,
    city: "Molondin",
    cantonShort: "VD",
    latitude: 46.7631,
    longitude: 6.7478,
  },
  {
    zipCode: 1415,
    city: "D\u00e9moret",
    cantonShort: "VD",
    latitude: 46.7479,
    longitude: 6.7578,
  },
  {
    zipCode: 1420,
    city: "Fiez",
    cantonShort: "VD",
    latitude: 46.8272,
    longitude: 6.6266,
  },
  {
    zipCode: 1421,
    city: "Fontaines-sur-Grandson",
    cantonShort: "VD",
    latitude: 46.834,
    longitude: 6.6188,
  },
  {
    zipCode: 1421,
    city: "Grandevent",
    cantonShort: "VD",
    latitude: 46.8419,
    longitude: 6.6055,
  },
  {
    zipCode: 1422,
    city: "Grandson",
    cantonShort: "VD",
    latitude: 46.8122,
    longitude: 6.6529,
  },
  {
    zipCode: 1423,
    city: "Vaugondry",
    cantonShort: "VD",
    latitude: 46.8448,
    longitude: 6.6365,
  },
  {
    zipCode: 1423,
    city: "Romairon",
    cantonShort: "VD",
    latitude: 46.8506,
    longitude: 6.6422,
  },
  {
    zipCode: 1423,
    city: "Villars-Burquin",
    cantonShort: "VD",
    latitude: 46.8472,
    longitude: 6.6262,
  },
  {
    zipCode: 1423,
    city: "Fontanezier",
    cantonShort: "VD",
    latitude: 46.8641,
    longitude: 6.6577,
  },
  {
    zipCode: 1424,
    city: "Champagne",
    cantonShort: "VD",
    latitude: 46.8358,
    longitude: 6.6516,
  },
  {
    zipCode: 1425,
    city: "Onnens VD",
    cantonShort: "VD",
    latitude: 46.8429,
    longitude: 6.6899,
  },
  {
    zipCode: 1426,
    city: "Concise",
    cantonShort: "VD",
    latitude: 46.8558,
    longitude: 6.737,
  },
  {
    zipCode: 1426,
    city: "Corcelles-pr\u00e8s-Concise",
    cantonShort: "VD",
    latitude: 46.8425,
    longitude: 6.7101,
  },
  {
    zipCode: 1427,
    city: "Bonvillars",
    cantonShort: "VD",
    latitude: 46.8376,
    longitude: 6.6746,
  },
  {
    zipCode: 1428,
    city: "Provence",
    cantonShort: "VD",
    latitude: 46.8915,
    longitude: 6.6932,
  },
  {
    zipCode: 1428,
    city: "Mutrux",
    cantonShort: "VD",
    latitude: 46.8817,
    longitude: 6.7247,
  },
  {
    zipCode: 1429,
    city: "Giez",
    cantonShort: "VD",
    latitude: 46.8145,
    longitude: 6.6098,
  },
  {
    zipCode: 1430,
    city: "Orges",
    cantonShort: "VD",
    latitude: 46.8122,
    longitude: 6.5831,
  },
  {
    zipCode: 1431,
    city: "Novalles",
    cantonShort: "VD",
    latitude: 46.8319,
    longitude: 6.5925,
  },
  {
    zipCode: 1431,
    city: "Vugelles-La Mothe",
    cantonShort: "VD",
    latitude: 46.825,
    longitude: 6.5738,
  },
  {
    zipCode: 1432,
    city: "Gressy",
    cantonShort: "VD",
    latitude: 46.7525,
    longitude: 6.6386,
  },
  {
    zipCode: 1432,
    city: "Belmont-sur-Yverdon",
    cantonShort: "VD",
    latitude: 46.7379,
    longitude: 6.6255,
  },
  {
    zipCode: 1433,
    city: "Suchy",
    cantonShort: "VD",
    latitude: 46.7213,
    longitude: 6.6051,
  },
  {
    zipCode: 1434,
    city: "Ependes VD",
    cantonShort: "VD",
    latitude: 46.7448,
    longitude: 6.6027,
  },
  {
    zipCode: 1435,
    city: "Essert-Pittet",
    cantonShort: "VD",
    latitude: 46.7288,
    longitude: 6.5789,
  },
  {
    zipCode: 1436,
    city: "Chamblon",
    cantonShort: "VD",
    latitude: 46.778,
    longitude: 6.5987,
  },
  {
    zipCode: 1436,
    city: "Treycovagnes",
    cantonShort: "VD",
    latitude: 46.7695,
    longitude: 6.6067,
  },
  {
    zipCode: 1437,
    city: "Susc\u00e9vaz",
    cantonShort: "VD",
    latitude: 46.7621,
    longitude: 6.5868,
  },
  {
    zipCode: 1438,
    city: "Mathod",
    cantonShort: "VD",
    latitude: 46.7596,
    longitude: 6.565,
  },
  {
    zipCode: 1439,
    city: "Rances",
    cantonShort: "VD",
    latitude: 46.7659,
    longitude: 6.5076,
  },
  {
    zipCode: 1440,
    city: "Montagny-Chamard",
    cantonShort: "VD",
    latitude: 46.7904,
    longitude: 6.6098,
  },
  {
    zipCode: 1441,
    city: "Valeyres-sous-Montagny",
    cantonShort: "VD",
    latitude: 46.8005,
    longitude: 6.6037,
  },
  {
    zipCode: 1442,
    city: "Montagny-pr\u00e8s-Yverdon",
    cantonShort: "VD",
    latitude: 46.7904,
    longitude: 6.6098,
  },
  {
    zipCode: 1443,
    city: "Champvent",
    cantonShort: "VD",
    latitude: 46.7838,
    longitude: 6.5622,
  },
  {
    zipCode: 1443,
    city: "Villars-sous-Champvent",
    cantonShort: "VD",
    latitude: 46.7854,
    longitude: 6.5847,
  },
  {
    zipCode: 1443,
    city: "Essert-sous-Champvent",
    cantonShort: "VD",
    latitude: 46.7938,
    longitude: 6.5845,
  },
  {
    zipCode: 1445,
    city: "Vuiteboeuf",
    cantonShort: "VD",
    latitude: 46.8047,
    longitude: 6.5542,
  },
  {
    zipCode: 1446,
    city: "Baulmes",
    cantonShort: "VD",
    latitude: 46.7864,
    longitude: 6.5048,
  },
  {
    zipCode: 1450,
    city: "Ste-Croix",
    cantonShort: "VD",
    latitude: 46.8236,
    longitude: 6.5036,
  },
  {
    zipCode: 1450,
    city: "La Sagne (Ste-Croix)",
    cantonShort: "VD",
    latitude: 46.8085,
    longitude: 6.4984,
  },
  {
    zipCode: 1450,
    city: "Le Ch\u00e2teau-de-Ste-Croix",
    cantonShort: "VD",
    latitude: 46.8168,
    longitude: 6.5359,
  },
  {
    zipCode: 1452,
    city: "Les Rasses",
    cantonShort: "VD",
    latitude: 46.8382,
    longitude: 6.5353,
  },
  {
    zipCode: 1453,
    city: "Bullet",
    cantonShort: "VD",
    latitude: 46.8491,
    longitude: 6.5608,
  },
  {
    zipCode: 1453,
    city: "Mauborget",
    cantonShort: "VD",
    latitude: 46.8687,
    longitude: 6.609,
  },
  {
    zipCode: 1454,
    city: "L'Auberson",
    cantonShort: "VD",
    latitude: 46.8177,
    longitude: 6.46,
  },
  {
    zipCode: 1454,
    city: "La Vraconnaz",
    cantonShort: "VD",
    latitude: 46.8434,
    longitude: 6.481,
  },
  {
    zipCode: 1462,
    city: "Yvonand",
    cantonShort: "VD",
    latitude: 46.7989,
    longitude: 6.7367,
  },
  {
    zipCode: 1463,
    city: "Rovray",
    cantonShort: "VD",
    latitude: 46.7889,
    longitude: 6.7667,
  },
  {
    zipCode: 1464,
    city: "Chavannes-le-Ch\u00eane",
    cantonShort: "VD",
    latitude: 46.7801,
    longitude: 6.7809,
  },
  {
    zipCode: 1464,
    city: "Ch\u00eane-P\u00e2quier",
    cantonShort: "VD",
    latitude: 46.7659,
    longitude: 6.7706,
  },
  {
    zipCode: 1000,
    city: "Lausanne 27",
    cantonShort: "VD",
    latitude: 46.5416,
    longitude: 6.682,
  },
  {
    zipCode: 1000,
    city: "Lausanne 22",
    cantonShort: "VD",
    latitude: 46.5218,
    longitude: 6.633,
  },
  {
    zipCode: 1000,
    city: "Lausanne 10",
    cantonShort: "VD",
    latitude: 46.5218,
    longitude: 6.633,
  },
  {
    zipCode: 1000,
    city: "Lausanne 16",
    cantonShort: "VD",
    latitude: 46.5218,
    longitude: 6.633,
  },
  {
    zipCode: 1000,
    city: "Lausanne 25",
    cantonShort: "VD",
    latitude: 46.5726,
    longitude: 6.694,
  },
  {
    zipCode: 1000,
    city: "Lausanne 18",
    cantonShort: "VD",
    latitude: 46.5218,
    longitude: 6.633,
  },
  {
    zipCode: 1000,
    city: "Lausanne 12",
    cantonShort: "VD",
    latitude: 46.5218,
    longitude: 6.633,
  },
  {
    zipCode: 1000,
    city: "Lausanne 26",
    cantonShort: "VD",
    latitude: 46.5557,
    longitude: 6.6962,
  },
  {
    zipCode: 1000,
    city: "Lausanne 14",
    cantonShort: "VD",
    latitude: 46.5218,
    longitude: 6.633,
  },
  {
    zipCode: 1001,
    city: "Lausanne",
    cantonShort: "VD",
    latitude: 46.516,
    longitude: 6.6328,
  },
  {
    zipCode: 1002,
    city: "Lausanne",
    cantonShort: "VD",
    latitude: 46.516,
    longitude: 6.6328,
  },
  {
    zipCode: 1003,
    city: "Lausanne",
    cantonShort: "VD",
    latitude: 46.52,
    longitude: 6.6305,
  },
  {
    zipCode: 1004,
    city: "Lausanne",
    cantonShort: "VD",
    latitude: 46.5289,
    longitude: 6.6178,
  },
  {
    zipCode: 1005,
    city: "Lausanne",
    cantonShort: "VD",
    latitude: 46.5217,
    longitude: 6.6432,
  },
  {
    zipCode: 1006,
    city: "Lausanne",
    cantonShort: "VD",
    latitude: 46.4912,
    longitude: 6.6349,
  },
  {
    zipCode: 1007,
    city: "Lausanne",
    cantonShort: "VD",
    latitude: 46.488,
    longitude: 6.607,
  },
  {
    zipCode: 1008,
    city: "Jouxtens-M\u00e9zery",
    cantonShort: "VD",
    latitude: 46.5519,
    longitude: 6.596,
  },
  {
    zipCode: 1010,
    city: "Lausanne",
    cantonShort: "VD",
    latitude: 46.5377,
    longitude: 6.6553,
  },
  {
    zipCode: 1011,
    city: "Lausanne",
    cantonShort: "VD",
    latitude: 46.5256,
    longitude: 6.6433,
  },
  {
    zipCode: 1012,
    city: "Lausanne",
    cantonShort: "VD",
    latitude: 46.5271,
    longitude: 6.6566,
  },
  {
    zipCode: 1014,
    city: "Lausanne Adm cant VD",
    cantonShort: "VD",
    latitude: 46.5218,
    longitude: 6.633,
  },
  {
    zipCode: 1014,
    city: "Lausanne Adm cant",
    cantonShort: "VD",
    latitude: 46.5218,
    longitude: 6.633,
  },
  {
    zipCode: 1015,
    city: "Lausanne",
    cantonShort: "VD",
    latitude: 46.5192,
    longitude: 6.5762,
  },
  {
    zipCode: 1018,
    city: "Lausanne",
    cantonShort: "VD",
    latitude: 46.5381,
    longitude: 6.6279,
  },
  {
    zipCode: 1019,
    city: "Lausanne Services sp\u00e9ciaux",
    cantonShort: "VD",
    latitude: 46.5218,
    longitude: 6.633,
  },
  {
    zipCode: 1019,
    city: "Lausanne",
    cantonShort: "VD",
    latitude: 46.5218,
    longitude: 6.633,
  },
  {
    zipCode: 1032,
    city: "Romanel-sur-Lausanne",
    cantonShort: "VD",
    latitude: 46.5659,
    longitude: 6.6091,
  },
  {
    zipCode: 1033,
    city: "Cheseaux-sur-Lausanne",
    cantonShort: "VD",
    latitude: 46.5822,
    longitude: 6.5958,
  },
  {
    zipCode: 1039,
    city: "Cheseaux Polyval",
    cantonShort: "VD",
    latitude: 46.5845,
    longitude: 6.5955,
  },
  {
    zipCode: 1052,
    city: "Le Mont-sur-Lausanne",
    cantonShort: "VD",
    latitude: 46.5616,
    longitude: 6.6445,
  },
  {
    zipCode: 1066,
    city: "Epalinges",
    cantonShort: "VD",
    latitude: 46.5507,
    longitude: 6.6685,
  },
  {
    zipCode: 1009,
    city: "Pully",
    cantonShort: "VD",
    latitude: 46.4884,
    longitude: 6.6557,
  },
  {
    zipCode: 1068,
    city: "Les Monts-de-Pully",
    cantonShort: "VD",
    latitude: 46.5346,
    longitude: 6.6852,
  },
  {
    zipCode: 1070,
    city: "Puidoux",
    cantonShort: "VD",
    latitude: 46.4945,
    longitude: 6.7779,
  },
  {
    zipCode: 1071,
    city: "St-Saphorin (Lavaux)",
    cantonShort: "VD",
    latitude: 46.4554,
    longitude: 6.789,
  },
  {
    zipCode: 1071,
    city: "Rivaz",
    cantonShort: "VD",
    latitude: 46.458,
    longitude: 6.7747,
  },
  {
    zipCode: 1071,
    city: "Chexbres",
    cantonShort: "VD",
    latitude: 46.4835,
    longitude: 6.784,
  },
  {
    zipCode: 1072,
    city: "Forel (Lavaux)",
    cantonShort: "VD",
    latitude: 46.5381,
    longitude: 6.7631,
  },
  {
    zipCode: 1073,
    city: "Savigny",
    cantonShort: "VD",
    latitude: 46.5447,
    longitude: 6.723,
  },
  {
    zipCode: 1073,
    city: "Mollie-Margot",
    cantonShort: "VD",
    latitude: 46.56,
    longitude: 6.7377,
  },
  {
    zipCode: 1076,
    city: "Ferlens VD",
    cantonShort: "VD",
    latitude: 46.589,
    longitude: 6.7891,
  },
  {
    zipCode: 1077,
    city: "Servion",
    cantonShort: "VD",
    latitude: 46.5737,
    longitude: 6.7801,
  },
  {
    zipCode: 1078,
    city: "Essertes",
    cantonShort: "VD",
    latitude: 46.5645,
    longitude: 6.789,
  },
  {
    zipCode: 1080,
    city: "Les Cullayes",
    cantonShort: "VD",
    latitude: 46.5728,
    longitude: 6.7558,
  },
  {
    zipCode: 1081,
    city: "Montpreveyres",
    cantonShort: "VD",
    latitude: 46.5813,
    longitude: 6.7305,
  },
  {
    zipCode: 1083,
    city: "M\u00e9zi\u00e8res VD",
    cantonShort: "VD",
    latitude: 46.5871,
    longitude: 6.7656,
  },
  {
    zipCode: 1084,
    city: "Carrouge VD",
    cantonShort: "VD",
    latitude: 46.6027,
    longitude: 6.7728,
  },
  {
    zipCode: 1090,
    city: "La Croix (Lutry)",
    cantonShort: "VD",
    latitude: 46.5215,
    longitude: 6.7054,
  },
  {
    zipCode: 1091,
    city: "Aran",
    cantonShort: "VD",
    latitude: 46.5081,
    longitude: 6.7154,
  },
  {
    zipCode: 1091,
    city: "Grandvaux",
    cantonShort: "VD",
    latitude: 46.5045,
    longitude: 6.7261,
  },
  {
    zipCode: 1091,
    city: "Chenaux",
    cantonShort: "VD",
    latitude: 46.4954,
    longitude: 6.7307,
  },
  {
    zipCode: 1092,
    city: "Belmont-sur-Lausanne",
    cantonShort: "VD",
    latitude: 46.5265,
    longitude: 6.6876,
  },
  {
    zipCode: 1093,
    city: "La Conversion",
    cantonShort: "VD",
    latitude: 46.5141,
    longitude: 6.6858,
  },
  {
    zipCode: 1094,
    city: "Paudex",
    cantonShort: "VD",
    latitude: 46.4828,
    longitude: 6.6715,
  },
  {
    zipCode: 1095,
    city: "Lutry",
    cantonShort: "VD",
    latitude: 46.4875,
    longitude: 6.6881,
  },
  {
    zipCode: 1096,
    city: "Villette (Lavaux)",
    cantonShort: "VD",
    latitude: 46.4744,
    longitude: 6.7009,
  },
  {
    zipCode: 1096,
    city: "Cully",
    cantonShort: "VD",
    latitude: 46.4699,
    longitude: 6.7261,
  },
  {
    zipCode: 1097,
    city: "Riex",
    cantonShort: "VD",
    latitude: 46.5008,
    longitude: 6.7406,
  },
  {
    zipCode: 1098,
    city: "Epesses",
    cantonShort: "VD",
    latitude: 46.4966,
    longitude: 6.7497,
  },
  {
    zipCode: 1607,
    city: "Pal\u00e9zieux",
    cantonShort: "VD",
    latitude: 46.5441,
    longitude: 6.8402,
  },
  {
    zipCode: 1607,
    city: "Les Tavernes",
    cantonShort: "VD",
    latitude: 46.5533,
    longitude: 6.8097,
  },
  {
    zipCode: 1607,
    city: "Pal\u00e9zieux-Village",
    cantonShort: "VD",
    latitude: 46.5536,
    longitude: 6.8358,
  },
  {
    zipCode: 1607,
    city: "Les Thioleyres",
    cantonShort: "VD",
    latitude: 46.5384,
    longitude: 6.8148,
  },
  {
    zipCode: 1608,
    city: "Oron-le-Ch\u00e2tel",
    cantonShort: "VD",
    latitude: 46.5724,
    longitude: 6.8413,
  },
  {
    zipCode: 1608,
    city: "Bussigny-sur-Oron",
    cantonShort: "VD",
    latitude: 46.5693,
    longitude: 6.8629,
  },
  {
    zipCode: 1608,
    city: "Chesalles-sur-Oron",
    cantonShort: "VD",
    latitude: 46.5748,
    longitude: 6.8527,
  },
  {
    zipCode: 1610,
    city: "Oron-la-Ville",
    cantonShort: "VD",
    latitude: 46.5715,
    longitude: 6.8236,
  },
  {
    zipCode: 1610,
    city: "Vuibroye",
    cantonShort: "VD",
    latitude: 46.5711,
    longitude: 6.8052,
  },
  {
    zipCode: 1610,
    city: "Ch\u00e2tillens",
    cantonShort: "VD",
    latitude: 46.5619,
    longitude: 6.8054,
  },
  {
    zipCode: 1612,
    city: "Ecoteaux",
    cantonShort: "VD",
    latitude: 46.5494,
    longitude: 6.8578,
  },
  {
    zipCode: 1613,
    city: "Maracon",
    cantonShort: "VD",
    latitude: 46.5585,
    longitude: 6.879,
  },
  {
    zipCode: 1026,
    city: "Echandens",
    cantonShort: "VD",
    latitude: 46.5397,
    longitude: 6.5379,
  },
  {
    zipCode: 1026,
    city: "Denges",
    cantonShort: "VD",
    latitude: 46.5234,
    longitude: 6.539,
  },
  {
    zipCode: 1026,
    city: "Echandens-Denges",
    cantonShort: "VD",
    latitude: 46.5397,
    longitude: 6.5379,
  },
  {
    zipCode: 1027,
    city: "Lonay",
    cantonShort: "VD",
    latitude: 46.5299,
    longitude: 6.517,
  },
  {
    zipCode: 1028,
    city: "Pr\u00e9verenges",
    cantonShort: "VD",
    latitude: 46.4941,
    longitude: 6.5291,
  },
  {
    zipCode: 1110,
    city: "Morges",
    cantonShort: "VD",
    latitude: 46.495,
    longitude: 6.5055,
  },
  {
    zipCode: 1110,
    city: "Morges 1",
    cantonShort: "VD",
    latitude: 46.514,
    longitude: 6.4932,
  },
  {
    zipCode: 1110,
    city: "Morges 2",
    cantonShort: "VD",
    latitude: 46.514,
    longitude: 6.4932,
  },
  {
    zipCode: 1112,
    city: "Echichens",
    cantonShort: "VD",
    latitude: 46.5303,
    longitude: 6.4968,
  },
  {
    zipCode: 1113,
    city: "St-Saphorin-sur-Morges",
    cantonShort: "VD",
    latitude: 46.5462,
    longitude: 6.4917,
  },
  {
    zipCode: 1114,
    city: "Colombier VD",
    cantonShort: "VD",
    latitude: 46.5552,
    longitude: 6.4678,
  },
  {
    zipCode: 1115,
    city: "Vullierens",
    cantonShort: "VD",
    latitude: 46.5734,
    longitude: 6.4808,
  },
  {
    zipCode: 1116,
    city: "Cottens VD",
    cantonShort: "VD",
    latitude: 46.5743,
    longitude: 6.4533,
  },
  {
    zipCode: 1117,
    city: "Grancy",
    cantonShort: "VD",
    latitude: 46.5936,
    longitude: 6.4609,
  },
  {
    zipCode: 1121,
    city: "Bremblens",
    cantonShort: "VD",
    latitude: 46.5483,
    longitude: 6.5194,
  },
  {
    zipCode: 1122,
    city: "Romanel-sur-Morges",
    cantonShort: "VD",
    latitude: 46.5566,
    longitude: 6.5106,
  },
  {
    zipCode: 1123,
    city: "Aclens",
    cantonShort: "VD",
    latitude: 46.5683,
    longitude: 6.5109,
  },
  {
    zipCode: 1124,
    city: "Gollion",
    cantonShort: "VD",
    latitude: 46.586,
    longitude: 6.512,
  },
  {
    zipCode: 1125,
    city: "Monnaz",
    cantonShort: "VD",
    latitude: 46.533,
    longitude: 6.4798,
  },
  {
    zipCode: 1126,
    city: "Vaux-sur-Morges",
    cantonShort: "VD",
    latitude: 46.5385,
    longitude: 6.4639,
  },
  {
    zipCode: 1127,
    city: "Clarmont",
    cantonShort: "VD",
    latitude: 46.5486,
    longitude: 6.4496,
  },
  {
    zipCode: 1128,
    city: "Reverolle",
    cantonShort: "VD",
    latitude: 46.5417,
    longitude: 6.44,
  },
  {
    zipCode: 1131,
    city: "Tolochenaz",
    cantonShort: "VD",
    latitude: 46.4849,
    longitude: 6.4921,
  },
  {
    zipCode: 1132,
    city: "Lully VD",
    cantonShort: "VD",
    latitude: 46.5063,
    longitude: 6.4644,
  },
  {
    zipCode: 1134,
    city: "Vufflens-le-Ch\u00e2teau",
    cantonShort: "VD",
    latitude: 46.5258,
    longitude: 6.4667,
  },
  {
    zipCode: 1134,
    city: "Chigny",
    cantonShort: "VD",
    latitude: 46.5162,
    longitude: 6.4767,
  },
  {
    zipCode: 1135,
    city: "Denens",
    cantonShort: "VD",
    latitude: 46.5174,
    longitude: 6.4526,
  },
  {
    zipCode: 1136,
    city: "Bussy-Chardonney",
    cantonShort: "VD",
    latitude: 46.5309,
    longitude: 6.441,
  },
  {
    zipCode: 1141,
    city: "S\u00e9very",
    cantonShort: "VD",
    latitude: 46.5707,
    longitude: 6.4421,
  },
  {
    zipCode: 1142,
    city: "Pampigny",
    cantonShort: "VD",
    latitude: 46.5862,
    longitude: 6.422,
  },
  {
    zipCode: 1143,
    city: "Apples",
    cantonShort: "VD",
    latitude: 46.555,
    longitude: 6.4166,
  },
  {
    zipCode: 1144,
    city: "Ballens",
    cantonShort: "VD",
    latitude: 46.5535,
    longitude: 6.378,
  },
  {
    zipCode: 1145,
    city: "Bi\u00e8re",
    cantonShort: "VD",
    latitude: 46.5474,
    longitude: 6.3185,
  },
  {
    zipCode: 1146,
    city: "Mollens VD",
    cantonShort: "VD",
    latitude: 46.5787,
    longitude: 6.3553,
  },
  {
    zipCode: 1147,
    city: "Montricher",
    cantonShort: "VD",
    latitude: 46.6033,
    longitude: 6.3588,
  },
  {
    zipCode: 1148,
    city: "L'Isle",
    cantonShort: "VD",
    latitude: 46.6245,
    longitude: 6.3905,
  },
  {
    zipCode: 1148,
    city: "La Coudre",
    cantonShort: "VD",
    latitude: 46.6342,
    longitude: 6.3987,
  },
  {
    zipCode: 1148,
    city: "Mont-la-Ville",
    cantonShort: "VD",
    latitude: 46.6532,
    longitude: 6.3876,
  },
  {
    zipCode: 1148,
    city: "Mauraz",
    cantonShort: "VD",
    latitude: 46.603,
    longitude: 6.4165,
  },
  {
    zipCode: 1148,
    city: "Moiry VD",
    cantonShort: "VD",
    latitude: 46.6534,
    longitude: 6.4528,
  },
  {
    zipCode: 1148,
    city: "Villars-Bozon",
    cantonShort: "VD",
    latitude: 46.6079,
    longitude: 6.4094,
  },
  {
    zipCode: 1148,
    city: "Chavannes-le-Veyron",
    cantonShort: "VD",
    latitude: 46.6089,
    longitude: 6.447,
  },
  {
    zipCode: 1148,
    city: "Cuarnens",
    cantonShort: "VD",
    latitude: 46.6311,
    longitude: 6.4359,
  },
  {
    zipCode: 1149,
    city: "Berolle",
    cantonShort: "VD",
    latitude: 46.5682,
    longitude: 6.3286,
  },
  {
    zipCode: 1162,
    city: "St-Prex",
    cantonShort: "VD",
    latitude: 46.4683,
    longitude: 6.4693,
  },
  {
    zipCode: 1163,
    city: "Etoy",
    cantonShort: "VD",
    latitude: 46.4841,
    longitude: 6.4217,
  },
  {
    zipCode: 1164,
    city: "Buchillon",
    cantonShort: "VD",
    latitude: 46.4468,
    longitude: 6.4286,
  },
  {
    zipCode: 1165,
    city: "Allaman",
    cantonShort: "VD",
    latitude: 46.442,
    longitude: 6.3983,
  },
  {
    zipCode: 1167,
    city: "Lussy-sur-Morges",
    cantonShort: "VD",
    latitude: 46.501,
    longitude: 6.4481,
  },
  {
    zipCode: 1168,
    city: "Villars-sous-Yens",
    cantonShort: "VD",
    latitude: 46.5069,
    longitude: 6.4301,
  },
  {
    zipCode: 1169,
    city: "Yens",
    cantonShort: "VD",
    latitude: 46.527,
    longitude: 6.4067,
  },
  {
    zipCode: 1170,
    city: "Aubonne",
    cantonShort: "VD",
    latitude: 46.491,
    longitude: 6.3856,
  },
  {
    zipCode: 1172,
    city: "Bougy-Villars",
    cantonShort: "VD",
    latitude: 46.4838,
    longitude: 6.3508,
  },
  {
    zipCode: 1173,
    city: "F\u00e9chy",
    cantonShort: "VD",
    latitude: 46.479,
    longitude: 6.3727,
  },
  {
    zipCode: 1174,
    city: "Pizy",
    cantonShort: "VD",
    latitude: 46.4935,
    longitude: 6.3467,
  },
  {
    zipCode: 1174,
    city: "Montherod",
    cantonShort: "VD",
    latitude: 46.5059,
    longitude: 6.3541,
  },
  {
    zipCode: 1175,
    city: "Lavigny",
    cantonShort: "VD",
    latitude: 46.5015,
    longitude: 6.4081,
  },
  {
    zipCode: 1176,
    city: "St-Livres",
    cantonShort: "VD",
    latitude: 46.5176,
    longitude: 6.3812,
  },
  {
    zipCode: 1187,
    city: "St-Oyens",
    cantonShort: "VD",
    latitude: 46.4949,
    longitude: 6.2983,
  },
  {
    zipCode: 1188,
    city: "Gimel",
    cantonShort: "VD",
    latitude: 46.5267,
    longitude: 6.2895,
  },
  {
    zipCode: 1189,
    city: "Saubraz",
    cantonShort: "VD",
    latitude: 46.5184,
    longitude: 6.3268,
  },
  {
    zipCode: 1304,
    city: "Senarclens",
    cantonShort: "VD",
    latitude: 46.5992,
    longitude: 6.4847,
  },
  {
    zipCode: 1304,
    city: "Allens",
    cantonShort: "VD",
    latitude: 46.5985,
    longitude: 6.5078,
  },
  {
    zipCode: 1304,
    city: "Cossonay-Ville",
    cantonShort: "VD",
    latitude: 46.6158,
    longitude: 6.5025,
  },
  {
    zipCode: 1304,
    city: "Dizy",
    cantonShort: "VD",
    latitude: 46.6344,
    longitude: 6.4949,
  },
  {
    zipCode: 1308,
    city: "La Chaux (Cossonay)",
    cantonShort: "VD",
    latitude: 46.6225,
    longitude: 6.4682,
  },
  {
    zipCode: 1312,
    city: "Ecl\u00e9pens",
    cantonShort: "VD",
    latitude: 46.6516,
    longitude: 6.5431,
  },
  {
    zipCode: 1313,
    city: "Ferreyres",
    cantonShort: "VD",
    latitude: 46.6602,
    longitude: 6.4795,
  },
  {
    zipCode: 1315,
    city: "La Sarraz",
    cantonShort: "VD",
    latitude: 46.6545,
    longitude: 6.5048,
  },
  {
    zipCode: 1316,
    city: "Chevilly",
    cantonShort: "VD",
    latitude: 46.6433,
    longitude: 6.4751,
  },
  {
    zipCode: 1317,
    city: "Orny",
    cantonShort: "VD",
    latitude: 46.6707,
    longitude: 6.5366,
  },
  {
    zipCode: 1318,
    city: "Pompaples",
    cantonShort: "VD",
    latitude: 46.6768,
    longitude: 6.5213,
  },
  {
    zipCode: 1166,
    city: "Perroy",
    cantonShort: "VD",
    latitude: 46.4427,
    longitude: 6.3706,
  },
  {
    zipCode: 1180,
    city: "Tartegnin",
    cantonShort: "VD",
    latitude: 46.468,
    longitude: 6.3133,
  },
  {
    zipCode: 1180,
    city: "Rolle",
    cantonShort: "VD",
    latitude: 46.4416,
    longitude: 6.3406,
  },
  {
    zipCode: 1182,
    city: "Gilly",
    cantonShort: "VD",
    latitude: 46.4631,
    longitude: 6.2983,
  },
  {
    zipCode: 1183,
    city: "Bursins",
    cantonShort: "VD",
    latitude: 46.4477,
    longitude: 6.2899,
  },
  {
    zipCode: 1184,
    city: "Luins",
    cantonShort: "VD",
    latitude: 46.4415,
    longitude: 6.2754,
  },
  {
    zipCode: 1184,
    city: "Vinzel",
    cantonShort: "VD",
    latitude: 46.4481,
    longitude: 6.279,
  },
  {
    zipCode: 1185,
    city: "Mont-sur-Rolle",
    cantonShort: "VD",
    latitude: 46.4754,
    longitude: 6.3364,
  },
  {
    zipCode: 1186,
    city: "Essertines-sur-Rolle",
    cantonShort: "VD",
    latitude: 46.4851,
    longitude: 6.3105,
  },
  {
    zipCode: 1188,
    city: "St-George",
    cantonShort: "VD",
    latitude: 46.5226,
    longitude: 6.2577,
  },
  {
    zipCode: 1195,
    city: "Bursinel",
    cantonShort: "VD",
    latitude: 46.4266,
    longitude: 6.3248,
  },
  {
    zipCode: 1195,
    city: "Dully",
    cantonShort: "VD",
    latitude: 46.4201,
    longitude: 6.31,
  },
  {
    zipCode: 1196,
    city: "Gland",
    cantonShort: "VD",
    latitude: 46.4104,
    longitude: 6.2867,
  },
  {
    zipCode: 1197,
    city: "Prangins",
    cantonShort: "VD",
    latitude: 46.3922,
    longitude: 6.2625,
  },
  {
    zipCode: 1260,
    city: "Nyon",
    cantonShort: "VD",
    latitude: 46.3803,
    longitude: 6.2352,
  },
  {
    zipCode: 1260,
    city: "Nyon 2",
    cantonShort: "VD",
    latitude: 46.3863,
    longitude: 6.2264,
  },
  {
    zipCode: 1260,
    city: "Nyon 1",
    cantonShort: "VD",
    latitude: 46.3863,
    longitude: 6.2264,
  },
  {
    zipCode: 1261,
    city: "Marchissy",
    cantonShort: "VD",
    latitude: 46.4981,
    longitude: 6.226,
  },
  {
    zipCode: 1261,
    city: "Longirod",
    cantonShort: "VD",
    latitude: 46.5041,
    longitude: 6.2522,
  },
  {
    zipCode: 1261,
    city: "Le Vaud",
    cantonShort: "VD",
    latitude: 46.4774,
    longitude: 6.2337,
  },
  {
    zipCode: 1262,
    city: "Eysins",
    cantonShort: "VD",
    latitude: 46.3813,
    longitude: 6.2051,
  },
  {
    zipCode: 1263,
    city: "Crassier",
    cantonShort: "VD",
    latitude: 46.376,
    longitude: 6.1681,
  },
  {
    zipCode: 1264,
    city: "St-Cergue",
    cantonShort: "VD",
    latitude: 46.451,
    longitude: 6.1414,
  },
  {
    zipCode: 1265,
    city: "La Cure",
    cantonShort: "VD",
    latitude: 46.4611,
    longitude: 6.0935,
  },
  {
    zipCode: 1266,
    city: "Duillier",
    cantonShort: "VD",
    latitude: 46.4092,
    longitude: 6.2297,
  },
  {
    zipCode: 1267,
    city: "Vich",
    cantonShort: "VD",
    latitude: 46.4281,
    longitude: 6.2494,
  },
  {
    zipCode: 1267,
    city: "Coinsins",
    cantonShort: "VD",
    latitude: 46.4234,
    longitude: 6.2374,
  },
  {
    zipCode: 1268,
    city: "Begnins",
    cantonShort: "VD",
    latitude: 46.4468,
    longitude: 6.2533,
  },
  {
    zipCode: 1268,
    city: "Burtigny",
    cantonShort: "VD",
    latitude: 46.4695,
    longitude: 6.2675,
  },
  {
    zipCode: 1269,
    city: "Bassins",
    cantonShort: "VD",
    latitude: 46.4895,
    longitude: 6.199,
  },
  {
    zipCode: 1270,
    city: "Tr\u00e9lex",
    cantonShort: "VD",
    latitude: 46.4189,
    longitude: 6.1966,
  },
  {
    zipCode: 1271,
    city: "Givrins",
    cantonShort: "VD",
    latitude: 46.4328,
    longitude: 6.1962,
  },
  {
    zipCode: 1272,
    city: "Genolier",
    cantonShort: "VD",
    latitude: 46.4362,
    longitude: 6.2204,
  },
  {
    zipCode: 1273,
    city: "Arzier-Le Muids",
    cantonShort: "VD",
    latitude: 46.4883,
    longitude: 6.1599,
  },
  {
    zipCode: 1274,
    city: "Grens",
    cantonShort: "VD",
    latitude: 46.3966,
    longitude: 6.1917,
  },
  {
    zipCode: 1274,
    city: "Signy",
    cantonShort: "VD",
    latitude: 46.3928,
    longitude: 6.2039,
  },
  {
    zipCode: 1274,
    city: "Signy-Centre",
    cantonShort: "VD",
    latitude: 46.3926,
    longitude: 6.2042,
  },
  {
    zipCode: 1275,
    city: "Ch\u00e9serex",
    cantonShort: "VD",
    latitude: 46.4109,
    longitude: 6.1479,
  },
  {
    zipCode: 1276,
    city: "Gingins",
    cantonShort: "VD",
    latitude: 46.4262,
    longitude: 6.1393,
  },
  {
    zipCode: 1277,
    city: "Borex",
    cantonShort: "VD",
    latitude: 46.381,
    longitude: 6.1792,
  },
  {
    zipCode: 1277,
    city: "Arnex-sur-Nyon",
    cantonShort: "VD",
    latitude: 46.3729,
    longitude: 6.1898,
  },
  {
    zipCode: 1278,
    city: "La Rippe",
    cantonShort: "VD",
    latitude: 46.407,
    longitude: 6.1167,
  },
  {
    zipCode: 1279,
    city: "Chavannes-de-Bogis",
    cantonShort: "VD",
    latitude: 46.34,
    longitude: 6.1545,
  },
  {
    zipCode: 1279,
    city: "Bogis-Bossey",
    cantonShort: "VD",
    latitude: 46.3538,
    longitude: 6.1716,
  },
  {
    zipCode: 1290,
    city: "Chavannes-des-Bois",
    cantonShort: "VD",
    latitude: 46.3169,
    longitude: 6.1367,
  },
  {
    zipCode: 1291,
    city: "Commugny",
    cantonShort: "VD",
    latitude: 46.3236,
    longitude: 6.1609,
  },
  {
    zipCode: 1295,
    city: "Tannay",
    cantonShort: "VD",
    latitude: 46.3072,
    longitude: 6.1811,
  },
  {
    zipCode: 1295,
    city: "Mies",
    cantonShort: "VD",
    latitude: 46.2995,
    longitude: 6.1746,
  },
  {
    zipCode: 1296,
    city: "Coppet",
    cantonShort: "VD",
    latitude: 46.316,
    longitude: 6.2006,
  },
  {
    zipCode: 1297,
    city: "Founex",
    cantonShort: "VD",
    latitude: 46.3331,
    longitude: 6.1976,
  },
  {
    zipCode: 1299,
    city: "Crans-pr\u00e8s-C\u00e9ligny",
    cantonShort: "VD",
    latitude: 46.3591,
    longitude: 6.2058,
  },
  {
    zipCode: 1299,
    city: "Crans VD",
    cantonShort: "VD",
    latitude: 46.36,
    longitude: 6.2196,
  },
  {
    zipCode: 1008,
    city: "Prilly",
    cantonShort: "VD",
    latitude: 46.5389,
    longitude: 6.605,
  },
  {
    zipCode: 1020,
    city: "Renens VD",
    cantonShort: "VD",
    latitude: 46.5353,
    longitude: 6.5897,
  },
  {
    zipCode: 1020,
    city: "Renens VD 1",
    cantonShort: "VD",
    latitude: 46.5353,
    longitude: 6.5897,
  },
  {
    zipCode: 1022,
    city: "Chavannes-pr\u00e8s-Renens",
    cantonShort: "VD",
    latitude: 46.5285,
    longitude: 6.5758,
  },
  {
    zipCode: 1023,
    city: "Crissier 1",
    cantonShort: "VD",
    latitude: 46.5585,
    longitude: 6.5774,
  },
  {
    zipCode: 1023,
    city: "Crissier",
    cantonShort: "VD",
    latitude: 46.5563,
    longitude: 6.5777,
  },
  {
    zipCode: 1024,
    city: "Ecublens VD",
    cantonShort: "VD",
    latitude: 46.5288,
    longitude: 6.5586,
  },
  {
    zipCode: 1025,
    city: "St-Sulpice VD",
    cantonShort: "VD",
    latitude: 46.4855,
    longitude: 6.5626,
  },
  {
    zipCode: 1029,
    city: "Villars-Ste-Croix",
    cantonShort: "VD",
    latitude: 46.5674,
    longitude: 6.5632,
  },
  {
    zipCode: 1030,
    city: "Bussigny",
    cantonShort: "VD",
    latitude: 46.5534,
    longitude: 6.5508,
  },
  {
    zipCode: 1658,
    city: "La Tine",
    cantonShort: "VD",
    latitude: 46.4712,
    longitude: 7.0479,
  },
  {
    zipCode: 1658,
    city: "Rossini\u00e8re",
    cantonShort: "VD",
    latitude: 46.4642,
    longitude: 7.0639,
  },
  {
    zipCode: 1659,
    city: "Rougemont",
    cantonShort: "VD",
    latitude: 46.4959,
    longitude: 7.2146,
  },
  {
    zipCode: 1659,
    city: "Flendruz",
    cantonShort: "VD",
    latitude: 46.5139,
    longitude: 7.1743,
  },
  {
    zipCode: 1660,
    city: "Ch\u00e2teau-d'Oex",
    cantonShort: "VD",
    latitude: 46.4703,
    longitude: 7.1501,
  },
  {
    zipCode: 1660,
    city: "L'Etivaz",
    cantonShort: "VD",
    latitude: 46.4117,
    longitude: 7.1644,
  },
  {
    zipCode: 1660,
    city: "La L\u00e9cherette",
    cantonShort: "VD",
    latitude: 46.4237,
    longitude: 7.0752,
  },
  {
    zipCode: 1660,
    city: "Les Moulins",
    cantonShort: "VD",
    latitude: 46.4531,
    longitude: 7.0998,
  },
  {
    zipCode: 1800,
    city: "Vevey 1",
    cantonShort: "VD",
    latitude: 46.4642,
    longitude: 6.8481,
  },
  {
    zipCode: 1800,
    city: "Vevey",
    cantonShort: "VD",
    latitude: 46.4496,
    longitude: 6.8373,
  },
  {
    zipCode: 1801,
    city: "Le Mont-P\u00e8lerin",
    cantonShort: "VD",
    latitude: 46.492,
    longitude: 6.8239,
  },
  {
    zipCode: 1802,
    city: "Corseaux",
    cantonShort: "VD",
    latitude: 46.4523,
    longitude: 6.817,
  },
  {
    zipCode: 1803,
    city: "Chardonne",
    cantonShort: "VD",
    latitude: 46.4649,
    longitude: 6.8109,
  },
  {
    zipCode: 1804,
    city: "Corsier-sur-Vevey",
    cantonShort: "VD",
    latitude: 46.4767,
    longitude: 6.8505,
  },
  {
    zipCode: 1805,
    city: "Jongny",
    cantonShort: "VD",
    latitude: 46.4877,
    longitude: 6.8464,
  },
  {
    zipCode: 1806,
    city: "St-L\u00e9gier-La Chi\u00e9saz",
    cantonShort: "VD",
    latitude: 46.4899,
    longitude: 6.8982,
  },
  {
    zipCode: 1807,
    city: "Blonay",
    cantonShort: "VD",
    latitude: 46.4778,
    longitude: 6.922,
  },
  {
    zipCode: 1808,
    city: "Les Monts-de-Corsier",
    cantonShort: "VD",
    latitude: 46.5016,
    longitude: 6.8776,
  },
  {
    zipCode: 1809,
    city: "Fenil-sur-Corsier",
    cantonShort: "VD",
    latitude: 46.4851,
    longitude: 6.8675,
  },
  {
    zipCode: 1811,
    city: "Vevey",
    cantonShort: "VD",
    latitude: 46.4642,
    longitude: 6.8481,
  },
  {
    zipCode: 1811,
    city: "Vevey Services sp\u00e9ciaux",
    cantonShort: "VD",
    latitude: 46.4642,
    longitude: 6.8481,
  },
  {
    zipCode: 1814,
    city: "La Tour-de-Peilz",
    cantonShort: "VD",
    latitude: 46.4405,
    longitude: 6.859,
  },
  {
    zipCode: 1815,
    city: "Clarens",
    cantonShort: "VD",
    latitude: 46.4315,
    longitude: 6.8825,
  },
  {
    zipCode: 1816,
    city: "Chailly-Montreux",
    cantonShort: "VD",
    latitude: 46.4528,
    longitude: 6.8921,
  },
  {
    zipCode: 1817,
    city: "Brent",
    cantonShort: "VD",
    latitude: 46.4547,
    longitude: 6.9035,
  },
  {
    zipCode: 1818,
    city: "Montreux La Redoute",
    cantonShort: "VD",
    latitude: 46.4518,
    longitude: 6.9408,
  },
  {
    zipCode: 1818,
    city: "Montreux Redoute",
    cantonShort: "VD",
    latitude: 46.4518,
    longitude: 6.9408,
  },
  {
    zipCode: 1820,
    city: "Montreux",
    cantonShort: "VD",
    latitude: 46.4268,
    longitude: 6.9026,
  },
  {
    zipCode: 1820,
    city: "Territet",
    cantonShort: "VD",
    latitude: 46.4243,
    longitude: 6.9205,
  },
  {
    zipCode: 1820,
    city: "Montreux 1",
    cantonShort: "VD",
    latitude: 46.4518,
    longitude: 6.9408,
  },
  {
    zipCode: 1820,
    city: "Montreux 2",
    cantonShort: "VD",
    latitude: 46.4518,
    longitude: 6.9408,
  },
  {
    zipCode: 1820,
    city: "Veytaux",
    cantonShort: "VD",
    latitude: 46.4231,
    longitude: 6.9485,
  },
  {
    zipCode: 1822,
    city: "Chernex",
    cantonShort: "VD",
    latitude: 46.4425,
    longitude: 6.9163,
  },
  {
    zipCode: 1823,
    city: "Glion",
    cantonShort: "VD",
    latitude: 46.4375,
    longitude: 6.9367,
  },
  {
    zipCode: 1824,
    city: "Caux",
    cantonShort: "VD",
    latitude: 46.4425,
    longitude: 6.9648,
  },
  {
    zipCode: 1832,
    city: "Villard-sur-Chamby",
    cantonShort: "VD",
    latitude: 46.4664,
    longitude: 6.9311,
  },
  {
    zipCode: 1832,
    city: "Chamby",
    cantonShort: "VD",
    latitude: 46.4524,
    longitude: 6.9192,
  },
  {
    zipCode: 1833,
    city: "Les Avants",
    cantonShort: "VD",
    latitude: 46.4648,
    longitude: 6.9542,
  },
  {
    zipCode: 3900,
    city: "Gamsen",
    cantonShort: "VS",
    latitude: 46.2996,
    longitude: 7.9443,
  },
  {
    zipCode: 3900,
    city: "Brig",
    cantonShort: "VS",
    latitude: 46.2837,
    longitude: 7.9866,
  },
  {
    zipCode: 3900,
    city: "Brigerbad",
    cantonShort: "VS",
    latitude: 46.3021,
    longitude: 7.9255,
  },
  {
    zipCode: 3901,
    city: "Rothwald",
    cantonShort: "VS",
    latitude: 46.277,
    longitude: 8.0802,
  },
  {
    zipCode: 3902,
    city: "Glis",
    cantonShort: "VS",
    latitude: 46.3087,
    longitude: 7.9792,
  },
  {
    zipCode: 3903,
    city: "Mund",
    cantonShort: "VS",
    latitude: 46.3508,
    longitude: 7.9229,
  },
  {
    zipCode: 3903,
    city: "Birgisch",
    cantonShort: "VS",
    latitude: 46.3306,
    longitude: 7.9617,
  },
  {
    zipCode: 3904,
    city: "Naters",
    cantonShort: "VS",
    latitude: 46.3367,
    longitude: 7.9852,
  },
  {
    zipCode: 3907,
    city: "Gabi (Simplon)",
    cantonShort: "VS",
    latitude: 46.1838,
    longitude: 8.0786,
  },
  {
    zipCode: 3907,
    city: "Gondo",
    cantonShort: "VS",
    latitude: 46.163,
    longitude: 8.0992,
  },
  {
    zipCode: 3907,
    city: "Simplon Dorf",
    cantonShort: "VS",
    latitude: 46.2007,
    longitude: 8.0368,
  },
  {
    zipCode: 3911,
    city: "Ried-Brig",
    cantonShort: "VS",
    latitude: 46.302,
    longitude: 8.039,
  },
  {
    zipCode: 3912,
    city: "Termen",
    cantonShort: "VS",
    latitude: 46.33,
    longitude: 8.0431,
  },
  {
    zipCode: 3913,
    city: "Rosswald",
    cantonShort: "VS",
    latitude: 46.3128,
    longitude: 8.0752,
  },
  {
    zipCode: 3914,
    city: "Blatten b. Naters",
    cantonShort: "VS",
    latitude: 46.364,
    longitude: 7.9762,
  },
  {
    zipCode: 3914,
    city: "Belalp",
    cantonShort: "VS",
    latitude: 46.4208,
    longitude: 7.9835,
  },
  {
    zipCode: 3939,
    city: "Eggerberg",
    cantonShort: "VS",
    latitude: 46.3159,
    longitude: 7.8888,
  },
  {
    zipCode: 1911,
    city: "Mayens-de-Chamoson",
    cantonShort: "VS",
    latitude: 46.2034,
    longitude: 7.1843,
  },
  {
    zipCode: 1955,
    city: "Chamoson",
    cantonShort: "VS",
    latitude: 46.2016,
    longitude: 7.2204,
  },
  {
    zipCode: 1955,
    city: "Mayens-de-Chamoson",
    cantonShort: "VS",
    latitude: 46.2232,
    longitude: 7.1659,
  },
  {
    zipCode: 1955,
    city: "Les V\u00e9rines (Chamoson)",
    cantonShort: "VS",
    latitude: 46.2235,
    longitude: 7.202,
  },
  {
    zipCode: 1955,
    city: "Grugnay (Chamoson)",
    cantonShort: "VS",
    latitude: 46.2083,
    longitude: 7.2148,
  },
  {
    zipCode: 1955,
    city: "St-Pierre-de-Clages",
    cantonShort: "VS",
    latitude: 46.1898,
    longitude: 7.2408,
  },
  {
    zipCode: 1955,
    city: "N\u00e9miaz (Chamoson)",
    cantonShort: "VS",
    latitude: 46.2141,
    longitude: 7.226,
  },
  {
    zipCode: 1957,
    city: "Ardon",
    cantonShort: "VS",
    latitude: 46.23,
    longitude: 7.2379,
  },
  {
    zipCode: 1963,
    city: "V\u00e9troz",
    cantonShort: "VS",
    latitude: 46.2163,
    longitude: 7.2806,
  },
  {
    zipCode: 1964,
    city: "Conthey",
    cantonShort: "VS",
    latitude: 46.2214,
    longitude: 7.303,
  },
  {
    zipCode: 1975,
    city: "St-S\u00e9verin",
    cantonShort: "VS",
    latitude: 46.2393,
    longitude: 7.304,
  },
  {
    zipCode: 1976,
    city: "Daillon",
    cantonShort: "VS",
    latitude: 46.2562,
    longitude: 7.3096,
  },
  {
    zipCode: 1976,
    city: "Aven",
    cantonShort: "VS",
    latitude: 46.2745,
    longitude: 7.2252,
  },
  {
    zipCode: 1976,
    city: "Erde",
    cantonShort: "VS",
    latitude: 46.275,
    longitude: 7.2929,
  },
  {
    zipCode: 1993,
    city: "Cl\u00e8bes (Nendaz)",
    cantonShort: "VS",
    latitude: 46.1838,
    longitude: 7.3486,
  },
  {
    zipCode: 1994,
    city: "Aproz (Nendaz)",
    cantonShort: "VS",
    latitude: 46.2038,
    longitude: 7.307,
  },
  {
    zipCode: 1996,
    city: "Basse-Nendaz",
    cantonShort: "VS",
    latitude: 46.1929,
    longitude: 7.3105,
  },
  {
    zipCode: 1996,
    city: "Baar (Nendaz)",
    cantonShort: "VS",
    latitude: 46.2047,
    longitude: 7.3289,
  },
  {
    zipCode: 1996,
    city: "Fey (Nendaz)",
    cantonShort: "VS",
    latitude: 46.1874,
    longitude: 7.2737,
  },
  {
    zipCode: 1996,
    city: "Saclentse",
    cantonShort: "VS",
    latitude: 46.1735,
    longitude: 7.3176,
  },
  {
    zipCode: 1996,
    city: "Brignon (Nendaz)",
    cantonShort: "VS",
    latitude: 46.1949,
    longitude: 7.3264,
  },
  {
    zipCode: 1996,
    city: "Cond\u00e9mines (Nendaz)",
    cantonShort: "VS",
    latitude: 46.1772,
    longitude: 7.2597,
  },
  {
    zipCode: 1996,
    city: "Beuson (Nendaz)",
    cantonShort: "VS",
    latitude: 46.1743,
    longitude: 7.3335,
  },
  {
    zipCode: 1996,
    city: "Bieudron (Nendaz)",
    cantonShort: "VS",
    latitude: 46.1924,
    longitude: 7.2715,
  },
  {
    zipCode: 1997,
    city: "Sornard (Nendaz)",
    cantonShort: "VS",
    latitude: 46.1882,
    longitude: 7.3033,
  },
  {
    zipCode: 1997,
    city: "Haute-Nendaz",
    cantonShort: "VS",
    latitude: 46.1661,
    longitude: 7.295,
  },
  {
    zipCode: 1997,
    city: "Siviez (Nendaz)",
    cantonShort: "VS",
    latitude: 46.1148,
    longitude: 7.3268,
  },
  {
    zipCode: 1927,
    city: "Chemin",
    cantonShort: "VS",
    latitude: 46.0896,
    longitude: 7.0936,
  },
  {
    zipCode: 1933,
    city: "Vens (Sembrancher)",
    cantonShort: "VS",
    latitude: 46.0859,
    longitude: 7.1302,
  },
  {
    zipCode: 1933,
    city: "La Garde (Sembrancher)",
    cantonShort: "VS",
    latitude: 46.0663,
    longitude: 7.1451,
  },
  {
    zipCode: 1933,
    city: "Sembrancher",
    cantonShort: "VS",
    latitude: 46.0701,
    longitude: 7.1452,
  },
  {
    zipCode: 1933,
    city: "Chamoille (Sembrancher)",
    cantonShort: "VS",
    latitude: 46.0611,
    longitude: 7.157,
  },
  {
    zipCode: 1934,
    city: "Bruson",
    cantonShort: "VS",
    latitude: 46.0506,
    longitude: 7.2066,
  },
  {
    zipCode: 1934,
    city: "Le Ch\u00e2ble VS",
    cantonShort: "VS",
    latitude: 46.082,
    longitude: 7.209,
  },
  {
    zipCode: 1936,
    city: "Verbier",
    cantonShort: "VS",
    latitude: 46.1008,
    longitude: 7.2297,
  },
  {
    zipCode: 1937,
    city: "Orsi\u00e8res",
    cantonShort: "VS",
    latitude: 46.0286,
    longitude: 7.1481,
  },
  {
    zipCode: 1938,
    city: "Champex-Lac",
    cantonShort: "VS",
    latitude: 46.0296,
    longitude: 7.0887,
  },
  {
    zipCode: 1941,
    city: "Voll\u00e8ges",
    cantonShort: "VS",
    latitude: 46.0847,
    longitude: 7.162,
  },
  {
    zipCode: 1941,
    city: "Cries (Voll\u00e8ges)",
    cantonShort: "VS",
    latitude: 46.0928,
    longitude: 7.1739,
  },
  {
    zipCode: 1942,
    city: "Levron",
    cantonShort: "VS",
    latitude: 46.1032,
    longitude: 7.1618,
  },
  {
    zipCode: 1943,
    city: "Praz-de-Fort",
    cantonShort: "VS",
    latitude: 45.9832,
    longitude: 7.0799,
  },
  {
    zipCode: 1944,
    city: "La Fouly VS",
    cantonShort: "VS",
    latitude: 45.9227,
    longitude: 7.098,
  },
  {
    zipCode: 1945,
    city: "Vich\u00e8res (Liddes)",
    cantonShort: "VS",
    latitude: 45.9995,
    longitude: 7.1643,
  },
  {
    zipCode: 1945,
    city: "Fornex (Liddes)",
    cantonShort: "VS",
    latitude: 46.0094,
    longitude: 7.1605,
  },
  {
    zipCode: 1945,
    city: "Liddes",
    cantonShort: "VS",
    latitude: 45.9858,
    longitude: 7.2254,
  },
  {
    zipCode: 1945,
    city: "Chez Petit (Liddes)",
    cantonShort: "VS",
    latitude: 45.9503,
    longitude: 7.1531,
  },
  {
    zipCode: 1945,
    city: "Les Moulins VS (Liddes)",
    cantonShort: "VS",
    latitude: 46.0048,
    longitude: 7.168,
  },
  {
    zipCode: 1945,
    city: "Dranse (Liddes)",
    cantonShort: "VS",
    latitude: 45.9863,
    longitude: 7.1824,
  },
  {
    zipCode: 1945,
    city: "Fontaine Dessous (Liddes)",
    cantonShort: "VS",
    latitude: 46.013,
    longitude: 7.1657,
  },
  {
    zipCode: 1945,
    city: "Petit Vich\u00e8res (Liddes)",
    cantonShort: "VS",
    latitude: 45.9877,
    longitude: 7.1557,
  },
  {
    zipCode: 1945,
    city: "Palasuit (Liddes)",
    cantonShort: "VS",
    latitude: 45.9711,
    longitude: 7.188,
  },
  {
    zipCode: 1945,
    city: "Fontaine Dessus (Liddes)",
    cantonShort: "VS",
    latitude: 46.0088,
    longitude: 7.1715,
  },
  {
    zipCode: 1945,
    city: "Rive Haute (Liddes)",
    cantonShort: "VS",
    latitude: 46.0061,
    longitude: 7.1712,
  },
  {
    zipCode: 1945,
    city: "Chandonne (Liddes)",
    cantonShort: "VS",
    latitude: 46.0104,
    longitude: 7.1863,
  },
  {
    zipCode: 1946,
    city: "Bourg-St-Pierre",
    cantonShort: "VS",
    latitude: 45.9196,
    longitude: 7.2128,
  },
  {
    zipCode: 1947,
    city: "Champsec",
    cantonShort: "VS",
    latitude: 46.0342,
    longitude: 7.2296,
  },
  {
    zipCode: 1947,
    city: "Verseg\u00e8res",
    cantonShort: "VS",
    latitude: 46.0646,
    longitude: 7.2324,
  },
  {
    zipCode: 1948,
    city: "Sarreyer",
    cantonShort: "VS",
    latitude: 46.0766,
    longitude: 7.2728,
  },
  {
    zipCode: 1948,
    city: "Fionnay",
    cantonShort: "VS",
    latitude: 45.9742,
    longitude: 7.3593,
  },
  {
    zipCode: 1948,
    city: "Lourtier",
    cantonShort: "VS",
    latitude: 46.0299,
    longitude: 7.265,
  },
  {
    zipCode: 3801,
    city: "Jungfraujoch",
    cantonShort: "VS",
    latitude: 46.5465,
    longitude: 7.9827,
  },
  {
    zipCode: 3984,
    city: "Fiesch",
    cantonShort: "VS",
    latitude: 46.4158,
    longitude: 8.1147,
  },
  {
    zipCode: 3984,
    city: "Fiesch",
    cantonShort: "VS",
    latitude: 46.4158,
    longitude: 8.1147,
  },
  {
    zipCode: 3984,
    city: "Fieschertal",
    cantonShort: "VS",
    latitude: 46.5008,
    longitude: 8.0654,
  },
  {
    zipCode: 3985,
    city: "M\u00fcnster VS",
    cantonShort: "VS",
    latitude: 46.4901,
    longitude: 8.2589,
  },
  {
    zipCode: 3985,
    city: "Geschinen",
    cantonShort: "VS",
    latitude: 46.4949,
    longitude: 8.2996,
  },
  {
    zipCode: 3988,
    city: "Ulrichen",
    cantonShort: "VS",
    latitude: 46.4814,
    longitude: 8.3385,
  },
  {
    zipCode: 3988,
    city: "Obergesteln",
    cantonShort: "VS",
    latitude: 46.5278,
    longitude: 8.3167,
  },
  {
    zipCode: 3989,
    city: "Biel VS",
    cantonShort: "VS",
    latitude: 46.4651,
    longitude: 8.2073,
  },
  {
    zipCode: 3989,
    city: "Ritzingen",
    cantonShort: "VS",
    latitude: 46.451,
    longitude: 8.2346,
  },
  {
    zipCode: 3989,
    city: "Grafschaft",
    cantonShort: "VS",
    latitude: 46.4707,
    longitude: 8.2475,
  },
  {
    zipCode: 3989,
    city: "Selkingen",
    cantonShort: "VS",
    latitude: 46.4703,
    longitude: 8.1845,
  },
  {
    zipCode: 3989,
    city: "Niederwald",
    cantonShort: "VS",
    latitude: 46.4274,
    longitude: 8.1989,
  },
  {
    zipCode: 3989,
    city: "Blitzingen",
    cantonShort: "VS",
    latitude: 46.4434,
    longitude: 8.2002,
  },
  {
    zipCode: 3994,
    city: "Lax",
    cantonShort: "VS",
    latitude: 46.3934,
    longitude: 8.1107,
  },
  {
    zipCode: 3995,
    city: "Steinhaus",
    cantonShort: "VS",
    latitude: 46.4187,
    longitude: 8.1879,
  },
  {
    zipCode: 3995,
    city: "M\u00fchlebach (Goms)",
    cantonShort: "VS",
    latitude: 46.4083,
    longitude: 8.1721,
  },
  {
    zipCode: 3995,
    city: "Ernen",
    cantonShort: "VS",
    latitude: 46.399,
    longitude: 8.1922,
  },
  {
    zipCode: 3995,
    city: "M\u00fchlebach (Goms)",
    cantonShort: "VS",
    latitude: 46.4083,
    longitude: 8.1721,
  },
  {
    zipCode: 3995,
    city: "Ausserbinn",
    cantonShort: "VS",
    latitude: 46.3811,
    longitude: 8.1625,
  },
  {
    zipCode: 3995,
    city: "Steinhaus",
    cantonShort: "VS",
    latitude: 46.4187,
    longitude: 8.1879,
  },
  {
    zipCode: 3996,
    city: "Binn",
    cantonShort: "VS",
    latitude: 46.3682,
    longitude: 8.2356,
  },
  {
    zipCode: 3997,
    city: "Bellwald",
    cantonShort: "VS",
    latitude: 46.4443,
    longitude: 8.157,
  },
  {
    zipCode: 3998,
    city: "Gluringen",
    cantonShort: "VS",
    latitude: 46.4769,
    longitude: 8.2175,
  },
  {
    zipCode: 3998,
    city: "Reckingen VS",
    cantonShort: "VS",
    latitude: 46.4536,
    longitude: 8.2619,
  },
  {
    zipCode: 3999,
    city: "Oberwald",
    cantonShort: "VS",
    latitude: 46.5576,
    longitude: 8.3936,
  },
  {
    zipCode: 1961,
    city: "Vernami\u00e8ge",
    cantonShort: "VS",
    latitude: 46.2105,
    longitude: 7.4358,
  },
  {
    zipCode: 1966,
    city: "Ayent",
    cantonShort: "VS",
    latitude: 46.2825,
    longitude: 7.4103,
  },
  {
    zipCode: 1966,
    city: "Villa (Ayent)",
    cantonShort: "VS",
    latitude: 46.2759,
    longitude: 7.4167,
  },
  {
    zipCode: 1966,
    city: "Botyre (Ayent)",
    cantonShort: "VS",
    latitude: 46.2765,
    longitude: 7.4068,
  },
  {
    zipCode: 1966,
    city: "Sign\u00e8se (Ayent)",
    cantonShort: "VS",
    latitude: 46.2628,
    longitude: 7.4087,
  },
  {
    zipCode: 1966,
    city: "Fortunau (Ayent)",
    cantonShort: "VS",
    latitude: 46.3097,
    longitude: 7.4295,
  },
  {
    zipCode: 1966,
    city: "St-Romain (Ayent)",
    cantonShort: "VS",
    latitude: 46.2835,
    longitude: 7.4114,
  },
  {
    zipCode: 1966,
    city: "La Place (Ayent)",
    cantonShort: "VS",
    latitude: 46.2737,
    longitude: 7.4114,
  },
  {
    zipCode: 1966,
    city: "Argnou (Ayent)",
    cantonShort: "VS",
    latitude: 46.2641,
    longitude: 7.4008,
  },
  {
    zipCode: 1966,
    city: "Saxonne (Ayent)",
    cantonShort: "VS",
    latitude: 46.2823,
    longitude: 7.4023,
  },
  {
    zipCode: 1966,
    city: "Blignou (Ayent)",
    cantonShort: "VS",
    latitude: 46.2707,
    longitude: 7.3974,
  },
  {
    zipCode: 1966,
    city: "Luc (Ayent)",
    cantonShort: "VS",
    latitude: 46.2849,
    longitude: 7.4237,
  },
  {
    zipCode: 1968,
    city: "Mase",
    cantonShort: "VS",
    latitude: 46.1954,
    longitude: 7.4607,
  },
  {
    zipCode: 1969,
    city: "Trogne (St-Martin)",
    cantonShort: "VS",
    latitude: 46.1749,
    longitude: 7.4699,
  },
  {
    zipCode: 1969,
    city: "Liez (St-Martin)",
    cantonShort: "VS",
    latitude: 46.1614,
    longitude: 7.4518,
  },
  {
    zipCode: 1969,
    city: "St-Martin VS",
    cantonShort: "VS",
    latitude: 46.1711,
    longitude: 7.4426,
  },
  {
    zipCode: 1969,
    city: "Eison (St-Martin)",
    cantonShort: "VS",
    latitude: 46.154,
    longitude: 7.4881,
  },
  {
    zipCode: 1969,
    city: "Suen (St-Martin)",
    cantonShort: "VS",
    latitude: 46.1829,
    longitude: 7.4387,
  },
  {
    zipCode: 1972,
    city: "Anz\u00e8re",
    cantonShort: "VS",
    latitude: 46.3424,
    longitude: 7.4064,
  },
  {
    zipCode: 1973,
    city: "Nax",
    cantonShort: "VS",
    latitude: 46.2068,
    longitude: 7.4783,
  },
  {
    zipCode: 1981,
    city: "Vex",
    cantonShort: "VS",
    latitude: 46.2087,
    longitude: 7.401,
  },
  {
    zipCode: 1982,
    city: "Euseigne",
    cantonShort: "VS",
    latitude: 46.1522,
    longitude: 7.4305,
  },
  {
    zipCode: 1983,
    city: "Lanna",
    cantonShort: "VS",
    latitude: 46.1118,
    longitude: 7.4563,
  },
  {
    zipCode: 1983,
    city: "Evol\u00e8ne",
    cantonShort: "VS",
    latitude: 46.1285,
    longitude: 7.4895,
  },
  {
    zipCode: 1984,
    city: "Les Haud\u00e8res",
    cantonShort: "VS",
    latitude: 46.0778,
    longitude: 7.4799,
  },
  {
    zipCode: 1984,
    city: "La Tour VS",
    cantonShort: "VS",
    latitude: 46.0989,
    longitude: 7.5045,
  },
  {
    zipCode: 1985,
    city: "Villa (Evol\u00e8ne)",
    cantonShort: "VS",
    latitude: 46.117,
    longitude: 7.5236,
  },
  {
    zipCode: 1985,
    city: "La Sage",
    cantonShort: "VS",
    latitude: 46.0987,
    longitude: 7.517,
  },
  {
    zipCode: 1985,
    city: "La Forclaz VS",
    cantonShort: "VS",
    latitude: 46.0428,
    longitude: 7.561,
  },
  {
    zipCode: 1986,
    city: "Arolla",
    cantonShort: "VS",
    latitude: 46.0119,
    longitude: 7.4863,
  },
  {
    zipCode: 1987,
    city: "H\u00e9r\u00e9mence",
    cantonShort: "VS",
    latitude: 46.0843,
    longitude: 7.3948,
  },
  {
    zipCode: 1988,
    city: "Les Collons",
    cantonShort: "VS",
    latitude: 46.1849,
    longitude: 7.3843,
  },
  {
    zipCode: 1988,
    city: "Thyon",
    cantonShort: "VS",
    latitude: 46.1734,
    longitude: 7.3736,
  },
  {
    zipCode: 3945,
    city: "Niedergampel",
    cantonShort: "VS",
    latitude: 46.3122,
    longitude: 7.6943,
  },
  {
    zipCode: 3945,
    city: "Gampel",
    cantonShort: "VS",
    latitude: 46.3398,
    longitude: 7.7338,
  },
  {
    zipCode: 3946,
    city: "Gruben",
    cantonShort: "VS",
    latitude: 46.1842,
    longitude: 7.7051,
  },
  {
    zipCode: 3946,
    city: "Turtmann",
    cantonShort: "VS",
    latitude: 46.303,
    longitude: 7.7088,
  },
  {
    zipCode: 3947,
    city: "Ergisch",
    cantonShort: "VS",
    latitude: 46.2726,
    longitude: 7.726,
  },
  {
    zipCode: 3948,
    city: "Unterems",
    cantonShort: "VS",
    latitude: 46.2887,
    longitude: 7.6898,
  },
  {
    zipCode: 3948,
    city: "Oberems",
    cantonShort: "VS",
    latitude: 46.2583,
    longitude: 7.6866,
  },
  {
    zipCode: 3951,
    city: "Agarn",
    cantonShort: "VS",
    latitude: 46.2845,
    longitude: 7.6673,
  },
  {
    zipCode: 3952,
    city: "Susten",
    cantonShort: "VS",
    latitude: 46.284,
    longitude: 7.6307,
  },
  {
    zipCode: 3953,
    city: "Varen",
    cantonShort: "VS",
    latitude: 46.338,
    longitude: 7.5927,
  },
  {
    zipCode: 3953,
    city: "Inden",
    cantonShort: "VS",
    latitude: 46.36,
    longitude: 7.5982,
  },
  {
    zipCode: 3953,
    city: "Leuk Stadt",
    cantonShort: "VS",
    latitude: 46.3222,
    longitude: 7.6485,
  },
  {
    zipCode: 3954,
    city: "Leukerbad",
    cantonShort: "VS",
    latitude: 46.4021,
    longitude: 7.6277,
  },
  {
    zipCode: 3955,
    city: "Albinen",
    cantonShort: "VS",
    latitude: 46.354,
    longitude: 7.6447,
  },
  {
    zipCode: 3956,
    city: "Guttet-Feschel",
    cantonShort: "VS",
    latitude: 46.3491,
    longitude: 7.6766,
  },
  {
    zipCode: 3957,
    city: "Erschmatt",
    cantonShort: "VS",
    latitude: 46.3461,
    longitude: 7.6964,
  },
  {
    zipCode: 3957,
    city: "Bratsch",
    cantonShort: "VS",
    latitude: 46.3242,
    longitude: 7.7122,
  },
  {
    zipCode: 3970,
    city: "Salgesch",
    cantonShort: "VS",
    latitude: 46.3114,
    longitude: 7.5753,
  },
  {
    zipCode: 1906,
    city: "Charrat",
    cantonShort: "VS",
    latitude: 46.1214,
    longitude: 7.1361,
  },
  {
    zipCode: 1907,
    city: "Saxon",
    cantonShort: "VS",
    latitude: 46.1362,
    longitude: 7.1844,
  },
  {
    zipCode: 1908,
    city: "Riddes",
    cantonShort: "VS",
    latitude: 46.1688,
    longitude: 7.2201,
  },
  {
    zipCode: 1911,
    city: "Ovronnaz",
    cantonShort: "VS",
    latitude: 46.2018,
    longitude: 7.1364,
  },
  {
    zipCode: 1912,
    city: "Produit (Leytron)",
    cantonShort: "VS",
    latitude: 46.1854,
    longitude: 7.191,
  },
  {
    zipCode: 1912,
    city: "Dugny (Leytron)",
    cantonShort: "VS",
    latitude: 46.191,
    longitude: 7.1806,
  },
  {
    zipCode: 1912,
    city: "Leytron",
    cantonShort: "VS",
    latitude: 46.1868,
    longitude: 7.2104,
  },
  {
    zipCode: 1912,
    city: "Montagnon (Leytron)",
    cantonShort: "VS",
    latitude: 46.1914,
    longitude: 7.1918,
  },
  {
    zipCode: 1913,
    city: "Saillon",
    cantonShort: "VS",
    latitude: 46.1705,
    longitude: 7.1777,
  },
  {
    zipCode: 1913,
    city: "Saillon",
    cantonShort: "VS",
    latitude: 46.1705,
    longitude: 7.1777,
  },
  {
    zipCode: 1914,
    city: "Is\u00e9rables",
    cantonShort: "VS",
    latitude: 46.1468,
    longitude: 7.2688,
  },
  {
    zipCode: 1914,
    city: "Auddes-sur-Riddes",
    cantonShort: "VS",
    latitude: 46.1536,
    longitude: 7.2413,
  },
  {
    zipCode: 1918,
    city: "La Tzoumaz",
    cantonShort: "VS",
    latitude: 46.1302,
    longitude: 7.2461,
  },
  {
    zipCode: 1919,
    city: "Martigny Mutuel",
    cantonShort: "VS",
    latitude: 46.1072,
    longitude: 7.081,
  },
  {
    zipCode: 1919,
    city: "Martigny Groupe Mutuel",
    cantonShort: "VS",
    latitude: 46.1072,
    longitude: 7.081,
  },
  {
    zipCode: 1920,
    city: "Martigny 1",
    cantonShort: "VS",
    latitude: 46.1072,
    longitude: 7.081,
  },
  {
    zipCode: 1920,
    city: "Martigny",
    cantonShort: "VS",
    latitude: 46.1095,
    longitude: 7.0745,
  },
  {
    zipCode: 1921,
    city: "Martigny-Croix",
    cantonShort: "VS",
    latitude: 46.0657,
    longitude: 7.0319,
  },
  {
    zipCode: 1926,
    city: "Fully",
    cantonShort: "VS",
    latitude: 46.1575,
    longitude: 7.1119,
  },
  {
    zipCode: 1928,
    city: "Ravoire",
    cantonShort: "VS",
    latitude: 46.0994,
    longitude: 7.043,
  },
  {
    zipCode: 1929,
    city: "Trient",
    cantonShort: "VS",
    latitude: 46.03,
    longitude: 6.9995,
  },
  {
    zipCode: 1932,
    city: "Bovernier",
    cantonShort: "VS",
    latitude: 46.0622,
    longitude: 7.0821,
  },
  {
    zipCode: 1932,
    city: "Les Valettes (Bovernier)",
    cantonShort: "VS",
    latitude: 46.0747,
    longitude: 7.0724,
  },
  {
    zipCode: 1868,
    city: "Collombey",
    cantonShort: "VS",
    latitude: 46.2722,
    longitude: 6.9428,
  },
  {
    zipCode: 1870,
    city: "Monthey 1",
    cantonShort: "VS",
    latitude: 46.2436,
    longitude: 6.9545,
  },
  {
    zipCode: 1870,
    city: "Monthey",
    cantonShort: "VS",
    latitude: 46.2388,
    longitude: 6.8972,
  },
  {
    zipCode: 1870,
    city: "Monthey",
    cantonShort: "VS",
    latitude: 46.2388,
    longitude: 6.8972,
  },
  {
    zipCode: 1870,
    city: "Monthey 2",
    cantonShort: "VS",
    latitude: 46.2436,
    longitude: 6.9545,
  },
  {
    zipCode: 1871,
    city: "Cho\u00ebx",
    cantonShort: "VS",
    latitude: 46.2396,
    longitude: 6.9522,
  },
  {
    zipCode: 1871,
    city: "Les Giettes",
    cantonShort: "VS",
    latitude: 46.2224,
    longitude: 6.9524,
  },
  {
    zipCode: 1872,
    city: "Troistorrents",
    cantonShort: "VS",
    latitude: 46.2291,
    longitude: 6.9084,
  },
  {
    zipCode: 1873,
    city: "Val-d'Illiez",
    cantonShort: "VS",
    latitude: 46.1902,
    longitude: 6.9063,
  },
  {
    zipCode: 1873,
    city: "Les Crosets",
    cantonShort: "VS",
    latitude: 46.1885,
    longitude: 6.8335,
  },
  {
    zipCode: 1873,
    city: "Champoussin",
    cantonShort: "VS",
    latitude: 46.207,
    longitude: 6.8606,
  },
  {
    zipCode: 1874,
    city: "Champ\u00e9ry",
    cantonShort: "VS",
    latitude: 46.1588,
    longitude: 6.846,
  },
  {
    zipCode: 1875,
    city: "Morgins",
    cantonShort: "VS",
    latitude: 46.2364,
    longitude: 6.8644,
  },
  {
    zipCode: 1893,
    city: "Muraz (Collombey)",
    cantonShort: "VS",
    latitude: 46.2826,
    longitude: 6.9017,
  },
  {
    zipCode: 1895,
    city: "Vionnaz",
    cantonShort: "VS",
    latitude: 46.309,
    longitude: 6.899,
  },
  {
    zipCode: 1896,
    city: "Miex",
    cantonShort: "VS",
    latitude: 46.3373,
    longitude: 6.8319,
  },
  {
    zipCode: 1896,
    city: "Vouvry",
    cantonShort: "VS",
    latitude: 46.3329,
    longitude: 6.9024,
  },
  {
    zipCode: 1897,
    city: "Bouveret",
    cantonShort: "VS",
    latitude: 46.3791,
    longitude: 6.856,
  },
  {
    zipCode: 1897,
    city: "Les Evouettes",
    cantonShort: "VS",
    latitude: 46.3615,
    longitude: 6.8597,
  },
  {
    zipCode: 1898,
    city: "St-Gingolph",
    cantonShort: "VS",
    latitude: 46.3823,
    longitude: 6.817,
  },
  {
    zipCode: 1899,
    city: "Torgon",
    cantonShort: "VS",
    latitude: 46.3084,
    longitude: 6.8596,
  },
  {
    zipCode: 3916,
    city: "Ferden",
    cantonShort: "VS",
    latitude: 46.3882,
    longitude: 7.7341,
  },
  {
    zipCode: 3917,
    city: "Goppenstein",
    cantonShort: "VS",
    latitude: 46.3657,
    longitude: 7.7557,
  },
  {
    zipCode: 3917,
    city: "Kippel",
    cantonShort: "VS",
    latitude: 46.3963,
    longitude: 7.7682,
  },
  {
    zipCode: 3918,
    city: "Wiler (L\u00f6tschen)",
    cantonShort: "VS",
    latitude: 46.4074,
    longitude: 7.787,
  },
  {
    zipCode: 3919,
    city: "Blatten (L\u00f6tschen)",
    cantonShort: "VS",
    latitude: 46.4447,
    longitude: 7.8646,
  },
  {
    zipCode: 3935,
    city: "B\u00fcrchen",
    cantonShort: "VS",
    latitude: 46.2693,
    longitude: 7.8183,
  },
  {
    zipCode: 3938,
    city: "Ausserberg",
    cantonShort: "VS",
    latitude: 46.3276,
    longitude: 7.8554,
  },
  {
    zipCode: 3940,
    city: "Steg VS",
    cantonShort: "VS",
    latitude: 46.3381,
    longitude: 7.7637,
  },
  {
    zipCode: 3942,
    city: "St. German",
    cantonShort: "VS",
    latitude: 46.3146,
    longitude: 7.822,
  },
  {
    zipCode: 3942,
    city: "Niedergesteln",
    cantonShort: "VS",
    latitude: 46.3488,
    longitude: 7.8147,
  },
  {
    zipCode: 3942,
    city: "Raron",
    cantonShort: "VS",
    latitude: 46.3029,
    longitude: 7.815,
  },
  {
    zipCode: 3943,
    city: "Eischoll",
    cantonShort: "VS",
    latitude: 46.2828,
    longitude: 7.7611,
  },
  {
    zipCode: 3944,
    city: "Unterb\u00e4ch VS",
    cantonShort: "VS",
    latitude: 46.2534,
    longitude: 7.7774,
  },
  {
    zipCode: 3949,
    city: "Hohtenn",
    cantonShort: "VS",
    latitude: 46.3314,
    longitude: 7.7701,
  },
  {
    zipCode: 3982,
    city: "Bitsch",
    cantonShort: "VS",
    latitude: 46.3421,
    longitude: 8.0131,
  },
  {
    zipCode: 3983,
    city: "M\u00f6rel",
    cantonShort: "VS",
    latitude: 46.3549,
    longitude: 8.0405,
  },
  {
    zipCode: 3983,
    city: "Bister",
    cantonShort: "VS",
    latitude: 46.3496,
    longitude: 8.0787,
  },
  {
    zipCode: 3983,
    city: "Filet",
    cantonShort: "VS",
    latitude: 46.3475,
    longitude: 8.0592,
  },
  {
    zipCode: 3983,
    city: "Greich",
    cantonShort: "VS",
    latitude: 46.3669,
    longitude: 8.0394,
  },
  {
    zipCode: 3983,
    city: "Goppisberg",
    cantonShort: "VS",
    latitude: 46.372,
    longitude: 8.049,
  },
  {
    zipCode: 3986,
    city: "Ried-M\u00f6rel",
    cantonShort: "VS",
    latitude: 46.3571,
    longitude: 8.0278,
  },
  {
    zipCode: 3987,
    city: "Riederalp",
    cantonShort: "VS",
    latitude: 46.3818,
    longitude: 8.0282,
  },
  {
    zipCode: 3991,
    city: "Betten",
    cantonShort: "VS",
    latitude: 46.38,
    longitude: 8.0725,
  },
  {
    zipCode: 3992,
    city: "Bettmeralp",
    cantonShort: "VS",
    latitude: 46.4316,
    longitude: 8.0564,
  },
  {
    zipCode: 3993,
    city: "Grengiols",
    cantonShort: "VS",
    latitude: 46.3402,
    longitude: 8.137,
  },
  {
    zipCode: 3994,
    city: "Martisberg",
    cantonShort: "VS",
    latitude: 46.3914,
    longitude: 8.0961,
  },
  {
    zipCode: 1869,
    city: "Massongex",
    cantonShort: "VS",
    latitude: 46.2363,
    longitude: 6.9846,
  },
  {
    zipCode: 1890,
    city: "St-Maurice",
    cantonShort: "VS",
    latitude: 46.1988,
    longitude: 7.0103,
  },
  {
    zipCode: 1890,
    city: "Mex VS",
    cantonShort: "VS",
    latitude: 46.1825,
    longitude: 6.981,
  },
  {
    zipCode: 1891,
    city: "V\u00e9rossaz",
    cantonShort: "VS",
    latitude: 46.2011,
    longitude: 6.9685,
  },
  {
    zipCode: 1902,
    city: "Evionnaz",
    cantonShort: "VS",
    latitude: 46.1483,
    longitude: 6.9486,
  },
  {
    zipCode: 1903,
    city: "Collonges",
    cantonShort: "VS",
    latitude: 46.1804,
    longitude: 7.0559,
  },
  {
    zipCode: 1904,
    city: "Vernayaz",
    cantonShort: "VS",
    latitude: 46.1412,
    longitude: 7.0329,
  },
  {
    zipCode: 1905,
    city: "Dor\u00e9naz",
    cantonShort: "VS",
    latitude: 46.1517,
    longitude: 7.0617,
  },
  {
    zipCode: 1922,
    city: "Les Granges (Salvan)",
    cantonShort: "VS",
    latitude: 46.1272,
    longitude: 7.0201,
  },
  {
    zipCode: 1922,
    city: "Salvan",
    cantonShort: "VS",
    latitude: 46.1354,
    longitude: 7.0074,
  },
  {
    zipCode: 1923,
    city: "Le Tr\u00e9tien",
    cantonShort: "VS",
    latitude: 46.0986,
    longitude: 6.9921,
  },
  {
    zipCode: 1923,
    city: "Les Mar\u00e9cottes",
    cantonShort: "VS",
    latitude: 46.1046,
    longitude: 6.9434,
  },
  {
    zipCode: 1925,
    city: "Finhaut",
    cantonShort: "VS",
    latitude: 46.0699,
    longitude: 6.9308,
  },
  {
    zipCode: 1925,
    city: "Le Ch\u00e2telard VS",
    cantonShort: "VS",
    latitude: 46.0594,
    longitude: 6.9563,
  },
  {
    zipCode: 1958,
    city: "St-L\u00e9onard",
    cantonShort: "VS",
    latitude: 46.2565,
    longitude: 7.4271,
  },
  {
    zipCode: 1977,
    city: "Icogne",
    cantonShort: "VS",
    latitude: 46.3418,
    longitude: 7.456,
  },
  {
    zipCode: 1978,
    city: "Lens",
    cantonShort: "VS",
    latitude: 46.2811,
    longitude: 7.449,
  },
  {
    zipCode: 3960,
    city: "Muraz (Sierre)",
    cantonShort: "VS",
    latitude: 46.2973,
    longitude: 7.5289,
  },
  {
    zipCode: 3960,
    city: "Corin-de-la-Cr\u00eate",
    cantonShort: "VS",
    latitude: 46.2859,
    longitude: 7.5008,
  },
  {
    zipCode: 3960,
    city: "Niouc",
    cantonShort: "VS",
    latitude: 46.2689,
    longitude: 7.5605,
  },
  {
    zipCode: 3960,
    city: "Loc",
    cantonShort: "VS",
    latitude: 46.2935,
    longitude: 7.5096,
  },
  {
    zipCode: 3960,
    city: "Sierre",
    cantonShort: "VS",
    latitude: 46.2876,
    longitude: 7.5457,
  },
  {
    zipCode: 3961,
    city: "St-Luc",
    cantonShort: "VS",
    latitude: 46.2186,
    longitude: 7.6272,
  },
  {
    zipCode: 3961,
    city: "Zinal",
    cantonShort: "VS",
    latitude: 46.103,
    longitude: 7.6455,
  },
  {
    zipCode: 3961,
    city: "Mission",
    cantonShort: "VS",
    latitude: 46.1956,
    longitude: 7.5966,
  },
  {
    zipCode: 3961,
    city: "St-Jean VS",
    cantonShort: "VS",
    latitude: 46.2044,
    longitude: 7.5573,
  },
  {
    zipCode: 3961,
    city: "Chandolin",
    cantonShort: "VS",
    latitude: 46.2563,
    longitude: 7.5998,
  },
  {
    zipCode: 3961,
    city: "Vissoie",
    cantonShort: "VS",
    latitude: 46.2282,
    longitude: 7.5747,
  },
  {
    zipCode: 3961,
    city: "Ayer",
    cantonShort: "VS",
    latitude: 46.1787,
    longitude: 7.6153,
  },
  {
    zipCode: 3961,
    city: "Grimentz",
    cantonShort: "VS",
    latitude: 46.1366,
    longitude: 7.5647,
  },
  {
    zipCode: 3963,
    city: "Crans-Montana 1",
    cantonShort: "VS",
    latitude: 46.3355,
    longitude: 7.5182,
  },
  {
    zipCode: 3963,
    city: "Aminona",
    cantonShort: "VS",
    latitude: 46.3595,
    longitude: 7.5375,
  },
  {
    zipCode: 3963,
    city: "Montana",
    cantonShort: "VS",
    latitude: 46.2954,
    longitude: 7.4918,
  },
  {
    zipCode: 3963,
    city: "Crans-Montana 2",
    cantonShort: "VS",
    latitude: 46.2947,
    longitude: 7.458,
  },
  {
    zipCode: 3963,
    city: "Crans-Montana",
    cantonShort: "VS",
    latitude: 46.3282,
    longitude: 7.4858,
  },
  {
    zipCode: 3965,
    city: "Chippis",
    cantonShort: "VS",
    latitude: 46.2771,
    longitude: 7.5406,
  },
  {
    zipCode: 3966,
    city: "Chalais",
    cantonShort: "VS",
    latitude: 46.2645,
    longitude: 7.5167,
  },
  {
    zipCode: 3966,
    city: "R\u00e9chy",
    cantonShort: "VS",
    latitude: 46.2657,
    longitude: 7.4943,
  },
  {
    zipCode: 3967,
    city: "Vercorin",
    cantonShort: "VS",
    latitude: 46.2431,
    longitude: 7.5357,
  },
  {
    zipCode: 3968,
    city: "Veyras",
    cantonShort: "VS",
    latitude: 46.3038,
    longitude: 7.5384,
  },
  {
    zipCode: 3971,
    city: "Chermignon-d'en-Bas",
    cantonShort: "VS",
    latitude: 46.2827,
    longitude: 7.4822,
  },
  {
    zipCode: 3971,
    city: "Chermignon",
    cantonShort: "VS",
    latitude: 46.2876,
    longitude: 7.4735,
  },
  {
    zipCode: 3971,
    city: "Ollon VS",
    cantonShort: "VS",
    latitude: 46.2771,
    longitude: 7.4849,
  },
  {
    zipCode: 3972,
    city: "Mi\u00e8ge",
    cantonShort: "VS",
    latitude: 46.3311,
    longitude: 7.5551,
  },
  {
    zipCode: 3973,
    city: "Venth\u00f4ne",
    cantonShort: "VS",
    latitude: 46.3048,
    longitude: 7.5237,
  },
  {
    zipCode: 3974,
    city: "Mollens VS",
    cantonShort: "VS",
    latitude: 46.3164,
    longitude: 7.5209,
  },
  {
    zipCode: 3975,
    city: "Randogne",
    cantonShort: "VS",
    latitude: 46.3105,
    longitude: 7.5029,
  },
  {
    zipCode: 3976,
    city: "Champzab\u00e9",
    cantonShort: "VS",
    latitude: 46.2791,
    longitude: 7.4983,
  },
  {
    zipCode: 3976,
    city: "No\u00ebs",
    cantonShort: "VS",
    latitude: 46.2777,
    longitude: 7.5059,
  },
  {
    zipCode: 3977,
    city: "Granges VS",
    cantonShort: "VS",
    latitude: 46.2629,
    longitude: 7.4653,
  },
  {
    zipCode: 3978,
    city: "Flanthey",
    cantonShort: "VS",
    latitude: 46.2704,
    longitude: 7.4516,
  },
  {
    zipCode: 3979,
    city: "Gr\u00f4ne",
    cantonShort: "VS",
    latitude: 46.2335,
    longitude: 7.4865,
  },
  {
    zipCode: 1950,
    city: "Sion 4",
    cantonShort: "VS",
    latitude: 46.2304,
    longitude: 7.3661,
  },
  {
    zipCode: 1950,
    city: "Sion",
    cantonShort: "VS",
    latitude: 46.2273,
    longitude: 7.3547,
  },
  {
    zipCode: 1950,
    city: "Sion 2",
    cantonShort: "VS",
    latitude: 46.2304,
    longitude: 7.3661,
  },
  {
    zipCode: 1951,
    city: "Sion",
    cantonShort: "VS",
    latitude: 46.2304,
    longitude: 7.3661,
  },
  {
    zipCode: 1958,
    city: "Uvrier",
    cantonShort: "VS",
    latitude: 46.2476,
    longitude: 7.4087,
  },
  {
    zipCode: 1962,
    city: "Pont-de-la-Morge (Sion)",
    cantonShort: "VS",
    latitude: 46.2325,
    longitude: 7.3165,
  },
  {
    zipCode: 1965,
    city: "Roumaz (Savi\u00e8se)",
    cantonShort: "VS",
    latitude: 46.2453,
    longitude: 7.3456,
  },
  {
    zipCode: 1965,
    city: "Dr\u00f4ne (Savi\u00e8se)",
    cantonShort: "VS",
    latitude: 46.2612,
    longitude: 7.3681,
  },
  {
    zipCode: 1965,
    city: "Savi\u00e8se",
    cantonShort: "VS",
    latitude: 46.3105,
    longitude: 7.3112,
  },
  {
    zipCode: 1965,
    city: "La Muraz (Savi\u00e8se)",
    cantonShort: "VS",
    latitude: 46.2531,
    longitude: 7.3478,
  },
  {
    zipCode: 1965,
    city: "St-Germain (Savi\u00e8se)",
    cantonShort: "VS",
    latitude: 46.2496,
    longitude: 7.3526,
  },
  {
    zipCode: 1965,
    city: "Diolly (Savi\u00e8se)",
    cantonShort: "VS",
    latitude: 46.2531,
    longitude: 7.3478,
  },
  {
    zipCode: 1965,
    city: "Orm\u00f4ne (Savi\u00e8se)",
    cantonShort: "VS",
    latitude: 46.2431,
    longitude: 7.3469,
  },
  {
    zipCode: 1965,
    city: "Granois (Savi\u00e8se)",
    cantonShort: "VS",
    latitude: 46.2489,
    longitude: 7.3369,
  },
  {
    zipCode: 1965,
    city: "Chandolin (Savi\u00e8se)",
    cantonShort: "VS",
    latitude: 46.2494,
    longitude: 7.3193,
  },
  {
    zipCode: 1965,
    city: "Mayens-de-la-Zour (Savi\u00e8se)",
    cantonShort: "VS",
    latitude: 46.2698,
    longitude: 7.3569,
  },
  {
    zipCode: 1965,
    city: "Monteiller (Savi\u00e8se)",
    cantonShort: "VS",
    latitude: 46.2571,
    longitude: 7.3565,
  },
  {
    zipCode: 1967,
    city: "Bramois",
    cantonShort: "VS",
    latitude: 46.2352,
    longitude: 7.4032,
  },
  {
    zipCode: 1971,
    city: "Grimisuat",
    cantonShort: "VS",
    latitude: 46.2588,
    longitude: 7.3815,
  },
  {
    zipCode: 1971,
    city: "Champlan (Grimisuat)",
    cantonShort: "VS",
    latitude: 46.2486,
    longitude: 7.3777,
  },
  {
    zipCode: 1974,
    city: "Arbaz",
    cantonShort: "VS",
    latitude: 46.3002,
    longitude: 7.3731,
  },
  {
    zipCode: 1991,
    city: "Turin (Salins)",
    cantonShort: "VS",
    latitude: 46.2164,
    longitude: 7.3576,
  },
  {
    zipCode: 1991,
    city: "Mis\u00e9riez (Salins)",
    cantonShort: "VS",
    latitude: 46.2071,
    longitude: 7.3448,
  },
  {
    zipCode: 1991,
    city: "Salins",
    cantonShort: "VS",
    latitude: 46.2074,
    longitude: 7.3535,
  },
  {
    zipCode: 1991,
    city: "Arvillard (Salins)",
    cantonShort: "VS",
    latitude: 46.2112,
    longitude: 7.3464,
  },
  {
    zipCode: 1991,
    city: "Pravidondaz (Salins)",
    cantonShort: "VS",
    latitude: 46.2122,
    longitude: 7.3548,
  },
  {
    zipCode: 1992,
    city: "Les Mayens-de-Sion",
    cantonShort: "VS",
    latitude: 46.1962,
    longitude: 7.3754,
  },
  {
    zipCode: 1992,
    city: "Les Agettes",
    cantonShort: "VS",
    latitude: 46.205,
    longitude: 7.3653,
  },
  {
    zipCode: 1992,
    city: "La Vernaz (Les Agettes)",
    cantonShort: "VS",
    latitude: 46.2104,
    longitude: 7.3733,
  },
  {
    zipCode: 1992,
    city: "Cr\u00eate-\u00e0-l'Oeil (Les Agettes)",
    cantonShort: "VS",
    latitude: 46.212,
    longitude: 7.3715,
  },
  {
    zipCode: 1992,
    city: "Cr\u00eate-\u00e0-l'Oeil(Les Agettes)",
    cantonShort: "VS",
    latitude: 46.2131,
    longitude: 7.373,
  },
  {
    zipCode: 1993,
    city: "Veysonnaz",
    cantonShort: "VS",
    latitude: 46.1963,
    longitude: 7.3424,
  },
  {
    zipCode: 3905,
    city: "Saas-Almagell",
    cantonShort: "VS",
    latitude: 46.054,
    longitude: 7.9669,
  },
  {
    zipCode: 3906,
    city: "Saas-Fee",
    cantonShort: "VS",
    latitude: 46.0894,
    longitude: 7.8996,
  },
  {
    zipCode: 3908,
    city: "Saas-Balen",
    cantonShort: "VS",
    latitude: 46.1528,
    longitude: 7.9352,
  },
  {
    zipCode: 3910,
    city: "Saas-Grund",
    cantonShort: "VS",
    latitude: 46.135,
    longitude: 7.9724,
  },
  {
    zipCode: 3920,
    city: "Zermatt",
    cantonShort: "VS",
    latitude: 45.9897,
    longitude: 7.7527,
  },
  {
    zipCode: 3922,
    city: "Kalpetran",
    cantonShort: "VS",
    latitude: 46.2139,
    longitude: 7.8347,
  },
  {
    zipCode: 3922,
    city: "Eisten",
    cantonShort: "VS",
    latitude: 46.1792,
    longitude: 7.9075,
  },
  {
    zipCode: 3922,
    city: "Stalden VS",
    cantonShort: "VS",
    latitude: 46.2335,
    longitude: 7.8771,
  },
  {
    zipCode: 3923,
    city: "T\u00f6rbel",
    cantonShort: "VS",
    latitude: 46.2421,
    longitude: 7.836,
  },
  {
    zipCode: 3924,
    city: "St. Niklaus VS",
    cantonShort: "VS",
    latitude: 46.1678,
    longitude: 7.8092,
  },
  {
    zipCode: 3925,
    city: "Gr\u00e4chen",
    cantonShort: "VS",
    latitude: 46.1967,
    longitude: 7.8489,
  },
  {
    zipCode: 3926,
    city: "Embd",
    cantonShort: "VS",
    latitude: 46.2169,
    longitude: 7.7942,
  },
  {
    zipCode: 3927,
    city: "Herbriggen",
    cantonShort: "VS",
    latitude: 46.1371,
    longitude: 7.7836,
  },
  {
    zipCode: 3928,
    city: "Randa",
    cantonShort: "VS",
    latitude: 46.097,
    longitude: 7.7793,
  },
  {
    zipCode: 3929,
    city: "T\u00e4sch",
    cantonShort: "VS",
    latitude: 46.054,
    longitude: 7.8112,
  },
  {
    zipCode: 3930,
    city: "Eyholz",
    cantonShort: "VS",
    latitude: 46.2889,
    longitude: 7.9152,
  },
  {
    zipCode: 3930,
    city: "Visp",
    cantonShort: "VS",
    latitude: 46.2912,
    longitude: 7.867,
  },
  {
    zipCode: 3931,
    city: "Lalden",
    cantonShort: "VS",
    latitude: 46.3,
    longitude: 7.8999,
  },
  {
    zipCode: 3932,
    city: "Visperterminen",
    cantonShort: "VS",
    latitude: 46.2426,
    longitude: 7.941,
  },
  {
    zipCode: 3933,
    city: "Staldenried",
    cantonShort: "VS",
    latitude: 46.2176,
    longitude: 7.9101,
  },
  {
    zipCode: 3934,
    city: "Zeneggen",
    cantonShort: "VS",
    latitude: 46.2738,
    longitude: 7.861,
  },
  {
    zipCode: 3937,
    city: "Baltschieder",
    cantonShort: "VS",
    latitude: 46.3771,
    longitude: 7.8812,
  },
  {
    zipCode: 3937,
    city: "Baltschieder",
    cantonShort: "VS",
    latitude: 46.3771,
    longitude: 7.8812,
  },
  {
    zipCode: 6300,
    city: "Zugerberg",
    cantonShort: "ZG",
    latitude: 47.1413,
    longitude: 8.532,
  },
  {
    zipCode: 6300,
    city: "Zug",
    cantonShort: "ZG",
    latitude: 47.1685,
    longitude: 8.5035,
  },
  {
    zipCode: 6301,
    city: "Zug",
    cantonShort: "ZG",
    latitude: 47.1724,
    longitude: 8.5174,
  },
  {
    zipCode: 6302,
    city: "Zug",
    cantonShort: "ZG",
    latitude: 47.1724,
    longitude: 8.5174,
  },
  {
    zipCode: 6303,
    city: "Zug",
    cantonShort: "ZG",
    latitude: 47.1724,
    longitude: 8.5174,
  },
  {
    zipCode: 6312,
    city: "Steinhausen",
    cantonShort: "ZG",
    latitude: 47.1985,
    longitude: 8.4873,
  },
  {
    zipCode: 6313,
    city: "Finstersee",
    cantonShort: "ZG",
    latitude: 47.1627,
    longitude: 8.6359,
  },
  {
    zipCode: 6313,
    city: "Menzingen",
    cantonShort: "ZG",
    latitude: 47.172,
    longitude: 8.5988,
  },
  {
    zipCode: 6313,
    city: "Edlibach",
    cantonShort: "ZG",
    latitude: 47.1758,
    longitude: 8.57,
  },
  {
    zipCode: 6314,
    city: "Unter\u00e4geri",
    cantonShort: "ZG",
    latitude: 47.1202,
    longitude: 8.5808,
  },
  {
    zipCode: 6314,
    city: "Neu\u00e4geri",
    cantonShort: "ZG",
    latitude: 47.1518,
    longitude: 8.5587,
  },
  {
    zipCode: 6315,
    city: "Ober\u00e4geri",
    cantonShort: "ZG",
    latitude: 47.1356,
    longitude: 8.6243,
  },
  {
    zipCode: 6315,
    city: "Alosen",
    cantonShort: "ZG",
    latitude: 47.1446,
    longitude: 8.6558,
  },
  {
    zipCode: 6315,
    city: "Morgarten",
    cantonShort: "ZG",
    latitude: 47.108,
    longitude: 8.6349,
  },
  {
    zipCode: 6317,
    city: "Oberwil b. Zug",
    cantonShort: "ZG",
    latitude: 47.1354,
    longitude: 8.5001,
  },
  {
    zipCode: 6318,
    city: "Walchwil",
    cantonShort: "ZG",
    latitude: 47.1064,
    longitude: 8.5303,
  },
  {
    zipCode: 6319,
    city: "Allenwinden",
    cantonShort: "ZG",
    latitude: 47.1647,
    longitude: 8.5498,
  },
  {
    zipCode: 6330,
    city: "Cham",
    cantonShort: "ZG",
    latitude: 47.1945,
    longitude: 8.4562,
  },
  {
    zipCode: 6331,
    city: "H\u00fcnenberg",
    cantonShort: "ZG",
    latitude: 47.193,
    longitude: 8.4169,
  },
  {
    zipCode: 6332,
    city: "Hagendorn",
    cantonShort: "ZG",
    latitude: 47.2106,
    longitude: 8.4297,
  },
  {
    zipCode: 6333,
    city: "H\u00fcnenberg See",
    cantonShort: "ZG",
    latitude: 47.1709,
    longitude: 8.4468,
  },
  {
    zipCode: 6340,
    city: "Baar",
    cantonShort: "ZG",
    latitude: 47.1996,
    longitude: 8.536,
  },
  {
    zipCode: 6340,
    city: "Sihlbrugg",
    cantonShort: "ZG",
    latitude: 47.229,
    longitude: 8.5728,
  },
  {
    zipCode: 6341,
    city: "Baar",
    cantonShort: "ZG",
    latitude: 47.1963,
    longitude: 8.5295,
  },
  {
    zipCode: 6343,
    city: "Risch",
    cantonShort: "ZG",
    latitude: 47.125,
    longitude: 8.4588,
  },
  {
    zipCode: 6343,
    city: "Holzh\u00e4usern ZG",
    cantonShort: "ZG",
    latitude: 47.1521,
    longitude: 8.4411,
  },
  {
    zipCode: 6343,
    city: "Buonas",
    cantonShort: "ZG",
    latitude: 47.1383,
    longitude: 8.4591,
  },
  {
    zipCode: 6343,
    city: "Rotkreuz",
    cantonShort: "ZG",
    latitude: 47.1446,
    longitude: 8.451,
  },
  {
    zipCode: 6345,
    city: "Neuheim",
    cantonShort: "ZG",
    latitude: 47.1996,
    longitude: 8.584,
  },
  {
    zipCode: 8143,
    city: "Uetliberg",
    cantonShort: "ZH",
    latitude: 47.35,
    longitude: 8.49,
  },
  {
    zipCode: 8143,
    city: "Stallikon",
    cantonShort: "ZH",
    latitude: 47.318,
    longitude: 8.4921,
  },
  {
    zipCode: 8906,
    city: "Bonstetten",
    cantonShort: "ZH",
    latitude: 47.3166,
    longitude: 8.4663,
  },
  {
    zipCode: 8907,
    city: "Wettswil",
    cantonShort: "ZH",
    latitude: 47.3373,
    longitude: 8.4699,
  },
  {
    zipCode: 8908,
    city: "Hedingen",
    cantonShort: "ZH",
    latitude: 47.3015,
    longitude: 8.4509,
  },
  {
    zipCode: 8909,
    city: "Zwillikon",
    cantonShort: "ZH",
    latitude: 47.2895,
    longitude: 8.4311,
  },
  {
    zipCode: 8910,
    city: "Affoltern am Albis",
    cantonShort: "ZH",
    latitude: 47.2784,
    longitude: 8.4582,
  },
  {
    zipCode: 8911,
    city: "Rifferswil",
    cantonShort: "ZH",
    latitude: 47.245,
    longitude: 8.499,
  },
  {
    zipCode: 8912,
    city: "Obfelden",
    cantonShort: "ZH",
    latitude: 47.2615,
    longitude: 8.422,
  },
  {
    zipCode: 8913,
    city: "Ottenbach",
    cantonShort: "ZH",
    latitude: 47.2815,
    longitude: 8.4094,
  },
  {
    zipCode: 8914,
    city: "Aeugst am Albis",
    cantonShort: "ZH",
    latitude: 47.2681,
    longitude: 8.487,
  },
  {
    zipCode: 8914,
    city: "Aeugstertal",
    cantonShort: "ZH",
    latitude: 47.2822,
    longitude: 8.4923,
  },
  {
    zipCode: 8915,
    city: "Hausen am Albis",
    cantonShort: "ZH",
    latitude: 47.2507,
    longitude: 8.5289,
  },
  {
    zipCode: 8925,
    city: "Ebertswil",
    cantonShort: "ZH",
    latitude: 47.2273,
    longitude: 8.5562,
  },
  {
    zipCode: 8926,
    city: "Hauptikon",
    cantonShort: "ZH",
    latitude: 47.2326,
    longitude: 8.4935,
  },
  {
    zipCode: 8926,
    city: "Uerzlikon",
    cantonShort: "ZH",
    latitude: 47.2186,
    longitude: 8.499,
  },
  {
    zipCode: 8926,
    city: "Kappel am Albis",
    cantonShort: "ZH",
    latitude: 47.2273,
    longitude: 8.5219,
  },
  {
    zipCode: 8932,
    city: "Mettmenstetten",
    cantonShort: "ZH",
    latitude: 47.2478,
    longitude: 8.464,
  },
  {
    zipCode: 8933,
    city: "Maschwanden",
    cantonShort: "ZH",
    latitude: 47.2348,
    longitude: 8.4292,
  },
  {
    zipCode: 8934,
    city: "Knonau",
    cantonShort: "ZH",
    latitude: 47.2245,
    longitude: 8.4626,
  },
  {
    zipCode: 8212,
    city: "Nohl",
    cantonShort: "ZH",
    latitude: 47.6666,
    longitude: 8.609,
  },
  {
    zipCode: 8245,
    city: "Feuerthalen",
    cantonShort: "ZH",
    latitude: 47.688,
    longitude: 8.6452,
  },
  {
    zipCode: 8246,
    city: "Langwiesen",
    cantonShort: "ZH",
    latitude: 47.6825,
    longitude: 8.6611,
  },
  {
    zipCode: 8247,
    city: "Flurlingen",
    cantonShort: "ZH",
    latitude: 47.6824,
    longitude: 8.6367,
  },
  {
    zipCode: 8248,
    city: "Uhwiesen",
    cantonShort: "ZH",
    latitude: 47.6702,
    longitude: 8.6465,
  },
  {
    zipCode: 8414,
    city: "Buch am Irchel",
    cantonShort: "ZH",
    latitude: 47.5481,
    longitude: 8.6274,
  },
  {
    zipCode: 8415,
    city: "Berg am Irchel",
    cantonShort: "ZH",
    latitude: 47.5648,
    longitude: 8.5875,
  },
  {
    zipCode: 8415,
    city: "Gr\u00e4slikon",
    cantonShort: "ZH",
    latitude: 47.5566,
    longitude: 8.6055,
  },
  {
    zipCode: 8416,
    city: "Flaach",
    cantonShort: "ZH",
    latitude: 47.5847,
    longitude: 8.6052,
  },
  {
    zipCode: 8444,
    city: "Henggart",
    cantonShort: "ZH",
    latitude: 47.5625,
    longitude: 8.6837,
  },
  {
    zipCode: 8447,
    city: "Dachsen",
    cantonShort: "ZH",
    latitude: 47.662,
    longitude: 8.6235,
  },
  {
    zipCode: 8450,
    city: "Andelfingen",
    cantonShort: "ZH",
    latitude: 47.5928,
    longitude: 8.6755,
  },
  {
    zipCode: 8451,
    city: "Kleinandelfingen",
    cantonShort: "ZH",
    latitude: 47.6075,
    longitude: 8.6845,
  },
  {
    zipCode: 8452,
    city: "Adlikon b. Andelfingen",
    cantonShort: "ZH",
    latitude: 47.5851,
    longitude: 8.7071,
  },
  {
    zipCode: 8453,
    city: "Alten",
    cantonShort: "ZH",
    latitude: 47.5992,
    longitude: 8.6453,
  },
  {
    zipCode: 8457,
    city: "Humlikon",
    cantonShort: "ZH",
    latitude: 47.5753,
    longitude: 8.6753,
  },
  {
    zipCode: 8458,
    city: "Dorf",
    cantonShort: "ZH",
    latitude: 47.5712,
    longitude: 8.6501,
  },
  {
    zipCode: 8459,
    city: "Volken",
    cantonShort: "ZH",
    latitude: 47.5764,
    longitude: 8.63,
  },
  {
    zipCode: 8460,
    city: "Marthalen",
    cantonShort: "ZH",
    latitude: 47.6201,
    longitude: 8.6424,
  },
  {
    zipCode: 8461,
    city: "Oerlingen",
    cantonShort: "ZH",
    latitude: 47.6229,
    longitude: 8.6777,
  },
  {
    zipCode: 8462,
    city: "Rheinau",
    cantonShort: "ZH",
    latitude: 47.6294,
    longitude: 8.6144,
  },
  {
    zipCode: 8463,
    city: "Benken ZH",
    cantonShort: "ZH",
    latitude: 47.6541,
    longitude: 8.6539,
  },
  {
    zipCode: 8464,
    city: "Ellikon am Rhein",
    cantonShort: "ZH",
    latitude: 47.6021,
    longitude: 8.6034,
  },
  {
    zipCode: 8465,
    city: "Wildensbuch",
    cantonShort: "ZH",
    latitude: 47.6526,
    longitude: 8.6781,
  },
  {
    zipCode: 8465,
    city: "Rudolfingen",
    cantonShort: "ZH",
    latitude: 47.6379,
    longitude: 8.6718,
  },
  {
    zipCode: 8466,
    city: "Tr\u00fcllikon",
    cantonShort: "ZH",
    latitude: 47.6368,
    longitude: 8.6975,
  },
  {
    zipCode: 8467,
    city: "Truttikon",
    cantonShort: "ZH",
    latitude: 47.633,
    longitude: 8.73,
  },
  {
    zipCode: 8468,
    city: "Waltalingen",
    cantonShort: "ZH",
    latitude: 47.6209,
    longitude: 8.7725,
  },
  {
    zipCode: 8468,
    city: "Guntalingen",
    cantonShort: "ZH",
    latitude: 47.6355,
    longitude: 8.7603,
  },
  {
    zipCode: 8475,
    city: "Ossingen",
    cantonShort: "ZH",
    latitude: 47.6126,
    longitude: 8.7216,
  },
  {
    zipCode: 8476,
    city: "Unterstammheim",
    cantonShort: "ZH",
    latitude: 47.6469,
    longitude: 8.7895,
  },
  {
    zipCode: 8477,
    city: "Oberstammheim",
    cantonShort: "ZH",
    latitude: 47.6283,
    longitude: 8.8046,
  },
  {
    zipCode: 8478,
    city: "Thalheim an der Thur",
    cantonShort: "ZH",
    latitude: 47.5825,
    longitude: 8.7456,
  },
  {
    zipCode: 8058,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.458,
    longitude: 8.5853,
  },
  {
    zipCode: 8060,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.458,
    longitude: 8.5853,
  },
  {
    zipCode: 8152,
    city: "Glattbrugg",
    cantonShort: "ZH",
    latitude: 47.4332,
    longitude: 8.5629,
  },
  {
    zipCode: 8152,
    city: "Glattpark (Opfikon)",
    cantonShort: "ZH",
    latitude: 47.4217,
    longitude: 8.5633,
  },
  {
    zipCode: 8152,
    city: "Opfikon",
    cantonShort: "ZH",
    latitude: 47.4311,
    longitude: 8.5808,
  },
  {
    zipCode: 8180,
    city: "B\u00fclach",
    cantonShort: "ZH",
    latitude: 47.5274,
    longitude: 8.5415,
  },
  {
    zipCode: 8181,
    city: "H\u00f6ri",
    cantonShort: "ZH",
    latitude: 47.5075,
    longitude: 8.5025,
  },
  {
    zipCode: 8182,
    city: "Hochfelden",
    cantonShort: "ZH",
    latitude: 47.5279,
    longitude: 8.5094,
  },
  {
    zipCode: 8184,
    city: "Bachenb\u00fclach",
    cantonShort: "ZH",
    latitude: 47.5016,
    longitude: 8.5451,
  },
  {
    zipCode: 8185,
    city: "Winkel",
    cantonShort: "ZH",
    latitude: 47.4849,
    longitude: 8.5579,
  },
  {
    zipCode: 8192,
    city: "Glattfelden",
    cantonShort: "ZH",
    latitude: 47.5573,
    longitude: 8.5022,
  },
  {
    zipCode: 8192,
    city: "Zweidlen",
    cantonShort: "ZH",
    latitude: 47.5681,
    longitude: 8.4743,
  },
  {
    zipCode: 8193,
    city: "Eglisau",
    cantonShort: "ZH",
    latitude: 47.5733,
    longitude: 8.5245,
  },
  {
    zipCode: 8194,
    city: "H\u00fcntwangen",
    cantonShort: "ZH",
    latitude: 47.5919,
    longitude: 8.4984,
  },
  {
    zipCode: 8195,
    city: "Wasterkingen",
    cantonShort: "ZH",
    latitude: 47.5954,
    longitude: 8.4716,
  },
  {
    zipCode: 8196,
    city: "Wil ZH",
    cantonShort: "ZH",
    latitude: 47.6085,
    longitude: 8.5091,
  },
  {
    zipCode: 8197,
    city: "Rafz",
    cantonShort: "ZH",
    latitude: 47.6115,
    longitude: 8.543,
  },
  {
    zipCode: 8302,
    city: "Kloten",
    cantonShort: "ZH",
    latitude: 47.458,
    longitude: 8.5849,
  },
  {
    zipCode: 8303,
    city: "Bassersdorf",
    cantonShort: "ZH",
    latitude: 47.4404,
    longitude: 8.6304,
  },
  {
    zipCode: 8304,
    city: "Wallisellen",
    cantonShort: "ZH",
    latitude: 47.4174,
    longitude: 8.5949,
  },
  {
    zipCode: 8305,
    city: "Dietlikon",
    cantonShort: "ZH",
    latitude: 47.4245,
    longitude: 8.615,
  },
  {
    zipCode: 8309,
    city: "N\u00fcrensdorf",
    cantonShort: "ZH",
    latitude: 47.4576,
    longitude: 8.6489,
  },
  {
    zipCode: 8424,
    city: "Embrach",
    cantonShort: "ZH",
    latitude: 47.509,
    longitude: 8.5957,
  },
  {
    zipCode: 8425,
    city: "Oberembrach",
    cantonShort: "ZH",
    latitude: 47.4895,
    longitude: 8.6329,
  },
  {
    zipCode: 8426,
    city: "Lufingen",
    cantonShort: "ZH",
    latitude: 47.4834,
    longitude: 8.5934,
  },
  {
    zipCode: 8427,
    city: "Freienstein",
    cantonShort: "ZH",
    latitude: 47.534,
    longitude: 8.595,
  },
  {
    zipCode: 8427,
    city: "Rorbas",
    cantonShort: "ZH",
    latitude: 47.534,
    longitude: 8.5683,
  },
  {
    zipCode: 8428,
    city: "Teufen ZH",
    cantonShort: "ZH",
    latitude: 47.5503,
    longitude: 8.576,
  },
  {
    zipCode: 8105,
    city: "Watt",
    cantonShort: "ZH",
    latitude: 47.4458,
    longitude: 8.4847,
  },
  {
    zipCode: 8105,
    city: "Regensdorf",
    cantonShort: "ZH",
    latitude: 47.4339,
    longitude: 8.4639,
  },
  {
    zipCode: 8106,
    city: "Adlikon b. Regensdorf",
    cantonShort: "ZH",
    latitude: 47.4527,
    longitude: 8.4634,
  },
  {
    zipCode: 8107,
    city: "Buchs ZH",
    cantonShort: "ZH",
    latitude: 47.4588,
    longitude: 8.437,
  },
  {
    zipCode: 8108,
    city: "D\u00e4llikon",
    cantonShort: "ZH",
    latitude: 47.4394,
    longitude: 8.4324,
  },
  {
    zipCode: 8112,
    city: "Otelfingen",
    cantonShort: "ZH",
    latitude: 47.4645,
    longitude: 8.3897,
  },
  {
    zipCode: 8113,
    city: "Boppelsen",
    cantonShort: "ZH",
    latitude: 47.4718,
    longitude: 8.4069,
  },
  {
    zipCode: 8114,
    city: "D\u00e4nikon ZH",
    cantonShort: "ZH",
    latitude: 47.4447,
    longitude: 8.4058,
  },
  {
    zipCode: 8115,
    city: "H\u00fcttikon",
    cantonShort: "ZH",
    latitude: 47.4418,
    longitude: 8.3897,
  },
  {
    zipCode: 8153,
    city: "R\u00fcmlang",
    cantonShort: "ZH",
    latitude: 47.4475,
    longitude: 8.5288,
  },
  {
    zipCode: 8154,
    city: "Oberglatt ZH",
    cantonShort: "ZH",
    latitude: 47.4779,
    longitude: 8.5239,
  },
  {
    zipCode: 8155,
    city: "Nassenwil",
    cantonShort: "ZH",
    latitude: 47.4653,
    longitude: 8.4704,
  },
  {
    zipCode: 8155,
    city: "Niederhasli",
    cantonShort: "ZH",
    latitude: 47.478,
    longitude: 8.4844,
  },
  {
    zipCode: 8156,
    city: "Oberhasli",
    cantonShort: "ZH",
    latitude: 47.4607,
    longitude: 8.4999,
  },
  {
    zipCode: 8157,
    city: "Dielsdorf",
    cantonShort: "ZH",
    latitude: 47.4765,
    longitude: 8.4544,
  },
  {
    zipCode: 8158,
    city: "Regensberg",
    cantonShort: "ZH",
    latitude: 47.4782,
    longitude: 8.4283,
  },
  {
    zipCode: 8162,
    city: "S\u00fcnikon",
    cantonShort: "ZH",
    latitude: 47.49,
    longitude: 8.4309,
  },
  {
    zipCode: 8162,
    city: "Steinmaur",
    cantonShort: "ZH",
    latitude: 47.501,
    longitude: 8.4483,
  },
  {
    zipCode: 8164,
    city: "Bachs",
    cantonShort: "ZH",
    latitude: 47.5284,
    longitude: 8.4293,
  },
  {
    zipCode: 8165,
    city: "Sch\u00f6fflisdorf",
    cantonShort: "ZH",
    latitude: 47.5037,
    longitude: 8.4191,
  },
  {
    zipCode: 8165,
    city: "Oberweningen",
    cantonShort: "ZH",
    latitude: 47.504,
    longitude: 8.4065,
  },
  {
    zipCode: 8165,
    city: "Schleinikon",
    cantonShort: "ZH",
    latitude: 47.4982,
    longitude: 8.3921,
  },
  {
    zipCode: 8166,
    city: "Niederweningen",
    cantonShort: "ZH",
    latitude: 47.5029,
    longitude: 8.375,
  },
  {
    zipCode: 8172,
    city: "Niederglatt ZH",
    cantonShort: "ZH",
    latitude: 47.4932,
    longitude: 8.5004,
  },
  {
    zipCode: 8173,
    city: "Neerach",
    cantonShort: "ZH",
    latitude: 47.5094,
    longitude: 8.4762,
  },
  {
    zipCode: 8174,
    city: "Stadel b. Niederglatt",
    cantonShort: "ZH",
    latitude: 47.5293,
    longitude: 8.4688,
  },
  {
    zipCode: 8175,
    city: "Windlach",
    cantonShort: "ZH",
    latitude: 47.5459,
    longitude: 8.4716,
  },
  {
    zipCode: 8187,
    city: "Weiach",
    cantonShort: "ZH",
    latitude: 47.5561,
    longitude: 8.4397,
  },
  {
    zipCode: 8340,
    city: "Hinwil",
    cantonShort: "ZH",
    latitude: 47.3033,
    longitude: 8.8457,
  },
  {
    zipCode: 8342,
    city: "Wernetshausen",
    cantonShort: "ZH",
    latitude: 47.2923,
    longitude: 8.8766,
  },
  {
    zipCode: 8344,
    city: "B\u00e4retswil",
    cantonShort: "ZH",
    latitude: 47.3389,
    longitude: 8.8715,
  },
  {
    zipCode: 8345,
    city: "Adetswil",
    cantonShort: "ZH",
    latitude: 47.3419,
    longitude: 8.837,
  },
  {
    zipCode: 8496,
    city: "Steg im T\u00f6sstal",
    cantonShort: "ZH",
    latitude: 47.3411,
    longitude: 8.9514,
  },
  {
    zipCode: 8497,
    city: "Fischenthal",
    cantonShort: "ZH",
    latitude: 47.3317,
    longitude: 8.9255,
  },
  {
    zipCode: 8498,
    city: "Gibswil",
    cantonShort: "ZH",
    latitude: 47.3218,
    longitude: 8.9043,
  },
  {
    zipCode: 8607,
    city: "Aathal-Seegr\u00e4ben",
    cantonShort: "ZH",
    latitude: 47.3409,
    longitude: 8.7669,
  },
  {
    zipCode: 8608,
    city: "Bubikon",
    cantonShort: "ZH",
    latitude: 47.2699,
    longitude: 8.8118,
  },
  {
    zipCode: 8614,
    city: "Bertschikon (Gossau ZH)",
    cantonShort: "ZH",
    latitude: 47.321,
    longitude: 8.7591,
  },
  {
    zipCode: 8620,
    city: "Wetzikon ZH 1",
    cantonShort: "ZH",
    latitude: 47.3243,
    longitude: 8.8047,
  },
  {
    zipCode: 8620,
    city: "Wetzikon ZH",
    cantonShort: "ZH",
    latitude: 47.3226,
    longitude: 8.8014,
  },
  {
    zipCode: 8623,
    city: "Wetzikon ZH",
    cantonShort: "ZH",
    latitude: 47.3325,
    longitude: 8.8194,
  },
  {
    zipCode: 8624,
    city: "Gr\u00fct (Gossau ZH)",
    cantonShort: "ZH",
    latitude: 47.3088,
    longitude: 8.7819,
  },
  {
    zipCode: 8625,
    city: "Gossau ZH",
    cantonShort: "ZH",
    latitude: 47.3041,
    longitude: 8.7531,
  },
  {
    zipCode: 8626,
    city: "Ottikon (Gossau ZH)",
    cantonShort: "ZH",
    latitude: 47.2912,
    longitude: 8.789,
  },
  {
    zipCode: 8627,
    city: "Gr\u00fcningen",
    cantonShort: "ZH",
    latitude: 47.2794,
    longitude: 8.7619,
  },
  {
    zipCode: 8630,
    city: "R\u00fcti ZH",
    cantonShort: "ZH",
    latitude: 47.2582,
    longitude: 8.865,
  },
  {
    zipCode: 8632,
    city: "Tann",
    cantonShort: "ZH",
    latitude: 47.2679,
    longitude: 8.8502,
  },
  {
    zipCode: 8633,
    city: "Wolfhausen",
    cantonShort: "ZH",
    latitude: 47.2566,
    longitude: 8.7985,
  },
  {
    zipCode: 8635,
    city: "D\u00fcrnten",
    cantonShort: "ZH",
    latitude: 47.2771,
    longitude: 8.8544,
  },
  {
    zipCode: 8636,
    city: "Wald ZH",
    cantonShort: "ZH",
    latitude: 47.2888,
    longitude: 8.9231,
  },
  {
    zipCode: 8637,
    city: "Laupen ZH",
    cantonShort: "ZH",
    latitude: 47.2662,
    longitude: 8.931,
  },
  {
    zipCode: 8134,
    city: "Adliswil",
    cantonShort: "ZH",
    latitude: 47.3093,
    longitude: 8.5213,
  },
  {
    zipCode: 8134,
    city: "Adliswil 1",
    cantonShort: "ZH",
    latitude: 47.3093,
    longitude: 8.5213,
  },
  {
    zipCode: 8135,
    city: "Sihlwald",
    cantonShort: "ZH",
    latitude: 47.268,
    longitude: 8.5567,
  },
  {
    zipCode: 8135,
    city: "Sihlbrugg Station",
    cantonShort: "ZH",
    latitude: 47.2419,
    longitude: 8.575,
  },
  {
    zipCode: 8135,
    city: "Langnau am Albis",
    cantonShort: "ZH",
    latitude: 47.2707,
    longitude: 8.5387,
  },
  {
    zipCode: 8136,
    city: "Gattikon",
    cantonShort: "ZH",
    latitude: 47.2825,
    longitude: 8.552,
  },
  {
    zipCode: 8800,
    city: "Thalwil",
    cantonShort: "ZH",
    latitude: 47.2917,
    longitude: 8.5676,
  },
  {
    zipCode: 8802,
    city: "Kilchberg ZH",
    cantonShort: "ZH",
    latitude: 47.3249,
    longitude: 8.5488,
  },
  {
    zipCode: 8803,
    city: "R\u00fcschlikon",
    cantonShort: "ZH",
    latitude: 47.3063,
    longitude: 8.5526,
  },
  {
    zipCode: 8804,
    city: "Au ZH",
    cantonShort: "ZH",
    latitude: 47.2478,
    longitude: 8.6491,
  },
  {
    zipCode: 8805,
    city: "Richterswil",
    cantonShort: "ZH",
    latitude: 47.213,
    longitude: 8.7063,
  },
  {
    zipCode: 8810,
    city: "Horgen 1",
    cantonShort: "ZH",
    latitude: 47.2487,
    longitude: 8.5848,
  },
  {
    zipCode: 8810,
    city: "Horgen",
    cantonShort: "ZH",
    latitude: 47.2524,
    longitude: 8.608,
  },
  {
    zipCode: 8815,
    city: "Horgenberg",
    cantonShort: "ZH",
    latitude: 47.2499,
    longitude: 8.5807,
  },
  {
    zipCode: 8816,
    city: "Hirzel",
    cantonShort: "ZH",
    latitude: 47.2198,
    longitude: 8.6005,
  },
  {
    zipCode: 8820,
    city: "W\u00e4denswil",
    cantonShort: "ZH",
    latitude: 47.2239,
    longitude: 8.6596,
  },
  {
    zipCode: 8824,
    city: "Sch\u00f6nenberg ZH",
    cantonShort: "ZH",
    latitude: 47.194,
    longitude: 8.64,
  },
  {
    zipCode: 8825,
    city: "H\u00fctten",
    cantonShort: "ZH",
    latitude: 47.173,
    longitude: 8.666,
  },
  {
    zipCode: 8833,
    city: "Samstagern",
    cantonShort: "ZH",
    latitude: 47.1938,
    longitude: 8.6789,
  },
  {
    zipCode: 8942,
    city: "Oberrieden",
    cantonShort: "ZH",
    latitude: 47.2746,
    longitude: 8.5778,
  },
  {
    zipCode: 8125,
    city: "Zollikerberg",
    cantonShort: "ZH",
    latitude: 47.3462,
    longitude: 8.6087,
  },
  {
    zipCode: 8126,
    city: "Zumikon",
    cantonShort: "ZH",
    latitude: 47.3341,
    longitude: 8.6262,
  },
  {
    zipCode: 8127,
    city: "Forch",
    cantonShort: "ZH",
    latitude: 47.3216,
    longitude: 8.6462,
  },
  {
    zipCode: 8618,
    city: "Oetwil am See",
    cantonShort: "ZH",
    latitude: 47.2739,
    longitude: 8.7235,
  },
  {
    zipCode: 8634,
    city: "Hombrechtikon",
    cantonShort: "ZH",
    latitude: 47.2558,
    longitude: 8.7634,
  },
  {
    zipCode: 8700,
    city: "K\u00fcsnacht ZH",
    cantonShort: "ZH",
    latitude: 47.3201,
    longitude: 8.5893,
  },
  {
    zipCode: 8702,
    city: "Zollikon",
    cantonShort: "ZH",
    latitude: 47.34,
    longitude: 8.5783,
  },
  {
    zipCode: 8702,
    city: "Zollikon Dorf",
    cantonShort: "ZH",
    latitude: 47.3433,
    longitude: 8.5947,
  },
  {
    zipCode: 8703,
    city: "Erlenbach ZH",
    cantonShort: "ZH",
    latitude: 47.3023,
    longitude: 8.5998,
  },
  {
    zipCode: 8704,
    city: "Herrliberg",
    cantonShort: "ZH",
    latitude: 47.2954,
    longitude: 8.6254,
  },
  {
    zipCode: 8706,
    city: "Meilen",
    cantonShort: "ZH",
    latitude: 47.2765,
    longitude: 8.6462,
  },
  {
    zipCode: 8707,
    city: "Uetikon am See",
    cantonShort: "ZH",
    latitude: 47.2673,
    longitude: 8.6792,
  },
  {
    zipCode: 8708,
    city: "M\u00e4nnedorf",
    cantonShort: "ZH",
    latitude: 47.2543,
    longitude: 8.6949,
  },
  {
    zipCode: 8712,
    city: "St\u00e4fa",
    cantonShort: "ZH",
    latitude: 47.2409,
    longitude: 8.7254,
  },
  {
    zipCode: 8713,
    city: "Uerikon",
    cantonShort: "ZH",
    latitude: 47.2263,
    longitude: 8.7554,
  },
  {
    zipCode: 8714,
    city: "Feldbach",
    cantonShort: "ZH",
    latitude: 47.2344,
    longitude: 8.7879,
  },
  {
    zipCode: 8307,
    city: "Effretikon",
    cantonShort: "ZH",
    latitude: 47.4238,
    longitude: 8.6928,
  },
  {
    zipCode: 8307,
    city: "Ottikon b. Kemptthal",
    cantonShort: "ZH",
    latitude: 47.4374,
    longitude: 8.7222,
  },
  {
    zipCode: 8308,
    city: "Agasul",
    cantonShort: "ZH",
    latitude: 47.4198,
    longitude: 8.7433,
  },
  {
    zipCode: 8308,
    city: "Illnau",
    cantonShort: "ZH",
    latitude: 47.4086,
    longitude: 8.7194,
  },
  {
    zipCode: 8310,
    city: "Kemptthal",
    cantonShort: "ZH",
    latitude: 47.4574,
    longitude: 8.7116,
  },
  {
    zipCode: 8310,
    city: "Grafstal",
    cantonShort: "ZH",
    latitude: 47.4441,
    longitude: 8.6983,
  },
  {
    zipCode: 8312,
    city: "Winterberg ZH",
    cantonShort: "ZH",
    latitude: 47.4586,
    longitude: 8.691,
  },
  {
    zipCode: 8314,
    city: "Kyburg",
    cantonShort: "ZH",
    latitude: 47.4521,
    longitude: 8.7428,
  },
  {
    zipCode: 8315,
    city: "Lindau",
    cantonShort: "ZH",
    latitude: 47.4433,
    longitude: 8.6764,
  },
  {
    zipCode: 8317,
    city: "Tagelswangen",
    cantonShort: "ZH",
    latitude: 47.4273,
    longitude: 8.6692,
  },
  {
    zipCode: 8320,
    city: "Fehraltorf",
    cantonShort: "ZH",
    latitude: 47.387,
    longitude: 8.75,
  },
  {
    zipCode: 8322,
    city: "Madetswil",
    cantonShort: "ZH",
    latitude: 47.4132,
    longitude: 8.7945,
  },
  {
    zipCode: 8322,
    city: "G\u00fcndisau",
    cantonShort: "ZH",
    latitude: 47.398,
    longitude: 8.806,
  },
  {
    zipCode: 8330,
    city: "Pf\u00e4ffikon ZH",
    cantonShort: "ZH",
    latitude: 47.3701,
    longitude: 8.7914,
  },
  {
    zipCode: 8331,
    city: "Auslikon",
    cantonShort: "ZH",
    latitude: 47.3449,
    longitude: 8.8079,
  },
  {
    zipCode: 8332,
    city: "Russikon",
    cantonShort: "ZH",
    latitude: 47.3954,
    longitude: 8.7806,
  },
  {
    zipCode: 8332,
    city: "Rumlikon",
    cantonShort: "ZH",
    latitude: 47.408,
    longitude: 8.7612,
  },
  {
    zipCode: 8335,
    city: "Hittnau",
    cantonShort: "ZH",
    latitude: 47.3663,
    longitude: 8.8292,
  },
  {
    zipCode: 8484,
    city: "Neschwil",
    cantonShort: "ZH",
    latitude: 47.428,
    longitude: 8.7932,
  },
  {
    zipCode: 8484,
    city: "Weisslingen",
    cantonShort: "ZH",
    latitude: 47.4338,
    longitude: 8.7704,
  },
  {
    zipCode: 8484,
    city: "Theilingen",
    cantonShort: "ZH",
    latitude: 47.417,
    longitude: 8.767,
  },
  {
    zipCode: 8489,
    city: "Ehrikon",
    cantonShort: "ZH",
    latitude: 47.4163,
    longitude: 8.8115,
  },
  {
    zipCode: 8489,
    city: "Wildberg",
    cantonShort: "ZH",
    latitude: 47.4279,
    longitude: 8.8229,
  },
  {
    zipCode: 8489,
    city: "Schalchen",
    cantonShort: "ZH",
    latitude: 47.4052,
    longitude: 8.8331,
  },
  {
    zipCode: 8492,
    city: "Wila",
    cantonShort: "ZH",
    latitude: 47.4098,
    longitude: 8.8653,
  },
  {
    zipCode: 8493,
    city: "Saland",
    cantonShort: "ZH",
    latitude: 47.3874,
    longitude: 8.8601,
  },
  {
    zipCode: 8494,
    city: "Bauma",
    cantonShort: "ZH",
    latitude: 47.368,
    longitude: 8.8916,
  },
  {
    zipCode: 8499,
    city: "Sternenberg",
    cantonShort: "ZH",
    latitude: 47.3888,
    longitude: 8.914,
  },
  {
    zipCode: 8044,
    city: "Gockhausen",
    cantonShort: "ZH",
    latitude: 47.3776,
    longitude: 8.6049,
  },
  {
    zipCode: 8117,
    city: "F\u00e4llanden",
    cantonShort: "ZH",
    latitude: 47.3709,
    longitude: 8.6421,
  },
  {
    zipCode: 8118,
    city: "Pfaffhausen",
    cantonShort: "ZH",
    latitude: 47.3658,
    longitude: 8.6247,
  },
  {
    zipCode: 8121,
    city: "Benglen",
    cantonShort: "ZH",
    latitude: 47.3609,
    longitude: 8.6375,
  },
  {
    zipCode: 8122,
    city: "Binz",
    cantonShort: "ZH",
    latitude: 47.357,
    longitude: 8.6269,
  },
  {
    zipCode: 8123,
    city: "Ebmatingen",
    cantonShort: "ZH",
    latitude: 47.3514,
    longitude: 8.6405,
  },
  {
    zipCode: 8124,
    city: "Maur",
    cantonShort: "ZH",
    latitude: 47.3376,
    longitude: 8.6726,
  },
  {
    zipCode: 8132,
    city: "Egg b. Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.2938,
    longitude: 8.6895,
  },
  {
    zipCode: 8132,
    city: "Hinteregg",
    cantonShort: "ZH",
    latitude: 47.3111,
    longitude: 8.6853,
  },
  {
    zipCode: 8133,
    city: "Esslingen",
    cantonShort: "ZH",
    latitude: 47.2906,
    longitude: 8.7137,
  },
  {
    zipCode: 8306,
    city: "Br\u00fcttisellen",
    cantonShort: "ZH",
    latitude: 47.4219,
    longitude: 8.6342,
  },
  {
    zipCode: 8600,
    city: "D\u00fcbendorf 1",
    cantonShort: "ZH",
    latitude: 47.3905,
    longitude: 8.6172,
  },
  {
    zipCode: 8600,
    city: "D\u00fcbendorf",
    cantonShort: "ZH",
    latitude: 47.3937,
    longitude: 8.6202,
  },
  {
    zipCode: 8602,
    city: "Wangen b. D\u00fcbendorf",
    cantonShort: "ZH",
    latitude: 47.4096,
    longitude: 8.6508,
  },
  {
    zipCode: 8603,
    city: "Schwerzenbach",
    cantonShort: "ZH",
    latitude: 47.3811,
    longitude: 8.6562,
  },
  {
    zipCode: 8604,
    city: "Volketswil",
    cantonShort: "ZH",
    latitude: 47.3942,
    longitude: 8.6815,
  },
  {
    zipCode: 8605,
    city: "Gutenswil",
    cantonShort: "ZH",
    latitude: 47.3867,
    longitude: 8.7177,
  },
  {
    zipCode: 8606,
    city: "N\u00e4nikon",
    cantonShort: "ZH",
    latitude: 47.3715,
    longitude: 8.6955,
  },
  {
    zipCode: 8606,
    city: "Greifensee",
    cantonShort: "ZH",
    latitude: 47.3635,
    longitude: 8.6777,
  },
  {
    zipCode: 8610,
    city: "Uster 1",
    cantonShort: "ZH",
    latitude: 47.3532,
    longitude: 8.7216,
  },
  {
    zipCode: 8610,
    city: "Uster",
    cantonShort: "ZH",
    latitude: 47.3478,
    longitude: 8.721,
  },
  {
    zipCode: 8613,
    city: "Uster 3",
    cantonShort: "ZH",
    latitude: 47.3532,
    longitude: 8.7216,
  },
  {
    zipCode: 8614,
    city: "Sulzbach",
    cantonShort: "ZH",
    latitude: 47.3278,
    longitude: 8.7465,
  },
  {
    zipCode: 8615,
    city: "Wermatswil",
    cantonShort: "ZH",
    latitude: 47.365,
    longitude: 8.7379,
  },
  {
    zipCode: 8615,
    city: "Freudwil",
    cantonShort: "ZH",
    latitude: 47.3759,
    longitude: 8.7328,
  },
  {
    zipCode: 8616,
    city: "Riedikon",
    cantonShort: "ZH",
    latitude: 47.3299,
    longitude: 8.7088,
  },
  {
    zipCode: 8617,
    city: "M\u00f6nchaltorf",
    cantonShort: "ZH",
    latitude: 47.3122,
    longitude: 8.7219,
  },
  {
    zipCode: 8311,
    city: "Br\u00fctten",
    cantonShort: "ZH",
    latitude: 47.4748,
    longitude: 8.6724,
  },
  {
    zipCode: 8352,
    city: "Ricketwil (Winterthur)",
    cantonShort: "ZH",
    latitude: 47.487,
    longitude: 8.7977,
  },
  {
    zipCode: 8352,
    city: "Elsau",
    cantonShort: "ZH",
    latitude: 47.5026,
    longitude: 8.8079,
  },
  {
    zipCode: 8353,
    city: "Elgg",
    cantonShort: "ZH",
    latitude: 47.4918,
    longitude: 8.863,
  },
  {
    zipCode: 8354,
    city: "Hofstetten ZH",
    cantonShort: "ZH",
    latitude: 47.4692,
    longitude: 8.8588,
  },
  {
    zipCode: 8354,
    city: "Dickbuch",
    cantonShort: "ZH",
    latitude: 47.4958,
    longitude: 8.8324,
  },
  {
    zipCode: 8400,
    city: "Winterthur",
    cantonShort: "ZH",
    latitude: 47.4931,
    longitude: 8.7297,
  },
  {
    zipCode: 8401,
    city: "Winterthur",
    cantonShort: "ZH",
    latitude: 47.5056,
    longitude: 8.7241,
  },
  {
    zipCode: 8403,
    city: "Winterthur",
    cantonShort: "ZH",
    latitude: 47.5056,
    longitude: 8.7241,
  },
  {
    zipCode: 8404,
    city: "Reutlingen (Winterthur)",
    cantonShort: "ZH",
    latitude: 47.527,
    longitude: 8.7493,
  },
  {
    zipCode: 8404,
    city: "Stadel (Winterthur)",
    cantonShort: "ZH",
    latitude: 47.5388,
    longitude: 8.7656,
  },
  {
    zipCode: 8404,
    city: "Winterthur",
    cantonShort: "ZH",
    latitude: 47.5094,
    longitude: 8.7581,
  },
  {
    zipCode: 8405,
    city: "Winterthur",
    cantonShort: "ZH",
    latitude: 47.478,
    longitude: 8.776,
  },
  {
    zipCode: 8406,
    city: "Winterthur",
    cantonShort: "ZH",
    latitude: 47.4829,
    longitude: 8.7021,
  },
  {
    zipCode: 8408,
    city: "Winterthur",
    cantonShort: "ZH",
    latitude: 47.5074,
    longitude: 8.6831,
  },
  {
    zipCode: 8409,
    city: "Winterthur",
    cantonShort: "ZH",
    latitude: 47.5051,
    longitude: 8.7786,
  },
  {
    zipCode: 8412,
    city: "Aesch (Neftenbach)",
    cantonShort: "ZH",
    latitude: 47.5441,
    longitude: 8.6794,
  },
  {
    zipCode: 8412,
    city: "Riet (Neftenbach)",
    cantonShort: "ZH",
    latitude: 47.5364,
    longitude: 8.6893,
  },
  {
    zipCode: 8412,
    city: "H\u00fcnikon (Neftenbach)",
    cantonShort: "ZH",
    latitude: 47.5534,
    longitude: 8.6634,
  },
  {
    zipCode: 8413,
    city: "Neftenbach",
    cantonShort: "ZH",
    latitude: 47.5298,
    longitude: 8.6605,
  },
  {
    zipCode: 8418,
    city: "Schlatt ZH",
    cantonShort: "ZH",
    latitude: 47.4703,
    longitude: 8.8272,
  },
  {
    zipCode: 8421,
    city: "D\u00e4ttlikon",
    cantonShort: "ZH",
    latitude: 47.5274,
    longitude: 8.6221,
  },
  {
    zipCode: 8422,
    city: "Pfungen",
    cantonShort: "ZH",
    latitude: 47.5112,
    longitude: 8.6425,
  },
  {
    zipCode: 8442,
    city: "Hettlingen",
    cantonShort: "ZH",
    latitude: 47.5495,
    longitude: 8.7062,
  },
  {
    zipCode: 8471,
    city: "Rutschwil (D\u00e4gerlen)",
    cantonShort: "ZH",
    latitude: 47.5561,
    longitude: 8.7315,
  },
  {
    zipCode: 8471,
    city: "Berg (D\u00e4gerlen)",
    cantonShort: "ZH",
    latitude: 47.5664,
    longitude: 8.7351,
  },
  {
    zipCode: 8471,
    city: "D\u00e4gerlen",
    cantonShort: "ZH",
    latitude: 47.5616,
    longitude: 8.7177,
  },
  {
    zipCode: 8471,
    city: "Oberwil (D\u00e4gerlen)",
    cantonShort: "ZH",
    latitude: 47.5704,
    longitude: 8.7118,
  },
  {
    zipCode: 8471,
    city: "B\u00e4nk (D\u00e4gerlen)",
    cantonShort: "ZH",
    latitude: 47.5495,
    longitude: 8.7388,
  },
  {
    zipCode: 8472,
    city: "Seuzach",
    cantonShort: "ZH",
    latitude: 47.5343,
    longitude: 8.7261,
  },
  {
    zipCode: 8474,
    city: "Dinhard",
    cantonShort: "ZH",
    latitude: 47.5578,
    longitude: 8.7631,
  },
  {
    zipCode: 8479,
    city: "Altikon",
    cantonShort: "ZH",
    latitude: 47.5773,
    longitude: 8.7841,
  },
  {
    zipCode: 8482,
    city: "Sennhof (Winterthur)",
    cantonShort: "ZH",
    latitude: 47.4669,
    longitude: 8.7551,
  },
  {
    zipCode: 8483,
    city: "Kollbrunn",
    cantonShort: "ZH",
    latitude: 47.4557,
    longitude: 8.7783,
  },
  {
    zipCode: 8486,
    city: "Rikon im T\u00f6sstal",
    cantonShort: "ZH",
    latitude: 47.4505,
    longitude: 8.8041,
  },
  {
    zipCode: 8487,
    city: "R\u00e4mism\u00fchle",
    cantonShort: "ZH",
    latitude: 47.4399,
    longitude: 8.8166,
  },
  {
    zipCode: 8487,
    city: "Zell ZH",
    cantonShort: "ZH",
    latitude: 47.4513,
    longitude: 8.8297,
  },
  {
    zipCode: 8488,
    city: "Turbenthal",
    cantonShort: "ZH",
    latitude: 47.4373,
    longitude: 8.8744,
  },
  {
    zipCode: 8495,
    city: "Schmidr\u00fcti",
    cantonShort: "ZH",
    latitude: 47.4202,
    longitude: 8.91,
  },
  {
    zipCode: 8523,
    city: "Hagenbuch ZH",
    cantonShort: "ZH",
    latitude: 47.5159,
    longitude: 8.8759,
  },
  {
    zipCode: 8542,
    city: "Wiesendangen",
    cantonShort: "ZH",
    latitude: 47.5222,
    longitude: 8.7949,
  },
  {
    zipCode: 8543,
    city: "Bertschikon",
    cantonShort: "ZH",
    latitude: 47.5254,
    longitude: 8.8371,
  },
  {
    zipCode: 8543,
    city: "Kefikon ZH",
    cantonShort: "ZH",
    latitude: 47.5479,
    longitude: 8.8281,
  },
  {
    zipCode: 8543,
    city: "Gundetswil",
    cantonShort: "ZH",
    latitude: 47.5387,
    longitude: 8.8197,
  },
  {
    zipCode: 8544,
    city: "Attikon",
    cantonShort: "ZH",
    latitude: 47.5361,
    longitude: 8.8004,
  },
  {
    zipCode: 8545,
    city: "Rickenbach Sulz",
    cantonShort: "ZH",
    latitude: 47.5408,
    longitude: 8.7865,
  },
  {
    zipCode: 8545,
    city: "Rickenbach ZH",
    cantonShort: "ZH",
    latitude: 47.5548,
    longitude: 8.7957,
  },
  {
    zipCode: 8546,
    city: "Menzengr\u00fct",
    cantonShort: "ZH",
    latitude: 47.5487,
    longitude: 8.8166,
  },
  {
    zipCode: 8548,
    city: "Ellikon an der Thur",
    cantonShort: "ZH",
    latitude: 47.5631,
    longitude: 8.8241,
  },
  {
    zipCode: 8010,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.395,
    longitude: 8.4488,
  },
  {
    zipCode: 8011,
    city: "Z\u00fcrich M\u00fclligen SC",
    cantonShort: "ZH",
    latitude: 47.395,
    longitude: 8.4488,
  },
  {
    zipCode: 8012,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.395,
    longitude: 8.4488,
  },
  {
    zipCode: 8102,
    city: "Oberengstringen",
    cantonShort: "ZH",
    latitude: 47.4102,
    longitude: 8.466,
  },
  {
    zipCode: 8103,
    city: "Unterengstringen",
    cantonShort: "ZH",
    latitude: 47.4112,
    longitude: 8.4429,
  },
  {
    zipCode: 8104,
    city: "Weiningen ZH",
    cantonShort: "ZH",
    latitude: 47.4217,
    longitude: 8.4322,
  },
  {
    zipCode: 8142,
    city: "Uitikon Waldegg",
    cantonShort: "ZH",
    latitude: 47.367,
    longitude: 8.4593,
  },
  {
    zipCode: 8901,
    city: "Urdorf Tessi",
    cantonShort: "ZH",
    latitude: 47.3798,
    longitude: 8.4224,
  },
  {
    zipCode: 8901,
    city: "Urdorf",
    cantonShort: "ZH",
    latitude: 47.3798,
    longitude: 8.4224,
  },
  {
    zipCode: 8902,
    city: "Urdorf",
    cantonShort: "ZH",
    latitude: 47.3799,
    longitude: 8.4224,
  },
  {
    zipCode: 8903,
    city: "Birmensdorf ZH",
    cantonShort: "ZH",
    latitude: 47.3539,
    longitude: 8.4344,
  },
  {
    zipCode: 8904,
    city: "Aesch ZH",
    cantonShort: "ZH",
    latitude: 47.3354,
    longitude: 8.433,
  },
  {
    zipCode: 8951,
    city: "Fahrweid",
    cantonShort: "ZH",
    latitude: 47.4095,
    longitude: 8.415,
  },
  {
    zipCode: 8952,
    city: "Schlieren",
    cantonShort: "ZH",
    latitude: 47.395,
    longitude: 8.449,
  },
  {
    zipCode: 8953,
    city: "Dietikon 2",
    cantonShort: "ZH",
    latitude: 47.402,
    longitude: 8.3982,
  },
  {
    zipCode: 8953,
    city: "Dietikon 1",
    cantonShort: "ZH",
    latitude: 47.402,
    longitude: 8.3982,
  },
  {
    zipCode: 8953,
    city: "Dietikon",
    cantonShort: "ZH",
    latitude: 47.402,
    longitude: 8.3981,
  },
  {
    zipCode: 8954,
    city: "Geroldswil",
    cantonShort: "ZH",
    latitude: 47.4234,
    longitude: 8.4127,
  },
  {
    zipCode: 8955,
    city: "Oetwil an der Limmat",
    cantonShort: "ZH",
    latitude: 47.431,
    longitude: 8.3979,
  },
  {
    zipCode: 8000,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.3667,
    longitude: 8.55,
  },
  {
    zipCode: 8001,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.3721,
    longitude: 8.5417,
  },
  {
    zipCode: 8002,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.3601,
    longitude: 8.5327,
  },
  {
    zipCode: 8003,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.3733,
    longitude: 8.5139,
  },
  {
    zipCode: 8004,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.3789,
    longitude: 8.5204,
  },
  {
    zipCode: 8005,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.3876,
    longitude: 8.5207,
  },
  {
    zipCode: 8006,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.3873,
    longitude: 8.5499,
  },
  {
    zipCode: 8008,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.3538,
    longitude: 8.5587,
  },
  {
    zipCode: 8017,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.3667,
    longitude: 8.55,
  },
  {
    zipCode: 8018,
    city: "Z\u00fcrich PF",
    cantonShort: "ZH",
    latitude: 47.3828,
    longitude: 8.5307,
  },
  {
    zipCode: 8021,
    city: "Z\u00fcrich 1",
    cantonShort: "ZH",
    latitude: 47.3828,
    longitude: 8.5307,
  },
  {
    zipCode: 8022,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.3667,
    longitude: 8.55,
  },
  {
    zipCode: 8024,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.3667,
    longitude: 8.55,
  },
  {
    zipCode: 8027,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.3667,
    longitude: 8.55,
  },
  {
    zipCode: 8031,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.3667,
    longitude: 8.55,
  },
  {
    zipCode: 8032,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.368,
    longitude: 8.5665,
  },
  {
    zipCode: 8034,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.3667,
    longitude: 8.55,
  },
  {
    zipCode: 8036,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.3667,
    longitude: 8.55,
  },
  {
    zipCode: 8037,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.3978,
    longitude: 8.5242,
  },
  {
    zipCode: 8038,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.342,
    longitude: 8.5352,
  },
  {
    zipCode: 8040,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.3667,
    longitude: 8.55,
  },
  {
    zipCode: 8041,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.3339,
    longitude: 8.5139,
  },
  {
    zipCode: 8042,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.3667,
    longitude: 8.55,
  },
  {
    zipCode: 8044,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.3807,
    longitude: 8.5753,
  },
  {
    zipCode: 8045,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.3531,
    longitude: 8.5089,
  },
  {
    zipCode: 8046,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.4221,
    longitude: 8.5092,
  },
  {
    zipCode: 8047,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.3739,
    longitude: 8.4861,
  },
  {
    zipCode: 8048,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.3862,
    longitude: 8.4793,
  },
  {
    zipCode: 8049,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.4085,
    longitude: 8.4944,
  },
  {
    zipCode: 8050,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.4119,
    longitude: 8.5512,
  },
  {
    zipCode: 8051,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.4006,
    longitude: 8.5781,
  },
  {
    zipCode: 8052,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.4243,
    longitude: 8.5403,
  },
  {
    zipCode: 8053,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.3609,
    longitude: 8.5975,
  },
  {
    zipCode: 8055,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.364,
    longitude: 8.4944,
  },
  {
    zipCode: 8057,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.4,
    longitude: 8.5445,
  },
  {
    zipCode: 8063,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.3667,
    longitude: 8.55,
  },
  {
    zipCode: 8064,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.3983,
    longitude: 8.4855,
  },
  {
    zipCode: 8070,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.3667,
    longitude: 8.55,
  },
  {
    zipCode: 8071,
    city: "Z\u00fcrich CS PZ",
    cantonShort: "ZH",
    latitude: 47.3828,
    longitude: 8.5307,
  },
  {
    zipCode: 8074,
    city: "Z\u00fcrich Voice Pub",
    cantonShort: "ZH",
    latitude: 47.3828,
    longitude: 8.5307,
  },
  {
    zipCode: 8074,
    city: "Z\u00fcrich Voice Publishing",
    cantonShort: "ZH",
    latitude: 47.3828,
    longitude: 8.5307,
  },
  {
    zipCode: 8075,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.3667,
    longitude: 8.55,
  },
  {
    zipCode: 8080,
    city: "Z\u00fcrich 80",
    cantonShort: "ZH",
    latitude: 47.3828,
    longitude: 8.5307,
  },
  {
    zipCode: 8081,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.3828,
    longitude: 8.5307,
  },
  {
    zipCode: 8081,
    city: "Z\u00fcrich Helsana",
    cantonShort: "ZH",
    latitude: 47.3828,
    longitude: 8.5307,
  },
  {
    zipCode: 8085,
    city: "Z\u00fcrich Versicherung",
    cantonShort: "ZH",
    latitude: 47.3828,
    longitude: 8.5307,
  },
  {
    zipCode: 8085,
    city: "Z\u00fcrich Versich.",
    cantonShort: "ZH",
    latitude: 47.3828,
    longitude: 8.5307,
  },
  {
    zipCode: 8086,
    city: "Z\u00fcrich R Digest",
    cantonShort: "ZH",
    latitude: 47.3828,
    longitude: 8.5307,
  },
  {
    zipCode: 8086,
    city: "Z\u00fcrich Reader's Digest",
    cantonShort: "ZH",
    latitude: 47.3828,
    longitude: 8.5307,
  },
  {
    zipCode: 8087,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.3667,
    longitude: 8.55,
  },
  {
    zipCode: 8088,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.3667,
    longitude: 8.55,
  },
  {
    zipCode: 8090,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.3667,
    longitude: 8.55,
  },
  {
    zipCode: 8091,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.3667,
    longitude: 8.55,
  },
  {
    zipCode: 8092,
    city: "Z\u00fcrich ETH-Zentrum",
    cantonShort: "ZH",
    latitude: 47.3828,
    longitude: 8.5307,
  },
  {
    zipCode: 8092,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.3828,
    longitude: 8.5307,
  },
  {
    zipCode: 8093,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.3828,
    longitude: 8.5307,
  },
  {
    zipCode: 8093,
    city: "Z\u00fcrich ETH-H\u00f6nggerberg",
    cantonShort: "ZH",
    latitude: 47.3828,
    longitude: 8.5307,
  },
  {
    zipCode: 8096,
    city: "Z\u00fcrich IBRS local",
    cantonShort: "ZH",
    latitude: 47.3828,
    longitude: 8.5307,
  },
  {
    zipCode: 8098,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.3667,
    longitude: 8.55,
  },
  {
    zipCode: 8099,
    city: "Z\u00fcrich",
    cantonShort: "ZH",
    latitude: 47.3828,
    longitude: 8.5307,
  },
  {
    zipCode: 8099,
    city: "Z\u00fcrich Sonderdienste",
    cantonShort: "ZH",
    latitude: 47.3828,
    longitude: 8.5307,
  },
];

export const getFormattedZipCodePlacesLocationsSwiss = () => {
  return getPlacesWithZipAndLocationFromSwiss().map((place) => {
    return {
      ...place,
      originalName: place.name,
      name: `[${place.zipCode}] ${place.city}`,
    };
  });
};