import React from "react";
import NumberFormat from "react-number-format";

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, name, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={"’"}
      suffix={name === "price" ? ".-" : ""}
    />
  );
};

export default NumberFormatCustom;
