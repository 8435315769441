import React from "react";

const VehicleAddress = (props) => {
  const { vehiclelocation, page } = props;

  return (
    <span>
      {page === "details" && vehiclelocation?.streetandnumber && (
        <>
          {vehiclelocation.streetandnumber}
          <br />
        </>
      )}
      {vehiclelocation?.zipcode} {vehiclelocation?.city}
    </span>
  );
};

export default VehicleAddress;
