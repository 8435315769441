import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { formatAddress } from "../../../lib/utils";
import Api from "../../../lib/api";
import MerchantForm from "../../../components/merchant-form/index";
import "./index.css";

const profileInfo = (props) => {
  const { profileData, phoneTypes, errorMessages, setLocalProfileData } = props;
  const { t } = useTranslation("common, profile");
  const [addressData, setAddressData] = useState({});
  const [changeMap, setChangeMap] = useState(1);
  const [canSyncMap, setCanSyncMap] = useState(true);

  const hasAddressChanged = (value) => {
    switch (value) {
      case "streetandnumber":
      case "city":
      case "country":
        return true;
      default:
        return false;
    }
  };

  /**
   *-----------------------------------------------
   * UPDATE PROFILE DATA
   *-----------------------------------------------
   */
  const inputChangeHandler = (argument, value, change, pIndex = null) => {
    let data = { ...profileData };

    if (change === "address") {
      if (value === "") {
        Object.keys(profileData.address_data).forEach((key) => {
          if (key !== argument) {
            data = {
              ...data,
              address_data: {
                ...data.address_data,
                [key]: data.address_data[key],
              },
            };
          }
        });
      } else {
        data = {
          ...data,
          address_data: { ...data.address_data, [argument]: value },
        };
      }
      if (argument === "country" && value !== "Switzerland") {
        data = {
          ...data,
          address_data: { ...data.address_data, region: "" },
        };
      }
    } else if (change === "phone") {
      data.phones[pIndex] = { ...data.phones[pIndex], [argument]: value };
    } else {
      data[argument] = value;
    }
    if (hasAddressChanged(argument)) {
      setCanSyncMap(true);
    }
    setLocalProfileData(data);
  };

  /**
   *-----------------------------------------------
   * ADD ANOTHER SET OF PHONE INPUTS
   *-----------------------------------------------
   */
  const addPhoneNumber = () => {
    let data = {
      ...profileData,
      phones: [...profileData.phones, { type: "", number: "" }],
    };
    if (profileData.phones === null) {
      data = { ...profileData, phones: [{ type: "", number: "" }] };
    }
    setLocalProfileData(data);
  };

  /**
   *-----------------------------------------------
   * REMOVE PHONE BY PHONE INDEX
   *-----------------------------------------------
   */
  const removePhoneNumber = (pIndex) => {
    const data = { ...profileData };
    data.phones = data.phones.filter((item, index) => index !== pIndex);
    setLocalProfileData(data);
  };

  const findOnMap = async () => {
    try {
      if (!canSyncMap) return null;
      const formattedAddress = formatAddress(
        profileData?.address_data?.streetandnumber,
        profileData?.city,
        profileData?.country
      );
      if (!formattedAddress) return null;
      const result = await Api.geocoding(formattedAddress);
      const lat = result?.results[0]?.geometry.location.lat;
      const lng = result?.results[0]?.geometry.location.lng;
      const data = { ...profileData, location: profileData.location };
      data.location = {
        latitude: lat,
        longitude: lng,
      };
      setLocalProfileData(data);
      setChangeMap((prevState) => prevState + 1);
      setCanSyncMap(false);
    } catch (err) {
      return null;
    }
  };

  useEffect(() => {
    const data = { ...profileData };
    if (Object.keys(addressData).length !== 0) {
      data.city = addressData.city;
      data.country = addressData.country;
      data.address_data = {
        ...data.address_data,
        streetandnumber: addressData.address_data.streetandnumber,
        zipcode: addressData.address_data.zipcode,
      };
      data.location = {
        latitude: addressData.location.latitude,
        longitude: addressData.location.longitude,
      };
      setLocalProfileData(data);
    }
  }, [addressData]);

  return (
    <div className="contact-main">
      <div className="header-text">
        <p>
          <span className="post-address">{t("profile:postal-address")}</span>
          {t("profile:web-shown-address")}
        </p>
      </div>
      <div className="contact-details">
        <div className="postal-address">
          <MerchantForm
            inputChangeHandler={inputChangeHandler}
            profileData={profileData}
            phoneTypes={phoneTypes}
            addPhoneNumber={addPhoneNumber}
            removePhoneNumber={removePhoneNumber}
            errorMessages={errorMessages}
            setAddressData={setAddressData}
            findOnMap={findOnMap}
            changeMap={changeMap}
          />
        </div>
        <div className="web-shown">
          {/* <ShowContactInfo>
          <Avatar customClass="small-avatar" circularSize={ 10 } />
        </ShowContactInfo> */}
        </div>
      </div>
    </div>
  );
};

export default profileInfo;
