import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Image from "../../../assets/images/carousel-home/clickable-image.png";
import { useTranslation } from "react-i18next";
import { backIcon } from "../../../components/svg-icons/index";
import Button from "../../../components/button";
import BulbRedCircle from "./components/bulb-quick-tips-image";
import UserInfoModal from "./components/user-modal";
import "./index.css";

const Cars = (props) => {
  const { chooseType } = props;
  const history = useHistory();
  const { t, i18n } = useTranslation(["footer", "common", "navbar"]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const goBack = () => {
    history.goBack();
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    if (chooseType === 1) {
      setIsModalOpen(true);
    } else {
      history.push(`/${i18n.language}/sell/`);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const goToContactUsHandler = (e) => {
    if (e.button === 0) {
      e.preventDefault();
      history.push(`/${i18n.language}/contact-us/`);
    }
  };

  const handleMiddleClick = (e) => {
    if (e.button === 1) {
      e.preventDefault();
      window.open(`/${i18n.language}/contact-us/`, "_blank");
    }
  };

  return (
    <div className="cars">
      <header>
        <div className="cars-go-back" onClick={goBack}>
          {backIcon}
          <p className="no-margin flex">{t("common:back")}</p>
        </div>
        <h3>{t("footer:cars")}</h3>
      </header>
      <main>
        <div className="image-wrapper">
          <img src={Image} alt="Car" />
          <div className="overlay-text">{t("footer:cars-image-text")}</div>
        </div>
        <div className="create-ad-instructions">
          <div className="first-create-ad-button-section">
            <p>{t("footer:cars-1-1")}</p>
            <a>
              <Button
                click={handleButtonClick}
                label={t("navbar:create-ad")}
                color="red"
              />
            </a>
          </div>
          <div className="instructions-text">
            <h3>{t("footer:cars-2-1")}</h3>
            <p>{t("footer:cars-2-2")}</p>
            <h3>{t("footer:cars-2-3")}</h3>
            <p>{t("footer:cars-2-4")}</p>
          </div>
          <div className="instructions-quick-tips">
            <div>
              <BulbRedCircle />
              <div>
                <h3>{t("footer:cars-3-1")}</h3>
                <p>
                  {t("footer:cars-3-2")}{" "}
                  <a
                    href="#"
                    onClick={goToContactUsHandler}
                    onAuxClick={handleMiddleClick}
                  >
                    {t("common:here")}
                  </a>{" "}
                  {t("footer:cars-3-3")}
                </p>
              </div>
            </div>
            <div>
              <BulbRedCircle />
              <div>
                <h3>{t("footer:cars-3-4")}</h3>
                <p>{t("footer:cars-3-5")}</p>
              </div>
            </div>
            <div>
              <BulbRedCircle />
              <div>
                <h3>{t("footer:cars-3-6")}</h3>
                <p>{t("footer:cars-3-7")}</p>
                <p>{t("footer:cars-3-8")}</p>
                <p>{t("footer:cars-3-9")}</p>
                <p>{t("footer:cars-3-10")}</p>
                <p>{t("footer:cars-3-11")}</p>
              </div>
            </div>
          </div>
          <div className="last-create-ad-button-section">
            <h2>{t("footer:cars-3-12")}</h2>
            <a>
              <Button
                click={handleButtonClick}
                label={t("navbar:create-ad")}
                color="red"
              />
            </a>
          </div>
        </div>
      </main>
      <UserInfoModal
        isOpen={isModalOpen}
        onClose={closeModal}
        message={t("sell:register-login-seller")}
      />
    </div>
  );
};

const stateToProps = (state) => {
  const { chooseType } = state.profile;
  return {
    chooseType,
  };
};

export default connect(stateToProps)(Cars);
