import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab/";
import { ArrowDown, ArrowDownDisabled } from "../svg-icons";
import "./index.css";

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#969696",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#969696",
      },
    },
  },
})(TextField);

const Select = (props) => {
  const { label, placeholder, defaultValue, data, handleChange, disabled } =
    props;
  const { t, i18n } = useTranslation("common");
  const language = localStorage.getItem("i18nextLng");

  return (
    <div className="mui-select">
      <Autocomplete
        disabled={disabled}
        value={defaultValue ? defaultValue : null}
        id={label}
        options={data}
        popupIcon={disabled ? ArrowDownDisabled : ArrowDown}
        getOptionLabel={(option) => {
          const { name, short_name } = option;
          const fullName =
            typeof name === "object"
              ? name[language ? language.toUpperCase() : "EN"] ?? ""
              : name;

          let label = fullName;

          if (
            typeof short_name === "string" &&
            short_name.trim().startsWith("{") &&
            short_name.trim().endsWith("}")
          ) {
            const parsedShortName = JSON.parse(short_name.trim());
            const fullShortName =
              typeof parsedShortName === "object"
                ? parsedShortName[language ? language.toUpperCase() : "EN"] ??
                  ""
                : parsedShortName;
            label += ` ${fullShortName}`;
          }

          return label.trim();
        }}
        getOptionSelected={(option, value) => option === value}
        onChange={handleChange}
        renderInput={(params) => (
          <CssTextField
            {...params}
            variant="outlined"
            InputLabelProps={{ shrink: false }}
            placeholder={placeholder ? placeholder : t("common:select")}
          />
        )}
        noOptionsText={t("common:no-option")}
      />
    </div>
  );
};

export default Select;
