import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { getEq, getEquipment } from "../../../../store/car/actions";
import { ArrowDownRed, ArrowUpRed } from "../../../../components/svg-icons";
import { useCustomTranslation } from "../../../../hooks";
import "./index.css";

const Equipment = (props) => {
  const { ad, equipment, dispatch } = props;
  const { t } = useTranslation(["common", "adDetails"]);
  const [collapseEquipment, setCollapseEquipment] = useState(true);
  const { translateArray } = useCustomTranslation();

  useEffect(() => {
    dispatch(getEquipment());
  }, []);

  const equipmentListObject = {};
  translateArray(equipment)?.forEach((item) => {
    equipmentListObject[item.id] = item.name;
  });

  const allEquipmentStringsFromAutoIdad = [
    ...(ad?.standardequipmentids ?? []),
    ...(ad?.optionalequipmentids ?? []),
  ];

  const renderItem = (item) => {
    return !equipmentListObject[item] ? item : equipmentListObject[item];
  };

  return !!allEquipmentStringsFromAutoIdad ? (
    <div>
      <div className="width-768">
        <div
          className={`${
            allEquipmentStringsFromAutoIdad?.length > 12 && collapseEquipment
              ? "eq-collapsed"
              : "eq-expand"
          }`}
        >
          <ul>
            {Array.isArray(allEquipmentStringsFromAutoIdad) &&
              allEquipmentStringsFromAutoIdad.map((item, index) => (
                <li key={`${item}-${index}`}>{renderItem(item)}</li>
              ))}
          </ul>
        </div>
        {allEquipmentStringsFromAutoIdad?.length > 12 && (
          <span
            className="show-more"
            onClick={() => setCollapseEquipment(!collapseEquipment)}
          >
            <p className="show-more-link">
              {collapseEquipment ? ArrowDownRed : ArrowUpRed}
              {collapseEquipment
                ? t("common:show-more")
                : t("common:show-less")}
            </p>
          </span>
        )}
      </div>
      <div className="width-1024">
        <div
          className={`${
            allEquipmentStringsFromAutoIdad > 15 && collapseEquipment
              ? "eq-collapsed"
              : "eq-expand"
          }`}
        >
          <ul>
            {Array.isArray(allEquipmentStringsFromAutoIdad) &&
              allEquipmentStringsFromAutoIdad.map((item, index) => (
                <li key={`${item}-${index}`}>{renderItem(item)}</li>
              ))}
          </ul>
        </div>
        {allEquipmentStringsFromAutoIdad?.length > 15 && (
          <span
            className="show-more"
            onClick={() => setCollapseEquipment(!collapseEquipment)}
          >
            <p className="show-more-link">
              {collapseEquipment ? ArrowDownRed : ArrowUpRed}
              {collapseEquipment
                ? t("common:show-more")
                : t("common:show-less")}
            </p>
          </span>
        )}
      </div>
      <div className="width-1920">
        <div
          className={`${
            allEquipmentStringsFromAutoIdad?.length > 16 && collapseEquipment
              ? "eq-collapsed"
              : "eq-expand"
          }`}
        >
          <ul>
            {Array.isArray(allEquipmentStringsFromAutoIdad) &&
              allEquipmentStringsFromAutoIdad.map((item, index) => (
                <li key={`${item}-${index}`}>{renderItem(item)}</li>
              ))}
          </ul>
        </div>
        {allEquipmentStringsFromAutoIdad?.length > 16 && (
          <span
            className="show-more"
            onClick={() => setCollapseEquipment(!collapseEquipment)}
          >
            <p className="show-more-link">
              {collapseEquipment ? ArrowDownRed : ArrowUpRed}
              {collapseEquipment
                ? t("common:show-more")
                : t("common:show-less")}
            </p>
          </span>
        )}
      </div>
    </div>
  ) : (
    <div>
      <h3>{t("adDetails:eq-not-selected")}</h3>
    </div>
  );
};

const stateToProps = (state) => {
  const { equipment } = state.car;

  return {
    equipment,
  };
};

export default connect(stateToProps)(Equipment);
