import { useState, useEffect } from "react";

const useIsOnMobileChecker = (maxWidth = 480) => {
  const [isMobile, setIsMobile] = useState(
    window.matchMedia(`(max-width: ${maxWidth}px)`).matches
  );

  useEffect(() => {
    let resizeTimeout;

    const handleResize = () => {
      // Throttle resize event with requestAnimationFrame for performance
      if (!resizeTimeout) {
        resizeTimeout = window.requestAnimationFrame(() => {
          resizeTimeout = null;
          const currentWidth = window.innerWidth;
          setIsMobile(currentWidth <= maxWidth);
        });
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
      if (resizeTimeout) {
        window.cancelAnimationFrame(resizeTimeout);
      }
    };
  }, [maxWidth]);

  return isMobile;
};

export default useIsOnMobileChecker;
