import { formatAddress } from "../lib/utils";
import { useSelector } from "react-redux";
import Api from "../lib/api";

const useGoogleMap = () => {
  const profileData = useSelector((state) => {
    return state.profile.profileData ?? {};
  });

  const getCoordinatesForAddress = async (street, city, country) => {
    const formattedAddress = formatAddress(street, city, country);
    if (!formattedAddress) return null;
    const result = await Api.geocoding(formattedAddress);
    const lat = result?.results[0]?.geometry.location.lat;
    const lng = result?.results[0]?.geometry.location.lng;
    const data = { ...profileData, location: profileData.location };
    data.location = {
      latitude: lat,
      longitude: lng,
    };
    return data;
  };

  return { getCoordinatesForAddress };
};

export default useGoogleMap;
