import audi from "../../../assets/images/brands/audi.png";
import vw from "../../../assets/images/brands/vw.png";
import bmw from "../../../assets/images/brands/bmw.png";
import fiat from "../../../assets/images/brands/fiat.png";
import ford from "../../../assets/images/brands/ford.png";
import chevrolet from "../../../assets/images/brands/chevrolet.png";
import mercedes from "../../../assets/images/brands/mercedes.png";
import opel from "../../../assets/images/brands/opel.png";
import renault from "../../../assets/images/brands/renault.png";
import alfaromeo from "../../../assets/images/brands/alfaromeo.png";
import astonmartin from "../../../assets/images/brands/astonmartin.png";
import bentley from "../../../assets/images/brands/bentley.png";
import cadillac from "../../../assets/images/brands/cadillac.png";
import citroen from "../../../assets/images/brands/citroen.png";
import dacia from "../../../assets/images/brands/dacia.png";
import dsautomobiles from "../../../assets/images/brands/dsautomobiles.png";
import ferarri from "../../../assets/images/brands/ferarri.png";
import honda from "../../../assets/images/brands/honda.png";
import hyundai from "../../../assets/images/brands/hyundai.png";
import infiniti from "../../../assets/images/brands/infiniti.png";
import jaguar from "../../../assets/images/brands/jaguar.png";
import jeep from "../../../assets/images/brands/jeep.png";
import kia from "../../../assets/images/brands/kia.png";
import lada from "../../../assets/images/brands/lada.png";
import lamborghini from "../../../assets/images/brands/lamborghini.png";
import lancia from "../../../assets/images/brands/lancia.png";
import landrover from "../../../assets/images/brands/landrover.png";
import lexus from "../../../assets/images/brands/lexus.png";
import lotus from "../../../assets/images/brands/lotus.png";
import maserati from "../../../assets/images/brands/maserati.png";
import mazda from "../../../assets/images/brands/mazda.png";
import mini from "../../../assets/images/brands/mini.png";
import mitsubishi from "../../../assets/images/brands/mitsubishi.png";
import morgan from "../../../assets/images/brands/morgan.png";
import nissan from "../../../assets/images/brands/nissan.png";
import peugeot from "../../../assets/images/brands/peugeot.png";
import porsche from "../../../assets/images/brands/porsche.png";
import rollsroyce from "../../../assets/images/brands/rollsroyce.png";
import smart from "../../../assets/images/brands/smart.png";
import sangyong from "../../../assets/images/brands/sangyong.png";
import seat from "../../../assets/images/brands/seat.png";
import skoda from "../../../assets/images/brands/skoda.png";
import subaru from "../../../assets/images/brands/subaru.png";
import suzuki from "../../../assets/images/brands/suzuki.png";
import tata from "../../../assets/images/brands/tata.png";
import tesla from "../../../assets/images/brands/tesla.png";
import toyota from "../../../assets/images/brands/toyota.png";
import volvo from "../../../assets/images/brands/volvo.png";

export const brandsImagesArr = [
  { label: "BMW", src: bmw, idmake: 10 },
  { label: "VW", src: vw, idmake: 92 },
  { label: "Audi", src: audi, idmake: 7 },
  { label: "Mercedes-Benz", src: mercedes, idmake: 58 },
  { label: "Ford", src: ford, idmake: 33 },
  { label: "Opel", src: opel, idmake: 66 },
  { label: "Fiat", src: fiat, idmake: 31 },
  { label: "Nissan", src: nissan, idmake: 64 },
  { label: "Mazda", src: mazda, idmake: 56 },
  { label: "Renault", src: renault, idmake: 72 },
  { label: "Alfa Romeo", src: alfaromeo, idmake: 3 },
  { label: "Bentley", src: bentley, idmake: 9 },
  { label: "Peugeot", src: peugeot, idmake: 67 },
  { label: "Porsche", src: porsche, idmake: 70 },
  { label: "Cadillac", src: cadillac, idmake: 13 },
  { label: "Citroën", src: citroen, idmake: 18 },
  { label: "Dacia", src: dacia, idmake: 20 },
  { label: "Tesla", src: tesla, idmake: 87 },
  { label: "Toyota", src: toyota, idmake: 88 },
  { label: "Volvo", src: volvo, idmake: 90 },
  { label: "Ferarri", src: ferarri, idmake: 30 },
  { label: "Honda", src: honda, idmake: 36 },
  { label: "Hyundai", src: hyundai, idmake: 38 },
  { label: "Infiniti", src: infiniti, idmake: 40 },
  { label: "Jaguar", src: jaguar, idmake: 44 },
  { label: "Jeep", src: jeep, idmake: 45 },
  { label: "Kia", src: kia, idmake: 46 },
  { label: "Aston Martin", src: astonmartin, idmake: 6 },
  { label: "Lada", src: lada, idmake: 47 },
  { label: "Lamborghini", src: lamborghini, idmake: 48 },
  { label: "Lancia", src: lancia, idmake: 49 },
  { label: "Land Rover", src: landrover, idmake: 50 },
  { label: "Lexus", src: lexus, idmake: 51 },
  { label: "Lotus", src: lotus, idmake: 52 },
  { label: "Maserati", src: maserati, idmake: 54 },
  { label: "Mini", src: mini, idmake: 61 },
  { label: "Mitshubishi", src: mitsubishi, idmake: 62 },
  { label: "Morgan", src: morgan, idmake: 63 },
  { label: "Chevrolet", src: chevrolet, idmake: 15 },
  { label: "Rolls Royce", src: rollsroyce, idmake: 73 },
  { label: "SEAT", src: seat, idmake: 77 },
  { label: "Skoda", src: skoda, idmake: 79 },
  { label: "Smart", src: smart, idmake: 80 },
  { label: "SsangYong", src: sangyong, idmake: 81 },
  { label: "Subaru", src: subaru, idmake: 82 },
  { label: "Suzuki", src: suzuki, idmake: 84 },
  { label: "Tata", src: tata, idmake: 86 },
  { label: "DS Automobiles", src: dsautomobiles, idmake: 28 },
];
