export const emissionClassLessThan = [
  { name: "All", value: null },
  { name: "50", value: 50 },
  { name: "100", value: 100 },
  { name: "150", value: 150 },
  { name: "200", value: 200 },
  { name: "250", value: 250 },
  { name: "300", value: 300 },
];

export const perimetarOptions = [
  { name: "All", value: null, radius: null },
  { name: "10 km", value: 10000, radius: 6.21 }, // 10 km in miles
  { name: "20 km", value: 20000, radius: 12.43 }, // 20 km in miles
  { name: "30 km", value: 30000, radius: 18.64 }, // 30 km in miles
  { name: "40 km", value: 40000, radius: 24.85 }, // 40 km in miles
  { name: "50 km", value: 50000, radius: 31.07 }, // 50 km in miles
  { name: "75 km", value: 75000, radius: 46.6 }, // 75 km in miles
  { name: "100 km", value: 100000, radius: 62.14 }, // 100 km in miles
  { name: "200 km", value: 200000, radius: 124.27 }, // 200 km in miles
  { name: "300 km", value: 300000, radius: 186.41 }, // 300 km in miles
];
