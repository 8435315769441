import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Share, Delete } from "../../svg-icons";
import { DialogModal } from "../../dialog";
import { toDateFormat } from "../../../lib/utils";
import "./index.css";

const searchCard = (props) => {
  const { index, deleteHandler, renderHandler, data } = props;
  const { t, i18n } = useTranslation(["common", "savedSearch"]);
  const [openModal, setOpenModal] = useState(false);
  const [shareHover, setShareHover] = useState(false);
  const [deleteHover, setDeleteHover] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { adsFound, date, filterNames, filtersApplied, name } = data;

  const hideDialog = () => {
    setOpenModal(false);
  };

  const deleteSavedSearch = () => {
    deleteHandler(index);
    setOpenModal(false);
  };

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
  });

  useEffect(() => {}, []);

  return (
    <div className="searchcard">
      {windowWidth < 1023 ? (
        <div className="small-saved-search">
          <section>
            <div>
              <h5>{t("savedSearch:saved-search-name")}</h5>
              <h4>{name}</h4>
            </div>
            <div className="icons">
              <span
                onClick={() => setOpenModal(true)}
                onMouseMove={() => setDeleteHover(true)}
                onMouseOut={() => setDeleteHover(false)}
              >
                <Delete color={deleteHover ? "#f61d2a" : "#c8c8c8"} />
              </span>
              <span
                onMouseMove={() => setShareHover(true)}
                onMouseOut={() => setShareHover(false)}
              >
                <Share color={shareHover ? "#f61d2a" : "#c8c8c8"} />
              </span>
            </div>
          </section>
          <section>
            <div>
              <div>
                <h5>{t("savedSearch:search-date")}</h5>
                <h4>{toDateFormat(date)}</h4>
              </div>
              <div>
                <h5>{t("savedSearch:no-of-results")}</h5>
                <h4>{adsFound}</h4>
              </div>
            </div>
            <div>
              <p onClick={() => renderHandler(filtersApplied)}>
                {t("savedSearch:view-this-search")}
              </p>
            </div>
          </section>
          <section>
            <div>
              <h5>{t("savedSearch:search-filters")}:</h5>
            </div>
            <div>
              {Object.keys(filterNames).length > 0 ? (
                filterNames.map((item, index) => <p key={index}>{item}</p>)
              ) : (
                <p>{t("savedSearch:no-filters")}</p>
              )}
            </div>
          </section>
        </div>
      ) : (
        <div className="big-saved-search">
          <section>
            <div>
              <h5>{t("savedSearch:saved-search-name")}</h5>
              <h4>{name}</h4>
            </div>
            <div>
              <h5>{t("savedSearch:search-date")}</h5>
              <h4>{toDateFormat(date)}</h4>
            </div>
            <div>
              <h5>{t("savedSearch:no-of-results")}</h5>
              <h4>{adsFound}</h4>
            </div>
            <div className="icons">
              <span
                onClick={() => setOpenModal(true)}
                onMouseMove={() => setDeleteHover(true)}
                onMouseOut={() => setDeleteHover(false)}
              >
                <Delete color={deleteHover ? "#f61d2a" : "#c8c8c8"} />
              </span>
              <span
                onMouseMove={() => setShareHover(true)}
                onMouseOut={() => setShareHover(false)}
              >
                <Share color={shareHover ? "#f61d2a" : "#c8c8c8"} />
              </span>
            </div>
          </section>
          <section>
            <div>
              <div>
                <h5>{t("savedSearch:search-filters")}:</h5>
              </div>
              <div>
                {Object.keys(filterNames).length > 0 ? (
                  filterNames.map((item, index) => <p key={index}>{item}</p>)
                ) : (
                  <p>{t("savedSearch:no-filters")}</p>
                )}
              </div>
            </div>
            <span>
              <p onClick={() => renderHandler(filtersApplied)}>
                {t("savedSearch:view-this-search")}
              </p>
            </span>
          </section>
        </div>
      )}
      <DialogModal
        data={{
          title: "common:delete",
          message: "common:delete-confirmation",
          buttons: [
            {
              label: "common:cancel",
              color: "white",
              className: "cancel",
              action: hideDialog,
            },
            {
              label: "common:delete",
              color: "red",
              className: "confirm",
              action: deleteSavedSearch,
            },
          ],
        }}
        hideDialog={hideDialog}
        openModal={openModal}
      />
    </div>
  );
};

export default searchCard;
