import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, TextField } from "@material-ui/core";
import { withTranslation } from "../../../lib/translate";
import { emailRegex } from "../../../lib/utils";
import { recoverPassword, clearData } from "../../../store/actions";
import SimpleLoader from "../../../components/simple-loader";
import FormControl from "../../../components/form-control/index";
import Button from "../../../components/button/index";
import ThanksPage from "../../../components/thanks-page";
import ChooseUser from "../choose-user";
import "./index.css";

const RecoverPassword = (props) => {
  const { dispatch, loading, recoverPasswordFail, recoverPasswordSuccess } =
    props;
  const { t } = useTranslation(["common", "auth"]);
  const [email, setEmail] = useState("");
  const [emailErrorFlag, setEmailErrorFlag] = useState(false);
  const [choosenType, setChoosenType] = useState(null);

  useEffect(() => {
    return () => {
      dispatch(clearData(["recoverPasswordFail", "recoverPasswordSuccess"]));
    };
  }, []);

  useEffect(() => {
    setEmail("");
  }, [recoverPasswordSuccess]);

  const recoverPasswordHandler = (event) => {
    event.preventDefault();
    if (!emailRegex.test(email)) {
      setEmailErrorFlag(true);
      return null;
    } else {
      setEmailErrorFlag(false);
    }
    dispatch(recoverPassword(email, choosenType));
  };

  if (choosenType === null) {
    return (
      <ChooseUser
        title={t("auth:do-you-want-to-recover-as")}
        description={t("auth:choose-whether-you-want-to-recover")}
        setChoosenType={setChoosenType}
      />
    );
  }

  return (
    <SimpleLoader loading={loading}>
      {!recoverPasswordSuccess ? (
        <Box className="register recover-password">
          <Box className="header">
            <h5>{t("auth:recover-password")}</h5>
          </Box>
          <form
            onSubmit={recoverPasswordHandler}
            className="register-form email-code"
          >
            <Box className="flex-row-wrapper regformdiv1">
              <FormControl className="register-label primary-font-color">
                <span>{t("auth:your-email-address")}</span>
                <TextField
                  value={email}
                  variant="outlined"
                  type="text"
                  placeholder={t("common:type")}
                  onChange={(event) => setEmail(event.target.value)}
                />
                {(emailErrorFlag || recoverPasswordFail) && (
                  <span className="errorMessage">
                    {t("auth:recover-password-warning")}
                  </span>
                )}{" "}
              </FormControl>
              <Button
                type="submit"
                color="red"
                label={t("auth:recover")}
                className="register-button"
                click={recoverPasswordHandler}
              />
              <Button
                color="gray"
                label={t("common:return-to-picker")}
                className="register-button"
                click={() => setChoosenType(null)}
              />
            </Box>
          </form>
        </Box>
      ) : (
        <div>
          {!emailErrorFlag &&
            !recoverPasswordFail &&
            recoverPasswordSuccess && (
              <ThanksPage
                texts={[
                  "auth:recover-password-success1",
                  "auth:recover-password-success2",
                ]}
              ></ThanksPage>
            )}
        </div>
      )}
    </SimpleLoader>
  );
};

const stateToProps = (state) => {
  const { loading, recoverPasswordFail, recoverPasswordSuccess } =
    state.profile;
  return {
    loading,
    recoverPasswordFail,
    recoverPasswordSuccess,
  };
};

export default connect(stateToProps)(withTranslation(RecoverPassword));
