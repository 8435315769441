import React from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import Button from "../button/index";
import TextField from "../text-field";
import "./index.css";

export const DialogModal = (props) => {
  const { openModal, hideDialog, data } = props;
  const { t, i18n } = useTranslation(["common", "adDetails"]);

  return (
    <Dialog open={openModal} onClose={hideDialog} className="confirm-dialog">
      <div>
        <DialogTitle>{t(data?.title)}</DialogTitle>
      </div>
      <div className="confirm-dialog-content">
        <DialogContentText>
          {t(data?.message)}
          <span className="submessage">{t(data?.submessage)}</span>
        </DialogContentText>
        <DialogActions>
          {data?.buttons?.map((button, index) => (
            <Button
              key={index}
              label={t(button?.label)}
              color={button?.color}
              className={button?.className}
              click={button?.action}
            />
          ))}
        </DialogActions>
      </div>
    </Dialog>
  );
};

export const Save = (props) => {
  const {
    openModal,
    hideDialog,
    changeHandler,
    action,
    title,
    errorMessage,
    disabled,
    info,
    inputValue,
  } = props;
  const { t, i18n } = useTranslation(["common"]);

  return (
    <Dialog open={openModal} onClose={hideDialog} className="save-dialog">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{info}</DialogContentText>
        <TextField
          id="search"
          type="text"
          placeholder={t("common:type")}
          changeHandler={changeHandler}
          value={inputValue}
        />
        <DialogActions>
          <Button
            label={t("common:cancel")}
            color="gray"
            className="cancel"
            click={hideDialog}
          />
          <Button
            label={t("common:confirm")}
            color="red"
            className="confirm"
            click={action}
            disabled={disabled}
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
