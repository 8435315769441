import React from "react";
// import Icon from '../icon';
import "./index.css";

const Loader = (props) => (
  <div
    className={
      "loader-backdrop" +
      (props.loading ? "-show" : "") +
      (props.locale ? " loader-locale" : "") +
      (props.className ? " " + props.className : "")
    }
  >
    <div className="loader-wrapper">
      <div className={"loader-progress" + (props.progress ? "-show" : "")}>
        {props.progress || ""}
      </div>
      <div className="loader-indicator">
        {/* <Icon icon={ props.icon || 'location_searching' } /> */}
      </div>
    </div>
  </div>
);

export default Loader;
