import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { getLocale } from "../store/car/actions";

let numberOfThingsToWait = 6;
let allreadyLoadedTypes = [];

const useInitialLoading = () => {
  const [loading, setLoading] = useState(true);

  const {
    makeWithAll,
    carModelWithAll,
    fuelTypeWithAll,
    transmissionWithAll,
    bodyTypeCount,
    energyEfficience,
    locales,
  } = useSelector((state) => ({
    makeWithAll: state.car.makeWithAll ?? [],
    carModelWithAll: state.car.carModelWithAll ?? [],
    fuelTypeWithAll: state.car.fuelTypeWithAll ?? [],
    transmissionWithAll: state.car.transmissionWithAll ?? [],
    bodyTypeCount: state.car.bodyTypeCount ?? [],
    energyEfficience: state.car.energyEfficience ?? [],
    locales: state.car.locales ?? [],
  }));

  useEffect(() => {
    // reset loader if we allredy have everything loaded
    if (allreadyLoadedTypes.length === numberOfThingsToWait) {
      setLoading(false);
    }
  }, []);

  const { chooseType, isAuthorized } = useSelector((state) => ({
    chooseType: state.profile.chooseType,
    isAuthorized: state.profile.isAuthorized,
  }));

  const check = (condition, type) => {
    if (condition === false && !allreadyLoadedTypes.includes(type)) {
      allreadyLoadedTypes.push(type);
      setLoading(allreadyLoadedTypes.length !== numberOfThingsToWait);
    }
  };

  useEffect(() => {
    check(makeWithAll.length === 0, "makeWithAll");
  }, [makeWithAll]);

  useEffect(() => {
    // this is always empty array at the start
    // check(carModelWithAll.length === 0, "carModelWithAll");
  }, [carModelWithAll]);

  useEffect(() => {
    check(fuelTypeWithAll.length === 0, "fuelTypeWithAll");
  }, [fuelTypeWithAll]);

  useEffect(() => {
    check(transmissionWithAll.length === 0, "transmissionWithAll");
  }, [transmissionWithAll]);

  useEffect(() => {
    check(bodyTypeCount.length === 0, "bodyTypeCount");
  }, [bodyTypeCount]);

  useEffect(() => {
    check(energyEfficience.length === 0, "energyEfficience");
  }, [energyEfficience]);

  useEffect(() => {
    check(locales.length === 0, "locales");
  }, [locales]);

  return {
    loading,
    counter: numberOfThingsToWait,
  };
};

export default useInitialLoading;
