import React from "react";
import { useHistory, useParams } from "react-router-dom";
import history from "../../../../lib/history";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/button";
import "./index.css";

const ReportThanks = () => {
  const { t, i18n } = useTranslation("reportAd");
  const history = useHistory();
  const { idad } = useParams();

  const backToAdPage = () => {
    localStorage.removeItem("previewAd");
    history.push(`/${i18n.language}/ad/${idad}`);
  };

  return (
    <div className="report-thanks">
      <header>
        <h5>{t("reportAd:report-sent-successfully")}</h5>
      </header>
      <main>
        <p>{t("reportAd:thank-you-for-report")}</p>
        <Button
          color="red"
          label={t("reportAd:go-back-to-ad")}
          className="go-back-button"
          click={backToAdPage}
        />
      </main>
    </div>
  );
};

export default ReportThanks;
