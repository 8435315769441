import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, TextField } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { withTranslation } from "../../../lib/translate";
import { changePassword, clearData } from "../../../store/actions";
import { ShowPassword } from "../../../components/svg-icons";
import SimpleLoader from "../../../components/simple-loader";
import FormControl from "../../../components/form-control/index";
import Button from "../../../components/button/index";
import ThanksPage from "../../../components/thanks-page";
import "./index.css";

const ChangePassword = (props) => {
  const { dispatch, loading, changePasswordSuccess, changePasswordFail } =
    props;
  const { t } = useTranslation(["common", "auth"]);
  const { hash } = useParams();
  const [payload, setPayload] = useState({
    email: "",
    type: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [data, setData] = useState({ password: "", repeatPassword: "" });
  const [validationMessages, setValidationMessages] = useState({});

  console.log("payload", payload);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const email = queryParams.get("email");
    const type = queryParams.get("type");
    console.log("queryParams", queryParams);
    setPayload({
      email,
      type,
    });
    return () => {
      dispatch(clearData(["changePasswordSuccess", "changePasswordFail"]));
    };
  }, []);

  const ChangePasswordHandler = (event) => {
    event.preventDefault();
    let errorFlag = false;

    for (const key in data) {
      const value = data[key].trim();
      if (key.includes("password")) {
        if (value.length < 6 || value.length > 30) {
          errorFlag = true;
          setValidationMessages((prevState) => ({
            ...prevState,
            passwordError: "auth:validation-password-message",
          }));
        } else {
          setValidationMessages((prevState) => {
            delete prevState.passwordError;
            return { ...prevState };
          });
        }
        if (key === "password" && value !== data.repeatPassword.trim()) {
          errorFlag = true;
          setValidationMessages((prevState) => {
            return {
              ...prevState,
              samePasswordError: "auth:validation-same-password-message",
            };
          });
        } else if (key === "repeatPassword" && value !== data.password.trim()) {
          errorFlag = true;
          setValidationMessages((prevState) => {
            return {
              ...prevState,
              samePasswordError: "auth:validation-same-password-message",
            };
          });
        } else {
          setValidationMessages((prevState) => {
            delete prevState.samePasswordError;
            return { ...prevState };
          });
        }
      }
    }
    if (errorFlag || !data?.password.trim() || !data?.repeatPassword.trim())
      return;
    dispatch(
      changePassword({
        password: data.password.trim(),
        email: payload.email,
        type: payload.type,
        hash,
      })
    );
  };

  const inputChangeHandler = (event) => {
    setData((prevState) => {
      return { ...prevState, [event.target.name]: event.target.value };
    });
  };

  return (
    <SimpleLoader loading={loading}>
      {!changePasswordSuccess ? (
        <Box className="register change-password">
          <Box className="header">
            <h5>{t("auth:change-password")}</h5>
          </Box>
          <form
            onSubmit={ChangePasswordHandler}
            className="register-form email-code"
          >
            <Box className="flex-row-wrapper regformdiv1">
              <FormControl className="register-label primary-font-color">
                <span>{t("auth:new-password")}</span>
                <Box className="inputandicon">
                  <TextField
                    value={data?.password ? data.password : ""}
                    name="password"
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    placeholder={t("common:type")}
                    onChange={(event) => inputChangeHandler(event)}
                  />
                  <span
                    className="showPassword"
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    {ShowPassword}
                  </span>
                </Box>
              </FormControl>
            </Box>
            <Box className="flex-row-wrapper regformdiv1">
              <FormControl className="register-label primary-font-color">
                <span>{t("auth:repeat-new-password")}</span>
                <Box className="inputandicon">
                  <TextField
                    value={data?.repeatPassword ? data.repeatPassword : ""}
                    name="repeatPassword"
                    variant="outlined"
                    type={showRepeatPassword ? "text" : "password"}
                    placeholder={t("common:type")}
                    onChange={(event) => inputChangeHandler(event)}
                  />
                  <span
                    className="showPassword"
                    onClick={() => setShowRepeatPassword((prev) => !prev)}
                  >
                    {ShowPassword}
                  </span>
                </Box>
              </FormControl>
              {validationMessages?.passwordError && (
                <span className="errorMessage">
                  {t(validationMessages.passwordError)}
                </span>
              )}
              {validationMessages?.samePasswordError && (
                <span className="errorMessage">
                  {t(validationMessages.samePasswordError)}
                </span>
              )}
              {!validationMessages?.passwordError &&
                !validationMessages?.samePasswordError &&
                changePasswordFail && (
                  <span className="errorMessage">
                    {t("common:global-error")}
                  </span>
                )}
              <Button
                type="submit"
                color="red"
                label={t("auth:change-password")}
                className="register-button"
                click={ChangePasswordHandler}
              />
            </Box>
          </form>
        </Box>
      ) : (
        <div>
          {!validationMessages?.passwordError &&
            !validationMessages?.samePasswordError &&
            !changePasswordFail &&
            changePasswordSuccess && (
              <ThanksPage texts={["auth:changed-password"]}></ThanksPage>
            )}
        </div>
      )}
    </SimpleLoader>
  );
};

const stateToProps = (state) => {
  const { loading, changePasswordSuccess, changePasswordFail } = state.profile;
  return {
    loading,
    changePasswordSuccess,
    changePasswordFail,
  };
};

export default connect(stateToProps)(withTranslation(ChangePassword));
