import React from "react";
import { Helmet } from "react-helmet-async";

const SEO = ({ title, description, image, url }) => {
  const currentUrl = url || window.location.href;
  const isDomain =
    window.location.hostname === "carindex.ch" ||
    window.location.hostname === "www.carindex.ch";

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      {isDomain && (
        <script>
          {`
          var _mtm = window._mtm = window._mtm || [];
          _mtm.push({ 'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start' });
          (function () {
            var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
            g.async = true; g.src = 'https://cdn.matomo.cloud/carindex.matomo.cloud/container_y4KIfBpa.js';
            s.parentNode.insertBefore(g, s);
          })();
        `}
        </script>
      )}
    </Helmet>
  );
};

export default SEO;
