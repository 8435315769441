/* eslint-disable */
import secureLocalStorage from "react-secure-storage";
import { store } from "../../index";
import { pairMessages, saveMessage } from "../../lib/utils";
import { UPLOAD_TYPES, USER_TYPES } from "../../types";
import {
  CREATEAD_RESULT,
  DELETEADS_RESULT,
  GETADSFOROWNER,
  GETBODYTYPE,
  GETFUELTYPE,
  GETLOCALE,
  GETMAKE,
  GETTRANSMISSIONFORLOCALE,
  PUBLISHAD,
  PUBLISHAD_RESULT,
  GETVEHICLESCOUNTBYBODYTYPE,
  GETENERGYEFFICIENCE,
} from "../car/constants";
import { UPLOAD_RESULT, UPLOAD_ERROR, BEGINUPLOAD } from "../common/constants";
import {
  CONNECT,
  SETAUTHORIZATION,
  GETPROFILEDATA,
  GETMERCHANTCARD,
  REGISTERWEBUSER_RESULT,
  CONNECT_RESULT,
  ADDMERCHANTCARD_RESULT,
  EDITPROFILEDATA_RESULT,
  SET_CHOOSE_TYPE,
  UPDATE_AVATAR,
} from "../profile/constants";

export default function (socket) {
  socket.onmessage = function (e) {
    if (typeof e.data === "string") {
      const data = JSON.parse(e.data);
      if (data.method !== undefined && data.method === "_result") {
        const message = pairMessages(e.data);
        const state = store.getState();
        store.dispatch({
          type: message.method,
          params: message,
        });
        if (message.method === REGISTERWEBUSER_RESULT) {
          store.dispatch({
            type: CONNECT,
          });
        }
        if (message.method === CONNECT_RESULT) {
          if (state.profile.chooseType === USER_TYPES.STANDARD) {
            store.dispatch({
              type: GETPROFILEDATA,
            });
          }
          if (state.profile.chooseType === USER_TYPES.MERCHANT) {
            store.dispatch({
              type: GETMERCHANTCARD,
            });
            store.dispatch({
              type: GETADSFOROWNER,
            });
          }
        }
        if (message.method === ADDMERCHANTCARD_RESULT) {
          store.dispatch({
            type: GETMERCHANTCARD,
          });
        }
        if (message.method === EDITPROFILEDATA_RESULT) {
          store.dispatch({
            type: GETPROFILEDATA,
          });
        }
        if (message.method === CREATEAD_RESULT) {
          const state = store.getState();
          const readyToPublish = state.car.readyToPublish;
          const photos = state.common.ad_images;
          const idad = data.params[0];

          if (photos.length > 0) {
            store.dispatch({
              type: BEGINUPLOAD,
              uploadType: 14,
              idad,
            });
          }
          if (readyToPublish) {
            store.dispatch({
              type: PUBLISHAD,
              idad,
            });
          }
          store.dispatch({
            type: GETADSFOROWNER,
          });
        }
        if (message.method === PUBLISHAD_RESULT) {
          store.dispatch({
            type: GETADSFOROWNER,
          });
        }
        if (message.method === DELETEADS_RESULT) {
          store.dispatch({
            type: GETADSFOROWNER,
          });
        }
      } else if (data.method !== undefined && data.method === "onMessage") {
        const state = store.getState();
        data.params[1] ? (data.params[1] = new Date().getTime()) : "";
        saveMessage(data.params);
        setLastUserMessage(data.params);
        if (state.auth.userType === 4) {
          store.dispatch({
            type: SETMESSAGESTATE,
            params: [data.params[2].msg_id, 1, data.params[2].for_merchant],
          });
          store.dispatch({
            type: "FORMERCHANTMESSAGE",
            params: [data.params[2].for_merchant],
          });
        } else {
          store.dispatch({
            type: SETMESSAGESTATE,
            params: [data.params[2].msg_id, 1],
          });
        }
        setTimeout(() => {
          store.dispatch({
            type: data.method,
            params: data.params,
          });
        }, 300);
        return true;
      } else if (data.method !== undefined && data.method === "onAddContact") {
        const state = store.getState();
        const userType = state.auth.userType;
        saveContactsInfo([data.params[0].contact], data.params[1], userType);
        return true;
      } else if (data.method !== undefined && data.method === "onPresence") {
        const state = store.getState();
        const userType = state.auth.userType;
        //user is online/go offline
        const uid = data.params[0].uid;
        //user go offline if last_seen !== undefined
        if (data.params[0].last_seen !== undefined) {
          if (userType === 4) {
            setLastSeen(uid, new Date().getTime(), data.params[1], userType);
          } else {
            setLastSeen(uid, new Date().getTime());
          }
        } else {
          if (userType === 4) {
            setLastSeen(uid, null, data.params[1], userType);
          } else {
            setLastSeen(uid, null);
          }
        }
        store.dispatch({
          type: ONLINESTATUSCHANGED,
          params: data.params[0],
        });
      } else if (data.method !== undefined && data.method === "onTyping") {
        const state = store.getState();
        const userType = state.auth.userType;
        if (userType === 4) {
          //user is typing
          store.dispatch({
            type: USERTYPING,
            params: data.params,
          });
        } else {
          //user is typing
          store.dispatch({
            type: USERTYPING,
            params: data.params[0],
          });
        }
      } else if (
        data.method !== undefined &&
        data.method === "onChatMessageState"
      ) {
        const state = store.getState();
        const userType = state.auth.userType;
        // const merchantUid = state.merchant.merchantUid za generalnog agenta

        //changed message state
        if (userType === 4) {
          store.dispatch({
            type: CHATMESAGESTATE,
            params: [data.params, merchantUid, userType],
          });
        } else {
          store.dispatch({
            type: CHATMESAGESTATE,
            params: data.params,
          });
        }
      } else if (
        data.method !== undefined &&
        data.method === "onProfileUpdate"
      ) {
        //user changed his profile -> data.params[0]
        store.dispatch({
          type: GETCONTACTSWEB,
        });
        store.dispatch({
          type: USERCHANGEPROFILE,
          params: data.params[0],
        });
      } else if (data.method !== undefined && data.method === "_error") {
        const message = pairMessages(e.data);
        store.dispatch({
          type: message.method,
          params: message,
        });
      } else if (
        data.method !== undefined &&
        data.method === "onUploadComplete"
      ) {
        const state = store.getState();
        const { uploadType } = state.common;
        //begin next upload if uploadType is ad image and if there is more photos to upload
        if (uploadType === UPLOAD_TYPES.AD_IMAGE) {
          const { created_ad_id, ad_image_index, ad_images } = state.common;
          if (!!ad_images.length && ad_image_index < ad_images.length) {
            store.dispatch({
              type: BEGINUPLOAD,
              uploadType: 14,
              idad: created_ad_id,
            });
          }
          // if there is no more photos to upload, run UPLOAD_ERROR OR UPLOAD_RESULT case
          else {
            if (data.params[0] !== 0) {
              store.dispatch({
                type: UPLOAD_ERROR,
                params: [data.params[0]],
              });
            } else {
              store.dispatch({
                type: UPLOAD_RESULT,
                params: [data.params[1]],
              });
            }
            store.dispatch({
              type: GETADSFOROWNER,
            });
          }
        } else {
          if (state.profile.chooseType === USER_TYPES.MERCHANT) {
            store.dispatch({
              type: GETMERCHANTCARD,
            });
          }
          if (state.profile.chooseType === USER_TYPES.STANDARD) {
            store.dispatch({
              type: UPDATE_AVATAR,
              params: data.params[1],
            });
          }
          if (data.params[0] !== 0) {
            store.dispatch({
              type: UPLOAD_ERROR,
              params: [data.params[0]],
            });
          } else {
            store.dispatch({
              type: UPLOAD_RESULT,
              params: [data.params[1]],
            });
          }
        }
      } else if (data.method !== undefined && data.method === "chatCreated") {
        //chat opened
        const uid = data.params[0].uid;

        store.dispatch({
          type: CHATOPENEDSTATUSCHANGED,
          params: uid,
        });
      } else if (data.method !== undefined && data.method === "chatAssigned") {
        //chat assigned
        const uid = data.params[0].uid;

        store.dispatch({
          type: CHATOPENEDSTATUSCHANGED,
          params: uid,
        });
      } else if (data.method !== undefined && data.method === "chatDeleted") {
        //chat deleted
        const uid = data.params[0].uid;

        store.dispatch({
          type: CHATOPENEDSTATUSCHANGED,
          params: uid,
        });
      }
    }
  };
  let connectionClosed = false;
  socket.onclose = function () {
    connectionClosed = true;
    store.dispatch({
      type: SETAUTHORIZATION,
      params: false,
    });
  };
  socket.onopen = function () {
    connectionClosed = true;
    if (connectionClosed === true) {
      store.dispatch({
        type: GETMAKE,
      });
      store.dispatch({
        type: GETBODYTYPE,
      });
      store.dispatch({
        type: GETFUELTYPE,
      });
      store.dispatch({
        type: GETTRANSMISSIONFORLOCALE,
      });
      store.dispatch({
        type: GETVEHICLESCOUNTBYBODYTYPE,
      });
      store.dispatch({
        type: GETENERGYEFFICIENCE,
      });
      store.dispatch({
        type: GETLOCALE,
      });
      connectionClosed = false;
      const token = localStorage.getItem("token");
      const email = secureLocalStorage.getItem("email");
      const password = secureLocalStorage.getItem("password");
      const userType = secureLocalStorage.getItem("userType");
      if (email === null || password === null || userType === null) {
        secureLocalStorage.removeItem("email");
        secureLocalStorage.removeItem("password");
        secureLocalStorage.removeItem("userType");
        localStorage.removeItem("token");
        return;
      }
      store.dispatch({
        type: SET_CHOOSE_TYPE,
        userType,
      });
      store.dispatch({
        type: SETAUTHORIZATION,
        params: { token: token },
      });
      if (!!email && !!password) {
        store.dispatch({
          type: CONNECT,
          email,
          password,
          userType,
        });
      }
    }
  };
}
