import React, { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { scroll } from "../../../../lib/utils";
import { editProfile } from "../../../../store/actions";
import ShownContactInfo from "../../../../components/user-info/index";
import Avatar from "../../../../components/avatar/index";
import Button from "../../../../components/button/index";
import UserForm from "../../../../components/user-form";
import "../../merchant/01-merchant-details/index.css";

const UserDetails = (props) => {
  const { dispatch, profileData } = props;
  const { t } = useTranslation(["common", "profile", "sell"]);
  const [localProfileData, setLocalProfileData] = useState(
    JSON.parse(JSON.stringify(profileData))
  );
  const [errorMessages, setErrorMessages] = useState({});

  const inputChangeHandler = (argument, value) => {
    let data = JSON.parse(JSON.stringify(localProfileData));
    data[argument] = value;
    if (data.first_name !== null && data.last_name !== null) {
      data.display_name = `${data.first_name} ${data.last_name}`;
    }
    setLocalProfileData(data);
  };

  const updateProfileHandler = () => {
    const errors = [];
    if (!localProfileData?.first_name || localProfileData?.first_name === "") {
      errors.push({
        name: "first_name",
        message: "common:required-error-message",
        target: "#first-name-scroll-target",
      });
    }
    if (!localProfileData?.last_name || localProfileData?.last_name === "") {
      errors.push({
        name: "last_name",
        message: "common:required-error-message",
        target: "#last-name-scroll-target",
      });
    }
    if (errors.length > 0) {
      const errorData = {};
      errors.forEach((error, index) => {
        if (index === 0) {
          scroll(error.target);
        }
        errorData[error.name] = error.message;
      });
      setErrorMessages(errorData);
    } else {
      dispatch(editProfile(localProfileData));
    }
  };

  return (
    <div className="user-contact">
      <div className="usercontact-main">
        <div>
          <div className="user-contact-header">
            <h5>{t("sell:contact-details")}</h5>
            <p>{t("profile:contact-details-instruction")}</p>
          </div>
          <div className="userprofile-form">
            <UserForm
              inputChangeHandler={inputChangeHandler}
              localProfileData={localProfileData}
              errorMessages={errorMessages}
            />
          </div>
        </div>
        <div>
          <ShownContactInfo>
            <Avatar customClass="small-avatar" circularSize={10} />
          </ShownContactInfo>
        </div>
      </div>
      <div className="user-contact-bottom">
        <div>
          <Button
            color="red"
            className="user-contact-button"
            label={t("common:save-all-changes")}
            click={updateProfileHandler}
          />
        </div>
        <div></div>
      </div>
    </div>
  );
};

const stateToProps = (state) => {
  const { profileData, chooseType } = state.profile;
  return {
    profileData,
    chooseType,
  };
};

export default connect(stateToProps)(UserDetails);
