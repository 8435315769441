import React from "react";
import Slider from "@material-ui/core/Slider";
import TextField from "../text-field/";
import { useTranslation } from "react-i18next";
import "./index.css";

const _Slider = (props) => {
  const {
    handleChange,
    min,
    max,
    label,
    type,
    title,
    value1,
    value2,
    placeholder1,
    placeholder2,
    disabled,
    children,
  } = props;
  const { t } = useTranslation(["common"]);
  let conjunction = `${t("common:to")}`;
  let slider = (
    <Slider
      value={[value1 ?? min, value2 ?? max]}
      min={min}
      max={max}
      onChange={(event, value) => handleChange(label, value)}
    />
  );

  if (type === 1) {
    slider = (
      <Slider
        value={value1 ?? min}
        min={min}
        max={max}
        onChange={(event, value) => handleChange(value)}
        disabled={disabled}
      />
    );
    conjunction = `${t("common:or")}`;
  }

  return (
    <div className="slider">
      <h5>{title}</h5>
      <div className="slider-inputs">
        <TextField
          placeholder={placeholder1}
          value={value1 === null || value1 === min ? "" : value1}
          changeHandler={(event) =>
            handleChange(label, [+event.target.value, value2])
          }
          type="number"
        />
        <p>{conjunction}</p>
        <TextField
          placeholder={placeholder2}
          value={value2 === null || value2 === max ? "" : value2}
          changeHandler={(event) =>
            handleChange(label, [value1, +event.target.value])
          }
          type="number"
        />
        {children ? <span>{children}</span> : null}
      </div>
      {slider}
    </div>
  );
};

export default _Slider;
