import React from "react";
import "./index.css";

const NotFound = () => {
  return (
    <div className="notfound">
      <h1>404</h1>
      <h3>Page not found</h3>
    </div>
  );
};

export default NotFound;
