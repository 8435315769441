import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { defaultLocation as defaultAplicationLocation } from "../data/index";
import Api from "../lib/api";
import { setVehicleLocation } from "../store/actions";
import useGoogleMap from "./useGoogleMap";

export const CoordinateType = {
  LATITUDE: "latitude",
  LONGITUDE: "longitude",
};

const useProfileDataLocation = () => {
  const dispatch = useDispatch();
  const ad_data = useSelector((state) => state.car.ad_data ?? {});
  const vehicle_location = useSelector(
    (state) => state.car.vehicle_location ?? {}
  );
  const profileData = useSelector((state) => state.profile.profileData ?? {});
  const { getCoordinatesForAddress } = useGoogleMap();
  const [mapInstance, setMapInstance] = useState(null);
  const [mapsInstance, setMapsInstance] = useState(null);

  const getCoordinates = (coordinateType) => {
    const adLocation =
      vehicle_location?.[coordinateType] ??
      ad_data?.vehiclelocation?.location?.[coordinateType];
    const profileLocation = profileData?.location?.[coordinateType];
    const defaultLocation =
      coordinateType === CoordinateType.LATITUDE
        ? defaultAplicationLocation.lat
        : defaultAplicationLocation.lng;
    return adLocation ?? profileLocation ?? defaultLocation;
  };

  const addMarker = (lat, lng, _map, _maps) => {
    const map = _map ?? mapInstance;
    const maps = _maps ?? mapsInstance;
    if (!map || !maps) {
      return;
    }
    const markerOptions = {
      position: {
        lat: lat ?? getCoordinates(CoordinateType.LATITUDE),
        lng: lng ?? getCoordinates(CoordinateType.LONGITUDE),
      },
      map,
      gmpDraggable: true,
    };

    // Create the AdvancedMarkerElement
    const marker = new maps.marker.AdvancedMarkerElement(markerOptions);

    // Add a dragend event listener
    marker.addListener("dragend", async () => {
      const lat = marker.position.lat;
      const lng = marker.position.lng;
      const vehicleLocation = {
        region: "",
        location: {
          latitude: lat,
          longitude: lng,
        },
      };

      try {
        const geocodeResponse = await Api.reverseGeocoding(lat, lng);
        geocodeResponse.results[0].address_components.forEach((item) => {
          if (item.types.includes("street_number")) {
            vehicleLocation.streetandnumber = item.long_name;
          }
          if (item.types.includes("route")) {
            vehicleLocation.streetandnumber =
              `${
                vehicleLocation?.streetandnumber
                  ? vehicleLocation.streetandnumber + " "
                  : ""
              }` + item.long_name;
          }
          if (item.types.includes("locality")) {
            vehicleLocation.city = item.long_name;
          }
          if (item.types.includes("postal_code")) {
            vehicleLocation.zipcode = item.long_name;
          }
        });
        dispatch(
          setVehicleLocation({
            ...vehicleLocation,
            place: geocodeResponse.results[0].formatted_address,
            location: { ...vehicleLocation?.location },
          })
        );
      } catch (err) {
        console.error("Geocoding failed:", err);
        return null;
      }
    });
  };

  const loadMap = async (map, maps) => {
    // Import the marker library
    await window.google.maps.importLibrary("marker");

    setMapInstance(map);
    setMapsInstance(maps);

    await addMarker(null, null, map, maps);

    // const markerOptions = {
    //   position: {
    //     lat: getCoordinates(CoordinateType.LATITUDE),
    //     lng: getCoordinates(CoordinateType.LONGITUDE),
    //   },
    //   map,
    //   gmpDraggable: true,
    // };

    // // Create the AdvancedMarkerElement
    // const marker = new maps.marker.AdvancedMarkerElement(markerOptions);

    // // Add a dragend event listener
    // marker.addListener("dragend", async () => {
    //   const lat = marker.position.lat;
    //   const lng = marker.position.lng;
    //   const vehicleLocation = {
    //     region: "",
    //     location: {
    //       latitude: lat,
    //       longitude: lng,
    //     },
    //   };

    //   try {
    //     const geocodeResponse = await Api.reverseGeocoding(lat, lng);
    //     geocodeResponse.results[0].address_components.forEach((item) => {
    //       if (item.types.includes("street_number")) {
    //         vehicleLocation.streetandnumber = item.long_name;
    //       }
    //       if (item.types.includes("route")) {
    //         vehicleLocation.streetandnumber =
    //           `${
    //             vehicleLocation?.streetandnumber
    //               ? vehicleLocation.streetandnumber + " "
    //               : ""
    //           }` + item.long_name;
    //       }
    //       if (item.types.includes("locality")) {
    //         vehicleLocation.city = item.long_name;
    //       }
    //       if (item.types.includes("postal_code")) {
    //         vehicleLocation.zipcode = item.long_name;
    //       }
    //     });
    //     dispatch(
    //       setVehicleLocation({
    //         ...vehicleLocation,
    //         place: geocodeResponse.results[0].formatted_address,
    //         location: { ...vehicleLocation.location },
    //       })
    //     );
    //   } catch (err) {
    //     console.error("Geocoding failed:", err);
    //     return null;
    //   }
    // });
  };

  useEffect(() => {
    const fetchData = async () => {
      const vehicleLocation = {
        region: "",
        location: {
          // latitude: getCoordinates(CoordinateType.LATITUDE),
          // longitude: getCoordinates(CoordinateType.LONGITUDE),
        },
      };
      let place = "";
      place += profileData?.address_data?.streetandnumber
        ? `${place ? ", " : ""}${profileData.address_data.streetandnumber}`
        : "";
      vehicleLocation.streetandnumber = profileData?.address_data
        ?.streetandnumber
        ? profileData.address_data.streetandnumber
        : "";
      place += profileData?.city
        ? `${place ? ", " : ""}${profileData.city}`
        : "";
      vehicleLocation.city = profileData?.address_data?.city
        ? profileData.address_data.city
        : "";
      place += profileData?.country
        ? `${place ? ", " : ""}${profileData.country}`
        : "";
      vehicleLocation.zipcode = profileData?.address_data?.zipcode
        ? profileData.address_data.zipcode
        : "";
      let location = {};
      if (!profileData?.location) {
        const addressData = await getCoordinatesForAddress(
          profileData?.address_data?.streetandnumber,
          profileData?.address_data?.city,
          profileData?.country
        );
        location = addressData.location;
      }

      dispatch(
        setVehicleLocation({
          // ...vehicleLocation.location,
          ...location,
          place,
        })
      );
    };

    if (!vehicle_location?.place) {
      fetchData();
    }
  }, []);

  return { loadMap, getCoordinates, addMarker };
};

export default useProfileDataLocation;
