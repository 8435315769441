import React from "react";
import { useTranslation } from "react-i18next";
import "./index.css";

const Company = () => {
  const { t } = useTranslation(["footer"]);
  return (
    <div className="company">
      <header>
        <h3>CARINDEX</h3>
      </header>
      <main>
        <p>{t("footer:company-1")}</p>
        <p>{t("footer:company-2")}</p>
        <p>{t("footer:company-3")}</p>
        <p>{t("footer:company-4")}</p>
        <p>{t("footer:company-5")}</p>
        <p>{t("footer:company-6")}</p>
      </main>
    </div>
  );
};

export default Company;
