import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { reportAd, resetReadyToReport } from "../../store/actions";
import FormControl from "../../components/form-control";
import Button from "../../components/button";
import ReportThanks from "./components/report-thanks-page";
import "./index.css";

const ReportAd = (props) => {
  const { dispatch, profileData, readyToReport } = props;
  const { t } = useTranslation("reportAd");
  // const [reasonOfReport, setReasonToReport] = useState({
  //   errors: false,
  //   suspicious: false,
  // });
  const [formData, setFormData] = useState({
    name: profileData?.first_name || "",
    email: profileData?.email || "",
    description: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const { idad } = useParams();

  const handleFormChange = (key, value, isCheckbox) => {
    if (isCheckbox) {
      if (key === "errors" || key === "suspicious") {
        setReasonToReport((prevState) => ({
          ...prevState,
          [key]: value,
        }));
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const note = formData.description;

    if (!note) {
      setErrorMessage(t("reportAd:error-message-describe-reason"));
      return;
    }
    setErrorMessage("");
    dispatch(reportAd(idad, note));
  };

  useEffect(() => {
    return () => {
      dispatch(resetReadyToReport());
    };
  }, []);

  return (
    <>
      {readyToReport ? (
        <ReportThanks />
      ) : (
        <div className="report-ad">
          <div className="header">
            <h5>{t("reportAd:report-suspicious-offer")}</h5>
            <p>{t("reportAd:header-lorem-ipsum")}</p>
          </div>
          <form className="report-form">
            <FormControl
              inputType="textfield"
              type="text"
              label={t("reportAd:insert-name-or-surname")}
              placeholder="Type"
              value={formData.name}
              handleChange={(e) => handleFormChange("name", e.target.value)}
              disabled={true}
            />
            <FormControl
              inputType="textfield"
              label={t("reportAd:insert-e-mail-address")}
              type="text"
              id="email"
              name="email"
              placeholder="Type"
              value={formData.email}
              handleChange={(e) => handleFormChange("email", e.target.value)}
              disabled={true}
            />
            <div className="radio-buttons">
              <label>{t("reportAd:reason-of-the-report")}</label>
              <FormControl
                inputType="checkbox"
                label={t("reportAd:contains-errors")}
                name="errors"
                className="errors-radio"
                // value={reasonOfReport.errors}
                // handleChange={() =>
                //   handleFormChange("errors", !reasonOfReport.errors, true)
                // }
                disabled={true}
              />
              <FormControl
                inputType="checkbox"
                label={t("reportAd:ad-is-suspicious")}
                name="suspicious"
                // value={reasonOfReport.suspicious}
                // handleChange={() =>
                //   handleFormChange(
                //     "suspicious",
                //     !reasonOfReport.suspicious,
                //     true
                //   )
                // }
                disabled={true}
              />
            </div>
            <div className="textarea">
              <label>{t("reportAd:description")}</label>
              <textarea
                name="description"
                placeholder="Type"
                id="description"
                value={formData.description}
                onChange={(e) =>
                  handleFormChange("description", e.target.value)
                }
              ></textarea>
              {errorMessage && (
                <div style={{ color: "red" }}>
                  {t("reportAd:error-message-describe-reason")}
                </div>
              )}
            </div>
          </form>
          <Button
            type="submit"
            color="red"
            label={t("reportAd:send")}
            className="send-report-button"
            click={handleSubmit}
          />
        </div>
      )}
    </>
  );
};

const stateToProps = (state) => {
  const { readyToReport } = state.car;
  const { profileData } = state.profile;
  return { profileData, readyToReport };
};

export default connect(stateToProps)(ReportAd);
