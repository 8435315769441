import socket from "../../lib/ws-client";

export function sendMessage(method, params) {
  let message = JSON.stringify({
    method: method,
    params: params,
    seq: sequenceMessage(),
  });
  _log(method, params);
  socket.send(message);
  storeMessage(message);
}

function sequenceMessage() {
  if (localStorage.getItem("seq")) {
    localStorage.setItem("seq", parseInt(localStorage.getItem("seq")) + 1);
  } else {
    localStorage.setItem("seq", 1);
  }
  return parseInt(localStorage.getItem("seq"));
}

function storeMessage(message) {
  let storedMessages = [];
  if (localStorage.getItem("messages"))
    storedMessages = JSON.parse(localStorage.getItem("messages"));
  storedMessages.push(message);
  localStorage.setItem("messages", JSON.stringify(storedMessages));
}

// export function startUpload(file) {
//   socket.binaryType = "arraybuffer";
//   socket.send(file);
// }

function _log(method, params) {
  if (!process.env.debug) return;
  // console.log('%cSEND: ' + '%c' + method + '  ' + params, 'color: #44CADB', 'color: black');
}

export function startUpload(file) {
  socket.binaryType = "arraybuffer";
  // console.log('file to upload', file)
  socket.send(file);
}
