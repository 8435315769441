import React from "react";
import Logo from "../../assets/images/new-logo-white.svg";
import GooglePlay from "../../assets/images/google-play.svg";
import { NavLink } from "react-router-dom";
import AppStore from "../../assets/images/app-store.svg";
import { useTranslation } from "react-i18next";
import { currentYear } from "../../data";
import "./index.css";

const footer = () => {
  const { t, i18n } = useTranslation(["footer, common", "navbar"]);
  const current_year = currentYear;

  return (
    <div className="footer flex-row-wrapper flex-justify-between footer-font-color">
      <div className="footer-top">
        <span className="flex-col-wrapper flex-aling-center footer-image-container">
          <img src={Logo} alt="" />
        </span>
      </div>
      <div className="footer-main-content">
        <div className="footer-main-subcontent">
          <div className="flex-col-wrapper">
            <div className="flex-row-wrapper footer-list-container">
              <h4 className="footer-header">{t("common:car-index")}</h4>
              <ul className="footer-list">
                <li>
                  <NavLink
                    to={`/${i18n.language}/advanced-search/`}
                    className="list-item-text"
                  >
                    {t("common:advanced-search")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/${i18n.language}/dealer-search`}
                    className="list-item-text"
                  >
                    {t("footer:find-garages")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/${i18n.language}/brand-model/`}
                    className="list-item-text"
                  >
                    {t("footer:overview-of-all-brands")} <br />
                    {t("footer:and-model")}
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="flex-row-wrapper footer-list-container">
              <h4 className="footer-header">{t("navbar:about-us")}</h4>
              <ul className="footer-list">
                <li>
                  <NavLink
                    to={`/${i18n.language}/about-us/company`}
                    className="list-item-text"
                  >
                    {t("footer:company")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/${i18n.language}/contact-us/`}
                    className="list-item-text"
                  >
                    {t("common:contact")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/${i18n.language}/newsletter/`}
                    className="list-item-text"
                  >
                    Newsletter
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/${i18n.language}/legal/impressum/`}
                    className="list-item-text"
                  >
                    {t("footer:impressum")}
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="flex-row-wrapper footer-list-container">
              <h4 className="footer-header">{t("footer:insertion")}</h4>
              <ul className="footer-list">
                <li>
                  <NavLink
                    to={`/${i18n.language}/cars/`}
                    className="list-item-text"
                  >
                    {t("footer:cars")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/${i18n.language}/cars/`}
                    className="list-item-text"
                  >
                    {t("footer:products-and-prices")}
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink to="#" className="list-item-text">
                    {t("footer:facts-and-figures")}
                  </NavLink>
                </li> */}
              </ul>
              <br></br>
              <h4 className="footer-header">{t("footer:advertising")}</h4>
              <ul className="footer-list">
                <li>
                  <NavLink
                    to={`/${i18n.language}/contact-us/`}
                    className="list-item-text"
                  >
                    {t("common:car-index")}
                  </NavLink>
                </li>
                <li>
                  <a
                    className="list-item-text"
                    href="https://automobilrevue.ch/mediadaten"
                    target="_blank"
                  >
                    {t("footer:automobil-revue")}
                  </a>
                </li>
                <li>
                  <a
                    className="list-item-text"
                    href="https://www.menmagazin.ch/kontakt"
                    target="_blank"
                  >
                    {t("footer:men")}
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex-row-wrapper footer-list-container">
              <h4 className="footer-header">{t("footer:legal-security")}</h4>
              <ul className="footer-list">
                <li>
                  <NavLink
                    to={`/${i18n.language}/legal/safety-instructions/`}
                    className="list-item-text"
                  >
                    {t("footer:safety-instructions")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/${i18n.language}/legal/insertion-rules/`}
                    className="list-item-text"
                  >
                    {t("footer:insertion-rules")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/${i18n.language}/legal/terms-and-conditions/`}
                    className="list-item-text"
                  >
                    {t("footer:terms-conditions")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/${i18n.language}/legal/privacy-protection/`}
                    className="list-item-text"
                  >
                    {t("footer:privacy-protection-declaration")}
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="flex-row-wrapper footer-list-container">
              <h4 className="footer-header">{t("footer:social-media")}</h4>
              <ul className="footer-list">
                <li>
                  <a
                    className="list-item-text"
                    href="https://www.instagram.com/carindex.ch?igsh=c2F4dDJ4anp5cmsz&utm_source=qr"
                    target="_blank"
                  >
                    {t("footer:instagram")}
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/share/2WAjwgex7NUCT3hW/?mibextid=LQQJ4d"
                    className="list-item-text"
                    target="_blank"
                  >
                    {t("footer:facebook")}
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/carindex/"
                    className="list-item-text"
                    target="_blank"
                  >
                    Linkedin
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCS7aALVBsYFFBD78YBnFshw"
                    className="list-item-text"
                    target="_blank"
                  >
                    {t("footer:youtube")}
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex-row-wrapper footer-list">
              <h4 className="footer-header">{t("footer:app-download")}</h4>
              <div className="flex-row-wrapper footer-list-container">
                <img className="app-download-icon" src={GooglePlay} alt="" />
                <img className="app-download-icon" src={AppStore} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex-min centered">
        <p className="copy-right-1">{t("footer:copy-right-1")}</p>
        <p className="copy-right">
          &#169; {current_year}, {t("footer:copy-right")}
        </p>
      </div>
    </div>
  );
};

export default footer;
