import React from "react";
import { connect } from "react-redux";
import AvatarIcon from "../../assets/shapes/icon.svg";

import "./index.css";
const Avatar = (props) => {
  const { customClass, isAuthorized, handler, avatar } = props;

  let userAvatar = null;
  let realImage;

  if (!!avatar) {
    userAvatar = <img src={avatar} onClick={handler} />;
    realImage = true;
  }
  if (!avatar || !isAuthorized) {
    userAvatar = (
      <span className="person-icon">
        <img className="MuiSvgIcon-root" src={AvatarIcon} onClick={handler} />
      </span>
    );
    realImage = false;
  }
  return (
    <div
      className={`avatar ${customClass} ${
        realImage ? "border-red" : "border-grey"
      }`}
    >
      {userAvatar}
    </div>
  );
};

const stateToProps = (state) => {
  const { loading, isAuthorized, avatar, profileData } = state.profile;
  return {
    loading,
    avatar: avatar ?? profileData?.avatar,
    isAuthorized,
  };
};

export default connect(stateToProps)(Avatar);
