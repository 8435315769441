import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import RadioGroup from "@material-ui/core/RadioGroup";
import history from "../../../../lib/history";
import UserPackage from "../../../../components/user-package";
import "./index.css";

const MerchantPackages = (props) => {
  const pathname = history.location.pathname;
  const { t, i18n } = useTranslation("common,sell");
  const [packageType, setPackageType] = useState("basic");
  const [userPackages, setUserPackages] = useState([]);

  useEffect(() => {
    // Update userPackages state when language changes
    const updatedPackages = [
      {
        type: t("profile:basic"),
        price: t("common:free"),
        checked: false,
        items: [
          t("profile:one-active-ad"),
          t("profile:full-feature-description"),
          `10 ${t("profile:photos-per-vehicle")}`,
          t("profile:ad-expiration-15-days"),
        ],
      },
      {
        type: t("profile:dynamic"),
        price: "x,- chf",
        checked: false,
        items: [
          t("profile:up-to-five-active-ads"),
          t("profile:full-feature-description"),
          `15 ${t("profile:photos-per-vehicle")}`,
          t("profile:one-video-walk-trough"),
          t("profile:ad-duration-unlimited"),
        ],
      },
      {
        type: t("profile:advanced"),
        price: "yy- chf",
        checked: false,
        items: [
          t("profile:unlimited-no-of-active-ads"),
          t("profile:full-feature-description"),
          `25 ${t("profile:photos-per-vehicle")}`,
          t("profile:one-video-walk-trough"),
          t("profile:ad-duration-unlimited"),
        ],
      },
      {
        type: t("profile:premium"),
        price: "zzz- chf",
        checked: false,
        items: [
          t("profile:unlimited-no-of-active-ads"),
          t("profile:full-feature-description"),
          `25 ${t("profile:photos-per-vehicle")}`,
          t("profile:one-video-walk-trough"),
          t("profile:ad-duration-unlimited"),
          t("profile:special-endorsement-badge"),
          t("profile:first-webpage-listing"),
          t("profile:api-for-creating-ad"),
        ],
      },
    ];
    setUserPackages(updatedPackages);
  }, [t]);

  const packageChangeHandler = (pIndex) => {
    const data = userPackages.map((item, index) => {
      if (index === pIndex) {
        setPackageType(item.type);
        return { ...item, checked: !item.checked };
      } else {
        return { ...item, checked: false };
      }
    });
    setUserPackages(data);
  };

  return (
    <div className="user-packages-page">
      <header>
        <h4>
          {t("profile:your-current-package")}: <span>{packageType}</span>
        </h4>
      </header>
      <main className="u-packages">
        {userPackages.map((item, index) => {
          return (
            <RadioGroup
              key={index}
              name="upackage"
              onChange={() => packageChangeHandler(index)}
            >
              <UserPackage
                userPackage="basic"
                uPackage={item}
                checked={item.checked}
                pIndex={index}
                type={item.type}
              />
            </RadioGroup>
          );
        })}
      </main>
    </div>
  );
};

export default MerchantPackages;
