import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Image from "../../../assets/images/carousel-home/clickable-image.png";
import { useTranslation } from "react-i18next";
import { backIcon } from "../../../components/svg-icons/index";
import Button from "../../../components/button";
import FormControl from "../../../components/form-control";
import Radio from "../../../components/radio";
import NewsletterImage from "../../../assets/images/newsletter/newsletter.png";

import "./index.css";

const NewsLetter = (props) => {
  const { profileData } = props;
  const { t, i18n } = useTranslation(["footer", "common"]);
  const history = useHistory();

  const labelHTML = `${t(
    "common:yes-i-accept"
  )} <a href="legal/terms-and-conditions" style="color: red;">T&C</a> ${t(
    "common:and-the"
  )} <a href="legal/privacy-protection" style="color: red;">${t(
    "footer:privacy-protection-declaration"
  )}</a>`;

  const goBack = () => {
    history.goBack();
  };

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setBody((prevBody) => ({
      ...prevBody,
      [name]: parseInt(e.target.value),
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBody((prevBody) => ({
      ...prevBody,
      [name]: value,
    }));
  };

  return (
    <div className="newsletter">
      <header>
        <div className="newsletter-go-back" onClick={goBack}>
          {backIcon}
          <p className="no-margin flex">{t("common:back")}</p>
        </div>
        <h3>{t("footer:newsletter")}</h3>
      </header>
      <main>
        <div className="image-wrapper">
          {Image && <img src={Image} alt="Car" />}
          <h3 className="instructions-text-title--mobile">
            {t("footer:newsletter-1")}
          </h3>
        </div>
        <div className="newsletter-instructions">
          <div className="instructions-text">
            <h3>{t("footer:newsletter-1")}</h3>
            <p>{t("footer:newsletter-2")}</p>
            <p>{t("footer:newsletter-3")}</p>
            <h3>{t("footer:newsletter-4")}</h3>
          </div>
          <h3 className="newsletter-modal-title--mobile">
            {t("footer:newsletter-4")}
          </h3>
          <div className="newsletter-modal">
            <div className="header">
              <h2>{t("footer:newsletter")}</h2>
              <span>
                <img src={NewsletterImage} alt="Newsletter" />
              </span>
            </div>
            <div className="main">
              <FormControl
                inputType="textfield"
                label={t("profile:email-address")}
                name="email"
                type="text"
                placeholder={t("common:type")}
                handleChange={handleInputChange}
              />
              <div className="radios">
                <Radio
                  name={"acceptance"}
                  className={"radio-button-target"}
                  label={t("common:german")}
                  // checked={step4Complete}
                  // changeHandler={(e) => handleStep4Change(e.target.checked)}
                />
                <Radio
                  name={"acceptance"}
                  className={"radio-button-target"}
                  label={t("common:french")}
                  // checked={step4Complete}
                  // changeHandler={(e) => handleStep4Change(e.target.checked)}
                />
                <Radio
                  name={"acceptance"}
                  className={"radio-button-target"}
                  label={t("common:italian")}
                  // checked={step4Complete}
                  // changeHandler={(e) => handleStep4Change(e.target.checked)}
                />
                <Radio
                  name={"acceptance"}
                  className={"radio-button-target"}
                  label={t("common:english")}
                  // checked={step4Complete}
                  // changeHandler={(e) => handleStep4Change(e.target.checked)}
                />
              </div>
              <div className="checkbox-and-button">
                <FormControl
                  inputType="checkbox"
                  label={
                    <span dangerouslySetInnerHTML={{ __html: labelHTML }} />
                  }
                  // handleChange={() => checkboxHandler("fromMfk", !fromMfk)}
                  // value={fromMfk}
                />
                <Button
                  type="submit"
                  color="transparent"
                  label={t("common:register")}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

const stateToProps = (state) => {
  const { profileData } = state.profile;
  return {
    profileData,
  };
};

export default connect(stateToProps)(NewsLetter);
