import React from "react";
import { ArrowDownRed, ArrowRight } from "../svg-icons";
import "./index.css";

export const NavDropDown = (props) => {
  const { title, subtitle, titleClass, subtitleClass, rootClass, click } =
    props;
  return (
    <div className={`nav-dropdown ${rootClass}`}>
      {subtitle && <p className={subtitleClass}>{subtitle}</p>}
      <span className={`title ${titleClass}`} onClick={click}>
        <p>{title}</p>
        {subtitle ? ArrowDownRed : <ArrowRight />}
      </span>
    </div>
  );
};
