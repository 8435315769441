import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const LeavePagePrompt = () => {
  const { t } = useTranslation("common");
  const history = useHistory();

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    const handleUnload = () => {
      const confirmationMessage = t("common:prompt-leave-page");
      event.returnValue = confirmationMessage;
      return confirmationMessage;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleUnload);
      //   }
    };
  }, [history]);

  return null;
};

export default LeavePagePrompt;
